import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IOrganizationListFilter } from '@src/service/business/organization/OrganizationListBusinessStore';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';

// -- Const
// ----------

// -- Prop types
// ----------
export interface IOrganizationListFilterOwnProps {
  filter?: IOrganizationListFilter;
  onSubmit?: (values: IOrganizationListFilter) => void;
}

type IOrganizationListFilterProps = IOrganizationListFilterOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display contact data input fields */
const OrganizationListFilter: React.FC<IOrganizationListFilterProps> = (props: IOrganizationListFilterProps) => {

  const [form] = Form.useForm<IOrganizationListFilter>();

  const onFinish = (values: IOrganizationListFilter) => {
    const filter: IOrganizationListFilter = { ...props.filter, ...values };
    const newFilter: IOrganizationListFilter = {};
    Object.keys(filter).forEach((key) => {
      const filterKey = key as keyof IOrganizationListFilter; // this is the real type returned from Object.keys, but TS for some reason doesn't know it and treats value as just string
      if (values[filterKey] != null && values[filterKey] !== '') { // filter values 0 or false are valid, so take care not just to check if it is falsy
        newFilter[filterKey] = values[filterKey];
      }
    });
    props.onSubmit?.(newFilter);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  return (
    <Form<IOrganizationListFilter>
      form={form}
      name="organizationListFilter"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={props.filter}
      layout="vertical"
    >
      <Row className="full-width" align="bottom" justify="space-between">
        <Col span={18}>
          <Form.Item
            name={['name']}
            fieldKey={['name']}
            label={props.translate('COMMON.LABEL.NAME')}
          >
            <Input suffix={<LemonIcon name="search" />} placeholder={props.translate('COMMON.LABEL.INPUT_SEARCH_PLACEHOLDER')} allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item className="text-center">
            <Button data-test-id="timun-organizationList__filterForm-submitButton" htmlType="submit">
              {props.translate('COMMON.ACTION_SEARCH')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize<IOrganizationListFilterOwnProps>(OrganizationListFilter as any);

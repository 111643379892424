import React, { useEffect } from 'react';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { IUserCategory } from '@src/model/user/UserCategory';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserCategoryBusinessStore, { IUserCategoryListFilter } from '@src/service/business/user/userCategoryBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Button, Form, FormInstance, Select } from 'antd';
import { connect } from 'react-redux';

// -- Const
// ----------
const { Option } = Select;

// -- Prop types
// ----------
export interface IUserCategoryForm {
  userCategoryIds: string[];
}

export interface IExternalEducationUserCategoryFormOwnProps {
  value?: IUserCategory[];
  disabled?: boolean;
  formRef?: FormInstance<IUserCategoryForm>;
  onSubmit?: (value: Array<IIdRef<string>>) => void;
}

export interface IExternalEducationUserCategoryFormStateProps {
  userCategoryList: ICollectionData<IUserCategory>;
}

export interface IExternalEducationUserCategoryFormDispatchProps {
  fetchUserCategoryList: (params: ICollectionFetchPayload<IUserCategoryListFilter>) => ITrackableAction;
}

type IExternalEducationUserCategoryFormProps = IExternalEducationUserCategoryFormOwnProps & IExternalEducationUserCategoryFormStateProps & IExternalEducationUserCategoryFormDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationUserCategoryForm: React.FC<IExternalEducationUserCategoryFormProps> = (props: IExternalEducationUserCategoryFormProps) => {
  const [form] = Form.useForm<IUserCategoryForm>(props.formRef);

  useEffect(() => {
    props.fetchUserCategoryList({ filter: { active: true } });
  }, []);

  const handleSubmit = (value: IUserCategoryForm) => {
    props.onSubmit?.(transformOutputValue(value));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  const transformInputValue = () => {
    return { userCategoryIds: props.value?.map((userCategory) => userCategory.id) };
  };

  const transformOutputValue = (value: IUserCategoryForm): Array<IIdRef<string>> => {
    return value.userCategoryIds.map((userCategoryId) => ({ id: userCategoryId }));
  };

  const options = props.userCategoryList?.content
    .map((item: IUserCategory) => (
      <Option
        value={item.id}
        key={item.id}>
        {item.name}
      </Option >
    ));

  return (
    <Form<IUserCategoryForm>
      initialValues={transformInputValue()}
      layout="vertical"
      form={form}
      name="userCategories"
      onFinishFailed={onFinishFailed}
      onFinish={handleSubmit}>

      <Form.Item
        name={['userCategoryIds']}
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_APPLICATION.USER_CATEGORY.FORM.USER_CATEGORY_LABEL')}
          tooltipDescription={props.translate('EXTERNAL_EDUCATION_APPLICATION.USER_CATEGORY.FORM.USER_CATEGORY_TOOLTIP_LABEL')} />}
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <Select mode="multiple" allowClear={true}>
          {options}
        </Select>
      </Form.Item>
      {/* If formRef prop is passed to ExternalEducationUserCategoryForm. That mean that parent component is obligated to submit form. Example modals */}
      <Button hidden={!!props.formRef} disabled={props.disabled} onClick={form.submit}> {props.translate('COMMON.ACTION_SAVE')} </Button>
    </Form>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IExternalEducationUserCategoryFormStateProps => ({
  userCategoryList: UserCategoryBusinessStore.selectors.getUserCategoryList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExternalEducationUserCategoryFormDispatchProps => ({
  fetchUserCategoryList: (params: ICollectionFetchPayload<IUserCategoryListFilter>) => createTrackableAction(dispatch(UserCategoryBusinessStore.actions.fetchUserCategoryList(params))),
});

export default connect<IExternalEducationUserCategoryFormStateProps, IExternalEducationUserCategoryFormDispatchProps, IExternalEducationUserCategoryFormOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ExternalEducationUserCategoryForm as any));

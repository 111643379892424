import LemonIcon from '@src/components/common/image/LemonIcon';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ProgressBar from '@src/components/common/progressbar/ProgressBar';
import UserListContainer from '@src/components/user/list/UserListContainer';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import courseViewBusinessStore from '@src/service/business/courses/courseViewBusinessStore';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { ProgressProps } from 'antd/lib/progress';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { connect } from 'react-redux';
import { ICourse } from '@src/model/course/Course';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import HeaderTitle from '@src/components/course/common/HeaderTitle';

const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');

// --
// ----- Prop types
interface ICourseUserListContainerOwnProps {
  courseId: string;
}

interface ICourseUserListContainerStateProps {
  course: ICourse;
}

interface ICourseUserListContainerDispatchProps {}

type ICourseUserListContainerProps = ICourseUserListContainerOwnProps & ICourseUserListContainerStateProps & ICourseUserListContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// --
// ----- Component
const CourseUserListContainer: React.FC<ICourseUserListContainerProps> = (props: ICourseUserListContainerProps) => {
  const predefinedListFilter: Readonly<IUserListFilter> = useMemo(() => {
    return { role: UserRoleEnum.TRAINEE, withCourseProgress: true, course: props.params.courseId };
  }, [UserRoleEnum, props.params.courseId]);

  const additionalCols = useCallback((): ITimunDataTableCol<IUserInfo>[] => {
    return [
      {
        key: 'statusCol',
        contentType: 'string',
        headerTitle: props.translate('USER_LIST.COURSE_STATUS_LABEL'),
        content: (user: IUserInfo) => {
          const progressProp: ProgressProps = {};
          let statusCell;

          if (user?.additionalData?.progress) {
            statusCell = (
              <span>
                <ProgressBar progressProps={{ type: 'circle', width: 30, ...progressProp }} progress={user.additionalData.progress} hasExam={props.course?.exam !== null} />
                &nbsp;
                {props.translate(`COURSE_LIST.COURSE_STATUS_${user.additionalData.progress.completionStatus.name}`)}
              </span>
            );
          }
          return statusCell;
        },
      },
      {
        key: 'dueDateCol',
        contentType: 'string',
        headerTitle: props.translate('USER_LIST.COURSE_DEADLINE_LABEL'),
        content: (user: IUserInfo) => {
          return user.additionalData?.progress?.deadlineDate != null ? moment(user.additionalData.progress.deadlineDate).format(DATE_FORMAT) : undefined;
        },
      },
    ];
  }, [props.translate, props.course?.exam, DATE_FORMAT]);

  return (
    <AppContent title={<HeaderTitle title={props.translate('USER_LIST.TRAINEES_TITLE')} icon={<LemonIcon name="left" />} link={props.location.state?.returnRoute} />}>
      <UserListContainer additionalCols={additionalCols} additionalSearchParams={false} predefinedListFilter={predefinedListFilter} />
    </AppContent>
  );
};
const mapStateToProps = (state: any): ICourseUserListContainerStateProps => ({
  course: courseViewBusinessStore.selectors.getCourseContent(state),
});

const mapDispatchToProps = (dispatch: any): ICourseUserListContainerDispatchProps => ({});

export default connect<ICourseUserListContainerStateProps, ICourseUserListContainerDispatchProps, ICourseUserListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(CourseUserListContainer) as any));

import FileTypesListView from '@src/components/common/file/FileTypesListView';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExternalEducationTemplateFileTypeEnum } from '@src/model/file/FileType';
import { IFile } from '@src/model/file/File';
import { IFileListsByType } from '@src/service/business/files/util';
import React from 'react';

interface IExternalEducationTemplateFileListViewOwnProps {
  displayTypes: Array<keyof typeof ExternalEducationTemplateFileTypeEnum>;
  files?: IFileListsByType;
  canRemove?: boolean;
  onRemove?: (file: IFile[]) => void;
}
type IExternalEducationTemplateFileListViewProps = IExternalEducationTemplateFileListViewOwnProps & IWithLocalizeOwnProps;

interface IExternalEducationTemplateFileListViewState {
}

class ExternalEducationTemplateFileListView extends React.Component<IExternalEducationTemplateFileListViewProps, IExternalEducationTemplateFileListViewState> {
  state: IExternalEducationTemplateFileListViewState = {
  };

  render = () => {
    return <FileTypesListView
      displayTypes={this.props.displayTypes}
      files={this.props.files}
      canRemove={this.props.canRemove}
      onRemove={this.props.onRemove}
    />;
  };
}

export default withLocalize<IExternalEducationTemplateFileListViewOwnProps>(ExternalEducationTemplateFileListView as any);


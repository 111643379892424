import { IUserSettings, UserSettingsBusinessStore } from '@src/service/business/usersettings/userSettingsBusinessStore';
import { useDispatch, useSelector } from 'react-redux';

/* Custom hook to store and get user settings from redux store */
const useUserSettingsStore = (): [IUserSettings | undefined, ((data: IUserSettings) => void) | undefined] => {
  const dispatch = useDispatch();

  const userSettings = useSelector((store: any) => UserSettingsBusinessStore.selectors.getUserSettings(store));

  const setUserSettings = (data: IUserSettings) => dispatch(UserSettingsBusinessStore.actions.storeUserSettings(data));

  return [userSettings, setUserSettings];
};

export default useUserSettingsStore;

import React from 'react';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { ExternalEducationTemplateHelperUtils } from '@src/components/externaleducation/common/ExternalEducationTemplateHelperUtils';
import ExternalEducationTemplateCoordinatorApprovalPanel from '@src/components/externaleducation/ExternalEducationTemplateCoordinatorApprovalPanel';
import ExternalEducationTemplateDetailsPanel from '@src/components/externaleducation/ExternalEducationTemplateDetailsPanel';
import ExternalEducationTemplateTabs from '@src/components/externaleducation/ExternalEducationTemplateTabs';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IFileListsByType } from '@src/service/business/files/util';
import { ActivityUtils } from '@src/service/util/activity/ActivityUtils';

// -- Prop types
// ----------

export interface IExternalEducationTemplateViewOwnProps {
  currentUser: IUserInfo;
  externalEducationTemplate: IExternalEducationTemplate;
  openedTab: string;
  canEdit?: boolean;
  files?: IFileListsByType;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onUpdate?: (data: IExternalEducationTemplate) => void;
  onVerificationSubmit: (updatedExternalEducationTemplate: IExternalEducationTemplate) => void;
  onActivityPhaseChange?: (phaseId: ExternalEducationTemplateActivityPhaseEnum) => void;
  onCoordinatorAdd?: () => void;
  onExternalEducationTemplateEdit?: () => void;
}
type IExternalEducationTemplateViewProps = IExternalEducationTemplateViewOwnProps & IWithRolesOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education template details panel and tabs section */
const ExternalEducationTemplateView: React.FC<IExternalEducationTemplateViewProps> = (props: IExternalEducationTemplateViewProps) => {
  /** Flags isAllowedToApprove and isCurrentUserEvaluator are needed because coordinator is not evaulator until he take education template under approval process  */
  const isAllowedToApprove = props.allowedRoles([UserRoleEnum.COORDINATOR, UserRoleEnum.ORGANIZATION_ADMIN]);
  const isCurrentUserAdmin = props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]);
  const isCurrentUserCoordinator = props.isInRoles([UserRoleEnum.COORDINATOR]);
  const isCurrentUserEvaluator = props.externalEducationTemplate.activity && ActivityUtils.getParticipantByRole(ParticipantRoleEnum.EVALUATOR, props.externalEducationTemplate.activity)?.userId === props.currentUser.id;
  const isCurrentUserAssignee = props.externalEducationTemplate.activity && ActivityUtils.isParticipantAssignee(props.currentUser.id, props.externalEducationTemplate.activity);
  const isCurrentUserMember = props.externalEducationTemplate.educationProvider.currentUserMember != null;
  const showApprovalPanel =
    props.externalEducationTemplate.activity &&
    isAllowedToApprove && !isCurrentUserAssignee &&
    ActivityUtils.isInPhases([ExternalEducationTemplateActivityPhaseEnum.APPROVAL_REQUESTED, ExternalEducationTemplateActivityPhaseEnum.APPROVAL_IN_PROCESS, ExternalEducationTemplateActivityPhaseEnum.CHANGE_SUBMITTED], props.externalEducationTemplate.activity);
  const showEducationTemplateDocuments = props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) || isCurrentUserMember;

  return (
    <AppContent>
      {/* Verification Panel */}
      {showApprovalPanel && <ExternalEducationTemplateCoordinatorApprovalPanel isCurrentUserEvalutor={isCurrentUserEvaluator} externalEducationTemplate={props.externalEducationTemplate} onCoordinatorAdd={props.onCoordinatorAdd} onActivityPhaseChange={props.onActivityPhaseChange} />}

      <ExternalEducationTemplateDetailsPanel
        collapsible={false}
        active={true}
        isCurrentUserMember={isCurrentUserMember}
        onVerificationSubmit={props.onVerificationSubmit}
        externalEducationTemplate={props.externalEducationTemplate}
        showEducationTemplateDocuments={showEducationTemplateDocuments}
        showEducationTemplateAveragePrice={props.allowedRoles([UserRoleEnum.COORDINATOR, UserRoleEnum.ORGANIZATION_ADMIN]) || isCurrentUserMember}
        files={props.files}
        canRemoveFile={props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN])}
        canAddFile={ExternalEducationTemplateHelperUtils.isEducationTemplateInStatus(props.externalEducationTemplate, ['IN_CREATION']) || props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN])}
        onFileRemove={props.onFileRemove}
        onFileUpload={props.onFileUpload}
        canEditTemplate={props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) || (isCurrentUserMember && ExternalEducationTemplateHelperUtils.isEducationTemplateInStatus(props.externalEducationTemplate, ['IN_CREATION']))}
        onExternalEducationTemplateEdit={props.onExternalEducationTemplateEdit}
      />

      <ExternalEducationTemplateTabs
        isCurrentUserMember={isCurrentUserMember}
        isCurrentUserCoordinator={isCurrentUserCoordinator}
        isCurrentUserAdmin={isCurrentUserAdmin}
        openedTab={props.openedTab}
        showActivityTab={isCurrentUserMember || props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN])}
        externalEducationTemplate={props.externalEducationTemplate}
      />
    </AppContent>
  );
};

export default withLocalize<IExternalEducationTemplateViewOwnProps>(withRoles(ExternalEducationTemplateView as any));

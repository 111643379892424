import ExamInstanceListContainer from '@src/components/exam/examinstancelist/ExamInstanceListContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type IExamTemplateStatisticsPageProps = WithRouterProps;

const ExamTemplateStatisticsPage = (props: IExamTemplateStatisticsPageProps) => <ExamInstanceListContainer courseId={props.params.courseId} />;

export default withRouter<IExamTemplateStatisticsPageProps>(ExamTemplateStatisticsPage);

import React from 'react';

import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamTemplateHelperUtils from '@src/components/exam/common/ExamTemplateHelperUtils';
import QuizCreatorContainer from '@src/components/exam/quiz/QuizCreatorContainer';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamTemplate } from '@src/model/education/ExamTemplate';
import { Typography } from 'antd';

// -- Prop types
// ----------

export interface IExamTemplateQuizCreatorViewOwnProps {
  examTemplate: IExamTemplate;
  onQuizCollectionSubmit: (data: IQuiz) => void;
}
type IExamTemplateQuizCreatorViewProps = IExamTemplateQuizCreatorViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display exam template question collection as exam */
const ExamTemplateQuizCreatorView: React.FC<IExamTemplateQuizCreatorViewProps> = (props: IExamTemplateQuizCreatorViewProps) => {
  const isExamTemplateFinished = ExamTemplateHelperUtils.isExamTemplateInPhase(props.examTemplate, [ActivityPhaseEnum.FINISHED]);
  return <React.Fragment>{isExamTemplateFinished ? <Typography.Paragraph strong={true}>{props.translate('EXAM_TEMPLATE.QUIZ_CREATOR.INFO_TEXT_LABEL')}</Typography.Paragraph> : <QuizCreatorContainer onQuizCollectionSubmit={props.onQuizCollectionSubmit} quizId={props.examTemplate.collection.id} />}</React.Fragment>;
};

export default withLocalize<IExamTemplateQuizCreatorViewOwnProps>(ExamTemplateQuizCreatorView as any);

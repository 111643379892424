import { IBaseUserActivity } from '@src/model/activity/UserActivity';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { FileTypeEnum } from '@src/model/file/FileType';
import { SurveyTypeEnum } from '@src/model/survey/SurveyTemplate';

export interface IExternalEducationApplicationTimelineActivity {
  content: IBaseUserActivity<ICodeBookEntry<ExternalEducationAplicationTimelineActivityItemTypeEnum>, IExternalEducationApplicationTimelineActivityContent>;
  type: ExternalEducationApplicationTimelineActivityTypeEnum;
  createdAt: string;
}

export enum ExternalEducationApplicationTimelineActivityTypeEnum {
  ACTION = 'ACTION',
  NOTE = 'NOTE',
}

/** Union type for user activity content. */
export type IExternalEducationApplicationTimelineActivityContent = IEducationApplicationDateContent | ITimelineFileContent | IConsultationUserActivityContent | ITimelineCoordinatorRemoveContent | ITimelineCoordinatorAddContent | ITimelineSurveySubmittedContent | null;

// Timeline activities content types
export interface ITimelineFileContent {
  fileResource: ICodeBookEntry;
  type: ICodeBookEntry<FileTypeEnum>;
}
// ----- Consultation activity
export interface IConsultationUserActivityContent {
  description?: string;
}

export interface ITimelineCoordinatorRemoveContent {
  removedParticipant: ICodeBookEntry;
}

export interface ITimelineCoordinatorAddContent {
  addedParticipant: ICodeBookEntry;
}

export interface ITimelineSurveySubmittedContent {
  surveyType: ICodeBookEntry<SurveyTypeEnum>;
}

export interface IEducationApplicationDateContent {
  endDateTime: string;
  paymentRequestedDateTime: string;
}

export enum ExternalEducationAplicationTimelineActivityItemTypeEnum {
  EXTERNAL_EDUCATION_APPLICATION_SUBMITTED = '1',
  EXTERNAL_EDUCATION_APPLICATION_APPROVED = '2',
  EXTERNAL_EDUCATION_APPLICATION_DENIED = '3',
  COORDINATOR_ADDED = '4',
  COORDINATOR_REMOVED = '5',
  SIGNED_FINANCING_AGREEMENT_REQUESTED = '6',
  FILE_ATTACHED = '7',
  EDUCATION_COMPLETED = '8',
  SURVEY_SUBMITTED = '9',
  EXTERNAL_EDUCATION_APPLICATION_RETURNED_TO_USER = '10',
  EXTERNAL_EDUCATION_APPLICATION_USER_CANCELLED = '11',
  MUTUALLY_SIGNED_FINANCING_AGREEMENT_REQUESTED = '12',
  SIGNED_CONTRACT_ANNEX_REQUESTED = '13',
  MUTUALLY_SIGNED_CONTRACT_ANNEX_REQUESTED = '14',
  COORDINATOR_REVOKED_APPROVAL = '15',
  EXTERNAL_EDUCATION_APPLICATION_CHANGES_SUBMITTED = '16',
  EXTERNAL_EDUCATION_TEMPLATE_CHANGED = '17',
  CONSULTATION_REQUESTED = '18',
  CONSULTATION_TERMINATED = '19',
  CONSULTATION_COMPLETED = '20',
  CONTRACT_REGENERATION = '21',
  EDUCATION_PROVIDER_REQUESTED_PAYMENT = '22',
}

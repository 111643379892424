import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { ICourse } from '@src/model/course/Course';
import { IComputerManagedInstruction } from '@src/model/course/scorm/ComputerManagedInstruction';
import { IShareableContentObject } from '@src/model/course/scorm/ShareableContentObject';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Selectors

/** Returns scorm course from store. */
const getScormCourseContent = (store: any): ICourse => store.scormCourseData;

/** Returns list of scorm course lectures from store. */
const getScormCourseLectureList = (store: any): IShareableContentObject[] => store.scormCourseLectureList;

/** Returns scorm course lecture CMI from store. */
const getScormLectureCMI = (store: any): IComputerManagedInstruction => store.scormLectureCMI;

// -
// -------------------- Actions

const Actions = {
  SCORM_COURSE_CONTENT_FETCH: 'SCORM_COURSE_CONTENT_FETCH',
  SCORM_COURSE_CONTENT_LOAD: 'SCORM_COURSE_CONTENT_LOAD',
  SCORM_COURSE_CONTENT_CLEAR: 'SCORM_COURSE_CONTENT_CLEAR',
  SCORM_COURSE_LECTURES_LIST_FETCH: 'SCORM_COURSE_LECTURES_LIST_FETCH',
  SCORM_COURSE_LECTURES_LIST_LOAD: 'SCORM_COURSE_LECTURES_LIST_LOAD',
  SCORM_COURSE_LECTURES_LIST_CLEAR: 'SCORM_COURSE_LECTURES_LIST_CLEAR',
  SCORM_COURSE_CMI_FETCH: 'SCORM_COURSE_CMI_FETCH',
  SCORM_COURSE_CMI_LOAD: 'SCORM_COURSE_CMI_LOAD',
  SCORM_COURSE_CMI_CLEAR: 'SCORM_COURSE_CMI_CLEAR',
  SCORM_COURSE_CMI_UPDATE: 'SCORM_COURSE_CMI_UPDATE',
};

/** Fetch scorm course by ID. */
const fetchScormCourseContent = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.SCORM_COURSE_CONTENT_FETCH,
    payload: { id },
  };
};

/** Load scorm course to store. */
const loadScormCourseContent = (data: ICourse): IPayloadAction<ICourse> => {
  return {
    type: Actions.SCORM_COURSE_CONTENT_LOAD,
    payload: data,
  };
};

/** Clear scorm course from store. Eg. when leaving view. */
const clearScormCourseContent = (): ILemonAction => {
  return {
    type: Actions.SCORM_COURSE_CONTENT_CLEAR,
  };
};

/** Fetch scorm course lecture list. */
const fetchScormCourseLectureList = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.SCORM_COURSE_LECTURES_LIST_FETCH,
    payload: {
      id,
    },
  };
};

/** Load scorm course lecture list to store. */
const loadScormCourseLectureList = (data: ICollectionData<IShareableContentObject>): IPayloadAction<ICollectionData<IShareableContentObject>> => {
  return {
    type: Actions.SCORM_COURSE_LECTURES_LIST_LOAD,
    payload: data,
  };
};

/** Clear scorm course lecture list from store. Eg. when leaving list view. */
const clearScormCourseLectureList = (): ILemonAction => {
  return {
    type: Actions.SCORM_COURSE_LECTURES_LIST_CLEAR,
  };
};

/** Fetch scorm lecture CMI by lecture ID. */
const fetchScormLectureCMI = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.SCORM_COURSE_CMI_FETCH,
    payload: { id },
  };
};

/** Load scorm lecture CMI to store. */
const loadScormLectureCMI = (data: ICourse): IPayloadAction<ICourse> => {
  return {
    type: Actions.SCORM_COURSE_CMI_LOAD,
    payload: data,
  };
};

/** Clear scorm lecture CMI from store. Eg. when leaving view. */
const clearScormLectureCMI = (): ILemonAction => {
  return {
    type: Actions.SCORM_COURSE_CMI_CLEAR,
  };
};


/** Update CMI by lecture ID. */
const updateScormLectureCMI = (id: string, data: IComputerManagedInstruction): IPayloadAction<IIdDataPayload<IComputerManagedInstruction>> => {
  return {
    type: Actions.SCORM_COURSE_CMI_UPDATE,
    payload: { id, data },
  };
};


// -
// -------------------- Side-effects

const fetchScormCourseEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SCORM_COURSE_CONTENT_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadScormCourseContent(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SCORM_COURSE_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    // tslint:disable-next-line: no-duplicate-string
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching scorm course', error);
      return o;
    })
  );
};

const fetchScormCourseLectureListEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SCORM_COURSE_LECTURES_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .fetchSubobject(id, 'shareablecontentobjects')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadScormCourseLectureList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching scorm course lecture list', error);
      return o;
    })
  );
};

const fetchScormLectureCMIEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SCORM_COURSE_CMI_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('ShareableContentObject')
        .fetchSubobject(id, 'computermanagedinstruction')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadScormLectureCMI(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching scorm lecture CMI', error);
      return o;
    })
  );
};

const updateScormLectureCMIEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IComputerManagedInstruction>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SCORM_COURSE_CMI_UPDATE;
    }),

    // startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ShareableContentObject')
        .updateSubobject(payload.id, 'computermanagedinstruction', payload.data)
        .pipe(trackAction(action));
    }),

    // stopGlobalProgress(),

    map((data) => {
      return loadScormLectureCMI(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_UPDATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating lecture CMI', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const scormCourseData = (state: ICourse | null = null, action: IPayloadAction<ICourse>) => {
  if (action.type === Actions.SCORM_COURSE_CONTENT_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SCORM_COURSE_CONTENT_CLEAR) {
    return null;
  }

  return state;
};

const scormCourseLectureList = (state: IShareableContentObject[] | null = null, action: IPayloadAction<IShareableContentObject[]>) => {
  if (action.type === Actions.SCORM_COURSE_LECTURES_LIST_LOAD) {
    return action.payload;
  } else if (action.type === Actions.SCORM_COURSE_LECTURES_LIST_CLEAR) {
    return null;
  }

  return state;
};

const scormLectureCMI = (state: IComputerManagedInstruction | null = null, action: IPayloadAction<IComputerManagedInstruction>) => {
  if (action.type === Actions.SCORM_COURSE_CMI_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SCORM_COURSE_CMI_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const ScormCourseViewBusinessStore = {
  actions: {
    fetchScormCourseContent,
    clearScormCourseContent,
    fetchScormCourseLectureList,
    clearScormCourseLectureList,
    fetchScormLectureCMI, loadScormLectureCMI, clearScormLectureCMI, updateScormLectureCMI,
  },

  selectors: {
    getScormCourseContent,
    getScormCourseLectureList,
    getScormLectureCMI,
  },

  effects: {
    fetchScormCourseEffect,
    fetchScormCourseLectureListEffect,
    fetchScormLectureCMIEffect, updateScormLectureCMIEffect,
  },

  reducers: {
    scormCourseData,
    scormCourseLectureList,
    scormLectureCMI,
  },
};

// --
// export business store
export default ScormCourseViewBusinessStore;

import AdditionalCourseListView from '@src/components/course/list/additional/AdditionalCourseListView';
import { ICourse } from '@src/model/course/Course';
import { ICollectionData } from '@src/service/business/common/types';
import AdditionalCourseListBusinessStore, { IAdditionalCourseListFilter } from '@src/service/business/courses/additionalCourseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import memoizeOne from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

interface IAdditionalCourseListContainerOwnProps {
  course: ICourse;
  getPath: (course: ICourse) => string;
}

interface IAdditionalCourseListContainerStateProps {
  courseList: ICollectionData<ICourse>;
}

interface IAdditionalCourseListContainerDispatchProps {
  fetchAdditionalCourseList: (filter: IAdditionalCourseListFilter, page: number, size: number, sort: string[]) => void;
  clearAdditionalCourseList: () => void;
}
type IAdditionalCourseListContainerProps = IAdditionalCourseListContainerOwnProps & IAdditionalCourseListContainerStateProps & IAdditionalCourseListContainerDispatchProps;

// -- Component
// ----------

/** Display additional relevant courses in regard to course group */
class AdditionalCourseListContainer extends React.Component<IAdditionalCourseListContainerProps> {
  // remove the course we are currently viewing from fetched list
  getAdditionalCourses = memoizeOne((courseList: ICourse[]) => courseList.filter((course) => course.id !== this.props.course.id));

  componentDidMount = () => {
    this.fetchAdditionalCourseList();
  };

  componentDidUpdate = (prevProps: IAdditionalCourseListContainerProps) => {
    if (this.props.course !== prevProps.course) {
      this.fetchAdditionalCourseList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearAdditionalCourseList();
  };

  render = () => {
    const additionalCourses = this.props.courseList ? this.getAdditionalCourses(this.props.courseList.content) : [];

    return <AdditionalCourseListView courseList={additionalCourses} getPath={this.props.getPath} />;
  };

  private getCourseGroupsIds = () => { // extract id values from courseGroups obj
    return this.props.course.courseGroups.map((cg) => cg.id);
  };

  private fetchAdditionalCourseList = (page: number = 0, size: number = AppConfigService.getValue('api.paging.gridRowPageSize'), sort: string[] = []) => {
    const filter = { courseGroups: this.getCourseGroupsIds() };
    this.props.fetchAdditionalCourseList(filter, page, size, sort);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdditionalCourseListContainerOwnProps): IAdditionalCourseListContainerStateProps => ({
  courseList: AdditionalCourseListBusinessStore.selectors.getList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdditionalCourseListContainerDispatchProps => ({
  fetchAdditionalCourseList: (filter: IAdditionalCourseListFilter, page: number, size: number, sort: string[]) => dispatch(AdditionalCourseListBusinessStore.actions.fetchList({ filter, page, size, sort })),
  clearAdditionalCourseList: () => dispatch(AdditionalCourseListBusinessStore.actions.clearList()),
});

export default connect<IAdditionalCourseListContainerStateProps, IAdditionalCourseListContainerDispatchProps, IAdditionalCourseListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(AdditionalCourseListContainer);

import React, { useMemo } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { EmploymentStatusEnum, IEmploymentStatus } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import DataSelect from '@src/components/common/select/DataSelect';

// -- Prop types
// ----------
export interface IExternalEducationApplicationEmploymentStatusPickerOwnProps {
  value?: IEmploymentStatus;
  onChange?: (data: Partial<IEmploymentStatus> | Partial<IEmploymentStatus>[]) => void;
  placeholder?: string;
}
type IExternalEducationApplicationEmploymentStatusPickerProps = IExternalEducationApplicationEmploymentStatusPickerOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display employment status dropdown */
const ExternalEducationApplicationEmploymentStatusPicker: React.FC<IExternalEducationApplicationEmploymentStatusPickerProps> = (props: IExternalEducationApplicationEmploymentStatusPickerProps) => {
  const options = useMemo(() => {
    return Object.keys(EmploymentStatusEnum).map((item: string) => ({
      value: EmploymentStatusEnum[item as keyof typeof EmploymentStatusEnum],
      data: { id: EmploymentStatusEnum[item as keyof typeof EmploymentStatusEnum] },
      key: EmploymentStatusEnum[item as keyof typeof EmploymentStatusEnum],
      dataTestId: `timun-externalEducationApplicationEmploymentStatusPicker__selectOption-${item}`,
      label: props.translate(`EXTERNAL_EDUCATION_APPLICATION.${item}_LABEL`),
    }));
  }, []);
  return <DataSelect<IEmploymentStatus> allowClear={true} value={props.value} onChange={props.onChange} placeholder={props.placeholder} options={options} dataTestId={'timun-externalEducationApplicationFilter__employmentSelect'} />;
};

export default withLocalize<IExternalEducationApplicationEmploymentStatusPickerOwnProps>(ExternalEducationApplicationEmploymentStatusPicker as any);

import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import IIdRef from '@src/model/common/IdRef';
import { IUserGroupCourse } from '@src/model/course/UserGroupCourse';
import { IFile } from '@src/model/file/File';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { IUserGroupMember } from '@src/model/usergroup/UserGroupMember';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface IUserGroupMemberListFilter {}

export type IUserGroupMemberCreatePayload = Exclude<IUserGroupMember, 'id'>;

export interface IUserGroupCreatePayload {
  name: string;
  description: string;
  groupType: IIdRef<UserGroupTypeEnum>;
  parent?: IIdRef<string>;
}

export interface IUserGroupCoursePayload {
  course: IIdRef<string>;
  workPositions?: IIdRef<string>[];
}

// List filter ID
const USER_GROUP_MEMBER_LIST_FILTER = '@@USER_GROUP_MEMBER_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns UserGroup from store. */
const getUserGroup = (store: any): IUserGroup => store.userGroup;

/** Returns UserGroupMember list from store. */
const getUserGroupMemberList = (store: any): ICollectionData<IUserGroupMember> => store.userGroupMemberList;

/** Returns UserGroupMember list filter. */
const getUserGroupMemberListFilter = (store: any): IUserGroupMemberListFilter => ListFilterBusinessStore.selectors.getListFilter(store, USER_GROUP_MEMBER_LIST_FILTER);

/** Returns courses workpositions for user group from store. */
const getUserGroupWorkPositionCourseList = (store: any): ICollectionData<IUserGroupCourse> => store.userGroupWorkPositionCourseList;

// -
// -------------------- Actions

const Actions = {
  USER_GROUP_FETCH: 'USER_GROUP_FETCH',
  USER_GROUP_LOAD: 'USER_GROUP_LOAD',
  USER_GROUP_CLEAR: 'USER_GROUP_CLEAR',
  USER_GROUP_CREATE: 'USER_GROUP_CREATE',
  USER_GROUP_UPDATE: 'USER_GROUP_UPDATE',
  USER_GROUP_DELETE: 'USER_GROUP_DELETE',
  USER_GROUP_LEAVE: 'USER_GROUP_LEAVE',
  USER_GROUP_JOIN: 'USER_GROUP_JOIN',
  USER_GROUP_COVER_IMAGE_UPLOAD: 'USER_GROUP_COVER_IMAGE_UPLOAD',
  USER_GROUP_MEMBER_LIST_FETCH: 'USER_GROUP_MEMBER_LIST_FETCH',
  USER_GROUP_MEMBER_LIST_LOAD: 'USER_GROUP_MEMBER_LIST_LOAD',
  USER_GROUP_MEMBER_LIST_CLEAR: 'USER_GROUP_MEMBER_LIST_CLEAR',
  USER_GROUP_COURSE_WORKPOSITION_LIST_FETCH: 'USER_GROUP_COURSE_WORKPOSITION_LIST_FETCH',
  USER_GROUP_COURSE_WORKPOSITION_LIST_UPDATE: 'USER_GROUP_COURSE_WORKPOSITION_LIST_UPDATE',
  USER_GROUP_COURSE_WORKPOSITION_LIST_LOAD: 'USER_GROUP_COURSE_WORKPOSITION_LIST_LOAD',
  USER_GROUP_COURSE_WORKPOSITION_LIST_CLEAR: 'USER_GROUP_COURSE_WORKPOSITION_LIST_CLEAR',
  USER_GROUP_MEMBERS_ADD: 'USER_GROUP_MEMBERS_ADD',
  USER_GROUP_MEMBERS_UPDATE: 'USER_GROUP_MEMBERS_UPDATE',
  USER_GROUP_MEMBERS_DELETE: 'USER_GROUP_MEMBERS_DELETE',
  USER_GROUP_SYNC_LDAP: 'USER_GROUP_SYNC_LDAP',
};

/** Fetch UserGroup by ID. */
const fetchUserGroup = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_GROUP_FETCH,
    payload: {
      id,
    },
  };
};

/** Load UserGroup to store. */
const loadUserGroup = (data: IUserGroup): IPayloadAction<IUserGroup> => {
  return {
    type: Actions.USER_GROUP_LOAD,
    payload: data,
  };
};

/** Clear user group from store. Eg. when leaving view. */
const clearUserGroup = (): ILemonAction => {
  return {
    type: Actions.USER_GROUP_CLEAR,
  };
};

/** Create UserGroup */
const createUserGroup = (data: IUserGroupCreatePayload): IPayloadAction<IUserGroupCreatePayload> => {
  return {
    type: Actions.USER_GROUP_CREATE,
    payload: data,
  };
};

/** Update UserGroup */
const updateUserGroup = (data: IUserGroup): IPayloadAction<IUserGroupCreatePayload> => {
  return {
    type: Actions.USER_GROUP_UPDATE,
    payload: data,
  };
};

/** Update UserGroup */
const deleteUserGroup = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_GROUP_DELETE,
    payload: {
      id,
    },
  };
};

/** Leave UserGroup */
const leaveUserGroup = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_GROUP_LEAVE,
    payload: {
      id,
    },
  };
};

/** Join UserGroup */
const joinUserGroup = (accessCode: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_GROUP_JOIN,
    payload: {
      id: accessCode,
    },
  };
};

/** Add UserGroup cover image. */
const uploadUserGroupCoverImage = (id: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.USER_GROUP_COVER_IMAGE_UPLOAD,
    payload: {
      id,
      data,
    },
  };
};

/** Fetch UserGroupMember list by id and filter */
const fetchUserGroupMemberList = (userGroupId: string, params: ICollectionFetchPayload<IUserGroupMemberListFilter>): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IUserGroupMemberListFilter>>> => {
  return {
    type: Actions.USER_GROUP_MEMBER_LIST_FETCH,
    payload: {
      id: userGroupId,
      data: params,
    },
  };
};

/** Load UserGroupMember list to the store */
const loadUserGroupMemberList = (data: ICollectionData<IUserGroupMember>): IPayloadAction<ICollectionData<IUserGroupMember>> => {
  return {
    type: Actions.USER_GROUP_MEMBER_LIST_LOAD,
    payload: data,
  };
};

/** Clear UserGroupMember list from store. Eg. when leaving view. */
const clearUserGroupMemberList = (): ILemonAction => {
  return {
    type: Actions.USER_GROUP_MEMBER_LIST_CLEAR,
  };
};

/** Clear UserGroupMember list filter from store. Eg. when leaving view. */
const clearUserGroupMemberListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(USER_GROUP_MEMBER_LIST_FILTER);
};

/** Fetch user group course workposition list. */
const fetchUserGroupWorkPositionCourseList = (data: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_GROUP_COURSE_WORKPOSITION_LIST_FETCH,
    payload: data,
  };
};

/** Updates courses workposition for user group. */
const updateUserGroupWorkPositionCourseList = (data: IIdDataPayload<IUserGroupCoursePayload[]>): IPayloadAction<IIdDataPayload<IUserGroupCoursePayload[]>> => {
  return {
    type: Actions.USER_GROUP_COURSE_WORKPOSITION_LIST_UPDATE,
    payload: data,
  };
};

/** Load user group coursesworkposition to store. */
const loadUserGroupWorkPositionCourseList = (data: ICollectionData<IUserGroupCourse>): IPayloadAction<ICollectionData<IUserGroupCourse>> => {
  return {
    type: Actions.USER_GROUP_COURSE_WORKPOSITION_LIST_LOAD,
    payload: data,
  };
};

const clearUserGroupWorkPositionCourseList = (): ILemonAction => {
  return {
    type: Actions.USER_GROUP_COURSE_WORKPOSITION_LIST_CLEAR,
  };
};

/** Add userGroupMembers to target group */
const addUserGroupMembers = (groupId: string, data: IUserGroupMemberCreatePayload[]): IPayloadAction<IIdDataPayload<IUserGroupMemberCreatePayload[]>> => {
  return {
    type: Actions.USER_GROUP_MEMBERS_ADD,
    payload: {
      id: groupId,
      data,
    },
  };
};

/** update userGroupMembers in target group */
const updateUserGroupMembers = (groupId: string, data: IUserGroupMember): IPayloadAction<IIdDataPayload<IUserGroupMember>> => {
  return {
    type: Actions.USER_GROUP_MEMBERS_UPDATE,
    payload: {
      id: groupId,
      data,
    },
  };
};

/** remove userGroupMembers from target group */
const removeUserGroupMembers = (groupId: string, data: IUserGroupMember[]): IPayloadAction<IIdDataPayload<IUserGroupMember[]>> => {
  return {
    type: Actions.USER_GROUP_MEMBERS_DELETE,
    payload: {
      id: groupId,
      data,
    },
  };
};

const userGroupLdapSync = () => {
  return {
    type: Actions.USER_GROUP_SYNC_LDAP,
  };
};

// -
// -------------------- Side-effects

const fetchUserGroupEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserGroup(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching UserGroup', error);
      return o;
    })
  );
};

const createUserGroupEffect = (action$: Observable<IPayloadAction<IUserGroupCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating user group', error);
      return o;
    })
  );
};

const updateUserGroupEffect = (action$: Observable<IPayloadAction<IUserGroup>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating user group', error);
      return o;
    })
  );
};

const deleteUserGroupEffect = (action$: Observable<IPayloadAction<IIdDataPayload<string>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('UserGroup')
        .deleteEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting user group', error);
      return o;
    })
  );
};

const leaveUserGroupEffect = (action$: Observable<IPayloadAction<IIdDataPayload<string>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_LEAVE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('UserGroup')
        .fetchSubobject(id, 'leave')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error leaving user group', error);
      return o;
    })
  );
};

const joinUserGroupEffect = (action$: Observable<IPayloadAction<IIdDataPayload<string>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_JOIN;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const accessCode = action.payload.id;

      return EntityApiServiceRegistry.getService('UserGroup')
        .createMethod('join', { value: accessCode })
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error joining user group', error);
      return o;
    })
  );
};

const uploadUserGroupCoverImageEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_COVER_IMAGE_UPLOAD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .createSubobject(id, 'cover', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error uploading cover image', error);
      return o;
    })
  );
};

const fetchUserGroupMemberListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IUserGroupMemberListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_MEMBER_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .fetchSubentityList(id, 'member', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserGroupMemberList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching UserGroupMember list', error);
      return o;
    })
  );
};

const fetchUserGroupWorkPositionCourseListEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_COURSE_WORKPOSITION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('UserGroup')
        .fetchSubentityList(id, 'courseWorkPositionRelation')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserGroupWorkPositionCourseList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching user group course workposition list', error);
      return o;
    })
  );
};

const updateUserGroupWorkPositionCourseListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IUserGroupCoursePayload[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_COURSE_WORKPOSITION_LIST_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .updateSubentityList(id, 'courseWorkPositionRelation', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => ({ message: LocalizeService.translate('COMMON.EDITED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS })),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating user group course workposition list', error);
      return o;
    })
  );
};

const addUserGroupMembersEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IUserGroupMemberCreatePayload[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_MEMBERS_ADD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .createSubentityList(id, 'member', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP_ERROR_MESSAGE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error adding userGroupMembers', error);
      return o;
    })
  );
};

const updateUserGroupMembersEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IUserGroupMember>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_MEMBERS_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .updateSubentityList(id, 'member', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating userGroupMembers', error);
      return o;
    })
  );
};

const removeUserGroupMembersEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IUserGroupMember[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_MEMBERS_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .deleteSubentityList(id, 'member', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error removing userGroupMembers', error);
      return o;
    })
  );
};

const userGroupLdapSyncEffect = (action$: Observable<ILemonAction>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_SYNC_LDAP;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      return EntityApiServiceRegistry.getService('UserGroup')
        .createMethod('syncldap')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LDAP.ERROR', 'GENERAL_MESSAGE.GENERAL_CREATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error syncing LDAP', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const userGroup = (state: IUserGroup | null = null, action: IPayloadAction<IUserGroup>) => {
  if (action.type === Actions.USER_GROUP_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_GROUP_CLEAR) {
    return null;
  }

  return state;
};

const userGroupMemberList = (state: ICollectionData<IUserGroupMember> | null = null, action: IPayloadAction<ICollectionData<IUserGroupMember>>) => {
  if (action.type === Actions.USER_GROUP_MEMBER_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_GROUP_MEMBER_LIST_CLEAR) {
    return null;
  }

  return state;
};

const userGroupWorkPositionCourseList = (state: ICollectionData<IUserGroupCourse> | null = null, action: IPayloadAction<ICollectionData<IUserGroupCourse>>) => {
  if (action.type === Actions.USER_GROUP_COURSE_WORKPOSITION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_GROUP_COURSE_WORKPOSITION_LIST_CLEAR) {
    return null;
  }
  return state;
};

// --
// -------------------- Business Store

export const UserGroupBusinessStore = {
  actions: {
    fetchUserGroup,
    loadUserGroup,
    clearUserGroup,
    createUserGroup,
    updateUserGroup,
    deleteUserGroup,
    leaveUserGroup,
    joinUserGroup,
    uploadUserGroupCoverImage,
    fetchUserGroupMemberList,
    loadUserGroupMemberList,
    clearUserGroupMemberList,
    clearUserGroupMemberListFilter,
    fetchUserGroupWorkPositionCourseList,
    updateUserGroupWorkPositionCourseList,
    loadUserGroupWorkPositionCourseList,
    clearUserGroupWorkPositionCourseList,
    addUserGroupMembers,
    updateUserGroupMembers,
    removeUserGroupMembers,
    userGroupLdapSync,
  },

  selectors: {
    getUserGroup,
    getUserGroupMemberList,
    getUserGroupMemberListFilter,
    getUserGroupWorkPositionCourseList,
  },

  effects: {
    fetchUserGroupEffect,
    fetchUserGroupMemberListEffect,
    createUserGroupEffect,
    updateUserGroupEffect,
    uploadUserGroupCoverImageEffect,
    deleteUserGroupEffect,
    leaveUserGroupEffect,
    joinUserGroupEffect,
    fetchUserGroupWorkPositionCourseListEffect,
    updateUserGroupWorkPositionCourseListEffect,
    addUserGroupMembersEffect,
    updateUserGroupMembersEffect,
    removeUserGroupMembersEffect,
    userGroupLdapSyncEffect,
  },

  reducers: {
    userGroup,
    userGroupMemberList,
    userGroupWorkPositionCourseList,
  },
};

// --
// export business store

export default UserGroupBusinessStore;

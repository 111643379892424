import { FormComponentProps } from '@ant-design/compatible/lib/form';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { CourseStatusEnum } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import { IPublicCourseListFilter } from '@src/service/business/courses/publicCourseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Input, Row, Select, Form } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';

const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');

const sortItems = [
  {
    id: 'title,asc',
    translationKey: 'COMMON.ACTION_SORT_ASCEND',
  },
  {
    id: 'title,desc',
    translationKey: 'COMMON.ACTION_SORT_DESCEND',
  },
  {
    id: 'createdAt,desc',
    translationKey: 'COMMON.ACTION_SORT_NEWEST',
  },
  {
    id: 'createdAt,asc',
    translationKey: 'COMMON.ACTION_SORT_OLDEST',
  },
];

// -- Prop types
// ----------

interface ICourseListViewFilterOwnProps {
  courseGroups?: ICourseGroup[];
  courseListFilter: ICourseListFilter;
  sortValue?: string[];
  showStatusFilter?: boolean;
  onSortChange?: (sort: string[]) => void;
  onFilterChange?: (data: IPublicCourseListFilter) => void;
}

type ICourseListViewFilterProps = ICourseListViewFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------

/**  */
const CourseListViewFilter: React.FC<ICourseListViewFilterProps> = (props: ICourseListViewFilterProps) => {
  const [form] = Form.useForm();

  const handleStatusChange = useCallback(
    debounce((data: CourseStatusEnum) => {
      props.onFilterChange?.({ ...props.courseListFilter, status: data });
    }, debounceTimeout),
    [props.onFilterChange, props.courseListFilter, debounceTimeout]
  );

  const handleTitleSearch = useCallback(
    (value: string) => {
      if (value.trim()) {
        props.onFilterChange?.({ ...props.courseListFilter, title: value.trim() });
      } else {
        const { title, ...filter } = props.courseListFilter;
        props.onFilterChange?.(filter);
      }
    },
    [props.onFilterChange, props.courseListFilter]
  );

  const handleGroupChange = useCallback(
    (data: string[]) => {
      props.onFilterChange?.({ courseGroups: data });
    },
    [props.onFilterChange]
  );

  return (
    <Form form={form} initialValues={{ title: props.courseListFilter?.title, courseGroup: props.courseListFilter?.courseGroups, status: props.courseListFilter?.status }} layout="vertical">
      <Row gutter={16} className="timun-wrapper__gutterBox--vertical">
        <Col xs={24} sm={12} md={props.showStatusFilter ? 6 : 8}>
          <Form.Item name={'title'} label={props.translate('COMMON.LABEL.NAME')}>
            <Input.Search prefix={<LemonIcon name="search" />} allowClear={true} placeholder={props.translate('COMMON.ACTION_SEARCH')} onSearch={handleTitleSearch} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={props.showStatusFilter ? 6 : 8}>
          <Form.Item name={'courseGroup'} label={props.translate('COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_FORM_LABEL')}>
            <Select mode="multiple" placeholder={props.translate('COURSE_VIEW_PUBLIC.ALL_COURSEGROUPS_LABEL')} allowClear={true} onChange={handleGroupChange} data-test-id="timun-courseListFilter__courseGroupSelect">
              {(props.courseGroups || []).map((group) => {
                return (
                  <Select.Option key={group.id} value={group.id} data-test-id={`timun-courseListFilter__courseGroupSelect_option_${group.id}`}>
                    {group.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        {props.showStatusFilter && (
          <Col xs={24} sm={12} md={6}>
            <Form.Item name={'status'} label={props.translate('COMMON.LABEL.STATUS')}>
              <Select className="full-width" placeholder={props.translate('COURSE_VIEW_PUBLIC.ALL_STATUSES_LABEL')} allowClear={true} onChange={handleStatusChange}>
                {Object.keys(CourseStatusEnum).map((item: string) => (
                  <Select.Option value={CourseStatusEnum[item as keyof typeof CourseStatusEnum]} key={CourseStatusEnum[item as keyof typeof CourseStatusEnum]}>
                    {props.translate(`COURSE.STATUS_TITLE.${item}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={12} md={props.showStatusFilter ? 6 : 8}>
          <Form.Item label={props.translate('COMMON.ACTION_SORT_LABEL')}>
            <Select onChange={props.onSortChange} value={props.sortValue || [sortItems[0].id]} data-test-id="timun-courseListFilter__sortSelect">
              {sortItems.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id} data-test-id={`timun-courseListFilter__sortSelect_option_${item.id}`}>
                    {props.translate(item.translationKey)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<ICourseListViewFilterOwnProps>(CourseListViewFilter as any);

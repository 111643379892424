import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { ITimunTableActionButton } from '@src/components/common/table/types';
import { classNames } from '@src/components/common/util/classNames';
import { IShortInfo } from '@src/model/common/IShortInfo';
import { ICollectionDataCount } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { LangUtils } from '@src/service/util/LangUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Empty, Row, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { get } from 'lodash';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import React from 'react';
import { Link } from 'react-router';

// -- Const
// ----------
const TABLE_CSS_CLASS_BASE = 'timun-table__shortInfoTable';

const Column = Table.Column;
const TABLE_SCROLL_SIZE = AppConfigService.getValue('layout.table.scrollSize');

// -- Prop types
// ----------

export interface ITimunShortInfoTableOwnProps<T> {
  items?: T[];
  className?: string;
  bordered?: boolean;
  paginationData?: ICollectionDataCount;
  titleColumnWidth?: string | number;
  titlePropPath?: string;
  /** render predefined action buttons for add, edit and delete */
  actionButtons?: {
    add?: ITimunTableActionButton<T>;
    edit?: ITimunTableActionButton<T>;
    delete?: ITimunTableActionButton<T>;
  };
  /** base route path if the item needs to be clickable */
  itemBaseRoute?: string;
  paginationConfig?: Exclude<TablePaginationConfig, 'current' | 'total' | 'pageSize'>;
  showPagination?: boolean;
  emptyText?: string;
  customNameRender?: (record: T) => React.ReactNode;
  expandableRow?: (record: T) => React.ReactNode;
  /** render text under the item name */
  rowDescription?: (record: T) => React.ReactNode;
  /** render any columns before the predefined name column */
  renderFrontAdditionalColumns?: () => JSX.Element;
  /** render any columns after the predefined name column */
  renderBackAdditionalColumns?: () => JSX.Element;
  /** render any action buttons after the predefined ones */
  renderAdditionalActions?: () => JSX.Element;
}

type ITimunShortInfoTableProps<T> = ITimunShortInfoTableOwnProps<T>;

// -- Component
// ----------
/** Display customizable table with basic information and actions */
class TimunShortInfoTable<T extends IShortInfo> extends React.Component<ITimunShortInfoTableProps<T>> {
  getTitlePropPath = (): string => {
    return this.props.titlePropPath ?? 'name';
  };

  render() {
    const paginationConfig: TablePaginationConfig | boolean =
      this.props.showPagination && this.props.paginationData
        ? {
            position: ['topCenter', 'bottomCenter'],
            hideOnSinglePage: false,
            showSizeChanger: false,
            current: this.props.paginationData.number + 1,
            total: this.props.paginationData.totalElements,
            pageSize: this.props.paginationData.size,
            responsive: true,
            showTotal: (total, range) => LocalizeService.translate('DATA_TABLE.PAGINATION_SHOW_TOTAL_LABEL', { rangeOne: range[0], rangeTwo: range[1], total }),
            ...this.props.paginationConfig,
          }
        : false;

    return (
      <React.Fragment>
        <Table
          className={this.getTableClassName()}
          rowKey={(record) => record.id}
          dataSource={this.props.items}
          expandedRowRender={this.props.expandableRow}
          expandIcon={this.expandIcon}
          bordered={this.props.bordered ?? true}
          showHeader={false}
          onChange={this.handleTableChange}
          pagination={paginationConfig}
          locale={{
            emptyText: <Empty description={this.props.emptyText ?? LocalizeService.translate('COMMON.MESSAGE_NO_DATA')} />,
          }}
          scroll={{ x: TABLE_SCROLL_SIZE }}
        >
          {/* Front additional columns */}
          {this.props.renderFrontAdditionalColumns?.()}

          <Column
            key="name"
            width={this.props.titleColumnWidth}
            align="left"
            render={(record: T) => (
              <React.Fragment key={record.id}>
                <Row> {this.renderName(record)} </Row>
                {this.props.rowDescription && <Row> {this.props.rowDescription(record)} </Row>}
              </React.Fragment>
            )}
          />

          {/* Back additional columns */}
          {this.props.renderBackAdditionalColumns?.()}

          {/* Action buttons */}
          {this.renderActionColumns?.()}
        </Table>
      </React.Fragment>
    );
  }

  renderActionColumns = (): React.ReactNode => {
    const actionButtons = this.props.actionButtons;
    return (
      <React.Fragment>
        {actionButtons?.add?.showButton != null && (
          <Column key="add" width="5%" align="center" render={(record: T) => this.getPermission(record, actionButtons?.add?.showButton) && <Button key={record.id} title={LocalizeService.translate('COMMON.ACTION_ADD')} icon={<LemonIcon name="plus" />} onClick={() => actionButtons?.add?.onClick?.(record)} />} />
        )}
        {actionButtons?.edit?.showButton != null && (
          <Column
            key="edit"
            width="5%"
            align="center"
            render={(record: T) => this.getPermission(record, actionButtons?.edit?.showButton) && <Button key={record.id} title={LocalizeService.translate('COMMON.ACTION_EDIT')} icon={<LemonIcon name="edit" />} onClick={() => actionButtons?.edit?.onClick?.(record)} {...actionButtons?.edit?.buttonProps} />}
          />
        )}
        {actionButtons?.delete?.showButton != null && (
          <Column
            key="delete"
            width="5%"
            align="center"
            render={(record: T) => this.getPermission(record, actionButtons?.delete?.showButton) && <DeleteElementButton<T> item={record} titlePropPath={this.getTitlePropPath()} onDelete={actionButtons?.delete?.onClick} icon={<LemonIcon name="delete" />} {...actionButtons?.delete?.buttonProps} />}
          />
        )}
        {this.props.renderAdditionalActions?.()}
      </React.Fragment>
    );
  };

  renderName = (record: T): React.ReactNode => {
    const name: React.ReactNode = this.props.customNameRender?.(record) || get(record, this.getTitlePropPath());
    if (this.props.itemBaseRoute) {
      return (
        <Link className="timun-shortInfoTabel__link" to={`${this.props.itemBaseRoute}/${record.id}`}>
          {name}
        </Link>
      );
    } else {
      return name;
    }
  };

  handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<T> | SorterResult<T>[]) => {
    if (pagination.current && this.props.paginationConfig?.onChange && pagination.pageSize) {
      this.props.paginationConfig?.onChange(pagination.current, pagination.pageSize);
    }
    // TODO: set table sortimg string | string[]
    /* if (sorter?.columnKey && typeof sorter.columnKey === 'string' && sorter?.order && this.props.onSortChange) {
      this.props.onSortChange(sorter.columnKey, sorter.order);
    } */
  };

  expandIcon = (props: RenderExpandIconProps<T>): React.ReactNode => {
    if (props.expanded) {
      return <LemonIcon className="timun-shortInfoTable__customIcon" size="xlarge" name="expandUp" onClick={(e: any) => props.onExpand(props.record, e)} />;
    } else {
      return <LemonIcon className="timun-shortInfoTable__customIcon" size="xlarge" name="expandDown" onClick={(e: any) => props.onExpand(props.record, e)} />;
    }
  };

  getPermission = (object: T, permission?: boolean | ((object: T) => boolean)): boolean => {
    if (LangUtils.isFunction(permission)) {
      return permission(object);
    }
    return permission ?? false;
  };

  getTableClassName = (): string => {
    return classNames({
      [TABLE_CSS_CLASS_BASE]: true,
      [this.props.className || '']: true,
    });
  };
}

export default TimunShortInfoTable;

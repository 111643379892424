import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input, Modal, Form } from 'antd';
import React, { useCallback } from 'react';

const NAME_LENGTH_MAX = AppConfigService.getValue('userValidation.nameLengthMax');

// -- Prop types
// ----------

export interface INameInputFormOwnProps {
  title: string; // sets name of the modal title
  defaultName?: string; // if the component is used for renaming, old name can be passed for initial display
  onSubmit: (name: string) => void;
  onCancel: () => void;
}

export interface INameInput {
  name: string;
}

type INameInputFormProps = INameInputFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------

/** Component for entering the name of a newly created item */
const NameInputForm: React.FC<INameInputFormProps> = (props: INameInputFormProps) => {
  const [form] = Form.useForm();

  const handleFinish = useCallback((values: INameInput) => props.onSubmit(values.name), [props.onSubmit]);

  const handleCancel = useCallback(() => {
    if (form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: props.onCancel,
        title: props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      props.onCancel();
    }
  }, [props.onCancel, form.isFieldsTouched]);

  return (
    <Modal maskClosable={false} title={props.title} visible={true} className="lemon-modal__modal--sm" onOk={form.submit} onCancel={handleCancel} okText={props.translate('COMMON.ACTION_SAVE')} cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form layout="vertical" hideRequiredMark={true} onFinish={handleFinish} form={form}>
        <Form.Item
          name="name"
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
          ]}
          label={props.translate('COMMON.LABEL.NAME')}
          initialValue={props.defaultName}
        >
          <Input maxLength={NAME_LENGTH_MAX} autoFocus={true} data-test-id={`timun-nameInputModal__nameInput`} onPressEnter={form.submit} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withLocalize<INameInputFormOwnProps>(NameInputForm as any);

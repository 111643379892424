import { Col, Row } from 'antd';
import React from 'react';

import AppLogoContainer from '@src/components/app/layout/AppLogoContainer';
import MenuControlsContainer from '@src/components/app/layout/MenuControlsContainer';
import AppNavigation from '@src/components/app/navigation/AppNavigation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AppSecondaryLogoContainer from '@src/components/app/layout/AppSecondaryLogoContainer';

// -- Prop types
// ----------
export interface IAppHeaderPublicProps {}
interface IAppHeaderStateProps {}
type IAppHeaderProps = IAppHeaderPublicProps & IWithLocalizeOwnProps & IAppHeaderStateProps;

// --
// ----- Component
const AppHeader: React.FC<IAppHeaderProps> = (props) => {
  return (
    <Row className="lemon-appLayout__headerContent" align="middle" justify="end" wrap={false}>
      <Col flex="none" className="lemon-application__logoContainer">
        <AppLogoContainer />
      </Col>

      <Col flex="auto" className="lemon-application__headerAppInfo">
        <AppNavigation />
      </Col>

      <Col>
        <AppSecondaryLogoContainer />
      </Col>

      <Col flex="none" className="lemon-application__userMenuContainer">
        <MenuControlsContainer />
      </Col>
    </Row>
  );
};

export default withLocalize<IAppHeaderPublicProps>(AppHeader as any);

import { message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { Observable } from 'rxjs';

import { ICourse } from '@src/model/course/Course';
import { IFile } from '@src/model/file/File';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import CourseApiService from '@src/service/business/courses/CourseApiService';
import AuthTokenManager from '@src/service/util/AuthTokenManager';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

/** Helper utils for handling file uploads. */
export class FileUploadHelper {
  /**
   * Common upload props.
   *
   * @deprecated FileUpload component uses custom request which has no use of these props. See `HttpClient.uploadFile()` for details.
   */
  static getUploadDefaultProps = (): UploadProps => {
    return {
      action: UrlBuilderFactory.createApiFileBuilder().build(),
      headers: {
        // tslint:disable-next-line: object-literal-key-quotes
        authorization: `Bearer ${AuthTokenManager.getToken()}`,
        // remove X-Requested-With header because CORS complains it is not in header
        // but this generates an error: Type 'null' is not assignable to type 'string' because of --strictNullChecks
        // TODO: open issue on https://github.com/ant-design/ant-design/issues
        'X-Requested-With': null as any,
      },
    };
  };

  /** Upload file blob to API files resource. Can be used with drag'n'drop, editors, ... and other components that read entire file into a blob. */
  static uploadFile(file: File, fileName: string, onUploadProgress?: (event: { percent: number; }) => void): Observable<IFile> {
    const formData = new FormData();
    formData.append('file', file, fileName);

    return EntityApiServiceRegistry.getService('File').createEntity(formData, { onUploadProgress });
  }

  /** Upload and import SCORM archive to API. */
  static importScorm(file: File, fileName: string, onUploadProgress?: (event: { percent: number; }) => void): Observable<ICourse> {
    const formData = new FormData();
    formData.append('file', file, fileName);

    return EntityApiServiceRegistry.getService<CourseApiService>('Course').scormImport(formData, { onUploadProgress });
  }

  static handleAntdUploadChange = <T>(info: UploadChangeParam<UploadFile<T>>, onUploadSuccess?: (file: T) => any) => {
    const file = info.file;
    const filename = file.name;
    if (file.status === 'done') {
      if (onUploadSuccess && file.response) {
        onUploadSuccess(file.response);
      }
      message.success(LocalizeService.translate('FILE_UPLOAD.FILE_ADDED_MESSAGE', { filename }));
    } else if (file.status === 'error') {
      message.error(LocalizeService.translate('FILE_UPLOAD.FILE_UPLOAD_ERROR_MESSAGE', { filename }));
    } else if (file.status === 'removed') {
      // TODO: it gets to status removed when BE refuses upload
      message.info(LocalizeService.translate('FILE_UPLOAD.FILE_REMOVED_MESSAGE', { filename }));
    }
  };
}

import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserRegistrationFormModal from '@src/components/user/registration/UserRegistrationFormModal';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData } from '@src/service/business/common/types';
import { IFileListsByType, mapFileListsByType } from '@src/service/business/files/util';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import { UserBusinessStore } from '@src/service/business/user/UserBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { StaticPageRoutingHelper } from '@src/service/util/StaticPageRoutingHelper';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------

// --
// ----- Prop types
interface IUserRegistrationContainerOwnProps { }

interface IUserRegistrationContainerStateProps {
  currentUser: IUserInfo;
  userFiles: ICollectionData<IFile>;
}

interface IUserRegistrationContainerDispatchProps {
  fetchUserFileList: (id: string, page: number, size: number, sort: string[]) => void;
  clearUserFileList: () => void;
  addUserFile: (id: string, data: IFile[]) => ITrackableAction;
  removeUserFile: (id: string, data: IFile[]) => ITrackableAction;
  updateUser: (user: IUserInfo) => ITrackableAction;
}

type IUserRegistrationContainerProps = IUserRegistrationContainerOwnProps & IUserRegistrationContainerStateProps & IWithLocalizeOwnProps & IUserRegistrationContainerDispatchProps & WithRouterProps;

// --
// ----- Component
class UserRegistrationContainer extends React.Component<IUserRegistrationContainerProps> {
  componentDidMount() {
    this.fetchUserFileList();
  }

  render = () => {
    if (this.props.currentUser) {
      return <UserRegistrationFormModal
        user={this.props.currentUser}
        onSubmit={this.handleFormSubmit}
        onCancel={this.handleFormCancel}
        userFiles={this.getUserFiles()}
        onFileUpload={this.handleUploadFile} />;
    } else {
      return null;
    }
  };

  // private onAvatarSubmit = (data: IFile) => {
  //   this.props.createUserAvatar(this.props.currentUser.id, data, {
  //     // success: () => {
  //     //   this.fetchUser();
  //     // },
  //   });
  // };
  getUserFiles = (): IFileListsByType | undefined => {
    if (this.props.userFiles) {
      return mapFileListsByType(this.props.userFiles.content);
    }
    return;
  };

  handleUploadFile = (data: IFile[]) => {
    this.props.addUserFile(this.props.currentUser.id, data).track().subscribe(
      () => this.fetchUserFileList()
    );
  };

  handleRemoveFile = (data: IFile[]) => {
    this.props.removeUserFile(this.props.currentUser.id, data).track().subscribe(
      () => {
        this.fetchUserFileList();
      }
    );
  };

  private handleFormSubmit = (user: IUserInfo) => {
    this.props
      .updateUser(user)
      .track()
      .subscribe(() => {
        StaticPageRoutingHelper.routeToAuthDefaultPage();
      });
  };

  private handleFormCancel = () => {
    this.props.router.push(AppConfigService.getValue('routing.publicDefaultRoute'));
  };

  private fetchUserFileList = () => {
    // fetch all files
    this.props.fetchUserFileList(this.props.currentUser.id, 0, 1000, ['']);
  };
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserRegistrationContainerOwnProps): IUserRegistrationContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  userFiles: UserBusinessStore.selectors.getUserFileList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IUserRegistrationContainerOwnProps): IUserRegistrationContainerDispatchProps => ({
  updateUser: (user: IUserInfo) => dispatch(createTrackableAction(UserBusinessStore.actions.updateUser(user))),
  fetchUserFileList: (id: string, page: number, size: number, sort: string[]) => dispatch(UserBusinessStore.actions.fetchUserFileList(id, page, size, sort)),
  clearUserFileList: () => dispatch(UserBusinessStore.actions.clearUserFileList()),
  addUserFile: (id: string, data: IFile[]) => createTrackableAction(dispatch(UserBusinessStore.actions.addUserFiles(id, data))),
  removeUserFile: (id: string, data: IFile[]) => createTrackableAction(dispatch(UserBusinessStore.actions.removeUserFiles(id, data))),
});

export default connect<IUserRegistrationContainerStateProps, IUserRegistrationContainerDispatchProps, IUserRegistrationContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(UserRegistrationContainer as any)));

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import AddElementLink from '@src/components/course/update/AddElementLink';
import { CourseCompletionStatusEnum, CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { ILectureInfo } from '@src/model/course/Lecture';
import { ICourseLectureListElement } from '@src/model/course/LectureGroup';
import AppConfigService from '@src/service/common/AppConfigService';
import { createEvent } from '@src/service/util/event/lemonEvent';
import { createTrackableEvent, ITrackableEvent } from '@src/service/util/event/trackEvent';
import { Button, Layout, Menu, Tooltip } from 'antd';
import React, { ReactElement } from 'react';
import { Link, withRouter } from 'react-router';

// -- Const
// ----------

const COURSE_ABOUT_ROUTE = AppConfigService.getValue('components.courses.courseAboutRouterProp');
const COURSE_NOTES_ROUTE = AppConfigService.getValue('components.courses.courseNotesRouterProp');
const COURSE_EXAM_TEMPLATE_ROUTE = AppConfigService.getValue('components.courses.courseExamTemplateRouterProp');
const COURSE_EXAM_ROUTE = AppConfigService.getValue('components.courses.courseExamRouterProp');
const COURSE_CERTIFICATE_ROUTE = AppConfigService.getValue('components.courses.courseCertificateRouterProp');

const { SubMenu, Item } = Menu;
const { Content } = Layout;

// -- Prop types
// ----------

export interface ICourseViewSiderOwnProps {
  course?: ICourse;
  lectureList: ICourseLectureListElement[];
  selectedLecture: string;
  showExamInstanceList: boolean;
  canEdit?: boolean;
  previewMode?: boolean;
  adminMode?: boolean;
  canSeeExamTemplate: boolean;
  onLectureAdd?: (groupId: string, title: string) => void;
  onLectureGroupAdd?: (title: string, event: ITrackableEvent<string>) => void;
  onCreateExamTemplate?: (title: string) => void;
}

type ICourseViewSiderProps = ICourseViewSiderOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface ICourseViewSiderState {
  openSubmenu: string[];
  lectureList: ReactElement[];
}

// -- Component
// ----------

/** view component to display the menu with lecture list in the course view */
class CourseViewSider extends React.Component<ICourseViewSiderProps, ICourseViewSiderState> {
  state: ICourseViewSiderState = {
    openSubmenu: [],
    lectureList: [],
  };

  componentDidMount = () => {
    // initial list update
    this.updateList();
  };

  componentDidUpdate = (prevProps: ICourseViewSiderProps) => {
    if (this.props !== prevProps) {
      if (this.props.lectureList !== prevProps.lectureList || this.props.course !== prevProps.course) {
        this.updateList();
      }
      if (this.props.selectedLecture !== prevProps.selectedLecture) {
        if (CourseHelperUtils.isLecture(this.props.selectedLecture)) {
          this.updateOpenSubmenu();
        } else {
          this.setState({
            openSubmenu: [],
          });
        }
      }
    }
  };

  render = () => {
    const disabledCourseAbout = this.props.course == null;
    const disabledExamInstance = this.props.course && this.props.course.progress && this.props.course.progress.completedLessonsNumber !== this.props.course.progress.lessonsNumber;
    const disabledCourseCertificate = this.props.course?.progress?.completionStatus.id !== CourseCompletionStatusEnum.COMPLETED;
    return (
      <Layout>
        <Content>
          <Menu className="timun-courseView__siderMenu" onOpenChange={this.handleOpenChange} mode="inline" selectedKeys={[this.props.selectedLecture]} openKeys={this.state.openSubmenu}>
            <Item key={COURSE_ABOUT_ROUTE} disabled={disabledCourseAbout} data-test-id="timun-courseViewSider__aboutCourseMenuItem">
              <Link to={this.getAboutLink()}>
                <div className="timun-courseView__lectureTitle">{this.props.translate('COURSE_VIEW.LECTURE_LIST_MENU.COURSE_ABOUT_ITEM_LABEL')}</div>
              </Link>
            </Item>

            {...this.state.lectureList}
            {this.props.canEdit && this.props.course && (
              <Item key={`addNew_group_${this.props.course.id}`} data-test-id="timun-courseViewSider__addGroupMenuItem">
                <AddElementLink className="text-normalize" onSubmit={(title) => this.handleLectureGroupAdd(title)} title={this.props.translate('COURSE_VIEW.UPDATE.GROUP_NAME_INPUT_TITLE')} />
              </Item>
            )}

            {this.props.showExamInstanceList && (
              <Item key={COURSE_EXAM_ROUTE} disabled={disabledExamInstance} data-test-id="timun-courseViewSider__examInstanceMenuItem">
                <Link to={this.getCourseLink(COURSE_EXAM_ROUTE)}>
                  <div className="timun-courseView__lectureTitle">{this.props.translate('COURSE_VIEW.VIEW.LECTURE_QUIZ_TITLE')}</div>
                </Link>
              </Item>
            )}

            {!this.props.previewMode && (
              <Item key={COURSE_NOTES_ROUTE}>
                <Link to={this.getCourseLink(COURSE_NOTES_ROUTE)} data-test-id="timun-courseViewSider__notesMenuItem">
                  <div className="timun-courseView__lectureTitle">{this.props.translate('NOTE_LIST.MY_NOTES_LABEL')}</div>
                </Link>
              </Item>
            )}

            {this.props.canSeeExamTemplate && this.props.course?.exam && CourseHelperUtils.hasExamTemplate(this.props.course) && (
              <Item key={COURSE_EXAM_TEMPLATE_ROUTE} data-test-id="timun-courseViewSider__examTemplateMenuItem">
                <Link to={{ pathname: this.getExamTemplateLink(this.props.course?.exam.examTemplate.id), state: { examCourseId: this.props.course.id } }}>
                  <div className="timun-courseView__lectureTitle">{this.props.translate('COURSE_VIEW.LECTURE_LIST_MENU.EXAM_TEMPLATE_ITEM_LABEL')}</div>
                </Link>
              </Item>
            )}

            {this.props.canSeeExamTemplate && this.props.course && CourseHelperUtils.isCourseInStatus(this.props.course, [CourseStatusEnum.IN_CREATION]) && !CourseHelperUtils.hasExamTemplate(this.props.course) && (
              <Item key={COURSE_EXAM_TEMPLATE_ROUTE} data-test-id="timun-courseViewSider__addExamMenuItem">
                <Button type="primary" className="text-normalize" icon={<LemonIcon name="plus" />} onClick={this.onCreateExamTemplate}>
                  {this.props.translate('EDUCATION_GROUP.ADD_EXAM_TEMPLATE')}
                </Button>
              </Item>
            )}
            <Item key={COURSE_CERTIFICATE_ROUTE} disabled={disabledCourseCertificate} data-test-id="timun-courseViewSider__confirmationCourseMenuItem">
              <Link to={this.getCourseLink(COURSE_CERTIFICATE_ROUTE)}>
                <div className="timun-courseView__lectureTitle">{this.props.translate('COURSE_VIEW.COURSE_CERTIFICATE_ITEM_LABEL')}</div>
              </Link>
            </Item>
          </Menu>
        </Content>
      </Layout>
    );
  };

  onCreateExamTemplate = () => {
    if (this.props.onCreateExamTemplate && this.props.course) {
      this.props.onCreateExamTemplate(this.props.translate('EXAM_TEMPLATE.CREATE_TITLE', { examTemplateTitle: this.props.course.title }));
    }
  };

  handleLectureGroupAdd = (title: string) => {
    const createGroupEvent = createTrackableEvent(createEvent(title));
    createGroupEvent.track().subscribe((response: any) => {
      this.handleOpenChange([...this.state.openSubmenu, this.getGroupKey(response.id)]);
    });
    if (this.props.onLectureGroupAdd) {
      this.props.onLectureGroupAdd(title, createGroupEvent);
    } else {
      console.warn('Add function not provided when canEdit parameter is set');
    }
  };

  handleOpenChange = (openKeys: string[]) => {
    this.setState({
      openSubmenu: openKeys,
    });
  };

  getGroupKey = (groupId: string): string => {
    return `group_${groupId}`;
  };

  getCourseLink = (id: string): string => {
    if (this.props.course) {
      return this.props.adminMode ? CourseHelperUtils.getAdminPath(this.props.course, id) : CourseHelperUtils.getTraineePath(this.props.course, id);
    } else return '';
  };

  getAboutLink = (): string => {
    if (this.props.previewMode) {
      return '';
    } else if (this.props.course) {
      return this.props.adminMode ? CourseHelperUtils.getAdminPath(this.props.course, COURSE_ABOUT_ROUTE) : CourseHelperUtils.getTraineePath(this.props.course, COURSE_ABOUT_ROUTE);
    } else return '';
  };

  getExamTemplateLink = (examTemplateId: string) => {
    if (this.props.course) {
      return CourseHelperUtils.getTraineePath(this.props.course, COURSE_EXAM_TEMPLATE_ROUTE) + `/${examTemplateId}/quizcreator`;
    }
    return;
  };

  setOpenSubmenu = (groupKey: string, lectureId: string) => {
    if (lectureId === this.props.selectedLecture && (this.state.openSubmenu.length !== 1 || this.state.openSubmenu[0] !== groupKey)) {
      this.setState({
        openSubmenu: [groupKey],
      });
    }
  };

  private updateOpenSubmenu = () => {
    // when setting menu selected item with selectedKeys prop, subMenu doesn't get opened automatically
    this.props.lectureList.forEach((lectureGroup: ICourseLectureListElement) => {
      const groupKey = this.getGroupKey(lectureGroup.id);

      lectureGroup.lectureList.forEach((lecture: ILectureInfo) => {
        this.setOpenSubmenu(groupKey, lecture.id);
      });
    });
  };

  private updateList = () => {
    const sortedLectures: ILectureInfo[] = [];

    const sortedLectureList: ICourseLectureListElement[] = CourseHelperUtils.sortbyOrdering(this.props.lectureList).map(
      (lectureGroup: ICourseLectureListElement): ICourseLectureListElement => {
        CourseHelperUtils.sortbyOrdering(lectureGroup.lectureList).forEach(
          (lecture: ILectureInfo): ILectureInfo => {
            sortedLectures.push(lecture);
            return lecture;
          }
        );
        return lectureGroup;
      }
    );
    const lectureList = sortedLectureList.map((lectureGroup: ICourseLectureListElement) => {
      const groupKey = this.getGroupKey(lectureGroup.id);
      const groupLectures = lectureGroup.lectureList.map((lecture: ILectureInfo) => {
        this.setOpenSubmenu(groupKey, lecture.id);
        const menuItemDisabled = !this.props.previewMode && lecture.locked;
        const isCourseLectureCompleted = this.props.course?.progress?.lectureProgresses?.some((lectureProgress) => {
          return lectureProgress.lecture.id === lecture.id && lectureProgress.finishDateTime != null;
        });
        return (
          <Item key={lecture.id} disabled={menuItemDisabled} data-test-id={`timun-courseViewSider__lecture_${lecture.id}`}>
            <Tooltip title={this.props.previewMode ? this.props.translate('COURSE_VIEW.PUBLIC_LECTURE_TOOLTIP') : lecture.title} placement="topLeft">
              <Link to={this.props.previewMode ? '' : this.getCourseLink(lecture.id)}>{lecture.title}</Link>
              {isCourseLectureCompleted && <LemonIcon name="check" className="timun-courseView__checkmark" />}
            </Tooltip>
          </Item>
        );
      });

      return (
        <SubMenu
          data-test-id={`timun-courseViewSider__lectureGroupMenu_${lectureGroup.id}`}
          key={groupKey}
          title={
            <Tooltip title={lectureGroup.title} placement="topLeft">
              <div className="timun-courseView__lectureTitle">{lectureGroup.title}</div>
            </Tooltip>
          }
        >
          {groupLectures}
          {this.props.canEdit && (
            <Item key={`addNew_lecture_${lectureGroup.id}`} data-test-id="timun-courseViewSider__addLectureMenuItem">
              <AddElementLink className="text-normalize" onSubmit={(title) => (this.props.onLectureAdd ? this.props.onLectureAdd(lectureGroup.id, title) : console.warn('Add function not provided when canEdit parameter is set'))} title={this.props.translate('COURSE_VIEW.UPDATE.LECTURE_NAME_INPUT_TITLE')} />
            </Item>
          )}
        </SubMenu>
      );
    });

    this.setState({
      lectureList,
    });
  };
}

export default withLocalize<ICourseViewSiderOwnProps>(withRouter(withRoles(CourseViewSider) as any));

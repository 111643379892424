import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IFileListsByType } from '@src/service/business/files/util';

import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IFile } from '@src/model/file/File';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';
import { SurveyTypeEnum } from '@src/model/survey/SurveyTemplate';
import { Button, Col, Row, Typography } from 'antd';

import React from 'react';

// -- Prop types
// ----------
interface IExternalEducationApplicationTraineeCompletedViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  canParticipate?: boolean;
  onUpdate?: (data: IExternalEducationApplication) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: EducationApplicationActivityPhaseEnum, save?: boolean) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}

type IExternalEducationApplicationTraineeCompletedViewProps = IExternalEducationApplicationTraineeCompletedViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationTraineeCompletedViewState {}

// -- Component
// ----------

class ExternalEducationApplicationTraineeCompletedView extends React.Component<IExternalEducationApplicationTraineeCompletedViewProps, IExternalEducationApplicationTraineeCompletedViewState> {
  state: IExternalEducationApplicationTraineeCompletedViewState = {};

  componentDidUpdate = (prevProps: IExternalEducationApplicationTraineeCompletedViewProps, prevState: IExternalEducationApplicationTraineeCompletedViewState) => {
    //
  };

  isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  handleChangePhase = (phase: EducationApplicationActivityPhaseEnum) => {
    if (this.props.onChangePhase) {
      this.props.onChangePhase(phase);
    }
  };

  render() {
    const traineeSurvey = this.props.externalEducationApplication.surveyInstances?.find((survey) => survey.type.id === SurveyTypeEnum.USER_EDUCATION_COMPLETED);
    return (
      <React.Fragment>
        {this.isApplicationInPhases(['EDUCATION_COMPLETED']) && (
          <React.Fragment>
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_COMPLETED_TEXT')}</Col>
            </Row>

            {traineeSurvey && (
              <Row>
                <Col>
                  <br />
                  <Typography.Title level={3}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.SURVEY.TRAINEE_SURVEY_LABEL')}</Typography.Title>

                  <Button type="primary" href={this.getSurveyLink(traineeSurvey)} disabled={traineeSurvey.answersSubmitted} data-test-id="timun-externalEducationApplicationView__takeSurveyButton">
                    {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.TAKE_SURVEY_BUTTON_LABEL')}
                  </Button>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}

        {this.isApplicationInPhases(['CONTRACT_TERMINATED']) && (
          <React.Fragment>
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CONTRACT_TERMINATED_TEXT')}</Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  private getSurveyLink = (survey: ISurveyInstance): string => {
    return `public/surveyinstance?token=${survey.token}`;
  };
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationTraineeCompletedViewOwnProps>(ExternalEducationApplicationTraineeCompletedView as any));

import FileItemCard from '@src/components/common/file/FileItemCard';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IFile } from '@src/model/file/File';
import { ITimunFile } from '@src/service/business/files/util';
import { Col, Row } from 'antd';
import React from 'react';

interface IFileListCardViewOwnProps {
  fileList?: ITimunFile[];
  canRemove?: boolean;
  onRemove?: (file: IFile[]) => void;
}
type IFileListCardViewProps = IFileListCardViewOwnProps & IWithLocalizeOwnProps;

interface IFileListCardViewState {
}

class FileListCardView extends React.Component<IFileListCardViewProps, IFileListCardViewState> {
  state: IFileListCardViewState = {
  };

  render = () => {
    return <React.Fragment>
      <Row justify="space-between" gutter={16}>
        {this.props.fileList?.map((file) => {
          return (
            <Col xs={24} md={24} lg={12} key={file.uid}>
              <FileItemCard<IFile>
                file={file}
                canRemove={this.props.canRemove}
                onRemove={this.handleFileDelete}
              />
            </Col>
          );
        })
        }
      </Row>
    </React.Fragment>;
  };

  handleFileDelete = (data: ITimunFile) => {
    const deleted = data.response;
    if (this.props.onRemove && deleted) {
      this.props.onRemove([deleted]);
    }
  };

}

export default withLocalize<IFileListCardViewOwnProps>(FileListCardView as any);


import { classNames } from '@src/components/common/util/classNames';
import { Col, Row, Typography } from 'antd';
import React, { ReactNode } from 'react';

const CSS_CLASS_VIEW_SECTION_ITEM_BASE = 'timun-viewSectionContent__item';

// -- Prop types
// ----------

interface IViewSectionContentItemOwnProps {
  icon?: ReactNode;
  title: string | ReactNode;
  description?: string;
  actions?: ReactNode;
  className?: string;
}

type IViewSectionContentItemProps = IViewSectionContentItemOwnProps;

// -- Component
// ----------

class ViewSectionContentItem extends React.Component<IViewSectionContentItemProps> {
  render = () => {
    return <Row className={this.getClassName()} align="middle">
      <Col className="timun-viewSectionContent__icon" xs={3} md={2}>
        {this.props.icon}
      </Col>
      <Col xs={this.props.actions ? 17 : 21} md={this.props.actions ? 15 : 22}>
        <Typography.Title className="timun-viewSectionContent__itemTitle" level={3}>{this.props.title}</Typography.Title>
        {this.props.description}
      </Col>
      {
        this.props.actions && <Col xs={4} md={7}>
          {this.props.actions}
        </Col>
      }
    </Row>;
  };

  getClassName() {
    return classNames({
      [CSS_CLASS_VIEW_SECTION_ITEM_BASE]: true,
      [this.props.className || '']: true,
    });
  }
}

export default ViewSectionContentItem;

import { FormComponentProps } from '@ant-design/compatible/es/form';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkillGroup } from '@src/model/skillgroup/SkillGroup';
import { ISkillGroupCreatePayload } from '@src/service/business/skillgroup/SkillGroupBusinessStore';
import { Form, Input, Modal, Radio } from 'antd';
import React, { useCallback } from 'react';

// -- Prop types
// ----------
interface ISkillGroupFormOwnProps {
  onClose?: () => void;
  onUpdate?: (skillGroupData: ISkillGroup) => void;
  onCreate?: (skillGroupData: ISkillGroupCreatePayload) => void;
  title: string;
  skillGroup?: ISkillGroup;
}

type ISkillGroupFormProps = ISkillGroupFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------
const SkillGroupForm: React.FC<ISkillGroupFormProps> = (props: ISkillGroupFormProps) => {
  const [form] = Form.useForm<ISkillGroup>();

  const handleFinish = useCallback(
    (values: ISkillGroup) => {
      const skillGroupData = props.skillGroup
        ? {
            ...props.skillGroup,
            ...values,
          }
        : ({ ...values } as ISkillGroup);
      if (props.skillGroup) {
        props.onUpdate?.(skillGroupData);
      } else {
        props.onCreate?.(skillGroupData);
      }
    },
    [props.onUpdate, props.onCreate, props.skillGroup]
  );

  return (
    <Modal title={props.title} visible={true} onCancel={props.onClose} onOk={form.submit} okText={props.translate('COMMON.ACTION_SAVE')} cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form<ISkillGroup> form={form} name={'skillGroup'} onFinish={handleFinish} initialValues={props.skillGroup}>
        <Form.Item
          label={props.translate('COMMON.LABEL.NAME')}
          name={'title'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: 128, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={props.translate('COMMON.LABEL.STATUS')} name={'active'} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={true}>{props.translate('COMMON.LABEL.ACTIVE')} </Radio.Button>
            <Radio.Button value={false}>{props.translate('COMMON.LABEL.INACTIVE')} </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item className="hidden" name={'id'}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<ISkillGroupFormOwnProps>(SkillGroupForm as any);

import UserInterfaceUtils from '@src/components/common/util/UserInterfaceUtils';
import React from 'react';

/** Higher order component for wrapping component with default scroll to top behaviour on mount. */
const withScroll = <P extends object>(Component: React.ComponentType<P>) =>
  class WithScroll extends React.Component<P> {
    componentDidMount = () => {
      UserInterfaceUtils.scrollToTop();
    };

    render() {
      return (
        <Component {...this.props} />
      );
    }
  };

export default withScroll;

import { isFunction, isTextarea, mergePlugins, uuid } from '@src/components/common/editor/Utils';
import * as React from 'react';
import tinymce, { Editor as EditorType, Settings } from 'tinymce';

// Theme
import 'tinymce/themes/silver';

// Toolbar icons
import 'tinymce/icons/default';

// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/print';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/wordcount';

export interface IEditorOwnProps {
  id?: string;
  inline?: boolean;
  initialValue?: string;
  onChange?: React.EventHandler<any>;
  value?: string;
  init?: Settings;
  outputFormat?: 'html' | 'text';
  tagName?: string;
  plugins?: string | string[];
  toolbar?: string | string[];
  disabled?: boolean;
  textareaName?: string;
  placeholder?: string;
}

type IEditorProps = IEditorOwnProps;

export class Editor extends React.Component<IEditorOwnProps> {
  private id: string;
  private elementRef: React.RefObject<Element>;
  private editor?: EditorType;
  private inline: boolean;
  private currentContent?: string | null;

  constructor(props: IEditorProps) {
    super(props);
    this.id = this.props.id || uuid('tiny-react');
    this.elementRef = React.createRef<Element>();
    this.inline = this.props?.inline ?? false;
  }

  public componentDidUpdate(prevProps: IEditorProps) {
    if (this.editor && this.editor.initialized) {
      this.currentContent = this.currentContent || this.editor.getContent({ format: this.props.outputFormat });

      if (typeof this.props.value === 'string' && this.props.value !== prevProps.value && this.props.value !== this.currentContent) {
        this.editor.setContent(this.props.value);
      }
      if (typeof this.props.disabled === 'boolean' && this.props.disabled !== prevProps.disabled) {
        this.editor.setMode(this.props.disabled ? 'readonly' : 'design');
      }
    }
  }

  public componentDidMount() {
    if (tinymce !== null) {
      this.initialise();
    }
  }

  public componentWillUnmount() {
    if (tinymce !== null && this.editor != null) {
      tinymce.EditorManager.remove(this.editor);
    }
  }

  public render() {
    return !this.inline && this.renderIframe();
  }

  private initialise = () => {
    const finalInit = {
      ...this.props.init,
      target: !this.elementRef.current ? undefined : this.elementRef.current, // ako je null, stavi undefined
      readonly: this.props.disabled,
      inline: this.inline,
      plugins: mergePlugins(this.props.init?.plugins ?? [], this.props.plugins),
      toolbar: this.props.toolbar || (this.props.init && this.props.init.toolbar),
      theme: 'silver',
      setup: (editor: EditorType) => {
        this.editor = editor;
        editor.on('init', (e: Event) => {
          this.initEditor(e, editor);
        });

        if (this.props.init && typeof this.props.init.setup === 'function') {
          this.props.init.setup(editor);
        }
      },
    };

    if (isTextarea(this.elementRef.current) && this.elementRef.current) {
      this.elementRef.current.style.visibility = '';
    }

    tinymce.init(finalInit);
  };

  private initEditor(initEvent: Event, editor: any) {
    const value = typeof this.props.value === 'string' ? this.props.value : typeof this.props.initialValue === 'string' ? this.props.initialValue : '';
    editor.setContent(value);

    if (isFunction(this.props.onChange)) {
      editor.on('change keyup setcontent', (e: any) => {
        const newContent = editor.getContent({ format: this.props.outputFormat });

        if (newContent !== this.currentContent) {
          this.currentContent = newContent;
          if (isFunction(this.props.onChange)) {
            this.props.onChange(this.currentContent);
          }
        }
      });
    }

    if (isFunction(this.props.init)) {
      this.props.init(initEvent, editor);
    }
  }

  private renderIframe() {
    return React.createElement('textarea', {
      ref: this.elementRef,
      style: { visibility: 'hidden' },
      name: this.props.textareaName,
      id: this.id,
    });
  }
}

import { Editor } from '@src/components/common/editor/Editor';
import { TimunEditorConfig } from '@src/components/common/editor/TimunEditorConfigs';
import { validateExternalUrl } from '@src/components/common/form/validation';
import FormImageComponent from '@src/components/common/image/FormImageComponent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import CompanyPickerFormView from '@src/components/company/view/CompanyPickerFormView';
import PaymentInfoInput from '@src/components/payment/PaymentInfoInput';
import { IOrganization } from '@src/model/organization/Organization';
import { Form, Input, Modal, Switch } from 'antd';
import React, { useCallback } from 'react';

// -- Prop types
// ----------

export interface IOrganizationFormOwnProps {
  title: string;
  organization?: IOrganization;
  onSubmit?: (values: IOrganization) => void;
  onCancel: () => void;
}

type IOrganizationFormProps = IOrganizationFormOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------
const OrganizationForm: React.FC<IOrganizationFormProps> = (props: IOrganizationFormProps) => {
  const [form] = Form.useForm<IOrganization>();

  const handleFinish = useCallback(
    (values: IOrganization) => {
      props.onSubmit?.(values);
    },
    [props.onSubmit]
  );

  const handleFinishFailed = useCallback((errorInfo: any) => {
    console.log(errorInfo);
  }, []);

  const handleClear = useCallback(() => {
    form.resetFields();
  }, [form.resetFields]);

  return (
    <Modal className="lemon-modal__modal--md" visible={true} maskClosable={false} title={props.title} okText={props.translate(`COMMON.ACTION_SAVE`)} cancelText={props.translate('COMMON.ACTION_CANCEL')} onCancel={props.onCancel} onOk={() => form.submit()}>
      <Form name="organizationForm" form={form} onFinish={handleFinish} onFinishFailed={handleFinishFailed} initialValues={props.organization} layout="vertical">
        <Form.Item valuePropName="checked" noStyle={true} hidden={true} name={['active']}>
          <Switch />
        </Form.Item>

        <Form.Item noStyle={true} hidden={true} name={['id']}>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item name={['company']} rules={[{ required: true, message: props.translate('ORGANIZATION_FORM.COMPANY_REQUIRED_LABEL') }]} valuePropName={'company'}>
          <CompanyPickerFormView onClear={handleClear} />
        </Form.Item>

        <Form.Item name={['description']} label={props.translate('ORGANIZATION_FORM.DESCRIPTION_LABEL')}>
          <Editor
            init={{
              ...TimunEditorConfig.defaultConfig(),
              height: 500,
              menubar: true,
            }}
          />
        </Form.Item>

        <Form.Item name={['webUrl']} label={props.translate('ORGANIZATION_FORM.URL_LABEL')} rules={[{ validator: validateExternalUrl }]}>
          <Input placeholder={props.translate('ORGANIZATION_FORM.URL_PLACEHOLDER_LABEL')} />
        </Form.Item>

        <Form.Item name={['paymentInfo']}>
          <PaymentInfoInput required={true} />
        </Form.Item>

        <Form.Item name={['logo']} label={props.translate('ORGANIZATION_FORM.LOGO_LABEL')}>
          <FormImageComponent />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withLocalize<IOrganizationFormOwnProps>(withPathResolver(OrganizationForm) as any);

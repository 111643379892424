import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export enum CacheEnum {
  COUNTIES = 'counties',
  EDUCATION_CATEGORIES = 'educationcategories',
  EXTERNAL_EDUCATION_TEMPLATE = 'externaleducationtemplate',
  LOCATIONS = 'locations',
  SKILL_GROUPS_ACTIVE = 'skillgroupsactive',
  SKILLS_BY_SKILLGROUP_ACTIVE = 'skillsbyskillgroupactive',
  EDUCATION_SECTORS = 'educationsectors',
  ENROLLMENT_REQUIREMENTS = 'enrollmentrequirements',
  EXTERNAL_EDUCATION_INSTANCES = 'externaleducationinstances',
  ORGANIZATION_PROFILES = 'organizationprofiles',
  SKILLS = 'skills',
}

export interface ICacheListFilter {
  cache?: CacheEnum;
}

// List filter ID
const CACHE_LIST_FILTER = '@@CACHE_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns list of Caches from store. */
const getCacheList = (store: any): CacheEnum[] => store.cacheList;

/** Returns Cache list filter. */
const getCacheListFilter = (store: any): ICacheListFilter => ListFilterBusinessStore.selectors.getListFilter(store, CACHE_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  CACHE_LIST_FETCH: 'CACHE_LIST_FETCH',
  CACHE_LIST_LOAD: 'CACHE_LIST_LOAD',
  CACHE_LIST_CLEAR: 'CACHE_LIST_CLEAR',
  CACHE_LIST_DELETE: 'CACHE_LIST_DELETE',
  CACHE_DELETE: 'CACHE_DELETE',
};

/** Fetch Cache list by filter. */
const fetchCacheList = (filter: ICacheListFilter): IPayloadAction<ICacheListFilter> => {
  return {
    type: Actions.CACHE_LIST_FETCH,
    payload: filter,
  };
};

/** Load Cache list to store. */
const loadCacheList = (data: CacheEnum[]): IPayloadAction<CacheEnum[]> => {
  return {
    type: Actions.CACHE_LIST_LOAD,
    payload: data,
  };
};

/** Clear Cache list from store. Eg. when leaving list view. */
/** Clear Cache list from store. Eg. when leaving list view. */
const clearCacheList = (): ILemonAction => {
  return {
    type: Actions.CACHE_LIST_CLEAR,
  };
};

/** Delete Cache list  */
const deletCacheList = (): ILemonAction => {
  return {
    type: Actions.CACHE_LIST_DELETE,
  };
};

/** Delete specific  */
const deleteCache = (cache: CacheEnum): IPayloadAction<CacheEnum> => {
  return {
    type: Actions.CACHE_DELETE,
    payload: cache,
  };
};

/** Store Cache list filter to store. */
const storeCacheListFilter = (listFilter: ICacheListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(CACHE_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects
const fetchCacheListEffect = (action$: Observable<IPayloadAction<ICacheListFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CACHE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const filter = action.payload;

      return EntityApiServiceRegistry.getService('Cache')
        .fetchNoMethod(filter)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCacheList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching Cache list', error);
      return o;
    })
  );
};

const deleteCacheListEffect = (action$: Observable<ILemonAction>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CACHE_LIST_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      return EntityApiServiceRegistry.getService('Cache')
        .deleteNoMethod()
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting Cache list', error);
      return o;
    })
  );
};

const deleteCacheEffect = (action$: Observable<IPayloadAction<CacheEnum>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CACHE_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const cacheName = action.payload;

      return EntityApiServiceRegistry.getService('Cache')
        .deleteMethod(cacheName, {})
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching Cache', error);
      return o;
    })
  );
};
// -
// -------------------- Reducers
const cacheList = (state: CacheEnum[] | null = null, action: IPayloadAction<CacheEnum[]>) => {
  if (action.type === Actions.CACHE_LIST_LOAD) {
    return action.payload;
  } else if (action.type === Actions.CACHE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const CacheListBusinessStore = {
  actions: {
    deletCacheList,
    deleteCache,
    fetchCacheList,
    loadCacheList,
    clearCacheList,
    storeCacheListFilter,
  },

  selectors: {
    getCacheList,
    getCacheListFilter,
  },

  effects: {
    fetchCacheListEffect,
    deleteCacheListEffect,
    deleteCacheEffect,
  },

  reducers: {
    cacheList,
  },
};

// --
// export business store
export default CacheListBusinessStore;

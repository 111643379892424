import React from 'react';

import { validateCheckbox } from '@src/components/common/form/validation';
import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserFileListUpload from '@src/components/user/file/UserFileListUpload';
import UserFormInputFields from '@src/components/user/form/UserFormInputFields';
import GdprContainer from '@src/components/user/registration/GdprContainer';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import { IFileListsByType } from '@src/service/business/files/util';
import { Checkbox, Col, Form, FormInstance, Row, Steps } from 'antd';
import moment from 'moment';

//  -- Const
//  ----------
const Step = Steps.Step;

// --Prop types
// ----------

export interface IUserRegistrationFormOwnProps {
  value: IUserInfo;
  formRef?: FormInstance<IUserInfo>;
  disabled?: boolean;
  currentStep: number;
  userFiles?: IFileListsByType;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  setStep?: (value: number) => void;
  onSubmit?: (data: IUserInfo) => void;
}
type IUserRegistrationFormProps = IUserRegistrationFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display user registration form */
const UserRegistrationForm: React.FC<IUserRegistrationFormProps> = (props: IUserRegistrationFormProps) => {
  const [form] = Form.useForm<IUserInfo>(props.formRef);
  const initialValues = {
    ...props.value,
    dateOfBirth: props.value?.dateOfBirth ? moment(props.value.dateOfBirth) : undefined,
  };

  return (
    <React.Fragment>
      <div className="timun-registration__steps">
        <Steps current={props.currentStep} onChange={props.setStep} responsive={true}>
          <Step title={props.translate('USER_REGISTRATION.MODAL.STEP_ONE_TITLE')} data-test-id="timun-userRegistrationModal__stepsView_1" />
          <Step title={props.translate('USER_REGISTRATION.MODAL.STEP_TWO_TITLE')} data-test-id="timun-userRegistrationModal__stepsView_2" />
          <Step title={props.translate('USER_REGISTRATION.MODAL.STEP_THREE_TITLE')} data-test-id="timun-userRegistrationModal__stepsView_3" />
        </Steps>
      </div>
      <Form<IUserInfo>
        initialValues={initialValues}
        layout="vertical"
        form={form}
        name="user"
        onFinish={props.onSubmit}>

        {/* -------------------- Step 1 -------------------- */}
        {props.currentStep === 0 &&
          <React.Fragment>
            <Form.Item>
              <Row>
                <Col className="text-center">{props.translate('USER_REGISTRATION.MODAL.WELCOME', { firstName: props.value?.firstName, lastName: props.value?.lastName })}</Col>
              </Row>
            </Form.Item>

            <Form.Item label={props.translate('USER_FORM.GDPR_LABEL')}
              name={'gdprAccepted'}
              valuePropName="checked"
              required={true}
              rules={[{ validator: (rule, value, callback) => validateCheckbox(rule, value, callback, props.translate('USER_REGISGTRATION.FORM.ERROR_MESSAGE.GDPR_REQUIRED')) }]}>
              <Checkbox>{props.translate('USER_FORM.GDPR_ACCEPTED')}</Checkbox>
            </Form.Item>

            <GdprContainer />
          </React.Fragment>}
        {/* -------------------- Step 2 -------------------- */}
        {props.currentStep === 1 && <React.Fragment>
          <Row className="timun-userRegistrationForm__infoRow panel">
            <Col> {props.value.extendedData?.notzSyncDateTime ? props.translate('USER_REGISTRATION.MODAL.NOTZ_USER_INFORMATION_TEXT') : props.translate('USER_REGISTRATION.MODAL.BASIC_USER_INFORMATION_TEXT')} </Col>
          </Row>
          <UserFormInputFields formRef={form} />
        </React.Fragment>}

        {/* -------------------- Step 3 -------------------- */}
        {props.currentStep === 2 && <React.Fragment>
          <Row className="timun-userRegistrationForm__infoRow panel">
            <Col> {props.value.extendedData?.notzSyncDateTime ? props.translate('USER_REGISTRATION.MODAL.NOTZ_USER_FILES_INFORMATION_TEXT') : props.translate('USER_REGISTRATION.MODAL.BASIC_USER_FILES_INFORMATION_TEXT')} </Col>
          </Row>
          <GridItem label={props.translate(props.translate('FILES.TYPE_ENUM.LABEL.ID_CARD'))}>
            <UserFileListUpload
              userFiles={props.userFiles}
              fileType="ID_CARD"
              onFileUpload={props.onFileUpload}
              onFileRemove={props.onFileRemove} />
          </GridItem>

          <GridItem label={props.translate('FILES.TYPE_ENUM.LABEL.COMPLETED_EDUCATION_CERTIFICATE')}>
            <UserFileListUpload
              userFiles={props.userFiles}
              fileType="COMPLETED_EDUCATION_CERTIFICATE"
              onFileUpload={props.onFileUpload}
              onFileRemove={props.onFileRemove} />
          </GridItem>
        </React.Fragment>}
      </Form>
    </React.Fragment >
  );
};

export default withLocalize<IUserRegistrationFormOwnProps>(UserRegistrationForm as any);

import { IActivity } from '@src/model/activity/Activity';
import { IActivityParticipant, ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';

export class ActivityUtils {
  /** Return participant by requested role. */
  static getParticipantByRole(requestedRole: keyof typeof ParticipantRoleEnum, activity: IActivity): IActivityParticipant | undefined {
    return activity.participants.find((paritcipant: IActivityParticipant) => paritcipant.participantRole === ParticipantRoleEnum[requestedRole]);
  }

  /** Returns participant name by requested role. */
  static getParticipantNameByRole(requestedRole: keyof typeof ParticipantRoleEnum, activity?: IActivity): string | undefined {
    const participant = activity?.participants.find((paritcipant: IActivityParticipant) => paritcipant.participantRole === ParticipantRoleEnum[requestedRole]);
    if (participant) {
      return `${participant?.firstName} ${participant?.lastName}`;
    } else {
      return undefined;
    }
  }

  /** Check if activity is in one of listed phases. */
  static isInPhases(requestedPhases: ActivityPhaseEnum[], activity: IActivity): boolean {
    return requestedPhases.some((phase) => activity.currentPhase.id === phase);
  }

  /** get key of the faze from enum. */
  static getPhaseName(activity: IActivity): string | undefined {
    return Object.keys(ActivityPhaseEnum).find((phase) => ActivityPhaseEnum[phase as keyof typeof ActivityPhaseEnum] === activity.currentPhase.id);
  }

  /** Check if userId is in one of participants userId. */
  static isParticipant(userId: string, activity: IActivity): boolean {
    return activity.participants.some((participant) => participant.userId === userId);
  }

  /** Check if userId has participant role Assignee */
  static isParticipantAssignee(userId: string, activity: IActivity): boolean {
    return activity.participants.find((participant) => participant.userId === userId)?.participantRole === ParticipantRoleEnum.ASSIGNEE;
  }
}

import React from 'react';
import { connect } from 'react-redux';

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { IUserCategory } from '@src/model/user/UserCategory';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserCategoryBusinessStore, { IUserCategoryListFilter } from '@src/service/business/user/userCategoryBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');
// -- Prop types
// ----------

export interface IUserCategoryDataPickerOwnProps {
  value?: IUserCategory;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (newValue?: IUserCategory) => void;
}

export interface IUserCategoryDataPickerStateProps {
  userCategoryList: ICollectionData<IUserCategory>;
}

export interface IUserCategoryDataPickerDispatchProps {
  fetchUserCategoryList: (params: ICollectionFetchPayload<IUserCategoryListFilter>) => void;
}
type IUserCategoryDataPickerProps = IUserCategoryDataPickerOwnProps & IUserCategoryDataPickerStateProps & IUserCategoryDataPickerDispatchProps;

interface IUserCategoryDataPickerState {
}

// -- Component
// ----------

/** Describe your component ... */
class UserCategoryDataPicker extends React.Component<IUserCategoryDataPickerProps, IUserCategoryDataPickerState> {
  state: IUserCategoryDataPickerState = {};

  componentDidMount() {
    this.props.fetchUserCategoryList({ size: DEFAULT_PAGE_SIZE_VALUE });
  }

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.props.userCategoryList?.content.map(this.mapToItem);

    return <DataPicker
      value={pickerValue}
      items={pickerItems}
      disabled={this.props.disabled}
      onChange={this.handleDataPickerChange}
      dataTestIdPrefix="timun-skillPicker"
      placeholderSuffix={this.props.placeholder}
    />;
  };

  private handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  /** Map entity ref list to data picker item list. */
  private mapToItem(UserCategory: IUserCategory): IDataPickerItem {
    return {
      value: UserCategory.id,
      name: UserCategory.name,
      data: UserCategory,
    };
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserCategoryDataPickerOwnProps): IUserCategoryDataPickerStateProps => ({
  userCategoryList: UserCategoryBusinessStore.selectors.getUserCategoryList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserCategoryDataPickerDispatchProps => ({
  fetchUserCategoryList: (params: ICollectionFetchPayload<IUserCategoryListFilter>) => dispatch(UserCategoryBusinessStore.actions.fetchUserCategoryList(params)),
});

export default connect<IUserCategoryDataPickerStateProps, IUserCategoryDataPickerDispatchProps, IUserCategoryDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(UserCategoryDataPicker as any);

import TimunCard from '@src/components/common/list/TimunCard';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { Col, Empty, Row } from 'antd';
import React, { SyntheticEvent } from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IUserGroupListOwnProps {
  emptyMessage: string;
  userGroups: IUserGroup[];
  showButtons: boolean;
  basePath: string;
  returnRoute?: string;
  onUserGroupEdit?: (userGroup: IUserGroup) => void;
  onUserGroupCoverEdit?: (userGroup: IUserGroup) => void;
  onUserGroupDelete?: (userGroupId: string) => void;
}

type IUserGroupListProps = IUserGroupListOwnProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------
class UserGroupList extends React.Component<IUserGroupListProps> {
  render() {
    return this.props.userGroups.length ? (
      <Row gutter={[16, 8]}>
        {this.props.userGroups.map((userGroup: IUserGroup) => {
          return (
            <Col key={userGroup.id} xs={24} sm={12} md={8} lg={6}>
              <TimunCard
                title={userGroup.name}
                coverImageUrl={userGroup.coverImageUrl}
                showButtons={this.props.showButtons}
                onCardClick={() => this.handleCardClick(userGroup.id)}
                onEdit={() => this.props.onUserGroupEdit && this.props.onUserGroupEdit(userGroup)}
                onUpdateCoverImage={() => this.props.onUserGroupCoverEdit && this.props.onUserGroupCoverEdit(userGroup)}
                onDelete={() => this.props.onUserGroupDelete && this.props.onUserGroupDelete(userGroup.id)}
              />
            </Col>
          );
        })}
      </Row>
    ) : (
      <Empty description={this.props.emptyMessage} />
    );
  }

  handleDelete = (e: SyntheticEvent, userGroup: IUserGroup) => {
    e.stopPropagation();
  };

  handleCardClick = (id: string) => {
    const path = id ? `${this.props.basePath}/${id}` : this.props.basePath;
    this.props.router.push({ pathname: path, state: { returnRoute: this.props.returnRoute } });
  };
}

// -- HOCs and exports
// ----------

export default withLocalize(withRouter<IUserGroupListOwnProps>(UserGroupList as any));

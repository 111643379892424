import React, { PropsWithChildren } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import AdministrationSkillViewContainer from '@src/components/administrationskills/view/AdministrationSkillViewContainer';


// -- Prop types
// ----------
type IAdministrationSkillMenuProps = WithRouterProps;

const AdministrationSkillMenuLayout = (props: PropsWithChildren<IAdministrationSkillMenuProps>) => {
  // TODO: add querry param instead of slicing the pathname string
  const getSkillsPathId = (): string => props.location.pathname.slice(props.location.pathname.lastIndexOf('/') + 1) ;
  return (
    <AdministrationSkillViewContainer currentPage={getSkillsPathId()}>
        {props.children}
    </AdministrationSkillViewContainer>
  );
};

export default withRouter<IAdministrationSkillMenuProps>(AdministrationSkillMenuLayout as any);
import { QuizCreator } from '@lamarodigital/quizzler-lib-frontend';
import { IQuizzlerUploadProps } from '@lamarodigital/quizzler-lib-frontend/components';
import { QuestionTypes } from '@lamarodigital/quizzler-lib-frontend/components/creator/QuizCreatorContainer';
import { IQuestionOutcome } from '@lamarodigital/quizzler-lib-frontend/model/quiz/outcome/QuestionOutcome';
import { IQuestion } from '@lamarodigital/quizzler-lib-frontend/model/quiz/question/Question';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import { QuizTypeEnum } from '@lamarodigital/quizzler-lib-frontend/model/quiz/QuizType';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import { QuizHelperUtils } from '@src/components/exam/quiz/QuizHelperUtils';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import QuestionBusinessStore from '@src/service/business/quiz/questionBusinessStore';
import questionOutcomeListBusinessStore, { IQuestionOutcomeCreatePayload, IQuestionOutcomeListFilter } from '@src/service/business/quiz/questionOutcomeListBusinessStore';
import QuizCollectionBusinessStore from '@src/service/business/quiz/quizCollectionBusinessStore';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import UserSettingsBusinessStore, { IUserSettings } from '@src/service/business/usersettings/userSettingsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { Observable } from 'rxjs';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE = AppConfigService.getValue('api.paging.defaultPageSize');

// -- Prop types
// ----------
export interface IQuizCreatorContainerOwnProps {
  onQuizCollectionSubmit?: (quizCollection: IQuiz) => void;
  quizId: string;
}

export interface IQuizCreatorContainerStateProps {
  question: IQuestion;
  quizCollection: IQuiz;
  tenantConfig: ITenantConfiguration;
  outcomeList: ICollectionData<IQuestionOutcome>;
  outcomeListFilter: IQuestionOutcomeListFilter;
  userSettings: IUserSettings;
}

export interface IQuizCreatorContainerDispatchProps {
  updateQuizCollection: (data: IQuiz, dataId: string) => ITrackableAction;
  fetchQuizCollection: (quizId: string) => ITrackableAction;
  clearQuizCollection: () => void;
  createQuizCollectionQueston: (quizId: string, questionData: IQuestion) => ITrackableAction;

  fetchOutcomeList: (params: ICollectionFetchPayload<IQuestionOutcomeListFilter>) => void;
  createOutcome: (outcomeData: IQuestionOutcomeCreatePayload) => ITrackableAction;
  storeOutcomeListFilter: (filter: IQuestionOutcomeListFilter) => void;

  updateQuestion: (data: IQuestion) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type IQuizCreatorContainerProps = IQuizCreatorContainerOwnProps & IQuizCreatorContainerStateProps & IWithTenantPropEnabledOwnProps & IQuizCreatorContainerDispatchProps & IWithLocalizeOwnProps;

interface IQuizCreatorContainerState {}

// -- Component
// ----------
class QuizCreatorContainer extends React.Component<IQuizCreatorContainerProps, IQuizCreatorContainerState> {
  state = {};

  componentDidMount() {
    this.props.fetchQuizCollection(this.props.quizId);
    this.updateOutcomeList();
  }

  componentDidUpdate(prevProps: IQuizCreatorContainerProps, prevState: IQuizCreatorContainerState) {
    if (prevProps.outcomeListFilter !== this.props.outcomeListFilter) {
      this.updateOutcomeList();
    }
    if (prevProps.quizId !== this.props.quizId) {
      this.props.fetchQuizCollection(this.props.quizId);
    }
  }

  componentWillUnmount() {
    this.props.clearQuizCollection();
  }

  renderQuestionTypeList = (quizType: QuizTypeEnum) => {
    let questionTypeList;
    if (quizType === QuizTypeEnum.QUIZ || quizType === QuizTypeEnum.POOL) {
      questionTypeList = this.props.tenantConfig.configuration.examQuestionList;
    } else {
      questionTypeList = this.props.tenantConfig.configuration.surveyQuestionList;
    }
    return questionTypeList as QuestionTypes;
  };

  render() {
    const uploadProps: IQuizzlerUploadProps = {
      ...FileUploadHelper.getUploadDefaultProps(),
      onChange: FileUploadHelper.handleAntdUploadChange,
      getQuizzlerFile: QuizHelperUtils.getQuizzlerFile,
    };
    return (
      <React.Fragment>
        {this.props.quizCollection && (
          <QuizCreator
            questionOutcomeList={this.props.outcomeList?.content ?? []}
            quiz={this.props.quizCollection}
            // TODO: tenantConfig enableQuestionOutcomes
            onQuestionSubmit={this.handleQuestionSubmit}
            onQuestionOrderChange={this.handleQuestionOrderChange}
            questionTypes={this.renderQuestionTypeList(this.props.quizCollection.type.id)}
            onQuestionRemove={this.handleQuestionRemove}
            onQuestionListChange={this.handleQuestionListChange}
            resolveFileUrl={QuizHelperUtils.resolveFileUrl}
            appUploadProps={uploadProps}
            showOutcomeSection={true}
            onOutcomeSearch={this.handleOutcomeSearch}
            onCreateOutcome={this.handleOutcomeCreate}
            locale={this.props.userSettings?.locale}
          />
        )}
      </React.Fragment>
    );
  }

  handleQuizUpdate = (quizData: IQuiz) => {
    if (quizData.id) {
      this.props
        .updateQuizCollection(quizData, quizData.id)
        .track()
        .subscribe(
          // success
          () => {
            this.props.onQuizCollectionSubmit?.(this.props.quizCollection);
          }
        );
    }
  };

  handleQuestionRemove = (quizData: IQuiz, index: number, question: IQuestion) => {
    if (quizData.id && question.id) {
      this.props.updateQuizCollection(quizData, quizData.id);
      this.props.reportMessage({ message: this.props.translate('QUIZ_CREATOR.QUESTION_DELETED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
    }
  };

  handleQuestionSubmit = (questionData: IQuestion, questionIndex: number, quizData: IQuiz) => {
    if (questionData.id) {
      this.handleQuestionUpdate(questionData, quizData);
    } else {
      this.handleQuestionCreate(questionData, quizData);
    }
  };

  handleQuestionCreate = (questionData: IQuestion, quizData: IQuiz) => {
    this.props
      .createQuizCollectionQueston(quizData.id, questionData)
      .track()
      .subscribe(
        // success
        () => {
          this.props.reportMessage({ message: this.props.translate('QUIZ_CREATOR.QUESTION_SUCCESS_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
          this.props.fetchQuizCollection(quizData.id);
        }
      );
  };

  handleQuestionUpdate = (questionData: IQuestion, quizData: IQuiz) => {
    this.props
      .updateQuestion(questionData)
      .track()
      .subscribe(
        // success
        () => {
          // need to update whole quiz because question property required is part of quiz collection
          if (quizData.id) {
            this.props.updateQuizCollection(quizData, quizData.id);
          }
          this.props.reportMessage({ message: this.props.translate('QUIZ_CREATOR.QUESTION_SUCCESS_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
        }
      );
  };

  handleQuestionOrderChange = (question: IQuestion, oldIndex: number, quiz: IQuiz) => {
    this.props
      .updateQuestion(question)
      .track()
      .subscribe(
        // success
        () => {
          this.handleQuestionListChange(quiz);
        }
      );
  };

  handleQuestionListChange = (updateQuiz: IQuiz) => {
    if (updateQuiz.id) {
      this.props.updateQuizCollection(updateQuiz, updateQuiz.id);
    }
  };

  handleOutcomeSearch = (value: string) => {
    this.props.storeOutcomeListFilter({ title: value });
  };

  /** We are fetching all objects in list so we have predefined collection params. */
  updateOutcomeList = () => {
    this.props.fetchOutcomeList({ filter: this.props.outcomeListFilter, page: 0, size: DEFAULT_PAGE_SIZE, sort: [''] });
  };

  handleOutcomeCreate = (title: string): Observable<IQuestionOutcome> => {
    return this.props.createOutcome({ title }).track();
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IQuizCreatorContainerOwnProps): IQuizCreatorContainerStateProps => ({
  question: QuestionBusinessStore.selectors.getQuestion(state),
  quizCollection: QuizCollectionBusinessStore.selectors.getQuizCollection(state),
  tenantConfig: PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state),
  outcomeList: questionOutcomeListBusinessStore.selectors.getQuestionOutcomeList(state),
  outcomeListFilter: questionOutcomeListBusinessStore.selectors.getQuestionOutcomeListFilter(state),
  userSettings: UserSettingsBusinessStore.selectors.getUserSettings(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IQuizCreatorContainerDispatchProps => ({
  updateQuestion: (data: IQuestion) => createTrackableAction(dispatch(QuestionBusinessStore.actions.updateQuestion(data))),

  fetchOutcomeList: (params: ICollectionFetchPayload<IQuestionOutcomeListFilter>) => dispatch(questionOutcomeListBusinessStore.actions.fetchQuestionOutcomeList(params)),
  createOutcome: (outcomeData: IQuestionOutcomeCreatePayload) => createTrackableAction(dispatch(questionOutcomeListBusinessStore.actions.createQuestionOutcome(outcomeData))),
  storeOutcomeListFilter: (filter: IQuestionOutcomeListFilter) => dispatch(questionOutcomeListBusinessStore.actions.storeQuestionOutcomeListFilter(filter)),

  fetchQuizCollection: (quizId: string) => createTrackableAction(dispatch(QuizCollectionBusinessStore.actions.fetchQuizCollection({ id: quizId }))),
  updateQuizCollection: (data: IQuiz, dataId: string) => createTrackableAction(dispatch(QuizCollectionBusinessStore.actions.updateQuizCollection(data, dataId))),
  clearQuizCollection: () => dispatch(QuizCollectionBusinessStore.actions.clearQuizCollectionData()),
  createQuizCollectionQueston: (quizId: string, questionData: IQuestion) => createTrackableAction(dispatch(QuizCollectionBusinessStore.actions.createQuizCollectionQuestion(quizId, questionData))),

  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<IQuizCreatorContainerStateProps, IQuizCreatorContainerDispatchProps, IQuizCreatorContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withTenantPropEnabled(withLocalize(QuizCreatorContainer) as any));

import React, { useMemo } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { CourseCompletionStatusEnum, ICourseCompletionStatus } from '@src/model/course/Course';
import { Row, Typography } from 'antd';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';

// -- Prop types
// ----------

export interface IExamInstanceInfoPanelOwnProps {
  attemptNumber?: number;
  remainingAttemptNumber?: number;
  courseCompletionStatus?: ICourseCompletionStatus;
}
type IExamInstanceInfoPanelProps = IExamInstanceInfoPanelOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExamInstanceInfoPanel = (props: IExamInstanceInfoPanelProps) => {
  const isExamInstanceInfoPanelVisible: boolean = useMemo(() => {
    return props.attemptNumber != null || CourseHelperUtils.isCourseProgressInStatus([CourseCompletionStatusEnum.COMPLETED], props.courseCompletionStatus);
  }, [props.attemptNumber, props.courseCompletionStatus?.id]);
  return (
    <React.Fragment>
      {isExamInstanceInfoPanelVisible && (
        <Typography.Paragraph className="panel">
          {props.attemptNumber && (
            <React.Fragment>
              <Row>{<Typography.Text className="timun-wrapper__gutterBox--vertical"> {props.translate('EXAM_INSTANCE_LIST.TRAINEE.EXAM_ATTEMPT_NUMBER_ALLOWED_LABEL', { attemptNumber: props.attemptNumber })}</Typography.Text>}</Row>
              <Row>{CourseHelperUtils.isCourseProgressInStatus([CourseCompletionStatusEnum.FAILED], props.courseCompletionStatus) && <Typography.Text className="timun-wrapper__gutterBox--vertical"> {props.translate('EXAM_INSTANCE_LIST.TRAINEE.EXAM_FAILED_LABEL')}</Typography.Text>}</Row>
              <Row>
                {CourseHelperUtils.isCourseProgressInStatus([CourseCompletionStatusEnum.IN_PROGRESS, CourseCompletionStatusEnum.TO_DO], props.courseCompletionStatus) && (
                  <Typography.Text className="timun-wrapper__gutterBox--vertical"> {props.translate('EXAM_INSTANCE_LIST.TRAINEE.REMAINING_EXAM_ATTEMPT_NUMBER_LABEL', { remainingAttemptNumber: props.remainingAttemptNumber })}</Typography.Text>
                )}
              </Row>
            </React.Fragment>
          )}
          <Row>{CourseHelperUtils.isCourseProgressInStatus([CourseCompletionStatusEnum.COMPLETED], props.courseCompletionStatus) && <Typography.Text className="timun-wrapper__gutterBox--vertical"> {props.translate('EXAM_INSTANCE_LIST.TRAINEE.EXAM_PASSED_LABEL')}</Typography.Text>}</Row>
        </Typography.Paragraph>
      )}
    </React.Fragment>
  );
};

export default withLocalize<IExamInstanceInfoPanelOwnProps>(ExamInstanceInfoPanel as any);

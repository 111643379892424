import { IIdDataPayload, ITitlePayload } from '@src/service/business/common/types';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import IIdRef from '@src/model/common/IdRef';
import { IEducationQualification } from '@src/model/educationqualification/EducationQualification';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface IEducationQualificationListFilter {
  name?: string;
}

export interface IEducationQualificationCreatePayload {
  name: string;
  description?: string;
}

// List filter ID
const EDUCATION_QUALIFICATION_LIST_FILTER = '@@EDUCATION_QUALIFICATION_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns EducationQualification list from store. */
const getEducationQualificationList = (store: any): ICollectionData<IEducationQualification> => store.educationQualificationList;

/** Returns EducationQualification list filter. */
const getEducationQualificationListFilter = (store: any): IEducationQualificationListFilter => ListFilterBusinessStore.selectors.getListFilter(store, EDUCATION_QUALIFICATION_LIST_FILTER);

// -
// -------------------- Actions
const Actions = {
  EDUCATION_QUALIFICATION_LIST_FETCH: 'EDUCATION_QUALIFICATION_LIST_FETCH',
  EDUCATION_QUALIFICATION_LIST_LOAD: 'EDUCATION_QUALIFICATION_LIST_LOAD',
  EDUCATION_QUALIFICATION_LIST_CLEAR: 'EDUCATION_QUALIFICATION_LIST_CLEAR',
  EDUCATION_QUALIFICATION_CREATE: 'EDUCATION_QUALIFICATION_CREATE',
  EDUCATION_QUALIFICATION_UPDATE: 'EDUCATION_QUALIFICATION_UPDATE',
  EDUCATION_QUALIFICATION_DELETE: 'EDUCATION_QUALIFICATION_DELETE',
};

/** Fetch EducationQualification list by filter */
const fetchEducationQualificationList = (params: ICollectionFetchPayload<IEducationQualificationListFilter>): IPayloadAction<ICollectionFetchPayload<IEducationQualificationListFilter>> => {
  return {
    type: Actions.EDUCATION_QUALIFICATION_LIST_FETCH,
    payload: params,
  };
};

/** Load EducationQualification list to the store */
const loadEducationQualificationList = (data: ICollectionData<IEducationQualification>): IPayloadAction<ICollectionData<IEducationQualification>> => {
  return {
    type: Actions.EDUCATION_QUALIFICATION_LIST_LOAD,
    payload: data,
  };
};

/** Clear EducationQualification list from store. Eg. when leaving view. */
const clearEducationQualificationList = (): ILemonAction => {
  return {
    type: Actions.EDUCATION_QUALIFICATION_LIST_CLEAR,
  };
};

/** Create EducationQualification */
const createEducationQualification = (data: IEducationQualificationCreatePayload): IPayloadAction<IEducationQualificationCreatePayload> => {
  return {
    type: Actions.EDUCATION_QUALIFICATION_CREATE,
    payload: data,
  };
};

/** Update EducationQualification by id */
const updateEducationQualification = (data: IEducationQualification): IPayloadAction<IIdDataPayload<IEducationQualification>> => {
  return {
    type: Actions.EDUCATION_QUALIFICATION_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

/** Delete EducationQualification by ID */
const deleteEducationQualification = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.EDUCATION_QUALIFICATION_DELETE,
    payload: data,
  };
};

const storeEducationQualificationListFilter = (listFilter: IEducationQualificationListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(EDUCATION_QUALIFICATION_LIST_FILTER, listFilter);
};

const clearEducationQualificationListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(EDUCATION_QUALIFICATION_LIST_FILTER);
};

// -
// -------------------- Side-effects
const fetchEducationQualificationListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IEducationQualificationListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_QUALIFICATION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationQualification')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationQualificationList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_QUALIFICATION.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching EducationQualification list', error);
      return o;
    })
  );
};

const createEducationQualificationEffect = (action$: Observable<IPayloadAction<ITitlePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_QUALIFICATION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationQualification')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_QUALIFICATION.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error creating EducationQualification', error);
      return o;
    })
  );
};

const updateEducationQualificationEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IEducationQualification>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_QUALIFICATION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('EducationQualification')
        .updateEntity(id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_QUALIFICATION.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating EducationQualification', error);
      return o;
    })
  );
};

const deleteEducationQualificationEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_QUALIFICATION_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('EducationQualification')
        .deleteEntity(data.id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_QUALIFICATION.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting EducationQualification', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers
const educationQualificationList = (state: ICollectionData<IEducationQualification> | null = null, action: IPayloadAction<ICollectionData<IEducationQualification>>) => {
  if (action.type === Actions.EDUCATION_QUALIFICATION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EDUCATION_QUALIFICATION_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store
export const EducationQualificationBusinessStore = {
  actions: {
    fetchEducationQualificationList,
    loadEducationQualificationList,
    clearEducationQualificationList,
    createEducationQualification,
    updateEducationQualification,
    deleteEducationQualification,
    storeEducationQualificationListFilter,
    clearEducationQualificationListFilter,
  },

  selectors: {
    getEducationQualificationList,
    getEducationQualificationListFilter,
  },

  effects: {
    fetchEducationQualificationListEffect,
    createEducationQualificationEffect,
    updateEducationQualificationEffect,
    deleteEducationQualificationEffect,
  },

  reducers: {
    educationQualificationList,
  },
};

// --
// export business store
export default EducationQualificationBusinessStore;

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import EducationApplicationTimelineItem from '@src/components/externalEducationApplication/common/EducationApplicationTimelineItem';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import React from 'react';

interface IExternalEducationApplicationActivitiesViewSectionOwnProps {
  id: string;
  timeline: IExternalEducationApplicationTimelineActivity[];
  isNotePublic?: boolean;
  canCreateNotes?: boolean;
  canSetPrivateNote?: boolean;
  onCreateNote?: () => void;
  active?: boolean;
}
type IExternalEducationApplicationActivitiesViewSectionProps = IExternalEducationApplicationActivitiesViewSectionOwnProps & IWithLocalizeOwnProps;

class ExternalEducationApplicationActivitiesViewSection extends React.Component<IExternalEducationApplicationActivitiesViewSectionProps> {
  render = () => {
    return (
      <ViewSectionItem header={<HeaderTitle title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.TITLE')} icon={<LemonIcon name="activities" size="large" />} />} active={this.props.active} collapsible={true} bordered={true}>
        {this.props.timeline &&
          this.props.timeline.map((item: IExternalEducationApplicationTimelineActivity) => {
            return <EducationApplicationTimelineItem key={item.content.id} item={item} />;
          })}
        {this.props.canCreateNotes && <NoteCreateContainer objectPublic={this.props.isNotePublic} canSetPrivateNote={this.props.canSetPrivateNote} onCreateNote={this.props.onCreateNote} objectId={this.props.id} objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION} />}
      </ViewSectionItem>
    );
  };
}

export default withLocalize<IExternalEducationApplicationActivitiesViewSectionOwnProps>(ExternalEducationApplicationActivitiesViewSection as any);

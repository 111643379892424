import React, { useMemo } from 'react';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkill } from '@src/model/skillgroup/Skill';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { ICollectionDataCount } from '@src/service/business/common/types';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { Button, Col, Row } from 'antd';

// -- Const
// ----------

// -- Prop types
// ----------

export interface ICourseSkillListViewOwnProps {
  courseSkillList: ISkill[];
  paginationData?: ICollectionDataCount;
  onPageChange?: (page: number, pageSize?: number) => void;
  onEditingChange?: () => void;
}

type ICourseSkillListViewProps = ICourseSkillListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const CourseSkillListView = (props: ICourseSkillListViewProps) => {
  const columns: ITimunDataTableCol<ISkill>[] = useMemo(
    () => [
      {
        key: 'name',
        contentType: 'string',
        headerTitle: props.translate('COURSE_SKILL_LIST.SKILL_DESCRIPTION_LABEL'),
        content: (skill: ISkill) => skill.title,
      },
      {
        key: 'groupName',
        contentType: 'string',
        headerTitle: props.translate('COURSE_SKILL_LIST.SKILL_GROUP_DESCRIPTION_LABEL'),
        content: (skill: ISkill) => skill.skillGroup.title,
      },
    ],
    [props.translate]
  );

  return (
    <React.Fragment>
      <Row justify="end" className="timun-wrapper__gutterBox--vertical">
        <Col>
          <Button icon={<LemonIcon name="edit" />} onClick={props.onEditingChange}>
            {props.translate('COURSE_SKILL_LIST.SKILL_EDIT_BUTTON_LABEL')}
          </Button>
        </Col>
      </Row>
      <DataTable<ISkill> items={props.courseSkillList} columns={columns} paginationData={props.paginationData} paginationConfig={{ onChange: props.onPageChange }} />
    </React.Fragment>
  );
};

export default withLocalize<ICourseSkillListViewOwnProps>(CourseSkillListView as any);

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import { validateIban } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import moment from 'moment';
import React from 'react';

const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');

interface IExternalEducaitonContractGenerationFromOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  onSubmit?: (data: IExternalEducationApplication) => void;
  hidePaymentInfo?: boolean;
}

type IExternalEducaitonContractGenerationFromProps = IExternalEducaitonContractGenerationFromOwnProps & IWithLocalizeOwnProps;

const ExternalEducaitonContractGenerationFrom = (props: IExternalEducaitonContractGenerationFromProps) => {
  const initialValues = {
    ...props.externalEducationApplication,
    startDateTime: props.externalEducationApplication?.startDateTime ? moment(props.externalEducationApplication?.startDateTime) : undefined,
  };
  const [form] = Form.useForm<IExternalEducationApplication>();
  const handleFinish = () => {
    form.validateFields()
      .then((values: IExternalEducationApplication) => {
        const data = {
          ...props.externalEducationApplication,
          paymentInfo: values.paymentInfo,
          startDateTime: moment(values.startDateTime).format(backendDate),
        };
        props.onSubmit?.(data);
      });
  };

  return <Form<IExternalEducationApplication>
    initialValues={initialValues}
    layout="vertical"
    form={form}
    name="externalEducationApplication"
    onFinish={handleFinish}>
    {/* Start date time of education program */}
    <Row>
      <Col span={12}>
        <Form.Item requiredMark={true} label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.START_DATE_TIME_LABEL')}
          tooltipDescription={props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.START_DATE_TIME_TOOLTIP')} />}
          name={['startDateTime']}
          rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
          <DatePicker className="timun-externalEducationContractGenerationForm__select" placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.START_DATE_TIME_PLACEHOLDER')} allowClear={true} format={DATE_FORMAT} />
        </Form.Item>
      </Col>
    </Row>

    {/* User payment info */}
    {!props.hidePaymentInfo && <React.Fragment>
      <Form.Item name={['paymentInfo', 'id']} noStyle={true} hidden={true}>
        <Input type="hidden" />
      </Form.Item>

      <Row gutter={[16, 16]} align="bottom">
        <Col xs={24} sm={24} md={12}>
          <Form.Item label={props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.BANK_NAME_LABEL')}
            name={['paymentInfo', 'bankName']}
            rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
            <Input data-test-id="timun-externalEducationApplicationPaymentInfoForm__bankName" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item label={props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.IBAN_LABEL')}
            name={['paymentInfo', 'iban']}
            rules={[{ validator: validateIban }, { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
            <Input data-test-id="timun-externalEducationApplicationPaymentInfoForm__iban" />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>}
    <Row>
      <Col>
        <Button type="primary" onClick={form.submit} data-test-id="timun-externalEducationApplicationPaymentInfoForm__submitButton">
          {props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.GENERATE_CONTRACT_BUTTON_TEXT')}
        </Button>
      </Col>
    </Row>
  </Form>;
};

export default withLocalize<IExternalEducaitonContractGenerationFromOwnProps>(ExternalEducaitonContractGenerationFrom as any);

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import HttpErrorException from '@src/service/api/rest/HttpErrorException';
import IHttpRestClient from '@src/service/api/rest/IHttpRestClient';
import { IHttpRestClientOptions } from '@src/service/api/rest/IHttpRestClient';
import TenantManager from '@src/service/business/tenant/TenantManager';
import AppConfigService from '@src/service/common/AppConfigService';

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export default class HttpRestClient implements IHttpRestClient {
  // TODO: replace default options with request interceptors
  constructor(private defaultOptions?: any) {}

  // ---------- Resource CRUD methods

  fetchResource(url: string, resource: string, id: string, queryParams?: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}`,
      method: HttpMethod.GET,
      options: {
        ...this.useDefaultOptions(options),
        params: queryParams,
      },
    });
  }

  createResource(url: string, resource: string, body: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.POST,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  updateResource(url: string, resource: string, id: string, body: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}`,
      method: HttpMethod.PUT,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  deleteResource(url: string, resource: string, id: string, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}`,
      method: HttpMethod.DELETE,
      options: {
        ...this.useDefaultOptions(options),
      },
    });
  }

  updateResourceMethod(url: string, resource: string, id: string, method: string, body: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${method}`,
      method: HttpMethod.PUT,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  // ---------- Resource list CRUD methods

  fetchResourceList(url: string, resource: string, queryParams?: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.GET,
      options: {
        ...this.useDefaultOptions(options),
        params: queryParams,
      },
    });
  }

  // tslint:disable-next-line: no-identical-functions
  createResourceList(url: string, resource: string, body: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.POST,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  deleteResourceList(url: string, resource: string, body: object[], options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.DELETE,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  // ---------- Subresource API

  fetchSubresource(url: string, resource: string, id: string, subresource: string, queryParams?: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.GET,
      options: {
        ...this.useDefaultOptions(options),
        params: queryParams,
      },
    });
  }

  createSubresource(url: string, resource: string, id: string, subresource: string, body: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.POST,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  updateSubresource(url: string, resource: string, id: string, subresource: string, body: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.PUT,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  deleteSubresource(url: string, resource: string, id: string, subresource: string, body: object[], options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.DELETE,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  // ---------- Subresource list API

  // tslint:disable-next-line: no-identical-functions
  fetchSubresourceList(url: string, resource: string, id: string, subresource: string, queryParams?: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.GET,
      options: {
        ...this.useDefaultOptions(options),
        params: queryParams,
      },
    });
  }

  // tslint:disable-next-line: no-identical-functions
  createSubresourceList(url: string, resource: string, id: string, subresource: string, body: object[], options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.POST,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  // tslint:disable-next-line: no-identical-functions
  updateSubresourceList(url: string, resource: string, id: string, subresource: string, body: object[], options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.PUT,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  // tslint:disable-next-line: no-identical-functions
  deleteSubresourceList(url: string, resource: string, id: string, subresource: string, body: object[], options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.DELETE,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  // ---------- Custom resource method methods

  fetchMethod(url: string, resource: string, method: string, queryParams?: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${method}`,
      method: HttpMethod.GET,
      options: {
        ...this.useDefaultOptions(options),
        params: queryParams,
      },
    });
  }

  // tslint:disable-next-line: no-identical-functions
  fetchNoMethod(url: string, resource: string, queryParams?: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.GET,
      options: {
        ...this.useDefaultOptions(options),
        params: queryParams,
      },
    });
  }

  createMethod(url: string, resource: string, method: string, body?: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${method}`,
      method: HttpMethod.POST,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  updateMethod(url: string, resource: string, method: string, body: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${method}`,
      method: HttpMethod.PUT,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  deleteMethod(url: string, resource: string, method: string, body: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${method}`,
      method: HttpMethod.DELETE,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  deleteNoMethod(url: string, resource: string, body?: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.DELETE,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  createSubmethod(url: string, resource: string, id: string, submethod: string, body?: object, options?: IHttpRestClientOptions): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${submethod}`,
      method: HttpMethod.POST,
      options: {
        ...this.useDefaultOptions(options),
        data: body,
      },
    });
  }

  // ---------- REST API methods

  protected useDefaultOptions(options?: IHttpRestClientOptions): AxiosRequestConfig {
    // construct API default params
    const defaultParamsConfig = AppConfigService.getValue('api.defaultParams');

    return {
      ...(this.defaultOptions || {}),

      // TODO: push this config from app init script
      ...(defaultParamsConfig || {}),

      // default parameter serializer
      paramsSerializer(params: any) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },

      // inject tenant ID into default headers
      headers: {
        TenantID: TenantManager.getTenantCode(),

        // other headers (put this after manual entries to allow override by callers)
        ...(defaultParamsConfig?.headers || {}),
      },

      // add request options
      ...options,
      // override onUploadProgress property for some handling
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        options?.onUploadProgress?.({ percent: percentCompleted });
      },
    };
  }

  // tslint:disable-next-line:type-literal-delimiter
  protected doRequest(request: { uri: string; method: HttpMethod; options?: AxiosRequestConfig }): Observable<any> {
    request.options = {
      ...(request.options || {}),
    };

    return from(
      axios.request({
        method: request.method,
        url: request.uri,

        // other options (put this after manual entries to allow override by callers)
        ...(request.options || {}),
      })
    ).pipe(
      // extract response content
      map((httpResponse: AxiosResponse) => {
        // throw error to trigger error handlers downstream
        if (httpResponse.status !== 200) {
          throw new HttpErrorException(httpResponse.status, httpResponse.statusText);
        }

        return httpResponse.data;
      })
    );
  }
}

import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';

export interface IProgressTracker {
  id: string;
  type: IProgressTrackerType;
  status: IProgressTrackerStatus;
  createdDateTime: string;
  finishedDateTime: string;
}

export interface IProgressTrackerType extends ICodeBookEntry<ProgressTrackerTypeEnum> {}

export enum ProgressTrackerTypeEnum {
  USER_PARSER = '1',
  REPORT_CREATION = '2',
  TRAINEE_ENROLLMENT = '3',
  GENERIC_NOTIFICATION = '4',
  LDAP_LOG = '5',
}

export interface IProgressTrackerStatus extends ICodeBookEntry<ProgressTrackerStatusEnum> {}

export enum ProgressTrackerStatusEnum {
  IN_PROGRESS = '1',
  COMPLETED = '2',
  FAILED = '3',
}

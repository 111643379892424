import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import StatusBadge from '@src/components/common/status/StatusBadge';
import TimunShortInfoTable from '@src/components/common/table/TimunShortInfoTable';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { IShortInfo } from '@src/model/common/IShortInfo';
import { ICollectionData } from '@src/service/business/common/types';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Col, Row, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';

// -- Const
// ----------
const path = '/administration/codebooks';

// -- Prop types
// ----------

interface ICodebookItem extends IShortInfo {
  ordering?: number;
  active?: boolean;
}

interface ICodebookListViewStateProps {}

interface ICodebookListViewOwnProps<T> {
  title: string;
  titlePropPath?: string;
  customDescription?: (record?: T) => React.ReactNode;
  descriptionPrefix?: string;
  buttonLabel?: string;
  codebookItemList: ICollectionData<T>;
  showOrderingColumn?: boolean;
  showBackColumn?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  createDisabled?: boolean;

  onEditCodebookItemClick?: (codebookItem: T) => void;
  onCreateCodebookItemClick?: () => void;
  onCodebookItemDelete?: (codebookItem: T) => void;
  onPageChange: (page: number, size?: number) => void;
}

type ICodebookListViewProps<T> = ICodebookListViewOwnProps<T>;

// -- Component
// ----------

/** Display codebook list of items */
class CodebookListView<T extends ICodebookItem> extends React.Component<ICodebookListViewProps<T>, ICodebookListViewStateProps> {
  render() {
    const codeBookActionColumns = {
      edit: { showButton: true, onClick: this.props.onEditCodebookItemClick },
    };

    const additionalColumns = () => <Column key="ordering" width="5%" align="center" render={(record: T) => record.ordering} />;
    const statusColumns = () => <Column key="ordering" width="5%" align="center" render={(record: T) => <StatusBadge status={record.active} />} />;

    return (
      <React.Fragment>
        <Row justify="space-between">
          <Col>
            <HeaderTitle title={LocalizeService.translate('CODEBOOK.CODEBOOK_TITLE')} link={path} icon={<LemonIcon name="left" />} />
          </Col>
          <Col>
            <Button disabled={this.props.createDisabled} type="primary" icon={<LemonIcon name="plus" />} onClick={this.props.onCreateCodebookItemClick}>
              {this.props.buttonLabel}
            </Button>
          </Col>
        </Row>
        <Typography.Title>{this.props.title}</Typography.Title>
        <TimunShortInfoTable<T>
          titlePropPath={this.props.titlePropPath}
          items={this.props.codebookItemList.content}
          paginationData={this.props.codebookItemList.page}
          renderFrontAdditionalColumns={this.props.showOrderingColumn ? additionalColumns : undefined}
          renderBackAdditionalColumns={this.props.showBackColumn ? statusColumns : undefined}
          rowDescription={this.renderGradeRowDescription}
          paginationConfig={{ onChange: this.props.onPageChange }}
          actionButtons={codeBookActionColumns}
        />
      </React.Fragment>
    );
  }

  renderGradeRowDescription = (record: T) => {
    if (this.props.customDescription) {
      return this.props.customDescription(record);
    }
    if (record.description) {
      return (
        <GridItem key={record.id} contentType="secondary" layout="horizontal" label={this.props.descriptionPrefix && LocalizeService.translate(this.props.descriptionPrefix)}>
          {record.description}
        </GridItem>
      );
    }
    return;
  };
}

// -- HOCs and exports
// ----------

export default CodebookListView;

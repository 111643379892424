import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { IEnrollmentRequirement } from '@src/model/enrollmentrequirement/EnrollmentRequirement';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export type IEnrollmentRequirementCreatePayload = Pick<IEnrollmentRequirement, Exclude<keyof IEnrollmentRequirement, 'id' | 'description'>>;

export interface IEnrollmentRequirementListFilter {
  name?: string;
  active?: boolean;
}

// List filter ID
const ENROLLMENT_REQUIREMENT_LIST_FILTER = '@@ENROLLMENT_REQUIREMENT_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns EnrollmentRequirement from store. */
const getEnrollmentRequirement = (store: any): IEnrollmentRequirement => store.enrollmentRequirement;

/** Returns list of EnrollmentRequirements from store. */
const getEnrollmentRequirementList = (store: any): ICollectionData<IEnrollmentRequirement> => store.enrollmentRequirementList;

/** Returns EnrollmentRequirement list filter. */
const getEnrollmentRequirementListFilter = (store: any): IEnrollmentRequirementListFilter => ListFilterBusinessStore.selectors.getListFilter(store, ENROLLMENT_REQUIREMENT_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  ENROLLMENT_REQUIREMENT_FETCH: 'ENROLLMENT_REQUIREMENT_FETCH',
  ENROLLMENT_REQUIREMENT_LOAD: 'ENROLLMENT_REQUIREMENT_LOAD',
  ENROLLMENT_REQUIREMENT_CLEAR: 'ENROLLMENT_REQUIREMENT_CLEAR',
  ENROLLMENT_REQUIREMENT_CREATE: 'ENROLLMENT_REQUIREMENT_CREATE',
  ENROLLMENT_REQUIREMENT_UPDATE: 'ENROLLMENT_REQUIREMENT_UPDATE',
  ENROLLMENT_REQUIREMENT_LIST_FETCH: 'ENROLLMENT_REQUIREMENT_LIST_FETCH',
  ENROLLMENT_REQUIREMENT_LIST_LOAD: 'ENROLLMENT_REQUIREMENT_LIST_LOAD',
  ENROLLMENT_REQUIREMENT_LIST_CLEAR: 'ENROLLMENT_REQUIREMENT_LIST_CLEAR',
};

/** Fetch EnrollmentRequirement by ID. */
const fetchEnrollmentRequirement = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.ENROLLMENT_REQUIREMENT_FETCH,
    payload: params,
  };
};

/** Load EnrollmentRequirement to store. */
const loadEnrollmentRequirement = (data: IEnrollmentRequirement): IPayloadAction<IEnrollmentRequirement> => {
  return {
    type: Actions.ENROLLMENT_REQUIREMENT_LOAD,
    payload: data,
  };
};

/** Clear EnrollmentRequirement from store. Eg. when leaving view. */
const clearEnrollmentRequirementData = (): ILemonAction => {
  return {
    type: Actions.ENROLLMENT_REQUIREMENT_CLEAR,
  };
};

/** Create new EnrollmentRequirement. */
const createEnrollmentRequirement = (data: IEnrollmentRequirementCreatePayload): IPayloadAction<IEnrollmentRequirementCreatePayload> => {
  return {
    type: Actions.ENROLLMENT_REQUIREMENT_CREATE,
    payload: data,
  };
};

/** Update EnrollmentRequirement by ID. */
const updateEnrollmentRequirement = (data: IEnrollmentRequirement): IPayloadAction<IEnrollmentRequirement> => {
  return {
    type: Actions.ENROLLMENT_REQUIREMENT_UPDATE,
    payload: data,
  };
};

/** Fetch EnrollmentRequirement list by filter. */
const fetchEnrollmentRequirementList = (params: ICollectionFetchPayload<IEnrollmentRequirementListFilter>): IPayloadAction<ICollectionFetchPayload<IEnrollmentRequirementListFilter>> => {
  return {
    type: Actions.ENROLLMENT_REQUIREMENT_LIST_FETCH,
    payload: params,
  };
};

/** Load EnrollmentRequirement list to store. */
const loadEnrollmentRequirementList = (data: ICollectionData<IEnrollmentRequirement>): IPayloadAction<ICollectionData<IEnrollmentRequirement>> => {
  return {
    type: Actions.ENROLLMENT_REQUIREMENT_LIST_LOAD,
    payload: data,
  };
};

/** Clear EnrollmentRequirement list from store. Eg. when leaving list view. */
const clearEnrollmentRequirementList = (): ILemonAction => {
  return {
    type: Actions.ENROLLMENT_REQUIREMENT_LIST_CLEAR,
  };
};

/** Store EnrollmentRequirement list filter to store. */
const storeEnrollmentRequirementListFilter = (listFilter: IEnrollmentRequirementListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(ENROLLMENT_REQUIREMENT_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchEnrollmentRequirementEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ENROLLMENT_REQUIREMENT_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('EnrollmentRequirement')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEnrollmentRequirement(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EnrollmentRequirement_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'ENROLLMENT_REQUIREMENT.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching EnrollmentRequirement', error);
      return o;
    })
  );
};

const createEnrollmentRequirementEffect = (action$: Observable<IPayloadAction<IEnrollmentRequirementCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ENROLLMENT_REQUIREMENT_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EnrollmentRequirement')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEnrollmentRequirement(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EnrollmentRequirement_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'ENROLLMENT_REQUIREMENT.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating EnrollmentRequirement', error);
      return o;
    })
  );
};

const updateEnrollmentRequirementEffect = (action$: Observable<IPayloadAction<IEnrollmentRequirement>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ENROLLMENT_REQUIREMENT_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('EnrollmentRequirement')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEnrollmentRequirement(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'ENROLLMENT_REQUIREMENT.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating EnrollmentRequirement', error);
      return o;
    })
  );
};

const fetchEnrollmentRequirementListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IEnrollmentRequirementListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ENROLLMENT_REQUIREMENT_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EnrollmentRequirement')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEnrollmentRequirementList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'ENROLLMENT_REQUIREMENT.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching EnrollmentRequirement list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const enrollmentRequirement = (state: IEnrollmentRequirement | null = null, action: IPayloadAction<IEnrollmentRequirement>) => {
  if (action.type === Actions.ENROLLMENT_REQUIREMENT_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.ENROLLMENT_REQUIREMENT_CLEAR) {
    return null;
  }

  return state;
};

const enrollmentRequirementList = (state: ICollectionData<IEnrollmentRequirement> | null = null, action: IPayloadAction<ICollectionData<IEnrollmentRequirement>>) => {
  if (action.type === Actions.ENROLLMENT_REQUIREMENT_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.ENROLLMENT_REQUIREMENT_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const EnrollmentRequirementListBusinessStore = {
  actions: {
    fetchEnrollmentRequirement,
    loadEnrollmentRequirement,
    clearEnrollmentRequirementData,
    createEnrollmentRequirement,
    updateEnrollmentRequirement,
    fetchEnrollmentRequirementList,
    loadEnrollmentRequirementList,
    clearEnrollmentRequirementList,
    storeEnrollmentRequirementListFilter,
  },

  selectors: {
    getEnrollmentRequirement,
    getEnrollmentRequirementList,
    getEnrollmentRequirementListFilter,
  },

  effects: {
    fetchEnrollmentRequirementEffect,
    createEnrollmentRequirementEffect,
    updateEnrollmentRequirementEffect,
    fetchEnrollmentRequirementListEffect,
  },

  reducers: {
    enrollmentRequirement,
    enrollmentRequirementList,
  },
};

// --
// export business store
export default EnrollmentRequirementListBusinessStore;

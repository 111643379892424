import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SkillBasedRecommendationListItem from '@src/components/skillbasedrecommendations/list/SkillBasedRecommendationListItem';
import { IChartDataItem } from '@src/components/user/statistics/TimunRadarChart';
import { ISkillBasedRecommendation } from '@src/model/skillbasedrecommendation/SkillBasedRecommendation';
import { Empty, List } from 'antd';
import React from 'react';

export interface ISkillBasedRecommendationListOwnProps {
  recommendations: ISkillBasedRecommendation[];
  selectedEntitySkills?: IChartDataItem[];
}

type ISkillBasedRecommendationListProps = ISkillBasedRecommendationListOwnProps & IWithLocalizeOwnProps;

class SkillBasedRecommendationList extends React.Component<ISkillBasedRecommendationListProps> {
  render = () => {
    return (
      <React.Fragment>
        <List
          itemLayout="horizontal"
          className="timun_recommendations_list"
          bordered={false}
          dataSource={this.props.recommendations}
          renderItem={(recommendation: ISkillBasedRecommendation) => <SkillBasedRecommendationListItem recommendation={recommendation} selectedEntitySkills={this.props.selectedEntitySkills} />}
          locale={{
            emptyText: <Empty description={this.props.translate('COMMON.MESSAGE_NO_MATCH')} />,
          }}
        />
      </React.Fragment>
    );
  };
}

export default withLocalize<ISkillBasedRecommendationListOwnProps>(SkillBasedRecommendationList as any);

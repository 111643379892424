import AppContent from '@src/components/common/container/AppContent';
import React, { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import AdministrationSkillViewSider from '@src/components/administrationskills/view/AdministrationSkillViewSider';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

const { Sider, Content } = Layout;

export interface IAdministrationSkillViewContaineOwnProps {
  currentPage: string;
  children: React.ReactNode;
}

type IAdministrationSkillViewContainerProps = IWithLocalizeOwnProps & IAdministrationSkillViewContaineOwnProps;


const AdministrationSkillViewContainer = (props: PropsWithChildren<IAdministrationSkillViewContainerProps>) => {
  return (
    <AppContent>
      <Layout>
        <Layout>
          <Sider className="timun-courseView__sider" breakpoint="lg" collapsedWidth={0}>
            <AdministrationSkillViewSider selected={props.currentPage} />
          </Sider>
          <Content className="timun-courseView__content">
            <React.Fragment>
              {props.children}
            </React.Fragment>
          </Content>
        </Layout>
      </Layout>

    </AppContent>
  );
}

export default withLocalize(AdministrationSkillViewContainer) as any;
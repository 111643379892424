export enum ExternalEducationApplicationFileTypeEnum {
  CURRICULUM_VITAE = 'CURRICULUM_VITAE',
  ENROLLMENT_REQUIREMENT = 'ENROLLMENT_REQUIREMENT',
  OFFER = 'OFFER',
  FINANCING_AGREEMENT = 'FINANCING_AGREEMENT',
  SIGNED_FINANCING_AGREEMENT = 'SIGNED_FINANCING_AGREEMENT',
  MUTUALLY_SIGNED_FINANCING_AGREEMENT = 'MUTUALLY_SIGNED_FINANCING_AGREEMENT',
  PROOF_OF_PAYMENT = 'PROOF_OF_PAYMENT',
  ATTENDANCE_CERTIFICATE = 'ATTENDANCE_CERTIFICATE',
  COMPETENCIES_CERTIFICATE = 'COMPETENCIES_CERTIFICATE',
  APPLICATION_EXPORT = 'APPLICATION_EXPORT',
  CONTRACT_ANNEX = 'CONTRACT_ANNEX',
  SIGNED_CONTRACT_ANNEX = 'SIGNED_CONTRACT_ANNEX',
  MUTUALLY_SIGNED_CONTRACT_ANNEX = 'MUTUALLY_SIGNED_CONTRACT_ANNEX',
  REFERRAL = 'REFERRAL',
}

export enum ExternalEducationTemplateFileTypeEnum {
  EDUCATION_EXECUTION_APPROVAL = 'EDUCATION_EXECUTION_APPROVAL',
  CURRICULUM = 'CURRICULUM',
}

export enum UserFileTypeEnum {
  GENERAL = 'GENERAL',
  CURRICULUM_VITAE = 'CURRICULUM_VITAE',
  COMPLETED_EDUCATION_CERTIFICATE = 'COMPLETED_EDUCATION_CERTIFICATE',
  SIGNED_FINANCING_AGREEMENT = 'SIGNED_FINANCING_AGREEMENT',
  ID_CARD = 'ID_CARD',
  ADDITIONAL_CERTIFICATE = 'ADDITIONAL_CERTIFICATE',
}
export type FileTypeEnum = ExternalEducationApplicationFileTypeEnum | ExternalEducationTemplateFileTypeEnum | UserFileTypeEnum;

export const FileTypeEnum = { ...ExternalEducationApplicationFileTypeEnum, ...ExternalEducationTemplateFileTypeEnum, ...UserFileTypeEnum };

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationReportListFilterInfoPanel from '@src/components/externalEducationApplication/filter/common/EducationApplicationReportListFilterInfoPanel';
import EducationApplicationStatisticsCount from '@src/components/externalEducationApplication/statistics/EducationApplicationStatisticsCount';
import EducationApplicationStatisticsExpenses from '@src/components/externalEducationApplication/statistics/EducationApplicationStatisticsExpenses';
import EducationApplicationStatisticSurveyReport from '@src/components/externalEducationApplication/statistics/EducationApplicationStatisticSurveyReport';
import { IExternalEducationApplicationStatistics } from '@src/model/externalEducationApplication/ExternalEducationApplicationStatistics';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { Button, Modal } from 'antd';
import React from 'react';


interface IExternalEducationApplicationStatisticsViewOwnProps {
  onCancel?: () => void;
  educationApplicationStatistics: IExternalEducationApplicationStatistics;
  applicationListFilter: IExternalEducationApplicationListFilter;
}

type IExternalEducationApplicationStatisticsViewProps = IExternalEducationApplicationStatisticsViewOwnProps & IWithLocalizeOwnProps;

class ExternalEducationApplicationStatisticsView extends React.Component<IExternalEducationApplicationStatisticsViewProps, any> {
  render() {

    return (
      <Modal
        className="lemon-modal__modal--lg"
        title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.MODAL.STATISTICS_TITLE')}
        onCancel={this.props.onCancel}
        visible={true}
        footer={
          <Button key="back" onClick={this.props.onCancel} data-test-id="timun-externalEducationApplicationStatisticsModal__closeButton">
            {this.props.translate('COMMON.ACTION_CLOSE')}
          </Button>
        }
      >
        {/* Selected filter information */}
        {this.props.applicationListFilter && <EducationApplicationReportListFilterInfoPanel filter={this.props.applicationListFilter} />}

        <EducationApplicationStatisticsCount
          numberOfApplications={this.props.educationApplicationStatistics.numberOfApplications}
          countPerApplicationStatus={this.props.educationApplicationStatistics.countPerApplicationStatus}
          countPerEmploymentStatus={this.props.educationApplicationStatistics.countPerEmploymentStatus}
          countPerEducationCategory={this.props.educationApplicationStatistics.countPerEducationCategory}
          countPerUserCategory={this.props.educationApplicationStatistics.countPerUserCategory}
        />

        <EducationApplicationStatisticsExpenses averagePerExpenseTypes={this.props.educationApplicationStatistics.averagePerExpenseType} />

        <EducationApplicationStatisticSurveyReport surveyReportPerTypes={this.props.educationApplicationStatistics.surveyReportPerType} />

      </Modal>
    );
  }
}

export default withLocalize<IExternalEducationApplicationStatisticsViewOwnProps>(ExternalEducationApplicationStatisticsView as any);

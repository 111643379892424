import DeleteButton from '@src/components/common/button/DeleteButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import { Button, Card, Tooltip } from 'antd';
import React, { ReactNode, SyntheticEvent } from 'react';

// -- Const
// ----------
export enum TimunActionTypeEnum {
  EDIT_CARD = 'editCard',
  EDIT_COVER = 'editCover',
  DELETE_CARD = 'deleteCard',
}

// -- Prop types
// ----------
export interface ITimunCardOwnProps {
  title?: string;
  showButtons?: boolean;
  coverImageUrl?: string;
  description?: string | ReactNode;
  onEdit?: () => void;
  onDelete?: () => void;
  onUpdateCoverImage?: () => void;
  onCardClick?: () => void;
}

type ITimunCardProps = ITimunCardOwnProps;

// -- Component
// ----------

/** Component display card information */
const TimunCard: React.FC<ITimunCardProps> = (props) => {
  const onUpdateCoverImage = (event: SyntheticEvent) => {
    event.stopPropagation();
    props.onUpdateCoverImage?.();
  };

  const onEdit = (event: SyntheticEvent) => {
    event.stopPropagation();
    props.onEdit?.();
  };
  return (
    <Card
      className="timun-timunCard__content"
      onClick={props.onCardClick}
      cover={<LemonImage imagePath={props.coverImageUrl || 'cover-placeholder.png'} className="timun-courseCard__coverImage" />}
      actions={
        props.showButtons
          ? [
              <Button icon={<LemonIcon name="plus" />} key={TimunActionTypeEnum.EDIT_COVER} onClick={onUpdateCoverImage} />,
              <Button icon={<LemonIcon name="edit" />} key={TimunActionTypeEnum.EDIT_CARD} onClick={onEdit} />,
              <DeleteButton icon={<LemonIcon name="delete" />} key={TimunActionTypeEnum.DELETE_CARD} onConfirm={props.onDelete} />,
            ]
          : []
      }
    >
      <Card.Meta className="text-center" title={<Tooltip overlay={props.title}>{props.title}</Tooltip>} description={props.description} />
    </Card>
  );
};

export default TimunCard;

import React, { useCallback } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationApplicationBaseInfo } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { ICollectionData } from '@src/service/business/common/types';
import { Button, Tag } from 'antd';
import { ActivityUtils } from '@src/service/util/activity/ActivityUtils';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { DateUtils } from '@src/service/util/date/DateUtils';

// -- Prop types
// ----------

export interface IOrganizationEducationApplicationListOwnProps {
  externalEducationApplicationRefList: ICollectionData<IExternalEducationApplicationBaseInfo>;
  onPageChange?: (page: number, pageSize?: number) => void;
  additionalCols?: () => ITimunDataTableCol<IExternalEducationApplicationBaseInfo>[];
  onEducationApplicationSelect?: (externalEducationApplication: IExternalEducationApplicationBaseInfo) => void;
}
type IOrganizationEducationApplicationListProps = IOrganizationEducationApplicationListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const OrganizationEducationApplicationList: React.FC<IOrganizationEducationApplicationListProps> = (props: IOrganizationEducationApplicationListProps) => {
  const renderColumns = useCallback(() => {
    const columns: ITimunDataTableCol<IExternalEducationApplicationBaseInfo>[] = [
      {
        key: 'offerCode',
        contentType: 'string',
        headerTitle: props.translate('ORGANIZATION_EXTERNAL_EDUCATION_TEMPLATE.LIST.OFFER_CODE_HEADER_LABEL'),
        content: (record: IExternalEducationApplicationBaseInfo) => record.offerCode,
      },
      {
        key: 'educationTemplateName',
        contentType: 'string',
        headerTitle: props.translate('ORGANIZATION_EXTERNAL_EDUCATION_TEMPLATE.LIST.EXTERNAL_EDUCATION_TEMPLATE_HEADER_LABEL'),
        content: (record: IExternalEducationApplicationBaseInfo) => record.externalEducationTemplate?.title,
      },
      {
        key: 'assignee',
        contentType: 'string',
        headerTitle: props.translate('ORGANIZATION_EXTERNAL_EDUCATION_TEMPLATE.LIST.ASSIGNEE_HEADER_LABEL'),
        content: (record: IExternalEducationApplicationBaseInfo) => ActivityUtils.getParticipantNameByRole('ASSIGNEE', record.activity),
      },
      {
        key: 'educationApplicationPhase',
        contentType: 'string',
        headerTitle: props.translate('ORGANIZATION_EXTERNAL_EDUCATION_TEMPLATE.LIST.EXTERNAL_EDUCATION_APPLICATION_PHASE_HEADER_LABEL'),
        content: (record: IExternalEducationApplicationBaseInfo) => {
          const phaseName = ActivityUtils.getPhaseName(record.activity);
          return <Tag className="timun-tag__default">{props.translate(`EXTERNAL_EDUCATION_APPLICATION.PHASE.${phaseName}_LABEL`)} </Tag>;
        },
      },
      {
        key: 'paymentRequestedDateTime',
        contentType: 'string',
        headerTitle: props.translate('ORGANIZATION_EXTERNAL_EDUCATION_TEMPLATE.LIST.EXTERNAL_EDUCATION_APPLICATION_PAYMENT_REQUESTED_DATE_TIME_HEADER_LABEL'),
        content: (record: IExternalEducationApplicationBaseInfo) => (record.paymentRequestedDateTime && DateUtils.toViewDateFormat(record.paymentRequestedDateTime, 'dateWithYear')) ?? '-',
      },
      {
        key: 'endDateTime',
        contentType: 'string',
        headerTitle: props.translate('ORGANIZATION_EXTERNAL_EDUCATION_TEMPLATE.LIST.EXTERNAL_EDUCATION_APPLICATION_END_DATE_TIME_HEADER_LABEL'),
        content: (record: IExternalEducationApplicationBaseInfo) => (record.endDateTime && DateUtils.toViewDateFormat(record.endDateTime, 'dateWithYear')) ?? '-',
      },
      {
        key: 'action',
        contentType: 'string',
        headerTitle: '',
        content: (record: IExternalEducationApplicationBaseInfo) =>
          ActivityUtils.isInPhases([EducationApplicationActivityPhaseEnum.EDUCATION_IN_PROGRESS, EducationApplicationActivityPhaseEnum.EDUCATION_COMPLETED], record.activity) &&
          record.paymentRequestedDateTime == null && <Button onClick={() => props.onEducationApplicationSelect?.(record)}> {props.translate('ORGANIZATION_EXTERNAL_EDUCATION_TEMPLATE.LIST.ACTION_REQUEST_PAYMENT_BUTTON_LABEL')}</Button>,
      },
    ];
    if (props.additionalCols) {
      columns.push(...props.additionalCols());
    }
    return columns;
  }, [props.additionalCols]);
  return (
    <React.Fragment>
      <DataTable items={props.externalEducationApplicationRefList.content} paginationData={props.externalEducationApplicationRefList.page} columns={renderColumns()} scrollable={true} paginationConfig={{ onChange: props.onPageChange }} showActionMenu={true} />
    </React.Fragment>
  );
};

export default withLocalize<IOrganizationEducationApplicationListOwnProps>(OrganizationEducationApplicationList as any);

import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import EducationQualificationForm from '@src/components/codebook/form/EducationQualificationForm';
import CodebookListView from '@src/components/codebook/view/CodebookList';
import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEducationQualification } from '@src/model/educationqualification/EducationQualification';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import EducationQualificationBusinessStore, { IEducationQualificationCreatePayload, IEducationQualificationListFilter } from '@src/service/business/educationqualification/EducationQualificationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const VIEW_NAME = '@@EDUCATION_QUALIFICATION_CODEBOOK_LIST';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');

// -- Prop types
// ----------
export interface IEducationQualificationCodebookContainerOwnProps {
}

export interface IEducationQualificationCodebookContainerStateProps {
  educationQualificationList: ICollectionData<IEducationQualification>;
}

export interface IEducationQualificationCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchEducationQualificationList: (params: ICollectionFetchPayload<IEducationQualificationListFilter>) => ITrackableAction;
  createEducationQualification: (data: IEducationQualificationCreatePayload) => ITrackableAction;
  updateEducationQualification: (data: IEducationQualification) => ITrackableAction;
  deleteEducationQualification: (id: string) => ITrackableAction;
}

type IEducationQualificationCodebookContainerProps = IEducationQualificationCodebookContainerOwnProps & IEducationQualificationCodebookContainerStateProps
  & IEducationQualificationCodebookContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Education qualification container */
const EducationQualificationCodebookContainer = (props: IEducationQualificationCodebookContainerProps) => {
  const [educationQualificationModalsState, toggleEducationQualificationModals] = useEntityModalsState<IEducationQualification>();
  const [, updateCollectionParams, onUpdateList] = useCollectionState<IEducationQualificationListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchEducationQualificationList,
    initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
  });
  const handleEducationQualificationDeleteSubmit = useCallback((data: IEducationQualification) => {
    props.deleteEducationQualification(data.id).track().subscribe(
      // success
      () => {
        props.reportMessage({ message: props.translate('EDUCATION_QUALIFICATION.DELETE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        onUpdateList();
      }
    );
  }, [props.deleteEducationQualification, props.reportMessage]);

  const handleEducationQualificationCreateSubmit = useCallback((data: IEducationQualificationCreatePayload) => {
    props.createEducationQualification(data).track().subscribe(
      // success
      () => {
        props.reportMessage({ message: props.translate('EDUCATION_QUALIFICATION.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        onUpdateList();
        toggleEducationQualificationModals.onCloseCreate();
      }
    );
  }, [props.createEducationQualification, props.reportMessage, onUpdateList, toggleEducationQualificationModals]);

  const handleEducationQualificationUpdateSubmit = useCallback((data: IEducationQualification) => {
    props.updateEducationQualification(data).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleEducationQualificationModals.onCloseUpdate();
      }
    );
  }, [props.updateEducationQualification, props.reportMessage, onUpdateList, toggleEducationQualificationModals]);

  return (
    <React.Fragment>
      {props.educationQualificationList && <CodebookListView<IEducationQualification>
        title={props.translate(`CODEBOOK.TITLE_LABEL.${CodebookEnum.EDUCATION_QUALIFICATION}`)}
        buttonLabel={props.translate('EDUCATION_QUALIFICATION.CREATE_EDUCATION_QUALIFICATION_BUTTON_LABEL')}
        codebookItemList={props.educationQualificationList}
        canEdit={true}
        onEditCodebookItemClick={toggleEducationQualificationModals.onOpenUpdate}
        onCreateCodebookItemClick={toggleEducationQualificationModals.onOpenCreate}
        canDelete={true}
        onCodebookItemDelete={handleEducationQualificationDeleteSubmit}
        onPageChange={updateCollectionParams.onPageChange}
      />}

      {(educationQualificationModalsState.isUpdateModalVisible || educationQualificationModalsState.isCreateModalVisible) && <EducationQualificationForm
        educationQualification={educationQualificationModalsState.selectedEntity}
        onCancel={toggleEducationQualificationModals.onCloseCreate}
        onEducationQualificationCreateSubmit={handleEducationQualificationCreateSubmit}
        onEducationQualificationUpdateSubmit={handleEducationQualificationUpdateSubmit} />}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationQualificationCodebookContainerOwnProps): IEducationQualificationCodebookContainerStateProps => ({
  educationQualificationList: EducationQualificationBusinessStore.selectors.getEducationQualificationList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationQualificationCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchEducationQualificationList: (params: ICollectionFetchPayload<IEducationQualificationListFilter>) => createTrackableAction(dispatch(EducationQualificationBusinessStore.actions.fetchEducationQualificationList(params))),
  createEducationQualification: (data: IEducationQualificationCreatePayload) => createTrackableAction(dispatch(EducationQualificationBusinessStore.actions.createEducationQualification(data))),
  deleteEducationQualification: (id: string) => createTrackableAction(dispatch(EducationQualificationBusinessStore.actions.deleteEducationQualification({ id }))),
  updateEducationQualification: (data: IEducationQualification) => createTrackableAction(dispatch(EducationQualificationBusinessStore.actions.updateEducationQualification(data))),
});

export default connect<IEducationQualificationCodebookContainerStateProps, IEducationQualificationCodebookContainerDispatchProps, IEducationQualificationCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(EducationQualificationCodebookContainer as any));

import React, { useEffect } from 'react';

import FileTypeUploadField from '@src/components/common/file/FileTypeUploadField';
import { validateCheckBoxValue } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { FileTypeEnum } from '@src/model/file/FileType';
import { hasFilesInTypes, IFileListsByType } from '@src/service/business/files/util';
import { Checkbox, Col, Form, Row } from 'antd';
import { IFile } from '@src/model/file/File';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';

// -- Prop types
// ----------
export interface IFormItemFileUploadOwnProps {
  userFiles: IFileListsByType;
  fileType: keyof typeof FileTypeEnum;
  allowedFileTypes?: (keyof typeof FileTypeEnum)[];
  dataTestIdPrefix?: string;
  required?: boolean;
  label?: string;
  /** info text will display info icon with tooltip next to label */
  infoText?: string;
  requiredMessage?: string;
  canRemoveFile?: boolean;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}
type IFormItemFileUploadProps = IFormItemFileUploadOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
/** Form item field file upload with validation. */
const FormItemFileUpload: React.FC<IFormItemFileUploadProps> = (props: IFormItemFileUploadProps) => {
  const form = Form.useFormInstance();
  useEffect(() => {
    if (hasFilesInTypes(props.userFiles, [props.fileType])) {
      form.setFieldsValue({
        [props.fileType]: true,
      });
    } else {
      form.setFieldsValue({
        [props.fileType]: false,
      });
    }
  }, [props.userFiles[props.fileType]]);

  return (
    <Row>
      <Col xs={24} md={24} lg={12}>
        <Form.Item required={props.required} label={props.infoText ? <FormItemLabelIcon label={props.label} tooltipDescription={props.infoText} /> : props.label}>
          <FileTypeUploadField fileList={props.userFiles} defaultFileType={props.fileType} onFileUpload={props.onFileUpload} onFileRemove={props.onFileRemove} canRemoveFile={props.canRemoveFile} />
          <Form.Item noStyle={true} name={props.fileType} required={props.required} valuePropName="checked" rules={props.required ? [{ validator: validateCheckBoxValue(props.requiredMessage ?? props.translate('COMMON.FILL_REQUIRED_FIELD')) }] : []}>
            <Checkbox className="timun-formItemFileUpload__checkbox" />
          </Form.Item>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default withLocalize<IFormItemFileUploadOwnProps>(FormItemFileUpload as any);

import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { Button, Modal, Tree } from 'antd';
import React from 'react';

const { TreeNode } = Tree;

const COURSE_GROUP_MISSING_ID = '-1';

/**
 * All courses in course tree use this prefix in their key. It's used to differentiate from coursegroup keys (parent Tree nodes)
 * as Tree key prop is passed through onCheck method, which is later used to extract a course ID to update a workposition.
 */
const COURSE_KEY_PREFIX = 'COURSE-';

interface ICoursesByCourseGroupMap {
  [groupId: string]: ICourse[];
}

// -- Prop types
// ----------

interface ICoursesTreeModalOwnProps {
  isPicker: boolean;
  courseGroups: ICourseGroup[];
  allCoursesList: ICourse[];
  assignedCourses: ICourse[];
  modalTitle: string;
  onCloseModal: () => void;
  onSubmit: (data: string[]) => void;
}

// --
// ----- State types

interface ICoursesTreeModalState {
  coursesMap: ICoursesByCourseGroupMap;
  assignedCourses: string[];
  checkedCourses: string[];
  isTouched: boolean;
}

// -- Component
// ----------

class CoursesTreeModal extends React.Component<IWithLocalizeOwnProps & ICoursesTreeModalOwnProps, ICoursesTreeModalState> {
  state: ICoursesTreeModalState = {
    coursesMap: {},
    assignedCourses: [],
    checkedCourses: [],
    isTouched: false,
  };

  componentDidMount() {
    this.updateCoursesMap();
    if (!this.props.isPicker) {
      this.checkboxAssignedKeys(this.props.assignedCourses);
    }
  }

  // tslint:disable-next-line: cognitive-complexity
  render() {
    return (
      <Modal
        maskClosable={false}
        visible={true}
        title={this.props.modalTitle}
        okText={this.props.translate('COMMON.ACTION_SAVE')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
        onOk={this.onModalSubmit}
        onCancel={this.handleCancel}
        footer={
          this.props.isPicker
            ? null
            : [
              <Button key="back" onClick={this.handleCancel}>
                {this.props.translate('COMMON.ACTION_CANCEL')}
              </Button>,
              <Button key="submit" type="primary" onClick={this.onModalSubmit} disabled={!this.state.isTouched}>
                {this.props.translate('COMMON.ACTION_SAVE')}
              </Button>,
            ]
        }
      >
        <Tree checkable={!this.props.isPicker} selectable={this.props.isPicker} defaultExpandAll={true} checkedKeys={this.props.assignedCourses ? this.state.assignedCourses : []} onCheck={(check) => this.onAssignCourses(check as string[])}>
          {Object.keys(this.state.coursesMap)
            .map((courseGroupId: string) => {
              const courseGroup = this.getCourseGroupById(courseGroupId);
              const courses = this.state.coursesMap[courseGroupId];

              if (this.state.coursesMap[courseGroupId].length) {
                return (
                  <TreeNode title={courseGroup === undefined ? this.props.translate('COURSE_LIST.UNGROUPED_TITLE') : courseGroup.title} key={courseGroup === undefined ? this.props.translate('COURSE_LIST.UNGROUPED_TITLE') : courseGroup.id}>
                    {courses.map((course) => {
                      if (course.status.id === CourseStatusEnum.PUBLISHED) {
                        return <TreeNode title={<div onClick={this.props.isPicker ? () => this.props.onSubmit([course.id]) : undefined}>{course.title}</div>} key={this.getTreeKey(course, courseGroup)} />;
                      } else {
                        return null;
                      }
                    })}
                  </TreeNode>
                );
              } else {
                return null;
              }
            })
            .filter((el) => el != null)}
        </Tree>
      </Modal>
    );
  }

  handleCancel = () => {
    if (this.state.isTouched) {
      confirmationDialog({
        onConfirm: this.props.onCloseModal,
        title: this.props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      this.props.onCloseModal?.();
    }
  };

  onModalSubmit = () => {
    this.props.onSubmit(this.state.checkedCourses);
  };

  updateCoursesMap() {
    this.setState({
      coursesMap: this.coursesByCourseGroup(this.props.allCoursesList),
    });
  }

  /** Receives course and its coursegroup and returns the corresponding treenode key of the course */
  getTreeKey = (course: ICourse, courseGroup: ICourseGroup | undefined): string => {
    return courseGroup === undefined ? COURSE_GROUP_MISSING_ID + COURSE_KEY_PREFIX + course.id : courseGroup.id + COURSE_KEY_PREFIX + course.id;
  };

  /** Maps courses to their coursegroups in an object with keys that corresponds to coursegroup id */
  coursesByCourseGroup = (courseList: ICourse[]): ICoursesByCourseGroupMap => {
    return courseList.reduce((accum, course) => {
      // get course groups
      const courseGroups = this.getCourseGroupsById(course.courseGroups);

      let refList: IIdRef<string>[];

      // if course group is missing from list or course's group ID is invalid, assign course to "ungrouped list"
      if (courseGroups.length === 0) {
        refList = [{ id: COURSE_GROUP_MISSING_ID }];
      } else {
        refList = courseGroups.map((courseGroup) => ({ id: courseGroup.id }));
      }

      // add course to each of it's groups if they have status PUBLISHED
      refList.forEach((ref) => {
        if (accum[ref.id] == null) {
          accum[ref.id] = [];
        }
        if (course.status.id === CourseStatusEnum.PUBLISHED) {
          accum[ref.id].push(course);
        }
      });
      return accum;
    }, {} as ICoursesByCourseGroupMap);
  };

  /** Return list of course groups found in reference list. */
  getCourseGroupsById(refList: IIdRef<string>[]): ICourseGroup[] {
    return this.props.courseGroups.filter((courseGroup) => refList.find((ref) => ref.id === courseGroup.id) != null);
  }

  /** Return course group by ID. */
  getCourseGroupById(id: string): ICourseGroup | undefined {
    return this.props.courseGroups.find((courseGroup) => courseGroup.id === id);
  }

  /** Saves courses ids from TreeNode keys without parent Nodes */
  onAssignCourses = (courses: string[]) => {
    this.touch();
    const assignedCourses: string[] = courses
      .map((course) => {
        // if received element doesn't have COURSE_KEY_PREFIX it is not a course key
        if (course.includes(COURSE_KEY_PREFIX)) {
          return course.substring(course.indexOf(COURSE_KEY_PREFIX) + COURSE_KEY_PREFIX.length, course.length);
        }
        return '';
      })
      .filter((el) => el !== '');
    this.setState({
      checkedCourses: assignedCourses,
      assignedCourses: courses,
    });
  };

  /** Checks boxes of courses that are already assigned to workposition */
  checkboxAssignedKeys = (courseList: ICourse[]) => {
    // map courses to coursegroups where they belong
    const checkedCoursesMap = this.coursesByCourseGroup(courseList);
    const checkedKeys: string[] = [];

    // go through checkedCoursesMap and map courses in a single array
    Object.keys(checkedCoursesMap).forEach((courseGroupId) => {
      const courseGroup = this.getCourseGroupById(courseGroupId);
      const courses = checkedCoursesMap[courseGroupId];

      courses.forEach((course) => {
        checkedKeys.push(this.getTreeKey(course, courseGroup));
      });
    });

    this.setState({ assignedCourses: checkedKeys });
    this.setState({ checkedCourses: checkedKeys });
  };

  /** Mimic Form behaviour when touching/changing a field value */
  private touch() {
    if (!this.state.isTouched) {
      this.setState({
        isTouched: true,
      });
    }
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<ICoursesTreeModalOwnProps>(CoursesTreeModal as any);

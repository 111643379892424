import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserInfo } from '@src/model/user/User';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import AdminCourseListContainer from '@src/components/course/list/catalog/AdminCourseListContainer';

const VIEW_NAME = '@@CREATOR_COURSE_LIST';

// -- Prop types
// ----------

export interface ICreatorCourseListContainerOwnProps {}
export interface ICreatorCourseListContainerStateProps {
  currentUser: IUserInfo;
}
export interface ICreatorCourseListContainerDispatchProps {}
type ICreatorCourseListContainerProps = ICreatorCourseListContainerOwnProps & ICreatorCourseListContainerStateProps & ICreatorCourseListContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Container component for list of all courses that creator has created and navigation to course creation. */
const CreatorCourseListContainer = (props: ICreatorCourseListContainerProps) => {
  const predefinedListFilter = useMemo((): ICourseListFilter => {
    return { createdBy: props.currentUser.id };
  }, [props.currentUser]);

  return <AdminCourseListContainer viewName={VIEW_NAME} predefinedListFilter={predefinedListFilter} showCourseListActions={true} />;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): ICreatorCourseListContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICreatorCourseListContainerDispatchProps => ({});

export default connect<ICreatorCourseListContainerStateProps, ICreatorCourseListContainerDispatchProps, ICreatorCourseListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(CreatorCourseListContainer as any));

import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEducationCategory } from '@src/model/educationcategory/EducationCategory';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';


// -- Prop types
// ----------
export interface IEducationCategoryCheckboxDataPickerOwnProps {
  value?: IEducationCategory[];
  onChange?: (value: IEducationCategory[]) => void;
  disabled?: boolean;
}
export interface IEducationCategoryCheckboxDataPickerStateProps {
  educationCategoryList: IEducationCategory[];
}
export interface IEducationCategoryCheckboxDataPickerDispatchProps {
}
type IEducationCategoryCheckboxDataPickerProps = IEducationCategoryCheckboxDataPickerOwnProps & IEducationCategoryCheckboxDataPickerStateProps & IEducationCategoryCheckboxDataPickerDispatchProps & IWithLocalizeOwnProps;

interface IEducationCategoryCheckboxDataPickerState {
  initialValues?: string[];
}

// -- Component
// ----------

/** Display education category checkbox list */
class EducationCategoryCheckboxDataPicker extends React.Component<IEducationCategoryCheckboxDataPickerProps, IEducationCategoryCheckboxDataPickerState> {
  state: IEducationCategoryCheckboxDataPickerState = {
    initialValues: undefined,
  };

  componentDidMount() {
    this.handleInitalValues();
  }

  componentDidUpdate(prevProps: IEducationCategoryCheckboxDataPickerProps, prevState: IEducationCategoryCheckboxDataPickerState) {
    if (prevProps.value !== this.props.value) {
      this.handleInitalValues();
    }
  }

  handleInitalValues = () => {
    const initialValues = this.props.value?.map((value) => value.id);
    this.setState({ initialValues });
  }

  render = () => {
    return (
      <Checkbox.Group disabled={this.props.disabled} value={this.state.initialValues} onChange={this.handleChange}>
        {this.props.educationCategoryList?.map((category) => <Checkbox key={category.id} value={category.id}>
          {category.name}
        </Checkbox>)}
      </Checkbox.Group>
    );
  };

  handleChange = (data: CheckboxValueType[]) => {
    const result = this.props.educationCategoryList.filter((category) => data.includes(category.id));
    this.props.onChange?.(result);
  }

}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationCategoryCheckboxDataPickerOwnProps): IEducationCategoryCheckboxDataPickerStateProps => ({
  educationCategoryList: CollectionBusinessStore.selectors.getCollectionContent(state, 'EducationCategory'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationCategoryCheckboxDataPickerDispatchProps => ({
});

export default connect<IEducationCategoryCheckboxDataPickerStateProps, IEducationCategoryCheckboxDataPickerDispatchProps, IEducationCategoryCheckboxDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)((withLocalize(EducationCategoryCheckboxDataPicker as any)));

import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IActionMenuItem } from '@src/components/common/table/ActionMenu';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationTraineeConsultationModal from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationTraineeConsultationModal';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationUserStatus } from '@src/model/externalEducationApplication/ExternalEducationApplicationUserStatus';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData } from '@src/service/business/common/types';
import { ActivityUtils } from '@src/service/util/activity/ActivityUtils';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------
export interface IEducationApplicationListViewOwnProps {
  educationApplicationUserStatus?: IExternalEducationApplicationUserStatus;
  currentUser?: IUserInfo;
  isOwnProfile?: boolean;
  isCurrentUserCoordinator?: boolean;
  isCurrentUserTrainee?: boolean;
  isCurrentUserAdmin?: boolean;
  applicationList: ICollectionData<IExternalEducationApplication>;
  additionalSearchParams?: boolean;
  showCoordinatorCreateButton?: boolean;
  showCoordinatorColumn?: boolean;
  hideMyProfileColumn?: boolean;
  scrollable?: boolean;
  userActions?: Array<IActionMenuItem<IExternalEducationApplication>>;
  userListActions?: Array<IActionMenuItem<IExternalEducationApplication[]>>;
  additionalCols?: () => Array<ITimunDataTableCol<IExternalEducationApplication>>;
  onApplicationConsultationStart?: (data?: IExternalEducationApplication) => void;
  onApplicationCreate?: () => void;
  onPageChange?: (page: number, pageSize?: number) => void;
}

type IEducationApplicationListViewProps = IEducationApplicationListViewOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationListViewState {
  isConsultationModalVisible: boolean;
}

// -- Component
// ----------
class EducationApplicationListView extends React.Component<IEducationApplicationListViewProps, IEducationApplicationListViewState> {
  state: IEducationApplicationListViewState = {
    isConsultationModalVisible: false,
  };

  isCurrectUserApplicationEvaluator = (record: IExternalEducationApplication) => {
    return EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.EVALUATOR, record.activity)?.userId === this.props.currentUser?.id;
  };

  renderColumns = () => {
    const columns: Array<ITimunDataTableCol<IExternalEducationApplication>> = [
      {
        key: 'icon',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: '',
        // show exclamation icon next to application that need HZZ support and doesn't have assigned coordinator or need HZZ support and currentUser is assigned to that application
        content: (record: IExternalEducationApplication) => {
          const showIcon =
            (this.props.isCurrentUserCoordinator && (EducationApplicationHelperUtils.isInPhases(['CONSULTATION_REQUESTED'], record.activity) || (this.isCurrectUserApplicationEvaluator(record) && EducationApplicationHelperUtils.isInPhases(['CONSULTATION_STARTED'], record.activity)))) ||
            (this.props.isCurrentUserAdmin && EducationApplicationHelperUtils.isInPhases(['CONSULTATION_REQUESTED', 'CONSULTATION_STARTED'], record.activity));
          return showIcon ? <LemonIcon name="exclamation" /> : undefined;
        },
      },
      {
        key: 'activityStatus',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.APPLICATION_STATUS_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => {
          const phaseName = ActivityUtils.getPhaseName(record.activity);
          return (
            <Link to={`/externaleducationapplication/${record.id}`} data-test-id={`timun-externalEducationApplicationListView__openApplicationLink_${record.id}`}>
              {this.props.translate(`EXTERNAL_EDUCATION_APPLICATION.PHASE.${phaseName}_LABEL`)}
            </Link>
          );
        },
      },
      {
        key: 'skill',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.SKILL_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) =>
          record.skill ? (
            <Link to={`/externaleducationapplication/${record.id}`} data-test-id={`timun-externalEducationApplicationListView__openApplicationLink_${record.id}`}>
              <Row key={record.skill.id}>{record.skill.title}</Row>
            </Link>
          ) : (
            this.props.translate('COMMON.MESSAGE_NO_DATA')
          ),
      },
      {
        key: 'educationTemplate',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_TEMPLATE_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => record.externalEducationTemplate?.title ?? this.props.translate('COMMON.MESSAGE_NO_DATA'),
      },
      {
        key: 'submittedAt',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.APPLICATION_SUBMISSION_DATE_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => DateUtils.toViewDateFormat(record.submittedAt, 'dateWithTime') ?? this.props.translate('COMMON.MESSAGE_NO_DATA'),
      },
    ];
    if (this.props.additionalCols) {
      columns.push(...this.props.additionalCols());
    }
    // show coordinator name colum on MyProfile if another coordinator watching other coordinator profile or if current user is trainee/assignee
    if (!this.props.isOwnProfile && this.props.isCurrentUserCoordinator && !this.props.hideMyProfileColumn) {
      columns.push({
        key: 'evaluatorName',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EVALUATOR_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.EVALUATOR, record.activity) ?? this.props.translate('COMMON.MESSAGE_NO_DATA'),
      });
    }
    // show assignee/trainee name colum on coordinator MyProfile
    if (!this.props.isCurrentUserTrainee && this.props.isOwnProfile && !this.props.hideMyProfileColumn) {
      columns.push({
        key: 'assigneeName',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.ASSIGNEE, record.activity),
      });
    }

    return columns;
  };

  render() {
    const hasActiveConsultation = this.props.educationApplicationUserStatus?.hasActiveConsultation;
    const hasActiveExternalEducationApplication = this.props.educationApplicationUserStatus?.hasActiveExternalEducationApplication;
    const createButton = (
      <Tooltip title={this.getNewApplicationButtonTooltipTitle()}>
        <Button disabled={hasActiveConsultation || hasActiveExternalEducationApplication} type="primary" icon={<LemonIcon name="plus" />} onClick={this.handleApplicationCreate} data-test-id="timun-externalEducationApplicationListView__createApplicationButton">
          {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.NEW_APPLICATION_BUTTON_LABEL')}
        </Button>
      </Tooltip>
    );

    return (
      <AppContent title={this.props.showCoordinatorCreateButton ? <Row justify="end">{createButton}</Row> : null}>
        {/* Trainee consultation or aplication creation panels */}
        {this.props.isCurrentUserTrainee && (
          <Row className="timun_externalEducationListView__panelRow" gutter={[12, 8]}>
            <Col xs={24} md={12}>
              <div className="panel timun_externalEducationListView__panel">
                <Typography.Paragraph>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.NEW_APPLICATION_TEXT')}</Typography.Paragraph>
                {createButton}
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="panel timun_externalEducationListView__panel">
                <Typography.Paragraph>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.CONSULTATION_TEXT')}</Typography.Paragraph>
                <Tooltip title={this.getConsultationButtonTooltipTitle()}>
                  <Button disabled={hasActiveConsultation || hasActiveExternalEducationApplication} onClick={this.toggleConsultationModal}>
                    {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.CONSULTATION_BUTTON_LABEL')}
                  </Button>
                </Tooltip>
              </div>
            </Col>
          </Row>
        )}

        <DataTable<IExternalEducationApplication>
          items={this.props.applicationList.content}
          paginationData={this.props.applicationList.page}
          columns={this.renderColumns()}
          scrollable={this.props.scrollable}
          paginationConfig={{ onChange: this.props.onPageChange }}
          actionMenuItems={this.props.userActions}
          groupActions={this.props.userListActions}
        />

        {/* Modal */}
        {this.state.isConsultationModalVisible && <ExternalEducationApplicationTraineeConsultationModal onCancel={this.toggleConsultationModal} onConsultationSubmit={this.handleConsultationSubmit} />}
      </AppContent>
    );
  }
  toggleConsultationModal = () => this.setState({ isConsultationModalVisible: !this.state.isConsultationModalVisible });
  handleConsultationSubmit = (data?: IExternalEducationApplication) => this.props.onApplicationConsultationStart?.(data);
  handleApplicationCreate = () => this.props.onApplicationCreate?.();
  getNewApplicationButtonTooltipTitle = () => {
    if (this.props.educationApplicationUserStatus?.hasActiveConsultation) {
      return this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ACTIVE_CONSULTATION_NEW_APPLICATION_BUTTON_TOOLTIP');
    } else if (this.props.educationApplicationUserStatus?.hasActiveExternalEducationApplication) {
      return this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ACTIVE_APPLICATION_NEW_APPLICATION_BUTTON_TOOLTIP');
    }
    // empty string wont trigger tooltip on hover
    return '';
  };

  getConsultationButtonTooltipTitle = () => {
    if (this.props.educationApplicationUserStatus?.hasActiveConsultation) {
      return this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ACTIVE_CONSULTATION_NEW_CONSULTATION_BUTTON_TOOLTIP');
    } else if (this.props.educationApplicationUserStatus?.hasActiveExternalEducationApplication) {
      return this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ACTIVE_APPLICATION_NEW_CONSULTATION_BUTTON_TOOLTIP');
    }
    // empty string wont trigger tooltip on hover
    return '';
  };
}

// -- HOCs and exports
// ----------
export default withLocalize<IEducationApplicationListViewOwnProps>(EducationApplicationListView as any);

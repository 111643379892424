import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import CodebookShortInfoForm from '@src/components/codebook/form/CodebookShortInfoForm';
import CodebookListView from '@src/components/codebook/view/CodebookList';
import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEducationCategory } from '@src/model/educationcategory/EducationCategory';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import EducationCategoryBusinessStore, { IEducationCategoryCreatePayload, IEducationCategoryListFilter } from '@src/service/business/educationcategory/educationCategoryBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const VIEW_NAME = '@@EDUCATION_CATEGORY_CODEBOOK';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');

// -- Prop types
// ----------
export interface IEducationCategoryCodebookContainerOwnProps { }
export interface IEducationCategoryCodebookContainerStateProps {
  educationCategoryList: ICollectionData<IEducationCategory>;
}
export interface IEducationCategoryCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchEducationCategoryList: (params: ICollectionFetchPayload<IEducationCategoryListFilter>) => ITrackableAction;
  createEducationCategory: (data: IEducationCategoryCreatePayload) => ITrackableAction;
  updateEducationCategory: (data: IEducationCategory) => ITrackableAction;
}
type IEducationCategoryCodebookContainerProps = IEducationCategoryCodebookContainerOwnProps & IEducationCategoryCodebookContainerStateProps & IEducationCategoryCodebookContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Coded grade container */
const EducationCategoryCodebookContainer = (props: IEducationCategoryCodebookContainerProps) => {
  const [educationCategoryModalsState, toggleEducationCategoryModals] = useEntityModalsState<IEducationCategory>();
  const [, updateCollectionParams, onUpdateList] = useCollectionState<IEducationCategoryListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchEducationCategoryList,
    initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
  });

  const handleCreate = useCallback((data: IEducationCategoryCreatePayload) => {
    props.createEducationCategory(data).track().subscribe(
      // success
      () => {
        props.reportMessage({ message: props.translate('EDUCATION_CATEGORY.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        onUpdateList();
        toggleEducationCategoryModals.onCloseCreate();
      }
    );
  }, [props.createEducationCategory, toggleEducationCategoryModals, onUpdateList, props.reportMessage]);

  const handleUpdate = useCallback((data: IEducationCategory) => {
    props.updateEducationCategory(data).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleEducationCategoryModals.onCloseUpdate();
      }
    );
  }, [props.updateEducationCategory, onUpdateList, toggleEducationCategoryModals]);

  return (
    <React.Fragment>
      {props.educationCategoryList && <CodebookListView<IEducationCategory>
        title={props.translate(`CODEBOOK.TITLE_LABEL.${CodebookEnum.EDUCATION_CATEGORY}`)}
        descriptionPrefix={'EDUCATION_CATEGORY.DESCRIPTION_LABEL'}
        buttonLabel={props.translate('EDUCATION_CATEGORY.CREATE_BUTTON_LABEL')}
        codebookItemList={props.educationCategoryList}
        canEdit={true}
        onEditCodebookItemClick={toggleEducationCategoryModals.onOpenUpdate}
        showBackColumn={true}
        onCreateCodebookItemClick={toggleEducationCategoryModals.onOpenCreate}
        onPageChange={updateCollectionParams.onPageChange}
      />}

      {(educationCategoryModalsState.isCreateModalVisible || educationCategoryModalsState.isUpdateModalVisible) && <CodebookShortInfoForm<IEducationCategory, IEducationCategoryCreatePayload>
        value={educationCategoryModalsState.selectedEntity}
        onCancel={toggleEducationCategoryModals.onCloseCreate}
        onUpdate={handleUpdate}
        onCreate={handleCreate} />}
    </React.Fragment>
  );

};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationCategoryCodebookContainerOwnProps): IEducationCategoryCodebookContainerStateProps => ({
  educationCategoryList: EducationCategoryBusinessStore.selectors.getEducationCategoryList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationCategoryCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchEducationCategoryList: (params: ICollectionFetchPayload<IEducationCategoryListFilter>) => dispatch(createTrackableAction(EducationCategoryBusinessStore.actions.fetchEducationCategoryList(params))),
  createEducationCategory: (data: IEducationCategoryCreatePayload) => dispatch(createTrackableAction(EducationCategoryBusinessStore.actions.createEducationCategory(data))),
  updateEducationCategory: (data: IEducationCategory) => dispatch(createTrackableAction(EducationCategoryBusinessStore.actions.updateEducationCategory(data))),
});

export default connect<IEducationCategoryCodebookContainerStateProps, IEducationCategoryCodebookContainerDispatchProps, IEducationCategoryCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(EducationCategoryCodebookContainer as any));

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationInstanceListContainer from '@src/components/externaleducationistance/ExternalEducationInstanceListContainer';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { Modal, Typography } from 'antd';


// -- Prop types
// ----------

export interface IExternalEducationInstanceListModalOwnProps {
  value: IExternalEducationTemplate;
  onCancel?: () => void;
}
type IExternalEducationInstanceListModalProps = IExternalEducationInstanceListModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationInstanceListModal: React.FC<IExternalEducationInstanceListModalProps> = (props: IExternalEducationInstanceListModalProps) => {

  return (
    <Modal
      className="lemon-modal__modal--lg"
      title={props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.MODAL.TITLE')}
      maskClosable={false}
      visible={true}
      onCancel={props.onCancel}
      footer={null}>
      <Typography.Paragraph>{props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.MODAL.INFO_TEXT')}</Typography.Paragraph>
      <ExternalEducationInstanceListContainer externalEducationTemplate={props.value} />
    </Modal >
  );
};

export default withLocalize<IExternalEducationInstanceListModalOwnProps>(ExternalEducationInstanceListModal as any);

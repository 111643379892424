import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import FileTypeUploadModal from '@src/components/common/file/FileTypeUploadModal';
import GridItem from '@src/components/common/grid/GridItem';
import { HtmlViewer } from '@src/components/common/htmlviewer/HtmlViewer';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withNumberFormat, { IWithNumberFormatOwnProps } from '@src/components/common/numberformat/withNumberFormat';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExternalEducationTemplateFileListView from '@src/components/externaleducation/common/ExternalEducationTemplateFileListView';
import { ExternalEducationTemplateHelperUtils } from '@src/components/externaleducation/common/ExternalEducationTemplateHelperUtils';
import ExternalEducationTemplateStatus from '@src/components/externaleducation/ExternalEducationTemplateStatus';
import { IEducationCategory } from '@src/model/educationcategory/EducationCategory';
import { IEnrollmentRequirement } from '@src/model/enrollmentrequirement/EnrollmentRequirement';
import { IExternalEducationOutcome } from '@src/model/externalEducationTemplate/ExternalEducationOutcome';
import { ExternalEducationTemplateStatusEnum, IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IFile } from '@src/model/file/File';
import { ISkill } from '@src/model/skillgroup/Skill';
import { IFileListsByType } from '@src/service/business/files/util';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Row, Tooltip } from 'antd';
import React, { SyntheticEvent, useState } from 'react';

const eurToKunaExchangeRate = AppConfigService.getValue('currencyExchangeRate.eurToHrk');
const currencySymbolEur = AppConfigService.getValue('currencyFormat.currencySymbolEur');

interface IExternalEducationTemplateDetailsPanelOwnProps {
  isCurrentUserMember?: boolean;
  externalEducationTemplate: IExternalEducationTemplate;
  active?: boolean;
  collapsible?: boolean;
  files?: IFileListsByType;
  showEducationTemplateDocuments?: boolean;
  showEducationTemplateAveragePrice?: boolean;
  canEditTemplate?: boolean;
  canAddFile?: boolean;
  canRemoveFile?: boolean;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onVerificationSubmit?: (updatedExternalEducationTemplate: IExternalEducationTemplate) => void;
  onExternalEducationTemplateEdit?: () => void;
}

type IExternalEducationTemplateDetailsPanelProps = IExternalEducationTemplateDetailsPanelOwnProps & IWithLocalizeOwnProps & IWithNumberFormatOwnProps;

// -- Component
// ----------
/** Display external education template info sections. */
const ExternalEducationTemplateDetailsPanel: React.FC<IExternalEducationTemplateDetailsPanelProps> = (props: IExternalEducationTemplateDetailsPanelProps) => {
  const [isFileModalVisible, setIsFileModalVisible] = useState<boolean>(false);

  const renderOutcomes = (data: IExternalEducationOutcome[]) => {
    return data.map((outcome: IExternalEducationOutcome) => {
      return (
        <Row key={outcome.id} align="middle">
          <Col span={1}>
            <LemonIcon name="outcome" size="xlarge" />
          </Col>
          <Col span={23}>{outcome.title}</Col>
        </Row>
      );
    });
  };
  const renderEnrollmentRequirements = (data: IEnrollmentRequirement[]) => {
    return data.map((enrollmentReq) => {
      return (
        <Row key={enrollmentReq.id} align="middle">
          <Col span={1}>
            <LemonIcon name="enrollmentRequirement" size="xlarge" />
          </Col>
          <Col span={23}>{enrollmentReq.name}</Col>
        </Row>
      );
    });
  };

  const handleVerificationSubmit = () => {
    const externalEducationTemplateData = { ...props.externalEducationTemplate };
    externalEducationTemplateData.status.id = ExternalEducationTemplateStatusEnum.CREATION_FINISHED;
    return props.onVerificationSubmit?.(externalEducationTemplateData);
  };

  const handleModalOpen = () => setIsFileModalVisible(true);
  const handleModalClose = () => setIsFileModalVisible(false);
  const handleFileUpload = (data: IFile[]) => {
    handleModalClose();
    props.onFileUpload?.(data);
  };

  const handleFileRemove = (file: IFile[]) => {
    confirmationDialog({
      onConfirm: () => props.onFileRemove?.(file),
      // ** There is only one file in array to remove so we can safely indexing that file from IFile[]  */
      title: props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES_VIEW_SECTION.FILE_REMOVE_CONFIRMATION_TITLE', { fileName: file[0].name }),
    });
  };

  const handleEditButtonAction = (event: SyntheticEvent) => {
    event.stopPropagation();
    props.onExternalEducationTemplateEdit?.();
  };
  const isVerificationSubmitButtonVisible = props.externalEducationTemplate.status.id === ExternalEducationTemplateStatusEnum.IN_CREATION && props.isCurrentUserMember;
  const isVerificationSubmitButtonDisabled = props.files ? !ExternalEducationTemplateHelperUtils.hasFilesInAllTypes(props.files, ['CURRICULUM', 'EDUCATION_EXECUTION_APPROVAL']) : true;

  return (
    <ViewSectionItem
      bordered={true}
      active={props.active}
      header={<HeaderTitle title={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.TITLE')} icon={<LemonIcon name="clipboard" />} />}
      collapsible={props.collapsible}
      actions={props.canEditTemplate ? <Button onClick={handleEditButtonAction}>{props.translate('COMMON.ACTION_EDIT')}</Button> : null}
    >
      {/* Title */}
      <Row justify="space-between" className="timun-externalEducationTemplate__row">
        <Row gutter={8}>
          <Col>
            <HeaderTitle title={props.externalEducationTemplate.title} icon={<LemonIcon name="externalEducation" size="large" />} />
          </Col>
          <Col>
            <ExternalEducationTemplateStatus status={props.externalEducationTemplate.status} />
          </Col>
        </Row>
        {isVerificationSubmitButtonVisible && (
          <Col className="timun-externalEducationTemplate__column">
            <Tooltip placement="bottom" title={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.VERIFICATION_BUTTON_TOOLTIP_LABELF')}>
              <Button type="primary" disabled={isVerificationSubmitButtonDisabled} onClick={handleVerificationSubmit} size="middle">
                {props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.VERIFICATION_BUTTON_LABEL')}
              </Button>
            </Tooltip>
          </Col>
        )}
      </Row>

      <Row className="timun-externalEducationTemplate__row">
        {/* Skills */}
        <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.SKILLS_TITLE')}>
          {props.externalEducationTemplate.skills.map((skill: ISkill) => (
            <Row key={skill.id}>
              <Col>{skill.title}</Col>
            </Row>
          ))}
        </GridItem>

        {/* Education categories */}
        {props.externalEducationTemplate.educationCategories && props.externalEducationTemplate.educationCategories.length > 0 && (
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate(`CODEBOOK.TITLE_LABEL.${CodebookEnum.EDUCATION_CATEGORY}`)}>
            {props.externalEducationTemplate.educationCategories.map((category: IEducationCategory) => (
              <Row key={category.id}>
                <Col>{category.name}</Col>
              </Row>
            ))}
          </GridItem>
        )}

        {/* Education sector */}
        {props.externalEducationTemplate?.educationSector && (
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_SECTOR_TITLE')}>
            {props.externalEducationTemplate?.educationSector.name}
          </GridItem>
        )}
      </Row>

      <Row className="timun-externalEducationTemplate__row">
        {/* Details */}
        <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.DETAILS_TITLE')}>
          <Row>
            <Col xs={24} md={12}>
              <ViewSectionContentItem
                icon={<LemonIcon name="clockCircle" size="xlarge" />}
                title={`${props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DURATION', { duration: props.externalEducationTemplate.duration })}`}
                description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DURATION_LABEL')}
              />
            </Col>
            {props.showEducationTemplateAveragePrice && (
              <Col xs={24} md={12}>
                <ViewSectionContentItem
                  icon={<LemonIcon name="moneyBill" size="xlarge" />}
                  title={
                    <React.Fragment>
                      <Row className="timun-educationApplicationCurrency__fontSize--primary">
                        {props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.COST_LABEL', {
                          averagePrice: props.formatCurrency(props.externalEducationTemplate.averagePrice / eurToKunaExchangeRate, undefined, currencySymbolEur),
                        })}
                      </Row>
                      <Row className="timun-educationApplicationCurrency__fontSize--secondary">{props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.COST_LABEL', { averagePrice: props.formatCurrency(props.externalEducationTemplate.averagePrice) })}</Row>
                    </React.Fragment>
                  }
                  description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.AVERAGE_PRICE_DESCRIPTION', { eurToKunaExchangeRate })}
                />
              </Col>
            )}
          </Row>

          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.CONTRACTOR_QUALIFICATION_DESCRIPTION')}>
            {props.externalEducationTemplate.contractorQualification}
          </GridItem>

          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.CERTIFICATE_NAME_DESCRIPTION')}>
            {props.externalEducationTemplate.certificateName}
          </GridItem>
        </GridItem>
      </Row>

      {props.externalEducationTemplate.maxVoucherPrice && (
        <Row className="timun-externalEducationTemplate__row">
          {/* Max voucher price */}
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.MAX_VOUCHER_PRICE_TITLE')}>
            <Row>
              <Col xs={24} md={12}>
                <ViewSectionContentItem
                  icon={<LemonIcon name="moneyBill" size="xlarge" />}
                  title={
                    <React.Fragment>
                      <Row className="timun-educationApplicationCurrency__fontSize--primary">{props.formatCurrency(props.externalEducationTemplate.maxVoucherPrice / eurToKunaExchangeRate, undefined, currencySymbolEur)}</Row>
                      <Row className="timun-educationApplicationCurrency__fontSize--secondary">{props.formatCurrency(props.externalEducationTemplate.maxVoucherPrice)}</Row>
                    </React.Fragment>
                  }
                  description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.MAX_VOUCHER_PRICE_DESCRIPTION', { eurToKunaExchangeRate })}
                />
              </Col>
            </Row>
          </GridItem>
        </Row>
      )}

      {props.externalEducationTemplate.description && (
        <Row className="timun-externalEducationTemplate__row">
          {/* Description */}
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.DESCRIPTION_TITLE')}>
            <HtmlViewer size="small" dangerousValue={props.externalEducationTemplate.description} />
          </GridItem>
        </Row>
      )}

      <Row className="timun-externalEducationTemplate__row">
        {/* Enrollment requirements */}
        <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.ENROLLMENT_REQUIREMENTS_TITLE')}>
          {renderEnrollmentRequirements(props.externalEducationTemplate.enrollmentRequirements)}
        </GridItem>
      </Row>

      <Row className="timun-externalEducationTemplate__row">
        {/* Outcomes */}
        <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_OUTCOME_LABEL')}>
          {renderOutcomes(props.externalEducationTemplate.educationOutcomes)}
        </GridItem>
      </Row>

      {props.showEducationTemplateDocuments && (
        <Row className="timun-externalEducationTemplate__row">
          {/* Documents */}
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.DOCUMENTS_LABEL')}>
            <Row justify="end">
              {props.canAddFile && (
                <Col>
                  <Button onClick={handleModalOpen} type="primary" icon={<LemonIcon name="plus" />}>
                    {props.translate('COMMON.ACTION_ADD_FILE')}
                  </Button>
                </Col>
              )}
            </Row>
            <ExternalEducationTemplateFileListView canRemove={props.canRemoveFile} onRemove={handleFileRemove} files={props.files} displayTypes={['CURRICULUM', 'EDUCATION_EXECUTION_APPROVAL']} />
          </GridItem>
        </Row>
      )}

      {isFileModalVisible && <FileTypeUploadModal defaultFileType="CURRICULUM" allowedFileTypes={['EDUCATION_EXECUTION_APPROVAL', 'CURRICULUM']} fileList={props.files} onFileRemove={props.onFileRemove} onCancel={handleModalClose} onFileUpload={handleFileUpload} />}
    </ViewSectionItem>
  );
};
export default withLocalize<IExternalEducationTemplateDetailsPanelOwnProps>(withNumberFormat(ExternalEducationTemplateDetailsPanel as any));

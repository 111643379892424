import React from 'react';
import { connect } from 'react-redux';

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { ICity } from '@src/model/location/City';
import CityListBusinessStore, { ICityListFilter } from '@src/service/business/city/cityBussinesStore';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';
import { debounce } from 'lodash';

// -- Const
// ----------
const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');
const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');
const MAX_PAGE_SIZE = AppConfigService.getValue('api.collectionDefaultLimit');
// -- Prop types
// ----------

export interface ICityDataPickerOwnProps {
  value?: ICity;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (newValue?: ICity) => void;
}

export interface ICityDataPickerStateProps {
  cityList: ICollectionData<ICity>;
}

export interface ICityDataPickerDispatchProps {
  fetchCityList: (params: ICollectionFetchPayload<ICityListFilter>) => ITrackableAction;
}
type ICityDataPickerProps = ICityDataPickerOwnProps & ICityDataPickerStateProps & ICityDataPickerDispatchProps;

interface ICityDataPickerState {
  cityList?: ICity[];
}

// -- Component
// ----------

/** Describe your component ... */
class CityDataPicker extends React.Component<ICityDataPickerProps, ICityDataPickerState> {
  state: ICityDataPickerState = {
    cityList: undefined,
  };

  handleDataPickerSearch = debounce((value: string) => {
    const filter: ICityListFilter = { name: value };
    if (value.length >= minSearchLength) {
      this.updateList(filter);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  }, debounceTimeout);

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.cityList?.map(this.mapToItem) || [];

    return <DataPicker placeholderSuffix={this.props.placeholder} onClear={this.handleClear} value={pickerValue} items={pickerItems} disabled={this.props.disabled} onChange={this.handleDataPickerChange} onSearch={this.handleDataPickerSearch} dataTestIdPrefix="timun-skillPicker" />;
  };

  private handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  private handleClear = () => {
    this.setState({ cityList: this.props.cityList.content });
  };

  /** Map entity ref list to data picker item list. */
  private mapToItem(city: ICity): IDataPickerItem {
    return {
      value: city.id,
      name: `${city.name} - ${city.county.name}`,
      data: city,
    };
  }

  private updateList = (filter: ICityListFilter = {}) => {
    this.props
      .fetchCityList({
        filter,
        page: 0,
        sort: [],
        size: MAX_PAGE_SIZE,
      })
      .track()
      .subscribe((data: ICollectionData<ICity>) => this.storeList(data.content));
  };

  private storeList = (cityList?: ICity[]) => {
    this.setState({ cityList });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICityDataPickerOwnProps): ICityDataPickerStateProps => ({
  cityList: CityListBusinessStore.selectors.getCityList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICityDataPickerDispatchProps => ({
  fetchCityList: (params: ICollectionFetchPayload<ICityListFilter>) => dispatch(createTrackableAction(CityListBusinessStore.actions.fetchCityList(params))),
});

export default connect<ICityDataPickerStateProps, ICityDataPickerDispatchProps, ICityDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(CityDataPicker as any);

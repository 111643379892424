import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RouterNavigationPromptContextProvider from '@src/components/common/route/prompt/RouterNavigationPromptContextProvider';
import ExternalContentView from '@src/components/externalcontent/view/ExternalContentView';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import ExternalContentViewBusinessStore from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// -- Prop types
// ----------

interface IExternalContentViewContainerOwnProps {
  externalId: string;
}

interface IExternalContentViewContainerStateProps {
  externalContent: IExternalContent;
}

interface IExternalContentViewContainerDispatchProps {
  fetchExternalContent: (id: string) => void;
  clearExternalContent: () => void;
}
type IExternalContentViewContainerProps = IExternalContentViewContainerOwnProps & IExternalContentViewContainerStateProps & IExternalContentViewContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class ExternalContentViewContainer extends React.Component<IExternalContentViewContainerProps> {
  componentDidMount = () => {
    this.props.fetchExternalContent(this.props.externalId);
  };

  componentDidUpdate = (prevProps: IExternalContentViewContainerProps) => {
    if (this.props.externalId !== prevProps.externalId) {
      this.props.fetchExternalContent(this.props.externalId);
    }
  };

  componentWillUnmount = () => {
    this.props.clearExternalContent();
  };

  render = () => {
    return (
      <RouterNavigationPromptContextProvider message={this.props.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION')}>
        <ExternalContentView externalContent={this.props.externalContent} />
      </RouterNavigationPromptContextProvider>
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalContentViewContainerOwnProps): IExternalContentViewContainerStateProps => ({
  externalContent: ExternalContentViewBusinessStore.selectors.getExternalContent(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IExternalContentViewContainerDispatchProps => ({
  fetchExternalContent: (id: string) => dispatch(ExternalContentViewBusinessStore.actions.fetchExternalContent(id)),
  clearExternalContent: () => dispatch(ExternalContentViewBusinessStore.actions.clearExternalContentData()),
});

export default connect<IExternalContentViewContainerStateProps, IExternalContentViewContainerDispatchProps, IExternalContentViewContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize<IExternalContentViewContainerOwnProps>(ExternalContentViewContainer as any));

import { IUpdateListFunction } from '@src/components/common/collectionParams/useCollectionState';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdminReportHeader from '@src/components/report/AdminReportHeader';
import ReportList from '@src/components/report/list/ReportList';
import { IReport } from '@src/model/report/Report';
import { ICollectionData } from '@src/service/business/common/types';
import { ICourseReportListFilter } from '@src/service/business/courses/courseViewBusinessStore';
import React from 'react';

// -- Prop types
// ----------

export interface IReportListViewOwnProps {
  courseId?: string;
  reportList: ICollectionData<IReport>;
  onDeleteReport: (data: IReport) => void;
  onSortChange: (sort?: string[] | undefined) => void;
  onPageChange: (page?: number | undefined, size?: number | undefined) => void;
  onReload: IUpdateListFunction<ICourseReportListFilter>;
  onButtonClick: () => void;
}

type IReportListViewProps = IReportListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const ReportListView: React.FC<IReportListViewProps> = (props: IReportListViewProps) => {
  return (
    <>
      {/* Header */}
      <AdminReportHeader onButtonClick={props.onButtonClick} onSortChange={props.onSortChange} />
      {/* List */}
      {props.reportList && <ReportList reportList={props.reportList.content} onDelete={props.onDeleteReport} onReload={props.onReload} />}
      {/* Pagination */}
      {props.reportList && <ListPagination page={props.reportList.page} onChange={props.onPageChange} />}
    </>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IReportListViewOwnProps>(ReportListView as any);

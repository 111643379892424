import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IFileListsByType } from '@src/service/business/files/util';

import FileTypeUploadField from '@src/components/common/file/FileTypeUploadField';
import { ExternalEducationApplicationFileTypeEnum } from '@src/model/file/FileType';
import { IFile } from '@src/model/file/File';
import { Col, Row } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React from 'react';


interface IExternalEducationApplicationFileListUploadOwnProps {
  applicationFiles?: IFileListsByType;
  fileType: keyof typeof ExternalEducationApplicationFileTypeEnum;
  onFileUpload?: (file: IFile[]) => void;
  canRemoveFile?: boolean;
  onFileRemove?: (file: IFile[]) => void;
}

interface IExternalEducationApplicationFileListUploadState {
  fileToDisplay?: UploadFile;
}

type IExternalEducationApplicationFileListUploadProps = IExternalEducationApplicationFileListUploadOwnProps & IWithLocalizeOwnProps;

class ExternalEducationApplicationFileListUpload extends React.Component<IExternalEducationApplicationFileListUploadProps, IExternalEducationApplicationFileListUploadState> {
  state: IExternalEducationApplicationFileListUploadState = {
  };

  render() {
    return (
      <Row>
        <Col xs={24} md={24} lg={12}>
          <FileTypeUploadField
            fileList={this.props.applicationFiles}
            defaultFileType={this.props.fileType}
            onFileUpload={this.props.onFileUpload}
            canRemoveFile={this.props.canRemoveFile}
            onFileRemove={this.props.onFileRemove}
            dataTestIdPrefix="externalEducationApplication"
          />
        </Col>
      </Row>
    );
  }
}

export default withLocalize<IExternalEducationApplicationFileListUploadOwnProps>(ExternalEducationApplicationFileListUpload as any);

import AppContent from '@src/components/common/container/AppContent';
import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import OrganizationDetailsPanel from '@src/components/organization/view/OrganizationDetailsPanel';
import OrganizationViewTabs from '@src/components/organization/view/OrganizationViewTabs';
import UserGroupAccessFormWrapper from '@src/components/usergroup/form/UserGroupAccessForm';
import { IOrganization } from '@src/model/organization/Organization';
import { OrganizationMemberRoleEnum } from '@src/model/organization/OrganizationMemberRole';
import { UserRoleEnum } from '@src/model/user/UserRole';
import AppConfigService from '@src/service/common/AppConfigService';
import { DomainUtils } from '@src/service/util/DomainUtils';
import { Button, Col, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');

export interface IOrganizationViewOwnProps {
  organization: IOrganization;
  openedTab: string;
  toggleEdit: (isEditing: boolean) => void;
  onEdit?: (org: IOrganization) => void;
  onGenerateOrganizationJoinCode?: () => void;
  onJoinOrganization?: (accessCode: string) => void;
}

type IOrganizationViewProps = IOrganizationViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IOrganizationViewState {
  isAccessModalVisible: boolean;
}

class OrganizationView extends React.Component<IOrganizationViewProps, IOrganizationViewState> {
  state: IOrganizationViewState = {
    isAccessModalVisible: false,
  };

  render = () => {
    const showCodePanel = this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) || this.props.organization.currentUserMember?.role.id === OrganizationMemberRoleEnum.OWNER;

    return (
      <React.Fragment>
        <AppContent>
          <Row justify="space-between" className="timun-wrapper__gutterBox">
            <Col>
              <HeaderTitle title={this.props.organization.company.name} icon={<LemonIcon name="organization"/>}/>
            </Col>
            {this.props.organization.currentUserMember == null &&
            <Col>
              <Button type="primary" onClick={() => this.toggleAccessModal(true)}>
                {this.props.translate('ACCESS_CODE_FORM.JOIN_BUTTON_LABEL')}
              </Button>
            </Col>
            }
          </Row>

          <OrganizationDetailsPanel
            organization={this.props.organization}
            toggleEdit={this.props.toggleEdit}
            onEdit={this.props.onEdit}
          />

          {showCodePanel && (
            <div className="panel">
              <Row align="middle" justify="space-between">
                <Col>
                  <GridItem label={this.props.translate('ACCESS_CODE_FORM.ACCESS_CODE_LABEL')}>
                    <Typography.Paragraph copyable={!!this.props.organization.code?.value && { text: this.getCopyableAccessCode() }}>
                      {this.props.organization.code?.value ?? '-'}
                    </Typography.Paragraph>
                  </GridItem>
                </Col>
                <Col>
                  <GridItem label={this.props.translate('ACCESS_CODE_FORM.CODE_EXPIRATION_LABEL')}>
                    <Typography.Paragraph>
                      {moment(this.props.organization.code?.expirationDateTime).format(dateFormat)}
                    </Typography.Paragraph>
                  </GridItem>
                </Col>
                <Col>
                  <Button icon={<LemonIcon name="plusCircle"/>} onClick={this.props.onGenerateOrganizationJoinCode}>
                    {this.props.translate('ACCESS_CODE_FORM.GENERATE_BUTTON_LABEL')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          <OrganizationViewTabs
            organization={this.props.organization}
            openedTab={this.props.openedTab}
          />
        </AppContent>

        {this.state.isAccessModalVisible &&
        <UserGroupAccessFormWrapper
          title={this.props.translate('ACCESS_CODE_FORM.TITLE')}
          onSubmit={this.handleSubmitCode}
          onCancel={() => this.toggleAccessModal(false)}
        />}
      </React.Fragment>

    );
  };

  getCopyableAccessCode = () => {
    const urlLabel = this.props.translate('ACCESS_CODE_FORM.URL_LABEL');
    const accessCodeLabel = this.props.translate('ACCESS_CODE_FORM.ACCESS_CODE_LABEL');
    const link = DomainUtils.getFullCurrentDomain() + '/organizations/' + this.props.organization.id;
    const code = this.props.organization.code?.value;
    return `${urlLabel}: ${link}\n${accessCodeLabel}: ${code}`;
  };

  handleSubmitCode = (accessCode: string) => {
    this.props.onJoinOrganization?.(accessCode);
    this.toggleAccessModal(false);
  };

  toggleAccessModal = (isVisible: boolean) => {
    this.setState({ isAccessModalVisible: isVisible });
  };
}

export default withLocalize<IOrganizationViewOwnProps>(withRoles(OrganizationView as any));

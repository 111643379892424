import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SkillLevelClassificationForm, { ISkillLevelClassificationData } from '@src/components/skilllevelclassification/form/SkillLevelClassificationForm';
import { ISkillLevel, ISkillLevelClassificationShortInfo } from '@src/model/skillgroup/SkillLevelClassification';
import { Form, Modal } from 'antd';

// -- Prop types
// ----------

export interface ISkillLevelClassificationModalOwnProps {
  skillLevelList: ISkillLevel[];
  skillLevelClassificationList: ISkillLevelClassificationShortInfo[];
  onSubmit?: (skillLevelClassificationList: ISkillLevelClassificationShortInfo[]) => void;
  onCancel?: () => void;
}
type ISkillLevelClassificationModalProps = ISkillLevelClassificationModalOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const SkillLevelClassificationModal: React.FC<ISkillLevelClassificationModalProps> = (props: ISkillLevelClassificationModalProps) => {
  const [form] = Form.useForm<ISkillLevelClassificationData>();

  const handleSubmit = (value: ISkillLevelClassificationShortInfo[]) => {
    props.onSubmit?.(value);
    props.onCancel?.();
  };

  return (
    <Modal title={props.translate('SKILL_LEVEL_CLASSIFICATION.MODAL.TITLE')} open={true} onCancel={props.onCancel} onOk={form.submit} okText={props.translate('COMMON.ACTION_SAVE')} okButtonProps={{ disabled: props.skillLevelList.length === 0 }} cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <SkillLevelClassificationForm skillLevelList={props.skillLevelList} formRef={form} skillLevelClassificationList={props.skillLevelClassificationList} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default withLocalize<ISkillLevelClassificationModalOwnProps>(SkillLevelClassificationModal as any);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import OrganizationTree from '@src/components/common/orgTree/OrganizationTree';
import OrganizationTreeNode from '@src/components/common/orgTree/OrganizationTreeNode';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';

import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import UserGroupHierarchyMemberGroup from '@src/components/usergroup/hierarchy/UserGroupHierarchyMemberGroup';
import UserGroupCreateContainer from '@src/components/usergroup/update/UserGroupCreateContainer';
import UserGroupUpdateContainer from '@src/components/usergroup/update/UserGroupUpdateContainer';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import React, { useCallback, useState } from 'react';

// -- Const
// ----------

const MAX_HIERARCHY_DEPTH = 50;

// -- Prop types
// ----------

export interface IUserGroupHierarchyGraphOwnProps {
  basePath: string;
  returnRoute?: string;
  userGroupHierarchy: IUserGroupHierarchy;
  onUserGroupHierachyUpdate?: () => void;
  canEdit?: boolean;
  canAdd?: boolean;
}

type IUserGroupHierarchyGraphProps = IUserGroupHierarchyGraphOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------
const UserGroupHierarchyGraph: React.FC<IUserGroupHierarchyGraphProps> = (props: IUserGroupHierarchyGraphProps) => {
  const [userGroupModalsState, toggleUserGroupModals] = useEntityModalsState<IUserGroup>();
  const [parentGroup, setParentGroup] = useState<IUserGroup | undefined>();

  const handleUserGroupCreateSubmit = useCallback(() => {
    setParentGroup(undefined);
    toggleUserGroupModals.onCloseCreate();
    props.onUserGroupHierachyUpdate?.();
  }, [setParentGroup, toggleUserGroupModals.onCloseCreate, props.onUserGroupHierachyUpdate]);

  const handleUserGroupUpdateSubmit = useCallback(() => {
    toggleUserGroupModals.onCloseUpdate();
    props.onUserGroupHierachyUpdate?.();
  }, [toggleUserGroupModals.onCloseUpdate, props.onUserGroupHierachyUpdate]);

  const handleOpenCreateModal = useCallback(
    (userGroup?: IUserGroup) => {
      setParentGroup(userGroup);
      toggleUserGroupModals.onOpenCreate();
    },
    [toggleUserGroupModals.onOpenCreate]
  );

  /** Map hierarchy list tree element. */
  const mapToItems = useCallback(
    (userGroupHierarchyList: IUserGroupHierarchy[], counter: number) => {
      return counter <= MAX_HIERARCHY_DEPTH
        ? userGroupHierarchyList.map((userGroupHierarchy) => {
            return (
              <OrganizationTreeNode
                key={userGroupHierarchy.userGroup.id}
                content={<UserGroupHierarchyMemberGroup basePath={props.basePath} returnRoute={props.returnRoute} userGroupHierarchy={userGroupHierarchy} canAdd={props.canAdd} canEdit={props.canEdit} onUserGroupEdit={toggleUserGroupModals.onOpenUpdate} onUserGroupHierarchyAdd={handleOpenCreateModal} />}
              >
                {userGroupHierarchy.children && mapToItems(userGroupHierarchy.children, counter + 1)}
              </OrganizationTreeNode>
            );
          })
        : null;
    },
    [MAX_HIERARCHY_DEPTH, props.basePath, props.canAdd, props.canEdit, toggleUserGroupModals.onOpenUpdate, handleOpenCreateModal]
  );

  const renderChildren = useCallback(() => {
    if (props.userGroupHierarchy.children) {
      return mapToItems(props.userGroupHierarchy.children, 1);
    }
    return null;
  }, [props.userGroupHierarchy]);

  return (
    <React.Fragment>
      <OrganizationTree>
        <OrganizationTreeNode
          key={props.userGroupHierarchy.userGroup.id}
          content={<UserGroupHierarchyMemberGroup basePath={props.basePath} returnRoute={props.returnRoute} userGroupHierarchy={props.userGroupHierarchy} canAdd={props.canAdd} canEdit={props.canEdit} onUserGroupEdit={toggleUserGroupModals.onOpenUpdate} onUserGroupHierarchyAdd={handleOpenCreateModal} />}
        >
          {renderChildren()}
        </OrganizationTreeNode>
      </OrganizationTree>

      {userGroupModalsState.isCreateModalVisible && parentGroup && <UserGroupCreateContainer onSubmit={handleUserGroupCreateSubmit} onCancel={toggleUserGroupModals.onCloseCreate} groupType={UserGroupTypeEnum.ORGANIZATION} parentGroup={parentGroup} />}

      {userGroupModalsState.isUpdateModalVisible && userGroupModalsState.selectedEntity && (
        <UserGroupUpdateContainer userGroup={userGroupModalsState.selectedEntity} groupType={UserGroupTypeEnum.ORGANIZATION} parentGroup={userGroupModalsState.selectedEntity.parent} onSubmit={handleUserGroupUpdateSubmit} onCancel={toggleUserGroupModals.onCloseUpdate} />
      )}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------
export default withRoles(withLocalize<IUserGroupHierarchyGraphOwnProps>(UserGroupHierarchyGraph as any));

import React from 'react';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { ITimunFile } from '@src/service/business/files/util';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import DocViewer, { PDFRenderer } from 'react-doc-viewer';
import LemonImage from '@src/components/common/image/LemonImage';

// -- Prop types
// ----------

export interface IFileViewOwnProps {
  file: ITimunFile;
}
type IFileViewProps = IFileViewOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;


// -- Component
// ----------

const FileView: React.FC<IFileViewProps> = (props: IFileViewProps) => {
  const fileUrl = props.file.url || UrlBuilderFactory.buildApiFileUrl(props.file.uid);

  return (
    <React.Fragment>
      {props.file.type?.startsWith('image/') &&
        <LemonImage className="timun-fileView__uploadedImageContent" imagePath={fileUrl} />
      }
      {props.file.type?.startsWith('video/') &&
        <video className="timun-fileView__uploadedVideoContent" controls={true} src={fileUrl} />
      }
      {props.file.type?.startsWith('audio/') &&
        <audio controls={true} src={fileUrl} />
      }
      {props.file.type?.startsWith('application/pdf') &&
        <DocViewer
          className="timun-fileView__previewPDF"
          documents={[
            {
              uri: fileUrl,
              fileType: props.file.type,
            },
          ]}
          pluginRenderers={[PDFRenderer]}
          config={{
            header: {
              disableHeader: true,
            },
          }} />
      }
    </React.Fragment>
  );
};

export default withLocalize<IFileViewOwnProps>(withPathResolver(FileView as any));

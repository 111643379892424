import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseWorkpositionTree from '@src/components/usergroup/update/CourseWorkpositionTree';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { IUserGroupCourse } from '@src/model/course/UserGroupCourse';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';
import courseListBusinessStore from '@src/service/business/courses/courseListBusinessStore';
import UserGroupBusinessStore, { IUserGroupCoursePayload } from '@src/service/business/usergroup/UserGroupBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface ICourseWorkpositionTreeContainerOwnProps {
  userGroupId: string;
}

export interface ICourseWorkpositionTreeContainerDispatchProps {
  fetchCourseList: () => void;
  fetchUserGroupCourseWorkPositionList: (id: string) => ITrackableAction;
  clearUserGroupCourseWorkPositionList: () => void;
  updateUserGroupCourseWorkPositionList: (id: string, data: IUserGroupCoursePayload[]) => ITrackableAction;
}

export interface ICourseWorkpositionTreeContainerStateProps {
  courseList: ICollectionData<ICourse>;
  courseGroups: ICourseGroup[];
  workPositionList: IWorkPosition[];
  userGroupCourseWorkPositionList: ICollectionData<IUserGroupCourse>;
}
type ICourseWorkpositionTreeContainerProps = ICourseWorkpositionTreeContainerOwnProps & ICourseWorkpositionTreeContainerDispatchProps & ICourseWorkpositionTreeContainerStateProps & IWithLocalizeOwnProps;

// -- State types
// ----------

// -- Component
// ----------

/** Component for selecting courses with workplaces. OnSubmit returns an array of IIdRefs */
class CourseWorkpositionTreeContainer extends React.Component<ICourseWorkpositionTreeContainerProps> {
  componentDidMount = () => {
    this.fetchData();
  };

  componentWillUnmount = () => {
    this.props.clearUserGroupCourseWorkPositionList();
  };

  render = () => {
    return (
      <React.Fragment>
        {this.props.courseList && this.props.userGroupCourseWorkPositionList && this.props.workPositionList && (
          <CourseWorkpositionTree courseGroups={this.props.courseGroups} allCoursesList={this.props.courseList.content} assignedCourses={this.props.userGroupCourseWorkPositionList.content} workPositionList={this.props.workPositionList} onSubmit={this.handleSubmit} />
        )}
      </React.Fragment>
    );
  };

  fetchData = () => {
    this.props.fetchCourseList();
    this.props.fetchUserGroupCourseWorkPositionList(this.props.userGroupId);
  };

  handleSubmit = (data: IUserGroupCoursePayload[]) => {
    this.props.updateUserGroupCourseWorkPositionList(this.props.userGroupId, data);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseWorkpositionTreeContainerOwnProps): ICourseWorkpositionTreeContainerStateProps => ({
  courseList: courseListBusinessStore.selectors.getCourseList(state),
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
  userGroupCourseWorkPositionList: UserGroupBusinessStore.selectors.getUserGroupWorkPositionCourseList(state),
  workPositionList: CollectionBusinessStore.selectors.getCollectionContent(state, 'WorkPosition'),
});

const mapDispatchToProps = (dispatch: any): ICourseWorkpositionTreeContainerDispatchProps => ({
  fetchCourseList: () => dispatch(courseListBusinessStore.actions.fetchAdminCourseList({ filter: { status: CourseStatusEnum.PUBLISHED } })),
  fetchUserGroupCourseWorkPositionList: (id: string) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.fetchUserGroupWorkPositionCourseList({ id }))),
  clearUserGroupCourseWorkPositionList: () => dispatch(UserGroupBusinessStore.actions.clearUserGroupWorkPositionCourseList()),
  updateUserGroupCourseWorkPositionList: (id: string, data: IUserGroupCoursePayload[]) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.updateUserGroupWorkPositionCourseList({ id, data }))),
});

export default connect<ICourseWorkpositionTreeContainerStateProps, ICourseWorkpositionTreeContainerDispatchProps, ICourseWorkpositionTreeContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(CourseWorkpositionTreeContainer as any));

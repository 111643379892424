import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EmailTemplateFormWrapper from '@src/components/emailtemplate/form/EmailTemplateForm';
import { IEmailTemplate } from '@src/model/emailtemplate/EmailTemplate';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import EmailTemplateBusinessStore from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// -- Prop types
// ----------
interface IEmailTemplateUpdateContainerOwnProps {
  emailTemplate?: IEmailTemplate;
  onCancel?: () => void;
  onUpdate?: () => void;
}

interface IEmailTemplateUpdateContainerStateProps {
}

interface IEmailTemplateUpdateContainerDispatchProps {
  updateEmailTemplate: (emailTemplate: IEmailTemplate) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

// -- State types
// ----------
type IEmailTemplateUpdateContainerContentProps = IEmailTemplateUpdateContainerOwnProps & IEmailTemplateUpdateContainerStateProps & IEmailTemplateUpdateContainerDispatchProps & IWithLocalizeOwnProps;

interface IEmailTemplateUpdateContainerState {
}

// -- Component
// ----------

/** Component for email template update */
class EmailTemplateUpdateContainer extends React.Component<IEmailTemplateUpdateContainerContentProps, IEmailTemplateUpdateContainerState> {
  state = {};

  render() {
    return (
      <EmailTemplateFormWrapper emailTemplate={this.props.emailTemplate} title={this.props.translate('EMAIL_TEMPLATE.EDIT_EMAIL_TEMPLATE_TITLE')} onCancel={this.props.onCancel} onUpdate={this.handleEmailTemplateUpdate} />
    );
  }

  private handleEmailTemplateUpdate = (emailTemplate: IEmailTemplate) => {
    this.props.updateEmailTemplate(emailTemplate).track().subscribe(
      // success
      () => {
        this.props.onUpdate && this.props.onUpdate();
        this.props.reportMessage({ message: this.props.translate('COMMON.EDITED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
      }
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEmailTemplateUpdateContainerOwnProps): IEmailTemplateUpdateContainerStateProps => ({});
// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IEmailTemplateUpdateContainerDispatchProps => ({
  updateEmailTemplate: (emailTemplate: IEmailTemplate) => createTrackableAction(dispatch(EmailTemplateBusinessStore.actions.updateEmailTemplate(emailTemplate))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<IEmailTemplateUpdateContainerStateProps, IEmailTemplateUpdateContainerDispatchProps, IEmailTemplateUpdateContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(EmailTemplateUpdateContainer as any));

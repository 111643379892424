import React from 'react';
import { connect } from 'react-redux';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import ExternalEducationTemplateDataTableView from '@src/components/common/datapicker/externalEducationTemplate/ExternalEducationTemplateDataTableView';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { ExternalEducationTemplateStatusEnum, IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import ExternalEducationTemplateListBusinessStore, { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');
// -- Prop types
// ----------

export interface IExternalEducationTemplateDataTablePickerOwnProps {
  skill: ISkill;
  value?: IExternalEducationTemplate;
  onChange?: (value?: IExternalEducationTemplate) => void;
  onReset?: () => void;
}
export interface IExternalEducationTemplateDataTablePickerStateProps {
  externalEducationTemplateList: ICollectionData<IExternalEducationTemplate>;
}
export interface IExternalEducationTemplateDataTablePickerDispatchProps {
  fetchList: (params: ICollectionFetchPayload<IExternalEducationTemplateListFilter>) => void;
  clearList: () => void;
}
type IExternalEducationTemplateDataTablePickerProps = IExternalEducationTemplateDataTablePickerOwnProps & IExternalEducationTemplateDataTablePickerStateProps & IExternalEducationTemplateDataTablePickerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationTemplateDataTablePicker = (props: IExternalEducationTemplateDataTablePickerProps) => {
  const initialCollectionValues: Partial<ICollectionFetchPayload<IExternalEducationTemplateListFilter>> = { size: DEFAULT_PAGE_SIZE_VALUE, filter: { withInstanceLocationCheck: true, status: ExternalEducationTemplateStatusEnum.PUBLISHED, approvalPhase: ExternalEducationTemplateActivityPhaseEnum.APPROVED } };
  const handleSkillIdUpdate = () => {
    onUpdateList(props.fetchList, { ...collectionParams.filter, skills: [props.skill.id] });
  };

  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IExternalEducationTemplateListFilter>({
    updateFn: handleSkillIdUpdate,
    initialValues: initialCollectionValues,
  }, [props.skill.id]);

  return (
    <React.Fragment>
      {props.externalEducationTemplateList &&
        <ExternalEducationTemplateDataTableView
          externalEducationTemplateList={props.externalEducationTemplateList}
          selectedItem={props.value}
          onChange={props.onChange}
          filter={collectionParams.filter}
          onFilterUpdate={updateCollectionParams.onFilterUpdate}
          onFilterChange={updateCollectionParams.onFilterSet} />
      }
    </React.Fragment>
  );
};


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationTemplateDataTablePickerOwnProps): IExternalEducationTemplateDataTablePickerStateProps => ({
  externalEducationTemplateList: ExternalEducationTemplateListBusinessStore.selectors.getExternalEducationTemplateList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExternalEducationTemplateDataTablePickerDispatchProps => ({
  fetchList: (params: ICollectionFetchPayload<IExternalEducationTemplateListFilter>) => dispatch(ExternalEducationTemplateListBusinessStore.actions.fetchExternalEducationTemplateList(params)),
  clearList: () => dispatch(ExternalEducationTemplateListBusinessStore.actions.clearExternalEducationTemplateList()),
});

export default connect<IExternalEducationTemplateDataTablePickerStateProps, IExternalEducationTemplateDataTablePickerDispatchProps, IExternalEducationTemplateDataTablePickerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ExternalEducationTemplateDataTablePicker as any));

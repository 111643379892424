import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import InvalidUserList from '@src/components/user/list/InvalidUserList';
import UserManagementList from '@src/components/user/list/UserManagementList';
import { InvalidUsers } from '@src/model/user/InvalidUsers';
import { IUserInfo } from '@src/model/user/User';
import { Col, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface ITimunUserImportListViewOwnProps {
  validusers: IUserInfo[];
  invalidUsers: InvalidUsers[];
  canImportTimunUser: boolean;
  onTimunUserImport: (selected: IUserInfo[]) => void;
}

type ITimunUserImportListViewProps = ITimunUserImportListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const TimunUserImportListView: React.FC<ITimunUserImportListViewProps> = (props: ITimunUserImportListViewProps) => {
  return (
    <React.Fragment>
      <Row className="timun-wrapper__gutterBox--vertical" gutter={[4, 4]} justify="start">
        <Col>{props.translate('TIMUN_IMPORT_USER.VALID_USER_TITLE')}</Col>
      </Row>
      <UserManagementList userList={props.validusers} showSelectedElementsPanel={true} selectableTable={true} onSelectedUserSubmit={props.onTimunUserImport} onSelectedUserChange={props.canImportTimunUser} showAction={false} />

      <Row className="timun-wrapper__gutterBox--vertical" gutter={[4, 4]} justify="start">
        <Col>{props.translate('TIMUN_IMPORT_USER.INVALID_USER_TITLE')}</Col>
      </Row>
      <InvalidUserList invalidUsers={props.invalidUsers} />
    </React.Fragment>
  );
};

export default withLocalize<ITimunUserImportListViewOwnProps>(TimunUserImportListView as any);

import UserDataPicker from '@src/components/common/datapicker/UserDataPicker';
import WorkpositionDataPicker from '@src/components/common/datapicker/WorkpositionDataPicker';
import LemonInfoTooltip from '@src/components/common/info/LemonInfoTooltip';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { SkillBasedRecommendationType, SkillBasedRecommendationValueRelationTypeEnum, SkillBasedRecommendationValueRelationTypes } from '@src/model/skillbasedrecommendation/SkillBasedRecommendation';
import { ISkillBasedRecommendationsListFilter } from '@src/service/business/skillbasedrecommendations/SkillBasedRecommendationsBusinessStore';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React from 'react';

const valueRelationTypes = Object.entries(SkillBasedRecommendationValueRelationTypes).map(([name, id]) => {
  return {
    name,
    id,
  };
});

export interface ISkillBasedRecommendationListFilterOwnProps {
  filter?: ISkillBasedRecommendationsListFilter;
  onFilterChange?: (filter: ISkillBasedRecommendationsListFilter) => void;
}

type ISkillBasedRecommendationListFilterProps = ISkillBasedRecommendationListFilterOwnProps & IWithLocalizeOwnProps;

const SkillBasedRecommendationListFilter: React.FC<ISkillBasedRecommendationListFilterProps> = (props) => {
  const [form] = Form.useForm<ISkillBasedRecommendationsListFilter>();

  const onFinish = (values: ISkillBasedRecommendationsListFilter) => {
    props.onFilterChange?.(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  const handleTypeChange = () => {
    // reset workposition/user input because their ids point to different entitites
    form.setFieldsValue({ id: undefined });
  };

  const initialValues = {
    ...props.filter,
    type: props.filter?.type ?? SkillBasedRecommendationType.USER,
    valueRelation: props.filter?.valueRelation ?? SkillBasedRecommendationValueRelationTypeEnum.LOWER,
  };

  return (
    <Form name="SkillBasedRecommendationListFilter" layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={initialValues}>
      <Row gutter={12}>
        <Col xs={24} md={12} lg={6}>
          <Form.Item name="type" label={props.translate('SKILL_BASED_RECOMMENDATION.FILTER.TYPE_LABEL')} rules={[{ required: true, message: LocalizeService.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
            <Select onChange={handleTypeChange}>
              <Select.Option value={SkillBasedRecommendationType.USER}>{props.translate('SKILL_BASED_RECOMMENDATION.FILTER.TYPE_USER_LABEL')}</Select.Option>
              <Select.Option value={SkillBasedRecommendationType.WORKPOSITION}>{props.translate('SKILL_BASED_RECOMMENDATION.FILTER.TYPE_WORKPOSITION_LABEL')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={6}>
          <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.type !== nextValues.type} noStyle={true} preserve={false}>
            {() => {
              const isRecommendationForUser = form.getFieldValue('type') === SkillBasedRecommendationType.USER;
              const isRecommendationForWorkposition = form.getFieldValue('type') === SkillBasedRecommendationType.WORKPOSITION;

              return (
                <React.Fragment>
                  {isRecommendationForUser && (
                    <Form.Item name="id" label={props.translate('SKILL_BASED_RECOMMENDATION.FILTER.USER_LABEL')} rules={[{ required: true, message: LocalizeService.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
                      <UserDataPicker />
                    </Form.Item>
                  )}

                  {isRecommendationForWorkposition && (
                    <Form.Item name="id" label={props.translate('SKILL_BASED_RECOMMENDATION.FILTER.WORKPOSITION_LABEL')} rules={[{ required: true, message: LocalizeService.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
                      <WorkpositionDataPicker />
                    </Form.Item>
                  )}
                </React.Fragment>
              );
            }}
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={6}>
          <Form.Item
            name="valueRelation"
            label={
              <Row gutter={8}>
                <Col>{props.translate('SKILL_BASED_RECOMMENDATION.FILTER.VALUE_RELATION_LABEL')}</Col>
                <Col>
                  <LemonInfoTooltip message={props.translate('SKILL_BASED_RECOMMENDATION.FILTER.VALUE_REALTION_TOOLTIP')} />
                </Col>
              </Row>
            }
            rules={[{ required: true, message: LocalizeService.translate('COMMON.FILL_REQUIRED_FIELD') }]}
          >
            <Select>
              {valueRelationTypes.map((rel) => {
                return (
                  <Select.Option key={rel.id} value={rel.id}>
                    {props.translate(`SKILL_BASED_RECOMMENDATION.FILTER.RELATION_TYPE_${rel.name}`)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={6}>
          <Form.Item name="maxAbsoluteDiff" label={props.translate('SKILL_BASED_RECOMMENDATION.FILTER.MAX_ABSOLUTE_DIFF_LABEL')} rules={[{ required: true, message: LocalizeService.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={6}>
          <Button onClick={form.submit}>{props.translate('COMMON.ACTION_SHOW')}</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize<ISkillBasedRecommendationListFilterOwnProps>(SkillBasedRecommendationListFilter as any);

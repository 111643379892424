import { Locale } from 'antd/lib/locale-provider';
import hr_HR from 'antd/lib/locale/hr_HR';

/** Used for overriding specific antd locales, see {@link Locale} for specific labels you may wish to override */
const overrides = {
  Upload: {
    removeFile: 'Obriši',
  },
};

/** HR locale for AntD components */
export const hr: Locale = {
  ...hr_HR,
  ...overrides,
};

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import OrganizationExternalEducationTemplateListContainer from '@src/components/externaleducation/OrganizationExternalEducationTemplateListContainer';
import OrganizationEducationApplicationListContainer from '@src/components/organization/list/OrganizationEducationApplicationListContainer';
import OrganizationMemberListContainer from '@src/components/organization/members/OrganizationMemberListContainer';
import { IOrganization } from '@src/model/organization/Organization';
import { OrganizationMemberRoleEnum } from '@src/model/organization/OrganizationMemberRole';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { Tabs } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { withRouter, WithRouterProps } from 'react-router';

enum OrganizationViewContainerTabMenuKeys {
  Members = 'members',
  ExternalEducationTemplates = 'externaleducationtemplates',
  ExternalEducationApplications = 'externaleducationapplications',
}

interface IOrganizationViewTabsOwnProps {
  organization: IOrganization;
  openedTab: string;
}

type IOrganizationViewTabsProps = IOrganizationViewTabsOwnProps & IWithLocalizeOwnProps & WithRouterProps & IWithRolesOwnProps;

const OrganizationViewTabs = (props: IOrganizationViewTabsProps) => {
  const handleTabChange = useCallback(
    (key: string) => {
      props.router.push(`/organizations/${props.organization.id}/${key}`);
    },
    [props.router.push, props.organization.id]
  );

  const showMembersTab = useMemo(() => props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COORDINATOR]) || props.organization.currentUserMember?.role.id === OrganizationMemberRoleEnum.OWNER || props.organization.currentUserMember?.role.id === OrganizationMemberRoleEnum.ADMIN, [props.organization]);

  return (
    <div className="timun-organizationView__tabContainer">
      <Tabs activeKey={props.openedTab} defaultActiveKey={props.openedTab} onChange={handleTabChange} destroyInactiveTabPane={true}>
        <Tabs.TabPane tab={props.translate('ORGANIZATION_VIEW.EXTERNAL_EDUCATION_TEMPLATES_TAB_LABEL')} key={OrganizationViewContainerTabMenuKeys.ExternalEducationTemplates}>
          <div className="timun-organizationView__tab">
            <OrganizationExternalEducationTemplateListContainer educationProviderStatus={props.organization.active} educationProviderId={props.organization.id} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={props.translate('ORGANIZATION_VIEW.EXTERNAL_EDUCATION_APPLICATIONS_TAB_LABEL')} key={OrganizationViewContainerTabMenuKeys.ExternalEducationApplications}>
          <div className="timun-organizationView__tab">
            <OrganizationEducationApplicationListContainer organizationId={props.organization.id} />
          </div>
        </Tabs.TabPane>
        {showMembersTab && (
          <Tabs.TabPane tab={props.translate('ORGANIZATION_VIEW.USERS_TAB_LABEL')} key={OrganizationViewContainerTabMenuKeys.Members}>
            <div className="timun-organizationView__tab">
              <OrganizationMemberListContainer organization={props.organization} />
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default withLocalize<IOrganizationViewTabsOwnProps>(withRoles(withRouter(OrganizationViewTabs as any)));

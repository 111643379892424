import { LangUtils } from '@src/service/util/LangUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import ValidationUtils from '@src/service/util/validation/ValidationUtils';
import moment from 'moment';
// tslint:disable-next-line:no-var-requires

/**
 * Manual ant form validators. These can be used when doing manual validation of complex types eg. validating objects deep properties.
 *
 * TODO: remove dependency to LocalizeService by adding validation messages to ant's validation messages list and then using only message name.
 */

type AntdFormValidatorFn = (rule: any, value: any, callback: any, source?: any, options?: any) => any;

/** Characteristic value validator wrapper. Applies given validator to characteristic value property */
export function createValidator(validator: AntdFormValidatorFn) {
  return (rule: any, value: any, callback: any, source?: any, options?: any): any => {
    validator(rule, value, callback, source, options);
  };
}

/** Validate required value. */
export const validateRequired: AntdFormValidatorFn = (rule: any, value: any, callback: any, source?: any, options?: any): void => {
  if (LangUtils.isEmpty(value) || LangUtils.isStringEmpty(value)) {
    callback(LocalizeService.translate('COMMON.FILL_REQUIRED_FIELD'));
  }
  callback();
};

/** Validate integer value. */
export const validateInteger: AntdFormValidatorFn = (rule: any, value: any, callback: any, source?: any, options?: any): void => {
  if (String(parseInt(value, 10)) !== value) {
    callback(LocalizeService.translate('COMMON.VALIDATION.INVALID_INTEGER'));
  }
  callback();
};

/** Validate decimal value. */
export const validateDecimal: AntdFormValidatorFn = (rule: any, value: any, callback: any, source?: any, options?: any): void => {
  if (String(parseFloat(value)) !== value) {
    callback(LocalizeService.translate('COMMON.VALIDATION.INVALID_DECIMAL'));
  }
  callback();
};

export const validateDatetime: AntdFormValidatorFn = (rule: any, value: any, callback: any, source?: any, options?: any): void => {
  if (!moment(value).isValid()) {
    callback(LocalizeService.translate('COMMON.VALIDATION.INVALID_DATETIME'));
  }
  callback();
};

/**
 * Check if form values object contains any errors, firs level or nested.
 * Check is simple, run recursively through all fiels and return if any of them are not undefined. If there are nested objects (array, objects)
 * then they should also contains undefined props and values).
 */
export function hasFormErrors(errors: any): boolean {
  if (LangUtils.isArray(errors)) {
    return errors.some((value) => hasFormErrors(value));
  } else if (LangUtils.isJsObject(errors)) {
    return Object.keys(errors).some((field) => hasFormErrors(errors[field]));
  } else {
    return errors != null;
  }
}

export function getFormErrorList(errors: any): string[] {
  const errorList: string[] = [];
  if (LangUtils.isArray(errors)) {
    errors.forEach((value) => {
      if (value != null) {
        errorList.push(...getFormErrorList(value));
      }
    });
  } else if (LangUtils.isJsObject(errors)) {
    Object.keys(errors).forEach((field) => {
      errorList.push(...getFormErrorList(errors[field]));
    });
  } else {
    if (errors != null) {
      errorList.push(errors);
    }
  }

  return errorList;
}

export function getAntdV4FormErrorList(errorFields: any): string[] {
  let errorList: string[] = [];
  if (LangUtils.isArray(errorFields)) {
    errorList = errorFields.map((errorField) => errorField.errors);
  }
  return errorList;
}

/** Custom form validator for OIB */
export const validateOib = (rule: any, value: any, callback: any, source?: any, options?: any): void => {
  const errorMessage = LocalizeService.translate('COMMON.VALIDATION.INVALID_OIB');
  if (LangUtils.isStringEmpty(value) || ValidationUtils.isOibValid(value)) {
    callback();
  } else {
    callback(errorMessage);
  }
};

/** Custom form validator for IBAN */
export const validateIban = (rule: any, value: any, callback: any, source?: any, options?: any): void => {
  // value is undefined when initial value is not provided, thus triggering this validator unnecessarily
  if (LangUtils.isStringEmpty(value) || ValidationUtils.isIbanValid(value)) {
    callback();
  } else {
    callback(LocalizeService.translate('COMMON.VALIDATION.INVALID_IBAN'));
  }
};

/** Validate external URL, ie. should begin with https:// */
export const validateExternalUrl = (rule: any, value: any, callback: any, source?: any, options?: any): void => {
  // value is undefined when initial value is not provided, thus triggering this validator unnecessarily
  const httpsPrefix = 'https://';
  const httpPrefix = 'http://';
  if (value === '' || value == undefined || value.startsWith(httpsPrefix) || value.startsWith(httpPrefix)) {
    callback();
  } else {
    callback(LocalizeService.translate('COMMON.VALIDATION.INVALID_EXTERNAL_LINK'));
  }
};

/** Checkbox validation with custom message */
export const validateCheckBoxValue = (message: string) => async (rule: any, values: boolean, callback?: (error?: string | undefined) => void) => {
  if (!values) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

/** Validate if checkbox is checked */
export const validateCheckbox = (rule: any, value: any, callback: any, customErrorMsg?: string) => {
  if (value) {
    return callback();
  }
  return callback(customErrorMsg ?? LocalizeService.translate('COMMON.VALIDATION.CHECKBOX_REQUIRED'));
};

import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { IUserActivity } from '@src/model/activity/UserActivity';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { RequiredBy } from '@src/service/util/lang/type';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import { transformUserActivityListFilter } from '@src/service/business/user/util';

// -
// -------------------- Types&Consts

export interface IUserActivityListFilter {
  creator?: RequiredBy<Partial<IUserListFilter>, 'id'>;
  type?: string;
  startDate?: string;
  endDate?: string;
}
const USER_ACTIVITY_LIST_FILTER_NAME = 'USER_ACTIVITY_LIST_FILTER_NAME';

// -
// -------------------- Selectors

const getList = (store: any): ICollectionData<IUserActivity> => store.userActivityList;

const getListFilter = (store: any): IUserActivityListFilter => ListFilterBusinessStore.selectors.getListFilter(store, USER_ACTIVITY_LIST_FILTER_NAME);

// -
// -------------------- Actions

const Actions = {
  USER_ACTIVITY_LIST_FETCH: 'USER_ACTIVITY_LIST_FETCH',
  USER_ACTIVITY_LIST_LOAD: 'USER_ACTIVITY_LIST_LOAD',
  USER_ACTIVITY_LIST_CLEAR: 'USER_ACTIVITY_LIST_CLEAR',
};

const fetchList = (listFilter: IUserActivityListFilter, page: number, size: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<IUserActivityListFilter>> => {
  return {
    type: Actions.USER_ACTIVITY_LIST_FETCH,
    payload: {
      filter: listFilter,
      page,
      size,
      sort,
    },
  };
};

const loadList = (data: ICollectionData<IUserActivity>): IPayloadAction<ICollectionData<IUserActivity>> => {
  return {
    type: Actions.USER_ACTIVITY_LIST_LOAD,
    payload: data,
  };
};

const clearList = (): ILemonAction => {
  return {
    type: Actions.USER_ACTIVITY_LIST_CLEAR,
  };
};

const storeListFilter = (listFilter: IUserActivityListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(USER_ACTIVITY_LIST_FILTER_NAME, listFilter);
};

const clearListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(USER_ACTIVITY_LIST_FILTER_NAME);
};

// -
// -------------------- Side-effects

const fetchUserActivityListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IUserActivityListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_ACTIVITY_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const params = {
        ...action.payload,
        filter: action.payload.filter && transformUserActivityListFilter(action.payload.filter),
      };

      return EntityApiServiceRegistry.getService('UserActivity')
        .fetchEntityList(params)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching activity list', error);
      return o;
    })
  );
};
// -
// -------------------- Reducers
const userActivityList = (state: ICollectionData<IUserActivity> | null = null, action: IPayloadAction<ICollectionData<IUserActivity>>) => {
  if (action.type === Actions.USER_ACTIVITY_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_ACTIVITY_LIST_CLEAR) {
    return null;
  }

  return state;
};
export const userActivityListBusinessStore = {
  actions: {
    fetchList,
    clearList,
    storeListFilter,
    clearListFilter,
  },

  selectors: {
    getList,
    getListFilter,
  },

  effects: {
    fetchUserActivityListEffect,
  },

  reducers: {
    userActivityList,
  },
};
// --
// ----- Exports

export default userActivityListBusinessStore;

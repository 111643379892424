import React from 'react';

import { QuizDisplay } from '@lamarodigital/quizzler-lib-frontend';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { QuizHelperUtils } from '@src/components/exam/quiz/QuizHelperUtils';
import { IExamTemplate } from '@src/model/education/ExamTemplate';
import { Empty, Typography } from 'antd';
import useUserSettingsStore from '@src/components/common/usersettings/useUserSettings';
// -- Prop types
// ----------

export interface IExamTemplateQuizDisplayViewOwnProps {
  examTemplate: IExamTemplate;
}
type IExamTemplateQuizDisplayViewProps = IExamTemplateQuizDisplayViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display exam template question collection as exam */
const ExamTemplateQuizDisplayView: React.FC<IExamTemplateQuizDisplayViewProps> = (props: IExamTemplateQuizDisplayViewProps) => {
  const [userSettings] = useUserSettingsStore();

  return (
    <React.Fragment>
      {props.examTemplate.collection ? (
        <React.Fragment>
          <Typography.Paragraph strong={true}>{props.translate('EXAM_TEMPLATE.QUIZ_DISPLAY.INFO_TEXT_LABEL')}</Typography.Paragraph>
          <QuizDisplay locale={userSettings?.locale} resolveFileUrl={QuizHelperUtils.resolveFileUrl} showHelpIntroActionButton={false} showRedoIntroActionButton={false} showEvaluationFeedback={false} quiz={props.examTemplate?.collection} />
        </React.Fragment>
      ) : (
        <Empty description={props.translate('EXAM_TEMPLATE.QUIZ_DISPLAY.NO_QUESTION_EMPTY_MESSAGE_LABEL')} />
      )}
    </React.Fragment>
  );
};

export default withLocalize<IExamTemplateQuizDisplayViewOwnProps>(ExamTemplateQuizDisplayView as any);

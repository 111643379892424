import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DialogPanel from '@src/components/common/panel/DialogPanel';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationFileListView from '@src/components/externalEducationApplication/common/EducationApplicationFileListView';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationFileListUpload from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFileListUpload';
import ExternalEducationApplicationFilePreview from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFilePreview';
import ExternalEducationApplicationFinancingAgreementPreviewModal from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFinancingAgreementPreviewModal';
import ExternalEducationApplicationAgreementForm from '@src/components/externalEducationApplication/form/ExternalEducationApplicationAgreementForm';
import ExternalEducationUserCategoryForm from '@src/components/externalEducationApplication/form/ExternalEducationUserCategoryForm';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import IIdRef from '@src/model/common/IdRef';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { FinancingAgreemntTypeEnum, IExternalEducationApplicationFilePreviewData } from '@src/model/externalEducationApplication/ExternalEducationApplicationFilePreviewData';
import { ExternalEducationApplicationFileTypeEnum } from '@src/model/file/FileType';
import { IFile } from '@src/model/file/File';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { IUserCategory } from '@src/model/user/UserCategory';
import { IFileListsByType } from '@src/service/business/files/util';
import { ITrackableAction } from '@src/service/util/action/trackAction';
import { Button, Col, Row, Typography } from 'antd';
import React from 'react';

const LAYOUT_BUTTONS_3 = {
  sm: 24,
  xs: 24,
  md: 8,
};

// -- Prop types
// ----------
interface IExternalEducationApplicationCoordinatorApprovalViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  canParticipate?: boolean;
  isCurrentUserAdmin?: boolean;
  onUpdateExternalEducationApplication?: (data: IExternalEducationApplication) => void;
  onUserCategorySubmit?: (data: IExternalEducationApplication) => void;
  onExternalEducationApplicationAgreementSubmit?: (data: IExternalEducationApplication) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: EducationApplicationActivityPhaseEnum) => void;
  onCoordinatorAdd?: () => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onFilePreview: (externalEducationApplicationId: string, filePreviewData: IExternalEducationApplicationFilePreviewData) => ITrackableAction;
}

type IExternalEducationApplicationCoordinatorApprovalViewProps = IExternalEducationApplicationCoordinatorApprovalViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationCoordinatorApprovalViewState {
  showAgreementForm: boolean;
  showPaymentForm: boolean;
  isPreviewContractModalVisible: boolean;
  isReturnToContractGenerationModalVisible: boolean;
  financingAgreementPreview?: string;
}

// -- Component
// ----------

class ExternalEducationApplicationCoordinatorApprovalView extends React.Component<IExternalEducationApplicationCoordinatorApprovalViewProps, IExternalEducationApplicationCoordinatorApprovalViewState> {
  state: IExternalEducationApplicationCoordinatorApprovalViewState = {
    showAgreementForm: false,
    showPaymentForm: false,
    isPreviewContractModalVisible: false,
    isReturnToContractGenerationModalVisible: false,
    financingAgreementPreview: undefined,
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationCoordinatorApprovalViewProps, prevState: IExternalEducationApplicationCoordinatorApprovalViewState) => {
    //
  };

  isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  handleChangePhase = (phase: EducationApplicationActivityPhaseEnum) => {
    if (this.props.onChangePhase) {
      this.props.onChangePhase(phase);
    }
  };

  render() {
    const hasCoordinator = EducationApplicationHelperUtils.hasCoordinator(this.props.externalEducationApplication.activity);
    const canPreviewContract = this.props.canParticipate || this.props.isCurrentUserAdmin;
    const canSubmitContractGeneration = this.props.canParticipate && this.props.externalEducationApplication.externalEducationTemplate && this.props.externalEducationApplication.offerCode && this.props.externalEducationApplication.districtOffice && this.props.externalEducationApplication.financingAgreementType;
    return (
      <React.Fragment>
        {this.isApplicationInPhases(['USER_CANCELED']) && (
          <Row>
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_CANCELED_TEXT')}</Col>
          </Row>
        )}

        {this.isApplicationInPhases(['EXTERNAL_EDUCATION_APPLICATION_DENIED']) && (
          <Row>
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DENIED_TEXT')}</Col>
          </Row>
        )}

        {this.isApplicationInPhases(['SUBMITTED']) && (
          <React.Fragment>
            <Row>
              <Col>
                {hasCoordinator ? (
                  <Button block={true} disabled={!this.props.canParticipate} onClick={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.AWAITING_APPROVAL)} data-test-id="timun-externalEducationApplicationView__continueButton">
                    {this.props.translate('COMMON.ACTION_CONTINUE')}
                  </Button>
                ) : (
                  <Button type="primary" block={true} onClick={() => this.props.onCoordinatorAdd && this.props.onCoordinatorAdd()} data-test-id="timun-externalEducationApplicationView__takeRequestButton">
                    {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.TAKE_APPLICATION_BUTTON_TEXT')}
                  </Button>
                )}
              </Col>
            </Row>
          </React.Fragment>
        )}
        {this.isApplicationInPhases(['EXTERNAL_EDUCATION_TEMPLATE_SELECTION']) && <Typography.Paragraph> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_APPROVED_TEXT')}</Typography.Paragraph>}
        {this.isApplicationInPhases(['AWAITING_APPROVAL']) &&
          // Coordinator need select and save user category before proceeding to the approval step
          (this.props.externalEducationApplication.userCategories?.length >= 1 ? (
            <React.Fragment>
              <Row>
                <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.IN_PROCCESS_TEXT')}</Col>
              </Row>
              &nbsp;
              <Row gutter={[8, 12]}>
                <Col {...LAYOUT_BUTTONS_3}>
                  <NoteCreateContainer
                    disabled={!this.props.canParticipate}
                    objectId={this.props.externalEducationApplication.id}
                    objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
                    objectPublic={true}
                    buttonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_BUTTON_TEXT')}
                    modalTitle={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_MODAL_TITLE')}
                    modalOkButtonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_CONFIRM_BUTTON')}
                    onCreateNote={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.EXTERNAL_EDUCATION_APPLICATION_DENIED)}
                    hideIcon={true}
                  />
                </Col>
                <Col {...LAYOUT_BUTTONS_3}>
                  <NoteCreateContainer
                    disabled={!this.props.canParticipate}
                    objectId={this.props.externalEducationApplication.id}
                    objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
                    objectPublic={true}
                    buttonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_BUTTON_TEXT')}
                    modalTitle={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_MODAL_TITLE')}
                    modalOkButtonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_BUTTON_TEXT')}
                    onCreateNote={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.RETURNED_TO_USER)}
                    hideIcon={true}
                  />
                </Col>
                <Col {...LAYOUT_BUTTONS_3}>
                  <Button disabled={!this.props.canParticipate} type="primary" block={true} onClick={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.CONTRACT_DATA_PROCESSING)} data-test-id="timun-externalEducationApplicationView__approveApplicationButton">
                    {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPROVE_APPLICATION_BUTTON_TEXT')}
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Row>
                <Col xs={24} md={12} lg={8}>
                  <ExternalEducationUserCategoryForm value={this.props.externalEducationApplication.userCategories} disabled={!this.props.canParticipate} onSubmit={this.handleUserCategorySumbit} />
                </Col>
              </Row>
            </React.Fragment>
          ))}

        {this.isApplicationInPhases(['CONTRACT_DATA_PROCESSING']) && (
          <React.Fragment>
            <DialogPanel title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EDUCATION_APPLICATION_AGREEMENT_PANEL_TITLE')}>
              <ExternalEducationApplicationAgreementForm disabled={!this.props.canParticipate} onSubmit={this.handleAgreementSubmit} externalEducationApplication={this.props.externalEducationApplication} />
            </DialogPanel>
            {canSubmitContractGeneration && this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_GENERATION_TEXT')}
            <Row gutter={8}>
              <Col>
                <Button disabled={!canSubmitContractGeneration && !this.props.isCurrentUserAdmin} onClick={this.handleFinancingAgreementFilePreview} type="default" block={false} data-test-id="timun-externalEducationApplicationView__previewContractButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.PREVIEW_CONTRACT_BUTTON_TEXT')}
                </Button>
              </Col>
              <Col>
                <Button type="primary" block={false} disabled={!canSubmitContractGeneration} onClick={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.CONTRACT_GENERATION)} data-test-id="timun-externalEducationApplicationView__requestSignedContractButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REQUEST_SIGNED_CONTRACT_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases(['CONTRACT_GENERATION']) && (
          <React.Fragment>
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.AWAITING_SIGNED_CONTRACT_TEXT')}</Col>
            </Row>
            <Row className="timun-wrapper__gutterBox--vertical" justify="end">
              <Col>
                <Button type="default" block={false} disabled={!canPreviewContract} onClick={() => this.toggleReturnToContractGenerationModal(true)}>
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.PREVIEW_CONTRACT_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases(['AWAITING_SIGNED_CONTRACT']) && (
          <React.Fragment>
            <Row>
              <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.SIGNED_CONTRACT_UPLOADED_TEXT')}</Col>
            </Row>

            <EducationApplicationFileListView files={this.props.applicationFiles} displayTypes={['SIGNED_FINANCING_AGREEMENT']} />

            <Typography.Paragraph className="timun-wrapper__gutterBox--vertical"> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.MUTUALLY_SIGNED_FINANCING_AGREEMENT_TEXT')} </Typography.Paragraph>

            <GridItem label={this.props.translate('FILES.TYPE_ENUM.LABEL.MUTUALLY_SIGNED_FINANCING_AGREEMENT')} requiredMark={true}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="MUTUALLY_SIGNED_FINANCING_AGREEMENT" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <Row justify="start" gutter={8}>
              <Col>
                <Button
                  type="primary"
                  block={false}
                  onClick={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.AWAITING_PAYMENT)}
                  disabled={!EducationApplicationHelperUtils.hasFilesInAllTypes(this.props.applicationFiles, ['MUTUALLY_SIGNED_FINANCING_AGREEMENT']) || !this.props.canParticipate}
                  data-test-id="timun-externalEducationApplicationView__enableEducationButton"
                >
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.SEND_MUTUALY_SIGNED_FINANCING_AGREEMENT_BUTTON_TEXT')}
                </Button>
              </Col>
              <Col>
                <Button type="default" block={false} disabled={!canPreviewContract} onClick={() => this.toggleReturnToContractGenerationModal(true)}>
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.PREVIEW_CONTRACT_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>

            <Row className="timun-wrapper__gutterBox--vertical" align="middle" justify="end" gutter={4}>
              <Col> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_TO_CONTRACT_DATA_PROCESSING_INFO_TEXT')}</Col>
              <Col>
                <Button type="default" block={false} disabled={!this.props.canParticipate} onClick={this.handleReturnToContractDataProcessing}>
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_TO_CONTRACT_DATA_PROCESSING_BUTTON_LABEL')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {this.isApplicationInPhases(['AWAITING_PAYMENT']) && (
          <React.Fragment>
            <GridItem label={this.props.translate('FILES.TYPE_ENUM.LABEL.PROOF_OF_PAYMENT')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.PROOF_OF_PAYMENT_INFO')} requiredMark={true}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="PROOF_OF_PAYMENT" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <Row>
              <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENABLE_EDUCATION_TEXT')}</Col>
            </Row>

            <Row gutter={8}>
              <Col>
                <Button
                  type="primary"
                  block={false}
                  onClick={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.EDUCATION_IN_PROGRESS)}
                  disabled={!EducationApplicationHelperUtils.hasFilesInAllTypes(this.props.applicationFiles, ['PROOF_OF_PAYMENT']) || !this.props.canParticipate}
                  data-test-id="timun-externalEducationApplicationView__enableEducationButton"
                >
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENABLE_EDUCATION_BUTTON_TEXT')}
                </Button>
              </Col>
              <Col>
                <Button type="default" block={false} disabled={!canPreviewContract} onClick={() => this.toggleReturnToContractGenerationModal(true)}>
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.PREVIEW_CONTRACT_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>

            <Row className="timun-wrapper__gutterBox--vertical" justify="end">
              <Col>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_TO_CONTRACT_DATA_PROCESSING_INFO_TEXT')}
                &nbsp;
                <Button type="default" block={false} onClick={this.handleReturnToContractDataProcessing} disabled={!this.props.canParticipate} data-test-id="timun-externalEducationApplicationView__returnToContractDataProcessingButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_TO_CONTRACT_DATA_PROCESSING_BUTTON_LABEL')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {/* common deny request button */}
        {this.isApplicationInPhases(['EXTERNAL_EDUCATION_TEMPLATE_SELECTION', 'CONTRACT_GENERATION', 'CONTRACT_DATA_PROCESSING', 'AWAITING_SIGNED_CONTRACT', 'AWAITING_PAYMENT']) && (
          <React.Fragment>
            <Row justify="end">
              <Col>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_LABEL')}
                &nbsp;
                <NoteCreateContainer
                  disabled={!this.props.canParticipate}
                  objectId={this.props.externalEducationApplication.id}
                  objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
                  objectPublic={true}
                  buttonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_BUTTON_TEXT')}
                  modalTitle={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_MODAL_TITLE')}
                  modalOkButtonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_BUTTON_TEXT')}
                  onCreateNote={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.EXTERNAL_EDUCATION_APPLICATION_DENIED)}
                  hideIcon={true}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
        {/* Preview contract modal */}

        {this.state.isPreviewContractModalVisible && this.state.financingAgreementPreview && (
          <ExternalEducationApplicationFilePreview title={this.props.translate('FILES.TYPE.FINANCING_AGREEMENT_TEMPLATE_LABEL')} htmlFilePreview={this.state.financingAgreementPreview} onCancel={() => this.togglePreviewContractModal(false)} />
        )}

        {this.state.isReturnToContractGenerationModalVisible && (
          <ExternalEducationApplicationFinancingAgreementPreviewModal
            okText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.SAVE_NEW_CONTRACT_TYPE_BUTTON_TEXT')}
            externalEducationApplication={this.props.externalEducationApplication}
            onFilePreview={this.props.onFilePreview}
            onCancel={() => this.toggleReturnToContractGenerationModal(false)}
            onSubmit={this.handleReturnToContracGeneration}
          />
        )}
      </React.Fragment>
    );
  }

  handleFinancingAgreementFilePreview = () => {
    this.props
      .onFilePreview(this.props.externalEducationApplication.id, { fileType: ExternalEducationApplicationFileTypeEnum.FINANCING_AGREEMENT, contractType: this.props.externalEducationApplication.financingAgreementType })
      .track()
      .subscribe((response) => {
        this.setState({ financingAgreementPreview: response.content });
        this.togglePreviewContractModal(true);
      });
  };

  handleReturnToContractDataProcessing = () => {
    confirmationDialog({
      onConfirm: () => this.handleChangePhase(EducationApplicationActivityPhaseEnum.CONTRACT_DATA_PROCESSING),
      title: this.props.translate('COMMON.CONFIRMATION_TITLE'),
      content: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_TO_CONTRACT_DATA_PROCESSING_CONFIRMATION_TEXT'),
    });
  };

  handleReturnToContracGeneration = (financingAgreementType: FinancingAgreemntTypeEnum) => {
    this.props.onUpdateExternalEducationApplication?.({ ...this.props.externalEducationApplication, financingAgreementType });
    this.toggleReturnToContractGenerationModal(false);
  };

  toggleReturnToContractGenerationModal = (isVisible: boolean) => {
    this.setState({ isReturnToContractGenerationModalVisible: isVisible });
  };

  togglePreviewContractModal = (isVisible: boolean) => {
    this.setState({ isPreviewContractModalVisible: isVisible });
  };

  toggleAgreementForm = (isVisible: boolean) => {
    this.setState({
      showAgreementForm: isVisible,
    });
  };

  togglePaymentForm = (isVisible: boolean) => {
    this.setState({
      showPaymentForm: isVisible,
    });
  };

  handleUserCategorySumbit = (userCategoryIdList: IIdRef<string>[]) => {
    if (this.props.externalEducationApplication) {
      this.props.onUserCategorySubmit?.({
        ...this.props.externalEducationApplication,
        userCategories: userCategoryIdList as IUserCategory[],
      });
    }
  };

  handleAgreementFormClose = () => {
    this.toggleAgreementForm(false);
  };

  handleAgreementSubmit = (data: IExternalEducationApplication) => {
    this.props.onExternalEducationApplicationAgreementSubmit?.(data);
  };
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationCoordinatorApprovalViewOwnProps>(ExternalEducationApplicationCoordinatorApprovalView as any));

import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExternalEducationTemplateHelperUtils } from '@src/components/externaleducation/common/ExternalEducationTemplateHelperUtils';
import ExternalEducationInstanceListView from '@src/components/externaleducationistance/ExternalEducationInstanceListView';
import ExternalEducationInstanceModalForm from '@src/components/externaleducationistance/form/ExternalEducationInstanceModalForm';
import { IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { ExternalEducationTemplateStatusEnum, IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import ExternalEducationInstanceBusinessStore, { IExternalEducationInstanceCreatePayload } from '@src/service/business/externaleducations/ExternalEducationInstanceBusinessStore';
import ExternalEducationInstanceListBusinessStore, { IExternalEducationInstanceListFilter } from '@src/service/business/externaleducations/ExternalEducationInstanceListBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import RoleUtils from '@src/service/util/role/RoleUtils';

// -- Const
// ----------
const VIEW_NAME = '@@EXTERNAL_EDUCATION_INSTANCE_LIST';

// -- Prop types
// ----------

export interface IExternalEducationInstanceListContainerOwnProps {
  externalEducationTemplate: IExternalEducationTemplate;
  isCurrentUserMember?: boolean;
}
export interface IExternalEducationInstanceListContainerStateProps {
  externalEducationInstanceList: ICollectionData<IExternalEducationInstance>;
}
export interface IExternalEducationInstanceListContainerDispatchProps {
  fetchExternalEducationInstanceList: (params: ICollectionFetchPayload<IExternalEducationInstanceListFilter>) => ITrackableAction;
  clearExternalEducationInstanceList: () => void;

  createExternalEducationInstance: (data: IExternalEducationInstanceCreatePayload) => ITrackableAction;
  updateExternalEducationInstance: (data: IExternalEducationInstance) => ITrackableAction;
}
type IExternalEducationInstanceListContainerProps = IExternalEducationInstanceListContainerOwnProps & IExternalEducationInstanceListContainerStateProps
  & IExternalEducationInstanceListContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education instance list with modal */
const ExternalEducationInstanceListContainer = (props: IExternalEducationInstanceListContainerProps) => {
  const updateInstanceList = () => onUpdateList(props.fetchExternalEducationInstanceList, { ...collectionParams.filter, externalEducationTemplate: props.externalEducationTemplate.id });

  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IExternalEducationInstanceListFilter>({
    viewName: VIEW_NAME,
    updateFn: updateInstanceList,
  }, [props.externalEducationTemplate.id]);

  useEffect(() => {
    return () => {
      props.clearExternalEducationInstanceList();
    };
  }, []);

  const isTemplateInStatus = useCallback((statuses: Array<keyof typeof ExternalEducationTemplateStatusEnum>) => ExternalEducationTemplateHelperUtils.isEducationTemplateInStatus(props.externalEducationTemplate, statuses), [props.externalEducationTemplate.id]);

  const [externalEducationInstanceModalsState, toggleExternalEducationInstanceModals] = useEntityModalsState<IExternalEducationInstance>();

  const handleCreate = useCallback((externalEducationInstanceData: IExternalEducationInstanceCreatePayload) => {
    props.createExternalEducationInstance(externalEducationInstanceData).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleExternalEducationInstanceModals.onCloseCreate();
      }
    );
  }, [props.createExternalEducationInstance, toggleExternalEducationInstanceModals, onUpdateList]);

  const handleUpdate = useCallback((externalEducationInstanceData: IExternalEducationInstance) => {
    props.updateExternalEducationInstance(externalEducationInstanceData).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleExternalEducationInstanceModals.onCloseUpdate();
      }
    );
  }, [props.createExternalEducationInstance, toggleExternalEducationInstanceModals, onUpdateList]);

  const canEditInstance = ((props.externalEducationTemplate.status.id !== ExternalEducationTemplateStatusEnum.ARCHIVED) && props.isCurrentUserMember) || ((isTemplateInStatus(['ARCHIVED', 'PUBLISHED'])) && (RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN])));
  const canAddInstance = (RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) || props.isCurrentUserMember);

  return (
    <React.Fragment>
      {/* List */}
      {props.externalEducationInstanceList && <ExternalEducationInstanceListView
        externalEducationInstanceList={props.externalEducationInstanceList}
        filter={collectionParams.filter}
        onPageChange={updateCollectionParams.onPageChange}
        canAdd={canAddInstance}
        onAdd={toggleExternalEducationInstanceModals.onOpenCreate}
        canEdit={canEditInstance}
        onEdit={toggleExternalEducationInstanceModals.onOpenUpdate}
      />}

      {/* Modal */}
      {(externalEducationInstanceModalsState.isCreateModalVisible || externalEducationInstanceModalsState.isUpdateModalVisible) && <ExternalEducationInstanceModalForm
        externalEducationInstance={externalEducationInstanceModalsState.selectedEntity}
        externalEducationTemplateId={props.externalEducationTemplate.id}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onCancel={toggleExternalEducationInstanceModals.onCloseCreate} />}
    </React.Fragment>
  );

};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationInstanceListContainerOwnProps): IExternalEducationInstanceListContainerStateProps => ({
  externalEducationInstanceList: ExternalEducationInstanceListBusinessStore.selectors.getExternalEducationInstanceList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExternalEducationInstanceListContainerDispatchProps => ({
  fetchExternalEducationInstanceList: (params: ICollectionFetchPayload<IExternalEducationInstanceListFilter>) => createTrackableAction(dispatch(ExternalEducationInstanceListBusinessStore.actions.fetchExternalEducationInstanceList(params))),
  clearExternalEducationInstanceList: () => dispatch(ExternalEducationInstanceListBusinessStore.actions.clearExternalEducationInstanceList()),

  createExternalEducationInstance: (data: IExternalEducationInstanceCreatePayload) => createTrackableAction(dispatch(ExternalEducationInstanceBusinessStore.actions.createExternalEducationInstance(data))),
  updateExternalEducationInstance: (data: IExternalEducationInstance) => createTrackableAction(dispatch(ExternalEducationInstanceBusinessStore.actions.updateExternalEducationInstance(data))),
});


export default connect<IExternalEducationInstanceListContainerStateProps, IExternalEducationInstanceListContainerDispatchProps, IExternalEducationInstanceListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ExternalEducationInstanceListContainer as any));

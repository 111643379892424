import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { INotification } from '@src/model/notification/Notification';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { sortOrder } from '@src/service/util/config/TableUtils';
import { Button, Col, Empty, Row, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import moment from 'moment';
import React from 'react';

// -- Const
// ----------
const DATE_WITH_TIME = AppConfigService.getValue('dateTimeFormat.dateWithTime');
const DEFAULT_SORT = AppConfigService.getValue('components.notification.sort.date.descend.id');
const TABLE_SCROLL_SIZE = AppConfigService.getValue('layout.table.scrollSize');

const notificationListSortItems: any = {
  createdAt: {
    id: 'createdAt',
  },
  title: {
    id: 'title',
  },
};

// -- Prop types
// ----------
interface INotificationListOwnProps {
  notifications: ICollectionData<INotification>;
  showControls: boolean;
  onSortChange: (sort: string) => void;
  onPageChange: (page: number, pageSize?: number) => void;
  onDelete?: (notification: INotification) => void;
  onEdit?: (notification: INotification) => void;
}

type INotificationListProps = INotificationListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display notification list component */
class NotificationList extends React.Component<INotificationListProps> {
  columns: Array<ColumnProps<INotification>> = [
    {
      key: 'createdAt',
      title: this.props.translate('NOTIFICATION_LIST.DATE_TIME'),
      render: (notification: any) => moment(notification.createdAt).format(DATE_WITH_TIME),
      width: '15%',
      sorter: true,
      // this is BE default sort
      defaultSortOrder: sortOrder(DEFAULT_SORT),
    },
    {
      key: 'title',
      title: this.props.translate('NOTIFICATION_LIST.NOTIFICATION_TITLE'),
      render: (notification: INotification) => notification.title,
      width: '20%',
      sorter: true,
    },
    {
      key: 'message',
      title: this.props.translate('NOTIFICATION_LIST.NOTIFICATION_MESSAGE'),
      render: (notification: INotification) => <Typography.Paragraph className="timun-notificationList__message"> {notification.message} </Typography.Paragraph>,
      width: '40%',
    },
    // {TODO: commented for EOS instructions purpose. Only generic type is available
    //   key: 'type.name',
    //   title: this.props.translate('NOTIFICATION_LIST.NOTIFICATION_TYPE'),
    //   render: (notification: INotification) => this.props.translate(`NOTIFICATION_FORM.${notification.type.name}_TYPE`),
    //   width: this.props.showControls ? '10%' : '25%',
    // },
    {
      key: 'buttons',
      render: (notification: INotification) => {
        return this.props.showControls && (
          <Row justify="end" gutter={6}>
            <Col>
              <Button icon={<LemonIcon name="edit" />} onClick={() => this.openUpdateModal(notification)} />
            </Col>
            <Col>
              <DeleteElementButton<INotification> item={notification} onDelete={this.props.onDelete} icon={<LemonIcon name="delete" />} />
            </Col>
          </Row>
        );
      },
      width: this.props.showControls ? '15%' : '0%',
    },
  ];

  render() {
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          rowKey={(notification) => notification.id}
          dataSource={this.props.notifications ? this.props.notifications.content : []}
          locale={{ emptyText: <Empty description={this.props.translate('NOTIFICATION_LIST.NO_DATA')} /> }}
          onChange={this.handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={false}
          scroll={{ x: TABLE_SCROLL_SIZE }}
        />
      </React.Fragment>
    );
  }

  openUpdateModal = (notification: INotification) => {
    this.props.onEdit && this.props.onEdit(notification);
  };

  private handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.columnKey && notificationListSortItems[sorter.columnKey]) {
      this.props.onSortChange(`${notificationListSortItems[sorter.columnKey].id},${sorter.order.slice(0, -3)}`);
    }
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<INotificationListOwnProps>(NotificationList as any);

import React from 'react';
import { connect } from 'react-redux';

import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserSkillEvaluationListView from '@src/components/user/view/UserSkillEvaluationListView';
import { IUserInfo } from '@src/model/user/User';
import { IUserSkillEvaluation } from '@src/model/user/UserSkillEvaluation';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import UserSkillEvaluationBusinessStore, { IUserSkillEvaluationCreatePayload } from '@src/service/business/user/userSkillEvaluationBusinessStore';
import UserSkillEvaluationListBusinessStore, { IUserSkillEvaluationListFilter } from '@src/service/business/user/userSkillEvaluationListBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const DEFAULT_SORT_VALUE = ['createdDateTime,desc'];
const VIEW_NAME = '@@USER_SKILL_EVALUATION_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<IUserSkillEvaluationListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { sort: DEFAULT_SORT_VALUE },
};

// -- Prop types
// ----------
export interface IUserSkillEvaluationListContainerOwnProps {
  user: IUserInfo;
  workPositionList: IWorkPosition[];
}

export interface IUserSkillEvaluationListContainerStateProps {
  userSkillEvaluationList: ICollectionData<IUserSkillEvaluation>;
  currentUser: IUserInfo;
}

export interface IUserSkillEvaluationListContainerDispatchProps {
  fetchUserSkillEvaluationList: (id: string, params: ICollectionFetchPayload<IUserSkillEvaluationListFilter>) => void;
  createUserSkillEvaluation: (userSkillEvaluation: IUserSkillEvaluationCreatePayload) => ITrackableAction;
  updateUserSkillEvaluation: (userSkillEvaluation: IUserSkillEvaluation) => ITrackableAction;
  deleteUserSkillEvaluation: (id: string) => ITrackableAction;
}

type IUserSkillEvaluationListContainerProps = IUserSkillEvaluationListContainerOwnProps & IUserSkillEvaluationListContainerStateProps & IUserSkillEvaluationListContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IUserSkillEvaluationListFilter>;

interface IUserSkillEvaluationListContainerState { }

// -- Component
// ----------
/** Describe your component ... */
class UserSkillEvaluationListContainer extends React.Component<IUserSkillEvaluationListContainerProps, IUserSkillEvaluationListContainerState> {
  state: IUserSkillEvaluationListContainerState = {};

  componentDidMount() {
    this.updateUserSkillEvaluationList();
  }

  componentDidUpdate(prevProps: IUserSkillEvaluationListContainerProps, prevState: IUserSkillEvaluationListContainerState) {
    if (prevProps.user !== this.props.user || prevProps.collectionParams !== this.props.collectionParams) {
      this.updateUserSkillEvaluationList();
    }
  }

  render = () => {
    return (
      <React.Fragment>
        <UserSkillEvaluationListView
          userId={this.props.user.id}
          currentUser={this.props.currentUser}
          skillEvaluationList={this.props.userSkillEvaluationList}
          onCreate={this.handleUserSkillEvaluationCreate}
          onUpdate={this.handleUserSkillEvaluationUpdate}
          onDelete={this.handleUserSkillEvaluationDelete}
          onChange={this.updateUserSkillEvaluationList}
          workPosition={this.getWorkPosition()}
        />
      </React.Fragment>
    );
  };

  handleUserSkillEvaluationUpdate = (userSkillEvaluation: IUserSkillEvaluation): ITrackableAction => {
    return this.props.updateUserSkillEvaluation(userSkillEvaluation);
  };

  handleUserSkillEvaluationCreate = (userSkillEvaluation: IUserSkillEvaluationCreatePayload): ITrackableAction => {
    const skillEvaluationData = { ...userSkillEvaluation, user: { id: this.props.user.id } };
    return this.props.createUserSkillEvaluation(skillEvaluationData);
  };

  handleUserSkillEvaluationDelete = (userSkillEvaluation: IUserSkillEvaluation) => {
    this.props
      .deleteUserSkillEvaluation(userSkillEvaluation.id)
      .track()
      .subscribe(() => this.updateUserSkillEvaluationList());
  };

  fetchUserSkillEvaluationList = (payload: ICollectionFetchPayload<IUserSkillEvaluationListFilter>) => {
    this.props.fetchUserSkillEvaluationList(this.props.user.id, payload);
  };

  getWorkPosition = () => {
    const workPosition = this.props.workPositionList.find((workPos) => {
      return workPos.id === this.props.user.workData?.workPosition?.id;
    });
    return workPosition;
  };

  private updateUserSkillEvaluationList = () => {
    this.props.onUpdateList(this.fetchUserSkillEvaluationList);
  };
}

// -- HOCs and exports
// ----------
// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserSkillEvaluationListContainerOwnProps): IUserSkillEvaluationListContainerStateProps => ({
  userSkillEvaluationList: UserSkillEvaluationListBusinessStore.selectors.getUserSkillEvaluationList(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserSkillEvaluationListContainerDispatchProps => ({
  fetchUserSkillEvaluationList: (id: string, params: ICollectionFetchPayload<IUserSkillEvaluationListFilter>) => dispatch(UserSkillEvaluationListBusinessStore.actions.fetchUserSkillEvaluationList(id, params)),
  createUserSkillEvaluation: (userSkillEvaluation: IUserSkillEvaluationCreatePayload) => createTrackableAction(dispatch(UserSkillEvaluationBusinessStore.actions.createUserSkillEvaluation(userSkillEvaluation))),
  updateUserSkillEvaluation: (userSkillEvaluation: IUserSkillEvaluation) => createTrackableAction(dispatch(UserSkillEvaluationBusinessStore.actions.updateUserSkillEvaluation(userSkillEvaluation))),
  deleteUserSkillEvaluation: (id: string) => createTrackableAction(dispatch(UserSkillEvaluationBusinessStore.actions.deleteUserSkillEvaluation(id))),
});

export default connect<IUserSkillEvaluationListContainerStateProps, IUserSkillEvaluationListContainerDispatchProps, IUserSkillEvaluationListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(UserSkillEvaluationListContainer as any)));

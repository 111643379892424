import { IAddress } from '@src/model/user/Address';
import LocalizeService from '@src/service/util/localize/LocalizeService';

export class AddressUtils {
  /** Display street with home number, zip and city name */
  static getFullAddress = (address?: IAddress): string => {
    let fullAddress = LocalizeService.translate('COMMON.ADDRESS_MISSING_LABEL');
    if (address?.zip) {
      fullAddress = `${address.streetWithHomeNumber}, ${address.zip}, ${address.city.name}, ${address.city.county.name}`;
    } else if (address) {
      fullAddress = `${address.streetWithHomeNumber}, ${address.city.name} - ${address.city.county.name}`;
    }
    return fullAddress;
  };
}

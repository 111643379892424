import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import React from 'react';
import { Button, Col, Row } from 'antd';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { ICollectionData } from '@src/service/business/common/types';
import { IProgressTracker, ProgressTrackerTypeEnum } from '@src/model/progresstracker/ProgressTracker';
import ProgressTrackerList from '@src/components/user/list/ProgressTrackerList';
import UserListUploadModal from '@src/components/user/list/UserListUploadModal';
import { IFile } from '@src/model/file/File';

export interface IAdminProgressTrackerListViewOwnProps {
  showImportModal?: () => void;
  progressTrackerList: ICollectionData<IProgressTracker>;
  onPageChange?: (page: number, size?: number) => void;
  onClose?: () => void;
  onSubmit?: (file: IFile) => void;
  isImportModalVisible: boolean;
  type: ProgressTrackerTypeEnum;
  onReload: () => void;
}

type IAdminProgressTrackerListViewProps = IAdminProgressTrackerListViewOwnProps & IWithLocalizeOwnProps;

const AdminProgressTrackerListView: React.FC<IAdminProgressTrackerListViewProps> = (props: IAdminProgressTrackerListViewProps) => {
  return (
    <React.Fragment>
      <Row className="timun-wrapper__gutterBox--vertical" gutter={[4, 4]} justify="end">
        <Col>
          <Button icon={<LemonIcon name="plus" />} type="primary" onClick={props.showImportModal} data-test-id="timun-courseListView__createSCORMButton">
            {props.translate('USER_VIEW_CONTAINER.IMPORT_USERS')}
          </Button>
        </Col>
      </Row>
      {props.progressTrackerList && <ProgressTrackerList progressTrackerList={props.progressTrackerList} onPageChange={props.onPageChange} onReload={props.onReload} />}
      {props.isImportModalVisible && <UserListUploadModal onClose={props.onClose} onSubmit={props.onSubmit} title={props.translate('FILE_UPLOAD.HRNET_USER_IMPORT_MODAL_TITLE')} />}
    </React.Fragment>
  );
};

export default withLocalize<IAdminProgressTrackerListViewOwnProps>(AdminProgressTrackerListView as any);

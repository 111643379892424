import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import ExamInstanceHelperUtils from '@src/components/exam/common/ExamInstanceHelperUtils';
import ExamInstanceStatus from '@src/components/exam/common/ExamInstanceStatus';
import ExamInstanceListFilter from '@src/components/exam/examinstancelist/ExamInstanceListFilter';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { ICollectionData } from '@src/service/business/common/types';
import { IExamInstanceListFilter } from '@src/service/business/examtemplates/examInstanceBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { Button } from 'antd';
import moment from 'moment';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const timeFormat = AppConfigService.getValue('dateTimeFormat.timeWithSeconds');

// -- Prop types
// ----------

export interface ICourseCreatorExamInstanceListOwnProps {
  courseId: string;
  examInstances: ICollectionData<IExamInstance>;
  examInstanceListFilter?: IExamInstanceListFilter;
  onPageChange?: (page: number, pageSize?: number) => void;
  onFilterChange?: (filter: IExamInstanceListFilter) => void;
  additionalCols?: () => ITimunDataTableCol<IExamInstance>[];
}
type ICourseCreatorExamInstanceListProps = ICourseCreatorExamInstanceListOwnProps & IWithLocalizeOwnProps & WithRouterProps;


// -- Component
// ----------

/** Describe your component ... */
const CourseCreatorExamInstanceList: React.FC<ICourseCreatorExamInstanceListProps> = (props: ICourseCreatorExamInstanceListProps) => {
  const getExamPercentage = (totalPoints: number, totalEarnedPoints: number) => `${((totalEarnedPoints / totalPoints) * 100).toFixed()}%`;
  const renderColumns = () => {
    const columns: ITimunDataTableCol<IExamInstance>[] = [
      {
        key: 'traineeName',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.TRAINEE_LABEL'),
        content: (record: IExamInstance) => ExamInstanceHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.ASSIGNEE, record.activities[0]),
      },
      {
        key: 'submittedAt',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.SUBMITTED_AT_LABEL'),
        content: (record: IExamInstance) => record.additionalData.submittedAt ? DateUtils.toViewDateFormat(record.additionalData.submittedAt, 'dateWithTime') : '/',
      },
      {
        key: 'currentPhase',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.STATUS_LABEL'),
        content: (record: IExamInstance) => <ExamInstanceStatus activity={record.activities[0]} />,
      },
      {
        key: 'earnedPoints',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.EARNED_POINTS_LABEL'),
        content: (record: IExamInstance) => `${record.additionalData.totalEarnedPoints}/${record.additionalData.totalPoints} - ${getExamPercentage(record.additionalData.totalPoints, record.additionalData.totalEarnedPoints)}`,
      },
      {
        key: 'timeOfCompletion',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.TIME_LABEL'),
        content: (record: IExamInstance) => moment().startOf('day').milliseconds(record.additionalData.completionTimeInMs).format(timeFormat), // can't find a normal way to display time format from miliseconds
      },
      {
        key: 'action',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.ACTION_LABEL'),
        content: (record: IExamInstance) => {
          if (ExamInstanceHelperUtils.isExamInstancenInPhase(record, [ActivityPhaseEnum.EVALUATED])) {
            return <Button onClick={() => props.router.push(`/course/${props.courseId}/exam/${record.id}`)} > {props.translate('EXAM_INSTANCE.LIST_ITEM.BUTTON.VIEW_EVALUATION_LABEL')} </Button>;
          }
          return null;
        },
      },
    ];
    if (props.additionalCols) {
      columns.push(...props.additionalCols());
    }
    return columns;
  };
  return (
    <React.Fragment>
      <ExamInstanceListFilter filter={props.examInstanceListFilter} onFilterChange={props.onFilterChange} />
      <DataTable<IExamInstance>
        items={props.examInstances.content}
        paginationData={props.examInstances.page}
        columns={renderColumns()}
        scrollable={true}
        paginationConfig={{ onChange: props.onPageChange }}
        showActionMenu={true}
      />
    </React.Fragment>
  );
};

export default withRouter<ICourseCreatorExamInstanceListOwnProps>(withLocalize(CourseCreatorExamInstanceList as any));

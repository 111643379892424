import IIdRef from '@lamarodigital/quizzler-lib-frontend/model/common/IdRef';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import ExternalEducationTemplateDataTablePicker from '@src/components/common/datapicker/externalEducationTemplate/ExternalEducationTemplateDataTablePicker';
import SkillTreePicker from '@src/components/common/datapicker/SkillTreePicker';
import { getAntdV4FormErrorList, hasFormErrors, validateCheckbox } from '@src/components/common/form/validation';
import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DialogPanel from '@src/components/common/panel/DialogPanel';
import YesNoRadioButtons from '@src/components/common/radio/YesNoRadioButtons';
import UserInterfaceUtils from '@src/components/common/util/UserInterfaceUtils';
import CompanyPickerFormView from '@src/components/company/view/CompanyPickerFormView';
import { ExternalEducationTemplateHelperUtils } from '@src/components/externaleducation/common/ExternalEducationTemplateHelperUtils';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationEmploymentStatusPicker from '@src/components/externalEducationApplication/common/ExternalEducationApplicationEmploymentStatusPicker';
import ExternalEducationApplicationInfoView from '@src/components/externalEducationApplication/view/ExternalEducationApplicationInfoView';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IEnrollmentRequirement } from '@src/model/enrollmentrequirement/EnrollmentRequirement';
import { EmploymentStatusEnum, IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { IFile } from '@src/model/file/File';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { IUserInfo } from '@src/model/user/User';
import { IUserCategory } from '@src/model/user/UserCategory';
import { IFileListsByType } from '@src/service/business/files/util';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Checkbox, Col, Form, Input, RadioChangeEvent, Row, Steps, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import FormItemFileUpload from '@src/components/common/form/FormItemFileUpload';

const Step = Steps.Step;
const TextArea = Input.TextArea;
const MIN_DESCRIPTION_LENGTH: number = AppConfigService.getValue('components.externalEducationApplication.minDescriptionLength');

// -- Prop types
// ----------
interface IExternalEducationApplicationFormOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  traineeUser?: IUserInfo;
  timeline?: IExternalEducationApplicationTimelineActivity[];
  canParticipate?: boolean;
  isCurrentUserTrainee?: boolean;
  isCurrentUserCoordinator?: boolean;
  termsOfAgreement?: string;
  onCreateNote?: () => void;
  onChangePhase?: (phase: EducationApplicationActivityPhaseEnum) => void;
  onUpdate?: (data: IExternalEducationApplication) => void;
  onSubmit?: (data: IExternalEducationApplication) => void;
  onCancel?: () => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onCoordinatorAdd?: () => void;
}

type IExternalEducationApplicationFormProps = IExternalEducationApplicationFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const ExternalEducationApplicationForm = (props: IExternalEducationApplicationFormProps) => {
  const [form] = Form.useForm<IExternalEducationApplication>();
  const selectedSkill = Form.useWatch(['skill'], form);
  const selectedEducationTemplate = Form.useWatch(['externalEducationTemplate'], form);
  const selectedEmploymentStatus = Form.useWatch(['employmentStatus'], form);
  const selectedEmployerWillingToCoFinance = Form.useWatch(['employerWillingToCoFinance'], form);
  const selectedUserAllowedEmployerSurvey = Form.useWatch(['userAllowedEmployerSurvey'], form);

  const [errorFields, setErrorFields] = useState<string[] | undefined>();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [hasCoordinatorHelp, setHasCoordinatorHelp] = useState<boolean>(false);
  const initialValues = { ...props.externalEducationApplication };

  useEffect(() => {
    setHasCoordinatorHelp(props.externalEducationApplication.consultation != null);
    if (currentStep == 2) {
      getErrorFields();
    }
  }, [props.externalEducationApplication.consultation, currentStep]);

  const getErrorFields = () =>
    form
      .validateFields()
      .then((values) => setErrorFields(undefined))
      .catch((errorMsg) => {
        console.log(errorMsg);
        setErrorFields(errorMsg.errorFields);
      });

  const isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, props.externalEducationApplication.activity);
  };
  const handleSkillChange = () => {
    form.setFieldsValue({ ['externalEducationTemplate']: undefined });
  };

  const getApplicationObject = (values: Partial<IExternalEducationApplication>): IExternalEducationApplication => {
    const consultation = hasCoordinatorHelp ? values.consultation : undefined;
    return {
      ...props.externalEducationApplication,
      ...values,
      consultation,
    };
  };

  const handleUserCategorySubmit = (userCategoryIdList: IIdRef<string>[]) => {
    if (props.externalEducationApplication) {
      props.onUpdate?.({
        ...props.externalEducationApplication,
        userCategories: userCategoryIdList as IUserCategory[],
      });
    }
  };

  const handleEmpolyerWillingToCofiance = (value?: RadioChangeEvent) => {
    if (value?.target.checked) {
      form.setFieldsValue({ ['userAllowedEmployerSurvey']: value.target.checked });
    }
  };

  const handleCancelApplication = () => {
    confirmationDialog({
      onConfirm: () => props.onCancel?.(),
    });
  };

  const changeStep = (value: number) => {
    const nextStep = currentStep + value;
    setCurrentStep(nextStep);
    UserInterfaceUtils.scrollToTop();
  };

  const handleFinish = (values: IExternalEducationApplication) => props.onSubmit?.(values);
  const handleSave = () => props.onUpdate?.(form.getFieldsValue());

  return (
    <React.Fragment>
      {props.canParticipate && (
        <Row justify="end" align="middle" className="timun-wrapper__gutterBox--vertical">
          {props.isCurrentUserTrainee && (
            <Col>
              {props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_LABEL')}
              &nbsp;
              <Button block={false} onClick={handleCancelApplication} data-test-id="timun-externalEducationApplicationView__cancelApplicationButton">
                {props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_BUTTON_TEXT')}
              </Button>
            </Col>
          )}
          {props.isCurrentUserCoordinator && isApplicationInPhases(['RETURNED_TO_USER']) && (
            <Col>
              {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_LABEL')}
              &nbsp;
              <NoteCreateContainer
                disabled={!props.canParticipate}
                objectId={props.externalEducationApplication.id}
                objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
                objectPublic={true}
                buttonLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_BUTTON_TEXT')}
                modalTitle={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_MODAL_TITLE')}
                modalOkButtonLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_CONFIRM_BUTTON')}
                onCreateNote={() => props.onChangePhase?.(EducationApplicationActivityPhaseEnum.EXTERNAL_EDUCATION_APPLICATION_DENIED)}
                hideIcon={true}
              />
            </Col>
          )}
          {props.isCurrentUserCoordinator && isApplicationInPhases(['EXTERNAL_EDUCATION_APPLICATION_CREATION']) && (
            <Col>
              {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.COORDINATOR_CANCEL_USER_APPLICATION_LABEL')}
              &nbsp;
              <NoteCreateContainer
                disabled={!props.canParticipate}
                objectId={props.externalEducationApplication.id}
                objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
                objectPublic={true}
                buttonLabel={props.translate('COMMON.ACTION_CANCEL')}
                modalTitle={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.COORDINATOR_CANCEL_USER_APPLICATION_MODAL_TITLE')}
                modalCancelButtonLabel={props.translate('Zatvori')}
                modalOkButtonLabel={props.translate('COMMON.ACTION_CANCEL')}
                onCreateNote={() => props.onChangePhase?.(EducationApplicationActivityPhaseEnum.USER_CANCELED)}
                hideIcon={true}
              />
            </Col>
          )}
        </Row>
      )}
      <div className="panel">
        <Steps current={currentStep} onChange={setCurrentStep} responsive={true}>
          <Step title={props.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_ONE_TITLE')} data-test-id="timun-externalEducationApplicationForm__stepsView_1" />
          <Step title={props.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_TWO_TITLE')} data-test-id="timun-externalEducationApplicationForm__stepsView_2" />
          <Step title={props.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_THREE_TITLE')} data-test-id="timun-externalEducationApplicationForm__stepsView_3" />
        </Steps>
        <br />

        {currentStep === 2 && hasFormErrors(errorFields) && (
          <DialogPanel className="timun-externalEducationApplication__errorsPanel">
            {getAntdV4FormErrorList(errorFields).map((error, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <LemonIcon name="error" />
                    &nbsp;
                    {error}
                  </Col>
                </Row>
              );
            })}
          </DialogPanel>
        )}

        <Form<IExternalEducationApplication> form={form} initialValues={initialValues} onFinish={handleFinish} layout="vertical">
          {/* ---------- Hidden fields ---------- */}
          <Form.Item noStyle={true} hidden={true} name={'id'}>
            <Input />
          </Form.Item>

          {/* ---------- Step 1 ---------- */}
          <DialogPanel title={props.translate('EXTERNAL_EDUCATION_APPLICATION.STATEMENT_ACCEPTED_PANEL_TITLE')} className={currentStep === 0 ? '' : 'hidden'}>
            <Form.Item name={'statementAccepted'} valuePropName={'checked'} rules={[{ validator: (rule, value, callback) => validateCheckbox(rule, value, callback, props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.STATEMENT_ACCEPTED')) }]}>
              <Checkbox defaultChecked={false}>
                <Typography.Paragraph strong={true}> {props.translate('EXTERNAL_EDUCATION_APPLICATION.STATEMENT_ACCEPTED_LABEL')} </Typography.Paragraph>
              </Checkbox>
            </Form.Item>
          </DialogPanel>

          <DialogPanel title={props.translate('EXTERNAL_EDUCATION_APPLICATION.SKILL_PANEL_TITLE')} className={currentStep === 0 ? '' : 'hidden'}>
            <GridItem label={props.translate('EXTERNAL_EDUCATION_APPLICATION.SKILL_LABEL')} infoText={props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.SKILL_INFO')}>
              <Form.Item
                name={'skill'}
                required={true}
                rules={[
                  {
                    required: true,
                    message: props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.SKILL_DATA'),
                    validator: async (rule, value) => {
                      if (value.id == null) {
                        throw new Error(props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.SKILL_DATA'));
                      }
                    },
                  },
                ]}
              >
                <SkillTreePicker onChange={handleSkillChange} />
              </Form.Item>
            </GridItem>
          </DialogPanel>

          <DialogPanel title={props.translate('EXTERNAL_EDUCATION_APPLICATION.EDUCATION_PANEL_TITLE')} className={currentStep === 0 ? '' : 'hidden'}>
            {selectedSkill ? (
              <GridItem label={props.translate('EXTERNAL_EDUCATION_APPLICATION.EDUCATION_LABEL')}>
                <Form.Item
                  name={'externalEducationTemplate'}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.EDUCATION_DATA'),
                      validator: async (rule, value) => {
                        if (value.id == null) {
                          throw new Error(props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.EDUCATION_DATA'));
                        }
                      },
                    },
                  ]}
                >
                  <ExternalEducationTemplateDataTablePicker skill={selectedSkill} />
                </Form.Item>
              </GridItem>
            ) : (
              props.translate('EXTERNAL_EDUCATION_APPLICATION.EDUCATION_CONDITION_INFO_MESSAGE')
            )}
          </DialogPanel>

          {selectedEducationTemplate && props.traineeUser && (
            <DialogPanel title={props.translate('EXTERNAL_EDUCATION_APPLICATION.OFFER_PANEL_TITLE')} className={currentStep === 0 ? '' : 'hidden'}>
              <Row gutter={4}>
                <Col>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EXTERNAL_EDUCATION_TEMPLATE_SELECTION_TEXT')}</Col>
                <Col>
                  <a href={ExternalEducationTemplateHelperUtils.getEmailTemplate(selectedEducationTemplate, props.traineeUser)}>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EXTERNAL_EDUCATION_TEMPLATE_SELECTION_LABEL').toUpperCase()}</a>
                </Col>
              </Row>
              &nbsp;
              <FormItemFileUpload
                fileType={'OFFER'}
                userFiles={props.applicationFiles}
                label={props.translate('FILES.TYPE_ENUM.LABEL.OFFER')}
                required={true}
                onFileUpload={props.onFileUpload}
                onFileRemove={props.onFileRemove}
                canRemoveFile={true}
                requiredMessage={props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_OFFER')}
                infoText={props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.OFFER_INFO_LABEL')}
              />
            </DialogPanel>
          )}

          <DialogPanel title={props.translate('EXTERNAL_EDUCATION_APPLICATION.ADDITIONAL_INFO_PANEL_TITLE')} className={currentStep === 0 ? '' : 'hidden'}>
            <Form.Item name={'educationRelated'} required={true} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.EDUCATION_RELATED_LABEL')}>
              <YesNoRadioButtons name={'educationRelated'} />
            </Form.Item>

            <Form.Item name={'jobRelated'} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.JOB_RELATED_LABEL')}>
              <YesNoRadioButtons name={'jobRelated'} />
            </Form.Item>

            <Form.Item name={'interestRelated'} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.INTEREST_RELATED_LABEL')}>
              <YesNoRadioButtons name={'interestRelated'} />
            </Form.Item>

            <Form.Item
              name={'description'}
              label={props.translate('EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DESCRIPTION_LABEL')}
              rules={[
                { required: true, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_REQUIRED') },
                { min: MIN_DESCRIPTION_LENGTH, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_LENGTH') },
              ]}
            >
              <TextArea data-test-id="timun-externalEducationApplicationForm__description" autoSize={{ minRows: 2, maxRows: 6 }} placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_LENGTH')} />
            </Form.Item>
          </DialogPanel>

          {/* ---------- Step 2 ---------- */}

          <DialogPanel title={props.translate('EXTERNAL_EDUCATION_APPLICATION.ABOUT_ME_PANEL_TITLE')} className={currentStep === 1 ? '' : 'hidden'}>
            <Form.Item name={['employmentStatus']} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.EMPLOYMENT_STATUS_LABEL')} rules={[{ required: true, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.EMPLOYMENT_STATUS') }]}>
              <ExternalEducationApplicationEmploymentStatusPicker placeholder={props.translate('COMMON.LABEL.SELECT')} />
            </Form.Item>

            <FormItemFileUpload
              fileType={'CURRICULUM_VITAE'}
              userFiles={props.applicationFiles}
              label={props.translate('FILES.TYPE_ENUM.LABEL.CURRICULUM_VITAE')}
              required={true}
              onFileUpload={props.onFileUpload}
              onFileRemove={props.onFileRemove}
              canRemoveFile={true}
              requiredMessage={props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_CURRICULUM_VITAE')}
              infoText={props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CURRICULUM_VITAE_INFO')}
            />

            <GridItem label={props.translate('EXTERNAL_EDUCATION_APPLICATION.ENROLLMENT_REQUIREMENT_LABEL')}>
              {selectedEducationTemplate?.enrollmentRequirements?.map((requirement: IEnrollmentRequirement) => (
                <Tag key={requirement.id}> {requirement.name} </Tag>
              ))}
            </GridItem>

            <FormItemFileUpload
              fileType={'ENROLLMENT_REQUIREMENT'}
              userFiles={props.applicationFiles}
              label={props.translate('FILES.TYPE_ENUM.LABEL.ENROLLMENT_REQUIREMENT')}
              onFileUpload={props.onFileUpload}
              onFileRemove={props.onFileRemove}
              canRemoveFile={true}
              infoText={props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.ENROLLMENT_REQUIREMENT_INFO')}
            />
          </DialogPanel>

          <DialogPanel title={props.translate('EXTERNAL_EDUCATION_APPLICATION.COFINANCE_PANEL_TITLE')} className={currentStep === 1 ? '' : 'hidden'}>
            <Form.Item name={'userWillingToCoFinance'} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.USER_COFINANCE_LABEL')}>
              <YesNoRadioButtons name={'userWillingToCoFinance'} />
            </Form.Item>

            {selectedEmploymentStatus?.id === EmploymentStatusEnum.EMPLOYED && (
              <Form.Item name={['employerWillingToCoFinance']} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.EMPLOYER_COFINANCE_LABEL')}>
                <YesNoRadioButtons name={'employerWillingToCoFinance'} onChange={handleEmpolyerWillingToCofiance} />
              </Form.Item>
            )}
          </DialogPanel>

          {selectedEmploymentStatus?.id === EmploymentStatusEnum.EMPLOYED && (
            <DialogPanel title={props.translate('EXTERNAL_EDUCATION_APPLICATION.EMPLOYER_INFO_PANEL_TITLE')} className={currentStep === 1 ? '' : 'hidden'}>
              <Form.Item name={'userAllowedEmployerSurvey'} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.USER_ALLOWED_EMPLOYER_SURVEY_LABEL')}>
                <YesNoRadioButtons name={'userAllowedEmployerSurvey'} disabled={selectedEmployerWillingToCoFinance} />
              </Form.Item>
              {
                <Form.Item shouldUpdate={(prevValues, nextValues) => nextValues.userAllowedEmployerSurvey || nextValues.employerWillingToCoFinance}>
                  {() => {
                    return selectedUserAllowedEmployerSurvey || selectedEmployerWillingToCoFinance ? (
                      <DialogPanel title={props.translate('EXTERNAL_EDUCATION_APPLICATION.EMPLOYER_FORM_PANEL_TITLE')} className={currentStep === 1 ? '' : 'hidden'}>
                        <Form.Item valuePropName={'company'} name={'employerInformation'} rules={[{ required: selectedUserAllowedEmployerSurvey || selectedEmployerWillingToCoFinance, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.EMPLOYER_INFORMATION') }]}>
                          <CompanyPickerFormView />
                        </Form.Item>
                      </DialogPanel>
                    ) : null;
                  }}
                </Form.Item>
              }
            </DialogPanel>
          )}
        </Form>

        {/* ---------- Step 3 ---------- */}

        {currentStep === 2 && (
          <ExternalEducationApplicationInfoView
            panelsActive={true}
            externalEducationApplication={getApplicationObject(form.getFieldsValue())}
            applicationFiles={props.applicationFiles}
            traineeUser={props.traineeUser}
            isCurrentUserCoordinator={props.isCurrentUserCoordinator}
            isCurrentUserTrainee={props.isCurrentUserTrainee}
            onCreateNote={props.onCreateNote}
            timeline={props.timeline}
            canParticipate={props.canParticipate}
            onUserCategorySubmit={handleUserCategorySubmit}
            onFileUpload={props.onFileUpload}
            canFileRemove={true}
            onFileRemove={props.onFileRemove}
          />
        )}

        <Row justify="space-between" gutter={[8, 8]}>
          <Col>
            {currentStep > 0 && (
              <Button key="prev" onClick={() => changeStep(-1)} data-test-id="timun-externalEducationApplicationForm__prevButton">
                {props.translate('EXTERNAL_EDUCATION_APPLICATION.BUTTON_PREV_LABEL')}
              </Button>
            )}
          </Col>
          <Col>
            <Row gutter={[8, 8]}>
              {props.canParticipate && (
                <Col>
                  <Button key="save" onClick={handleSave} data-test-id="timun-externalEducationApplicationForm__saveButton">
                    {props.translate('EXTERNAL_EDUCATION_APPLICATION.BUTTON_SAVE_LABEL')}
                  </Button>
                </Col>
              )}
              {currentStep < 2 && (
                <Col>
                  <Button type="primary" key="next" onClick={() => changeStep(1)} data-test-id="timun-externalEducationApplicationForm__nextButton">
                    {props.translate('EXTERNAL_EDUCATION_APPLICATION.BUTTON_NEXT_LABEL')}
                  </Button>
                </Col>
              )}
              {currentStep === 2 && props.isCurrentUserTrainee && (
                <Col>
                  <Button type="primary" key="submit" disabled={hasFormErrors(errorFields)} onClick={form.submit} data-test-id="timun-externalEducationApplicationForm__submitButton">
                    {props.translate('EXTERNAL_EDUCATION_APPLICATION.BUTTON_SUBMIT_LABEL')}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IExternalEducationApplicationFormOwnProps>(ExternalEducationApplicationForm as any);

import { Form } from '@ant-design/compatible';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import UserGroupTabsView from '@src/components/usergroup/view/UserGroupTabsView';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { UserGroupMemberRoleEnum } from '@src/model/usergroup/UserGroupMemberRole';

import { Button, Col, Layout, Row, Typography } from 'antd';
import React, { useMemo } from 'react';

// -- Const
// ----------

const { Header, Content } = Layout;

// -- Prop types
// ----------

export interface IUserGroupViewOwnProps {
  returnRoute?: string;
  userGroup: IUserGroup;
  onUserGroupLeave?: () => void;
  onUserGroupJoin?: () => void;
}

type IUserGroupViewProps = IUserGroupViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

const UserGroupView = (props: IUserGroupViewProps) => {
  const isUserAdmin = useMemo(() => props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]), [props.allowedRoles]);
  const isMemberPending = useMemo(() => props.userGroup.currentUserMember?.role.id === UserGroupMemberRoleEnum.PENDING_MEMBER, [props.userGroup.currentUserMember]);
  const showGroupData = useMemo(() => (props.userGroup.currentUserMember != null && !isMemberPending) || isUserAdmin, [props.userGroup.currentUserMember, isMemberPending, isUserAdmin]);

  return (
    <Layout>
      <Header className="timun-userGroup__header">
        <Row justify="space-between">
          <Col>
            <HeaderTitle link={props.returnRoute} title={props.userGroup.name} icon={<LemonIcon name="left" />} />
          </Col>
          <Col>
            {props.userGroup.currentUserMember ? ( // TODO: join/leave same as organization?
              <Button onClick={props.onUserGroupLeave}>{props.translate('USER_GROUP.LEAVE_BUTTON_LABEL')}</Button>
            ) : (
              <Button type="primary" onClick={props.onUserGroupJoin}>
                {props.translate('USER_GROUP.JOIN_BUTTON_LABEL')}
              </Button>
            )}
          </Col>
        </Row>
      </Header>
      <Layout>
        <Content className="timun-userGroup__content">
          <Form layout="inline" className="panel timun-userGroup__form">
            <Form.Item className="ant-form-text" label={props.translate('USER_GROUP_VIEW.DESCRIPTION_LABEL')}>
              <Typography.Paragraph> {props.userGroup.description}</Typography.Paragraph>
            </Form.Item>
            {props.userGroup.latestCode?.value && ( // TODO: use view panel from organization join code?
              <Form.Item className="ant-form-text" label={props.translate('USER_GROUP_VIEW.ACCESS_CODE_LABEL')}>
                <Typography.Paragraph> {props.userGroup.latestCode.value}</Typography.Paragraph>
              </Form.Item>
            )}
          </Form>
          <br />

          {showGroupData && (
            <React.Fragment>
              <UserGroupTabsView userGroup={props.userGroup} />
            </React.Fragment>
          )}
          {isMemberPending && (
            <Row justify="center" className="timun-wrapper__gutterBox--vertical">
              <Col>{props.translate('USER_GROUP_VIEW.PENDING_MEMBER_INFO_TEXT')}</Col>
            </Row>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IUserGroupViewOwnProps>(UserGroupView as any));

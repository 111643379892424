import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import FileUpload, { IUploadedFile, IUploadStatus } from '@src/components/common/upload/FileUpload';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import { IFile } from '@src/model/file/File';
import AppConfigService from '@src/service/common/AppConfigService';
import { Spin } from 'antd';
import React, { useCallback } from 'react';

const ALLOWED_FILE_TYPES: string[] = AppConfigService.getValue('api.upload.allowedListTypes');
const ALLOWED_MAX_SIZE: number = AppConfigService.getValue('api.upload.maxFileSize');

const checkFile = (file: IUploadedFile) => FileUtils.checkFileValidity(file, ALLOWED_FILE_TYPES, ALLOWED_MAX_SIZE);

// -- Prop types
// ----------

export interface IUserListUploadFormOwnProps {
  submitAfterUpload?: boolean;

  onUploadedFileChange?: (file: IFile) => void;
  onSubmit?: (file: IFile) => void;
  onClose?: () => void;
}

type IUserListUploadFormProps = IUserListUploadFormOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

/** Component with a modal form for uploading user list to import */
const UserListUploadForm = (props: IUserListUploadFormProps) => {
  const handleUploadFile = (data: IFile) => {
    if (props.submitAfterUpload) {
      props.onSubmit?.(data);
    } else {
      props.onUploadedFileChange?.(data);
    }
  };

  const customRequest = useCallback((componentsData) => {
    // TODO: should we check if this really is File or smtng else?
    const file = componentsData.file as File;
    const fileName = file.name;

    // file upload
    FileUploadHelper.uploadFile(file, fileName, componentsData.onProgress).subscribe({
      next: componentsData.onSuccess,
      error: componentsData.onError,
    });
  }, []);

  return (
    <React.Fragment>
      <FileUpload onUploadFile={handleUploadFile} dragger={true} uploadProps={{ customRequest, multiple: false, beforeUpload: checkFile, showUploadList: true }}>
        {(status: IUploadStatus) => (
          <React.Fragment>
            {status.isUploading && <Spin />}&nbsp;
            <LemonIcon name="plus" />
            <br />
            {props.translate('FILE_UPLOAD.DROPZONE_TEXT')}
          </React.Fragment>
        )}
      </FileUpload>
    </React.Fragment>
  );
};

export default withLocalize<IUserListUploadFormOwnProps>(withPathResolver(UserListUploadForm) as any);

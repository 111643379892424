import { QuizDisplay } from '@lamarodigital/quizzler-lib-frontend';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { QuizHelperUtils } from '@src/components/exam/quiz/QuizHelperUtils';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';
import { Button, Divider, Layout, Typography } from 'antd';
import React from 'react';

// -- Const
// ----------
const { Header, Content, Footer } = Layout;

// -- Prop types
// ----------
export interface IPublicSurveyInstanceViewOwnProps {
  surveyInstance?: ISurveyInstance;
  showEvaluationFeedback: boolean;
  locale: string;
  onSubmit?: (surveyInstance: ISurveyInstance) => void;
}

type IPublicSurveyInstanceViewProps = IPublicSurveyInstanceViewOwnProps & IWithLocalizeOwnProps;

interface IPublicSurveyInstanceViewState {
  surveyInstance?: ISurveyInstance;
}

// -- Component
// ----------
class PublicSurveyInstanceView extends React.Component<IPublicSurveyInstanceViewProps, IPublicSurveyInstanceViewState> {
  state: IPublicSurveyInstanceViewState = {
    surveyInstance: undefined,
  };

  componentDidMount() {
    // mount
    if (this.props.surveyInstance) {
      this.setState({ surveyInstance: this.props.surveyInstance });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Layout className="panel">
          {this.state.surveyInstance && (
            <Header>
              <Typography.Title> {this.props.translate(`SURVEY_INSTANCE.SURVEY_TYPE_TITLE.${this.state.surveyInstance.type.name}`)} </Typography.Title>
              <Divider />
            </Header>
          )}
          <Layout>
            <Content className="timun-surveyInstanceView__introContent">
              {this.state.surveyInstance?.generatedIntro && (
                <Typography.Paragraph>
                  <div dangerouslySetInnerHTML={{ __html: this.state.surveyInstance?.generatedIntro }} />
                </Typography.Paragraph>
              )}
              {this.state.surveyInstance?.instructions && (
                <Typography.Paragraph>
                  <div dangerouslySetInnerHTML={{ __html: this.state.surveyInstance?.instructions }} />
                </Typography.Paragraph>
              )}
            </Content>
            <Divider />
            <Content className="timun-surveyInstanceView__quizContent">
              <QuizDisplay locale={this.props.locale} resolveFileUrl={QuizHelperUtils.resolveFileUrl} showHelpIntroActionButton={false} showRedoIntroActionButton={true} showEvaluationFeedback={this.props.showEvaluationFeedback} onChange={this.handleQuizValuesChange} quiz={this.state.surveyInstance?.collection} />
            </Content>
          </Layout>
          <Divider />
          <Footer>{this.props.onSubmit && <Button onClick={this.handleSubmit}>{this.props.translate('SURVEY_INSTANCE.BUTTON_SUBMIT_LABEL')}</Button>}</Footer>
        </Layout>
      </React.Fragment>
    );
  }

  handleSubmit = () => {
    if (this.props.onSubmit && this.state.surveyInstance) {
      this.props.onSubmit(this.state.surveyInstance);
    }
  };

  handleQuizValuesChange = (quiz: IQuiz) => {
    const updatedSurveyInstance = { ...this.props.surveyInstance, collection: quiz } as ISurveyInstance;
    this.setState({ surveyInstance: updatedSurveyInstance });
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IPublicSurveyInstanceViewOwnProps>(PublicSurveyInstanceView as any);

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdminUserManagementListFilter from '@src/components/user/filter/AdminUserManagementListFilter';
import UserManagementListView from '@src/components/user/view/UserManagementListView';
import { Button, Col, Divider, Row, Typography } from 'antd';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData } from '@src/service/business/common/types';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import LemonIcon from '@src/components/common/image/LemonIcon';

// -- Prop types
// ----------

export interface ICourseUserListViewOwnProps {
  filter?: IUserListFilter;
  userList: ICollectionData<IUserInfo>;
  workPositionList?: IWorkPosition[];
  onFilterSubmit?: (filter: IUserListFilter) => void;
  onPageChange?: (page: number, pageSize?: number) => void;
  onSelecteUserSubmit?: (data: IUserInfo[]) => void;
  onAllUsersEnroll?: () => void;
}
type ICourseUserListViewProps = ICourseUserListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const CourseUserListView: React.FC<ICourseUserListViewProps> = (props: ICourseUserListViewProps) => {
  const handleAllUsersEnroll = () =>
    confirmationDialog({
      onConfirm: () => props.onAllUsersEnroll?.(),
      title: props.translate('COMMON.CONFIRMATION_TITLE'),
    });

  return (
    <React.Fragment>
      <Row justify="end">
        <Col>
          <Button type="primary" icon={<LemonIcon name="verification" />} onClick={handleAllUsersEnroll}>
            {props.translate('USER_VIEW_CONTAINER.ENROLL_ALL_USERS')}
          </Button>
        </Col>
      </Row>
      <Divider />
      <Typography.Paragraph>{props.translate('COURSE_USER_LIST.VIEW.INFO_TEXT_LABEL')}</Typography.Paragraph>
      {/* Filter */}
      <AdminUserManagementListFilter onFilterSubmit={props.onFilterSubmit} userListFilter={props.filter} workPositionList={props.workPositionList} />
      {/* List */}
      <UserManagementListView canEnrollUserCourse={true} onSelectedUserSubmit={props.onSelecteUserSubmit} selectableTable={true} onPageChange={props.onPageChange} userList={props.userList} />
    </React.Fragment>
  );
};

export default withLocalize<ICourseUserListViewOwnProps>(CourseUserListView as any);

import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import { IExternalEducationApplicationBaseInfo } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import OrganizationEducationApplicationListView from '@src/components/organization/list/OrganizationEducationApplicationListView';
import OrganizationBusinessStore, { IOrganizationEducationApplicationRefListFilter } from '@src/service/business/organization/OrganizationBusinessStore';
import ExternalEducationApplicationBusinessStore from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface IOrganizationEducationApplicationListContainerOwnProps {
  organizationId: string;
}
export interface IOrganizationEducationApplicationListContainerStateProps {
  externalEducationApplicationRefList: ICollectionData<IExternalEducationApplicationBaseInfo>;
}
export interface IOrganizationEducationApplicationListContainerDispatchProps {
  fetchExternalEducationApplicationRefList: (params: ICollectionFetchPayload<IOrganizationEducationApplicationRefListFilter>) => void;
  updateExternalEducationApplicationRequestPaymentInfo: (data: IExternalEducationApplicationBaseInfo) => ITrackableAction;
}
type IOrganizationEducationApplicationListContainerProps = IOrganizationEducationApplicationListContainerOwnProps & IOrganizationEducationApplicationListContainerStateProps & IOrganizationEducationApplicationListContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const OrganizationEducationApplicationListContainer = (props: IOrganizationEducationApplicationListContainerProps) => {
  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IOrganizationEducationApplicationRefListFilter>(
    {
      updateFn: props.fetchExternalEducationApplicationRefList,
    },
    [props.organizationId]
  );

  const handlePaymentRequest = (data: IExternalEducationApplicationBaseInfo) =>
    props
      .updateExternalEducationApplicationRequestPaymentInfo(data)
      .track()
      .subscribe(
        // success
        () => onUpdateList()
      );
  return (
    <React.Fragment>
      {props.externalEducationApplicationRefList && (
        <OrganizationEducationApplicationListView
          externalEducationApplicationRefList={props.externalEducationApplicationRefList}
          filter={collectionParams.filter}
          onFilterUpdate={updateCollectionParams.onFilterUpdate}
          onFilterChange={updateCollectionParams.onFilterSet}
          onPageChange={updateCollectionParams.onPageChange}
          onPaymentRequest={handlePaymentRequest}
          organizationId={props.organizationId}
        />
      )}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IOrganizationEducationApplicationListContainerOwnProps): IOrganizationEducationApplicationListContainerStateProps => ({
  externalEducationApplicationRefList: OrganizationBusinessStore.selectors.getOrganizationEducationApplicationRefs(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IOrganizationEducationApplicationListContainerOwnProps): IOrganizationEducationApplicationListContainerDispatchProps => ({
  fetchExternalEducationApplicationRefList: (params: ICollectionFetchPayload<IOrganizationEducationApplicationRefListFilter>) => dispatch(OrganizationBusinessStore.actions.fetchOrganizationEducationApplicationRefList(ownProps.organizationId, params)),
  updateExternalEducationApplicationRequestPaymentInfo: (data: IExternalEducationApplicationBaseInfo) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.updateExternalEducationApplicationRequestPaymentInfo(data))),
});

export default connect<IOrganizationEducationApplicationListContainerStateProps, IOrganizationEducationApplicationListContainerDispatchProps, IOrganizationEducationApplicationListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(OrganizationEducationApplicationListContainer as any));

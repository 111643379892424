import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { hasFormErrors } from '@src/components/common/form/validation';
import IIdTitleRef from '@src/model/common/IdTitleRef';
import { CourseContentTypeEnum } from '@src/model/course/ContentType';
import { CourseCompletionStatusEnum, CourseStatusEnum, ICourse, ICourseCompletionStatus, ICourseProgress } from '@src/model/course/Course';
import { ICourseExam } from '@src/model/course/CourseExam';
import { IShareableContentObject } from '@src/model/course/scorm/ShareableContentObject';
import AppConfigService from '@src/service/common/AppConfigService';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { getCurrentUser } from '@src/service/business/login/loginBusinessService';

const COURSE_ABOUT_ROUTE = AppConfigService.getValue('components.courses.courseAboutRouterProp');
const COURSE_QUIZ_ROUTE = AppConfigService.getValue('components.courses.courseQuizRouterProp');
const COURSE_NOTES_ROUTE = AppConfigService.getValue('components.courses.courseNotesRouterProp');
const COURSE_EXAM_ROUTE = AppConfigService.getValue('components.courses.courseExamRouterProp');
const COURSE_CERTIFICATE_ROUTE = AppConfigService.getValue('components.courses.courseCertificateRouterProp');

export default class CourseHelperUtils {
  /** Check if course is in one of given statuses */
  static isCourseInStatus = (course: ICourse, requestedStatuses: CourseStatusEnum[]): boolean => {
    return requestedStatuses.find((status) => course && course.status.id === status) != null;
  };

  /** Check if course progress is in one of given statuses */
  static isCourseProgressInStatus = (requestedStatuses: CourseCompletionStatusEnum[], courseCompletionStatus?: ICourseCompletionStatus): boolean => {
    return requestedStatuses.find((status) => courseCompletionStatus?.id === status) != null;
  };

  /** Check if opened item is about course content */
  static isAboutCourseContent = (lectureId: string): boolean => {
    return lectureId === COURSE_ABOUT_ROUTE;
  };

  /** Check if opened item is course final quiz */
  static isCourseQuizContent = (lectureId: string): boolean => {
    return lectureId === COURSE_QUIZ_ROUTE;
  };

  /** Check if opened item is course note content */
  static isNotesCourseContent = (lectureId: string): boolean => {
    return lectureId === COURSE_NOTES_ROUTE;
  };

  /** Check if opened item is course exam content */
  static isExamCourseContent = (lectureId: string): boolean => {
    return lectureId === COURSE_EXAM_ROUTE;
  };

  /** Check if opened item is a lecture */
  static isLecture = (lectureId: string): boolean => {
    return !(CourseHelperUtils.isAboutCourseContent(lectureId) || CourseHelperUtils.isNotesCourseContent(lectureId) || CourseHelperUtils.isCourseQuizContent(lectureId) || CourseHelperUtils.isExamCourseContent(lectureId) || CourseHelperUtils.isCertificateCourseContent(lectureId));
  };

  /** Check if opened item is a course certificate  */
  static isCertificateCourseContent = (lectureId: string): boolean => {
    return lectureId === COURSE_CERTIFICATE_ROUTE;
  };

  /** Check if course has exam instance defined */
  static hasExamInstance = (quiz?: ICourseExam): boolean => {
    return quiz?.examInstance != null;
  };

  /** Check if course has exam template defined */
  static hasExamTemplate = (course: ICourse): boolean => {
    return course.exam?.examTemplate != null;
  };

  // exam is null if it was never defined, but once defined and deleted after, exam object exists with body value ''
  static hasExam = (exam: ICourseExam): boolean => {
    return exam != null;
  };

  // go through all form fields and get errors, return if there are any
  static hasFormErrors = (form: WrappedFormUtils): boolean => {
    const fieldsError: any = form.getFieldsError();
    return hasFormErrors(fieldsError);
  };

  // if user has changed some values and there are no errors, save button can be enabled
  static isReadyToSubmit = (form: WrappedFormUtils): boolean => {
    return form.isFieldsTouched() && !CourseHelperUtils.hasFormErrors(form);
  };

  // if current user has created course
  static isCurrentUserCourseCreator = (course: ICourse): boolean => {
    return course && course.createdBy?.id === getCurrentUser().id;
  };

  static isCourseInProgress = (progress?: ICourseProgress) => {
    return progress != null && (progress.completedLessonsNumber !== 0 || progress.scormProgress != null) && progress.completedLessonsNumber !== progress.lessonsNumber;
  };

  static getCurrentLecture = (course: ICourse): IIdTitleRef | undefined => {
    const hasAboutLecture = CourseHelperUtils.isLectureCourse(course);
    let currentLecture: string | undefined;
    let currentLectureTitle: string | undefined;

    const isCourseInProgress = CourseHelperUtils.isCourseInProgress(course.progress);

    if (course.progress != null && isCourseInProgress) {
      if (CourseHelperUtils.isLectureCourse(course)) {
        currentLecture = course.progress.currentLecture.id;
        currentLectureTitle = course.progress.currentLecture.title;
      } else {
        currentLecture = course.progress.scormProgress?.currentShareableContentObject.id;
        currentLectureTitle = course.progress.scormProgress?.currentShareableContentObject.title;
      }
    } else if (hasAboutLecture) {
      currentLecture = COURSE_ABOUT_ROUTE;
      currentLectureTitle = LocalizeService.translate('COURSE_VIEW.LECTURE_LIST_MENU.COURSE_ABOUT_ITEM_LABEL');
    }

    return currentLecture && currentLectureTitle ? { id: currentLecture, title: currentLectureTitle } : undefined;
  };

  static getTraineePath = (course: ICourse, lecture?: string): string => {
    const idPath = `${course.id}/${lecture ?? ''}`;
    if (CourseHelperUtils.isLectureCourse(course)) {
      return UrlBuilderFactory.buildAppUrl(`/course/${idPath}`);
    } else if (CourseHelperUtils.isScormCourse(course)) {
      return UrlBuilderFactory.buildAppUrl(`/scorm/${idPath}`);
    }

    console.warn('unknown course type');
    return '';
  };

  static getAdminPath = (course: ICourse, lecture?: string): string => {
    const idPath = `${course.id}/${lecture ?? 'about'}`;
    if (CourseHelperUtils.isLectureCourse(course)) {
      return UrlBuilderFactory.buildAppUrl(`/course/administration/${idPath}`);
    } else if (CourseHelperUtils.isScormCourse(course)) {
      return UrlBuilderFactory.buildAppUrl(`/scorm/administration/${idPath}`);
    }

    console.warn('unknown course type');
    return '';
  };

  static isScormCourse = (course: ICourse): boolean => {
    return course.courseContentType.id === CourseContentTypeEnum.SCORM;
  };

  static isLectureCourse = (course: ICourse): boolean => {
    return course.courseContentType.id === CourseContentTypeEnum.LECTURE;
  };

  static isSingleFileScormCourse = (lectures: IShareableContentObject[]) => {
    return lectures.length === 1 && (lectures[0].children == null || lectures[0].children.length === 0);
  };

  static findLecture = (lectureList: IShareableContentObject[], id: string): IShareableContentObject | undefined => {
    for (const lecture of lectureList) {
      if (lecture.id === id) {
        return lecture;
      }

      if (lecture.children) {
        const result = CourseHelperUtils.findLecture(lecture.children, id);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  static findLectureParent = (lectureList: IShareableContentObject[], lectureId: string, currentParent?: IShareableContentObject): IShareableContentObject | undefined => {
    for (const lecture of lectureList) {
      if (lecture.id === lectureId) {
        return currentParent;
      }
      if (lecture.children) {
        const result = CourseHelperUtils.findLectureParent(lecture.children, lectureId, lecture);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  static findLectureWithFile = (lectureList: IShareableContentObject[]): IShareableContentObject | undefined => {
    for (const lecture of lectureList) {
      if (lecture.filePath != null) {
        return lecture;
      }

      if (lecture.children) {
        const result = CourseHelperUtils.findLectureWithFile(lecture.children);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  static sortbyOrdering = <T extends { ordering: number }>(list: T[]): T[] => {
    return [...list].sort((a, b) => a.ordering - b.ordering);
  };
}

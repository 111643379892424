import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IReport, IReportStatusEnum } from '@src/model/report/Report';
import AppConfigService from '@src/service/common/AppConfigService';
import { Empty, Row, Spin, Table, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router';

// -- Const
// ----------
const Column = Table.Column;

const dateTimeFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');
const TABLE_SCROLL_SIZE = AppConfigService.getValue('layout.table.scrollSize');

// -- Prop types
// ----------
export interface IReportListOwnProps {
  reportList: IReport[];
  onDelete: (data: IReport) => void;
  onReload: () => void;
}

type IReportListProps = IReportListOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

const ReportList: React.FC<IReportListProps> = (props: IReportListProps) => {
  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={props.reportList}
      bordered={true}
      locale={{
        emptyText: <Empty description={props.translate('REPORTS_LIST.TABLE_EMPTY')} />,
      }}
      pagination={false}
      scroll={{ x: TABLE_SCROLL_SIZE }}
    >
      <Column
        key="name"
        width="40%"
        title={props.translate('REPORTS_LIST.TABLE_FILENAME_LABEL')}
        render={(record: IReport) => (
          <React.Fragment>
            {record.status.id === IReportStatusEnum.DONE && record.fileResource.name}
            {record.status.id === IReportStatusEnum.IN_CREATION && props.translate('REPORTS_LIST.TABLE_UNFINISHED_REPORT')}
            {record.status.id === IReportStatusEnum.FAILED && props.translate('REPORTS_LIST.TABLE_FAILED_REPORT')}
          </React.Fragment>
        )}
      />

      <Column key="type" width="20%" title={props.translate('REPORTS_LIST.TABLE_TYPE_LABEL')} align="center" render={(record: IReport) => props.translate(`REPORTS_LIST.TYPE_${record.type.name}`)} />

      <Column key="requestedBy" width="15%" title={props.translate('REPORTS_LIST.TABLE_REQUESTED_LABEL')} align="center" render={(record: IReport) => <UserNameView user={record.requestedBy} fallback={props.translate('USER_VIEW.USER_DELETED_LABEL')} />} />

      <Column key="datetime" width="16%" title={props.translate('REPORTS_LIST.TABLE_DATETIME_LABEL')} align="center" render={(record: IReport) => moment(record.requestedDateTime).format(dateTimeFormat)} />

      <Column
        key="actions"
        width="9%"
        title={
          <Tooltip overlay={props.translate('REPORTS_LIST.RELOAD_BUTTON_TOOLTIP')}>
            <a onClick={() => props.onReload()}>
              <LemonIcon name="redo" />
            </a>
          </Tooltip>
        }
        align="center"
        render={(record: IReport) => (
          <Row justify="space-between">
            {record.status.id === IReportStatusEnum.DONE && (
              <Link to={props.resolveApiFilePath(record.fileResource.id)} download={true} target="_self">
                <Tooltip title={props.translate('COMMON.ACTION_DOWNLOAD')}>
                  <LemonIcon name="download" />
                </Tooltip>
              </Link>
            )}

            {record.status.id === IReportStatusEnum.IN_CREATION && <Spin indicator={<LemonIcon name="spinner" spin={true} />} />}

            {record.status.id === IReportStatusEnum.FAILED && <Spin indicator={<LemonIcon name="times" />} />}

            <Link to="">
              <DeleteElementButton<IReport> item={record} titlePropPath={'fileResource.name'} icon={<LemonIcon name="delete" />} onDelete={props.onDelete} iconOnly={true} />
            </Link>
          </Row>
        )}
      />
    </Table>
  );
};

// -- HOCs and exports
// ----------

export default withPathResolver(withLocalize<IReportListOwnProps>(ReportList as any));

import { Col, Menu, Row } from 'antd';
import React, { useMemo } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { classNames } from '@src/components/common/util/classNames';
import { LABELS, MESSAGES } from '@src/service/locale/message';
// tslint:disable-next-line:no-submodule-imports
import { MenuInfo } from 'rc-menu/lib/interface';
import LemonIcon from '@src/components/common/image/LemonIcon';

// -- Prop types
// ----------
export interface ILocalePickerOwnProps {
  onLocaleChange: (menuIfo: MenuInfo) => void;
  locale: string;
  standalone?: boolean;
}

export interface ILocalePickerStateProps { }

export interface ILocalePickerDispatchProps { }
type ILocalePickerProps = ILocalePickerOwnProps & ILocalePickerStateProps & ILocalePickerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Menu component for picking app locale */
const LocalePicker: React.FC<ILocalePickerProps> = (props) => {
  const menuClassName = useMemo(() => {
    return classNames({
      'lemon-headerMenu__languageMenu': true,
      'lemon-appLayout__headerMenu': props.standalone ?? false,
    });
  }, [props.standalone]);
  return (
    <Menu
      mode={props.standalone ? 'horizontal' : 'vertical'}
      overflowedIndicator={null}
      onClick={props.onLocaleChange}
      defaultSelectedKeys={[props.locale]}
      className={menuClassName}
    >
      <Menu.SubMenu
        key="lemon-localeMenu"
        title={
          <Row align="middle" gutter={8}>
            <Col>
              {props.standalone ? <LemonIcon name="language" /> : props.translate('LOGIN_MENU.LANGUAGE_LABEL')}
            </Col>

            {props.standalone &&
              <Col>
                <LemonIcon name="down" />
              </Col>
            }
          </Row>
        }
        className={menuClassName}
      >
        {Object.keys(MESSAGES).map((locale) => {
          return <Menu.Item key={locale}>{LABELS[locale]}</Menu.Item>;
        })}
      </Menu.SubMenu>
    </Menu>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<ILocalePickerOwnProps>(LocalePicker as any);

import { Form } from '@ant-design/compatible';
import { Button, Divider, Row, Tag, Typography } from 'antd';
import Col, { ColProps } from 'antd/lib/col';
import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import LemonImage from '@src/components/common/image/LemonImage';
import { LangUtils } from '@src/service/util/LangUtils';

const GRID_LAYOUT_LABEL_LAYOUT: ColProps = { xs: 24, sm: 10, md: 8 };
const GRID_LAYOUT_WRAPPER_LAYOUT: ColProps = { xs: 24, sm: 12, md: 12 };

// -- Prop types
// ----------
export interface ITenantConfigurationViewOwnProps {
  configuration: ITenantConfiguration;

  onEdit?: () => void;
}
type ITenantConfigurationViewProps = ITenantConfigurationViewOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

/** Display tenant configuration items. */
const TenantConfigurationView: React.FC<ITenantConfigurationViewProps> = (props) => {
  const enabledLabel = props.translate('COMMON.ACTION_YES');
  const disabledLabel = props.translate('COMMON.ACTION_NO');
  /** Returns image file path or fallback image path */
  const getImageFormValue = (props: ITenantConfigurationViewProps): string | undefined => {
    const tenantConfiguration = props.configuration;

    const value = tenantConfiguration.configuration?.secondaryLogoFileId;
    if (!LangUtils.isStringEmpty(value)) {
      return props.resolveApiFilePath(value);
    }
    return;
  };
  return (
    <Form>
      <Form.Item>
        <Row justify="end">
          <Col className="text-center">
            <Button onClick={(e) => props.onEdit?.()}>{props.translate('COMMON.ACTION_EDIT')}</Button>
          </Col>
        </Row>
      </Form.Item>
      {/** HZZ
       * --- Enable external education applications ---
       * --- Bussines profile tab ---
       * --- Enable notz ---
       * --- Enable external educations ---
       */}
      <Typography.Title level={2}> {props.translate('TENANT_CONFIGURATION_VIEW.HZZ_TITLE')} </Typography.Title>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATION_APPLICATIONS_LABEL')}>
        {props.configuration.configuration?.enableExternalEducationApplications === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_BUSINESS_PROFILE')}>
        {props.configuration.configuration?.enableOrganizations === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_NOTZ')}>
        {props.configuration.configuration?.enableNotz === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATIONS_TEMPLATE_LABEL')}>
        {props.configuration.configuration?.enableExternalEducations === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Divider />

      {/** Import user
       * --- Hrnet user import ---
       * --- Timun user import ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.USER_IMPORT_TITLE')} </Typography.Title>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_HRNET_USER_IMPORT')}>
        {props.configuration.configuration?.enableHrnetUserImport === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_TIMUN_USER_IMPORT')}>
        {props.configuration.configuration?.enableTimunUserImport === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Divider />

      {/** Courses
       * --- Enable courses ---
       * --- Enable SCORM courses ---
       * --- Enable organization user groups ---
       * --- Comments tab ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.COURSES_TITLE')} </Typography.Title>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_COURSES_LABEL')}>
        {props.configuration.configuration?.enableCourses === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_SCORM_COURSES_LABEL')}>
        {props.configuration.configuration?.enableScormCourses === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ORGANIZATION_GROUP_COURSES_LABEL')}>
        {props.configuration.configuration?.enableOrganizationGroupCourses === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_COMMENTS')}>
        {props.configuration.configuration?.enableComments === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Divider />

      {/**  Skills
       * --- Enable skills  ---
       * --- Enable skills gap ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.SKILLS_TITLE')} </Typography.Title>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILLS')}>
        {props.configuration.configuration?.enableSkills === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_RECOMMEDITION')}>
        {props.configuration.configuration?.enableSkillsGap === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Divider />

      {/**  Codebooks
       * --- Codebooks tab ---
       * --- Admin codebook list ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.CODEBOOKS_TITLE')} </Typography.Title>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_CODEBOOKS')}>
        {props.configuration.configuration?.adminCodebook?.enableCodebooks === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ADMIN_CODEBOOK_LIST')}>
        <Row>
          {props.configuration.configuration?.adminCodebook?.adminCodebookList?.map((adminCodebook) => (
            <Col key={adminCodebook}>
              <Tag> {props.translate(`CODEBOOK.TITLE_LABEL.${adminCodebook}`)} </Tag>
            </Col>
          ))}
        </Row>
      </Form.Item>
      <Divider />

      {/** Groups
       * --- Enable user groups ---
       * --- Enable organization groups ---
       *  ---Enable organization graph ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.GROUPS_TITLE')} </Typography.Title>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUPS_LABEL')}>
        {props.configuration.configuration?.enableUserGroups === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ORGANIZATION_GROUP_LABEL')}>
        {props.configuration.configuration?.enableOrganizationGroups === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ORGANIZATION_GRAPH_LABEL')}>
        {props.configuration.configuration?.enableOrganizationGraph === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Divider />
      {/** Quizzler
       * --- Enable surveys ---
       * --- Exam question list ---
       * --- Suvey question list ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.QUIZZLER_TITLE')} </Typography.Title>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SURVEYS')}>
        {props.configuration.configuration?.enableSurveys === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.EXAM_QUESTION_LIST')}>
        <Row>
          {props.configuration.configuration?.examQuestionList?.map((questionType) => (
            <Col key={questionType}>
              <Tag> {props.translate(`QUESTION_TYPE.${questionType}`)} </Tag>
            </Col>
          ))}
        </Row>
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.SURVEY_QUESTION_LIST')}>
        <Row>
          {props.configuration.configuration?.surveyQuestionList?.map((questionType) => (
            <Col key={questionType}>
              <Tag> {props.translate(`QUESTION_TYPE.${questionType}`)} </Tag>
            </Col>
          ))}
        </Row>
      </Form.Item>
      <Divider />
      {/** System
       * --- Style Theme ---
       * --- Enable multi locales ---
       * --- Enable dashboard ---
       * --- Enable user profile edit ---
       * --- Enable user first last name and oib edit ---
       * --- Enable external contents ---
       * --- Enable repository ---
       * --- Enable webinars ---
       * --- Video integration ---
       * --- Workpositions tab ---
       * --- Activities tab ---
       * --- Notifications tab ---
       * --- Email templates tab ---
       * --- LDAP sync ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.BASIC_SYSTEM_SETTINGS_TITLE')} </Typography.Title>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.STYLE_THEME_LABEL')}>
        {props.configuration.configuration?.styleTheme}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.SECONDARY_LOGO_IMAGE_LABEL')}>
        <LemonImage imagePath={getImageFormValue(props)} className="timun-courseView__coverImage" />
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_LOCALES_LABEL')}>
        {props.configuration.configuration?.enableLanguages === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_DASHBOARD_LABEL')}>
        {props.configuration.configuration?.enableDashboard === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_PROFILE_EDIT_LABEL')}>
        {props.configuration.configuration?.enableUserProfileEdit === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_IDENTIFICATION_DETAILS_EDIT_LABEL')}>
        {props.configuration.configuration?.enableUserIdentificationDetailsEdit === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_CONTENTS_LABEL')}>
        {props.configuration.configuration?.enableExternalContents === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_REPOSITORY_LABEL')}>
        {props.configuration.configuration?.enableRepository === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_WEBINARS_LABEL')}>
        {props.configuration.configuration?.enableWebinars === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_INTEGRATION_VIDEO')}>
        {props.configuration.configuration?.enableIntegrationVideo === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_WORKPOSITIONS')}>
        {props.configuration.configuration?.enableWorkpositions === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_ACTIVITIES')}>
        {props.configuration.configuration?.enableActivities === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_NOTIFICATIONS')}>
        {props.configuration.configuration?.enableNotifications === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_EMAILS')}>
        {props.configuration.configuration?.enableEmails === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_LDAP_SYNC')}>
        {props.configuration.configuration?.enableLDAP === true ? enabledLabel : disabledLabel}
      </Form.Item>
      <Divider />
      <Form.Item>
        <Row justify="end">
          <Col className="text-center">
            <Button onClick={(e) => props.onEdit?.()}>{props.translate('COMMON.ACTION_EDIT')}</Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default withLocalize<ITenantConfigurationViewOwnProps>(withPathResolver(TenantConfigurationView as any));

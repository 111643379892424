import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { IProgressTracker, ProgressTrackerTypeEnum } from '@src/model/progresstracker/ProgressTracker';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { withRouter, WithRouterProps } from 'react-router';
import DeactivatedUserListContainer from '@src/components/user/list/DeactivatedUserListContainer';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IFile } from '@src/model/file/File';
import UserListUploadModal from '@src/components/user/list/UserListUploadModal';

// -- Const
// ---
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');

export interface IProgressTrackerListOwnProps {
  progressTrackerList: ICollectionData<IProgressTracker>;
  selectableTable?: boolean;
  showSelectedElementsPanel?: boolean;
  onHrnetUserListUpload?: (data: IFile) => void;
  onPageChange?: (page: number, size?: number) => void;
  onReload: () => void;
}

type IProgressTrackerListProps = IProgressTrackerListOwnProps & IWithLocalizeOwnProps & WithRouterProps & IWithTenantPropEnabledOwnProps;

const ProgressTrackerList: React.FC<IProgressTrackerListProps> = (props: IProgressTrackerListProps) => {
  const [instanceListModal, toggleInstanceModals] = useEntityModalsState<IProgressTracker>();
  const [isImportModalVisible, setIsImportModalVisible] = useState<boolean>(false);

  const handleUserImportListSubmit = useCallback(
    (data: IFile) => {
      props.onHrnetUserListUpload?.(data);
      setIsImportModalVisible(true);
    },
    [props.onHrnetUserListUpload, setIsImportModalVisible]
  );
  const columns: ITimunDataTableCol<IProgressTracker>[] = useMemo(
    () => [
      {
        key: 'date',
        contentType: 'string',
        headerTitle: props.translate('PROGRESS_TRACKER.DATE_LABEL'),
        content: (record: IProgressTracker) => moment(record.createdDateTime).format(dateFormat),
      },
      {
        key: 'status',
        contentType: 'string',
        headerTitle: props.translate('PROGRESS_TRACKER.STATUS_LABEL'),
        content: (record: IProgressTracker) => props.translate(`PROGRESS_TRACKER.STATUS_ENUM.${record.status.id}_LABEL`),
      },
      {
        key: 'action',
        contentType: 'string',
        headerTitle: props.translate('PROGRESS_TRACKER.ACTION_LABEL'),
        content: (record: IProgressTracker) => {
          if (record.type.id === ProgressTrackerTypeEnum.USER_PARSER) {
            return (
              <Button type="primary" onClick={() => toggleInstanceModals.onOpenEntity(record)}>
                {props.translate('PROGRESS_TRACKER.BUTTON_USER_PARSER')}
              </Button>
            );
          } else if (record.type.id === ProgressTrackerTypeEnum.REPORT_CREATION) {
            return (
              <Button type="primary" onClick={() => toggleInstanceModals.onOpenEntity(record)}>
                {props.translate('PROGRESS_TRACKER.BUTTON_REPORT_CREATION')}
              </Button>
            );
          } else if (record.type.id === ProgressTrackerTypeEnum.LDAP_LOG) {
            return (
              <Tooltip overlay={props.translate('PROGRESS_TRACKER.RELOAD_BUTTON_TOOLTIP')}>
                <a onClick={() => props.onReload()}>
                  <LemonIcon name="redo" />
                </a>
              </Tooltip>
            );
          } else {
            return '';
          }
        },
      },
    ],
    [toggleInstanceModals]
  );

  return (
    <React.Fragment>
      <DataTable<IProgressTracker> items={props.progressTrackerList.content} paginationData={props.progressTrackerList.page} columns={columns} scrollable={true} showActionMenu={true} showSelectedElementsPanel={true} paginationConfig={{ onChange: props.onPageChange }} />
      {/** deactivated user */}
      {instanceListModal.isEntityModalVisible && instanceListModal.selectedEntity?.type.id === ProgressTrackerTypeEnum.USER_PARSER && <DeactivatedUserListContainer progressTrackerId={instanceListModal.selectedEntity.id} onCancel={toggleInstanceModals.onCloseEntity} />}
      {/* Modal */}
      {isImportModalVisible && <UserListUploadModal onClose={() => setIsImportModalVisible(false)} onSubmit={handleUserImportListSubmit} title={props.translate('FILE_UPLOAD.HRNET_USER_IMPORT_MODAL_TITLE')} />}
    </React.Fragment>
  );
};

export default withLocalize(withRouter<IProgressTrackerListOwnProps>(withTenantPropEnabled(ProgressTrackerList as any)));

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdminCourseListContainer from '@src/components/course/list/catalog/AdminCourseListContainer';
import CreatorCourseListContainer from '@src/components/course/list/catalog/CreatorCourseListContainer';
import CoursesOverviewContainer from '@src/components/course/report/CoursesOverviewContainer';
import UsersCourseProgress from '@src/components/user/progress/UsersCourseProgress';
import { UserRoleEnum } from '@src/model/user/UserRole';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Tabs } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

enum CoursesAdministrationTabsViewMenuKeys {
  Create = 'create',
  Management = 'management',
  Reports = 'overview',
  UserProgress = 'userprogress',
}

export interface ICoursesAdministrationTabsViewOwnProps {
  openedTab: string;
}

type ICoursesAdministrationTabsViewProps = ICoursesAdministrationTabsViewOwnProps & WithRouterProps & IWithLocalizeOwnProps;

const CoursesAdministrationTabsView: React.FC<ICoursesAdministrationTabsViewProps> = (props) => {
  const handleChange = (key: string) => {
    props.router.push(`/courses/administration/${key}`);
  };

  return (
    <AppContent>
      <Tabs activeKey={props.openedTab} onChange={handleChange} destroyInactiveTabPane={true}>
        <Tabs.TabPane tab={props.translate('COURSE_LIST.ADMINISTRATION.COURSE_LIST_CREATOR_TAB')} key={CoursesAdministrationTabsViewMenuKeys.Create}>
          <CreatorCourseListContainer />
        </Tabs.TabPane>
        {RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && (
          <React.Fragment>
            <Tabs.TabPane tab={props.translate('COURSE_LIST.ADMINISTRATION.COURSE_LIST_MANAGEMENT_TAB')} key={CoursesAdministrationTabsViewMenuKeys.Management}>
              <AdminCourseListContainer />;
            </Tabs.TabPane>
            <Tabs.TabPane tab={props.translate('COURSE_LIST.ADMINISTRATION.COURSE_LIST_OVERVIEW_TAB')} key={CoursesAdministrationTabsViewMenuKeys.Reports}>
              <CoursesOverviewContainer />
            </Tabs.TabPane>
            <Tabs.TabPane tab={props.translate('COURSE_LIST.ADMINISTRATION.COURSE_LIST_USER_PROGRESS_TAB')} key={CoursesAdministrationTabsViewMenuKeys.UserProgress}>
              <UsersCourseProgress />
            </Tabs.TabPane>
          </React.Fragment>
        )}
      </Tabs>
    </AppContent>
  );
};

export default withLocalize<ICoursesAdministrationTabsViewOwnProps>(withRouter(CoursesAdministrationTabsView) as any);

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Radio, RadioChangeEvent } from 'antd';

// -- Prop types
// ----------

export interface IYesNoRadioButtonsOwnProps {
  name: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (value?: RadioChangeEvent) => void;
}
type IYesNoRadioButtonsProps = IYesNoRadioButtonsOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const YesNoRadioButtons: React.FC<IYesNoRadioButtonsProps> = (props: IYesNoRadioButtonsProps) => {
  return (
    <Radio.Group defaultValue={false} disabled={props.disabled} value={props.value} onChange={props.onChange} buttonStyle="solid" data-test-id={`timun-externalEducationApplicationForm__${props.name}`}>
      <Radio.Button value={true} data-test-id={`timun-externalEducationApplicationForm__${props.name}_true`}>
        {props.translate('COMMON.ACTION_YES')}
      </Radio.Button>
      <Radio.Button value={false} data-test-id={`timun-externalEducationApplicationForm__${props.name}_false`}>
        {props.translate('COMMON.ACTION_NO')}
      </Radio.Button>
    </Radio.Group>
  );
};

export default withLocalize<IYesNoRadioButtonsOwnProps>(YesNoRadioButtons as any);

// Global object types

declare const module: { hot: any; };
declare const require: any;

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

// redux-persist submodule's typings are broken and declared imports are not working (complains about modules havung implicite any type)
// tslint:disable-next-line:no-var-requires no-submodule-imports
const ReduxPersist = require('redux-persist/es/integration/react');
// tslint:disable-next-line: ordered-imports - can't import before store service
import LdLocaleProvider from '@src/components/app/locale/LdLocaleProvider';
// this initializes root BS so it's important to do it before other store using stuff
import StoreService from '@src/service/business/StoreService';

// import logging ASAP, before any of other custom code so logging can be used right away
// tslint:disable-next-line:no-import-side-effect
import '@src/service/common/logging/logging';

import App from '@src/components/App';
import appRouter from '@src/components/common/route/appRouter';
import { AppSplash } from '@src/components/app/AppSplash';

const rootEl = document.getElementById('root');

// init redux store
const appStore = StoreService.getStore();
const appPersistor = StoreService.getPersistor();

const renderApp = (router: any) => {
  render(
    <Provider store={appStore}>
      <LdLocaleProvider>
        <ReduxPersist.PersistGate loading={<AppSplash />} persistor={appPersistor}>
          <App>{router}</App>
        </ReduxPersist.PersistGate>
      </LdLocaleProvider>
    </Provider>,
    rootEl
  );
};

renderApp(appRouter);

// ---------- Hot Module Loader
if (module.hot) {
  module.hot.accept('./components/common/route/appRouter', () => {
    const routes = require('./components/common/route/appRouter').default;

    renderApp(routes);
  });
}

import React, { useCallback, useState } from 'react';

import { Editor } from '@src/components/common/editor/Editor';
import { TimunEditorConfig } from '@src/components/common/editor/TimunEditorConfigs';
import FormDataItemList from '@src/components/common/form/FormDataItemList';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AddressShortInfoForm from '@src/components/externaleducationistance/form/AddressShortInfoForm';
import ExecutionMethodSelector from '@src/components/externaleducationistance/form/ExecutionMethodSelector';
import { ExecutionMethodEnum, ExternalEducationInstanceStatusEnum, IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import AppConfigService from '@src/service/common/AppConfigService';
import { Form, FormInstance, Input, Radio } from 'antd';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');

/** Check if external education instance is in one of given statuses */
const isExternalEducationInstanceInStatus = (externalEducationInstance: IExternalEducationInstance, requestedStatuses: ExternalEducationInstanceStatusEnum[]): boolean => {
  return requestedStatuses.find((status) => externalEducationInstance && externalEducationInstance.status.id === status) != null;
};
// -- Prop types
// ----------

export interface IExternalEducationInstanceFormOwnProps {
  value?: IExternalEducationInstance;
  onSubmit?: (data: IExternalEducationInstance) => void;
  formRef?: FormInstance<IExternalEducationInstance>;
  disabled?: boolean;
}
type IExternalEducationInstanceFormProps = IExternalEducationInstanceFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education template form */
const ExternalEducationInstanceForm: React.FC<IExternalEducationInstanceFormProps> = (props: IExternalEducationInstanceFormProps) => {
  const [form] = Form.useForm<IExternalEducationInstance>(props.formRef);
  const [educationOnSite, setEducationOnSite] = useState<boolean>(false);

  const onExecutionMethodChange = useCallback((value: ExecutionMethodEnum) => {
    if (value === ExecutionMethodEnum.ONLINE) {
      setEducationOnSite(false);
    } else {
      setEducationOnSite(true);
    }
  }, []);

  const handleFinish = (values: IExternalEducationInstance) => {
    props.onSubmit?.(values);
  };

  const isPublished = props.value && isExternalEducationInstanceInStatus(props.value, [ExternalEducationInstanceStatusEnum.PUBLISHED]);
  const isArchived = props.value && isExternalEducationInstanceInStatus(props.value, [ExternalEducationInstanceStatusEnum.ARCHIVED]);
  return (
    <Form<IExternalEducationInstance> initialValues={props.value} layout="vertical" form={form} name="externalEducationTemplate" onFinish={handleFinish}>
      <Form.Item noStyle={true} hidden={true} name={'status'}>
        <Input />
      </Form.Item>

      <Form.Item noStyle={true} hidden={true} name={'externalEducationTemplate'}>
        <Input />
      </Form.Item>

      <Form.Item noStyle={true} hidden={true} name={['id']}>
        <Input />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.EXECUTION_METHOD_LABEL')} name={['executionMethod', 'id']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <ExecutionMethodSelector placeholder={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.EXECUTION_METHOD_PLACEHOLDER_LABEL')} allowClear={true} onChange={onExecutionMethodChange} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.ADDRESS_LABEL')} required={educationOnSite}>
        <FormDataItemList name={'addresses'} formRef={form} initialCount={1} min={educationOnSite ? 1 : 0}>
          <AddressShortInfoForm required={educationOnSite} />
        </FormDataItemList>
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.STATUS_LABEL')} name={['status', 'id']} rules={[{ required: true, message: props.translate('COMMON.PICK_REQUIRED_FIELD') }]}>
        <Radio.Group>
          <Radio.Button disabled={isArchived || isPublished} value={ExternalEducationInstanceStatusEnum.IN_CREATION}>
            {props.translate('COMMON.STATUS_LABEL.IN_CREATION')}
          </Radio.Button>
          <Radio.Button disabled={isArchived} value={ExternalEducationInstanceStatusEnum.PUBLISHED}>
            {props.translate('COMMON.STATUS_LABEL.PUBLISHED')}
          </Radio.Button>
          <Radio.Button disabled={isArchived || !isPublished} value={ExternalEducationInstanceStatusEnum.ARCHIVED}>
            {props.translate('COMMON.STATUS_LABEL.ARCHIVED')}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.DESCRIPTION_LABEL')} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
        name="description"
        rules={[
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
        ]}
      >
        <Editor
          init={{
            ...TimunEditorConfig.shortConfig(),
            height: 300,
            menubar: false,
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default withLocalize<IExternalEducationInstanceFormOwnProps>(ExternalEducationInstanceForm as any);

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExamInstanceHelperUtils from '@src/components/exam/common/ExamInstanceHelperUtils';
import ExamInstanceForm from '@src/components/exam/examInstance/ExamInstanceForm';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { getCurrentUser } from '@src/service/business/login/loginBusinessService';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { Divider, Layout, Typography } from 'antd';
import React, { useCallback } from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const { Header, Content } = Layout;

// -- Prop types
// ----------

export interface IExamInstanceViewOwnProps {
  examInstance: IExamInstance;
  onSubmit: (examInstance: IExamInstance) => void;
  courseId: string;
}

type IExamInstanceViewProps = IExamInstanceViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & WithRouterProps;

// -- Component
// ----------

/** Describe your component ... */
const ExamInstanceView = (props: IExamInstanceViewProps) => {
  const getExamInstanceListRoute = useCallback((): string => {
    let coursePath = UrlBuilderFactory.buildAppUrl(`course/${props.courseId}`);
    if (EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.ASSIGNEE, props.examInstance.activities[0])?.userId === getCurrentUser().id) {
      // Assignee path
      coursePath = UrlBuilderFactory.buildAppUrl(`course/${props.courseId}/exam`);
    } else if (props.allowedRoles([UserRoleEnum.TRAINER, UserRoleEnum.ORGANIZATION_ADMIN])) {
      coursePath = UrlBuilderFactory.buildAppUrl(`course/${props.courseId}/administration/examinstancelist`);
    } else if (props.allowedRoles([UserRoleEnum.COURSE_CREATOR])) {
      coursePath = UrlBuilderFactory.buildAppUrl(`course/${props.courseId}/examtemplate/${props.examInstance.examTemplate.id}/examinstancelist`);
    }
    return coursePath;
  }, [props.courseId, getCurrentUser, UrlBuilderFactory.buildAppUrl, props.examInstance.examTemplate.id]);

  return (
    <React.Fragment>
      {/* --- Display evaluation message  --- */}
      {ExamInstanceHelperUtils.isExamInstancenInPhase(props.examInstance, [ActivityPhaseEnum.ANSWERS_SUBMITTED, ActivityPhaseEnum.IN_EVALUATION]) && (
        <Layout className="panel">
          <Header>
            <HeaderTitle link={getExamInstanceListRoute()} title={props.examInstance?.title} icon={<LemonIcon name="left" />} />
          </Header>
          <Divider />
          <Layout>
            <Content className="timun-examInstanceView__examContentTitle">
              <Typography.Title> {props.translate('EXAM_INSTANCE.EVALUATION_MESSAGE_INFO')} </Typography.Title>
            </Content>
          </Layout>
        </Layout>
      )}

      {/* --- Display exam instance  --- */}
      {ExamInstanceHelperUtils.isExamInstancenInPhase(props.examInstance, [ActivityPhaseEnum.ANSWERING_STARTED]) && <ExamInstanceForm showEvaluationFeedback={false} showQuestionIntroActionButtons={true} examInstance={props.examInstance} onSubmit={props.onSubmit} examListRoute={getExamInstanceListRoute()} />}

      {/* --- Display exam instance feedback  --- */}
      {ExamInstanceHelperUtils.isExamInstancenInPhase(props.examInstance, [ActivityPhaseEnum.EVALUATED]) && <ExamInstanceForm showQuestionIntroActionButtons={false} showEvaluationFeedback={true} examInstance={props.examInstance} examListRoute={getExamInstanceListRoute()} />}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

export default withRoles(withLocalize(withRouter<IExamInstanceViewOwnProps>(ExamInstanceView as any)));

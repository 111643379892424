import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RouterNavigationPromptContextProvider from '@src/components/common/route/prompt/RouterNavigationPromptContextProvider';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import WebinarForm from '@src/components/webinar/create/WebinarForm';
import { IFile } from '@src/model/file/File';
import webinarViewBusinessStore, { IWebinarCreatePayload } from '@src/service/business/webinars/webinarViewBusinessStore';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { Layout } from 'antd';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';

const { Header, Content } = Layout;

interface IWebinarCreateContainerOwnProps { }

interface IWebinarCreateContainerDispatchProps {
  createWebinar: (data: IWebinarCreatePayload, callback: IActionThunkMap) => void;
  uploadWebinarCover: (id: string, data: IFile, callback: IActionThunkMap) => void;
}

type IWebinarCreateContainerProps = IWebinarCreateContainerOwnProps & IWebinarCreateContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

const WebinarCreateContainer: React.FC<IWebinarCreateContainerProps> = (props: IWebinarCreateContainerProps) => {
  const [coverImage, setCoverImage] = useState<IFile | undefined>(undefined);

  const handleCancel = useCallback(() => props.router.push('/webinars'), [props.router.push]);

  const handleWebinarCoverSubmit = useCallback(
    (webinarId: string) => {
      if (coverImage) {
        props.uploadWebinarCover(webinarId, coverImage, {
          success: () => {
            props.router.replace(`/webinar/${webinarId}`);
          },
        });
      }
    },
    [props.uploadWebinarCover, coverImage, props.router.replace]
  );

  const handleWebinarCreate = useCallback(
    (data: IWebinarCreatePayload) => {
      props.createWebinar(data, {
        success: (response) => {
          if (coverImage) {
            handleWebinarCoverSubmit(response.id);
          } else {
            props.router.replace(`/webinar/${response.id}`);
          }
        },
      });
    },
    [props.createWebinar, coverImage, handleWebinarCoverSubmit, props.router.replace]
  );

  const handleCoverPick = useCallback((data: IFile) => {
    if (data) {
      setCoverImage(data);
    }
  }, []);

  return (
    <RouterNavigationPromptContextProvider message={props.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION')}>
      <Layout>
        <Header className="timun-webinarView__webinarTitle">
          <div className="flex-between">
            <HeaderTitle title={props.translate('WEBINAR_VIEW.CREATE.CREATE_WEBINAR_TITLE')} link={'/webinars'} icon={<LemonIcon name="left" />} />
          </div>
        </Header>
        <Layout>
          &nbsp;
          <Content className="timun-webinarView__content">
            <WebinarForm onSubmit={handleWebinarCreate} onCancel={handleCancel} onCoverSubmit={handleCoverPick} coverImageId={coverImage?.id} />
          </Content>
        </Layout>
      </Layout>
    </RouterNavigationPromptContextProvider>
  );
};

// -- HOCs and exports
// ----------

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IWebinarCreateContainerDispatchProps => ({
  createWebinar: (data: IWebinarCreatePayload, thunkMap: IActionThunkMap) => dispatch(createActionThunk(webinarViewBusinessStore.actions.createWebinar(data), thunkMap)),
  uploadWebinarCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => dispatch(createActionThunk(webinarViewBusinessStore.actions.uploadWebinarCover(id, data), thunkMap)),
});

export default connect<any, IWebinarCreateContainerDispatchProps, IWebinarCreateContainerOwnProps>(null, mapDispatchToProps)(withLocalize(withRouter(WebinarCreateContainer as any)));

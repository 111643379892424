import { classNames } from '@src/components/common/util/classNames';
import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

export interface IHeaderTitleOwnProps {
  title: string;
  link?: string;
  icon: React.ReactNode;
  className?: string;
}
type IHeaderTitleProps = IHeaderTitleOwnProps;

// -- Component
// ----------

class HeaderTitle extends React.Component<IHeaderTitleProps> {
  render = () => {
    const title = (
      <Typography.Title level={3} className="timun-headerTitle__title">
        {this.props.icon}
        &nbsp; &nbsp;
        {this.props.title}
      </Typography.Title>
    );
    return this.props.link ? (
      <Link className={this.getClassName()} to={this.props.link} data-test-id={`timun-headerTitle__link_${this.props.link}`}>
        {title}
      </Link>
    ) : (
      <div className={this.getClassName()}> {title} </div>
    );
  };

  getClassName() {
    return classNames({
      [this.props.className || '']: true,
    });
  }
}

export default HeaderTitle;

import IIdRef from '@src/model/common/IdRef';
import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { PropsWithChildren } from 'react';

// -- Prop types
// ----------
export interface IFormObjectFieldOwnProps<T> {
  valuePropPath: Array<string | number>;
  objectNamePath: Array<string | number>;
  label?: string;
  rules?: Rule[];
}

type IFormObjectFieldProps<T> = IFormObjectFieldOwnProps<T>;

// -- Component
// ----------

/** Address input fileds */
const FormObjectField = <T extends IIdRef<string>>(props: PropsWithChildren<IFormObjectFieldProps<T>>) => {
  return (
    <React.Fragment>
      <Form.Item
        noStyle={true}
        hidden={true}
        name={[...props.objectNamePath]}
      />

      <Form.Item
        label={props.label}
        name={[...props.objectNamePath, ...props.valuePropPath]}
        rules={props.rules}>
        {props.children}
      </Form.Item>
    </React.Fragment>
  );
};

export default FormObjectField;

import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { ICourse } from '@src/model/course/Course';
import { ICourseLectureListElement } from '@src/model/course/LectureGroup';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Selectors

/** Returns public course from store. */
const getPublicCourseContent = (store: any): ICourse => store.publicCourseData;

/** Returns list of public course lectures from store. */
const getPublicCourseLectureList = (store: any): ICourseLectureListElement[] => store.publicCourseLectureList;

// -
// -------------------- Actions

const Actions = {
  PUBLIC_COURSE_CONTENT_FETCH: 'PUBLIC_COURSE_CONTENT_FETCH',
  PUBLIC_COURSE_CONTENT_LOAD: 'PUBLIC_COURSE_CONTENT_LOAD',
  PUBLIC_COURSE_CONTENT_CLEAR: 'PUBLIC_COURSE_CONTENT_CLEAR',
  PUBLIC_COURSE_LECTURES_LIST_FETCH: 'PUBLIC_COURSE_LECTURES_LIST_FETCH',
  PUBLIC_COURSE_LECTURES_LIST_LOAD: 'PUBLIC_COURSE_LECTURES_LIST_LOAD',
  PUBLIC_COURSE_LECTURES_LIST_CLEAR: 'PUBLIC_COURSE_LECTURES_LIST_CLEAR',
};

/** Fetch public course by ID. */
const fetchPublicCourseContent = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.PUBLIC_COURSE_CONTENT_FETCH,
    payload: { id },
  };
};

/** Load public course to store. */
const loadPublicCourseContent = (data: ICourse): IPayloadAction<ICourse> => {
  return {
    type: Actions.PUBLIC_COURSE_CONTENT_LOAD,
    payload: data,
  };
};

/** Clear public course from store. Eg. when leaving view. */
const clearPublicCourseContent = (): ILemonAction => {
  return {
    type: Actions.PUBLIC_COURSE_CONTENT_CLEAR,
  };
};

/** Fetch public course lecture list. */
const fetchPublicCourseLectureList = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.PUBLIC_COURSE_LECTURES_LIST_FETCH,
    payload: {
      id,
    },
  };
};

/** Load public course lecture list to store. */
const loadPublicCourseLectureList = (data: ICollectionData<ICourseLectureListElement>): IPayloadAction<ICollectionData<ICourseLectureListElement>> => {
  return {
    type: Actions.PUBLIC_COURSE_LECTURES_LIST_LOAD,
    payload: data,
  };
};

/** Clear public course lecture list from store. Eg. when leaving list view. */
const clearPublicCourseLectureList = (): ILemonAction => {
  return {
    type: Actions.PUBLIC_COURSE_LECTURES_LIST_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchPublicCourseEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PUBLIC_COURSE_CONTENT_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('PublicCourse')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadPublicCourseContent(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    // tslint:disable-next-line: no-duplicate-string
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching public course', error);
      return o;
    })
  );
};

const fetchPublicCourseLectureListEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PUBLIC_COURSE_LECTURES_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('PublicCourse')
        .fetchSubobject(id, 'lecturelist')
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadPublicCourseLectureList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching public course lecture list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const publicCourseData = (state: ICourse | null = null, action: IPayloadAction<ICourse>) => {
  if (action.type === Actions.PUBLIC_COURSE_CONTENT_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.PUBLIC_COURSE_CONTENT_CLEAR) {
    return null;
  }

  return state;
};

const publicCourseLectureList = (state: ICourseLectureListElement[] = [], action: IPayloadAction<ICourseLectureListElement[]>) => {
  if (action.type === Actions.PUBLIC_COURSE_LECTURES_LIST_LOAD) {
    return action.payload;
  } else if (action.type === Actions.PUBLIC_COURSE_LECTURES_LIST_CLEAR) {
    return [];
  }

  return state;
};

// --
// -------------------- Business Store

export const PublicCourseViewBusinessStore = {
  actions: {
    fetchPublicCourseContent,
    clearPublicCourseContent,
    fetchPublicCourseLectureList,
    clearPublicCourseLectureList,
  },

  selectors: {
    getPublicCourseContent,
    getPublicCourseLectureList,
  },

  effects: {
    fetchPublicCourseEffect,
    fetchPublicCourseLectureListEffect,
  },

  reducers: {
    publicCourseData,
    publicCourseLectureList,
  },
};

// --
// export business store
export default PublicCourseViewBusinessStore;

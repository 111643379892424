import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserGroupFileView from '@src/components/usergroup/file/UserGroupFileView';
import IIdRef from '@src/model/common/IdRef';
import { IFile } from '@src/model/file/File';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { UserGroupMemberRoleEnum } from '@src/model/usergroup/UserGroupMemberRole';
import { IUserGroupObject } from '@src/model/usergroup/UserGroupObject';
import { UserGroupObjectTypeEnum } from '@src/model/usergroup/UserGroupObjectType';
import { ICollectionData } from '@src/service/business/common/types';
import UserGroupReferencedObjectsBusinessStore, { IReferencedObjectListFilter, IReferencedObjetTypeEnum } from '@src/service/business/usergroup/UserGroupReferencedObjectsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

const midPageSize = AppConfigService.getValue('api.paging.midPageSize');

// -- Prop types
// ----------

export interface IUserGroupFilesContainerOwnProps {
  userGroup: IUserGroup;
}

export interface IUserGroupFilesContainerStateProps {
  referencedObjectList: ICollectionData<IUserGroupObject<IFile>>;
  referencedObjectListFilter: IReferencedObjectListFilter;
}

export interface IUserGroupFilesContainerDispatchProps {
  fetchReferencedObjectList: (userGroupId: string, filter: IReferencedObjectListFilter, page: number, size: number, sort: string[]) => void;
  storeReferencedObjectListFilter: (filter: IReferencedObjectListFilter) => void;
  clearReferencedObjectListFilter: () => void;
  clearReferencedObjectList: () => void;
  createReferencedObjectList: (userGroupId: string, objectTypeId: UserGroupObjectTypeEnum, objectRefs: Array<IIdRef<string>>) => ITrackableAction;
  removeReferencedObjects: (userGroupId: string, objectTypeId: UserGroupObjectTypeEnum, objectRefs: Array<IIdRef<string>>) => ITrackableAction;
}

type IUserGroupFilesContainerProps = IUserGroupFilesContainerOwnProps & IUserGroupFilesContainerStateProps & IUserGroupFilesContainerDispatchProps & IWithLocalizeOwnProps;

interface IUserGroupFilesContainerState {
  sort: string;
  page: number;
  size: number;
}

// -- Component
// ----------

/** Repository container component */
class UserGroupFilesContainer extends React.Component<IUserGroupFilesContainerProps, IUserGroupFilesContainerState> {
  state: IUserGroupFilesContainerState = {
    sort: '',
    page: 0,
    size: midPageSize,
  };

  componentDidMount = () => {
    this.props.storeReferencedObjectListFilter({ ...this.props.referencedObjectListFilter, objectType: IReferencedObjetTypeEnum.FILE_RESOURCE });
  };

  componentDidUpdate = (prevProps: IUserGroupFilesContainerProps, prevState: IUserGroupFilesContainerState) => {
    if (this.props.referencedObjectListFilter !== prevProps.referencedObjectListFilter || this.state.page !== prevState.page || this.state.size !== prevState.size) {
      this.updateUserGroupReferencedObjectList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearReferencedObjectListFilter();
    this.props.clearReferencedObjectList();
  };

  render = () => {
    const canChangeFiles = this.props.userGroup.currentUserMember?.role.id === UserGroupMemberRoleEnum.OWNER || this.props.userGroup.currentUserMember?.role.id === UserGroupMemberRoleEnum.ADMIN;
    return <UserGroupFileView
      fileList={this.props.referencedObjectList}
      fileListFilter={this.props.referencedObjectListFilter}
      onPageChange={this.handlePageChange}
      onUpload={this.handleUploadFile}
      onRemoveFile={this.handleRemoveFile}
      canChangeFiles={canChangeFiles}
    />;
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleUploadFile = (data: IFile) => {
    this.props
      .createReferencedObjectList(this.props.userGroup.id, UserGroupObjectTypeEnum.FILE_RESOURCE, [data])
      .track()
      .subscribe(() => {
        this.updateUserGroupReferencedObjectList();
      });
  };

  handleRemoveFile = (data: IFile) => {
    this.props
      .removeReferencedObjects(this.props.userGroup.id, UserGroupObjectTypeEnum.FILE_RESOURCE, [data])
      .track()
      .subscribe(() => this.updateUserGroupReferencedObjectList());
  };

  private updateUserGroupReferencedObjectList = (userGroupId = this.props.userGroup.id, filter: IReferencedObjectListFilter = this.props.referencedObjectListFilter, page: number = this.state.page, size: number = this.state.size, sort: string = this.state.sort) => {
    this.props.fetchReferencedObjectList(userGroupId, filter, page, size, [sort]);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserGroupFilesContainerStateProps => ({
  referencedObjectList: UserGroupReferencedObjectsBusinessStore.selectors.getReferencedObjectList(state),
  referencedObjectListFilter: UserGroupReferencedObjectsBusinessStore.selectors.getReferencedObjectListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserGroupFilesContainerDispatchProps => ({
  fetchReferencedObjectList: (userGroupId: string, filter: IReferencedObjectListFilter, page: number, size: number, sort: string[]) => dispatch(UserGroupReferencedObjectsBusinessStore.actions.fetchReferencedObjectList(userGroupId, filter, page, size, sort)),
  storeReferencedObjectListFilter: (filter: IReferencedObjectListFilter) => dispatch(UserGroupReferencedObjectsBusinessStore.actions.storeReferencedObjectListFilter(filter)),
  clearReferencedObjectListFilter: () => dispatch(UserGroupReferencedObjectsBusinessStore.actions.clearReferencedObjectListFilter()),
  clearReferencedObjectList: () => dispatch(UserGroupReferencedObjectsBusinessStore.actions.clearReferencedObjectList()),
  createReferencedObjectList: (userGroupId: string, objectTypeId: UserGroupObjectTypeEnum, objectRefs: Array<IIdRef<string>>) => dispatch(createTrackableAction(UserGroupReferencedObjectsBusinessStore.actions.createReferencedObjectList(userGroupId, objectTypeId, objectRefs))),
  removeReferencedObjects: (userGroupId: string, objectTypeId: UserGroupObjectTypeEnum, objectRefs: Array<IIdRef<string>>) => dispatch(createTrackableAction(UserGroupReferencedObjectsBusinessStore.actions.removeReferencedObjects(userGroupId, objectTypeId, objectRefs))),
});

export default connect<IUserGroupFilesContainerStateProps, IUserGroupFilesContainerDispatchProps, IUserGroupFilesContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(UserGroupFilesContainer as any));

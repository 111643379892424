import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import EducationApplicationFileListView from '@src/components/externalEducationApplication/common/EducationApplicationFileListView';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { EmploymentStatusEnum, IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { IUserInfo } from '@src/model/user/User';
import { IFileListsByType } from '@src/service/business/files/util';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { AddressUtils } from '@src/service/util/location/AddressUtils';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';

interface IEducationApplicationUserViewSectionOwnProps {
  user: IUserInfo;
  externalEducationApplication?: IExternalEducationApplication;
  files?: IFileListsByType;
  active?: boolean;
  canParticipate?: boolean;
  showFiles?: boolean;
  showNotzData?: boolean;
}
type IEducationApplicationUserViewSectionProps = IEducationApplicationUserViewSectionOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationUserViewSectionState {}

class EducationApplicationUserViewSection extends React.Component<IEducationApplicationUserViewSectionProps, IEducationApplicationUserViewSectionState> {
  state: IEducationApplicationUserViewSectionState = {};

  isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean | undefined => {
    if (this.props.externalEducationApplication) {
      return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
    }
    return;
  };
  render = () => {
    const userPaymentInfo = this.props.externalEducationApplication?.paymentInfo;

    const totalEmploymentDuration = moment.duration(this.props.user.workData?.totalEmploymentDuration);

    return (
      <React.Fragment>
        <ViewSectionItem
          header={<HeaderTitle className="timun-userViewSection__header" icon={<LemonIcon name="user" size="large" />} title={this.props.translate('REQUEST.USER_VIEW_SECTION.TITLE')} />}
          active={this.props.active}
          collapsible={true}
          bordered={true}
          actions={
            <a href={`/user/${this.props.user?.id}`} onClick={(event) => event.stopPropagation()} target="_blank" data-test-id="timun-externalEducationApplicationUserPanel__openProfileLink">
              {this.props.translate('REQUEST.USER_VIEW_SECTION.LINK_LABEL')}
            </a>
          }
          contentFooter={this.props.showFiles ? this.renderFiles() : null}
        >
          <Row>
            <Col xs={24} md={12}>
              <ViewSectionContentItem
                icon={<LemonIcon name="user" size="xlarge" />}
                title={`${this.props.user?.firstName} ${this.props.user?.lastName}`}
                description={this.props.externalEducationApplication?.employmentStatus?.name ? this.props.translate(`REQUEST.USER_VIEW_SECTION.${this.props.externalEducationApplication.employmentStatus?.name}_LABEL`) : undefined}
              />
              {this.props.user.dateOfBirth && <ViewSectionContentItem icon={<LemonIcon name="birthday" size="xlarge" />} title={DateUtils.toViewDateFormat(this.props.user.dateOfBirth, 'dateWithYear')} description={this.props.translate('USER_FORM.DATE_OF_BIRTH_LABEL')} />}

              <ViewSectionContentItem icon={<LemonIcon name="mail" size="xlarge" />} title={this.getContactEmail(this.props.user?.contactData)} description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMAIL_LABEL')} />
              <ViewSectionContentItem icon={<LemonIcon name="star" size="xlarge" />} title={this.props.user?.identificationNumber ?? this.props.translate('REQUEST.USER_VIEW_SECTION.IDENTIFICATION_NUMBER_MISSING_LABEL')} description={this.props.translate('REQUEST.USER_VIEW_SECTION.IDENTIFICATION_NUMBER_LABEL')} />

              <ViewSectionContentItem icon={<LemonIcon name="address" size="xlarge" />} title={AddressUtils.getFullAddress(this.props.user.residenceAddress?.address)} description={this.props.translate(`REQUEST.USER_VIEW_SECTION.RESIDENCE_TYPE.${this.props.user.residenceAddress?.type.id}_LABEL`)} />

              {this.props.user.educationData?.educationalProgram && this.props.user.educationData?.educationalInstitution && (
                <React.Fragment>
                  <ViewSectionContentItem icon={<LemonIcon name="education" size="xlarge" />} title={`${this.props.user.educationData?.educationalProgram}, ${this.props.user.educationData?.educationalInstitution}`} description={this.props.translate('REQUEST.USER_VIEW_SECTION.EDUCATION_LABEL')} />
                  <ViewSectionContentItem
                    icon={<LemonIcon name="education" size="xlarge" />}
                    title={this.props.user.extendedData?.internationalQualificationsAccepted ? this.props.translate('COMMON.ACTION_YES') : this.props.translate('COMMON.ACTION_NO')}
                    description={this.props.translate('USER_FORM.INTERNATIONAL_QUALIFICATIONS_ACCEPTED_LABEL')}
                  />
                </React.Fragment>
              )}

              {this.props.user.workData && (
                <React.Fragment>
                  <ViewSectionContentItem icon={<LemonIcon name="workposition" size="xlarge" />} title={totalEmploymentDuration.years()} description={`${this.props.translate('USER_FORM.TOTAL_EMPLOYMENT_DURATION_LABEL')} - ${this.props.translate('COMMON.LABEL.PERIOD.YEARS')}`} />
                  <ViewSectionContentItem icon={<LemonIcon name="workposition" size="xlarge" />} title={totalEmploymentDuration.months()} description={`${this.props.translate('USER_FORM.TOTAL_EMPLOYMENT_DURATION_LABEL')} - ${this.props.translate('COMMON.LABEL.PERIOD.MONTHS')}`} />
                  <ViewSectionContentItem icon={<LemonIcon name="workposition" size="xlarge" />} title={totalEmploymentDuration.days()} description={`${this.props.translate('USER_FORM.TOTAL_EMPLOYMENT_DURATION_LABEL')} - ${this.props.translate('COMMON.LABEL.PERIOD.DAYS')}`} />
                </React.Fragment>
              )}

              {this.props.externalEducationApplication && this.props.externalEducationApplication.employmentStatus?.id === EmploymentStatusEnum.UNEMPLOYED && userPaymentInfo && (
                <React.Fragment>
                  <ViewSectionContentItem icon={<LemonIcon name="building" size="xlarge" />} title={userPaymentInfo.bankName} description={this.props.translate('REQUEST.USER_VIEW_SECTION.BANK_NAME_LABEL')} />
                  <ViewSectionContentItem icon={<LemonIcon name="moneyCheck" size="xlarge" />} title={userPaymentInfo.iban} description={this.props.translate('REQUEST.USER_VIEW_SECTION.IBAN_LABEL')} />
                </React.Fragment>
              )}
            </Col>

            <Col xs={24} md={12}>
              {this.props.externalEducationApplication && this.props.externalEducationApplication.employmentStatus?.id === EmploymentStatusEnum.EMPLOYED && (
                <React.Fragment>
                  <ViewSectionContentItem
                    icon={<LemonIcon name="suitcase" size="xlarge" />}
                    title={this.props.externalEducationApplication?.employerInformation?.name ?? this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_NO_NAME_LABEL')}
                    description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_NAME_LABEL')}
                  />
                  <ViewSectionContentItem
                    icon={<LemonIcon name="star" size="xlarge" />}
                    title={this.props.externalEducationApplication?.employerInformation?.identificationNumber ?? this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_NO_IBAN_LABEL')}
                    description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_OIB_LABEL')}
                  />
                  <ViewSectionContentItem icon={<LemonIcon name="mail" size="xlarge" />} title={this.getContactEmail(this.props.externalEducationApplication?.employerInformation?.contactData)} description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_EMAIL_LABEL')} />
                  <ViewSectionContentItem icon={<LemonIcon name="address" size="xlarge" />} title={AddressUtils.getFullAddress(this.props.externalEducationApplication.employerInformation?.address)} description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_ADDRESS_LABEL')} />
                </React.Fragment>
              )}

              {this.props.showNotzData && (
                <React.Fragment>
                  <ViewSectionContentItem
                    icon={<LemonIcon name="star" size="xlarge" />}
                    title={DateUtils.toViewDateFormat(this.props.user.extendedData?.notzSyncDateTime, 'dateWithTime') ?? this.props.translate('COMMON.LABEL.MISSING_DATA')}
                    description={this.props.translate('REQUEST.USER_VIEW_SECTION.NOTZ_SYNC_DATE_TIME_LABEL')}
                  />
                  <ViewSectionContentItem
                    icon={<LemonIcon name="star" size="xlarge" />}
                    title={DateUtils.toViewDateFormat(this.props.user.extendedData?.notzLastCheckInDate, 'dateWithTime') ?? this.props.translate('COMMON.LABEL.MISSING_DATA')}
                    description={this.props.translate('REQUEST.USER_VIEW_SECTION.NOTZ_LAST_CHECK_IN_DATE_LABEL')}
                  />
                  <ViewSectionContentItem
                    icon={<LemonIcon name="star" size="xlarge" />}
                    title={DateUtils.toViewDateFormat(this.props.user.extendedData?.notzLastCheckOutDate, 'dateWithTime') ?? this.props.translate('COMMON.LABEL.MISSING_DATA')}
                    description={this.props.translate('REQUEST.USER_VIEW_SECTION.NOTZ_LAST_CHECK_OUT_DATE_LABEL')}
                  />
                  <ViewSectionContentItem icon={<LemonIcon name="star" size="xlarge" />} title={this.props.user.extendedData?.notzStatus ?? this.props.translate('COMMON.LABEL.MISSING_DATA')} description={this.props.translate('REQUEST.USER_VIEW_SECTION.NOTZ_STATUS_LABEL')} />
                </React.Fragment>
              )}
            </Col>
          </Row>
        </ViewSectionItem>
      </React.Fragment>
    );
  };

  renderFiles = () => {
    return (
      <React.Fragment>
        <Row justify="space-between">
          <Col>{this.props.translate('REQUEST.USER_VIEW_SECTION.FOOTER_TITLE')}</Col>
        </Row>
        <EducationApplicationFileListView files={this.props.files} displayTypes={['CURRICULUM_VITAE', 'ENROLLMENT_REQUIREMENT']} />
      </React.Fragment>
    );
  };

  getContactEmail = (contactData?: IContactData[]) => {
    const email = contactData && contactData.find((data) => data.type.id === ContactDataTypeEnum.EMAIL);

    if (email) {
      return email.value;
    } else {
      return this.props.translate('REQUEST.USER_VIEW_SECTION.EMAIL_MISSING_LABEL');
    }
  };

  getConsultationContact = (contactData?: IContactData) => {
    return contactData?.value;
  };
}

export default withLocalize<IEducationApplicationUserViewSectionOwnProps>(EducationApplicationUserViewSection as any);

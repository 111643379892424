import UserActivityList from '@src/components/activity/list/UserActivityList';
import ListPagination from '@src/components/common/list/ListPagination';
import { IUserActivity } from '@src/model/activity/UserActivity';
import { ICollectionData } from '@src/service/business/common/types';
import userActivityListBusinessStore, { IUserActivityListFilter } from '@src/service/business/user/userActivityListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

interface IUserProfileActivityListContainerOwnProps {
  userId: string;
}

interface IUserProfileActivityListContainerStateProps {
  userActivityList: ICollectionData<IUserActivity>;
  userActivityListFilter: IUserActivityListFilter;
}

interface IUserProfileActivityListContainerDispatchProps {
  fetchList: (listFilter: IUserActivityListFilter, page: number, size: number, sort: string[]) => void;
  clearList: () => void;
}
type IUserProfileActivityListContainerProps = IUserProfileActivityListContainerOwnProps & IUserProfileActivityListContainerStateProps & IUserProfileActivityListContainerDispatchProps;

interface IUserProfileActivityListContainerState {
  page: number;
  size: number;
  sort: string[];
}
// -- Component
// ----------

class UserProfileActivityListContainer extends React.Component<IUserProfileActivityListContainerProps, IUserProfileActivityListContainerState> {
  state: IUserProfileActivityListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: [],
  };

  componentDidMount = () => {
    this.updateUserActivityList();
  };

  componentDidUpdate = (prevProps: IUserProfileActivityListContainerProps, prevState: IUserProfileActivityListContainerState) => {
    if (this.state !== prevState) {
      this.updateUserActivityList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearList();
  };

  render = () => {
    return (
      <React.Fragment>
        {this.props.userActivityList && <ListPagination page={this.props.userActivityList.page} onChange={this.handlePageChange} />}
        <UserActivityList userActivities={this.props.userActivityList ? this.props.userActivityList.content : []} />
        {this.props.userActivityList && <ListPagination page={this.props.userActivityList.page} onChange={this.handlePageChange} />}
      </React.Fragment>
    );
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  private updateUserActivityList = () => {
    this.props.fetchList(this.props.userActivityListFilter, this.state.page, this.state.size, this.state.sort);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserProfileActivityListContainerStateProps => ({
  userActivityList: userActivityListBusinessStore.selectors.getList(state),
  userActivityListFilter: userActivityListBusinessStore.selectors.getListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserProfileActivityListContainerDispatchProps => ({
  fetchList: (listFilter: IUserActivityListFilter, page: number, size: number, sort: string[]) => dispatch(userActivityListBusinessStore.actions.fetchList(listFilter, page, size, sort)),
  clearList: () => dispatch(userActivityListBusinessStore.actions.clearList()),
});

export default connect<IUserProfileActivityListContainerStateProps, IUserProfileActivityListContainerDispatchProps, IUserProfileActivityListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(UserProfileActivityListContainer);

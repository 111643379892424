import UserProfileActivityListContainer from '@src/components/activity/UserProfileActivityListContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ExternalEducationTemplateListContainer from '@src/components/externaleducation/ExternalEducationTemplateListContainer';
import EducationApplicationListContainer from '@src/components/externalEducationApplication/list/EducationApplicationListContainer';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import UserDocumentsListContainer from '@src/components/user/list/UserDocumentsListContainer';
import UserOrganizationMembershipContainer from '@src/components/user/list/UserOrganizationMembershipContainer';
import UserSkillEvaluationListContainer from '@src/components/user/list/UserSkillEvaluationListContainer';
import UserAdditionalInfoView from '@src/components/user/view/UserAdditionalInfoView';
import UserCourseListContainer from '@src/components/user/view/UserCourseListContainer';
import NoteListViewContainer from '@src/components/user/view/NoteListViewContainer';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { Tabs } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { UserNoteUtils } from '@src/components/usernote/utils/UserNoteUtils';

enum UserViewContainerTabMenuKeys {
  Courses = 'courses',
  Activities = 'activities',
  Documents = 'documents',
  EducationApplications = 'educationapplications',
  Skills = 'skills',
  Organizations = 'organizations',
  ExternalEducationTemplates = 'externaleducationtemplates',
  AdditionalUserInfo = 'additionaluserinfo',
  Notes = 'notes',
}

interface IUserViewTabsOwnProps {
  userId: string;
  user: IUserInfo;
  openedTab: string;
  showActivitiesTab: boolean;
  showDocumentsTab: boolean;
  showEducationApplicationTab: boolean;
  showSkillTab: boolean;
  showOrganizationTab: boolean;
  canAddNote: boolean;
  canSetPrivateNote: boolean;
  workPositionList: IWorkPosition[];
  showExternalEducationTemplatesTab: boolean;
  isOwnProfile: boolean;
  onTabChange?: (key: string) => void;
  onNotzUpdate?: () => void;
}

type IUserViewTabsProps = IUserViewTabsOwnProps & IWithLocalizeOwnProps & WithRouterProps & IWithRolesOwnProps & IWithTenantPropEnabledOwnProps;

class UserViewTabs extends React.Component<IUserViewTabsProps> {
  render = () => {
    const coordinatorEducationApplicationTabLabel = this.props.isOwnProfile ? this.props.translate('USER_VIEW.COORDINATOR_EDUCATION_APPLICATION_TAB_LABEL') : this.props.translate('USER_VIEW.COORDINATOR_TRAINEE_EDUCATION_APPLICATION_TAB_LABEL');
    return (
      <div className="timun-userView__tabContainer">
        <Tabs activeKey={this.props.openedTab} onChange={this.props.onTabChange} destroyInactiveTabPane={true}>
          {this.props.isTenantPropEnabled('admin_activities') && this.props.showActivitiesTab && (
            <Tabs.TabPane tab={this.props.translate('USER_VIEW.ACTIVITIES_TAB_LABEL')} key={UserViewContainerTabMenuKeys.Activities}>
              <div className="panel timun-userView__tab">
                <UserProfileActivityListContainer userId={this.props.userId} />
              </div>
            </Tabs.TabPane>
          )}

          {this.props.isTenantPropEnabled('courses') && (
            <Tabs.TabPane tab={this.props.translate('USER_VIEW.ENROLLED_COURSES_TAB_LABEL')} key={UserViewContainerTabMenuKeys.Courses}>
              <div className="panel timun-userView__tab">{this.props.user && <UserCourseListContainer user={this.props.user} />}</div>
            </Tabs.TabPane>
          )}

          {this.props.isTenantPropEnabled('externaleducationapplications') && this.props.showEducationApplicationTab && (
            <Tabs.TabPane tab={this.props.isInRoles([UserRoleEnum.COORDINATOR]) ? coordinatorEducationApplicationTabLabel : this.props.translate('USER_VIEW.TRAINEE_EDUCATION_APPLICATION_TAB_LABEL')} key={UserViewContainerTabMenuKeys.EducationApplications}>
              <div className="panel timun-userView__tab">
                <EducationApplicationListContainer user={this.props.user} scrollable={true} showEducationApplicationStatusFilter={true} />
              </div>
            </Tabs.TabPane>
          )}

          {this.props.showDocumentsTab && (
            <Tabs.TabPane tab={this.props.translate('USER_VIEW.DOCUMENTS_TAB_LABEL')} key={UserViewContainerTabMenuKeys.Documents}>
              <div className="panel timun-userView__tab">
                <UserDocumentsListContainer userId={this.props.userId} />
              </div>
            </Tabs.TabPane>
          )}

          {this.props.showSkillTab && (
            <Tabs.TabPane tab={this.props.translate('USER_VIEW.SKILLS_TAB_LABEL')} key={UserViewContainerTabMenuKeys.Skills}>
              <div className="panel timun-userView__tab">
                <UserSkillEvaluationListContainer user={this.props.user} workPositionList={this.props.workPositionList} />
              </div>
            </Tabs.TabPane>
          )}
          {this.props.isTenantPropEnabled('organizations') && this.props.showOrganizationTab && (
            <Tabs.TabPane tab={this.props.translate('USER_VIEW.BUSINESS_PROFILES_TAB_LABEL')} key={UserViewContainerTabMenuKeys.Organizations}>
              <div className="panel timun-userView__tab">
                <UserOrganizationMembershipContainer userId={this.props.userId} />
              </div>
            </Tabs.TabPane>
          )}
          {this.props.isTenantPropEnabled('externaleducations') && this.props.showExternalEducationTemplatesTab && (
            <Tabs.TabPane tab={this.props.translate('USER_VIEW.EXTERNAL_EDUCATION_TEMPLATES_TAB_LABEL')} key={UserViewContainerTabMenuKeys.ExternalEducationTemplates}>
              <div className="panel timun-userView__tab">
                <ExternalEducationTemplateListContainer userId={this.props.userId} />
              </div>
            </Tabs.TabPane>
          )}

          {this.props.isTenantPropEnabled('externaleducations') && (
            <Tabs.TabPane tab={this.props.translate('USER_VIEW.ADDITIONAL_USER_INFO_TAB_LABEL')} key={UserViewContainerTabMenuKeys.AdditionalUserInfo}>
              <div className="panel timun-userView__tab">
                <UserAdditionalInfoView onNotzUpdate={this.props.onNotzUpdate} user={this.props.user} />
              </div>
            </Tabs.TabPane>
          )}

          {!this.props.isTenantPropEnabled('externaleducationapplications') && (
            <Tabs.TabPane tab={this.props.translate('NOTE_LIST.MY_NOTES_LABEL')} key={UserViewContainerTabMenuKeys.Notes}>
              <div className="panel timun-userView__tab">{this.props.user && <NoteListViewContainer canEditnote={UserNoteUtils.isAuthorCurrentUser} objectId={this.props.userId} type={NoteObjectTypeEnum.USER} canSetPrivateNote={this.props.canSetPrivateNote} canAddNote={this.props.canAddNote} />}</div>
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  };
}

export default withLocalize<IUserViewTabsOwnProps>(withRoles(withRouter(withTenantPropEnabled(UserViewTabs))) as any);

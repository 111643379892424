import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import OrganizationForm from '@src/components/organization/form/OrganizationForm';
import OrganizationView from '@src/components/organization/view/OrganizationView';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IOrganization } from '@src/model/organization/Organization';
import { IUserInfo } from '@src/model/user/User';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import OrganizationBusinessStore from '@src/service/business/organization/OrganizationBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------

// --
// ----- Prop types
interface IOrganizationViewContainerOwnProps {
  organizationId: string;
  openedTab: string;
}

interface IOrganizationViewContainerStateProps {
  currentUser: IUserInfo;
  organization: IOrganization;
}

interface IOrganizationViewContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchOrganization: (id: string) => void;
  clearOrganization: () => void;
  updateOrganization: (org: IOrganization) => ITrackableAction;
  generateOrganizationJoinCode: (id: string) => ITrackableAction;
  joinOrganization: (organizationAccessCode: string) => ITrackableAction;
}

type IOrganizationViewContainerProps = IOrganizationViewContainerOwnProps & IOrganizationViewContainerStateProps & IOrganizationViewContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;
// --
// ----- State types
interface IOrganizationViewContainerState {
  isEditingOrganization: boolean;
}

// --
// ----- Component
class OrganizationViewContainer extends React.Component<IOrganizationViewContainerProps, IOrganizationViewContainerState> {
  state: IOrganizationViewContainerState = {
    isEditingOrganization: false,
  };

  componentDidMount = () => {
    // initial data load
    this.fetchOrganization();
  };

  componentDidUpdate = (prevProps: IOrganizationViewContainerProps, prevState: IOrganizationViewContainerState) => {
    if (this.props.organizationId !== prevProps.organizationId) {
      this.fetchOrganization();
    }
  };

  componentWillUnmount = () => {
    this.props.clearOrganization();
  };

  render = () => {
    return (
      <React.Fragment>
        {this.props.organization && <OrganizationView organization={this.props.organization} toggleEdit={this.toggleOrganizationEdit} openedTab={this.props.openedTab} onEdit={this.handleEdit} onGenerateOrganizationJoinCode={this.handleGenerateCode} onJoinOrganization={this.handleJoinOrganization} />}

        {this.state.isEditingOrganization && <OrganizationForm title={this.props.translate('ORGANIZATION_FORM.TITLE_EDIT')} organization={this.props.organization} onSubmit={this.handleEdit} onCancel={() => this.toggleOrganizationEdit(false)} />}
      </React.Fragment>
    );
  };

  toggleOrganizationEdit = (isEditingOrganization: boolean) => {
    this.setState({
      isEditingOrganization,
    });
  };

  handleEdit = (org: IOrganization) => {
    this.props
      .updateOrganization(org)
      .track()
      .subscribe(() => {
        this.fetchOrganization();
        this.toggleOrganizationEdit(false);
      });
  };

  handleGenerateCode = () => {
    this.props
      .generateOrganizationJoinCode(this.props.organizationId)
      .track()
      .subscribe(() => this.fetchOrganization());
  };

  handleJoinOrganization = (accessCode: string) => {
    this.props
      .joinOrganization(accessCode)
      .track()
      .subscribe(() => {
        this.props.reportMessage({
          message: this.props.translate('ORGANIZATION_VIEW.JOINED_ORGANIZATION_INFO_MESSAGE'),
          type: UserFeedbackMessageType.NOTIFICATION,
          severity: UserFeedbackMessageSeverity.SUCCESS,
        });
        this.fetchOrganization();
      });
  };

  private fetchOrganization = () => {
    this.props.fetchOrganization(this.props.organizationId);
  };
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IOrganizationViewContainerOwnProps): IOrganizationViewContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  organization: OrganizationBusinessStore.selectors.getOrganization(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IOrganizationViewContainerOwnProps): IOrganizationViewContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchOrganization: (id: string) => dispatch(OrganizationBusinessStore.actions.fetchOrganization(id)),
  clearOrganization: () => dispatch(OrganizationBusinessStore.actions.clearOrganization()),
  updateOrganization: (org: IOrganization) => dispatch(createTrackableAction(OrganizationBusinessStore.actions.updateOrganization(org))),
  generateOrganizationJoinCode: (id: string) => dispatch(createTrackableAction(OrganizationBusinessStore.actions.generateOrganizationJoinCode(id))),
  joinOrganization: (organizationAccessCode: string) => createTrackableAction(dispatch(OrganizationBusinessStore.actions.joinOrganization(organizationAccessCode))),
});

export default connect<IOrganizationViewContainerStateProps, IOrganizationViewContainerDispatchProps, IOrganizationViewContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter<IOrganizationViewContainerOwnProps>(withTenantPropEnabled(withRouter(OrganizationViewContainer) as any))));

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import LemonIcon from '@src/components/common/image/LemonIcon';
import UserGroupDataPicker from '@src/components/common/datapicker/UserGroupDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { UserStatusEnum } from '@src/model/user/UserStatus';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const apiDateFormat = AppConfigService.getValue('dateTimeFormat.backendDate');

const LAYOUT_FILTER = {
  xs: 24,
  sm: 12,
  md: 6,
};

const FormItem = Form.Item;

// --
// ----- Prop types

interface IUsersViewOwnProps {
  filter?: IUserListFilter;
  additionalSearchParams: boolean;
  onChange: (listFilter: IUserListFilter) => void;
  showUserGroupParams?: boolean;
}

type IUsersViewProps = IUsersViewOwnProps & IWithLocalizeOwnProps & FormComponentProps;

const UserListFilter: React.FC<IUsersViewProps> = (props: IUsersViewProps) => {
  const [form] = Form.useForm<IUserListFilter>();

  useEffect(() => {
    form.resetFields();
  }, [props.filter]);

  const handleResetFilter = useCallback(() => {
    props.onChange?.({});
  }, [props.onChange]);

  const handleFinish = useCallback(
    (values: IUserListFilter) => {
      const listFilter: IUserListFilter = {
        ...values,
        assignedDateFrom: values.assignedDateFrom
          ? moment(values.assignedDateFrom)
              .startOf('day')
              .format(apiDateFormat)
              .toString()
          : undefined,
        assignedDateTo: values.assignedDateTo
          ? moment(values.assignedDateTo)
              .endOf('day')
              .format(apiDateFormat)
              .toString()
          : undefined,
      };

      props.onChange?.(listFilter);
    },
    [moment, props.onChange]
  );

  const UserRoleOptions = useMemo(() => Object.keys(UserRoleEnum).map((item: any) => ({ value: UserRoleEnum[item as keyof typeof UserRoleEnum], key: UserRoleEnum[item as keyof typeof UserRoleEnum], label: props.translate(`USER_FORM.ROLE_${item}_LABEL`) })), [UserRoleEnum, props.translate]);
  const UserStatusOptions = useMemo(() => Object.keys(UserStatusEnum).map((item: any) => ({ value: UserStatusEnum[item as keyof typeof UserStatusEnum], key: UserStatusEnum[item as keyof typeof UserStatusEnum], label: props.translate(`USER_FORM.STATUS_${item}_LABEL`) })), [UserStatusEnum, props.translate]);

  return (
    <Form<IUserListFilter>
      layout="vertical"
      form={form}
      initialValues={{ ...props.filter, assignedDateFrom: props.filter?.assignedDateFrom != null ? moment(props.filter?.assignedDateFrom) : undefined, assignedDateTo: props.filter?.assignedDateTo != null ? moment(props.filter?.assignedDateTo) : undefined }}
      onFinish={handleFinish}
    >
      <Row gutter={12}>
        <Col {...LAYOUT_FILTER}>
          <FormItem name={'name'} label={props.translate('USER_LIST_FILTER.NAME_LABEL')}>
            <Input prefix={<LemonIcon name="search" />} allowClear={true} />
          </FormItem>
        </Col>

        <Col {...LAYOUT_FILTER}>
          <FormItem name={'assignedDateFrom'} label={props.translate('USER_LIST_FILTER.ASSIGNED_DATE_FROM_LABEL')}>
            <DatePicker allowClear={true} format={dateFormat} className="full-width" />
          </FormItem>
        </Col>
        <Col {...LAYOUT_FILTER}>
          <FormItem name={'assignedDateTo'} label={props.translate('USER_LIST_FILTER.ASSIGNED_DATE_TO_LABEL')}>
            <DatePicker allowClear={true} format={dateFormat} className="full-width" />
          </FormItem>
        </Col>

        {props.showUserGroupParams && (
          <Col {...LAYOUT_FILTER}>
            <FormItem name={'userGroups'} label={props.translate('USER_LIST_FILTER.ORGANIZATION_GROUPS_LABEL')}>
              <UserGroupDataPicker />
            </FormItem>
          </Col>
        )}

        {props.additionalSearchParams && (
          <Col {...LAYOUT_FILTER}>
            <FormItem name={'role'} label={props.translate('USER_FORM.ROLES_PLACEHOLDER')}>
              <Select mode="multiple" allowClear={true} options={UserRoleOptions} />
            </FormItem>
          </Col>
        )}

        {props.additionalSearchParams && (
          <Col {...LAYOUT_FILTER}>
            <FormItem name={'status'} label={props.translate('USER_LIST_FILTER.ALL_USERS')}>
              <Select allowClear={true} options={UserStatusOptions} />
            </FormItem>
          </Col>
        )}
      </Row>

      <Row gutter={8}>
        <Col>
          <Button onClick={form.submit} type="primary">
            {props.translate('COMMON.ACTION_FILTER')}
          </Button>
        </Col>
        <Col>
          <Button onClick={handleResetFilter} type="primary">
            {props.translate('COMMON.ACTION_RESET_FILTER')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize<IUsersViewOwnProps>(UserListFilter as any);

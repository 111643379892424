import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { EducationApplicationTimelineUiHelper } from '@src/components/externalEducationApplication/common/EducationApplicationTimelineUiHelper';
import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { Typography } from 'antd';


// -- Prop types
// ----------

export interface IEducationApplicationTimelineItemOwnProps {
  item: IExternalEducationApplicationTimelineActivity;
}
type IEducationApplicationTimelineItemProps = IEducationApplicationTimelineItemOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display activity timeline item content */
const EducationApplicationTimelineItem: React.FC<IEducationApplicationTimelineItemProps> = (props: IEducationApplicationTimelineItemProps) => {

  return (
    <div key={Math.random()} className="timun-educationApplicationTimeline__card">
      <Typography.Title level={4}>{EducationApplicationTimelineUiHelper.getTimelineItemTitle(props.item)}</Typography.Title>
      {EducationApplicationTimelineUiHelper.isTimelineItemNote(props.item) && <div dangerouslySetInnerHTML={{ __html: EducationApplicationTimelineUiHelper.getTimelineItemNoteText(props.item) }} />}
      {EducationApplicationTimelineUiHelper.getTimelineItemContentDescription(props.item) && <Typography.Paragraph type="secondary"> {EducationApplicationTimelineUiHelper.getTimelineItemContentDescription(props.item)} </Typography.Paragraph>}
      <span>{EducationApplicationTimelineUiHelper.getTimelineItemDateTime(props.item)}</span>
    </div>
  );
};

export default withLocalize<IEducationApplicationTimelineItemOwnProps>(EducationApplicationTimelineItem as any);

import AdminCommentListItem from '@src/components/comment/AdminCommentListItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IComment } from '@src/model/comment/Comment';
import { Empty, List } from 'antd';
import React from 'react';

// -- PropTypes
// ----------
export interface IAdminCommentListOwnProps {
  commentList: IComment[];
  onApprove: (comment: IComment) => void;
}

type IAdminCommentListProps = IAdminCommentListOwnProps & IWithLocalizeOwnProps;

// --
// ----- State types
interface IAdminCommentListState {}

// -- Component
// ----------
class AdminCommentList extends React.Component<IAdminCommentListProps, IAdminCommentListState> {
  state = {};

  render() {
    return (
      <React.Fragment>
        <List itemLayout="horizontal" className="" bordered={false} locale={{ emptyText: <Empty description={this.props.translate('COMMENTS_VIEW.NO_DATA')} /> }} dataSource={this.props.commentList} renderItem={(comment: IComment) => <AdminCommentListItem onApprove={this.props.onApprove} comment={comment} />} />
      </React.Fragment>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<IAdminCommentListOwnProps>(AdminCommentList as any);

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import UserForm from '@src/components/user/form/UserForm';
import { IUserInfo } from '@src/model/user/User';
import { IUserRole } from '@src/model/user/UserRole';
import { IWorkData } from '@src/model/user/WorkData';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { Form, Modal } from 'antd';

// -- Prop types
// ----------
interface IUserModalOwnProps {
  value?: IUserInfo;
  workPositionList: IWorkPosition[];
  allowRoleChange: boolean;
  allowUserStatusChange: boolean;
  allowPasswordCreate?: boolean;
  setUserEdit?: (visible: boolean) => void;
  onUserSubmit?: (data: IUserInfo) => void;
}

type IUserModalProps = IUserModalOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------
/** User edit/create modal  */
const UserModal = (props: IUserModalProps) => {
  const [form] = Form.useForm<IUserInfo>();
  const handleCancel = () => props.setUserEdit?.(false);
  const handleSubmit = (values: IUserInfo) => {
    const roles = (values.roles.map((role) => ({ id: role })) as unknown) as IUserRole[]; // TODO: cast
    const dateOfBirth = DateUtils.toBackendDateFormat(values.dateOfBirth, 'backendQueryParamDateFormat');
    const startDate = DateUtils.toBackendDateFormat(values.workData?.startDate, 'backendQueryParamDateFormat');
    const workPosition = values.workData?.workPosition?.id ? { id: values.workData?.workPosition?.id } : undefined;
    const workData = { ...values.workData, workPosition, startDate } as IWorkData; // TODO: cast
    props.onUserSubmit?.({
      ...props.value,
      ...values,
      dateOfBirth,
      roles,
      workData,
    });
  };

  return (
    <React.Fragment>
      <Modal className="lemon-modal__modal--md" visible={true} maskClosable={false} title={props.translate(`USER_FORM.FORM_TITLE_${props.value ? 'EDIT' : 'CREATE'}`)} okText={props.translate(`COMMON.ACTION_SAVE`)} cancelText={props.translate('COMMON.ACTION_CANCEL')} onCancel={handleCancel} onOk={form.submit}>
        <UserForm formRef={form} value={props.value} onFormFinish={handleSubmit} workPositionList={props.workPositionList} allowPasswordCreate={props.allowPasswordCreate} allowRoleChange={props.allowRoleChange} />
      </Modal>
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------
export default withRoles(withLocalize<IUserModalOwnProps>(UserModal as any));

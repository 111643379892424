export interface IActivityPhase {
  id: ActivityPhaseEnum;
  name: string;
  description: string;
}

export enum ExamActivityPhaseEnum {
  EXAM_TEMPLATE_CREATION = '1',
  FINISHED = '2',
  ARCHIVED = '3',
  NOT_STARTED = '4',
  READY = '5',
  ANSWERING_STARTED = '6',
  ANSWERS_SUBMITTED = '7',
  IN_EVALUATION = '8',
  EVALUATED = '9',
  NEVER_STARTED = '10',
  CANCELLED = '11',
}

export enum EducationApplicationActivityPhaseEnum {
  CONSULTATION_REQUESTED = '12',
  CONSULTATION_STARTED = '13',
  CONSULTATION_TERMINATED = '14',
  EXTERNAL_EDUCATION_APPLICATION_CREATION = '15',
  SUBMITTED = '16',
  AWAITING_APPROVAL = '17',
  USER_CANCELED = '18',
  RETURNED_TO_USER = '19',
  EXTERNAL_EDUCATION_APPLICATION_DENIED = '20',
  EXTERNAL_EDUCATION_TEMPLATE_SELECTION = '21',
  CONTRACT_DATA_PROCESSING = '22',
  CONTRACT_GENERATION = '23',
  AWAITING_SIGNED_CONTRACT = '24',
  AWAITING_PAYMENT = '25',
  EDUCATION_IN_PROGRESS = '26',
  CONTRACT_ANNEX_REQUESTED = '27',
  CONTRACT_ANNEX_SUBMITTED = '28',
  CONTRACT_TERMINATED = '29',
  EDUCATION_COMPLETED = '30',
}

export enum ExternalEducationTemplateActivityPhaseEnum {
  APPROVAL_REQUESTED = '31',
  APPROVAL_IN_PROCESS = '32',
  CHANGE_REQUESTED = '33',
  CHANGE_SUBMITTED = '34',
  APPROVED = '35',
  EXTERNAL_EDUCATION_TEMPLATE_DENIED = '36',
  CANCELED = '37',
}

export const ActivityPhaseEnum = { ...ExamActivityPhaseEnum, ...EducationApplicationActivityPhaseEnum, ...ExternalEducationTemplateActivityPhaseEnum };

export type ActivityPhaseEnum = ExamActivityPhaseEnum | EducationApplicationActivityPhaseEnum | ExternalEducationTemplateActivityPhaseEnum;

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IProgressTracker, ProgressTrackerTypeEnum } from '@src/model/progresstracker/ProgressTracker';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import ProgressTrackerListBuisnessStore, { IProgressTrackerListFilter } from '@src/service/business/progresstracker/ProgressTrackerListBuisnessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import { IFile } from '@src/model/file/File';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import UserListBusinessStore from '@src/service/business/user/userListBusinessStore';
import AdminProgressTrackerListView from '@src/components/user/list/AdminProgressTrackerListView';

const PROGRESS_TRACKER_LIST_VIEW_NAME = '@@PROGRESS_TRACKER_LIST_CONTAINER';
const DEFAULT_PAGE_SIZE: number = AppConfigService.getValue('api.paging.defaultPageSize');

export interface IAdminProgressTrackerListContainerOwnProps {
  type: ProgressTrackerTypeEnum;
}

export interface IAdminProgressTrackerListContainerStateProps {
  progressTrackerList: ICollectionData<IProgressTracker>;
}

export interface IAdminProgressTrackerListContainerDispatchProps {
  fetchProgressTrackerList: (params: ICollectionFetchPayload<IProgressTrackerListFilter>) => void;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  importHrnetUserList: (data: IFile) => ITrackableAction;
}

type IAdminProgressTrackerListContainerProps = IAdminProgressTrackerListContainerOwnProps & IAdminProgressTrackerListContainerStateProps & IAdminProgressTrackerListContainerDispatchProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

const AdminProgressTrackerListContainer: React.FC<IAdminProgressTrackerListContainerProps> = (props: IAdminProgressTrackerListContainerProps) => {
  const handleProgressTrackerTypeUpdate = () => {
    onUpdateList(props.fetchProgressTrackerList, { ...collectionParams.filter, type: props.type });
  };

  const [isImportModalVisible, setIsImportModalVisible] = useState<boolean>(false);
  const initialCollectionValues = useMemo(() => ({ page: 0, size: DEFAULT_PAGE_SIZE, sort: [] }), []);
  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IProgressTrackerListFilter>(
    {
      viewName: PROGRESS_TRACKER_LIST_VIEW_NAME,
      updateFn: handleProgressTrackerTypeUpdate,
      initialValues: initialCollectionValues,
    },
    [props.type]
  );

  const handleHrnetUserListUpload = useCallback(
    (data: IFile) => {
      props
        .importHrnetUserList(data)
        .track()
        .subscribe(() => {
          props.reportMessage({ message: props.translate('USER_VIEW_CONTAINER.IMPORT_USERS_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        });
    },
    [props.importHrnetUserList, props.reportMessage]
  );

  const handleUserImportListSubmit = useCallback(
    (data: IFile) => {
      handleHrnetUserListUpload?.(data);
      setIsImportModalVisible(true);
    },
    [handleHrnetUserListUpload, setIsImportModalVisible]
  );

  return (
    <React.Fragment>
      <AdminProgressTrackerListView
        onSubmit={handleUserImportListSubmit}
        showImportModal={() => setIsImportModalVisible(true)}
        onClose={() => setIsImportModalVisible(false)}
        progressTrackerList={props.progressTrackerList}
        onPageChange={updateCollectionParams.onPageChange}
        isImportModalVisible={isImportModalVisible}
        type={props.type}
        onReload={onUpdateList}
      />
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdminProgressTrackerListContainerOwnProps): IAdminProgressTrackerListContainerStateProps => ({
  progressTrackerList: ProgressTrackerListBuisnessStore.selectors.getProgressTrackerList(state),
});
// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IAdminProgressTrackerListContainerOwnProps): IAdminProgressTrackerListContainerDispatchProps => ({
  fetchProgressTrackerList: (params: ICollectionFetchPayload<IProgressTrackerListFilter>) => dispatch(ProgressTrackerListBuisnessStore.actions.fetchProgressTrackerList(params)),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  importHrnetUserList: (data: IFile) => dispatch(createTrackableAction(UserListBusinessStore.actions.importHrnetUserList(data))),
});

export default connect<IAdminProgressTrackerListContainerStateProps, IAdminProgressTrackerListContainerDispatchProps, IAdminProgressTrackerListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter<IAdminProgressTrackerListContainerOwnProps>(AdminProgressTrackerListContainer as any)));

import React from 'react';

import FilePreviewModal from '@src/components/common/file/FilePreviewModal';
import FileUtils from '@src/components/common/file/FileUtils';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import FileUpload from '@src/components/common/upload/FileUpload';
import { IFile } from '@src/model/file/File';
import { ITimunFile } from '@src/service/business/files/util';
import { UploadFile } from 'antd/lib/upload/interface';

// -- Prop types
// ----------

export interface IUploadedFileListOwnProps {
  fileList: IFile[];
}
type IUploadedFileListProps = IUploadedFileListOwnProps & IWithLocalizeOwnProps;

interface IUploadedFileListState {
  fileToDisplay?: UploadFile;
}


// -- Component
// ----------

/** File list component with file preview ... */
class UploadedFileList extends React.Component<IUploadedFileListProps, IUploadedFileListState> {
  state: IUploadedFileListState = {
    fileToDisplay: undefined,
  };

  render = () => {
    const fileList: ITimunFile[] = FileUtils.mapToTimunFileList(this.props.fileList);

    return (
      <React.Fragment>
        <FileUpload
          uploadProps={{ onPreview: this.toggleImagePreviewVisible, showUploadList: { showRemoveIcon: false, showPreviewIcon: true, showDownloadIcon: true }, fileList }}
        />
        {this.state.fileToDisplay && <FilePreviewModal closeModal={this.handleClosePreview} record={this.state.fileToDisplay} />}
      </React.Fragment>
    );
  };

  toggleImagePreviewVisible = (_file: ITimunFile) => {
    if (FileUtils.isFilePreviewable(_file.type)) {
      this.setState({ fileToDisplay: _file });
    }
  };

  handleClosePreview = () => {
    this.setState({
      fileToDisplay: undefined,
    });
  };

}

export default withLocalize<IUploadedFileListOwnProps>(UploadedFileList as any);

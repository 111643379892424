// tslint:disable-next-line:no-submodule-imports
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseDataForm from '@src/components/course/create/CourseDataForm';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ICourseLectureGroup, ICourseLectureListElement } from '@src/model/course/LectureGroup';
import { ICourseUpdate } from '@src/service/business/courses/courseUpdateBusinessStore';

import { Button, Row, Tabs, Form } from 'antd';
import React, { useCallback, useMemo } from 'react';
import CourseLectureListForm from '@src/components/course/update/CourseLectureListForm';

const TabPane = Tabs.TabPane;

// -- Prop types
// ----------

export interface ICourseUpdateFormOwnProps {
  course: ICourse;
  courseGroups: ICourseGroup[];
  lectureList: ICourseLectureListElement[];
  canDeleteCourse: boolean;
  canEditStructure: boolean;
  onCourseUpdate: (data: ICourseUpdate) => void;
  onCourseDelete: (data: ICourse) => void;
  onLectureGroupAdd: (title: string) => void;
  onLectureGroupUpdate: (data: ICourseLectureGroup) => void;
  onLectureGroupDelete: (data: ICourseLectureGroup) => void;
  onEditingChange: (editing: boolean) => void;
}

type ICourseUpdateFormProps = ICourseUpdateFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------

/** Update form for course data and lecture groups */
const CourseUpdateForm: React.FC<ICourseUpdateFormProps> = (props: ICourseUpdateFormProps) => {
  const [form] = Form.useForm<ICourse>();

  const handleCancel = useCallback(() => {
    if (form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: () => {
          props.onEditingChange(false);
        },
        title: props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      props.onEditingChange(false);
    }
  }, [props.onEditingChange, form.isFieldsTouched]);

  const handleCourseDataSubmit = useCallback(
    (data: ICourse) => {
      props.onCourseUpdate(data);
      props.onEditingChange(false);
    },
    [props.onCourseUpdate, props.onEditingChange]
  );

  const isInCreation = useMemo(() => CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.IN_CREATION]), [props.course, CourseHelperUtils.isCourseInStatus]);

  return (
    <React.Fragment>
      <Tabs className="timun-courseView__lecture-content">
        <TabPane tab={<span data-test-id="timun-courseUpdateForm_tabs_about">{props.translate('COURSE_VIEW.UPDATE.ABOUT_COURSE_TAB_TITLE')}</span>} key="1">
          <CourseDataForm course={props.course} courseGroups={props.courseGroups} canDeleteCourse={props.canDeleteCourse} onUpdate={handleCourseDataSubmit} onCancel={handleCancel} onDelete={props.onCourseDelete} isInCreation={isInCreation} />
        </TabPane>

        <TabPane tab={<span data-test-id="timun-courseUpdateForm_tabs_lectureGroups">{props.translate('COURSE_VIEW.UPDATE.LECTURE_GROUP_TITLE')}</span>} key="2">
          <Row justify="end">
            <Button onClick={handleCancel} data-test-id="timun-courseUpdateForm-aboutCourse_cancelButton">
              {props.translate('COMMON.ACTION_CLOSE')}
            </Button>
          </Row>
          <CourseLectureListForm lectureList={props.lectureList} canEditStructure={props.canEditStructure} onLectureGroupUpdate={props.onLectureGroupUpdate} onLectureGroupDelete={props.onLectureGroupDelete} onLectureGroupAdd={props.onLectureGroupAdd} />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

export default withLocalize<ICourseUpdateFormOwnProps>(CourseUpdateForm as any);

import { IEducationSector } from '@src/model/educationsector/EducationSector';
import { ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IOrganization } from '@src/model/organization/Organization';
import { IUserInfo } from '@src/model/user/User';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { IEducationCategory } from '@src/model/educationcategory/EducationCategory';
import { ExternalEducationTemplateStatusEnum, IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICity } from '@src/model/location/City';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { transformExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateBusinessStoreUtil';
import { RequiredBy } from '@src/service/util/lang/type';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface IExternalEducationTemplateListFilter {
  educationProvider?: RequiredBy<Partial<IOrganization>, 'id'>;
  county?: RequiredBy<Partial<IEducationCategory>, 'id'>;
  city?: RequiredBy<Partial<ICity>, 'id'>;
  title?: string;
  skills?: string[];
  evaluator?: RequiredBy<Partial<IUserInfo>, 'id'>;
  educationSector?: RequiredBy<Partial<IEducationSector>, 'id'>;
  educationCategories?: string[];
  status?: ExternalEducationTemplateStatusEnum;
  minDuration?: number;
  maxDuration?: number;
  enrollmentRequirements?: string[];
  approvalPhase?: ExternalEducationTemplateActivityPhaseEnum;
  withInstanceLocationCheck?: boolean;
}

// List filter ID
const EXTERNAL_EDUCATION_TEMPLATE_LIST_FILTER = '@@EXTERNAL_EDUCATION_TEMPLATE_LIST_FILTER';

// -
// -------------------- Selectors

const getExternalEducationTemplateList = (store: any): ICollectionData<IExternalEducationTemplate> => store.externalEducationTemplateList;
const getExternalEducationTemplateListFilter = (store: any): IExternalEducationTemplateListFilter => ListFilterBusinessStore.selectors.getListFilter(store, EXTERNAL_EDUCATION_TEMPLATE_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_EDUCATION_TEMPLATE_LIST_FETCH: 'EXTERNAL_EDUCATION_TEMPLATE_LIST_FETCH',
  EXTERNAL_EDUCATION_TEMPLATE_LIST_LOAD: 'EXTERNAL_EDUCATION_TEMPLATE_LIST_LOAD',
  EXTERNAL_EDUCATION_TEMPLATE_LIST_CLEAR: 'EXTERNAL_EDUCATION_TEMPLATE_LIST_CLEAR',
};

const fetchExternalEducationTemplateList = (payload: ICollectionFetchPayload<IExternalEducationTemplateListFilter>): IPayloadAction<ICollectionFetchPayload<IExternalEducationTemplateListFilter>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_LIST_FETCH,
    payload,
  };
};

const loadExternalEducationTemplateList = (data: ICollectionData<IExternalEducationTemplate>): IPayloadAction<ICollectionData<IExternalEducationTemplate>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_LIST_LOAD,
    payload: data,
  };
};

const clearExternalEducationTemplateList = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_LIST_CLEAR,
  };
};

const storeExternalEducationTemplateListFilter = (externalEducationFilter: IExternalEducationTemplateListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(EXTERNAL_EDUCATION_TEMPLATE_LIST_FILTER, externalEducationFilter);
};

const clearExternalEducationTemplateListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(EXTERNAL_EDUCATION_TEMPLATE_LIST_FILTER);
};

// -
// -------------------- Side-effects

const fetchExternalEducationTemplateListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IExternalEducationTemplateListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = {
        // first copy entire payload
        ...action.payload,
        // then override filter with transformed value
        filter: action.payload.filter ? transformExternalEducationTemplateListFilter(action.payload.filter) : undefined,
      };

      return EntityApiServiceRegistry.getService('ExternalEducationTemplate')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationTemplateList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXTERNAL_EDUCATION_TEMPLATE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching external education template list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const externalEducationTemplateList = (state: ICollectionData<IExternalEducationTemplate> | null = null, action: IPayloadAction<ICollectionData<IExternalEducationTemplate>>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const ExternalEducationTemplateListBusinessStore = {
  actions: {
    fetchExternalEducationTemplateList,
    clearExternalEducationTemplateList,
    storeExternalEducationTemplateListFilter,
    clearExternalEducationTemplateListFilter,
  },

  selectors: {
    getExternalEducationTemplateList,
    getExternalEducationTemplateListFilter,
  },

  effects: {
    fetchExternalEducationTemplateListEffect,
  },

  reducers: {
    externalEducationTemplateList,
  },
};

// --
// export business store
export default ExternalEducationTemplateListBusinessStore;

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import { TreeSelect } from 'antd';

import { IReference } from '@src/model/common/Reference';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import UserGroupListBusinessStore from '@src/service/business/usergroup/UserGroupListBusinessStore';
import { DataNode } from 'rc-tree/lib/interface';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface IUserGroupHierarchyDataPickerOwnProps {
  value?: IReference<string>;
  disabled?: boolean;
  onChange?: (newValue?: IUserGroup) => void;
}

export interface IUserGroupHierarchyDataPickerStateProps {
  userGroupHierarchy: IUserGroupHierarchy;
}
export interface IUserGroupHierarchyDataPickerDispatchProps {
  fetchUserGroupHierarchy: () => void;
}

type IUserGroupHierarchyDataPickerProps = IUserGroupHierarchyDataPickerOwnProps & IUserGroupHierarchyDataPickerDispatchProps & IUserGroupHierarchyDataPickerStateProps & IWithLocalizeOwnProps;

const UserGroupHierarchyDataPicker: React.FC<IUserGroupHierarchyDataPickerProps> = (props: IUserGroupHierarchyDataPickerProps) => {
  useEffect(() => {
    props.fetchUserGroupHierarchy();
  }, [props.fetchUserGroupHierarchy]);

  const handleDataPickerChange = useCallback(
    (value: string, labelList: React.ReactNode[], extra: { triggerNode: { props: { data?: IUserGroup } } }) => {
      if (props.onChange && extra.triggerNode.props.data) {
        props.onChange(extra.triggerNode.props.data);
      }
    },
    [props.onChange]
  );

  const mapToItems = useCallback((userGroupHierarchyRoot: IUserGroupHierarchy[]): DataNode[] => {
    return userGroupHierarchyRoot.map((userGroupHierarchy: IUserGroupHierarchy) => {
      return {
        key: userGroupHierarchy?.userGroup?.id,
        value: userGroupHierarchy?.userGroup?.id,
        title: userGroupHierarchy?.userGroup?.name,
        children: mapToItems(userGroupHierarchy?.children),
        data: userGroupHierarchy?.userGroup,
      };
    });
  }, []);

  const pickerValue = useMemo(() => props.value && props.value?.id, [props.value]);
  const pickerItems = useMemo(
    () => [
      {
        key: props.userGroupHierarchy?.userGroup?.id,
        value: props.userGroupHierarchy?.userGroup?.id,
        selectable: true,
        title: props.userGroupHierarchy?.userGroup?.name,
        children: props.userGroupHierarchy ? mapToItems(props.userGroupHierarchy?.children) : undefined,
        data: props.userGroupHierarchy?.userGroup,
      },
    ],
    [props.userGroupHierarchy, mapToItems]
  );
  return <TreeSelect<string> placeholder={props.translate('USER_GROUP_HIERARCHY_DATA_PICKER.PLACEHOLDER')} showSearch={true} value={pickerValue} treeNodeFilterProp="title" treeData={pickerItems} treeDefaultExpandAll={true} disabled={props.disabled} virtual={false} onChange={handleDataPickerChange} />;
};

const mapStateToProps = (state: any): IUserGroupHierarchyDataPickerStateProps => ({
  userGroupHierarchy: UserGroupListBusinessStore.selectors.getUserGroupHierarchy(state),
});

const mapDispatchToProps = (dispatch: any): IUserGroupHierarchyDataPickerDispatchProps => ({
  fetchUserGroupHierarchy: () => dispatch(UserGroupListBusinessStore.actions.fetchUserGroupHierarchy()),
});

export default connect<IUserGroupHierarchyDataPickerStateProps, IUserGroupHierarchyDataPickerDispatchProps, IUserGroupHierarchyDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(UserGroupHierarchyDataPicker as any));

import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { ICourse } from '@src/model/course/Course';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

// public and private course list endpoints share the same model thus filter can be reused
export interface IPublicCourseListFilter extends ICourseListFilter {}

// List filter ID
const PUBLIC_COURSE_LIST_FILTER = '@@PUBLIC_COURSE_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns list of courses from store. */
const getList = (store: any): ICollectionData<ICourse> => store.publicCourseList;

/** Returns course list filter. */
const getListFilter = (store: any): IPublicCourseListFilter => ListFilterBusinessStore.selectors.getListFilter(store, PUBLIC_COURSE_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  PUBLIC_COURSE_LIST_FETCH: 'PUBLIC_COURSE_LIST_FETCH',
  PUBLIC_COURSE_LIST_LOAD: 'PUBLIC_COURSE_LIST_LOAD',
  PUBLIC_COURSE_LIST_CLEAR: 'PUBLIC_COURSE_LIST_CLEAR',
};

/** Fetch course list by filter. */
const fetchList = (params: ICollectionFetchPayload<IPublicCourseListFilter>): IPayloadAction<ICollectionFetchPayload<IPublicCourseListFilter>> => {
  return {
    type: Actions.PUBLIC_COURSE_LIST_FETCH,
    payload: params,
  };
};

/** Load course list to store. */
const loadList = (data: ICollectionData<ICourse>): IPayloadAction<ICollectionData<ICourse>> => {
  return {
    type: Actions.PUBLIC_COURSE_LIST_LOAD,
    payload: data,
  };
};

/** Clear course list from store. Eg. when leaving list view. */
const clearList = (): ILemonAction => {
  return {
    type: Actions.PUBLIC_COURSE_LIST_CLEAR,
  };
};

/** Store course list filter to store. */
const storeListFilter = (listFilter: IPublicCourseListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(PUBLIC_COURSE_LIST_FILTER, listFilter);
};

const clearListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(PUBLIC_COURSE_LIST_FILTER);
};

// -
// -------------------- Side-effects

const fetchPublicCourseListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IPublicCourseListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PUBLIC_COURSE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('PublicCourse')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const publicCourseList = (state: ICollectionData<ICourse> | null = null, action: IPayloadAction<ICollectionData<ICourse>>) => {
  if (action.type === Actions.PUBLIC_COURSE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.PUBLIC_COURSE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const PublicCourseListBusinessStore = {
  actions: {
    fetchList,
    loadList,
    clearList,
    storeListFilter,
    clearListFilter,
  },

  selectors: {
    getList,
    getListFilter,
  },

  effects: {
    fetchPublicCourseListEffect,
  },

  reducers: {
    publicCourseList,
  },
};

// --
// export business store
export default PublicCourseListBusinessStore;

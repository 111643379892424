import { ICode } from '@src/model/common/Code';
import { IAddress } from '@src/model/user/Address';

export interface IResidenceAddress {
  id: string;
  address: IAddress;
  type: ICode<ResidenceTypeEnum>;
}

export enum ResidenceTypeEnum {
  PERMANENT = '1',
  TEMPORARY = '2',
}

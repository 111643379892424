import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationReportListFilterInfoPanel from '@src/components/externalEducationApplication/filter//common/EducationApplicationReportListFilterInfoPanel';
import EducationApplicationReportListFilterModal from '@src/components/externalEducationApplication/filter/EducationApplicationReportListFilterModal';
import AdminEducationApplicationReportListView from '@src/components/externalEducationApplication/list/AdminEducationApplicationReportListView';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ICollectionData } from '@src/service/business/common/types';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { Button, Col, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------
export interface IAdminEducationApplicationReportViewOwnProps {
  applicationList?: ICollectionData<IExternalEducationApplication>;
  applicationListFilter?: IExternalEducationApplicationListFilter;
  onPageChange: (page: number, pageSize?: number | undefined) => void;
  onFilterSubmit?: (listFilter: IExternalEducationApplicationListFilter) => void;
}

type IAdminEducationApplicationReportViewProps = IAdminEducationApplicationReportViewOwnProps & IWithLocalizeOwnProps;

interface IAdminEducationApplicationReportViewState {
  isFilterModalVisible: boolean;
}

// -- Component
// ----------

/** Admin education application report list with modal filter */
class AdminEducationApplicationReportView extends React.Component<IAdminEducationApplicationReportViewProps, IAdminEducationApplicationReportViewState> {
  state: IAdminEducationApplicationReportViewState = {
    isFilterModalVisible: false,
  };

  render() {
    return (
      <AppContent>
        {/* Selected filter information */}
        {this.props.applicationListFilter && <EducationApplicationReportListFilterInfoPanel filter={this.props.applicationListFilter} />}
        {/* Actions */}
        <Row className="timun-wrapper__gutterBox--vertical" gutter={8}>
          <Col>
            <Button onClick={this.handleFilterModalOpen} data-test-id="timun-externalEducationApplicationAdminReport__openFilterButton"> {this.props.translate('COMMON.ACTION_FILTER')} </Button>
          </Col>
          <Col>
            <Button onClick={this.handleResetFiltersClick} data-test-id="timun-externalEducationApplicationAdminReport__resetFilterButton">{this.props.translate('COMMON.ACTION_RESET_FILTER')} </Button>
          </Col>
        </Row>
        {/* List with list pagination */}
        {this.props.applicationList && <AdminEducationApplicationReportListView applicationList={this.props.applicationList} onPageChange={this.props.onPageChange} />}

        {/*  Modal */}
        {this.state.isFilterModalVisible && <EducationApplicationReportListFilterModal filter={this.props.applicationListFilter ?? {}} onCancel={this.handleFilterModalCancel} onSubmit={this.handleSubmit} />}
      </AppContent>
    );
  }

  toggleFilterModal = (isVisible: boolean) => {
    this.setState({ isFilterModalVisible: isVisible });
  };

  handleFilterModalOpen = () => {
    this.toggleFilterModal(true);
  };

  handleFilterModalCancel = () => {
    this.toggleFilterModal(false);
  };

  handleResetFiltersClick = () => {
    if (this.props.onFilterSubmit) {
      this.props.onFilterSubmit({});
    }
  };

  handleSubmit = (listFilter: IExternalEducationApplicationListFilter) => {
    if (this.props.onFilterSubmit) {
      this.props.onFilterSubmit(listFilter);
      this.handleFilterModalCancel();
    }
  };
}
// -- HOCs and exports
// ----------

export default withLocalize<IAdminEducationApplicationReportViewOwnProps>(AdminEducationApplicationReportView as any);

import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import LocationCodebookForm from '@src/components/codebook/form/LocationCodebookForm';
import CodebookListView from '@src/components/codebook/view/CodebookList';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import GridItem from '@src/components/common/grid/GridItem';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ILocation } from '@src/model/location/Location';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import LocationBusinessStore, { ILocationCreatePayload, ILocationListFilter } from '@src/service/business/location/LocationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';

// -- Const
// ----------
const VIEW_NAME = '@@LOCATION_CODEBOOK_LIST';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');

// -- Prop types
// ----------
export interface ILocationCodebookContainerOwnProps {
}

export interface ILocationCodebookContainerStateProps {
  locationCodebookList: ICollectionData<ILocation>;
}

export interface ILocationCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchLocationCodebookList: (params: ICollectionFetchPayload<ILocationListFilter>) => ITrackableAction;
  createLocationCodebook: (data: ILocationCreatePayload) => ITrackableAction;
  updateLocationCodebook: (data: ILocation) => ITrackableAction;
}

type ILocationCodebookContainerProps = ILocationCodebookContainerOwnProps & ILocationCodebookContainerStateProps & ILocationCodebookContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Location codebook container */
const LocationCodebookContainer = (props: ILocationCodebookContainerProps) => {
  const [locationModalsState, toggleLocationModals] = useEntityModalsState<ILocation>();
  const [, updateCollectionParams, onUpdateList] = useCollectionState<ILocationListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchLocationCodebookList,
    initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
  });
  const handleLocationCodebookCreateSubmit = useCallback((data: ILocationCreatePayload) => {
    props.createLocationCodebook(data).track().subscribe(
      // success
      () => {
        props.reportMessage({ message: props.translate('LOCATION_CODEBOOK.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        onUpdateList();
        toggleLocationModals.onCloseCreate();
      }
    );
  }, [props.createLocationCodebook, props.reportMessage, onUpdateList, toggleLocationModals]);

  const handleLocationCodebookUpdateSubmit = useCallback((data: ILocation) => {
    props.updateLocationCodebook(data).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleLocationModals.onCloseUpdate();
      }
    );
  }, [props.updateLocationCodebook, onUpdateList, toggleLocationModals]);

  const renderGradeRowDescription = (record?: ILocation) => {
    if (record) {
      return <GridItem key={record.id} contentType="secondary" layout="horizontal" label={props.translate('LOCATION_CODEBOOK.LOCATION_DESCRIPTION_LABEL')}> {props.translate(`LOCATION_TYPE.${record.type.name}_LABEL`)} </GridItem>;
    }
    return;
  };

  return (
    <React.Fragment>
      {props.locationCodebookList &&
        <CodebookListView<ILocation>
          title={props.translate(`CODEBOOK.TITLE_LABEL.${CodebookEnum.LOCATION}`)}
          titlePropPath={'title'}
          descriptionPrefix={props.translate('LOCATION_CODEBOOK.LOCATION_DESCRIPTION_LABEL')}
          customDescription={renderGradeRowDescription}
          buttonLabel={props.translate('LOCATION_CODEBOOK.CREATE_LOCATION_BUTTON_LABEL')}
          codebookItemList={props.locationCodebookList}
          onEditCodebookItemClick={toggleLocationModals.onOpenUpdate}
          onCreateCodebookItemClick={toggleLocationModals.onOpenCreate}
          onPageChange={updateCollectionParams.onPageChange}
        />
      }

      {(locationModalsState.isUpdateModalVisible || locationModalsState.isCreateModalVisible) && <LocationCodebookForm
        locationCodebook={locationModalsState.selectedEntity}
        onCancel={toggleLocationModals.onCloseCreate}
        onLocationCodebookCreateSubmit={handleLocationCodebookCreateSubmit}
        onLocationCodebookUpdateSubmit={handleLocationCodebookUpdateSubmit} />}
    </React.Fragment>
  );
};


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ILocationCodebookContainerOwnProps): ILocationCodebookContainerStateProps => ({
  locationCodebookList: LocationBusinessStore.selectors.getLocationList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ILocationCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchLocationCodebookList: (params: ICollectionFetchPayload<ILocationListFilter>) => createTrackableAction(dispatch(LocationBusinessStore.actions.fetchLocationList(params))),
  createLocationCodebook: (data: ILocationCreatePayload) => createTrackableAction(dispatch(LocationBusinessStore.actions.createLocation(data))),
  updateLocationCodebook: (data: ILocation) => createTrackableAction(dispatch(LocationBusinessStore.actions.updateLocation(data))),
});

export default connect<ILocationCodebookContainerStateProps, ILocationCodebookContainerDispatchProps, ILocationCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(LocationCodebookContainer as any));

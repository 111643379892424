import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserGroupForm from '@src/components/usergroup/form/UserGroupForm';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import UserGroupBusinessStore, { IUserGroupCreatePayload } from '@src/service/business/usergroup/UserGroupBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface IUserGroupCreateContainerOwnProps {
  groupType: UserGroupTypeEnum;
  parentGroup?: IUserGroup;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export interface IUserGroupCreateContainerStateProps {}

export interface IUserGroupCreateContainerDispatchProps {
  createUserGroup: (userGroup: IUserGroupCreatePayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type IUserGroupCreateContainerProps = IUserGroupCreateContainerOwnProps & IUserGroupCreateContainerStateProps & IUserGroupCreateContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------
const UserGroupCreateContainer: React.FC<IUserGroupCreateContainerProps> = (props: IUserGroupCreateContainerProps) => {
  const handleCreateUserGroup = useCallback(
    (userGroup: IUserGroupCreatePayload) => {
      props
        .createUserGroup(userGroup)
        .track()
        .subscribe(
          // success
          () => {
            props.onSubmit?.();
            const createdMessage = props.groupType === UserGroupTypeEnum.ORGANIZATION ? props.translate('ORGANIZATION_GROUP.CREATED_GROUP_MESSAGE') : props.translate('USER_GROUP.CREATED_GROUP_MESSAGE');
            props.reportMessage({ message: createdMessage, type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
          }
        );
    },
    [props.createUserGroup, props.onSubmit, props.translate, props.reportMessage]
  );

  return <UserGroupForm parentGroup={props.parentGroup} groupType={props.groupType} onCancel={props.onCancel} onCreate={handleCreateUserGroup} />;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserGroupCreateContainerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserGroupCreateContainerDispatchProps => ({
  createUserGroup: (userGroup: IUserGroupCreatePayload) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.createUserGroup(userGroup))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<IUserGroupCreateContainerStateProps, IUserGroupCreateContainerDispatchProps, IUserGroupCreateContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(UserGroupCreateContainer as any));

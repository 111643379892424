// tslint:disable-next-line:no-submodule-imports
import CompanyDataPicker from '@src/components/common/datapicker/CompanyDataPicker';
import EducationCategoryDataPicker from '@src/components/common/datapicker/EducationCategoryDataPicker';
import ExternalEducationTemplatePicker from '@src/components/common/datapicker/ExternalEducationTemplatePicker';
import SkillDataPicker from '@src/components/common/datapicker/SkillDataPicker';
import UserCategoryDataPicker from '@src/components/common/datapicker/UserCategoryDataPicker';
import UserDataPicker from '@src/components/common/datapicker/UserDataPicker';
import CurrencyInput from '@src/components/common/input/CurrencyInput';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withNumberFormat, { IWithNumberFormatOwnProps } from '@src/components/common/numberformat/withNumberFormat';
import YesNoSelector from '@src/components/common/select/YesNoSelector';
import ExternalEducationApplicationEmploymentStatusPicker from '@src/components/externalEducationApplication/common/ExternalEducationApplicationEmploymentStatusPicker';
import EducationApplicationStatusSelector from '@src/components/externalEducationApplication/filter/common/EducationApplicationStatusSelector';
import { ILayoutType } from '@src/model/common/LayoutType';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, DatePicker, Form, FormInstance, Input, Row } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

// -- Const
// ----------
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const backendDateFormat = AppConfigService.getValue('dateTimeFormat.backendDate');
const day = 'day';

const LAYOUT_2COL: ILayoutType = {
  rows: {
    gutter: [16, 16],
  },
  cols: {
    xs: 24,
    sm: 12,
  },
};

// -- Prop types
// ----------
export interface IEducationApplicationReportListFilterFormOwnProps {
  value?: IExternalEducationApplicationListFilter;
  onChange?: (data: IExternalEducationApplicationListFilter) => void;
  onSubmit?: (data: IExternalEducationApplicationListFilter) => void;
  formRef?: FormInstance<IExternalEducationApplicationListFilter>;
  isFormComponent?: false;
}

type IEducationApplicationReportListFilterFormProps = IEducationApplicationReportListFilterFormOwnProps & IWithLocalizeOwnProps & IWithNumberFormatOwnProps;

// -- Component
// ----------
const EducationApplicationReportListFilterForm = (props: IEducationApplicationReportListFilterFormProps) => {
  const [form] = Form.useForm<IExternalEducationApplicationListFilter>(props.formRef);
  const disabledStartDate = (startValue?: Moment) => {
    const endValue = form.getFieldValue('educationEndDate');
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (endValue?: Moment) => {
    const startValue = form.getFieldValue('educationStartDate');
    if (!endValue || !startValue) {
      return false;
    }

    return endValue.valueOf() <= startValue.valueOf();
  };

  const handleSubmit = (values: IExternalEducationApplicationListFilter) => {
    props.onSubmit?.({
      ...values,
      submittedFrom: values.submittedFrom
        ? moment(values.submittedFrom)
            .startOf(day)
            .format(backendDateFormat)
        : undefined,
      submittedTo: values.submittedTo
        ? moment(values.submittedTo)
            .endOf(day)
            .format(backendDateFormat)
        : undefined,
    });
  };

  const handleValuesChange = (changedValues: any, values: IExternalEducationApplicationListFilter) => {
    props.onChange?.({
      ...values,
      submittedFrom: values.submittedFrom
        ? moment(values.submittedFrom)
            .startOf(day)
            .format(backendDateFormat)
        : undefined,
      submittedTo: values.submittedTo
        ? moment(values.submittedTo)
            .endOf(day)
            .format(backendDateFormat)
        : undefined,
    });
  };

  const initialValues = {
    ...props.value,
    submittedFrom: props.value?.submittedFrom ? moment(props.value?.submittedFrom) : undefined,
    submittedTo: props.value?.submittedTo ? moment(props.value?.submittedTo) : undefined,
  };

  return (
    <Form<IExternalEducationApplicationListFilter> name="externalEducationApplicationReportListFilterForm" form={form} layout="vertical" onValuesChange={handleValuesChange} onFinish={handleSubmit} initialValues={initialValues} component={props.isFormComponent}>
      <Form.Item name={'skill'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.SKILL_LABEL')}>
        <SkillDataPicker placeholder={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.SKILL_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item name={'education'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_LABEL')}>
        <ExternalEducationTemplatePicker />
      </Form.Item>

      <Form.Item name={'educationProvider'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_PROVIDER_LABEL')}>
        <CompanyDataPicker />
      </Form.Item>

      <Form.Item name={'assignee'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.ASSIGNEE_LABEL')}>
        <UserDataPicker placeholder={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.ASSIGNEE_PLACEHOLDER')} role={UserRoleEnum.TRAINEE} />
      </Form.Item>

      <Form.Item name={'evaluator'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EVALUATOR_LABEL')}>
        <UserDataPicker placeholder={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EVALUATOR_PLACEHOLDER')} role={UserRoleEnum.COORDINATOR} />
      </Form.Item>

      <Form.Item name={'educationCategory'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_CATEGORY_LABEL')}>
        <EducationCategoryDataPicker placeholder={props.translate('COMMON.LABEL.SELECT_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item name={'userCategory'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.USER_CATEGORY_LABEL')}>
        <UserCategoryDataPicker placeholder={props.translate('COMMON.LABEL.SELECT_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item name={'employmentStatus'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EMPLOYMENT_STATUS_LABEL')}>
        <ExternalEducationApplicationEmploymentStatusPicker placeholder={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.PICK_STATUS_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item name={'educationRelated'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_RELATED_LABEL')}>
        <YesNoSelector fieldName="educationRelated" />
      </Form.Item>

      <Form.Item name={'jobRelated'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.JOB_RELATED_LABEL')}>
        <YesNoSelector fieldName="jobRelated" />
      </Form.Item>

      <Form.Item name={'interestRelated'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.INTEREST_RELATED_LABEL')}>
        <YesNoSelector fieldName="interestRelated" />
      </Form.Item>

      <Form.Item name={'pendingConsultation'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.PENDING_CONSULTATION_LABEL')}>
        <YesNoSelector fieldName="pendingConsultation" />
      </Form.Item>

      <Form.Item name={'phases'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_APPLICATION_STATUS_LABEL')}>
        <EducationApplicationStatusSelector />
      </Form.Item>

      <Row align="bottom" justify="start" {...LAYOUT_2COL.rows}>
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'educationMinDuration'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_LABEL')}>
            <Input placeholder={props.translate('COMMON.LABEL.MINIMUM')} type="number" addonAfter={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_SUFFIX')} data-test-id={'timun-externalEducationApplicationFilter__educationMinDuration'} />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'educationMaxDuration'}>
            <Input placeholder={props.translate('COMMON.LABEL.MAXIMUM')} type="number" addonAfter={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_SUFFIX')} data-test-id={'timun-externalEducationApplicationFilter__educationMaxDuration'} />
          </Form.Item>
        </Col>
      </Row>

      <Row align="bottom" justify="start" {...LAYOUT_2COL.rows}>
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'submittedFrom'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_APPLICATION_SUBMISSION_DATE_LABEL')}>
            <DatePicker allowClear={true} disabledDate={disabledStartDate} format={dateFormat} data-test-id="timun-externalEducationApplicationFilter__submittedFromDate" />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'submittedTo'}>
            <DatePicker allowClear={true} disabledDate={disabledEndDate} format={dateFormat} data-test-id="timun-externalEducationApplicationFilter__submittedToDate" />
          </Form.Item>
        </Col>
      </Row>

      <Row align="bottom" justify="start" {...LAYOUT_2COL.rows}>
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'educationMinExpenses'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_EXPENSES_LABEL')}>
            <CurrencyInput placeholder={props.translate('COMMON.LABEL.MINIMUM')} data-test-id={'timun-externalEducationApplicationFilter__educationMinExpenses'} />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'educationMaxExpenses'}>
            <CurrencyInput placeholder={props.translate('COMMON.LABEL.MAXIMUM')} data-test-id={'timun-externalEducationApplicationFilter__educationMaxExpenses'} />
          </Form.Item>
        </Col>
      </Row>

      <Row align="bottom" justify="start" {...LAYOUT_2COL.rows}>
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'travelMinExpenses'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.TRAVEL_EXPENSES_LABEL')}>
            <CurrencyInput placeholder={props.translate('COMMON.LABEL.MINIMUM')} data-test-id={'timun-externalEducationApplicationFilter__travelMinExpenses'} />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'travelMaxExpenses'}>
            <CurrencyInput placeholder={props.translate('COMMON.LABEL.MAXIMUM')} data-test-id={'timun-externalEducationApplicationFilter__travelMaxExpenses'} />
          </Form.Item>
        </Col>
      </Row>
      <Row align="bottom" justify="start">
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'coFinancedEducationPriceMin'} label={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.COFINANCED_EDUCATION_PRICE_LABEL')}>
            <CurrencyInput placeholder={props.translate('COMMON.LABEL.MINIMUM')} data-test-id={'timun-externalEducationApplicationFilter__coFinancedEducationPriceMin'} />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_2COL.cols}>
          <Form.Item name={'coFinancedEducationPriceMax'}>
            <CurrencyInput placeholder={props.translate('COMMON.LABEL.MAXIMUM')} data-test-id={'timun-externalEducationApplicationFilter__coFinancedEducationPriceMax'} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default withNumberFormat(withLocalize<IEducationApplicationReportListFilterFormOwnProps>(EducationApplicationReportListFilterForm as any));

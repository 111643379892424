import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RepositoryTagList from '@src/components/repository/tag/RepositoryTagList';
import { ITag } from '@src/model/tag/Tag';
import { ICollectionData } from '@src/service/business/common/types';
import { ITagListFilter } from '@src/service/business/tag/TagBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Affix, Input, Row, Select, Typography } from 'antd';
import { debounce } from 'lodash';
import React, { ChangeEvent } from 'react';

// -- Prop types
// ----------

export interface IRepositoryTagFilterOwnProps {
  tagList: ICollectionData<ITag>;
  currentTags: string[];
  onTagSearch: (filter: ITagListFilter) => void;
  onTagChange: (tags: string[]) => void;
}

type IRepositoryTagFilterProps = IRepositoryTagFilterOwnProps & IWithLocalizeOwnProps;

interface IRepositoryTagFilterState {
  searchValue: string;
}

// -- Component
// ----------

/** Repository tag filter component */
class RepositoryTagFilter extends React.Component<IRepositoryTagFilterProps, IRepositoryTagFilterState> {
  state: IRepositoryTagFilterState = {
    searchValue: '',
  };

  debouncedSearch = debounce((value: string) => {
    const filter = { title: value };
    this.props.onTagSearch(filter);
  }, AppConfigService.getValue(`components.repository.debounceTime`));

  render = () => {
    return (
      <Affix>
        <div className="panel">
          <Row className="timun-tagFilter__item"><Typography.Title level={3}>{this.props.translate('REPOSITORY.TAG_FILTER_LABEL')}</Typography.Title></Row>
          <Row className="timun-tagFilter__item">
            <Input value={this.state.searchValue} placeholder={this.props.translate('REPOSITORY.TAG_FILTER_SEARCH_LABEL')} suffix={<LemonIcon name="search" />} onChange={this.handleSearch} allowClear={true} />
          </Row>
          <Row className="timun-tagFilter__item">
            <Select
              placeholder={this.props.translate('REPOSITORY.SELECTED_TAGS_LABEL')}
              className="timun-tagFilter__selectedTags"
              value={this.props.currentTags}
              mode="multiple"
              dropdownRender={(menu) => {
                return <React.Fragment />;
              }}
              onChange={this.handleTagChange}
              allowClear={true}
            >
              {this.props.tagList?.content.map((tag) => {
                return <Select.Option key={tag.id} value={tag.id}>{tag.title}</Select.Option>;
              })}
            </Select>
          </Row>
          <Row className="timun-tagFilter__tagList">
            <RepositoryTagList tagList={this.props.tagList ? this.props.tagList.content : []} onTagChange={this.handleTagChange} currentTags={this.props.currentTags} />
          </Row>
        </div>
      </Affix>
    );
  };

  handleTagChange = (tags: string[]) => {
    this.props.onTagChange(tags);
  };

  handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.setState({
      searchValue: value,
    });

    this.debouncedSearch(value);
  };
}


// -- HOCs and exports
// ----------

export default withLocalize<IRepositoryTagFilterOwnProps>(RepositoryTagFilter as any);

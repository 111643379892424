import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CoordinatorExternalEducationTemplateListView from '@src/components/externaleducation/CoordinatorExternalEducationTemplateListView';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import ExternalEducationTemplateListBusinessStore, { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';

// -- Const
// ----------
const VIEW_NAME = '@@COODINATOR_EXTERNAL_EDUCATION_TEMPLATE_LIST';

// -- Prop types
// ----------
export interface ICoordinatorExternalEducationTemplateListContainerOwnProps {
}

export interface ICoordinatorExternalEducationTemplateListContainerStateProps {
  externalEducationTemplateList: ICollectionData<IExternalEducationTemplate>;
}

export interface ICoordinatorExternalEducationTemplateListContainerDispatchProps {
  fetchList: (params: ICollectionFetchPayload<IExternalEducationTemplateListFilter>) => any;
  clearList: () => void;
}
type ICoordinatorExternalEducationTemplateListContainerProps = ICoordinatorExternalEducationTemplateListContainerOwnProps & ICoordinatorExternalEducationTemplateListContainerStateProps & ICoordinatorExternalEducationTemplateListContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------
const CoordinatorExternalEducationTemplateListContainer = (props: ICoordinatorExternalEducationTemplateListContainerProps) => {
  const initialCollectionValues: Partial<ICollectionFetchPayload<IExternalEducationTemplateListFilter>> = { sort: ['submittedAt,desc'], filter: {} };
  const [collectionParams, updateCollectionParams] = useCollectionState<IExternalEducationTemplateListFilter>({
    initialValues: initialCollectionValues,
    viewName: VIEW_NAME,
    updateFn: props.fetchList,
  });

  return (
    <React.Fragment>
      {props.externalEducationTemplateList &&
        <CoordinatorExternalEducationTemplateListView
          externalEducationTemplateList={props.externalEducationTemplateList}
          externalEducationTemplateFilter={collectionParams.filter}
          onPageChange={updateCollectionParams.onPageChange}
          onFilterChange={updateCollectionParams.onFilterSet}
          showFilter={true}
        />
      }
    </React.Fragment>
  );

};
// -- HOCs and exports
// ----------
// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICoordinatorExternalEducationTemplateListContainerOwnProps): ICoordinatorExternalEducationTemplateListContainerStateProps => ({
  externalEducationTemplateList: ExternalEducationTemplateListBusinessStore.selectors.getExternalEducationTemplateList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICoordinatorExternalEducationTemplateListContainerDispatchProps => ({
  fetchList: (params: ICollectionFetchPayload<IExternalEducationTemplateListFilter>) => dispatch(ExternalEducationTemplateListBusinessStore.actions.fetchExternalEducationTemplateList(params)),
  clearList: () => dispatch(ExternalEducationTemplateListBusinessStore.actions.clearExternalEducationTemplateList()),
});

export default connect<ICoordinatorExternalEducationTemplateListContainerStateProps, ICoordinatorExternalEducationTemplateListContainerDispatchProps, ICoordinatorExternalEducationTemplateListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(CoordinatorExternalEducationTemplateListContainer as any)));

import AppConfigService from '@src/service/common/AppConfigService';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

/** Editor config is assembled from several parts and has several different configs, so this is one place where config should be read from. */
export class TimunEditorConfig {
  /** Default editor config */
  static defaultConfig(): Record<string, any> {
    return {
      ...AppConfigService.getValue('tinyEditor'),
      content_css: UrlBuilderFactory.buildFontUrl('fonts.css'),
      // LD formula editor config
      ldformulaeditor_api_key: AppConfigService.getValue('imatheq.apiKey'),
      ldformulaeditor_lang: AppConfigService.getValue('imatheq.lang'),
    };
  }

  /** Short editor config */
  static shortConfig(): Record<string, any> {
    return {
      ...TimunEditorConfig.defaultConfig(),
      toolbar: 'undo redo |  bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
    };
  }
}

import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import OrganizationListView from '@src/components/organization/list/OrganizationListView';
import { IOrganization } from '@src/model/organization/Organization';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import OrganizationListBusinessStore, { IOrganizationListFilter } from '@src/service/business/organization/OrganizationListBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const VIEW_NAME = '@@ORGANIZATION_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<IOrganizationListFilter> = {
  viewName: VIEW_NAME,
  initialValues: {},
};

// -- Prop types
// ----------
export interface IOrganizationListContainerOwnProps {
}

export interface IOrganizationListContainerStateProps {
  organizationList: ICollectionData<IOrganization>;
}

export interface IOrganizationListContainerDispatchProps {
  fetchOrganizationList: (filter: ICollectionFetchPayload<IOrganizationListFilter>) => ITrackableAction;
}

type IOrganizationListContainerProps = IOrganizationListContainerOwnProps & IOrganizationListContainerStateProps
  & IOrganizationListContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IOrganizationListFilter> & IWithRolesOwnProps;

interface IOrganizationListContainerState {
}

// -- Component
// ----------
class OrganizationListContainer extends React.Component<IOrganizationListContainerProps, IOrganizationListContainerState> {
  state: IOrganizationListContainerState = {};

  componentDidMount() {
    this.fetchOrganizationList();
  }

  componentDidUpdate(prevProps: IOrganizationListContainerProps, prevState: IOrganizationListContainerState) {
    if (this.props.collectionParams !== prevProps.collectionParams) {
      this.fetchOrganizationList();
    }
  }

  componentWillUnmount() {
    //
  }

  render() {
    return (
      <React.Fragment>
        {this.props.organizationList &&
          <OrganizationListView
            organizationList={this.props.organizationList}
            filter={this.props.collectionParams.filter}
            onFilterChange={this.props.updateCollectionParams.onFilterSet}
            onPageChange={this.props.updateCollectionParams.onPageChange}
          />
        }
      </React.Fragment>
    );
  }

  fetchOrganizationList = () => {
    this.props.onUpdateList(this.props.fetchOrganizationList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IOrganizationListContainerStateProps => ({
  organizationList: OrganizationListBusinessStore.selectors.getOrganizationList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IOrganizationListContainerDispatchProps => ({
  fetchOrganizationList: (params: ICollectionFetchPayload<IOrganizationListFilter>) => createTrackableAction(dispatch(OrganizationListBusinessStore.actions.fetchOrganizationList(params))),
});

export default connect<IOrganizationListContainerStateProps, IOrganizationListContainerDispatchProps, IOrganizationListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(withRouter(withRoles(OrganizationListContainer) as any))));

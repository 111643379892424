import React, { useCallback, useEffect, useState } from 'react';

import { HtmlViewer } from '@src/components/common/htmlviewer/HtmlViewer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { FinancingAgreemntTypeEnum, IExternalEducationApplicationFilePreviewData } from '@src/model/externalEducationApplication/ExternalEducationApplicationFilePreviewData';
import { ExternalEducationApplicationFileTypeEnum } from '@src/model/file/FileType';
import { ITrackableAction } from '@src/service/util/action/trackAction';
import { Button, Modal, Row, Select } from 'antd';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';

// -- Const
// ----------
const { Option } = Select;
// -- Prop types
// ----------

export interface IExternalEducationApplicationFinancingAgreementPreviewModalOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  title?: string;
  okText?: string;
  cancelText?: string;
  showSubmitButton?: boolean;
  onCancel?: () => void;
  onSubmit?: (FinancingAgreemntType: FinancingAgreemntTypeEnum) => void;
  onFilePreview?: (externalEducationApplicationId: string, filePreviewData: IExternalEducationApplicationFilePreviewData) => ITrackableAction;
}
type IExternalEducationApplicationFinancingAgreementPreviewModalProps = IExternalEducationApplicationFinancingAgreementPreviewModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationApplicationFinancingAgreementPreviewModal: React.FC<IExternalEducationApplicationFinancingAgreementPreviewModalProps> = (props: IExternalEducationApplicationFinancingAgreementPreviewModalProps) => {
  const [financingAgreementPreview, setFinancingAgreementPreview] = useState<string | undefined>();
  const [financingAgreementType, setFinancingAgreementType] = useState<FinancingAgreemntTypeEnum>(FinancingAgreemntTypeEnum.EMPLOYED);
  const [isFinancingAgreementPreviewVisible, setIsFinancingAgreementPreviewVisible] = useState<boolean>(false);

  useEffect(() => {
    handleContractFilePreview(props.externalEducationApplication.financingAgreementType);
  }, [props.externalEducationApplication.id]);

  const handleContractFilePreview = useCallback((selectedFinancingAgreementType: FinancingAgreemntTypeEnum) => {
    props.onFilePreview?.(props.externalEducationApplication.id, { fileType: ExternalEducationApplicationFileTypeEnum.FINANCING_AGREEMENT, contractType: selectedFinancingAgreementType }).track().subscribe(
      (response) => {
        setFinancingAgreementType(selectedFinancingAgreementType);
        setFinancingAgreementPreview(response.content);
        setIsFinancingAgreementPreviewVisible(true);
      }
    );
  }, [props.onFilePreview, props.externalEducationApplication.id, setFinancingAgreementType, setFinancingAgreementPreview, setIsFinancingAgreementPreviewVisible]);

  const handleSubmit = () => {
    props.onSubmit?.(financingAgreementType);
  };

  return (
    <Modal
      className="lemon-modal__modal--lg"
      title={props.title}
      visible={true}
      maskClosable={false}
      onCancel={props.onCancel}
      footer={[
        <Button key="back" onClick={props.onCancel}>
          {props.cancelText ?? props.translate('COMMON.ACTION_CLOSE')}
        </Button>,
        props.showSubmitButton && <Button type="primary" key="front" onClick={handleSubmit}>
          {props.okText ?? props.translate('COMMON.ACTION_YES')}
        </Button>]}
    >
      <Row className="timun-wrapper__gutterBox--vertical">
        <Select className="timun-externalEducationApplicationReturnTContractGeneration__select" defaultValue={props.externalEducationApplication.financingAgreementType} onChange={handleContractFilePreview} placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.FINANCING_AGREEMENT_TYPE_PLACEHOLDER')}>
          {Object.keys(FinancingAgreemntTypeEnum).map((type) => <Option key={type} value={FinancingAgreemntTypeEnum[type as keyof typeof FinancingAgreemntTypeEnum]}> {props.translate(`EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.FINANCING_AGREEMENT_TYPE_LABEL.${type}`)} </Option>)}
        </Select>
      </Row>

      {isFinancingAgreementPreviewVisible && financingAgreementPreview && <HtmlViewer size="large" dangerousValue={financingAgreementPreview} />}
    </Modal>
  );
};
export default withLocalize<IExternalEducationApplicationFinancingAgreementPreviewModalOwnProps>(ExternalEducationApplicationFinancingAgreementPreviewModal as any);

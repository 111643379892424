import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEmailTemplate } from '@src/model/emailtemplate/EmailTemplate';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Empty, Row, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import React from 'react';

// -- Const
// ----------
const TABLE_SCROLL_SIZE = AppConfigService.getValue('layout.table.scrollSize');

// -- Prop types
// ----------
interface IEmailTemplateListOwnProps {
  emailTemplateList: ICollectionData<IEmailTemplate>;
  showControls: boolean;
  onDelete?: (emailTemplate: IEmailTemplate) => void;
  onEdit?: (emailTemplate: IEmailTemplate) => void;
  onShowContent?: (content: string) => void;
}

type IEmailTemplateListProps = IEmailTemplateListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
class EmailTemplateList extends React.Component<IEmailTemplateListProps> {
  columns: Array<ColumnProps<IEmailTemplate>> = [
    {
      key: 'title',
      title: this.props.translate('EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_TITLE'),
      render: (emailTemplate: IEmailTemplate) => emailTemplate.title,
      width: '20%',
    },
    {
      key: 'content',
      title: this.props.translate('EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_CONTENT'),
      render: (emailTemplate: IEmailTemplate) => {
        return this.renderContent(emailTemplate);
      },
      width: '50%',
      sorter: false,
      ellipsis: true,
    },
    {
      key: 'type.name',
      title: this.props.translate('EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_TYPE'),
      render: (emailTemplate: IEmailTemplate) => this.props.translate(`EMAIL_TEMPLATE_FORM.${emailTemplate.type.name}_TYPE`),
      width: this.props.showControls ? '15%' : '25%',
    },
    {
      key: 'buttons',
      render: (emailTemplate: IEmailTemplate) => {
        return this.props.showControls && (
          <Row justify="end" gutter={6}>
            <Col>
              <Button title={this.props.translate('COMMON.ACTION_EDIT')} icon={<LemonIcon name="edit"/>} onClick={() => this.openUpdateModal(emailTemplate)}/>
            </Col>
            <Col>
              <DeleteElementButton<IEmailTemplate> icon={<LemonIcon name="delete"/>} item={emailTemplate} onDelete={this.props.onDelete}/>
            </Col>
          </Row>
        );
      },
      width: this.props.showControls ? '15%' : '0%',
    },
  ];

  renderContent = (emailTemplate: IEmailTemplate) => {
    return <a onClick={() => this.props.onShowContent?.(emailTemplate.content)}>
      <Row>
        <LemonImage fallbackIconName="eye"/>&nbsp;{this.props.translate('EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_CONTENT_LINK_LABEL')}
      </Row>
    </a>;
  };

  render() {
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          rowKey={(emailTemplate) => emailTemplate.id}
          dataSource={this.props.emailTemplateList ? this.props.emailTemplateList.content : []}
          locale={{ emptyText: <Empty description={this.props.translate('EMAIL_TEMPLATE_LIST.NO_DATA')}/> }}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={false}
          scroll={{ x: TABLE_SCROLL_SIZE }}
        />
      </React.Fragment>
    );
  }

  openUpdateModal = (emailTemplate: IEmailTemplate) => {
    this.props.onEdit?.(emailTemplate);
  };

}

// -- HOCs and exports
// ----------

export default withLocalize<IEmailTemplateListOwnProps>(EmailTemplateList as any);

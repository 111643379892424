import FileItemCard from '@src/components/common/file/FileItemCard';
import FilePreviewModal from '@src/components/common/file/FilePreviewModal';
import FileUtils from '@src/components/common/file/FileUtils';
import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import FileUpload, { IUploadStatus } from '@src/components/common/upload/FileUpload';
import { UserHelperUtils } from '@src/components/user/utils/UserHelperUtils';
import { IFile } from '@src/model/file/File';
import { FileTypeEnum } from '@src/model/file/FileType';
import { UserFeedbackMessageType, UserFeedbackMessageSeverity } from '@src/service/business/common/types';
import { IFileListsByType, ITimunFile } from '@src/service/business/files/util';
import { Col, Row, Select, Spin } from 'antd';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';


interface IFileTypeUploadFieldOwnProps {
  fileList?: IFileListsByType;
  defaultFileType?: keyof typeof FileTypeEnum;
  allowedFileTypes?: (keyof typeof FileTypeEnum)[];
  onFileUpload?: (file: IFile[]) => void;
  canRemoveFile?: boolean;
  onFileRemove?: (file: IFile[]) => void;
  dataTestIdPrefix?: string;
}

type IFileTypeUploadFieldProps = IFileTypeUploadFieldOwnProps & IWithLocalizeOwnProps;

const FileTypeUploadField = (props: IFileTypeUploadFieldProps) => {

  const [fileToDisplay, setFileToDisplay] = useState<ITimunFile>();

  const [fileType, setFileType] = useState<keyof typeof FileTypeEnum | undefined>(props.defaultFileType);

  useEffect(() => {
    if (props.defaultFileType) {
      setFileType(props.defaultFileType);
    }
  }, [props.defaultFileType]);

  const togglePreviewVisible = (previewFile: ITimunFile) => {
    if (FileUtils.isFilePreviewable(previewFile.type)) {
      setFileToDisplay(previewFile);
    }
  };

  const handleClosePreview = () => {
    setFileToDisplay(undefined);
  };

  const handleFileAdd = (data: IFile) => {
    if (fileType) {
      const newFile: IFile = {
        ...data,
        type: FileTypeEnum[fileType],
      };
      props.onFileUpload?.([newFile]);
    }
  };

  const handleFileDelete = (data: ITimunFile) => {
    const deleted: IFile | undefined = data.response;
    if (props.onFileRemove && deleted) {
      props.onFileRemove([deleted]);
    }
  };

  const displayFileList = useMemo(() => {
    let fileTypes;
    if (props.allowedFileTypes) {
      fileTypes = props.allowedFileTypes;
    } else if (fileType) {
      fileTypes = [fileType];
    }
    return fileTypes?.reduce((accum: ITimunFile[], item: keyof typeof FileTypeEnum) => {
      const addList = props.fileList?.[FileTypeEnum[item]];
      if (addList) {
        return accum.concat(addList);
      }
      return accum;
    }, []);
  }, [props.fileList, props.allowedFileTypes, fileType]);

  return (
    <React.Fragment>
      {props.allowedFileTypes &&
        <React.Fragment>
          <Row gutter={[8, 8]} align="middle">
            <Col xs={24} md={18}>
              <GridItem layout="horizontal" label={props.translate('COMMON.ACTION_ADD')}>
                <Select value={fileType} placeholder={props.translate('FILE_TYPE_UPLOAD.SELECT_TYPE_PLACEHOLDER')} onChange={setFileType} dropdownMatchSelectWidth={true}>
                  {props.allowedFileTypes.map((type) => <Select.Option value={type} key={type}>{props.translate(`FILES.TYPE_ENUM.LABEL.${type}`)}</Select.Option>)}
                </Select>
              </GridItem>
            </Col>
          </Row>
        </React.Fragment>
      }
      <Row>
        <Col span={24}>
          {/* Antd Upload component has issues with upload and display of changes when there is a controlled list.
            Bypassed if one upload component handles upload only, and additional one displays the list and handles remove of files*/}
          <FileUpload
            onUploadFile={handleFileAdd}
            uploadProps={{
              beforeUpload: () => {
                if (fileType == null) {
                  UserHelperUtils.reportMessage({ message: props.translate('FILE_TYPE_UPLOAD.SELECT_TYPE_WARNING'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.WARNING });
                  return false;
                }
                return;
              },
            }}
            dragger={true}
            dataTestIdPrefix={`timun-${props.dataTestIdPrefix}__fileType_${fileType}`}
          >
            {(status: IUploadStatus) => (
              <div className="timun-fileUpload__dragField">
                <p className="ant-upload-drag-icon">{status.isUploading ? <Spin size="large" /> : <LemonIcon name="fileUploadDropzone" />}</p>
                <a>{props.translate('FILE_UPLOAD.DROPZONE_TEXT')}</a>
              </div>
            )}
          </FileUpload>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FileUpload
            onUploadFile={handleFileAdd}
            uploadProps={{
              onPreview: togglePreviewVisible,
              showUploadList: true,
              fileList: displayFileList,
              onRemove: handleFileDelete,
              itemRender: (originNode: ReactElement, file: ITimunFile, fileList: object[], actions: { download: (file: ITimunFile) => void, preview: (file: ITimunFile) => void, remove: (file: ITimunFile) => void; }) => (
                <FileItemCard
                  file={file}
                  canRemove={props.canRemoveFile}
                  onRemove={actions.remove}
                />
              ),
            }}
            dataTestIdPrefix={`timun-${props.dataTestIdPrefix}__fileType_${fileType}`}
          />
        </Col>
      </Row>

      {fileToDisplay && <FilePreviewModal closeModal={handleClosePreview} record={fileToDisplay} />}
    </React.Fragment>
  );
};

export default withLocalize<IFileTypeUploadFieldOwnProps>(FileTypeUploadField as any);


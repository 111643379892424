import { FormComponentProps } from '@ant-design/compatible/lib/form';
import UserDataPicker from '@src/components/common/datapicker/UserDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { userActivityTypesEnum } from '@src/model/activity/UserActivity';
import { IUserInfo } from '@src/model/user/User';
import { IUserActivityListFilter } from '@src/service/business/user/userActivityListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Row, Select, Form } from 'antd';
import moment, { Moment } from 'moment';

import React, { useMemo } from 'react';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const queryDateFormat = AppConfigService.getValue('dateTimeFormat.backendQueryParamDateFormat');

export interface IUserActivityListFilterOwnProps {
  filter: IUserActivityListFilter;
  userList: IUserInfo[];

  onSubmit: (listFilter: IUserActivityListFilter) => void;
}

type IUserActivityListFilterProps = IUserActivityListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

const ActivityListFilter: React.FC<IUserActivityListFilterProps> = (props: IUserActivityListFilterProps) => {
  const [form] = Form.useForm<IUserActivityListFilter>();

  const initialValues = {
    ...props.filter,
    startDate: props.filter?.startDate ? moment(props.filter.startDate) : undefined,
    endDate: props.filter?.endDate ? moment(props.filter.endDate) : undefined,
  };

  const startDateValue = Form.useWatch('startDate', form);
  const endDateValue = Form.useWatch('endDate', form);

  const activityTypesOptions = useMemo(() => {
    return Object.keys(userActivityTypesEnum).map((key: string) => ({
      value: userActivityTypesEnum[key],
      key: userActivityTypesEnum[key],
      label: props.translate(`USER_ACTIVITY.TYPE.${key}`),
    }));
  }, [userActivityTypesEnum, props.translate]);

  const disabledStartDate = (startValue?: Moment) => {
    if (!startValue || !endDateValue) {
      return false;
    }
    return moment(startValue.valueOf()) > moment(endDateValue.valueOf());
  };

  const disabledEndDate = (endValue?: Moment) => {
    if (!endValue || !startDateValue) {
      return false;
    }
    return moment(endValue.valueOf()) <= moment(startDateValue.valueOf());
  };

  const handleFinish = (values: IUserActivityListFilter) => {
    const data = {
      ...values,
      startDate: values.startDate ? moment(values.startDate).format(queryDateFormat) : undefined,
      endDate: values.endDate ? moment(values.endDate).format(queryDateFormat) : undefined,
    };
    props.onSubmit?.(data);
  };

  return (
    <React.Fragment>
      <Form<IUserActivityListFilter> form={form} onFinish={handleFinish} className="timun-userActivityList__filterForm" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} initialValues={initialValues}>
        <Row justify="start">
          <Col xs={24} lg={12}>
            <Form.Item name="creator" label={props.translate('USER_ACTIVITY_LIST_FILTER.USER_LABEL')}>
              <UserDataPicker placeholder={props.translate('USER_ACTIVITY_LIST_FILTER.USER_NAME_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="type" label={props.translate('USER_ACTIVITY_LIST_FILTER.ACTIVITY_TYPE_LABEL')}>
              <Select data-test-id="timun-userActivityList__activityType" placeholder={props.translate('USER_ACTIVITY.TYPE.ALL_TYPES')} options={activityTypesOptions} allowClear={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="start">
          <Col xs={24} lg={12}>
            <Form.Item className="" name="startDate" label={props.translate('USER_ACTIVITY_LIST_FILTER.START_DATE_LABEL')}>
              <DatePicker data-test-id="timun-userActivityList__requestedStartDate" allowClear={true} disabledDate={disabledStartDate} format={dateFormat} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item className="" name="endDate" label={props.translate('USER_ACTIVITY_LIST_FILTER.END_DATE_LABEL')}>
              <DatePicker data-test-id="timun-userActivityList__requestedEndDate" allowClear={true} disabledDate={disabledEndDate} format={dateFormat} />
            </Form.Item>
          </Col>
          <Col className="timun-userActivityList__filterForm-submitButton">
            <Form.Item className="text-center">
              <Button type="primary" data-test-id="timun-userActivityList__filterForm-submitButton" htmlType="submit">
                {props.translate('COMMON.ACTION_SEARCH')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default withLocalize<IUserActivityListFilterOwnProps>(ActivityListFilter as any);

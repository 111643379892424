import { IExternalEducationTemplateTimelineActivity } from '@src/model/externalEducationTemplate/ExternalEducationTemplateTimelineActivity';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export interface IExternalEducationTemplateTimelineActivityListFilter {}

// -
// -------------------- Selectors
const getExternalEducationTemplateTimelineList = (store: any): ICollectionData<IExternalEducationTemplateTimelineActivity> => store.externalEducationTemplateTimelineList;

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_FETCH: 'EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_FETCH',
  EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_LOAD: 'EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_LOAD',
  EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_CLEAR: 'EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_CLEAR',
};

// NOTE: remove Partial from payload type if ExternalEducationTemplateTimeline has to turn to proper list
const fetchExternalEducationTemplateTimelineList = (id: string, page?: number, size?: number, sort?: string[], filter?: IExternalEducationTemplateTimelineActivityListFilter): IPayloadAction<IIdDataPayload<Partial<ICollectionFetchPayload<IExternalEducationTemplateTimelineActivityListFilter>>>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_FETCH,
    payload: {
      id,
      data: {
        filter,
        page,
        size,
        sort,
      },
    },
  };
};

const loadExternalEducationTemplateTimelineList = (data: ICollectionData<IExternalEducationTemplateTimelineActivity>): IPayloadAction<ICollectionData<IExternalEducationTemplateTimelineActivity>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_LOAD,
    payload: data,
  };
};

const clearExternalEducationTemplateTimelineList = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_CLEAR,
  };
};
// -
// -------------------- Side-effects

const fetchExternalEducationTemplateTimelineListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IExternalEducationTemplateTimelineActivityListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;
      const params = action.payload.data;

      return EntityApiServiceRegistry.getService('ExternalEducationTemplate')
        .fetchSubentityList(id, 'timeline', params)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationTemplateTimelineList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXTERNAL_EDUCATION_TEMPLATE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching externalEducationApplication ExternalEducationTemplateTimeline list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const externalEducationTemplateTimelineList = (state: ICollectionData<IExternalEducationTemplateTimelineActivity> | null = null, action: IPayloadAction<ICollectionData<IExternalEducationTemplateTimelineActivity>>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_LOAD) {
    return { ...action.payload };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_TIMELINE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

const ExternalEducationTemplateTimelineListBusinessStore = {
  actions: {
    fetchExternalEducationTemplateTimelineList,
    clearExternalEducationTemplateTimelineList,
  },

  selectors: {
    getExternalEducationTemplateTimelineList,
  },

  effects: {
    fetchExternalEducationTemplateTimelineListEffect,
  },

  reducers: {
    externalEducationTemplateTimelineList,
  },
};

// --
// export business store

export default ExternalEducationTemplateTimelineListBusinessStore;

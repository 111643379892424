import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamTemplateQuizDisplayView from '@src/components/exam/view/ExamTemplateQuizDisplayView';
import { IExamTemplate } from '@src/model/education/ExamTemplate';
import examTemplateBusinessStore from '@src/service/business/examtemplates/examTemplateBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------

export interface IExamTemplateQuizDisplayContainerOwnProps {
  examTemplateId: string;
}

export interface IExamTemplateQuizDisplayContainerStateProps {
  examTemplate: IExamTemplate;
}

export interface IExamTemplateQuizDisplayContainerDispatchProps {
  fetchExamTemplate: () => ITrackableAction;
}

type IExamTemplateQuizDisplayContainerProps = IExamTemplateQuizDisplayContainerOwnProps & IExamTemplateQuizDisplayContainerStateProps & IExamTemplateQuizDisplayContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------

/** Container component for displaying examTemplate instances and creating instances */
const ExamTemplateQuizDisplayContainer: React.FC<IExamTemplateQuizDisplayContainerProps> = (props: IExamTemplateQuizDisplayContainerProps) => {
  useEffect(() => {
    props.fetchExamTemplate();
  }, [props.examTemplateId]);

  return (
    <React.Fragment>
      {/* Display examTemplate quiz creator  */}
      {props.examTemplate && <ExamTemplateQuizDisplayView examTemplate={props.examTemplate} />}
    </React.Fragment>
  );
};
// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExamTemplateQuizDisplayContainerOwnProps): IExamTemplateQuizDisplayContainerStateProps => ({
  examTemplate: examTemplateBusinessStore.selectors.getExamTemplate(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IExamTemplateQuizDisplayContainerOwnProps): IExamTemplateQuizDisplayContainerDispatchProps => ({
  fetchExamTemplate: () => createTrackableAction(dispatch(examTemplateBusinessStore.actions.fetchExamTemplate(ownProps.examTemplateId))),
});

// -- HOCs and exports
// ----------
export default connect<IExamTemplateQuizDisplayContainerStateProps, IExamTemplateQuizDisplayContainerDispatchProps, IExamTemplateQuizDisplayContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(ExamTemplateQuizDisplayContainer as any)));

import { IUploadedFile } from '@src/components/common/upload/FileUpload';
import { IPath } from '@src/model/common/Path';
import { IFile } from '@src/model/file/File';
import { IFileSystemElement } from '@src/model/file/FileSystemElement';
import IFolder from '@src/model/file/Folder';
import { ITimunFile } from '@src/service/business/files/util';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import NumberFormatService from '@src/service/util/numberformat/NumberFormatService';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { message } from 'antd';


export default class FileUtils {
  static isFolder = (arg: IFileSystemElement): arg is IFolder => {
    return arg.mimeType === 'application/vnd.lemon.folder';
  };

  static isFile = (arg: IFileSystemElement): arg is IFile => {
    return arg.mimeType !== 'application/vnd.lemon.folder';
  };

  static isFileImage(file: IFile) {
    return file.mimeType.startsWith('image/');
  }

  static getFileLink = (file: IFile) => {
    return UrlBuilderFactory.buildApiFileUrl(file.id);
  };

  static getRootFolder = (): IPath => {
    return { id: 'root', name: 'root', child: null };
  };

  static isFilePreviewable = (fileType?: string): boolean => {
    if (fileType?.startsWith('image') || fileType?.startsWith('video') || fileType?.startsWith('audio') || fileType?.includes('pdf')) {
      return true;
    } else {
      return false;
    }
  };

  static mapToTimunFile = (file: IFile): ITimunFile => {
    return {
      uid: file.id,
      name: file.name,
      type: file.mimeType,
      size: file.size,
      url: UrlBuilderFactory.buildApiFileUrl(file.id),
      status: 'done', // required for correct actions rendering (eg. download button)
    };
  };

  static mapToTimunFileList = (files: IFile[]): ITimunFile[] => {
    return files.map((file: IFile) => {
      return FileUtils.mapToTimunFile(file);
    });
  };

  static checkFileValidity = (file: IUploadedFile, allowedFileTypes?: string[], maxSize?: number) => {
    // check file type
    const isValidType = allowedFileTypes ? allowedFileTypes.some((pattern) => file.type.match(new RegExp(pattern))) : true;
    if (!isValidType) {
      message.error(LocalizeService.translate('FILE_UPLOAD.ERROR_FILE_TYPE'));
    }

    // check file size
    const isValidSize = maxSize ? file.size <= maxSize : true;
    if (maxSize && !isValidSize) {
      const maxFileSizeInMbs = NumberFormatService.formatBytes(maxSize);
      message.error(LocalizeService.translate('FILE_UPLOAD.ERROR_FILE_SIZE', { maxFileSize: maxFileSizeInMbs }));
    }

    return isValidType && isValidSize;
  };
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamTemplateStatisticsView from '@src/components/exam/view/ExamTemplateStatisticsView';
import { IExamTemplateStatistics } from '@src/model/education/ExamTemplateStatistics';
import CourseListBusinessStore from '@src/service/business/courses/courseListBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface IExamTemplateStatisticsContainerOwnProps {
  courseId: string;
}

export interface IExamTemplateStatisticsContainerStateProps {
  examTemplateStatistics: IExamTemplateStatistics;
}
export interface IExamTemplateStatisticsContainerDispatchProps {
  fetchExamTemplateStatistics: () => ITrackableAction;
}
type IExamTemplateStatisticsContainerProps = IExamTemplateStatisticsContainerOwnProps & IExamTemplateStatisticsContainerStateProps & IExamTemplateStatisticsContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExamTemplateStatisticsContainer = (props: IExamTemplateStatisticsContainerProps) => {
  useEffect(() => {
    props.fetchExamTemplateStatistics();
  }, [props.courseId]);

  return (
    <React.Fragment>
      {props.examTemplateStatistics && <ExamTemplateStatisticsView examTemplateStatistics={props.examTemplateStatistics} />}
    </React.Fragment >
  );

};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExamTemplateStatisticsContainerOwnProps): IExamTemplateStatisticsContainerStateProps => ({
  examTemplateStatistics: CourseListBusinessStore.selectors.getCourseExamTemplateStatistics(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IExamTemplateStatisticsContainerOwnProps): IExamTemplateStatisticsContainerDispatchProps => ({
  fetchExamTemplateStatistics: () => createTrackableAction(dispatch(CourseListBusinessStore.actions.fetchCourseExamTemplateStatistics(ownProps.courseId))),
});


export default connect<IExamTemplateStatisticsContainerStateProps, IExamTemplateStatisticsContainerDispatchProps, IExamTemplateStatisticsContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ExamTemplateStatisticsContainer as any));

import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { IExternalEducationOutcome } from '@src/model/externalEducationTemplate/ExternalEducationOutcome';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface IExternalEducationOutcomeListFilter {
  title?: string;
}

export type IExternalEducationOutcomeCreatePayload = Pick<IExternalEducationOutcome, 'title'>;

// List filter ID
const EXTERNAL_EDUCATION_OUTCOME_LIST_FILTER = '@@EXTERNAL_EDUCATION_OUTCOME_LIST_FILTER';

// -
// -------------------- Selectors

const getExternalEducationOutcomeList = (store: any): ICollectionData<IExternalEducationOutcome> => store.externalEducationOutcomeList;
const getExternalEducationOutcomeListFilter = (store: any): IExternalEducationOutcomeListFilter => ListFilterBusinessStore.selectors.getListFilter(store, EXTERNAL_EDUCATION_OUTCOME_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_EDUCATION_OUTCOME_LIST_FETCH: 'EXTERNAL_EDUCATION_OUTCOME_LIST_FETCH',
  EXTERNAL_EDUCATION_OUTCOME_PICKER_LIST_FETCH: 'EXTERNAL_EDUCATION_OUTCOME_PICKER_LIST_FETCH',
  EXTERNAL_EDUCATION_OUTCOME_LIST_LOAD: 'EXTERNAL_EDUCATION_OUTCOME_LIST_LOAD',
  EXTERNAL_EDUCATION_OUTCOME_LIST_CLEAR: 'EXTERNAL_EDUCATION_OUTCOME_LIST_CLEAR',
  EXTERNAL_EDUCATION_OUTCOME_CREATE: 'EXTERNAL_EDUCATION_OUTCOME_CREATE',
};

const fetchExternalEducationOutcomeList = (filter: IExternalEducationOutcomeListFilter): IPayloadAction<IExternalEducationOutcomeListFilter> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_OUTCOME_LIST_FETCH,
    payload: filter,
  };
};

const fetchExternalEducationOutcomePickerList = (filter: IExternalEducationOutcomeListFilter): IPayloadAction<IExternalEducationOutcomeListFilter> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_OUTCOME_PICKER_LIST_FETCH,
    payload: filter,
  };
};

const loadExternalEducationOutcomeList = (data: ICollectionData<IExternalEducationOutcome>): IPayloadAction<ICollectionData<IExternalEducationOutcome>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_OUTCOME_LIST_LOAD,
    payload: data,
  };
};

const clearExternalEducationOutcomeList = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_OUTCOME_LIST_CLEAR,
  };
};

const createExternalEducationOutcome = (outcome: IExternalEducationOutcomeCreatePayload): IPayloadAction<IExternalEducationOutcomeCreatePayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_OUTCOME_CREATE,
    payload: outcome,
  };
};

const storeExternalEducationOutcomeListFilter = (filter: IExternalEducationOutcomeListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(EXTERNAL_EDUCATION_OUTCOME_LIST_FILTER, filter);
};

const clearExternalEducationOutcomeListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(EXTERNAL_EDUCATION_OUTCOME_LIST_FILTER);
};

// -
// -------------------- Side-effects

const fetchExternalEducationListEffect = (action$: Observable<IPayloadAction<IExternalEducationOutcomeListFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_OUTCOME_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationOutcome')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationOutcomeList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_OUTCOME.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching external education outcome list', error);
      return o;
    })
  );
};

const fetchExternalEducationPickerListEffect = (action$: Observable<IPayloadAction<IExternalEducationOutcomeListFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_OUTCOME_PICKER_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationOutcome')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_OUTCOME.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching external education outcome picker list', error);
      return o;
    })
  );
};

const createExternalEducationOutcomeEffect = (action$: Observable<IPayloadAction<IExternalEducationOutcomeCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_OUTCOME_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationOutcome')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXTERNAL_EDUCATION.FORM.OUTCOME_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating external content', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const externalEducationOutcomeList = (state: ICollectionData<IExternalEducationOutcome> | null = null, action: IPayloadAction<ICollectionData<IExternalEducationOutcome>>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_OUTCOME_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_OUTCOME_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const ExternalEducationOutcomeListBusinessStore = {
  actions: {
    fetchExternalEducationOutcomeList,
    fetchExternalEducationOutcomePickerList,
    clearExternalEducationOutcomeList,
    storeExternalEducationOutcomeListFilter,
    clearExternalEducationOutcomeListFilter,
    createExternalEducationOutcome,
  },

  selectors: {
    getExternalEducationOutcomeList,
    getExternalEducationOutcomeListFilter,
  },

  effects: {
    fetchExternalEducationListEffect,
    fetchExternalEducationPickerListEffect,
    createExternalEducationOutcomeEffect,
  },

  reducers: {
    externalEducationOutcomeList,
  },
};

// --
// export business store
export default ExternalEducationOutcomeListBusinessStore;

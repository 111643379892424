import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TraineeExamInstanceList from '@src/components/exam/examinstancelist/TraineeExamInstanceList';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import activityBusinessStore, { IActivityPhaseCreatePayload } from '@src/service/business/examtemplates/activityBusinessStore';
import examInstanceBusinessStore, { IExamInstanceListFilter } from '@src/service/business/examtemplates/examInstanceBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { withRouter, WithRouterProps } from 'react-router';
import courseViewBusinessStore from '@src/service/business/courses/courseViewBusinessStore';
import { ICourse } from '@src/model/course/Course';
import { getCurrentUser } from '@src/service/business/login/loginBusinessService';

// -- Const
// ----------
const VIEW_NAME = '@@COURSE_EXAM_INSTANCE_LIST';

// -- Prop types
// ----------

export interface ICourseExamInstanceListContainerOwnProps {
  courseId: string;
  examTemplateId: string;
}
export interface ICourseExamInstanceListContainerStateProps {
  examInstanceList: ICollectionData<IExamInstance>;
  course: ICourse;
}
export interface ICourseExamInstanceListContainerDispatchProps {
  fetchExamInstanceList: (payload: ICollectionFetchPayload<IExamInstanceListFilter>) => void;
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => ITrackableAction;

  fetchCourse: () => void;
}
type ICourseExamInstanceListContainerProps = ICourseExamInstanceListContainerOwnProps & ICourseExamInstanceListContainerStateProps & ICourseExamInstanceListContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const CourseExamInstanceListContainer = (props: ICourseExamInstanceListContainerProps) => {
  useEffect(() => {
    props.fetchCourse();
  }, [props.courseId]);

  const handleExamTemplateIdUpdate = () => {
    onUpdateList(props.fetchExamInstanceList, { ...collectionParams.filter, template: props.examTemplateId, assignee: getCurrentUser() });
  };
  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IExamInstanceListFilter>(
    {
      viewName: VIEW_NAME,
      updateFn: handleExamTemplateIdUpdate,
    },
    [props.courseId, props.examTemplateId]
  );

  const handleNextPhase = (examInstance: IExamInstance, nextPhaseId: string) => {
    // ** Every exam instance have only one activity therefore, we indexing first one from array */
    props
      .updateActivityNextPhase(examInstance.activities[0].id, { phaseId: nextPhaseId })
      .track()
      .subscribe(
        // success
        () => props.router.push(`/course/${props.courseId}/exam/${examInstance.id}`)
      );
  };

  const resolveConfirmationDialog = (examInstance: IExamInstance) => {
    confirmationDialog({
      title: props.translate('EXAM_INSTANCE.CONFIRM_DIALOG_MESSAGE'),
      okType: 'primary',
      onConfirm: () => handleNextPhase(examInstance, ActivityPhaseEnum.ANSWERING_STARTED),
    });
  };

  return <AppContent>{props.examInstanceList && props.course && <TraineeExamInstanceList course={props.course} examInstances={props.examInstanceList} onExamStart={resolveConfirmationDialog} onPageChange={updateCollectionParams.onPageChange} />}</AppContent>;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseExamInstanceListContainerOwnProps): ICourseExamInstanceListContainerStateProps => ({
  examInstanceList: examInstanceBusinessStore.selectors.getExamInstanceList(state),
  course: courseViewBusinessStore.selectors.getCourseContent(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: ICourseExamInstanceListContainerOwnProps): ICourseExamInstanceListContainerDispatchProps => ({
  fetchExamInstanceList: (payload: ICollectionFetchPayload<IExamInstanceListFilter>) => dispatch(examInstanceBusinessStore.actions.fetchExamInstanceList(payload)),
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityPhase(id, data))),

  fetchCourse: () => dispatch(courseViewBusinessStore.actions.fetchCourseContent({ id: ownProps.courseId })),
});

export default connect<ICourseExamInstanceListContainerStateProps, ICourseExamInstanceListContainerDispatchProps, ICourseExamInstanceListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(CourseExamInstanceListContainer as any)));

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ExternalEducationTemplateDetailsPanel from '@src/components/externaleducation/ExternalEducationTemplateDetailsPanel';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import EducationApplicationDetailsViewSection from '@src/components/externalEducationApplication/view/section/EducationApplicationDetailsViewSection';
import EducationApplicationExpensesViewSection from '@src/components/externalEducationApplication/view/section/EducationApplicationExpensesViewSection';
import EducationApplicationUserViewSection from '@src/components/externalEducationApplication/view/section/EducationApplicationUserViewSection';
import ExternalEducationApplicationActivitiesViewSection from '@src/components/externalEducationApplication/view/section/ExternalEducationApplicationActivitiesViewSection';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IFileListsByType } from '@src/service/business/files/util';

import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { IFile } from '@src/model/file/File';

import EducationApplicationFilesViewSection from '@src/components/externalEducationApplication/view/section/EducationApplicationFilesViewSection';
import IIdRef from '@src/model/common/IdRef';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import RoleUtils from '@src/service/util/role/RoleUtils';
import React from 'react';
import EducationProviderViewSection from '@src/components/externaleducation/EducationProviderViewSection';
import { IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';

// -- Prop types
// ----------
interface IExternalEducationApplicationInfoViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  timeline?: IExternalEducationApplicationTimelineActivity[];
  traineeUser?: IUserInfo;
  canParticipate?: boolean;
  isCurrentUserCoordinator?: boolean;
  isCurrentUserTrainee?: boolean;
  isCurrentUserAdmin?: boolean;
  panelsActive?: boolean;
  canFileRemove?: boolean;

  onCreateNote?: () => void;
  onExpenseSubmit?: (data: IExternalEducationExpense[]) => void;
  onUserCategorySubmit?: (values: IIdRef<string>[]) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}

type IExternalEducationApplicationInfoViewProps = IExternalEducationApplicationInfoViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationInfoViewState {}

// -- Component
// ----------

class ExternalEducationApplicationInfoView extends React.Component<IExternalEducationApplicationInfoViewProps, IExternalEducationApplicationInfoViewState> {
  state: IExternalEducationApplicationInfoViewState = {};

  componentDidMount = () => {
    //
  };
  componentDidUpdate = (prevProps: IExternalEducationApplicationInfoViewProps, prevState: IExternalEducationApplicationInfoViewState) => {
    //
  };

  isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  render() {
    const isConsultationInProgress = this.isApplicationInPhases(['CONSULTATION_REQUESTED', 'CONSULTATION_STARTED', 'CONSULTATION_TERMINATED']);
    return this.props.externalEducationApplication ? (
      <React.Fragment>
        {/* ---------- File list ---------- */}
        <EducationApplicationFilesViewSection
          externalEducationApplication={this.props.externalEducationApplication}
          applicationFiles={this.props.applicationFiles}
          onFileUpload={this.props.onFileUpload}
          canFileRemove={this.props.canFileRemove}
          onFileRemove={this.props.onFileRemove}
          canUpload={this.props.canParticipate || this.props.isCurrentUserAdmin}
          active={this.props.panelsActive}
        />

        {/* ---------- Application info ---------- */}
        {!isConsultationInProgress && (
          <EducationApplicationDetailsViewSection
            active={this.props.panelsActive}
            educationApplicationDetails={this.props.externalEducationApplication}
            onUserCategorySubmit={this.props.onUserCategorySubmit}
            canEditUserCategory={
              this.props.canParticipate &&
              this.isApplicationInPhases([
                'SUBMITTED',
                'AWAITING_APPROVAL',
                'RETURNED_TO_USER',
                'EXTERNAL_EDUCATION_TEMPLATE_SELECTION',
                'CONTRACT_DATA_PROCESSING',
                'CONTRACT_GENERATION',
                'AWAITING_SIGNED_CONTRACT',
                'AWAITING_PAYMENT',
                'EDUCATION_IN_PROGRESS',
                'CONTRACT_ANNEX_REQUESTED',
                'CONTRACT_ANNEX_SUBMITTED',
                'USER_CANCELED',
                'CONTRACT_TERMINATED',
              ])
            }
          />
        )}

        {/* ---------- Common application data ---------- */}

        {/* ---------- Activities list ---------- */}
        {this.props.timeline && (
          <ExternalEducationApplicationActivitiesViewSection
            canCreateNotes={this.props.canParticipate}
            isNotePublic={this.props.isCurrentUserTrainee}
            canSetPrivateNote={this.props.isCurrentUserCoordinator}
            onCreateNote={this.props.onCreateNote}
            id={this.props.externalEducationApplication.id}
            timeline={this.props.timeline}
            active={this.props.panelsActive}
          />
        )}

        {/* ---------- Education info ---------- */}
        {!isConsultationInProgress && (
          <React.Fragment>{this.props.externalEducationApplication.externalEducationTemplate && <ExternalEducationTemplateDetailsPanel externalEducationTemplate={this.props.externalEducationApplication.externalEducationTemplate} active={this.props.panelsActive} collapsible={true} />}</React.Fragment>
        )}

        {/* ---------- Education provider ---------- */}
        {this.props.externalEducationApplication.externalEducationTemplate && <EducationProviderViewSection active={true} collapsible={true} educationProvider={this.props.externalEducationApplication.externalEducationTemplate?.educationProvider} />}

        {/* ---------- Expenses list ---------- */}
        {this.props.externalEducationApplication?.expenses && !isConsultationInProgress && this.props.externalEducationApplication?.expenses.length > 0 && (
          <EducationApplicationExpensesViewSection
            active={true}
            onExpenseSubmit={this.props.onExpenseSubmit}
            disabled={!this.props.canParticipate}
            isCurrentUserCoordinator={this.props.isCurrentUserCoordinator}
            onFileUpload={this.props.onFileUpload}
            externalEducationApplication={this.props.externalEducationApplication}
          />
        )}

        {/* ---------- Trainee info ---------- */}
        {this.props.traineeUser && this.props.applicationFiles ? (
          <EducationApplicationUserViewSection
            active={this.props.panelsActive}
            user={this.props.traineeUser}
            externalEducationApplication={this.props.externalEducationApplication}
            files={this.props.applicationFiles}
            canParticipate={this.props.canParticipate}
            showNotzData={RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COORDINATOR], this.props.currentUser.roles)}
          />
        ) : null}
      </React.Fragment>
    ) : null;
  }
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationInfoViewOwnProps>(ExternalEducationApplicationInfoView as any));

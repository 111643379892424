import { FormComponentProps } from '@ant-design/compatible/lib/form';
import LocationDataPicker from '@src/components/common/datapicker/LocationDataPicker';
import CurrencyInput from '@src/components/common/input/CurrencyInput';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { FinancingAgreemntTypeEnum } from '@src/model/externalEducationApplication/ExternalEducationApplicationFilePreviewData';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';

const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');
const Option = Select.Option;

interface IExternalEducationApplicationAgreementFormValues extends Pick<IExternalEducationApplication, 'districtOffice' | 'offerCode'> {
  educationExpenseValue: number;
  coFinancedEducationPrice: number;
  financingAgreementType: FinancingAgreemntTypeEnum;
}

interface IExternalEducationApplicationAgreementFormOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  disabled?: boolean;
  onSubmit?: (data: IExternalEducationApplication) => void;
}

type IExternalEducationApplicationAgreementFormProps = IExternalEducationApplicationAgreementFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

const ExternalEducationApplicationAgreementForm = (props: IExternalEducationApplicationAgreementFormProps) => {
  const [form] = Form.useForm<IExternalEducationApplicationAgreementFormValues>();
  const educationExpense = useMemo(() => EducationApplicationHelperUtils.getExpenseByType(props.externalEducationApplication.expenses, ExpeneseTypeEnum.EDUCATION_OFFER_PRICE), [props.externalEducationApplication.id]);
  const coFinancedEducationPrice = useMemo(() => EducationApplicationHelperUtils.getExpenseByType(props.externalEducationApplication.expenses, ExpeneseTypeEnum.CO_FINANCED_EDUCATION_PRICE), [props.externalEducationApplication.id]);

  const handleFinish = () => {
    form.validateFields().then((values: IExternalEducationApplicationAgreementFormValues) => {
      const educationExpenseData = educationExpense?.id
        ? { ...educationExpense, value: values.educationExpenseValue }
        : ({
            value: values.educationExpenseValue,
            paymentDate: moment().format(backendDate),
            expenseType: { id: ExpeneseTypeEnum.EDUCATION_OFFER_PRICE },
            externalEducationApplication: props.externalEducationApplication,
          } as IExternalEducationExpense);

      const coFinancedEducationPriceData = coFinancedEducationPrice?.id
        ? { ...coFinancedEducationPrice, value: values.coFinancedEducationPrice }
        : ({
            value: values.coFinancedEducationPrice,
            paymentDate: moment().format(backendDate),
            expenseType: { id: ExpeneseTypeEnum.CO_FINANCED_EDUCATION_PRICE },
            externalEducationApplication: props.externalEducationApplication,
          } as IExternalEducationExpense);

      const externalEducationApplicationData: IExternalEducationApplication = {
        ...props.externalEducationApplication,
        offerCode: values.offerCode,
        districtOffice: values.districtOffice,
        financingAgreementType: values.financingAgreementType,
        expenses: [educationExpenseData, coFinancedEducationPriceData],
      };

      props.onSubmit?.(externalEducationApplicationData);
    });
  };

  return (
    <Form<IExternalEducationApplicationAgreementFormValues> layout="vertical" form={form} name="externalEducationApplication" onFinish={handleFinish}>
      <Row gutter={8}>
        <Col>
          <Form.Item
            label={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CODE_OFFER_LABEL')}
            initialValue={props.externalEducationApplication.offerCode}
            name={'offerCode'}
            required={true}
            rules={[{ required: true, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.CODE_OFFER_MESSAGE') }]}
          >
            <Input disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CODE_OFFER_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationAgreementForm__offerCode" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.EDUCATION_EXPENSE_LABEL')}
            initialValue={educationExpense?.value}
            name={'educationExpenseValue'}
            required={true}
            rules={[{ required: true, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.EDUCATION_EXPENSE_MESSAGE') }]}
          >
            <CurrencyInput disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.EDUCATION_EXPENSE_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationAgreementForm__educationExpenseValue" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CO_FINANCED_EDUCATION_PRICE_LABEL')}
            initialValue={coFinancedEducationPrice?.value}
            name={'coFinancedEducationPrice'}
            rules={[{ required: true, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.CO_FINANCED_EDUCATION_PRICE_MESSAGE') }]}
            required={true}
          >
            <CurrencyInput disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CO_FINANCED_EDUCATION_PRICE_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationAgreementForm__coFinancedEducationPrice" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.DISTRICT_OFFICE_ADDRESS_LABEL')}
        initialValue={props.externalEducationApplication.districtOffice}
        name={'districtOffice'}
        rules={[{ required: true, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.DISTRICT_OFFICE_ADDRESS_MESSAGE') }]}
        required={true}
      >
        <LocationDataPicker disabled={props.disabled} />
      </Form.Item>

      <Form.Item
        label={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.FINANCING_AGREEMENT_TYPE_LABEL')}
        initialValue={props.externalEducationApplication.financingAgreementType}
        name={'financingAgreementType'}
        rules={[{ required: true, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.FINANCING_AGREEMENT_TYPE_MESSAGE') }]}
        required={true}
      >
        <Select disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.FINANCING_AGREEMENT_TYPE_PLACEHOLDER')}>
          {Object.keys(FinancingAgreemntTypeEnum).map((type) => (
            <Option key={type} value={FinancingAgreemntTypeEnum[type as keyof typeof FinancingAgreemntTypeEnum]}>
              {' '}
              {props.translate(`EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.FINANCING_AGREEMENT_TYPE_LABEL.${type}`)}{' '}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={props.disabled} data-test-id="timun-externalEducationApplicationAgreementForm__submitButton">
          {props.translate('COMMON.ACTION_SAVE')}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default withLocalize<IExternalEducationApplicationAgreementFormOwnProps>(ExternalEducationApplicationAgreementForm as any);

import { Radar } from '@ant-design/charts';
import { RadarConfig } from '@ant-design/charts/es/radar';
import ScrollableContainer from '@src/components/common/container/ScrollableContainer';
import React, { PropsWithChildren, useMemo } from 'react';

// "padding" inside rendered canvas of radar chart
const RADAR_CHART_CANVAS_PADDING = [60, 0, 30, 0];

export interface IChartDataItem {
  title: string;
  level: number;
  series: string;
}

export interface ITimunRadarChartOwnProps {
  chartData: IChartDataItem[][];
  minLevel?: number;
  maxLevel?: number;
}

type ITimunRadarChartProps = ITimunRadarChartOwnProps;

const getRadarData = (data: IChartDataItem[][]) => {
  // flatten contained arrays into one and sort elements because radar renders them as they come, will result in a jumbled mess otherwise
  return data.flat().sort((a, b) => {
    if (a.title.toUpperCase() > b.title.toUpperCase()) {
      return 1;
    }
    if (a.title.toUpperCase() < b.title.toUpperCase()) {
      return -1;
    }

    return 0;
  });
};

/** Radar graph component, agnostic of amount of series passed
 *
 *  Pass to props arrays of objects you wish to render in chart.
 *  Give fieldTitle name of the property to look for in given arrays of objects for name of the "measured" value
 *  Give fieldLevel name of the property to look for in given arrays of objects for the amount/level.
 *  Give fieldSeries name of the property to look for in given arrays of objects for the name of series that has the measures.
 *
 *  ie. if a given Type <T> has a property "name": "string", "maximum": "number", "user": "string" etc.
 *  you would pass fieldSeries: 'name', fieldLevel: 'maximum', series: 'user', data: [objects that have given properties]
 *
 *  In case the measured series come from two or more different types, manually remap into IChartDataItem
 *
 */
const TimunRadarChart = (props: PropsWithChildren<ITimunRadarChartProps>) => {
  const config: RadarConfig = useMemo(
    () => ({
      data: getRadarData(props.chartData),
      xField: 'title',
      yField: 'level',
      seriesField: 'series',
      meta: {
        level: {
          min: props.minLevel ?? 0,
          max: props.maxLevel,
        },
      },
      xAxis: {
        label: {
          // auto ellispis doesn't work
          autoEllipsis: true,
          // auto ellispis formatter fn, add ellipsis after 39th character
          formatter: (text: string, item: any, index: number): any => {
            if (text.length < 40) {
              return text;
            } else return text.slice(0, 40) + '...';
          },
        },
        line: null,
        tickLine: null,
        grid: {
          line: {
            style: {
              lineDash: null,
            },
          },
        },
      },
      yAxis: {
        line: null,
        tickLine: null,
        grid: {
          line: {
            type: 'line',
            style: {
              lineDash: null,
            },
          },
          alternateColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
      area: {}, // fills the middle with transparent color :shrug:
      renderer: 'svg',
      autoFit: true,
      padding: RADAR_CHART_CANVAS_PADDING,
    }),
    [props.chartData]
  );
  return (
    <ScrollableContainer>
      <Radar {...config} />
    </ScrollableContainer>
  );
};

export default TimunRadarChart;

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import { IWithCollectionStateOwnProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdminUserGroupListView from '@src/components/usergroup/list/AdminUserGroupListView';
import { IFile } from '@src/model/file/File';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserGroupBusinessStore from '@src/service/business/usergroup/UserGroupBusinessStore';
import UserGroupListBusinessStore, { IUserGroupListFilter } from '@src/service/business/usergroup/UserGroupListBusinessStore';

import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------

const VIEW_NAME = '@@ADMIN_GROUP_LIST';

// -- Prop types
// ----------
export interface IAdminGroupListContainerOwnProps {
  groupType: UserGroupTypeEnum;
}

export interface IAdminGroupListContainerStateProps {
  userGroupList: ICollectionData<IUserGroup>;
  userGroupHierarchy: IUserGroupHierarchy;
}

export interface IAdminGroupListContainerDispatchProps {
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => ITrackableAction;
  uploadUserGroupCoverImage: (id: string, data: IFile) => ITrackableAction;
  deleteUserGroup: (userGroupId: string) => ITrackableAction;
  fetchUserGroupHierarchy: () => void;
}

type IAdminGroupListContainerProps = IAdminGroupListContainerOwnProps & IAdminGroupListContainerStateProps & IAdminGroupListContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IUserGroupListFilter> & WithRouterProps;

// -- Component
// ----------

const AdminGroupListContainer: React.FC<IAdminGroupListContainerProps> = (props: IAdminGroupListContainerProps) => {
  const updateGroupList = () => {
    onUpdateList(props.fetchUserGroupList, { ...collectionParams.filter, type: props.groupType });
  };

  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IUserGroupListFilter>(
    {
      viewName: VIEW_NAME,
      updateFn: updateGroupList,
      initialValues: { filter: { type: props.groupType } },
    },
    [props.groupType]
  );

  useEffect(() => {
    if (props.groupType === UserGroupTypeEnum.ORGANIZATION) {
      props.fetchUserGroupHierarchy();
    }
  }, [props.groupType, props.fetchUserGroupHierarchy]);

  const handleDataUpdate = useCallback(() => {
    onUpdateList();
    if (props.groupType === UserGroupTypeEnum.ORGANIZATION) {
      props.fetchUserGroupHierarchy();
    }
  }, [onUpdateList, props.groupType, props.fetchUserGroupHierarchy]);

  const handleUserGroupDelete = useCallback(
    (userGroupId: string) => {
      props
        .deleteUserGroup(userGroupId)
        .track()
        .subscribe(() => handleDataUpdate());
    },
    [props.deleteUserGroup, handleDataUpdate]
  );

  const handleUserGroupCoverSubmit = useCallback(
    (data: IFile, selectedUserGroup: IUserGroup) => {
      props
        .uploadUserGroupCoverImage(selectedUserGroup?.id, data)
        .track()
        .subscribe(() => handleDataUpdate());
    },
    [props.uploadUserGroupCoverImage, handleDataUpdate]
  );

  return (
    <AdminUserGroupListView
      returnRoute={props.location?.pathname}
      groupType={props.groupType}
      userGroupList={props.userGroupList}
      userGroupHierarchy={props.userGroupHierarchy}
      userGroupListFilter={collectionParams.filter}
      onDataUpdate={handleDataUpdate}
      onPageChange={updateCollectionParams.onPageChange}
      onUserGroupDelete={handleUserGroupDelete}
      onUserGroupCoverSubmit={handleUserGroupCoverSubmit}
    />
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAdminGroupListContainerStateProps => ({
  userGroupList: UserGroupListBusinessStore.selectors.getUserGroupList(state),
  userGroupHierarchy: UserGroupListBusinessStore.selectors.getUserGroupHierarchy(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminGroupListContainerDispatchProps => ({
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => createTrackableAction(dispatch(UserGroupListBusinessStore.actions.fetchUserGroupList(params))),
  uploadUserGroupCoverImage: (id: string, data: IFile) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.uploadUserGroupCoverImage(id, data))),
  deleteUserGroup: (userGroupId: string) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.deleteUserGroup(userGroupId))),
  fetchUserGroupHierarchy: () => dispatch(UserGroupListBusinessStore.actions.fetchUserGroupHierarchy()),
});

export default connect<IAdminGroupListContainerStateProps, IAdminGroupListContainerDispatchProps, IAdminGroupListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(AdminGroupListContainer as any)));

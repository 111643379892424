import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { RouterNavigationPromptContext } from '@src/components/common/route/prompt/RouterNavigationPromptContext';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import UserInterfaceUtils from '@src/components/common/util/UserInterfaceUtils';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import RelatedObjects from '@src/components/course/RelatedObjects';
import CourseLectureContentUpdateForm from '@src/components/course/update/CourseLectureContentUpdateForm';
import CourseUpdateForm from '@src/components/course/update/CourseUpdateForm';
import CourseViewLectureContent from '@src/components/course/view/CourseViewLectureContent';
import CourseViewSider from '@src/components/course/view/CourseViewSider';
import TagContainer from '@src/components/tag/TagContainer';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import NoteListViewContainer from '@src/components/user/view/NoteListViewContainer';
import { UserNoteUtils } from '@src/components/usernote/utils/UserNoteUtils';
import { CommentObjectTypeEnum } from '@src/model/comment/CommentObjectType';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { ICourseExam } from '@src/model/course/CourseExam';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ILecture, ILectureWithContent } from '@src/model/course/Lecture';
import { ICourseLectureGroup, ICourseLectureListElement } from '@src/model/course/LectureGroup';
import { IFile } from '@src/model/file/File';
import { TagItemTypeEnum } from '@src/model/tag/TagItemType';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { UserRoleEnum } from '@src/model/user/UserRole';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import courseUpdateBusinessStore, { ICourseUpdate, ILectureCreatePayload, ILectureGroupCreatePayload } from '@src/service/business/courses/courseUpdateBusinessStore';
import courseViewBusinessStore, { ICourseLectureListFilter } from '@src/service/business/courses/courseViewBusinessStore';
import examTemplateBusinessStore, { IExamTemplateCreatePayload } from '@src/service/business/examtemplates/examTemplateBusinessStore';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { ITrackableEvent } from '@src/service/util/event/trackEvent';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { Button, Col, Layout, Row, Typography } from 'antd';
import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';
import { map, mergeMap } from 'rxjs/operators';

// -- Const
// ----------
const { Header, Sider, Content } = Layout;

// -- Prop types
// ----------

export interface ICourseUpdateContainerOwnProps {
  courseId?: string;
  lectureId: string;
}

export interface ICourseUpdateContainerStateProps {
  courseGroups: ICourseGroup[];
  course: ICourse;
  courseLectureList: ICourseLectureListElement[];
  lecture: ILectureWithContent;
  lectureFileList: ICollectionData<IFile>;
}

export interface ICourseUpdateContainerDispatchProps {
  fetchCourseContent: (courseId: string) => void;
  clearCourseContentData: () => void;
  updateCourse: (data: ICourseUpdate, callback: IActionThunkMap) => void;
  deleteCourse: (id: string, callback: IActionThunkMap) => void;
  uploadCourseCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => void;
  copyCourse: (id: string) => ITrackableAction;
  fetchCourseLectureList: (courseId: string, listFilter: ICourseLectureListFilter) => void;
  clearCourseLectureList: () => void;

  createLectureGroup: (data: ILectureGroupCreatePayload, sourceEvent?: ITrackableEvent<string>) => ITrackableAction;
  updateLectureGroup: (data: ICourseLectureGroup, callback: IActionThunkMap) => void;
  deleteLectureGroup: (id: string, callback: IActionThunkMap) => void;

  fetchLectureContent: (lectureId: string) => ITrackableAction;
  clearCourseLectureContentData: () => void;
  createLecture: (data: ILectureCreatePayload, callback: IActionThunkMap) => void;
  updateLecture: (data: ILecture, callback: IActionThunkMap) => void;
  deleteLecture: (id: string, callback: IActionThunkMap) => void;
  uploadLectureVideo: (id: string, data: IFile) => ITrackableAction;
  removeLectureVideo: (id: string) => ITrackableAction;

  fetchLectureFileList: (lectureId: string) => any;
  clearLectureFileList: () => void;
  addLectureFile: (lectureId: string, data: IFile[]) => ITrackableAction<IFile[]>;
  removeLectureFile: (lectureId: string, data: IFile[], callback: IActionThunkMap) => void;

  createExamTemplate: (data: IExamTemplateCreatePayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;

  updateCourseStatus: (courseId: string, data: CourseStatusEnum) => void;
}

type ICourseUpdateContainerProps = ICourseUpdateContainerOwnProps & IWithTenantPropEnabledOwnProps & ICourseUpdateContainerStateProps & ICourseUpdateContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps & IWithRolesOwnProps;

// -- Component
// ----------

/** container for the display&edit of a course and lectures inside */
const CourseUpdateContainer: React.FC<ICourseUpdateContainerProps> = (props: ICourseUpdateContainerProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const { setIsRouterPromptActive } = useContext(RouterNavigationPromptContext);

  useEffect(() => {
    // on component mount/update
    if (props.courseId) {
      fetchCourse();
      updateCourseLectureList();
    }
    if (props.lectureId) {
      if (CourseHelperUtils.isLecture(props.lectureId)) {
        fetchLectureCourse();
        fetchLectureFileList();
        updateLectureContent();
      }
      if (editing) {
        setEditing(false);
      }
    } else {
      console.warn('Bad route to course/lecture');
    }
    UserInterfaceUtils.scrollToTop();
  }, [props.courseId, props.lectureId]);

  useEffect(() => {
    // on component unmount
    return () => {
      props.clearCourseContentData();
      props.clearCourseLectureContentData();
      props.clearCourseLectureList();
      props.clearLectureFileList();
    };
  }, []);
  const canDeleteCourse: boolean = useMemo(() => props.allowedRoles([UserRoleEnum.SUPERADMIN]), [props.allowedRoles]); // I have become death, the destroyer of courses.
  const canEditStructure: boolean = useMemo(
    () =>
      (props.allowedRoles([UserRoleEnum.COURSE_CREATOR]) && CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.IN_CREATION])) ||
      (props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.IN_CREATION, CourseStatusEnum.CREATION_FINISHED])) ||
      props.allowedRoles([UserRoleEnum.SUPERADMIN]),
    [props.course, props.allowedRoles, CourseHelperUtils.isCourseInStatus]
  );

  const canSeeExamTemplate: boolean = useMemo(() => props.allowedRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]), [props.allowedRoles]);
  // ** course must be published && user role admins or trener that created course  */
  const canSeeAdministrationButton: boolean = useMemo(
    () => (props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]) || (props.allowedRoles([UserRoleEnum.TRAINER]) && CourseHelperUtils.isCurrentUserCourseCreator(props.course))) && !CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.ARCHIVED]),
    [props.course, props.allowedRoles, CourseHelperUtils.isCourseInStatus, CourseHelperUtils.isCurrentUserCourseCreator]
  );
  const isAdministrationButtonDisabled: boolean = useMemo(
    () => (props.isTenantPropEnabled('admin_skills') ? !CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.PUBLISHED, CourseStatusEnum.IN_CREATION, CourseStatusEnum.CREATION_FINISHED]) : !CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.PUBLISHED])),
    [props.course, props.isTenantPropEnabled, CourseHelperUtils.isCourseInStatus]
  );
  const canSeeCourseCopyButton: boolean = useMemo(() => props.allowedRoles([UserRoleEnum.TRAINER, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]) && CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.PUBLISHED, CourseStatusEnum.CREATION_FINISHED]), [
    props.course,
    props.allowedRoles,
    CourseHelperUtils.isCourseInStatus,
  ]);

  const canEditContent: boolean = useMemo(
    () =>
      (props.allowedRoles([UserRoleEnum.COURSE_CREATOR]) && CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.IN_CREATION])) ||
      (props.allowedRoles([UserRoleEnum.PUBLISHER]) && CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.IN_CREATION, CourseStatusEnum.CREATION_FINISHED])) ||
      props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]),
    [props.course, props.allowedRoles, CourseHelperUtils.isCourseInStatus]
  );

  useEffect(() => {
    if (canEditContent && editing && !deleteAction) {
      setIsRouterPromptActive?.(true);
    }
    if (!editing) {
      setIsRouterPromptActive?.(false);
    }
  }, [canEditContent, editing, deleteAction, setIsRouterPromptActive]);

  const fetchCourse = useCallback(
    (courseId = props.courseId) => {
      if (courseId) {
        props.fetchCourseContent(courseId);
      }
    },
    [props.courseId, props.fetchCourseContent]
  );

  const updateCourseLectureList = useCallback(
    (courseId = props.courseId) => {
      if (courseId) {
        props.fetchCourseLectureList(courseId, {});
      }
      setIsRouterPromptActive?.(false);
    },
    [props.courseId, props.fetchCourseLectureList]
  );

  const handleCourseUpdate = useCallback(
    (data: ICourseUpdate) => {
      props.updateCourse(data, {
        success: () => {
          fetchCourse();
          updateCourseLectureList();
        },
      });
    },
    [props.updateCourse, fetchCourse, updateCourseLectureList]
  );

  const handleCreateExamTemplate = useCallback(
    (title: string) => {
      props
        .createExamTemplate({ title })
        .track()
        .subscribe(
          // sucess
          (response) => {
            const updatedCourse = { ...props.course };
            const quiz = {} as ICourseExam;
            quiz.examTemplate = {
              id: response.id,
            };
            updatedCourse.exam = quiz;
            handleCourseUpdate(updatedCourse);
          }
        );
    },
    [props.createExamTemplate, props.course, handleCourseUpdate]
  );

  const handleCourseDelete = useCallback(
    (data: ICourse) => {
      setDeleteAction(true);
      setIsRouterPromptActive?.(false);
      props.deleteCourse(data.id, {
        success: () => {
          props.router.replace(`/courses`);
        },
      });
    },
    [props.deleteCourse, props.router.replace, setDeleteAction]
  );

  const handleCourseCoverSubmit = useCallback(
    (data: IFile) => {
      if (props.courseId) {
        props.uploadCourseCover(props.courseId, data, {
          success: () => {
            fetchCourse();
          },
        });
      }
    },
    [props.uploadCourseCover, props.courseId, fetchCourse]
  );

  const handleLectureAdd = useCallback(
    (groupId: string, title: string) => {
      const data: ILectureCreatePayload = {
        lectureGroup: { id: groupId },
        title,
      };

      props.createLecture(data, {
        success: (response) => {
          updateCourseLectureList();
          props.router.replace(`/course/administration/${props.courseId}/${response.id}`);
          setEditing(true);
        },
      });
    },
    [props.createLecture, props.courseId, setEditing, props.router.replace]
  );

  const updateLectureContent = useCallback(
    (lectureId = props.lectureId) => {
      props.fetchLectureContent(lectureId);
    },
    [props.lectureId, props.fetchLectureContent]
  );

  const handleLectureUpdate = useCallback(
    (data: ILectureWithContent) => {
      props.updateLecture(data, {
        success: () => {
          updateCourseLectureList();
          updateLectureContent();
        },
      });
    },
    [props.updateLecture, updateCourseLectureList, updateLectureContent]
  );

  const handleLectureDelete = useCallback(
    (data: ILectureWithContent) => {
      setDeleteAction(true);
      props.deleteLecture(data.id, {
        success: () => {
          props.router.replace(`/course/${props.courseId}`);
          setDeleteAction(false);
          updateCourseLectureList();
          setEditing(false);
          props.reportMessage({ message: props.translate('COURSE_VIEW.UPDATE.LECTURE_DELETED_MESSAGE', { lecture: data.title }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
        },
      });
    },
    [props.deleteLecture, props.reportMessage, props.courseId, setDeleteAction, props.router.replace, setDeleteAction, updateCourseLectureList, setEditing]
  );

  const handleLectureGroupAdd = useCallback(
    (title: string, event?: ITrackableEvent<string>) => {
      const data = {
        course: props.course,
        title,
      };
      if (event) {
        props
          .createLectureGroup(data, event)
          .track()
          .subscribe(() => {
            updateCourseLectureList();
          });
      } else {
        props
          .createLectureGroup(data)
          .track()
          .subscribe(() => updateCourseLectureList());
      }
    },
    [props.createLectureGroup, props.course, updateCourseLectureList]
  );

  const handleLectureGroupUpdate = useCallback(
    (data: ICourseLectureGroup) => {
      props.updateLectureGroup(data, {
        success: () => {
          updateCourseLectureList();
        },
      });
    },
    [props.updateLectureGroup, updateCourseLectureList]
  );

  const handleLectureGroupDelete = useCallback(
    (data: ICourseLectureGroup) => {
      props.deleteLectureGroup(data.id, {
        success: () => {
          updateCourseLectureList();
        },
      });
    },
    [props.deleteLectureGroup, updateCourseLectureList]
  );

  const fetchLectureFileList = useCallback(
    (lectureId: string = props.lectureId) => {
      props.fetchLectureFileList(lectureId);
    },
    [props.lectureId, props.fetchLectureFileList]
  );

  const handleLectureFileAdd = useCallback(
    (data: IFile[]) => {
      props
        .addLectureFile(props.lectureId, data)
        .track()
        .subscribe(() => {
          fetchLectureFileList();
        });
    },
    [props.addLectureFile, props.lectureId, fetchLectureFileList]
  );

  const handleCoverVideoSubmit = useCallback(
    (data: IFile) => {
      // this presumes that this is lecture video, in case course should also participate, some if-ology would be required
      props
        .uploadLectureVideo(props.lectureId, data)
        .track()
        .subscribe(() => {
          updateLectureContent();
        });
    },
    [props.uploadLectureVideo, props.lectureId, updateLectureContent]
  );

  const handleCoverVideoRemove = useCallback(() => {
    // this presumes that this is lecture video, in case course should also participate, some if-ology would be required
    props
      .removeLectureVideo(props.lectureId)
      .track()
      .subscribe(() => {
        updateLectureContent();
      });
  }, [props.removeLectureVideo, props.lectureId, updateLectureContent]);

  const handleLectureEditorFileAdd = useCallback(
    (fileBlob: any, fileName: string, success: (fileUrl: string) => void, failure: (err: string) => void) => {
      FileUploadHelper
        // -- upload editor file
        // maybe we could've sent entire blob to redux effect and handle this logic there but we want to handle blobs as close to it's origin (form, editor, ...) as we can to avoid sending blobs throughout app and accidentally keeping their references
        .uploadFile(fileBlob, fileName)
        .pipe(
          // -- add uploaded file to lecture
          mergeMap((file) => {
            return props
              .addLectureFile(props.lectureId, [file])
              .track()
              .pipe(
                // remap back to file to avoid meddling with list
                map((fileList) => file)
              );
          })
        )
        .subscribe(
          (file) => {
            const uploadedFileUrl = UrlBuilderFactory.buildApiFileUrl(file.id);
            console.log(`Uploaded edito file ${uploadedFileUrl}`);
            success(uploadedFileUrl);
          },
          // -- error, notify everyone, abort ship
          (err) => {
            console.error(`Error adding editor file "${fileName}" to lecture ${props.lectureId}`, err);
            failure(props.translate('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR'));
          }
        );
    },
    [props.addLectureFile, props.lectureId, UrlBuilderFactory.buildApiFileUrl]
  );

  const handleLectureFileDelete = useCallback(
    (data: IFile[]) => {
      props.removeLectureFile(props.lectureId, data, {
        success: () => {
          fetchLectureFileList();
        },
      });
    },
    [props.removeLectureFile, props.lectureId, fetchLectureFileList]
  );

  const fetchLectureCourse = useCallback(
    (lectureId = props.lectureId) => {
      props
        .fetchLectureContent(lectureId)
        .track()
        .subscribe((response: ILecture) => {
          fetchCourse(response.course.id);
          updateCourseLectureList(response.course.id);
        });
    },
    [props.lectureId, updateCourseLectureList, fetchCourse, props.fetchLectureContent]
  );

  const handleCourseCopy = useCallback(() => {
    confirmationDialog({
      onConfirm: () =>
        props.courseId &&
        props
          .copyCourse(props.courseId)
          .track()
          .subscribe(
            // Success
            () => {
              props.reportMessage({ message: props.translate('COURSE_VIEW.UPDATE.COURSE_COPY_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
            }
          ),
    });
  }, [props.copyCourse, props.reportMessage, props.courseId, confirmationDialog]);

  const handleUpdateCourseStatus = useCallback(
    (statusId: CourseStatusEnum) => {
      if (props.courseId) {
        props.updateCourseStatus(props.courseId, statusId);
      }
    },
    [props.courseId, props.updateCourseStatus]
  );

  return (
    <AppContent>
      {props.course && (
        <Layout>
          <Header className="timun-courseView__sider-courseTitle">
            <HeaderTitle link={'/courses/administration'} title={props.translate('COURSE_LIST.VIEW_TITLE')} icon={<LemonIcon name="left" />} />
            <Row justify="space-between" align="middle">
              <Col>
                <Typography.Title> {props.course.title} </Typography.Title>
              </Col>
              <Col>
                <Row gutter={4}>
                  {canSeeCourseCopyButton && (
                    <Col>
                      <Button icon={<LemonIcon name="copy" />} onClick={handleCourseCopy}>
                        {props.translate('COURSE_VIEW.UPDATE.COPY_COURSE_BUTTON_LABEL')}
                      </Button>
                    </Col>
                  )}
                  {canSeeAdministrationButton && (
                    <Col>
                      <Link to={props.isTenantPropEnabled('admin_skills') ? `course/${props.course.id}/administration/assignskill` : `course/${props.course.id}/administration/assignindividual`}>
                        <Button disabled={isAdministrationButtonDisabled} icon={<LemonIcon name="edit" />}>
                          {props.translate('COURSE_VIEW.UPDATE.ADMINISTRATION_BUTTON_LABEL')}
                        </Button>
                      </Link>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <TagContainer entityId={props.course.id} entityType={TagItemTypeEnum.COURSE} tags={props.course?.tags && props.course.tags} onEntityTagUpdate={fetchCourse} />
          </Header>
          <Layout>
            <Sider className="timun-courseView__sider" breakpoint="lg" collapsedWidth={0}>
              {props.courseLectureList && (
                <CourseViewSider
                  adminMode={true}
                  lectureList={props.courseLectureList}
                  selectedLecture={props.lectureId}
                  course={props.course}
                  showExamInstanceList={false}
                  canEdit={props.course.status.id === CourseStatusEnum.IN_CREATION}
                  onLectureAdd={handleLectureAdd}
                  onLectureGroupAdd={handleLectureGroupAdd}
                  canSeeExamTemplate={canSeeExamTemplate}
                  onCreateExamTemplate={handleCreateExamTemplate}
                />
              )}
            </Sider>
            <Content className="timun-courseView__content">
              {/* ----- Course about ----- */}
              {CourseHelperUtils.isAboutCourseContent(props.lectureId) &&
                (editing ? (
                  <CourseUpdateForm
                    course={props.course}
                    courseGroups={props.courseGroups}
                    lectureList={props.courseLectureList}
                    canEditStructure={canEditStructure}
                    canDeleteCourse={canDeleteCourse}
                    onEditingChange={setEditing}
                    onCourseUpdate={handleCourseUpdate}
                    onCourseDelete={handleCourseDelete}
                    onLectureGroupDelete={handleLectureGroupDelete}
                    onLectureGroupAdd={handleLectureGroupAdd}
                    onLectureGroupUpdate={handleLectureGroupUpdate}
                  />
                ) : (
                  <React.Fragment>
                    <CourseViewLectureContent
                      title={props.course.title}
                      duration={props.course.duration}
                      body={props.course.content && props.course.content.body}
                      canEdit={canEditContent || canEditStructure}
                      coverImageUrl={props.course.coverImageUrl}
                      onCoverImageSubmit={handleCourseCoverSubmit}
                      onEditingChange={() => setEditing(true)}
                      status={props.course.status}
                      onUpdateCourseStatus={handleUpdateCourseStatus}
                    />
                  </React.Fragment>
                ))}

              {/* Lecture */}
              {CourseHelperUtils.isLecture(props.lectureId) && props.lecture && props.lectureFileList && (
                <React.Fragment>
                  {editing ? (
                    <CourseLectureContentUpdateForm
                      lecture={props.lecture}
                      lectureList={props.courseLectureList}
                      course={props.course}
                      fileList={props.lectureFileList.content}
                      canEditStructure={canEditStructure}
                      onEditingChange={setEditing}
                      onLectureUpdate={handleLectureUpdate}
                      onLectureDelete={handleLectureDelete}
                      onFileAdd={handleLectureFileAdd}
                      onFileDelete={handleLectureFileDelete}
                      onEditorFileAdd={handleLectureEditorFileAdd}
                    />
                  ) : (
                    <React.Fragment>
                      <CourseViewLectureContent
                        title={props.lecture.title}
                        body={props.lecture.content && props.lecture.content.body}
                        coverVideo={props.lecture.integrationVideo}
                        coverVideoFileUrl={props.lecture.videoUrl}
                        fileList={props.lectureFileList.content}
                        canEdit={canEditContent || canEditStructure}
                        onEditingChange={() => setEditing(true)}
                        onCoverVideoSubmit={handleCoverVideoSubmit}
                        onCoverVideoRemove={handleCoverVideoRemove}
                      />
                      <RelatedObjects noteObject={{ objectId: props.course.id, objectTypeId: NoteObjectTypeEnum.COURSE }} commentObject={{ objectId: props.lectureId, objectTypeId: CommentObjectTypeEnum.LECTURE }} />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {/* ----- Course notes ----- */}
              {CourseHelperUtils.isNotesCourseContent(props.lectureId) && props.courseId && <NoteListViewContainer canEditnote={UserNoteUtils.isAuthorCurrentUser} objectId={props.course.id} type={NoteObjectTypeEnum.COURSE} />}
            </Content>
          </Layout>
        </Layout>
      )}
    </AppContent>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseUpdateContainerOwnProps): ICourseUpdateContainerStateProps => ({
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
  course: courseUpdateBusinessStore.selectors.getCourseContent(state),
  courseLectureList: courseUpdateBusinessStore.selectors.getCourseLectureList(state),
  lecture: courseUpdateBusinessStore.selectors.getLectureContent(state),
  lectureFileList: courseUpdateBusinessStore.selectors.getLectureFileList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch, ownProps: ICourseUpdateContainerOwnProps): ICourseUpdateContainerDispatchProps => ({
  fetchCourseContent: (courseId: string) => dispatch(courseUpdateBusinessStore.actions.fetchCourseContent({ id: courseId })),
  clearCourseContentData: () => dispatch(courseUpdateBusinessStore.actions.clearCourseContentData()),
  updateCourse: (data: ICourseUpdate, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.updateCourse(data), thunkMap)),
  deleteCourse: (id: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.deleteCourse({ id }), thunkMap)),
  uploadCourseCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.uploadCourseCover(id, data), thunkMap)),
  copyCourse: (id: string) => createTrackableAction(dispatch(courseUpdateBusinessStore.actions.copyCourseContent(id))),
  fetchCourseLectureList: (courseId: string, listFilter: ICourseLectureListFilter) => dispatch(courseUpdateBusinessStore.actions.fetchCourseLectureList(courseId, listFilter)),
  clearCourseLectureList: () => dispatch(courseUpdateBusinessStore.actions.clearCourseLectureList()),

  createLectureGroup: (data: ILectureGroupCreatePayload, sourceEvent?: ITrackableEvent<string>) => createTrackableAction(dispatch(courseUpdateBusinessStore.actions.createLectureGroup(data)), sourceEvent),
  updateLectureGroup: (data: ICourseLectureGroup, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.updateLectureGroup(data), thunkMap)),
  deleteLectureGroup: (id: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.deleteLectureGroup({ id }), thunkMap)),

  fetchLectureContent: (id: string) => createTrackableAction(dispatch(courseUpdateBusinessStore.actions.fetchLectureContent({ id }))),
  clearCourseLectureContentData: () => dispatch(courseUpdateBusinessStore.actions.clearLectureContentData()),
  createLecture: (data: ILectureCreatePayload, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.createLecture(data), thunkMap)),
  updateLecture: (data: ILecture, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.updateLecture(data), thunkMap)),
  deleteLecture: (id: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.deleteLecture({ id }), thunkMap)),
  uploadLectureVideo: (id: string, data: IFile) => dispatch(createTrackableAction(courseUpdateBusinessStore.actions.uploadLectureVideo(id, data))),
  removeLectureVideo: (id: string) => dispatch(createTrackableAction(courseUpdateBusinessStore.actions.removeLectureVideo(id))),

  fetchLectureFileList: (lectureId: string) => dispatch(courseUpdateBusinessStore.actions.fetchLectureFileList(lectureId)),
  clearLectureFileList: () => dispatch(courseUpdateBusinessStore.actions.clearLectureFileList()),
  addLectureFile: (lectureId: string, data: IFile[]) => createTrackableAction(dispatch(courseUpdateBusinessStore.actions.addLectureFile(lectureId, data))),
  removeLectureFile: (lectureId: string, data: IFile[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.removeLectureFile(lectureId, data), thunkMap)),

  createExamTemplate: (data: IExamTemplateCreatePayload) => createTrackableAction(dispatch(examTemplateBusinessStore.actions.createExamTemplate(data))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),

  updateCourseStatus: (courseId: string, data: CourseStatusEnum) => dispatch(courseViewBusinessStore.actions.updateCourseStatus(courseId, data)),
});

export default connect<ICourseUpdateContainerStateProps, ICourseUpdateContainerDispatchProps, ICourseUpdateContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRouter(withTenantPropEnabled(withRoles(withLocalize(CourseUpdateContainer as any)))));

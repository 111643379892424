import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import FilePreviewModal from '@src/components/common/file/FilePreviewModal';
import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import { IFile } from '@src/model/file/File';
import { ITimunFile } from '@src/service/business/files/util';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { Col, Empty, Row, Tooltip } from 'antd';
import React from 'react';

// -- Prop types
// ----------

interface IFileListOwnProps<T> {
  fileList: T[];
  fileColWidth: number;
  showActions?: boolean;
  renderAdditionalCols?: () => IListAdditionalCol[];
  onRemoveFile?: (data: T) => void;
}

type IFileListProps<T> = IFileListOwnProps<T>;

interface IFileListState {
  fileToDisplay?: ITimunFile;
}

// -- Component
// ----------

class FileList<T extends IFile> extends React.Component<IFileListProps<T>, IFileListState> {
  state: IFileListState = {
    fileToDisplay: undefined,
  };
  render = () => {
    const additionalCols = this.props.renderAdditionalCols ? this.props.renderAdditionalCols() : [];
    return (
      <React.Fragment>
        <Row className="timun-fileList__header">
          <Col xs={10} span={this.props.fileColWidth}>
            {LocalizeService.translate('USER_VIEW.DOCUMENTS_TABLE_DOCUMENT_LABEL')}
          </Col>
          {additionalCols.map((col) => {
            return (
              <Col className={col.className} key={col.key} {...col.responsiveWidth}>
                {col.headerTitle}
              </Col>
            );
          })}
        </Row>
        {/* CONTENT */}
        {this.props.fileList.length ? (
          <div className="timun-fileList__itemContainer">
            {this.props.fileList.map((file) => {
              return (
                <Row key={file.id} className="timun-fileList__item">
                  <Col span={this.props.fileColWidth}>
                    <LemonIcon name="paperClip" />
                    &nbsp;{file.name}
                  </Col>
                  {additionalCols.map((col) => {
                    return (
                      <Col className={col.className} key={col.key} {...col.responsiveWidth}>
                        {col.content(file)}
                      </Col>
                    );
                  })}
                  {/* ACTIONS */}
                  <Col lg={2} md={2} xs={4}>
                    <Row className="text-center" justify="space-around">
                      <Col span={8}>
                        {FileUtils.isFilePreviewable(file.mimeType) && (
                          <a onClick={() => this.toggleImagePreviewVisible(file)}>
                            <Tooltip title={LocalizeService.translate('COMMON.ACTION_PREVIEW')}>
                              <LemonIcon name="eye" />
                            </Tooltip>
                          </a>
                        )}
                      </Col>
                      <Col span={8}>
                        <a href={UrlBuilderFactory.buildApiFileUrl(file.id)} target="_self">
                          <Tooltip title={LocalizeService.translate('COMMON.ACTION_DOWNLOAD')}>
                            <LemonIcon name="download" />
                          </Tooltip>
                        </a>
                      </Col>
                      {this.props.showActions && (
                        <Col span={8}>
                          {this.props.onRemoveFile && (
                            <a>
                              <Tooltip title={LocalizeService.translate('COMMON.ACTION_DELETE')}>
                                <DeleteElementButton<T> item={file} titlePropPath={'name'} iconOnly={true} icon={<LemonIcon name="times" />} onDelete={this.props.onRemoveFile} />
                              </Tooltip>
                            </a>
                          )}
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </div>
        ) : (
          <Empty description={LocalizeService.translate('REPOSITORY.DOCUMENTS_EMPTY_LABEL')} />
        )}
        {this.state.fileToDisplay && <FilePreviewModal closeModal={this.handleClosePreview} record={this.state.fileToDisplay} />}
      </React.Fragment>
    );
  };

  toggleImagePreviewVisible = (_file: IFile) => {
    this.setState({ fileToDisplay: FileUtils.mapToTimunFile(_file) });
  };

  handleClosePreview = () => {
    this.setState({
      fileToDisplay: undefined,
    });
  };
}

// -- HOCs and exports
// ----------

export default FileList;

import { IFile } from '@src/model/file/File';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { actionThunk } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

export interface IFileListFilter {
  tags?: string[];
  searchTerm?: string;
}

const FILE_LIST_FILTER_NAME = 'FILE_LIST_FILTER_NAME';

// -
// -------------------- Selectors

const getFileList = (store: any): ICollectionData<IFile> => store.fileList;
const getFileListFilter = (store: any): IFileListFilter => ListFilterBusinessStore.selectors.getListFilter(store, FILE_LIST_FILTER_NAME);

// -
// -------------------- Actions

const Actions = {
  FILE_LIST_FETCH_ALL: 'FILE_LIST_FETCH_ALL',
  FILE_LIST_FETCH: 'FILE_LIST_FETCH',
  FILE_LIST_LOAD: 'FILE_LIST_LOAD',
  FILE_LIST_CLEAR: 'FILE_LIST_CLEAR',
  FILE_LIST_ADD_FILES: 'FILE_LIST_ADD_FILES',
  FILE_LIST_FOLDER_FETCH: 'FILE_LIST_FOLDER_FETCH',
  FILE_LIST_REMOVE_FILES: 'FILE_LIST_REMOVE_FILES',
};

/** Fetch files by folder id */
const fetchFileList = (folderId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>> => {
  return {
    type: Actions.FILE_LIST_FETCH,
    payload: {
      id: folderId,
      data: {
        filter: listFilter,
        page,
        size,
        sort,
      },
    },
  };
};

/** Fetch all files in repository */
const fetchRepositoryFileList = (listFilter: IFileListFilter, page: number, size: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<IFileListFilter>> => {
  return {
    type: Actions.FILE_LIST_FETCH_ALL,
    payload: {
      filter: listFilter,
      page,
      size,
      sort,
    },
  };
};

/** Fetch folder info by id */
const fetchFolderInfo = (folderId: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.FILE_LIST_FOLDER_FETCH,
    payload: folderId,
  };
};

/** Load files to store */
const loadFileList = (data: ICollectionData<IFile>): IPayloadAction<ICollectionData<IFile>> => {
  return {
    type: Actions.FILE_LIST_LOAD,
    payload: data,
  };
};

/** Clear file list */
const clearFileList = (): ILemonAction => {
  return {
    type: Actions.FILE_LIST_CLEAR,
  };
};

/** Add file to target folder */
const addFile = (folderId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.FILE_LIST_ADD_FILES,
    payload: {
      id: folderId,
      data,
    },
  };
};

const removeFile = (folderId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.FILE_LIST_REMOVE_FILES,
    payload: {
      id: folderId,
      data,
    },
  };
};

/** Store reminder list filter to store. */
const storeFileListFilter = (listFilter: IFileListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(FILE_LIST_FILTER_NAME, listFilter);
};

/** Clear reminder list filter from store. */
const clearFileListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(FILE_LIST_FILTER_NAME);
};

// -
// -------------------- Side-effects

const fetchFileListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.FILE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Folder')
        .fetchSubentityList(id, 'children', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadFileList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'API_RESPONSE_ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching folder content list', error);
      return o;
    })
  );
};

const fetchRepositoryFileListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IFileListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.FILE_LIST_FETCH_ALL;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      return EntityApiServiceRegistry.getService('Folder')
        .fetchEntityList(action.payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadFileList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'API_RESPONSE_ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching folder content list', error);
      return o;
    })
  );
};

const fetchFolderInfoEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.FILE_LIST_FOLDER_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Folder')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'API_RESPONSE_ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching folder info', error);
      return o;
    })
  );
};

const addFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.FILE_LIST_ADD_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Folder')
        .createSubentityList(id, 'children', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'API_RESPONSE_ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error adding file', error);
      return o;
    })
  );
};

const removeFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.FILE_LIST_REMOVE_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Folder')
        .deleteSubentityList(id, 'children', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      console.log(value);
      return { message: LocalizeService.translate('REPOSITORY.FILE_REMOVED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO };
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'API_RESPONSE_ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error adding file', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const fileList = (state: ICollectionData<IFile> | null = null, action: IPayloadAction<ICollectionData<IFile>>) => {
  if (action.type === Actions.FILE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.FILE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const FileListBusinessStore = {
  actions: {
    fetchFileList,
    fetchRepositoryFileList,
    fetchFolderInfo,
    loadFileList,
    clearFileList,
    addFile,
    storeFileListFilter,
    clearFileListFilter,
    removeFile,
  },

  selectors: {
    getFileList,
    getFileListFilter,
  },

  effects: {
    fetchFileListEffect,
    fetchRepositoryFileListEffect,
    fetchFolderInfoEffect,
    addFileEffect,
    removeFileEffect,
  },

  reducers: {
    fileList,
  },
};

// --
// ----- Exports

export default FileListBusinessStore;

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { CourseCompletionStatusEnum, ICourseProgress } from '@src/model/course/Course';
import { Progress } from 'antd';
import { ProgressProps } from 'antd/lib/progress';
import React from 'react';

interface IProgressBarOwnProps {
  progressProps: ProgressProps;
  progress?: ICourseProgress;
  hasExam?: boolean;
}

type IProgressBarProps = IProgressBarOwnProps & IWithLocalizeOwnProps;

class ProgressBar extends React.Component<IProgressBarProps> {
  render() {
    let courseProgressStatus: ProgressProps['status'];
    let courseProgressPercentage;
    const isQuizLectureNext = this.props.progress && this.props.progress.completedLessonsNumber !== 0 && this.props.progress.completedLessonsNumber !== this.props.progress.lessonsNumber;
    const userCompletionStatus = this.props.progress && this.props.progress.completionStatus.id === CourseCompletionStatusEnum.IN_PROGRESS && this.props.progress.completedLessonsNumber === this.props.progress.lessonsNumber;
    const userFailedCourse = this.props.progress && this.props.progress.completionStatus.id === CourseCompletionStatusEnum.FAILED;
    const hasLessons = this.props.progress?.lessonsNumber !== 0;

    if (isQuizLectureNext || userCompletionStatus || (!hasLessons && this.props.hasExam)) {
      courseProgressStatus = 'active';
    }

    if (userFailedCourse) {
      courseProgressStatus = 'exception';
    }

    if (this.props.progress) {
      courseProgressPercentage = this.props.progress ? Math.round((this.props.progress.completedLessonsNumber / this.props.progress.lessonsNumber) * 100) : undefined;
    }

    // Progress at 100% if there are no lessons, but an exam exists
    if (!hasLessons && this.props.hasExam) {
      courseProgressPercentage = 100;
    }

    return (
      <Progress {...this.props.progressProps} percent={courseProgressPercentage} status={courseProgressStatus}>
        {this.props.children}
      </Progress>
    );
  }
}

export default withLocalize<IProgressBarOwnProps>(ProgressBar as any);

import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { ColumnProps } from 'antd/lib/table';
import { get } from 'lodash';

export interface IVisibleColumn<T> {
  [key: string]: boolean;
}

export default class DataPickerHelperUtils {
  static setColumns = <T>(columnsData: Array<ITimunDataTableCol<T>>, visibleColumns?: IVisibleColumn<T>): Array<ColumnProps<T>> => {
    let columns;
    if (visibleColumns) {
      columns = columnsData.filter((column) => visibleColumns[column.key]);
    } else {
      columns = columnsData;
    }
    columns = columns.map((column) => {
      return {
        key: column.key.toString(),
        className: column.className,
        title: column.headerTitle,
        width: column.colWidth,
        render: (record: T) => (column.content ? column.content(record) : get(record, column.key)),
      };
    });
    return columns;
  };
}

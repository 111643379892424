import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExternalEducationUserCategoryModal from '@src/components/externalEducationApplication/form/ExternalEducationUserCategoryModal';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import IIdRef from '@src/model/common/IdRef';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ISkill } from '@src/model/skillgroup/Skill';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { DateUtils } from '@src/service/util/date/DateUtils';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Button, Tag } from 'antd';
import React, { SyntheticEvent, useCallback, useState } from 'react';

// -- Prop types
// ----------
export interface IEducationApplicationDetailsViewSectionOwnProps {
  educationApplicationDetails?: IExternalEducationApplication;
  active?: boolean;
  canEditUserCategory?: boolean;
  onUserCategorySubmit?: (values: IIdRef<string>[]) => void;
}

type IEducationApplicationDetailsViewSectionProps = IEducationApplicationDetailsViewSectionOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Component wrapped with viewSectionItem displaying application details */
const EducationApplicationDetailsViewSection: React.FC<IEducationApplicationDetailsViewSectionProps> = (props: IEducationApplicationDetailsViewSectionProps) => {
  const [isUserCategoryModalVisible, setIsUserCategoryModalVisible] = useState<boolean>(false);
  const isUserCategoryVisible = RoleUtils.allowedRoles([UserRoleEnum.COORDINATOR, UserRoleEnum.ORGANIZATION_ADMIN], props.currentUser.roles);
  const handleUserCategoryModalOpen = (event: SyntheticEvent) => {
    event.stopPropagation();
    setIsUserCategoryModalVisible(true);
  };
  const handleUserCategoryModalClose = () => setIsUserCategoryModalVisible(false);
  const renderSkillTag = useCallback(
    (skill: ISkill) => {
      return (
        <Tag color="blue" key={skill.id}>
          {skill.title}
        </Tag>
      );
    },
    [props.educationApplicationDetails?.id]
  );
  return (
    <React.Fragment>
      <ViewSectionItem
        bordered={true}
        active={props.active}
        header={<HeaderTitle title={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.TITLE')} icon={<LemonIcon name="clipboard" />} />}
        collapsible={true}
        actions={isUserCategoryVisible && props.canEditUserCategory && <Button onClick={handleUserCategoryModalOpen}>{props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.USER_CATEGORY_BUTTON_LABEL')}</Button>}
      >
        {isUserCategoryVisible && (
          <GridItem ariaLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.USER_CATEGORY_TITLE')} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.USER_CATEGORY_TITLE')}>
            {props.educationApplicationDetails && props.educationApplicationDetails.userCategories?.length >= 1 && props.educationApplicationDetails?.userCategories.map((userCategory) => <Tag key={userCategory.id}> {userCategory.name}</Tag>)}
          </GridItem>
        )}

        <GridItem ariaLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.SKILL_ACQUISITION_TITLE')} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.SKILL_ACQUISITION_TITLE')}>
          {props.educationApplicationDetails?.skill ? renderSkillTag(props.educationApplicationDetails.skill) : null}
        </GridItem>

        <GridItem ariaLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.JOB_RELATED_TITLE')} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.JOB_RELATED_TITLE')}>
          {props.educationApplicationDetails?.jobRelated ? props.translate('COMMON.LABEL.YES') : props.translate('COMMON.LABEL.NO')}
        </GridItem>

        <GridItem ariaLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_RELATED_TITLE')} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_RELATED_TITLE')}>
          {props.educationApplicationDetails?.educationRelated ? props.translate('COMMON.LABEL.YES') : props.translate('COMMON.LABEL.NO')}
        </GridItem>

        <GridItem ariaLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.INTEREST_RELATED_TITLE')} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.INTEREST_RELATED_TITLE')}>
          {props.educationApplicationDetails?.interestRelated ? props.translate('COMMON.LABEL.YES') : props.translate('COMMON.LABEL.NO')}
        </GridItem>

        <GridItem ariaLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.APPLICATION_DESCRIPTION_TITLE')} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.APPLICATION_DESCRIPTION_TITLE')}>
          {props.educationApplicationDetails?.description}
        </GridItem>

        {props.educationApplicationDetails?.userWillingToCoFinance != null && (
          <GridItem ariaLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.USER_WILLING_TO_COFINANCE_TITLE')} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.USER_WILLING_TO_COFINANCE_TITLE')}>
            {props.educationApplicationDetails?.userWillingToCoFinance ? props.translate('COMMON.LABEL.YES') : props.translate('COMMON.LABEL.NO')}
          </GridItem>
        )}

        {props.educationApplicationDetails?.employerWillingToCoFinance != null && (
          <GridItem ariaLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.EMPLOYER_WILLING_TO_COFINANCE_TITLE')} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.EMPLOYER_WILLING_TO_COFINANCE_TITLE')}>
            {props.educationApplicationDetails?.employerWillingToCoFinance ? props.translate('COMMON.LABEL.YES') : props.translate('COMMON.LABEL.NO')}
          </GridItem>
        )}

        {props.educationApplicationDetails?.paymentRequestedDateTime && (
          <GridItem ariaLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.ABOUT_EDUCATION_PROGRAM_TITLE')} label={props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.ABOUT_EDUCATION_PROGRAM_TITLE')}>
            {props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.ABOUT_EDUCATION_PROGRAM_TEXT', {
              paymentRequestedDateTime: DateUtils.toViewDateFormat(props.educationApplicationDetails?.paymentRequestedDateTime, 'dateWithYear'),
              educationProgramStartDateTime: DateUtils.toViewDateFormat(props.educationApplicationDetails?.startDateTime, 'dateWithYear'),
              eudcationProgramEndDateTime: DateUtils.toViewDateFormat(props.educationApplicationDetails?.endDateTime, 'dateWithYear'),
              educationProgramLocation: EducationApplicationHelperUtils.getEducationProgamLocation(props.educationApplicationDetails?.educationLocation),
            })}
          </GridItem>
        )}
      </ViewSectionItem>
      {isUserCategoryModalVisible && <ExternalEducationUserCategoryModal userCategories={props.educationApplicationDetails?.userCategories} onSubmit={props.onUserCategorySubmit} onCancel={handleUserCategoryModalClose} />}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IEducationApplicationDetailsViewSectionOwnProps>(EducationApplicationDetailsViewSection as any));

import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserGroupListBusinessStore, { IUserGroupListFilter } from '@src/service/business/usergroup/UserGroupListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const Option = Select.Option;
const MAX_PAGE_SIZE = AppConfigService.getValue('api.collectionDefaultLimit');

export interface IUserGroupDataPickerOwnProps {
  value?: string[];
  filter?: IUserGroupListFilter;
  onChange?: (value?: string[]) => void;
}

export interface IUserGroupDataPickerStateProps {}

export interface IUserGroupDataPickerDispatchProps {
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => ITrackableAction;
}

type IUserGroupDataPickerProps = IUserGroupDataPickerOwnProps & IUserGroupDataPickerStateProps & IUserGroupDataPickerDispatchProps;

const UserGroupDataPicker = (props: IUserGroupDataPickerProps) => {
  const [userGroupList, setUserGroupList] = useState<IUserGroup[] | undefined>();

  useEffect(() => {
    updateList(props.filter);
  }, []);

  const updateList = (filter: IUserGroupListFilter = {}) => {
    props
      .fetchUserGroupList({
        filter,
        page: 0,
        sort: [],
        size: MAX_PAGE_SIZE,
      })
      .track()
      .subscribe((data: ICollectionData<IUserGroup>) => setUserGroupList(data.content));
  };

  return (
    <Select mode="multiple" allowClear={true} onChange={props.onChange}>
      {userGroupList?.map((userGroup) => {
        return (
          <Option value={userGroup.id} key={userGroup.id}>
            {userGroup.name}
          </Option>
        );
      })}
    </Select>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserGroupDataPickerOwnProps): IUserGroupDataPickerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IUserGroupDataPickerDispatchProps => ({
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => createTrackableAction(dispatch(UserGroupListBusinessStore.actions.fetchUserGroupPickerList(params))),
});

export default connect<IUserGroupDataPickerStateProps, IUserGroupDataPickerDispatchProps, IUserGroupDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(UserGroupDataPicker);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import MemberList from '@src/components/common/member/MemberList';
import UserGroupHelperUtils from '@src/components/usergroup/utils/UserGroupHelperUtils';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { IUserGroupMember } from '@src/model/usergroup/UserGroupMember';
import { IUserGroupMemberRole, UserGroupMemberRoleEnum } from '@src/model/usergroup/UserGroupMemberRole';
import { ICollectionData } from '@src/service/business/common/types';
import RoleUtils from '@src/service/util/role/RoleUtils';
import React from 'react';

// -- Prop types
// ----------

export interface IUserGroupMemberListOwnProps {
  userGroup: IUserGroup;
  userGroupMemberList: ICollectionData<IUserGroupMember>;
  onPageChange?: (page?: number | undefined, size?: number | undefined) => void;
  onAddMember?: (user: IUserGroupMember) => void;
  onUpdateMember?: (user: IUserGroupMember) => void;
  onDeleteMember?: (user: IUserGroupMember) => void;
}
type IUserGroupMemberListProps = IUserGroupMemberListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class UserGroupMemberList extends React.Component<IUserGroupMemberListProps> {
  roleTypes = Object.entries(UserGroupMemberRoleEnum).map(([key, value]) => {
    return { value, label: this.props.translate(`USER_GROUP_MEMBER_LIST.ROLE_TYPE.${key}`) };
  });

  render = () => {
    const canAddMembers = RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) || UserGroupHelperUtils.allowedRoles(['ADMIN', 'OWNER'], this.props.userGroup.currentUserMember?.role);
    const canOpenProfile = RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) || (this.props.userGroup.groupType.id === UserGroupTypeEnum.ORGANIZATION && UserGroupHelperUtils.allowedRoles(['ADMIN', 'OWNER'], this.props.userGroup.currentUserMember?.role));

    return (
      <MemberList<IUserGroupMemberRole>
        defaultListType="card"
        canOpenProfile={canOpenProfile}
        memberList={this.props.userGroupMemberList}
        roleTypes={this.roleTypes}
        onPageChange={this.props.onPageChange}
        canEditMember={this.canEditMember}
        canAddMembers={canAddMembers}
        onAddMember={this.props.onAddMember}
        onUpdateMember={this.props.onUpdateMember}
        onDeleteMember={this.props.onDeleteMember}
      />
    );
  };

  canEditMember = (member: IUserGroupMember): boolean => {
    if (RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN])) {
      return true;
    }
    if (UserGroupHelperUtils.allowedRoles(['OWNER'], this.props.userGroup.currentUserMember?.role)) {
      return member.role.id !== UserGroupMemberRoleEnum.OWNER;
    }
    if (UserGroupHelperUtils.allowedRoles(['ADMIN'], this.props.userGroup.currentUserMember?.role)) {
      return member.role.id !== UserGroupMemberRoleEnum.OWNER && member.role.id !== UserGroupMemberRoleEnum.ADMIN;
    }
    return false;
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IUserGroupMemberListOwnProps>(UserGroupMemberList as any);

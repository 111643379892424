import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { classNames } from '@src/components/common/util/classNames';
import UserFormInputFields from '@src/components/user/form/UserFormInputFields';
import { IUserInfo } from '@src/model/user/User';
import { IUserRole, UserRoleEnum } from '@src/model/user/UserRole';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import AppConfigService from '@src/service/common/AppConfigService';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { DatePicker, Form, FormInstance, Input, Select, Typography } from 'antd';
import moment from 'moment';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';

// -- Const
// ----------
const Option = Select.Option;
const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const MIN_USERNAME_LENGTH: number = AppConfigService.getValue('userValidation.minUsernameLength');
const MIN_PASSWORD_LENGTH: number = AppConfigService.getValue('userValidation.minPasswordLength');

// -- Prop types
// ----------

export interface IUserFormOwnProps {
  value?: IUserInfo;
  formRef: FormInstance<IUserInfo>;
  workPositionList?: IWorkPosition[];
  allowRoleChange?: boolean;
  allowPasswordCreate?: boolean;
  onFormFinish?: (values: IUserInfo) => void;
}
type IUserFormProps = IUserFormOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const UserForm: React.FC<IUserFormProps> = (props: IUserFormProps) => {
  const [form] = Form.useForm<IUserInfo>(props.formRef);
  const initialValues = {
    ...props.value,
    roles: props.value?.roles.map((role: IUserRole) => role.id),
    dateOfBirth: props.value?.dateOfBirth ? moment(props.value.dateOfBirth) : undefined,
    workData: { ...props.value?.workData, startDate: props.value?.workData?.startDate ? moment(props.value.workData.startDate) : undefined },
  };

  const getAdminPanelClassName = () => {
    return classNames({
      ['hidden']: !RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]),
      ['panel']: true,
    });
  };

  return (
    <React.Fragment>
      <Form<IUserInfo> initialValues={initialValues} form={form} name="user" onFinish={props.onFormFinish} layout="vertical">
        {/* ---------- Admin part ---------- */}
        {/* -- User role -- */}
        <div className={getAdminPanelClassName()}>
          <Typography.Title className="timun-userModalForm__sectionTitle" level={4}>
            {props.translate('USER_FORM.ADMIN_PANEL_TITLE')}
          </Typography.Title>

          <Form.Item label={props.translate('USER_FORM.ROLES_LABEL')} name="roles" rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]} noStyle={!props.allowRoleChange} hidden={!props.allowRoleChange}>
            <Select mode="multiple" placeholder={props.translate('USER_FORM.ROLES_PLACEHOLDER')}>
              {Object.keys(UserRoleEnum).map((item) => (
                <Option key={UserRoleEnum[item as keyof typeof UserRoleEnum]} value={UserRoleEnum[item as keyof typeof UserRoleEnum]} disabled={item === 'SUPERADMIN'}>
                  {props.translate(`USER_FORM.ROLE_${item}_LABEL`)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* -- Workposition -- */}
          {props.isTenantPropEnabled('admin_workpositions') && (
            <Form.Item label={props.translate('USER_FORM.WORK_POSITION_NAME_LABEL')} name={['workData', 'workPosition', 'id']}>
              <Select placeholder={props.translate('USER_FORM.WORK_POSITION_NAME_PLACEHOLDER')} allowClear={true}>
                {props.workPositionList?.map((item: IWorkPosition) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {/* -- Workposition start date -- */}
          {props.isTenantPropEnabled('admin_workpositions') && (
            <Form.Item label={props.translate('USER_FORM.WORK_POSITION_START_DATE_LABEL')} name={['workData', 'startDate']}>
              <DatePicker placeholder={props.translate('USER_FORM.WORK_POSITION_START_DATE_PLACEHOLDER')} allowClear={true} format={DATE_FORMAT} />
            </Form.Item>
          )}

          {/* -- Username and password (new user) -- */}
          {props.allowPasswordCreate && (
            <React.Fragment>
              <Form.Item
                label={props.translate('USER_FORM.USERNAME_LABEL')}
                name={'username'}
                rules={[
                  { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
                  { min: MIN_USERNAME_LENGTH, message: props.translate('USER_FORM.USERNAME_MINIMUM_LENGTH_VALIDATION_MESSAGE') },
                ]}
              >
                <Input placeholder={props.translate('USER_FORM.USERNAME_PLACEHOLDER')} />
              </Form.Item>
              <Form.Item
                label={props.translate('USER_FORM.PASSWORD_LABEL')}
                name={'password'}
                rules={[
                  { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
                  { min: MIN_PASSWORD_LENGTH, message: props.translate('USER_FORM.PASSWORD_MINIMUM_LENGTH_VALIDATION_MESSAGE') },
                ]}
                hasFeedback={true}
              >
                <Input type="password" placeholder={props.translate('USER_FORM.PASSWORD_PLACEHOLDER')} />
              </Form.Item>
              <Form.Item
                label={props.translate('USER_FORM.CONFIRM_PASSWORD_LABEL')}
                name={'confirmPassword'}
                dependencies={['password']}
                rules={[
                  { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
                  { min: MIN_PASSWORD_LENGTH, message: props.translate('USER_FORM.PASSWORD_MINIMUM_LENGTH_VALIDATION_MESSAGE') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(props.translate('USER_FORM.PASSWORDS_DIFFERENT_ERROR')));
                    },
                  }),
                ]}
                validateFirst={true}
              >
                <Input type="password" placeholder={props.translate('USER_FORM.CONFIRM_PASSWORD_PLACEHOLDER')} />
              </Form.Item>
            </React.Fragment>
          )}
        </div>

        {/* ---------- User part ---------- */}
        <UserFormInputFields formRef={props.formRef} />
      </Form>
    </React.Fragment>
  );
};

export default withLocalize<IUserFormOwnProps>(withTenantPropEnabled(UserForm as any));

import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { RuleObject } from 'antd/lib/form';

// -- Const
// ----------
const { Option } = Select;
const EMAIL_REGEX = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

// -- Prop types
// ----------
export interface IContactDataPropertyInputOwnProps {
  formRef: FormInstance<any>;
  value?: IContactData;
  onChange?: () => void;
  required?: boolean;
  disabled?: boolean;
}

type IContactDataPropertyInputProps = IContactDataPropertyInputOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display contact data input fields */
const ContactDataPropertyInput: React.FC<IContactDataPropertyInputProps> = (props: IContactDataPropertyInputProps) => {

  const options = Object.keys(ContactDataTypeEnum).map((item: any) => (
    <Option value={ContactDataTypeEnum[item as keyof typeof ContactDataTypeEnum]} key={item} >
      {props.translate(`CONTACT_DATA_PROPERTY_INPUT.TYPE.${item}_LABEL`)}
    </Option>
  ));

  const minimumFieldValidation = async (rules: RuleObject, values: any, callback?: (error?: string | undefined) => void) => {
    if (!values || values.length < 1) {
      return Promise.reject(new Error(props.translate('CONTACT_DATA_PROPERTY_INPUT.ERROR_MESSAGE.MINIMUM_FIELD_REQUIRED')));
    }
    return Promise.resolve();
  };

  return (
    <Form.List name={'contactData'}
      rules={[
        {
          validator: minimumFieldValidation,
        },
      ]}>
      {(fields, { add, remove, move }, { errors }) => {
        return (
          <div>
            {fields.map((field) => {
              return (
                <React.Fragment key={field.key}>
                  <Row gutter={2}>
                    <Col xs={7} md={5}>
                      <Form.Item
                        name={[field.name, 'type', 'id']}
                        fieldKey={[field.key, 'type', 'id']}
                      >
                        <Select defaultValue={ContactDataTypeEnum.PHONE}>
                          {options}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={15} md={17}>
                      <Form.Item
                        shouldUpdate={(prevValues, nextValues) => prevValues.contactData?.[field.name]?.type?.id !== nextValues.contactData?.[field.name]?.type?.id}
                        noStyle={true}
                        preserve={false}
                      >
                        {() => {
                          const isEmail = props.formRef?.getFieldValue(['contactData', field.name, 'type', 'id']) === ContactDataTypeEnum.EMAIL;
                          return (
                            <Form.Item
                              name={[field.name, 'value']}
                              fieldKey={[field.key, 'value']}
                              rules={[isEmail ? { pattern: EMAIL_REGEX, message: props.translate('USER_FORM.EMAIL_VALIDATION_TEXT') } : {},
                              { required: props.required, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}
                            >
                              <Input disabled={props.disabled} type={isEmail ? 'email' : 'tel'} placeholder={props.translate(`USER_FORM.${isEmail ? 'EMAIL' : 'PHONE'}_PLACEHOLDER`)} />
                            </Form.Item>
                          );

                        }}
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      <Button onClick={() => remove(field.name)} icon={<LemonIcon name="times" />} />
                    </Col>

                  </Row>

                  <Form.Item
                    noStyle={true}
                    hidden={true}
                    name={[field.name, 'id']}
                    fieldKey={[field.key, 'id']}
                  >
                    <Input />
                  </Form.Item>
                </React.Fragment >
              );
            })}
            <Button onClick={() => add({ type: { id: ContactDataTypeEnum.PHONE } })}> {props.translate('CONTACT_DATA_PROPERTY_INPUT.ADD_CONTACT_LABEL')} </Button>
            <Form.ErrorList errors={errors} />
          </div>
        );
      }}
    </Form.List >

  );
};

export default withLocalize<IContactDataPropertyInputOwnProps>(ContactDataPropertyInput as any);

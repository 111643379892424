import { Typography } from 'antd';
import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import AppConfigService from '@src/service/common/AppConfigService';

const APP_NAME = AppConfigService.getValue('app.name');

// -- Prop types
// ----------

export interface IPublicWelcomeViewOwnProps {
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}

type PublicWelcomeViewProps = IPublicWelcomeViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/**
 * Default public view that is displayed if everything else fails.
 */
const PublicWelcomeView: React.FC<PublicWelcomeViewProps> = (props) => {
  const tenantName = props.tenantOrganizationConfiguration.configuration.displayName ?? APP_NAME;

  return (
    <Typography.Paragraph className="text-center">
      <br />
      <br />
      <br />
      <br />
      <Typography.Title level={1}>{props.translate('WELCOME.WELCOME_TITLE', { tenantName })}</Typography.Title>
      <Typography.Paragraph>{props.translate('WELCOME.WELCOME_DESCRIPTION')}</Typography.Paragraph>
    </Typography.Paragraph>
  );
};

export default withLocalize<IPublicWelcomeViewOwnProps>(PublicWelcomeView as any);

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ReportListModalView from '@src/components/report/list/ReportListModalView';
import { IReport, IReportTypeEnum } from '@src/model/report/Report';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import { transformExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/util';
import ReportsBusinessStore, { IReportCreatePayload, IReportListFilter } from '@src/service/business/reports/reportsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

const VIEW_NAME = '@@ADMIN_REPORT_LIST';

export interface IAdminReportContainerOwnProps {}

export interface IAdminReportContainerStateProps {
  reportList: ICollectionData<IReport>;
  reportListFilter: IReportListFilter;
}

export interface IAdminReportContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchReportList: (params: ICollectionFetchPayload<IReportListFilter>) => any;
  createReport: (data: IReportCreatePayload) => ITrackableAction;
  deleteReport: (data: string) => ITrackableAction;
}

type IAdminReportContainerProps = IAdminReportContainerDispatchProps & IAdminReportContainerStateProps & IAdminReportContainerOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const AdminReportContainer: React.FC<IAdminReportContainerProps> = (props: IAdminReportContainerProps) => {
  const [, updateCollectionParams, onUpdateList] = useCollectionState<IReportListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchReportList,
    initialValues: { sort: AppConfigService.getValue('api.sorting.byDate') },
  });

  const handleDeleteReport = useCallback(
    (data: IReport) => {
      props
        .deleteReport(data.id)
        .track()
        .subscribe(
          // success
          () => onUpdateList()
        );
    },
    [onUpdateList]
  );

  const handleCreateReport = useCallback(
    (data: IReportCreatePayload) => {
      let filters = data.filters;
      if (data.reportType === IReportTypeEnum.EXTERNAL_EDUCATION_APPLICATION) {
        filters = transformExternalEducationApplicationListFilter(filters ?? {});
      }
      props
        .createReport({ reportType: data.reportType, filters })
        .track()
        .subscribe(
          // success
          () => {
            props.reportMessage({ message: props.translate('REPORTS.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
            updateCollectionParams.onPageChange(0);
          }
        );
    },
    [updateCollectionParams.onPageChange]
  );

  return <ReportListModalView reportList={props.reportList} onCreateReport={handleCreateReport} onDeleteReport={handleDeleteReport} onSortChange={updateCollectionParams.onSortChange} onPageChange={updateCollectionParams.onPageChange} onReload={onUpdateList} />;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdminReportContainerOwnProps): IAdminReportContainerStateProps => ({
  reportList: ReportsBusinessStore.selectors.getReportList(state),
  reportListFilter: ReportsBusinessStore.selectors.getReportListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminReportContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchReportList: (params: ICollectionFetchPayload<IReportListFilter>) => dispatch(ReportsBusinessStore.actions.fetchReportList(params)),
  createReport: (data: IReportCreatePayload) => dispatch(createTrackableAction(ReportsBusinessStore.actions.createReport(data))),
  deleteReport: (data: string) => dispatch(createTrackableAction(ReportsBusinessStore.actions.deleteReport({ id: data }))),
});

export default connect<IAdminReportContainerStateProps, IAdminReportContainerDispatchProps, IAdminReportContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(AdminReportContainer as any));

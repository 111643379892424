import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import UserGroupHierarchyView from '@src/components/usergroup/hierarchy/UserGroupHierarchyView';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import UserGroupListBusinessStore from '@src/service/business/usergroup/UserGroupListBusinessStore';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------

// -- Prop types
// ----------

export interface IUserGroupHierarchyContainerOwnProps {
  basePath: string;
}

export interface IUserGroupHierarchyContainerStateProps {
  userGroupHierarchy: IUserGroupHierarchy;
}

export interface IUserGroupHierarchyContainerDispatchProps {
  fetchUserGroupHierarchy: () => void;
  clearUserGroupHierarchy: () => void;
}

type IUserGroupHierarchyContainerProps = IUserGroupHierarchyContainerOwnProps & IUserGroupHierarchyContainerStateProps & IUserGroupHierarchyContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

const UserGroupHierarchyContainer: React.FC<IUserGroupHierarchyContainerProps> = (props: IUserGroupHierarchyContainerProps) => {
  useEffect(() => {
    props.fetchUserGroupHierarchy();
    return () => {
      props.clearUserGroupHierarchy();
    };
  }, []);

  const isAdmin = useMemo(() => props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]), [props.allowedRoles]);

  const handleDataUpdate = useCallback(() => {
    props.fetchUserGroupHierarchy();
  }, [props.fetchUserGroupHierarchy]);

  return <React.Fragment>{props.userGroupHierarchy && <UserGroupHierarchyView basePath={props.basePath} returnRoute={props.location?.pathname} userGroupHierarchy={props.userGroupHierarchy} onUserGroupHierachyUpdate={isAdmin ? handleDataUpdate : undefined} showButtons={isAdmin} />}</React.Fragment>;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserGroupHierarchyContainerStateProps => ({
  userGroupHierarchy: UserGroupListBusinessStore.selectors.getUserGroupHierarchy(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserGroupHierarchyContainerDispatchProps => ({
  fetchUserGroupHierarchy: () => dispatch(UserGroupListBusinessStore.actions.fetchUserGroupHierarchy()),
  clearUserGroupHierarchy: () => dispatch(UserGroupListBusinessStore.actions.clearUserGroupHierarchy()),
});

export default connect<IUserGroupHierarchyContainerStateProps, IUserGroupHierarchyContainerDispatchProps, IUserGroupHierarchyContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(withRoles(UserGroupHierarchyContainer as any))));

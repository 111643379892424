import React, { useCallback } from 'react';

import TableDataPicker from '@src/components/common/datapicker/common/TableDataPicker';
import ExternalEducationTemplateDataFilter from '@src/components/common/datapicker/externalEducationTemplate/ExternalEducationTemplateDataFilter';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import ExternalEducationInstanceListModal from '@src/components/externaleducation/modal/ExternalEducationInstanceListModal';
import ExternalEducationTemplateModal from '@src/components/externaleducation/modal/ExternalEducationTemplateModal';
import { IExternalEducationTemplate, IInstanceLocation } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICollectionData } from '@src/service/business/common/types';
import { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import { Tag } from 'antd';

// -- Prop types
// ----------

export interface IExternalEducationTemplateDataTableViewOwnProps {
  externalEducationTemplateList: ICollectionData<IExternalEducationTemplate>;
  selectedItem?: IExternalEducationTemplate;
  filter?: IExternalEducationTemplateListFilter;
  onFilterUpdate?: (partialFilter?: Partial<IExternalEducationTemplateListFilter>) => void;
  onFilterChange?: (filter?: IExternalEducationTemplateListFilter) => void;
  onChange?: (value?: IExternalEducationTemplate) => void;
}
type IExternalEducationTemplateDataTableViewProps = IExternalEducationTemplateDataTableViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationTemplateDataTableView: React.FC<IExternalEducationTemplateDataTableViewProps> = (props: IExternalEducationTemplateDataTableViewProps) => {
  const [templateModal, toggleTemplateModals] = useEntityModalsState<IExternalEducationTemplate>();
  const [instanceListModal, toggleInstanceModals] = useEntityModalsState<IExternalEducationTemplate>();
  const getInstanceLocations = useCallback((instanceLocation?: IInstanceLocation) => {
    const locations = instanceLocation?.cities.map((city) => <Tag key={city.id}>{city.name}</Tag>);
    if (instanceLocation?.executableOnline) {
      locations?.push(<Tag key={'EXTERNAL_EDUCATION_TEMPLATE.TABLE_DATA_PICKER.CITY_ONLINE_LABEL'}>{props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE_DATA_PICKER.CITY_ONLINE_LABEL')}</Tag>);
    }
    return locations;
  }, []);
  const columns: ITimunDataTableCol<IExternalEducationTemplate>[] = [
    {
      key: 'externalEducationTemplateName',
      contentType: 'string',
      headerTitle: props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE_DATA_PICKER.EXTERNAL_EDUCATION_TEMPLATE_TITLE_HEADER_LABEL'),
      content: (record: IExternalEducationTemplate) => <a onClick={() => toggleTemplateModals.onOpenEntity(record)}> {record.title} </a>,
    },
    {
      key: 'educationProviderName',
      contentType: 'string',
      headerTitle: props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE_DATA_PICKER.EDUCATION_PROVIDER_HEADER_LABEL'),
      content: (record: IExternalEducationTemplate) => record.educationProvider.company.name,
    },
    {
      key: 'cities',
      contentType: 'string',
      headerTitle: props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE_DATA_PICKER.CITY_HEADER_LABEL'),
      content: (record: IExternalEducationTemplate) => getInstanceLocations(record.instanceLocation),
    },
    {
      key: 'seeExternalEducationTemplateInstances',
      contentType: 'string',
      headerTitle: '',
      content: (record: IExternalEducationTemplate) => <a onClick={() => toggleInstanceModals.onOpenEntity(record)}>{props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE_DATA_PICKER.SEE_INSTANCE_LIST_LABEL')}</a>,
    },
  ];
  return (
    <React.Fragment>
      <ExternalEducationTemplateDataFilter filter={props.filter} onFilterUpdate={props.onFilterUpdate} />

      <TableDataPicker<IExternalEducationTemplate> items={props.externalEducationTemplateList.content} value={props.selectedItem} paginationData={props.externalEducationTemplateList.page} columns={columns} scrollable={true} onChange={props.onChange} />

      {templateModal.isEntityModalVisible && templateModal.selectedEntity && <ExternalEducationTemplateModal value={templateModal.selectedEntity} onCancel={toggleTemplateModals.onCloseEntity} />}

      {instanceListModal.isEntityModalVisible && instanceListModal.selectedEntity && <ExternalEducationInstanceListModal value={instanceListModal.selectedEntity} onCancel={toggleInstanceModals.onCloseEntity} />}
    </React.Fragment>
  );
};

export default withLocalize<IExternalEducationTemplateDataTableViewOwnProps>(ExternalEducationTemplateDataTableView as any);

import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TraineeLastCourse from '@src/components/course/list/catalog/TraineeLastCourse';
import DashboardNotificationListContainer from '@src/components/notification/list/DashboardNotificationListContainer';
import ReminderCalendarContainer from '@src/components/reminder/calendar/ReminderCalendarContainer';
import { ICourse } from '@src/model/course/Course';
import { IUserInfo } from '@src/model/user/User';
import CourseListBusinessStore from '@src/service/business/courses/courseListBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';

// -- Prop types
// ----------

export interface ITraineeDashboardContainerOwnProps { }
export interface ITraineeDashboardContainerStateProps {
  currentUser: IUserInfo;
  lastCourse?: ICourse;
}
export interface ITraineeDashboardContainerDispatchProps {
  fetchLastCourse: () => void;
}
type ITraineeDashboardContainerProps = ITraineeDashboardContainerOwnProps & ITraineeDashboardContainerStateProps & ITraineeDashboardContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component display trainee dashboard information ... */
class TraineeDashboardContainer extends React.Component<ITraineeDashboardContainerProps> {
  componentDidMount() {
    this.props.fetchLastCourse();
  }

  render = () => {
    return (
      <React.Fragment>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Row>
              <Col span={24}>
                <div className="panel">
                  {/* Component that display trainee last viewed course */}
                  <AppContent title={this.props.translate('COURSE_CONTINUE.VIEW_TITLE')}>
                    <TraineeLastCourse lastCourse={this.props.lastCourse} />
                  </AppContent>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="panel">
                  <DashboardNotificationListContainer />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <div className="panel">
              <AppContent title={this.props.translate('REMINDER_CALENDAR.TITLE_LABEL')} level={3}>
                <ReminderCalendarContainer currentUser={this.props.currentUser} />
              </AppContent>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ITraineeDashboardContainerOwnProps): ITraineeDashboardContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  lastCourse: CourseListBusinessStore.selectors.getLastCourse(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ITraineeDashboardContainerDispatchProps => ({
  fetchLastCourse: () => dispatch(CourseListBusinessStore.actions.fetchLastCourse()),
});

export default connect<ITraineeDashboardContainerStateProps, ITraineeDashboardContainerDispatchProps, ITraineeDashboardContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(TraineeDashboardContainer as any));

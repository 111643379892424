import ExamTemplateSettingsContainer from '@src/components/exam/examtemplate/ExamTemplateSettingsContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type IExamTemplateSettingsPageProps = WithRouterProps;

const ExamTemplateSettingsPage = (props: IExamTemplateSettingsPageProps) => <ExamTemplateSettingsContainer examTemplateId={props.params.examTemplateId} />;

export default withRouter<IExamTemplateSettingsPageProps>(ExamTemplateSettingsPage);

import React, { SyntheticEvent } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { classNames } from '@src/components/common/util/classNames';
import { LemonApplicationIconResolver } from '@src/service/common/icon/LemonApplicationIconResolver';
import { ILemonApplicationIconSet } from '@src/service/common/icon/LemonApplicationIconSet';

// lots of ant's components have additional icon style tweaks thus we'll add ant's icon class to conform with that
const CSS_CLASS_ANTD_ICON_BASE = 'anticon';
const CSS_CLASS_BASE = 'lemon-icon';
const CSS_CLASS_SMALL = 'lemon-icon--sm';
const CSS_CLASS_LARGE = 'lemon-icon--lg';
const CSS_CLASS_XLARGE = 'lemon-icon--xl';
const CSS_CLASS_XXLARGE = 'lemon-icon--xxl';
const CSS_CLASS_XXXLARGE = 'lemon-icon--xxxl';
const CSS_CLASS_IMAGE = 'lemon-icon--image';

export type IconSize = 'small' | 'base' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge' | 'image';

// -- Prop types
// ----------

export interface ILemonIconOwnProps {
  name: keyof ILemonApplicationIconSet;
  className?: string;
  size?: IconSize;
  title?: string;
  rotate?: 0 | 90 | 180 | 270;
  spin?: boolean;

  // event handlers
  onClick?: (event: SyntheticEvent) => void;

  // passthrough event listener - to allow embedding in other components
  onMouseEnter?: (event: SyntheticEvent) => void;
  onMouseLeave?: (event: SyntheticEvent) => void;
  onTouchStart?: (event: SyntheticEvent) => void;
  onMouseDown?: (event: SyntheticEvent) => void;
  onFocus?: (event: SyntheticEvent) => void;
  onBlur?: (event: SyntheticEvent) => void;
  onContextMenu?: (event: SyntheticEvent) => void;
}
type ILemonIconProps = ILemonIconOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Custom Lemon icon wrapper that hides icon lib implementation from the rest of the app. */
const LemonIcon: React.StatelessComponent<ILemonIconProps> = (props) => {
  return (
    <i
      className={getClassName(props)}
      onClick={props.onClick}
      // passthrough event listener - to allow embedding in other components
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onTouchStart={props.onTouchStart}
      onMouseDown={props.onMouseDown}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onContextMenu={props.onContextMenu}
    >
      <FontAwesomeIcon icon={resolveIcon(props.name) as any} spin={props.spin} title={props.title} rotation={props.rotate !== 0 ? props.rotate : undefined} />
    </i>
  );
};

function resolveIcon(name: keyof ILemonApplicationIconSet): string {
  return LemonApplicationIconResolver.instance().resolveIcon(name);
}

function getClassName(props: ILemonIconProps) {
  return classNames({
    [CSS_CLASS_ANTD_ICON_BASE]: true,
    [CSS_CLASS_BASE]: true,
    [CSS_CLASS_SMALL]: props.size === 'small',
    [CSS_CLASS_LARGE]: props.size === 'large',
    [CSS_CLASS_XLARGE]: props.size === 'xlarge',
    [CSS_CLASS_XXLARGE]: props.size === 'xxlarge',
    [CSS_CLASS_XXXLARGE]: props.size === 'xxxlarge',
    [CSS_CLASS_IMAGE]: props.size === 'image',
    [props.className || '']: true,
  });
}

export default withLocalize<ILemonIconOwnProps>(LemonIcon as any);

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SurveyTemplateInstructionModal from '@src/components/survey/form/SurveyTemplateInstructionForm';
import { ISurveyTemplate, SurveyTypeEnum } from '@src/model/survey/SurveyTemplate';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------
export interface IAdminSurveyTemplateListOwnProps {
  surveyTemplateList?: ISurveyTemplate[];
  onSurveyTemplateCreate?: (title: string, surveyTypeId: SurveyTypeEnum) => void;
  onSurveyTemplateUpdate?: (surveyTemplate: ISurveyTemplate) => void;
}

type IAdminSurveyTemplateListProps = IAdminSurveyTemplateListOwnProps & IWithLocalizeOwnProps;

interface IAdminSurveyTemplateListState {
  isSurveyTemplateModalVisible: boolean;
  selectedSurveyTemplate?: ISurveyTemplate;
}

// -- Component
// ----------
class AdminSurveyTemplateList extends React.Component<IAdminSurveyTemplateListProps, IAdminSurveyTemplateListState> {
  state: IAdminSurveyTemplateListState = {
    isSurveyTemplateModalVisible: false,
  };

  render() {
    return (
      <React.Fragment>
        {
          this.isSurveyTypeCreated(SurveyTypeEnum.USER_EDUCATION_COMPLETED) ? null : <Row>
            <Button onClick={() => this.handleSurveyTemplateCreate('USER_EDUCATION_COMPLETED', SurveyTypeEnum.USER_EDUCATION_COMPLETED)} icon={<LemonIcon name="plus" />}>
              {this.props.translate('SURVEY_TEMPLATE.TITLE.USER_EDUCATION_COMPLETED')}
            </Button>
          </Row>
        }
        {
          this.isSurveyTypeCreated(SurveyTypeEnum.USER_EDUCATION_COMPLETED_CHECK_IN) ? null : < Row >
            <Button onClick={() => this.handleSurveyTemplateCreate('USER_EDUCATION_COMPLETED_CHECK_IN', SurveyTypeEnum.USER_EDUCATION_COMPLETED_CHECK_IN)} icon={<LemonIcon name="plus" />}>
              {this.props.translate('SURVEY_TEMPLATE.TITLE.USER_EDUCATION_COMPLETED_CHECK_IN')}
            </Button>
          </Row >
        }
        {
          this.isSurveyTypeCreated(SurveyTypeEnum.EDUCATION_PROVIDER) ? null : <Row>
            <Button onClick={() => this.handleSurveyTemplateCreate('EDUCATION_PROVIDER', SurveyTypeEnum.EDUCATION_PROVIDER)} icon={<LemonIcon name="plus" />}>
              {this.props.translate('SURVEY_TEMPLATE.TITLE.EDUCATION_PROVIDER')}
            </Button>
          </Row>
        }
        {
          this.isSurveyTypeCreated(SurveyTypeEnum.EMPLOYER) ? null : <Row>
            <Button onClick={() => this.handleSurveyTemplateCreate('EMPLOYER', SurveyTypeEnum.EMPLOYER)} icon={<LemonIcon name="plus" />}>
              {this.props.translate('SURVEY_TEMPLATE.TITLE.EMPLOYER')}
            </Button>
          </Row>
        }
        {/* List of survey templates */}
        {this.props.surveyTemplateList?.map((surveyTemplate: ISurveyTemplate) => {
          return (
            <Row gutter={8} className="panel" key={surveyTemplate.id}>
              <Col span={18}>
                <Link key={surveyTemplate.id} to={this.getPath(surveyTemplate.id)}>
                  {this.props.translate(`SURVEY_TEMPLATE.TITLE.${surveyTemplate.title}`)}
                </Link>
              </Col>
              <Col className="timun-adminSurveyTemplateList__column" span={6}>
                <Button onClick={() => this.handleSurveyTemplateEdit(surveyTemplate)}> {this.props.translate('COMMON.ACTION_EDIT')} </Button>
              </Col>
            </Row>
          );
        })
        }

        {/* Insturction survey template modal */}
        {this.state.isSurveyTemplateModalVisible && this.state.selectedSurveyTemplate && <SurveyTemplateInstructionModal surveyTemplate={this.state.selectedSurveyTemplate} onSubmit={this.handleSurveyTemplateSubmit} onCancel={this.toggleSurveyTemplateModal} />}
      </React.Fragment>
    );
  }

  handleSurveyTemplateEdit = (selectedSurveyTemplate: ISurveyTemplate) => {
    this.setState({ selectedSurveyTemplate });
    this.toggleSurveyTemplateModal();
  };

  toggleSurveyTemplateModal = () => {
    this.setState({ isSurveyTemplateModalVisible: !this.state.isSurveyTemplateModalVisible });
  };

  handleSurveyTemplateSubmit = (selectedSurveyTemplate: ISurveyTemplate) => {
    this.props.onSurveyTemplateUpdate && this.props.onSurveyTemplateUpdate(selectedSurveyTemplate);
    this.toggleSurveyTemplateModal();
  };

  isSurveyTypeCreated = (surveyTemplateTypeId: SurveyTypeEnum) => {
    const isSurveyCreated = this.props.surveyTemplateList?.some((surveyTemplate: ISurveyTemplate) => {
      return surveyTemplate.type.id === surveyTemplateTypeId;
    });
    return isSurveyCreated;
  };

  handleSurveyTemplateCreate = (title: string, surveyTypeId: SurveyTypeEnum) => {
    if (this.props.onSurveyTemplateCreate) {
      this.props.onSurveyTemplateCreate(title, surveyTypeId);
    }
  };

  getPath = (id: string) => {
    return `administration/surveytemplate/${id}`;
  };

}

// -- HOCs and exports
// ----------

export default withLocalize<IAdminSurveyTemplateListOwnProps>(AdminSurveyTemplateList as any);

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IFileListFilter } from '@src/service/business/files/FileListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

// -- Prop types
// ----------

export interface IRepositoryFileListFilterOwnProps {
  fileListFilter: IFileListFilter;
  onFilterChange?: (filter: IFileListFilter) => void;
}
type IRepositoryFileListFilterProps = IRepositoryFileListFilterOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Repository file list filter */
class RepositoryFileListFilter extends React.Component<IRepositoryFileListFilterProps> {

  debouncedSearch = debounce((value: string) => {
    this.props.onFilterChange && this.props.onFilterChange({ searchTerm: value });
  }, AppConfigService.getValue(`components.repository.debounceTime`));
  render = () => {
    return (
      <Input className="timun-repository__searchInput"
        defaultValue={this.props.fileListFilter?.searchTerm}
        placeholder={this.props.translate('COMMON.ACTION_SEARCH')}
        onChange={this.handleSearch}
        suffix={<LemonIcon name="search" />}
        allowClear={true} />
    );
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (this.props.fileListFilter?.searchTerm != value) {
      this.debouncedSearch(value);
    }
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IRepositoryFileListFilterOwnProps>(RepositoryFileListFilter as any);

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Link } from 'react-router';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { UserRoleEnum } from '@src/model/user/UserRole';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';

// -- Const
// ----------
enum CourseAdministrationSubMenuEnum {
  STATISTICS = 'STATISTICS',
  ASSIGNMENT_MANAGMENT = 'ASSIGNMENT_MANAGMENT',
  SKILL_MANAGEMENT = 'SKILL_MANAGEMENT',
  COURSE_REPORT = 'COURSE_REPORT',
}

enum CourseAdministrationRouteEnum {
  STATISTICS = 'statistics',
  INSTANCE_LIST = 'examinstancelist',
  ASSIGNMENT_MANAGMENT_WORK_POSITION = 'assignworkposition',
  ASSIGNMENT_MANAGMENT_INDIVIDUAL = 'assignindividual',
  ASSIGNMENT_MANAGMENT_ORGANIZATION_GROUP = 'assignorganizationgroup',
  TRAINE_LIST = 'traineelist',
  SKILL_MANAGEMENT = 'assignskill',
  COURSE_REPORT = 'coursereport',
}

// -- Prop types
// ----------

export interface ICourseAdministrationViewSiderOwnProps {
  course: ICourse;
  administrationPathId: string;
  showExamTemplateStatistics?: boolean;
}
type ICourseAdministrationViewSiderProps = ICourseAdministrationViewSiderOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const CourseAdministrationViewSider: React.FC<ICourseAdministrationViewSiderProps> = (props: ICourseAdministrationViewSiderProps) => {
  const isAdmin = useMemo(() => props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]), [props.allowedRoles]);

  const getAdministrationCourseLink = useCallback(
    (idPath: string): string => {
      return UrlBuilderFactory.buildAppUrl(`/course/${props.course.id}/administration/${idPath}`);
    },
    [props.course.id]
  );

  const isCoursePublished = props.course.status.id === CourseStatusEnum.PUBLISHED;
  useEffect(() => {
    setSelectedKeys([props.administrationPathId]);
  }, [props.administrationPathId, props.course.id]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>();

  return (
    <React.Fragment>
      <Menu
        selectedKeys={selectedKeys}
        mode="inline"
        defaultOpenKeys={[CourseAdministrationSubMenuEnum.ASSIGNMENT_MANAGMENT, CourseAdministrationSubMenuEnum.SKILL_MANAGEMENT, CourseAdministrationSubMenuEnum.STATISTICS, CourseAdministrationSubMenuEnum.COURSE_REPORT]}
        className="timun-courseView__administrationSiderMenu"
      >
        {props.isTenantPropEnabled('admin_skills') && (
          <SubMenu key={CourseAdministrationSubMenuEnum.SKILL_MANAGEMENT} title={props.translate('COURSE_ADMINISTRATION.SIDER.SUBMENU_SKILL_MANAGEMENT_LABEL')}>
            <Menu.Item key={CourseAdministrationRouteEnum.SKILL_MANAGEMENT}>
              <Link to={getAdministrationCourseLink(CourseAdministrationRouteEnum.SKILL_MANAGEMENT)}>{props.translate('COURSE_ADMINISTRATION.SIDER.MENU_SKILL_MANAGMENT_INDIVIDUAL_LABEL')}</Link>
            </Menu.Item>
          </SubMenu>
        )}

        <SubMenu key={CourseAdministrationSubMenuEnum.ASSIGNMENT_MANAGMENT} title={props.translate('COURSE_ADMINISTRATION.SIDER.SUBMENU_ASSIGNMENT_MANAGMENT_LABEL')} disabled={!isCoursePublished}>
          <Menu.Item key={CourseAdministrationRouteEnum.ASSIGNMENT_MANAGMENT_INDIVIDUAL}>
            <Link to={getAdministrationCourseLink(CourseAdministrationRouteEnum.ASSIGNMENT_MANAGMENT_INDIVIDUAL)}>{props.translate('COURSE_ADMINISTRATION.SIDER.MENU_ASSIGNMENT_MANAGMENT_INDIVIDUAL_LABEL')}</Link>
          </Menu.Item>
          {props.isTenantPropEnabled('organization_group_courses') && (
            <Menu.Item key={CourseAdministrationRouteEnum.ASSIGNMENT_MANAGMENT_ORGANIZATION_GROUP}>
              <Link to={getAdministrationCourseLink(CourseAdministrationRouteEnum.ASSIGNMENT_MANAGMENT_ORGANIZATION_GROUP)}>{props.translate('COURSE_ADMINISTRATION.SIDER.MENU_ASSIGNMENT_MANAGMENT_ORGANIZATION_GROUP_LABEL')}</Link>
            </Menu.Item>
          )}
        </SubMenu>

        <SubMenu key={CourseAdministrationSubMenuEnum.STATISTICS} title={props.translate('COURSE_ADMINISTRATION.SIDER.SUBMENU_STATISTICS_LABEL')} disabled={!isCoursePublished}>
          <Menu.Item key={CourseAdministrationRouteEnum.STATISTICS} disabled={!CourseHelperUtils.hasExamTemplate(props.course)}>
            <Link to={getAdministrationCourseLink(CourseAdministrationRouteEnum.STATISTICS)}>{props.translate('COURSE_ADMINISTRATION.SIDER.MENU_EXAM_STATISTIC_LABEL')}</Link>
          </Menu.Item>
          <Menu.Item key={CourseAdministrationRouteEnum.INSTANCE_LIST} disabled={!CourseHelperUtils.hasExamTemplate(props.course)}>
            <Link to={getAdministrationCourseLink(CourseAdministrationRouteEnum.INSTANCE_LIST)}>{props.translate('COURSE_ADMINISTRATION.SIDER.MENU_TRAINEE_RESULTS_LABEL')}</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key={CourseAdministrationSubMenuEnum.COURSE_REPORT} title={props.translate('COURSE_ADMINISTRATION.SIDER.SUBMENU_COURSE_REPORT_LABEL')} disabled={!isAdmin}>
          <Menu.Item key={CourseAdministrationRouteEnum.COURSE_REPORT}>
            <Link to={getAdministrationCourseLink(CourseAdministrationRouteEnum.COURSE_REPORT)}>{props.translate('COURSE_ADMINISTRATION.SIDER.MENU_COURSE_REPORT_INDIVIDUAL_LABEL')}</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </React.Fragment>
  );
};

export default withLocalize(withRoles(withTenantPropEnabled<ICourseAdministrationViewSiderOwnProps>(CourseAdministrationViewSider as any)));

import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TimunShortInfoTable from '@src/components/common/table/TimunShortInfoTable';
import SkillListView from '@src/components/skillgroup/view/SkillListView';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ISkillGroup } from '@src/model/skillgroup/SkillGroup';
import { ICollectionData } from '@src/service/business/common/types';
import { Button, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------
export interface IAdminSkillGroupListViewOwnProps {
  skillGroupList: ICollectionData<ISkillGroup>;
  onSkillGroupAdd: () => void;
  onSkillGroupEdit: (skillGroup: ISkillGroup) => void;
  onSkillEdit: (skill: ISkill) => void;
  onSkillLevelClassificationEdit: (skill: ISkill) => void;
  onSkillAdd: (skillGroup: ISkillGroup) => void;
  onPageChange: (page: number, size?: number) => void;
}

type IAdminSkillGroupListViewProps = IAdminSkillGroupListViewOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

interface IAdminSkillGroupListViewState {
}

// -- Component
// ----------
class AdminSkillGroupListView extends React.Component<IAdminSkillGroupListViewProps, IAdminSkillGroupListViewState> {
  state: IAdminSkillGroupListViewState = {};

  render() {
    const expandedSkillGroupRow = (record: ISkillGroup) => {
      return <SkillListView skillList={record.skills} onSkillEdit={this.props.onSkillEdit} onSkillLevelClassificationEdit={this.props.onSkillLevelClassificationEdit}/>;
    };

    const skillGroupTableActionColumns = {
      add: { showButton: true, onClick: this.props.onSkillAdd, buttonProps: { title: this.props.translate('SKILL_GROUP_LIST.SKILL_ADD_BUTTON_LABEL') } },
      edit: { showButton: true, onClick: this.props.onSkillGroupEdit },
    };

    return (
      <React.Fragment>
        <Row className="timun-skillGroupListView__row" justify="end">
          <Button type="primary" icon={<LemonIcon name="plus"/>} onClick={this.props.onSkillGroupAdd}>{this.props.translate('SKILL_GROUP_LIST.SKILL_GROUP_ADD_BUTTON_LABEL')} </Button>
        </Row>
        <TimunShortInfoTable<ISkillGroup>
          titlePropPath="title"
          items={this.props.skillGroupList.content}
          paginationData={this.props.skillGroupList.page}
          showPagination={true}
          rowDescription={this.renderSkillGroupRowDescription}
          expandableRow={expandedSkillGroupRow}
          paginationConfig={{ onChange: this.props.onPageChange }}
          actionButtons={skillGroupTableActionColumns}
        />
      </React.Fragment>
    );
  }

  renderSkillGroupRowDescription = (record: ISkillGroup) => (
    <GridItem label={this.props.translate('SKILL_GROUP_LIST.SKILL_GROUP_DESCRIPTION_LABEL')} layout="horizontal" key={record.id} contentType="secondary">
      {record.active ? this.props.translate('COMMON.LABEL.ACTIVE') : this.props.translate('COMMON.LABEL.INACTIVE')}
    </GridItem>
  );
}

// -- HOCs and exports
// ----------
export default withLocalize<IAdminSkillGroupListViewOwnProps>(withTenantPropEnabled(AdminSkillGroupListView as any));

import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TimunShortInfoTable from '@src/components/common/table/TimunShortInfoTable';
import SkillLevelClassificationListContainer from '@src/components/skilllevelclassification/SkillLevelClassificationListContainer';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { ISkill } from '@src/model/skillgroup/Skill';
import { Button } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';

// -- Prop types
// ----------

export interface ISkillListViewOwnProps {
  skillList: ISkill[];
  onSkillEdit: (skill: ISkill) => void;
  onSkillLevelClassificationEdit: (skill: ISkill) => void;
}

type ISkillListViewProps = ISkillListViewOwnProps & IWithTenantPropEnabledOwnProps & IWithLocalizeOwnProps;

interface ISkillListViewState {}

// -- Component
// ----------

/** Describe your component ... */
class SkillListView extends React.Component<ISkillListViewProps, ISkillListViewState> {
  state: ISkillListViewState = {};

  render = () => {
    const expandedSkillLevelClassificationRow = (record: ISkill) => {
      return <SkillLevelClassificationListContainer skillId={record.id} />;
    };

    const skillTableActionColumns = { edit: { showButton: true, onClick: this.props.onSkillEdit } };

    const skillTableAdditionalActionColumns = () => (
      <React.Fragment>
        {this.props.isTenantPropEnabled(['admin_skills', 'skillsgap']) && (
          <Column key="add" width="5%" align="center" render={(record: ISkill) => <Button key={record.id} title={this.props.translate('SKILL_LIST.VIEW.SKILL_LEVEL_CLASSIFICATION_BUTTON_LABEL')} icon={<LemonIcon name="level" />} onClick={() => this.props.onSkillLevelClassificationEdit(record)} />} />
        )}
        ,
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <TimunShortInfoTable<ISkill>
          titlePropPath="title"
          items={this.props.skillList}
          rowDescription={this.renderSkillRowDescription}
          expandableRow={this.props.isTenantPropEnabled(['admin_skills', 'skillsgap']) ? expandedSkillLevelClassificationRow : undefined}
          actionButtons={skillTableActionColumns}
          renderAdditionalActions={skillTableAdditionalActionColumns}
        />
      </React.Fragment>
    );
  };

  renderSkillRowDescription = (record: ISkill) => (
    <GridItem label={this.props.translate('SKILL_GROUP_LIST.SKILL_DESCRIPTION_LABEL')} layout="horizontal" key={record.id} contentType="secondary">
      {record.active ? this.props.translate('COMMON.LABEL.ACTIVE') : this.props.translate('COMMON.LABEL.INACTIVE')}
    </GridItem>
  );
}

// -- HOCs and exports
// ----------

export default withLocalize<ISkillListViewOwnProps>(withTenantPropEnabled(SkillListView as any));

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationTemplateDetailsPanel from '@src/components/externaleducation/ExternalEducationTemplateDetailsPanel';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { Modal } from 'antd';


// -- Prop types
// ----------

export interface IExternalEducationTemplateModalOwnProps {
  value: IExternalEducationTemplate;
  onCancel?: () => void;
}
type IExternalEducationTemplateModalProps = IExternalEducationTemplateModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationTemplateModal: React.FC<IExternalEducationTemplateModalProps> = (props: IExternalEducationTemplateModalProps) => {

  return (
    <Modal
      className="lemon-modal__modal--lg"
      title={props.translate('EXTERNAL_EDUCATION_TEMPLATE.MODAL.DETAILS_TITLE')}
      maskClosable={false}
      visible={true}
      onCancel={props.onCancel}
      footer={null}>
      <ExternalEducationTemplateDetailsPanel externalEducationTemplate={props.value} active={true} />
    </Modal >
  );
};

export default withLocalize<IExternalEducationTemplateModalOwnProps>(ExternalEducationTemplateModal as any);

import React from 'react';
import { connect } from 'react-redux';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TenantOrganizationConfigurationEdit from '@src/components/tenant/organizationconfiguration/TenantOrganizationConfigurationEdit';
import TenantOrganizationConfigurationView from '@src/components/tenant/organizationconfiguration/TenantOrganizationConfigurationView';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';
import TenantOrganizationConfigurationBusinessStore, { ITenantOrganizationConfigurationUpdatePayload } from '@src/service/business/tenant/tenantOrganizationConfigurationBussinessService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface ITenantOrganizationConfigurationContainerOwnProps { }
export interface ITenantOrganizationConfigurationContainerStateProps {
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}
export interface ITenantOrganizationConfigurationContainerDispatchProps {
  updateTenantOrganizationConfiguration: (tenantOrganizationConfiguration: ITenantOrganizationConfigurationUpdatePayload) => ITrackableAction;
}
type ITenantOrganizationConfigurationContainerProps = ITenantOrganizationConfigurationContainerOwnProps & ITenantOrganizationConfigurationContainerStateProps & ITenantOrganizationConfigurationContainerDispatchProps & IWithLocalizeOwnProps;

interface ITenantOrganizationConfigurationContainerState {
  editing: boolean;
}

// -- Component
// ----------

/** Describe your component ... */
class TenantOrganizationConfigurationContainer extends React.Component<ITenantOrganizationConfigurationContainerProps, ITenantOrganizationConfigurationContainerState> {
  state: ITenantOrganizationConfigurationContainerState = {
    editing: false,
  };

  render = () => {
    return (
      <AppContent>
        {this.props.tenantOrganizationConfiguration && (this.state.editing ? <TenantOrganizationConfigurationEdit configuration={this.props.tenantOrganizationConfiguration} onCancel={this.handleCancel} onSubmit={this.handleSubmit} /> : <TenantOrganizationConfigurationView configuration={this.props.tenantOrganizationConfiguration} onEdit={this.handleEdit} />)}
      </AppContent>
    );
  };

  handleCancel = () => {
    this.stopEditing();
  };

  handleEdit = () => {
    this.startEditing();
  };

  handleSubmit = (data: ITenantOrganizationConfigurationUpdatePayload) => {
    this.props
      .updateTenantOrganizationConfiguration(data)
      .track()
      .subscribe(() => this.stopEditing());
  };

  startEditing = () => {
    this.setState({ editing: true });
  };
  stopEditing = () => {
    this.setState({ editing: false });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ITenantOrganizationConfigurationContainerOwnProps): ITenantOrganizationConfigurationContainerStateProps => ({
  tenantOrganizationConfiguration: PublicTenantOrganizationConfigurationBusinessStore.selectors.getTenantOrganizationConfiguration(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ITenantOrganizationConfigurationContainerDispatchProps => ({
  updateTenantOrganizationConfiguration: (tenantOrganizationConfiguration: ITenantOrganizationConfigurationUpdatePayload) => dispatch(createTrackableAction(TenantOrganizationConfigurationBusinessStore.actions.updateTenantOrganizationConfiguration(tenantOrganizationConfiguration))),
});

export default connect<ITenantOrganizationConfigurationContainerStateProps, ITenantOrganizationConfigurationContainerDispatchProps, ITenantOrganizationConfigurationContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(TenantOrganizationConfigurationContainer as any));

import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import ScormCourseView from '@src/components/course/scorm/ScormCourseView';
import { ICourse } from '@src/model/course/Course';
import { IComputerManagedInstruction } from '@src/model/course/scorm/ComputerManagedInstruction';
import { IShareableContentObject } from '@src/model/course/scorm/ShareableContentObject';
import ScormCourseViewBusinessStore from '@src/service/business/courses/scormCourseViewBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';


// -- Prop types
// ----------

export interface IScormCourseViewContainerOwnProps {
  courseId: string;
  lectureId?: string;
}
export interface IScormCourseViewContainerStateProps {
  course: ICourse;
  lectureList: IShareableContentObject[];
  scormLectureCMI: IComputerManagedInstruction;
}
export interface IScormCourseViewContainerDispatchProps {
  fetchCourseContent: () => ITrackableAction;
  clearCourseContentData: () => void;
  fetchCourseLectureList: () => ITrackableAction;
  clearCourseLectureList: () => void;
  fetchScormLectureCMI: (lectureId: string) => ITrackableAction;
  clearCourseLectureCMI: () => void;
  updateScormLectureCMI: (lectureId: string, data: IComputerManagedInstruction) => ITrackableAction;
}
type IScormCourseViewContainerProps = IScormCourseViewContainerOwnProps & IScormCourseViewContainerStateProps & IScormCourseViewContainerDispatchProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ScormCourseViewContainer = (props: IScormCourseViewContainerProps) => {

  const isSingleFileScormCourse = useMemo(() => props.lectureList ? CourseHelperUtils.isSingleFileScormCourse(props.lectureList) : false, [props.lectureList]);

  const selectedLecture = useMemo(() => {
    if (props.lectureList) {
      if (props.lectureId) {
        return CourseHelperUtils.findLecture(props.lectureList, props.lectureId);
      } else if (isSingleFileScormCourse) {
        return props.lectureList[0];
      } else {
        return CourseHelperUtils.findLectureWithFile(props.lectureList);
      }
    }
    return;
  }, [props.lectureId, props.lectureList]);

  useEffect(() => {
    props.fetchCourseContent();
    props.fetchCourseLectureList();

    return () => {
      props.clearCourseContentData();
      props.clearCourseLectureList();
    };

  }, [props.courseId]);

  useEffect(() => {
    if (selectedLecture?.id) {
      props.fetchScormLectureCMI(selectedLecture.id);
    }

    return () => {
      props.clearCourseLectureCMI();
    };

  }, [selectedLecture?.id]);

  const handleLectureCMIUpdate = (lectureCMI: IComputerManagedInstruction) => {
    if (selectedLecture?.id) {
      props.updateScormLectureCMI(selectedLecture.id, lectureCMI);
    }
  };


  return (
    <React.Fragment>
      {props.course && props.lectureList &&
        <ScormCourseView
          course={props.course}
          lectureCMI={props.scormLectureCMI}
          lectureList={props.lectureList}
          hideSider={isSingleFileScormCourse}
          showNotes={props.lectureId ? CourseHelperUtils.isNotesCourseContent(props.lectureId) : false}
          selectedLecture={selectedLecture}
          onEntityTagUpdate={props.fetchCourseContent}
          onLectureCMIUpdate={handleLectureCMIUpdate}
        />
      }
    </React.Fragment>
  );

};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IScormCourseViewContainerOwnProps): IScormCourseViewContainerStateProps => ({
  course: ScormCourseViewBusinessStore.selectors.getScormCourseContent(state),
  lectureList: ScormCourseViewBusinessStore.selectors.getScormCourseLectureList(state),
  scormLectureCMI: ScormCourseViewBusinessStore.selectors.getScormLectureCMI(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IScormCourseViewContainerOwnProps): IScormCourseViewContainerDispatchProps => ({
  fetchCourseContent: () => createTrackableAction(dispatch(ScormCourseViewBusinessStore.actions.fetchScormCourseContent(ownProps.courseId))),
  clearCourseContentData: () => dispatch(ScormCourseViewBusinessStore.actions.clearScormCourseContent()),
  fetchCourseLectureList: () => createTrackableAction(dispatch(ScormCourseViewBusinessStore.actions.fetchScormCourseLectureList(ownProps.courseId))),
  clearCourseLectureList: () => dispatch(ScormCourseViewBusinessStore.actions.clearScormCourseLectureList()),
  fetchScormLectureCMI: (lectureId: string) => createTrackableAction(dispatch(ScormCourseViewBusinessStore.actions.fetchScormLectureCMI(lectureId))),
  clearCourseLectureCMI: () => dispatch(ScormCourseViewBusinessStore.actions.clearScormLectureCMI()),
  updateScormLectureCMI: (lectureId: string, data: IComputerManagedInstruction) => createTrackableAction(dispatch(ScormCourseViewBusinessStore.actions.updateScormLectureCMI(lectureId, data))),
});


export default connect<IScormCourseViewContainerStateProps, IScormCourseViewContainerDispatchProps, IScormCourseViewContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ScormCourseViewContainer as any));

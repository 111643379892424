import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IActionMenuItem } from '@src/components/common/table/ActionMenu';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionDataCount } from '@src/service/business/common/types';
import { Button } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IUserManagementListOwnProps {
  userList: IUserInfo[];
  paginationData?: ICollectionDataCount;
  scrollable?: boolean;
  selectableTable?: boolean;
  showSelectedElementsPanel?: boolean;
  showAction: boolean;
  canEnrollUserCourse?: boolean;
  onSelectedUserChange?: boolean;
  onSelectedUserSubmit?: (data: IUserInfo[]) => void;
  handleSelectedUsersChange?: (data: IUserInfo[]) => void;
  additionalCols?: () => ITimunDataTableCol<IUserInfo>[];
  onPageChange?: (page: number, pageSize?: number) => void;
}

type IUserManagementListProps = IUserManagementListOwnProps & IWithLocalizeOwnProps & WithRouterProps;

interface IUserUserManagementListState {}

// -- Component
// ----------
/** Display admin user management list with generic dataTable  */
class UserManagementList extends React.Component<IUserManagementListProps, IUserUserManagementListState> {
  state: IUserUserManagementListState = {};
  renderColumns = () => {
    const columns: ITimunDataTableCol<IUserInfo>[] = [
      {
        key: 'avatar',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_AVATAR_TITLE'),
        content: (record: IUserInfo) => <AppUserAvatar imagePath={record.profileImageUrl} />,
      },
      {
        key: 'userFirstName',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_FIRST_NAME_TITLE'),
        content: (record: IUserInfo) => record.firstName,
      },
      {
        key: 'userLastName',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_LAST_NAME_TITLE'),
        content: (record: IUserInfo) => record.lastName,
      },
      {
        key: 'userEmail',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_EMAIL_TITLE'),
        content: (record: IUserInfo) => this.getEmailValue(record.contactData),
      },
    ];
    if (this.props.additionalCols) {
      columns.push(...this.props.additionalCols());
    }
    return columns;
  };

  renderActionMenuItems = () => {
    const actions: IActionMenuItem<IUserInfo>[] = [
      {
        title: this.props.translate('USER_MANAGEMENT_LIST_VIEW.SHOW_USER_PROFILE_ACTION_BUTTON_TITLE'),
        onClick: (record) => this.props.router.push(`/user/${record.id}`),
      },
    ];

    return actions;
  };

  render() {
    return (
      <React.Fragment>
        <DataTable<IUserInfo>
          selectable={this.props.selectableTable}
          onSelectedChange={this.props.handleSelectedUsersChange}
          items={this.props.userList}
          paginationData={this.props.paginationData}
          paginationConfig={{ onChange: this.props.onPageChange }}
          columns={this.renderColumns()}
          scrollable={true}
          showActionMenu={this.props.showAction}
          showSelectedElementsPanel={true}
          actionMenuItems={this.renderActionMenuItems()}
          additionalHeaderActions={this.getSelectedUserSubmit}
        />
      </React.Fragment>
    );
  }

  getSelectedUserSubmit = (selectedUsers: IUserInfo[], resetSelecteUsersState?: (data: IUserInfo[]) => void): React.ReactNode => {
    const handleSubmit = () => {
      this.props.onSelectedUserSubmit?.(selectedUsers);
      resetSelecteUsersState?.([]);
    };
    if (this.props.canEnrollUserCourse || this.props.onSelectedUserChange) {
      return (
        <Button type="primary" icon={<LemonIcon name="check" />} onClick={handleSubmit}>
          {this.props.onSelectedUserChange ? this.props.translate('TIMUN_IMPORT_USER.BUTTON') : this.props.translate('USER_VIEW_CONTAINER.ENROLL_USER')}
        </Button>
      );
    }
    return;
  };

  getEmailValue = (contactData: IContactData[]): string => {
    if (contactData) {
      const email = contactData?.find((contact) => contact.type.id === ContactDataTypeEnum.EMAIL);
      if (email) {
        return email.value;
      }
    }
    return '';
  };
}

// -- HOCs and exports
// ----------
export default withLocalize(withRouter<IUserManagementListOwnProps>(UserManagementList as any));

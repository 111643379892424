import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IPieData, TimunPieChart } from '@src/components/externalEducationApplication/statistics/charts/TimunPieChart';
import StatisticCardItem from '@src/components/externalEducationApplication/statistics/StatisticCardItem';
import { IExamTemplateStatistics, IQuestionCorrectnessPercentage } from '@src/model/education/ExamTemplateStatistics';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Divider, Progress, Row, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';

// -- Prop types
// ----------
const timeFormat = AppConfigService.getValue('dateTimeFormat.timeWithSeconds');

// -- Prop types
// ----------

export interface IExamTemplateStatisticsViewOwnProps {
  examTemplateStatistics: IExamTemplateStatistics;
}
type IExamTemplateStatisticsViewProps = IExamTemplateStatisticsViewOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExamTemplateStatisticsView: React.FC<IExamTemplateStatisticsViewProps> = (props: IExamTemplateStatisticsViewProps) => {
  const columns: Array<ColumnProps<IQuestionCorrectnessPercentage>> = [
    {
      title: props.translate('EXAM_TEMPLATE.STATISTICS.QUESTION_ORDERING_TITLE'),
      key: 'ordering',
      width: '10%',
      render: (record: IQuestionCorrectnessPercentage) => record.question.ordering,
    },
    {
      title: props.translate('EXAM_TEMPLATE.STATISTICS.QUESTION_TEXT_TITLE'),
      key: 'text',
      render: (record: IQuestionCorrectnessPercentage) => record.question.intro?.text,
    },
    {
      title: props.translate('EXAM_TEMPLATE.STATISTICS.QUESTION_CORRECTNESS_TITLE'),
      key: 'time',
      width: '35%',
      render: (record: IQuestionCorrectnessPercentage) => {
        const value = roundPercentage(record.correctnessPercentage);

        return <Progress percent={value} />;
      },
    },
  ];

  const roundPercentage = (percentage: number): number => {
    return +(percentage.toFixed(1));
  };
  const examTemplateStatistics: IPieData[] = [
    {
      label: props.translate('EXAM_TEMPLATE.STATISTICS.CORRECTNESS_PERCENTAGE_INCORRECT'),
      value: roundPercentage(100 - props.examTemplateStatistics.correctnessPercentage),
    },
    {
      label: props.translate('EXAM_TEMPLATE.STATISTICS.CORRECTNESS_PERCENTAGE_CORRECT'),
      value: roundPercentage(props.examTemplateStatistics.correctnessPercentage),
    },
  ];

  const averageCompletionTimeInMs = moment().startOf('day').milliseconds(props.examTemplateStatistics.averageCompletionTimeInMs).format(timeFormat);
  return (
    <React.Fragment>
      <Row gutter={[16, 16]} align="middle" justify="space-between">
        <Col xs={24} lg={16}>
          <Typography.Title level={3}> {props.translate('EXAM_TEMPLATE.STATISTICS.QUIZ_CORRECTNESS_PERCENTAGE_TITLE')} </Typography.Title>
          <TimunPieChart data={examTemplateStatistics} percentage={true} />
        </Col>

        <Col className="text-center" xs={24} lg={8}>
          <StatisticCardItem statisticalNumber={props.examTemplateStatistics.submittedExamCount} description={props.translate('EXAM_TEMPLATE.STATISTICS.SUBMITED_COUNT_TITLE')} />
          <br />
          <StatisticCardItem statisticalNumber={averageCompletionTimeInMs} description={props.translate('EXAM_TEMPLATE.STATISTICS.AVERAGE_COMPLETION_TIME_TITLE')} />
        </Col>

        <Divider />

        <Col span={24}>
          <Typography.Title level={3}> {props.translate('EXAM_TEMPLATE.STATISTICS.QUESTION_LIST_CORRECTNESS_PERCENTAGE_TITLE')} </Typography.Title>
          <Table
            columns={columns}
            dataSource={props.examTemplateStatistics.questionsStatistics}
            rowKey={(record) => record.question.id}
            pagination={false}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withLocalize<IExamTemplateStatisticsViewOwnProps>(ExamTemplateStatisticsView as any);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamTemplateSettingsView from '@src/components/exam/view/ExamTemplateSettingsView';
import { IExamTemplate } from '@src/model/education/ExamTemplate';
import examTemplateBusinessStore from '@src/service/business/examtemplates/examTemplateBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------

export interface IExamTemplateSettingsContainerOwnProps {
  examTemplateId: string;
}

export interface IExamTemplateSettingsContainerStateProps {
  examTemplate: IExamTemplate;
}

export interface IExamTemplateSettingsContainerDispatchProps {
  fetchExamTemplate: () => ITrackableAction;
  updateExamTemplate: (examTemplate: IExamTemplate) => ITrackableAction;
}

type IExamTemplateSettingsContainerProps = IExamTemplateSettingsContainerOwnProps & IExamTemplateSettingsContainerStateProps & IExamTemplateSettingsContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------

/** Container component for displaying examTemplate instances and creating instances */
const ExamTemplateSettingsContainer = (props: IExamTemplateSettingsContainerProps) => {
  useEffect(() => {
    props.fetchExamTemplate();
  }, [props.examTemplateId]);

  const handleUpdatedExamTemplateSubmit = (examTemplateData: IExamTemplate) => {
    const newExamTemplate = { ...props.examTemplate, ...examTemplateData };
    props
      .updateExamTemplate(newExamTemplate)
      .track()
      .subscribe(() => {
        props.fetchExamTemplate();
      });
  };

  return <React.Fragment>{props.examTemplate && <ExamTemplateSettingsView examTemplate={props.examTemplate} onSubmit={handleUpdatedExamTemplateSubmit} />}</React.Fragment>;
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExamTemplateSettingsContainerOwnProps): IExamTemplateSettingsContainerStateProps => ({
  examTemplate: examTemplateBusinessStore.selectors.getExamTemplate(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IExamTemplateSettingsContainerOwnProps): IExamTemplateSettingsContainerDispatchProps => ({
  fetchExamTemplate: () => createTrackableAction(dispatch(examTemplateBusinessStore.actions.fetchExamTemplate(ownProps.examTemplateId))),
  updateExamTemplate: (examTemplate: IExamTemplate) => createTrackableAction(dispatch(examTemplateBusinessStore.actions.updateExamTemplate(examTemplate))),
});

// -- HOCs and exports
// ----------
export default connect<IExamTemplateSettingsContainerStateProps, IExamTemplateSettingsContainerDispatchProps, IExamTemplateSettingsContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(ExamTemplateSettingsContainer as any)));

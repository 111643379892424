import ScormCourseViewContainer from '@src/components/course/scorm/ScormCourseViewContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type IScormViewPageProps = WithRouterProps;

const ScormViewPage = (props: IScormViewPageProps) => <ScormCourseViewContainer courseId={props.params.courseId} lectureId={props.params.lectureId} />;

export default withRouter<IScormViewPageProps>(ScormViewPage);

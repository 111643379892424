import LocationCodebookContainer from '@src/components/codebook/LocationCodebookContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { Empty } from 'antd';
import React from 'react';

interface IAdminLocationPageOwnProps { }
type IAdminLocationCodebookPageProps = IWithRolesOwnProps & IWithTenantPropEnabledOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const AdminLocationCodebookPage = (props: IAdminLocationCodebookPageProps) => {
  const DisplayComponent = (props.isSuperAdmin() || props.isTenantPropEnabled('codebook_location')) ? <LocationCodebookContainer /> : <Empty description={props.translate('COMMON.LABEL.NOT_ALLOWED_TO_ACCESS')} />;
  return (
    <React.Fragment>
      {DisplayComponent}
    </React.Fragment>
  );
};

export default withTenantPropEnabled<IAdminLocationPageOwnProps>(withLocalize(withRoles(AdminLocationCodebookPage as any)));

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { Form, Modal, Typography } from 'antd';
import ExpenseForm from '@src/components/externalEducationApplication/form/ExpenseForm';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';


// -- Prop types
// ----------

export interface ICoFinancedEducationPriceModalFormOwnProps {
  value?: IExternalEducationApplication;
  onCancel?: () => void;
  onSubmit?: (data: IExternalEducationExpense[]) => void;
}
type ICoFinancedEducationPriceModalFormProps = ICoFinancedEducationPriceModalFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const CoFinancedEducationPriceModalForm: React.FC<ICoFinancedEducationPriceModalFormProps> = (props: ICoFinancedEducationPriceModalFormProps) => {
  const [form] = Form.useForm<IExternalEducationExpense>();
  const coFinancedEducationPrice = props.value?.expenses?.find(expense => expense.expenseType.id === ExpeneseTypeEnum.CO_FINANCED_EDUCATION_PRICE);
  const handleSubmit = (newCoFinancedEducationPrice: IExternalEducationExpense) => {
    const updatedExpenses = props.value?.expenses?.filter(expense => expense.expenseType.id !== ExpeneseTypeEnum.CO_FINANCED_EDUCATION_PRICE) ?? [];
    updatedExpenses?.push(newCoFinancedEducationPrice);
    props.onSubmit?.(updatedExpenses);
    props.onCancel?.();
  };

  return (
    <Modal
      title={props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.MODAL_TITLE')}
      visible={true}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Typography.Paragraph>{props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.MODAL_INFO_TEXT')}</Typography.Paragraph>
      <ExpenseForm formRef={form} value={coFinancedEducationPrice} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default withLocalize<ICoFinancedEducationPriceModalFormOwnProps>(CoFinancedEducationPriceModalForm as any);

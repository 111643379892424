import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Select } from 'antd';
import React, { useMemo } from 'react';

// -- Const
// ----------

export interface ISortItem {
  id: string;
  translationKey: string;
}
// -- Prop types
// ----------
export interface ISortSelectorOwnProps {
  value?: string[];
  sortItems: ISortItem[];
  onChange: (sort: string[]) => void;
}

type ISortSelectorProps = ISortSelectorOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const SortSelector: React.FC<ISortSelectorProps> = (props) => {
  const options = useMemo(() => props.sortItems.map((item) => ({ value: item.id, key: item.id, dataTestId: `timun-sortSelect_option_${item.id}`, label: props.translate(item.translationKey) })), [props.sortItems, props.translate]);

  return <Select onChange={props.onChange} placeholder={props.translate('COMMON.LABEL.PICK_ANSWER_PLACEHOLDER')} defaultValue={props.value} allowClear={true} data-test-id="timun-externalEducationApplicationFilter__sortSelect" options={options} />;
};

export default withLocalize<ISortSelectorOwnProps>(SortSelector as any);

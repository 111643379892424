import { FormComponentProps } from '@ant-design/compatible/lib/form';
import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import IntegrationVideoForm from '@src/components/common/integrationvideo/IntegrationVideoForm';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import AllowedUserRoles from '@src/components/common/role/AllowedUserRoles';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { RouterNavigationPromptContext } from '@src/components/common/route/prompt/RouterNavigationPromptContext';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import IfTenantPropEnabled from '@src/components/tenant/IfTenantPropEnabled';
import withTenantPropEnabled from '@src/components/tenant/withTenantPropEnabled';
import WebinarHelperUtils from '@src/components/webinar/common/WebinarHelperUtils';
import { IFile } from '@src/model/file/File';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IWebinar, WebinarStatusEnum } from '@src/model/webinar/Webinar';
import { IWebinarCreatePayload } from '@src/service/business/webinars/webinarViewBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Input, Radio, Row, TimePicker, Form } from 'antd';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';

const TextArea = Input.TextArea;

const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const TIME_FORMAT: string = AppConfigService.getValue('dateTimeFormat.time');
const BACKEND_DATE_TIME: string = AppConfigService.getValue('dateTimeFormat.backendDate');
const WEBINAR_TITLE_MAX_LENGTH: number = 128;
const WEBINAR_GUEST_MAX_LENGTH: number = AppConfigService.getValue('components.common.smallCharacterLengthInput');

interface IWebinarForm extends IWebinar {
  startDate: string;
  startTime: string;
}

interface IWebinarFormOwnProps {
  webinar?: IWebinar;
  coverImageId?: string;
  onSubmit?: (data: IWebinarCreatePayload) => void;
  onCancel?: (editing: boolean) => void;
  onDelete?: (data: IWebinar) => void;
  onCoverSubmit: (data: IFile) => void;
}

type IWebinarFormProps = IWebinarFormOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & IWithPathResolverOwnProps & FormComponentProps;

const WebinarForm: React.FC<IWebinarFormProps> = (props: IWebinarFormProps) => {
  const { setIsRouterPromptActive } = useContext(RouterNavigationPromptContext);
  const [form] = Form.useForm<IWebinarForm>();
  const [isEditingCover, setIsEditingCover] = useState<boolean>(false);

  const initialValues = useMemo(() => ({ ...props.webinar, startDate: props.webinar?.estimatedStartDateTime ? moment(props.webinar.estimatedStartDateTime) : undefined, startTime: props.webinar?.estimatedStartDateTime ? moment(props.webinar.estimatedStartDateTime) : undefined }), [props.webinar]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    if (props.onCancel) {
      props.onCancel(false);
      setIsRouterPromptActive?.(false);
    }
  }, [props.onCancel]);

  const toggleCoverEdit = useCallback(() => setIsEditingCover(!isEditingCover), [isEditingCover]);

  const handleFieldsChange = useCallback(() => setIsRouterPromptActive?.(true), [setIsRouterPromptActive]);

  const handleDelete = useCallback(
    (data: IWebinar) => {
      setIsRouterPromptActive?.(false);
      props.onDelete?.(data);
    },
    [setIsRouterPromptActive]
  );

  const handleFinish = useCallback(
    (values: IWebinarForm) => {
      const newDateValues = { year: moment(values.startDate).year(), month: moment(values.startDate).month(), date: moment(values.startDate).date() };
      const newStartDateTime = moment(values.startTime)
        .set(newDateValues)
        .format(BACKEND_DATE_TIME);
      const data: IWebinarCreatePayload = {
        coverImageUrl: values.coverImageUrl,
        description: values.description,
        estimatedStartDateTime: newStartDateTime,
        guest: values.guest,
        link: values.link,
        title: values.title,
        status: values.status,
        integrationVideo: values.integrationVideo,
      };
      setIsRouterPromptActive?.(false);
      props.onSubmit?.(data);
    },
    [props.onSubmit, setIsRouterPromptActive]
  );

  const inCreation = useMemo(() => props.webinar && WebinarHelperUtils.isWebinarInStatus(props.webinar, [WebinarStatusEnum.IN_CREATION]), [props.webinar, WebinarHelperUtils.isWebinarInStatus]);
  const creationFinished = useMemo(() => props.webinar && WebinarHelperUtils.isWebinarInStatus(props.webinar, [WebinarStatusEnum.CREATION_FINISHED]), [props.webinar, WebinarHelperUtils.isWebinarInStatus]);
  const wasPublished = useMemo(() => props.webinar && WebinarHelperUtils.isWebinarInStatus(props.webinar, [WebinarStatusEnum.PUBLISHED, WebinarStatusEnum.FINISHED]), [props.webinar, WebinarHelperUtils.isWebinarInStatus]);

  const ButtonControls = () => (
    <Form.Item>
      <Row justify="end" gutter={12}>
        <Col>
          <Button onClick={handleCancel}>{props.translate('COMMON.ACTION_CANCEL')}</Button>
        </Col>
        {props.onDelete && (
          <Col>
            <DeleteElementButton<IWebinar> icon={<LemonIcon name="delete" />} buttonText={props.translate('COMMON.ACTION_DELETE')} item={props.webinar} onDelete={handleDelete} />
          </Col>
        )}
        <Col>
          <Button type="primary" htmlType="submit">
            {props.translate('COMMON.ACTION_SAVE')}
          </Button>
        </Col>
      </Row>
    </Form.Item>
  );

  return (
    <Form className="timun-webinarCreate__form" layout="vertical" onFieldsChange={handleFieldsChange} onFinish={handleFinish} form={form} initialValues={initialValues}>
      <Form.Item
        label={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_TITLE')}
        name="title"
        rules={[
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
          { max: WEBINAR_TITLE_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_LINK')}
        name="link"
        rules={[
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { required: false, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_DATE')} name="startDate" rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <DatePicker className="timun-webinarCreate__form" placeholder={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_DATE_PLACEHOLDER')} allowClear={false} format={DATE_FORMAT} />
      </Form.Item>

      <Form.Item label={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_TIME')} name="startTime" rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <TimePicker className="timun-webinarCreate__form" placeholder={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_TIME_PLACEHOLDER')} allowClear={false} format={TIME_FORMAT} />
      </Form.Item>

      {props.webinar && (
        <Form.Item label={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_STATUS')} name={['status', 'id']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
          <Radio.Group buttonStyle="solid">
            <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
              <Radio.Button value={WebinarStatusEnum.IN_CREATION} disabled={(creationFinished || wasPublished) && props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.TRAINEE])}>
                {props.translate('WEBINAR.STATUS_TITLE.IN_CREATION')}
              </Radio.Button>
              <Radio.Button value={WebinarStatusEnum.CREATION_FINISHED} disabled={wasPublished && props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.TRAINEE])}>
                {props.translate('WEBINAR.STATUS_TITLE.CREATION_FINISHED')}
              </Radio.Button>
            </AllowedUserRoles>
            <AllowedUserRoles roles={[UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
              <Radio.Button value={WebinarStatusEnum.PUBLISHED} disabled={inCreation}>
                {props.translate('WEBINAR.STATUS_TITLE.PUBLISHED')}
              </Radio.Button>
            </AllowedUserRoles>
            <AllowedUserRoles roles={[UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
              <Radio.Button value={WebinarStatusEnum.FINISHED} disabled={inCreation}>
                {props.translate('WEBINAR.STATUS_TITLE.FINISHED')}
              </Radio.Button>
            </AllowedUserRoles>
            <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
              <Radio.Button value={WebinarStatusEnum.CANCELLED} disabled={inCreation || creationFinished}>
                {props.translate('WEBINAR.STATUS_TITLE.CANCELLED')}
              </Radio.Button>
              <Radio.Button value={WebinarStatusEnum.ARCHIVED}>{props.translate('WEBINAR.STATUS_TITLE.ARCHIVED')}</Radio.Button>
            </AllowedUserRoles>
          </Radio.Group>
        </Form.Item>
      )}

      <Form.Item
        label={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_GUEST')}
        name="guest"
        rules={[
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: WEBINAR_GUEST_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_DESCRIPTION')}
        name="description"
        rules={[
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
        ]}
      >
        <TextArea />
      </Form.Item>

      <Form.Item label={props.translate('WEBINAR_VIEW.CREATE.WEBINAR_PHOTO')} name={'coverImageUrl'} rules={[{ whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') }, { message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <React.Fragment>
          {props.webinar?.coverImageUrl || props.coverImageId ? (
            <div className={'timun-webinarView__coverImage-editable'}>
              <LemonImage imagePath={props.webinar?.coverImageUrl || (props.coverImageId && props.resolveApiFilePath(props.coverImageId)) || 'cover-placeholder.png'} className="timun-webinarView__coverImage" />
              <div className="timun-webinarView__coverImage-editButton">
                <a onClick={toggleCoverEdit}>
                  <LemonIcon name="edit" />
                </a>
              </div>
            </div>
          ) : (
            <Button icon={<LemonIcon name="paperClip" />} onClick={toggleCoverEdit}>
              {props.translate('FILE_UPLOAD.MODAL_UPLOAD_BUTTON')}
            </Button>
          )}
          {isEditingCover && <ImageUploadForm avatar={true} defaultImageLink={props.webinar?.coverImageUrl} onSubmit={props.onCoverSubmit} onClose={toggleCoverEdit} />}
        </React.Fragment>
      </Form.Item>

      <IfTenantPropEnabled property="integration_video" fallback="">
        <Form.Item label={props.translate('COURSE_VIEW.UPDATE.LECTURE_VIDEO_FORM_LABEL')} name="integrationVideo">
          <IntegrationVideoForm />
        </Form.Item>
      </IfTenantPropEnabled>
      <ButtonControls />
    </Form>
  );
};

export default withPathResolver(withTenantPropEnabled<IWebinarFormOwnProps>(withLocalize(withRoles(WebinarForm as any))));

import { DefaultViewNameDataList } from '@src/service/business/common/types';

export class BussinessStoreHelperUtils {
  static getStoreKey = (listKey?: string): string => {
    if (listKey) {
      return listKey;
    } else {
      return DefaultViewNameDataList;
    }
  };

  static getStoreValue = (store: any, listKey?: string): any => {
    return store[BussinessStoreHelperUtils.getStoreKey(listKey)];
  };
}

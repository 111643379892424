import { FormComponentProps } from '@ant-design/compatible/es/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import { Editor } from '@src/components/common/editor/Editor';
import { TimunEditorConfig } from '@src/components/common/editor/TimunEditorConfigs';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { emailTemplateTypeEnum, EmailTemplateTypeEnum, IEmailTemplate } from '@src/model/emailtemplate/EmailTemplate';
import { IEmailTemplateCreatePayload } from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input, Modal, Select, Form } from 'antd';
import React, { useCallback } from 'react';

// -- Const
// ----------
const MAX_NUMBER_OF_CHARACTER: number = AppConfigService.getValue('components.emailTemplateForm.maxChar');
const TITLE_MAX_LENGTH: number = AppConfigService.getValue('components.common.inputMaxLength');

const emailTemplateTypes = Object.keys(emailTemplateTypeEnum).map((key) => {
  return {
    name: key,
    id: emailTemplateTypeEnum[key],
  };
});

const FormItem = Form.Item;

// -- Prop types
// ----------
interface IEmailTemplateFormOwnProps {
  onCancel?: () => void;
  onUpdate?: (emailTemplate: IEmailTemplate) => void;
  onCreate?: (EmailTemplateData: IEmailTemplateCreatePayload) => void;
  title: string;
  emailTemplate?: IEmailTemplate;
}

type IEmailTemplateFormProps = IEmailTemplateFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

const EmailTemplateForm: React.FC<IEmailTemplateFormProps> = (props: IEmailTemplateFormProps) => {
  const [form] = Form.useForm<IEmailTemplate>();

  const handleCancel = useCallback(() => {
    if (form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: () => props.onCancel?.(),
        title: props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      props.onCancel?.();
    }
  }, [form.isFieldsTouched, props.onCancel]);

  const handleFinish = useCallback(
    (values: IEmailTemplate) => {
      const emailTemplateData = props.emailTemplate
        ? {
            ...props.emailTemplate,
            ...values,
          }
        : values;

      if (props.emailTemplate) {
        props.onUpdate?.(emailTemplateData);
      } else {
        props.onCreate?.(emailTemplateData);
      }
    },
    [props.emailTemplate, props.onUpdate, props.onCreate]
  );

  return (
    <Modal title={props.title} visible={true} onCancel={handleCancel} onOk={form.submit} okText={props.translate('COMMON.ACTION_SAVE')} cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form form={form} onFinish={handleFinish} initialValues={{ title: props.emailTemplate?.title, content: props.emailTemplate?.content, type: { id: props.emailTemplate?.type.id ?? EmailTemplateTypeEnum.CODE_GENERATION }, id: props.emailTemplate?.id }} layout="vertical">
        <FormItem
          label={props.translate('EMAIL_TEMPLATE_FORM.TITLE_LABEL')}
          name={'title'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: TITLE_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}
        >
          <Input />
        </FormItem>

        <FormItem
          name={'content'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: MAX_NUMBER_OF_CHARACTER, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}
          label={<FormItemLabelIcon label={props.translate('EMAIL_TEMPLATE_FORM.CONTENT_LABEL')} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: MAX_NUMBER_OF_CHARACTER })} />}
        >
          <Editor
            init={{
              ...TimunEditorConfig.defaultConfig(),
              height: 300,
              menubar: true,
            }}
          />
        </FormItem>

        <FormItem name={['type', 'id']} label={props.translate('EMAIL_TEMPLATE_FORM.TYPE_LABEL')}>
          <Select>
            {emailTemplateTypes.map((type) => {
              return (
                <Select.Option key={type.id} value={type.id}>
                  {props.translate(`EMAIL_TEMPLATE_FORM.${type.name}_TYPE`)}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>

        <FormItem name={'id'} hidden={true} noStyle={true}>
          <Input type="hidden" />
        </FormItem>
      </Form>
    </Modal>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IEmailTemplateFormOwnProps>(EmailTemplateForm as any);

import ExamTemplateQuizCreatorContainer from '@src/components/exam/examtemplate/ExamTemplateQuizCreatorContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type IExamTemplateQuizCreatorPageProps = WithRouterProps;

const ExamTemplateQuizCreatorPage = (props: IExamTemplateQuizCreatorPageProps) => <ExamTemplateQuizCreatorContainer examTemplateId={props.params.examTemplateId} />;

export default withRouter<IExamTemplateQuizCreatorPageProps>(ExamTemplateQuizCreatorPage);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NoteForm from '@src/components/user/form/NoteForm';
import { INote } from '@src/model/user/Note';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import UserNoteBusinessStore from '@src/service/business/usernotes/userNoteBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// -- Prop types
// ----------
interface INoteUpdateContainerOwnProps {
  note?: INote;
  onCancel?: () => void;
  onChange?: () => void;
  canSetPrivateNote?: boolean;
}

interface INoteUpdateContainerStateProps {}

interface INoteUpdateContainerDispatchProps {
  updateNote: (note: INote) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

// -- State types
// ----------
type INoteUpdateContainerContentProps = INoteUpdateContainerOwnProps & INoteUpdateContainerStateProps & INoteUpdateContainerDispatchProps & IWithLocalizeOwnProps;

interface INoteUpdateContainerState {}

// -- Component
// ----------

/** Component for note update */
class NoteUpdateContainer extends React.Component<INoteUpdateContainerContentProps, INoteUpdateContainerState> {
  state = {};

  render() {
    return <NoteForm note={this.props.note} objectTypeId={this.props.note?.objectType.id} title={this.props.translate('NOTE_LIST.NOTE_MODAL_LABEL')} onCancel={this.props.onCancel} onSubmit={this.handleNoteUpdate} canSetPrivateNote={this.props.canSetPrivateNote} />;
  }

  private handleNoteUpdate = (noteData: INote) => {
    this.props
      .updateNote(noteData)
      .track()
      .subscribe(
        // success
        () => {
          this.props.onChange?.();
          this.props.reportMessage({ message: this.props.translate('COMMON.EDITED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        }
      );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: INoteUpdateContainerOwnProps): INoteUpdateContainerStateProps => ({});
// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): INoteUpdateContainerDispatchProps => ({
  updateNote: (note: INote) => createTrackableAction(dispatch(UserNoteBusinessStore.actions.updateUserNote(note))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<INoteUpdateContainerStateProps, INoteUpdateContainerDispatchProps, INoteUpdateContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(NoteUpdateContainer as any));

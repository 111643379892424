import React from 'react';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Form, Input, Modal } from 'antd';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');

// -- Prop types
// ----------

export interface IExternalEducationApplicationTraineeConsultationModalOwnProps {
  canParticipate?: boolean;
  onCancel?: () => void;
  onConsultationSubmit?: (data: IExternalEducationApplication) => void;
}
type IExternalEducationApplicationTraineeConsultationModalProps = IExternalEducationApplicationTraineeConsultationModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationApplicationTraineeConsultationModal: React.FC<IExternalEducationApplicationTraineeConsultationModalProps> = (props: IExternalEducationApplicationTraineeConsultationModalProps) => {
  const [form] = Form.useForm<IExternalEducationApplication>();

  const handleFinish = (value: IExternalEducationApplication) => props.onConsultationSubmit?.(value);

  return (
    <Modal
      title={props.translate('EXTERNAL_EDUCATION_APPLICATION.CONSULTATION_MODAL.TITLE')}
      visible={true}
      onCancel={props.onCancel}
      onOk={form.submit}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}
      footer={<Button onClick={form.submit}> {props.translate('EXTERNAL_EDUCATION_APPLICATION.CONSULTATION_MODAL.OK_BUTTON_LABEL')} </Button>}>
      <Form
        layout="vertical"
        form={form}
        name="externalEducationApplication"
        onFinish={handleFinish}
      >
        <Form.Item
          name={['consultation', 'description']}
          label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_APPLICATION.CONSULTATION_FORM.CONSULTATION_DESCRIPTION_LABEL')}
            tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}>
          <Input.TextArea placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.CONSULTATION_FORM.CONSULTATION_DESCRIPTION_PLACEHOLDER')} />
        </Form.Item>
      </Form>
    </Modal >
  );
};

export default withLocalize<IExternalEducationApplicationTraineeConsultationModalOwnProps>(ExternalEducationApplicationTraineeConsultationModal as any);

import IIdRef from '@lamarodigital/quizzler-lib-frontend/model/common/IdRef';

import { IExamTemplate } from '@src/model/education/ExamTemplate';
import { EmailTemplateTypeEnum, IEmailTemplate } from '@src/model/emailtemplate/EmailTemplate';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export interface IEmailTemplateCreatePayload {
  content: string;
  title: string;
  type: IIdRef<EmailTemplateTypeEnum>;
}

export interface IEmailTemplateListFilter {
  type?: EmailTemplateTypeEnum;
}

// List filter ID
const EMAIL_TEMPLATE_LIST_FILTER = '@@EMAIL_TEMPLATE_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns email template list from store. */
const getEmailTemplateList = (store: any): ICollectionData<IEmailTemplate> => store.emailTemplateList;

/** Returns email template list filter. */
const getEmailTemplateListFilter = (store: any): IEmailTemplateListFilter => ListFilterBusinessStore.selectors.getListFilter(store, EMAIL_TEMPLATE_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  EMAIL_TEMPLATE_LIST_FETCH: 'EMAIL_TEMPLATE_LIST_FETCH',
  EMAIL_TEMPLATE_LIST_LOAD: 'EMAIL_TEMPLATE_LIST_LOAD',
  EMAIL_TEMPLATE_LIST_CLEAR: 'EMAIL_TEMPLATE_LIST_CLEAR',
  EMAIL_TEMPLATE_CREATE: 'EMAIL_TEMPLATE_CREATE',
  EMAIL_TEMPLATE_UPDATE: 'EMAIL_TEMPLATE_UPDATE',
  EMAIL_TEMPLATE_DELETE: 'EMAIL_TEMPLATE_DELETE',
};

/** Fetch email template list*/
const fetchEmailTemplateList = (params: ICollectionFetchPayload<IEmailTemplateListFilter>): IPayloadAction<ICollectionFetchPayload<IEmailTemplateListFilter>> => {
  return {
    type: Actions.EMAIL_TEMPLATE_LIST_FETCH,
    payload: params,
  };
};

/** Load email template list to store. */
const loadEmailTemplateList = (data: ICollectionData<IEmailTemplate>): IPayloadAction<ICollectionData<IEmailTemplate>> => {
  return {
    type: Actions.EMAIL_TEMPLATE_LIST_LOAD,
    payload: data,
  };
};

/** Clear email template list from store. Eg. when leaving view. */
const clearEmailTemplateListData = (): ILemonAction => {
  return {
    type: Actions.EMAIL_TEMPLATE_LIST_CLEAR,
  };
};

/** Create new email template. */
const createEmailTemplate = (data: IEmailTemplateCreatePayload): IPayloadAction<IEmailTemplateCreatePayload> => {
  return {
    type: Actions.EMAIL_TEMPLATE_CREATE,
    payload: data,
  };
};

/** Delete email template by id*/
const deleteEmailTemplate = (id: string): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.EMAIL_TEMPLATE_DELETE,
    payload: {
      id,
    },
  };
};

/** Update email template by ID. */
const updateEmailTemplate = (data: IEmailTemplate): IPayloadAction<IIdDataPayload<IEmailTemplate>> => {
  return {
    type: Actions.EMAIL_TEMPLATE_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

/** Store email template filter */
const storeEmailTemplateListFilter = (listFilter: IEmailTemplateListFilter) => {
  return ListFilterBusinessStore.actions.storeListFilter(EMAIL_TEMPLATE_LIST_FILTER, listFilter);
};

/** Clear email template filter */
const clearEmailTemplateListFilter = () => {
  return ListFilterBusinessStore.actions.clearListFilter(EMAIL_TEMPLATE_LIST_FILTER);
};

// -
// -------------------- Side-effects

const fetchEmailTemplateListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IEmailTemplateListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EMAIL_TEMPLATE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EmailTemplate')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEmailTemplateList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EMAIL_TEMPLATE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching email template', error);
      return o;
    })
  );
};

const createEmailTemplateEffect = (action$: Observable<IPayloadAction<IEmailTemplateCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EMAIL_TEMPLATE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;
      return EntityApiServiceRegistry.getService('EmailTemplate')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EMAIL_TEMPLATE.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating email template', error);
      return o;
    })
  );
};

const deleteEmailTemplateEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EMAIL_TEMPLATE_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;
      return EntityApiServiceRegistry.getService('EmailTemplate')
        .deleteEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EMAIL_TEMPLATE.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting email template', error);
      return o;
    })
  );
};

const updateEmailTemplateEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IEmailTemplate>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EMAIL_TEMPLATE_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EmailTemplate')
        .updateEntity(payload.id, payload.data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EMAIL_TEMPLATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating email template', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const emailTemplateList = (state: IExamTemplate | null = null, action: IPayloadAction<IExamTemplate>) => {
  if (action.type === Actions.EMAIL_TEMPLATE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EMAIL_TEMPLATE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const EmailTemplateBusinessStore = {
  actions: {
    fetchEmailTemplateList,
    loadEmailTemplateList,
    clearEmailTemplateListData,
    createEmailTemplate,
    updateEmailTemplate,
    deleteEmailTemplate,
    storeEmailTemplateListFilter,
    clearEmailTemplateListFilter,
  },

  selectors: {
    getEmailTemplateList,
    getEmailTemplateListFilter,
  },

  effects: {
    fetchEmailTemplateListEffect,
    createEmailTemplateEffect,
    updateEmailTemplateEffect,
    deleteEmailTemplateEffect,
  },

  reducers: {
    emailTemplateList,
  },
};

// --
// export business store
export default EmailTemplateBusinessStore;

/**
 * Helper methods for DateTime
 *
 */

import moment, { Moment } from 'moment';

export const getMinDateTime = (): Moment => {
  const now = moment();
  return now.minute(now.minute()).second(0);
};

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import ExternalEducationInstanceList from '@src/components/externaleducationistance/ExternalEducationInstanceList';
import { IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { ICollectionData } from '@src/service/business/common/types';
import { IExternalEducationInstanceListFilter } from '@src/service/business/externaleducations/ExternalEducationInstanceListBusinessStore';
import { Button, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface IExternalEducationInstanceListViewOwnProps {
  externalEducationInstanceList: ICollectionData<IExternalEducationInstance>;
  filter?: IExternalEducationInstanceListFilter;
  canAdd?: boolean;
  onAdd?: () => void;
  canEdit?: boolean;
  onEdit?: (data: IExternalEducationInstance) => void;
  onPageChange?: (page: number, size?: number) => void;
}

type IExternalEducationInstanceListViewProps = IExternalEducationInstanceListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education Instance short info table */
const ExternalEducationInstanceListView: React.FC<IExternalEducationInstanceListViewProps> = (props: IExternalEducationInstanceListViewProps) => {
  const additionalCols = (): Array<ITimunDataTableCol<IExternalEducationInstance>> => {
    return [
      {
        key: 'action',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.ACTION_LABEL'),
        content: (record: IExternalEducationInstance) => props.canEdit && <Button onClick={() => props.onEdit?.(record)} icon={<LemonIcon name="edit" />} />,
      },
    ];
  };

  return (
    <React.Fragment>
      {props.canAdd &&
        <React.Fragment>
          <Row className="timun-wrapper__gutterBox" justify="start">
            <Button type="primary" icon={<LemonIcon name="plus" />} onClick={props.onAdd}>
              {props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST_VIEW.ADD_EXTERNAL_EDUCATION_INSTANCE_BUTTON_LABEL')}
            </Button>
          </Row>
        </React.Fragment>
      }

      <ExternalEducationInstanceList onPageChange={props.onPageChange} externalEducationInstanceList={props.externalEducationInstanceList} additionalCols={props.canEdit ? additionalCols : undefined} />
    </React.Fragment>
  );
};

export default withLocalize<IExternalEducationInstanceListViewOwnProps>(ExternalEducationInstanceListView as any);

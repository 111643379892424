import { PaperClipOutlined } from '@ant-design/icons';
import { message, Progress, Spin } from 'antd';
import React from 'react';

import IntegrationVideoPlayer from '@src/components/common/integrationvideo/IntegrationVideoPlayer';
import IntegrationVideoUpload, { IUploadedVideoFile, IUploadStatus } from '@src/components/common/integrationvideo/IntegrationVideoUpload';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { IIntegrationVideo } from '@src/model/integrationvideo/IntegrationVideo';
import AppConfigService from '@src/service/common/AppConfigService';
import NumberFormatService from '@src/service/util/numberformat/NumberFormatService';
import LemonIcon from '@src/components/common/image/LemonIcon';

const ALLOWED_FILE_TYPES: string[] = AppConfigService.getValue('api.upload.allowedVideoTypes');
const ALLOWED_MAX_SIZE: number = AppConfigService.getValue('api.upload.maxFileSize');

// -- Prop types
// ----------

export interface IIntegrationVideoUploadFormOwnProps {
  value?: IIntegrationVideo;
  disabled?: boolean;

  onChange?: (file?: IIntegrationVideo) => void;
  onProgress?: (isInProgress: boolean) => void;
}
type IIntegrationVideoUploadFormProps = IIntegrationVideoUploadFormOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

/**
 * Component with a modal form for uploading new integration video file.
 *
 * TOOD: rename to VideoUploadControl or smthng better than "form"?
 * TODO: this is based on VideoUploadForm and ImageUploadForm components. Maybe we could extract most commons under 1 base file upload component
 */
class IntegrationVideoUploadForm extends React.Component<IIntegrationVideoUploadFormProps> {
  render() {
    const videoPreview = this.props.value != null ? <IntegrationVideoPlayer videoId={this.props.value.integrationId} integrationType={this.props.value.integrationType.id} /> : null;

    return (
      <React.Fragment>
        {/* ---------- video upload dialog ---------- */}
        <div className="timun-integrationVideo__previewPlaceholder">
          <IntegrationVideoUpload dragger={true} uploadProps={{ beforeUpload: this.beforeUpload, disabled: this.props.disabled }} onUpload={this.handleVideoUpload} onError={this.handleVideoError} onStatusChange={this.handleUploadStatusChange}>
            {(status) => (
              <React.Fragment>
                {status.isUploading && <Spin />}
                {videoPreview ?? <LemonIcon name="plus" />}
                {status.uploadProgress != null && status.uploadProgress.total > 0 && <Progress size="small" percent={Math.round((status.uploadProgress.uploaded / status.uploadProgress.total) * 100)} />}
              </React.Fragment>
            )}
          </IntegrationVideoUpload>
        </div>

        <div className="timun-integrationVideo__uploadLinkContainer">
          <IntegrationVideoUpload uploadProps={{ beforeUpload: this.beforeUpload, disabled: this.props.disabled }} onUpload={this.handleVideoUpload} onError={this.handleVideoError} onStatusChange={this.handleUploadStatusChange}>
            {(status) => {
              return (
                <React.Fragment>
                  {status.isUploading && <Spin />}&nbsp;
                  <a>
                    <PaperClipOutlined />
                    {this.props.translate('INTEGRATION_VIDEO.UPLOAD.UPLOAD_BUTTON')}
                  </a>
                  {status.uploadProgress != null && status.uploadProgress.total > 0 && <Progress size="small" percent={Math.round((status.uploadProgress.uploaded / status.uploadProgress.total) * 100)} />}
                </React.Fragment>
              );
            }}
          </IntegrationVideoUpload>
        </div>
      </React.Fragment>
    );
  }

  /** Handle uploading status change */
  handleUploadStatusChange = (status: IUploadStatus) => {
    this.props.onProgress?.(status.isUploading);
  };

  /** Video has uploaded successfully. */
  handleVideoUpload = (newVideo: IIntegrationVideo) => {
    message.success(this.props.translate('INTEGRATION_VIDEO.MESSAGE.UPLOAD_SUCCESSFUL'));

    this.props.onChange?.(newVideo);
  };

  /** Handle video upload error */
  handleVideoError = (err: unknown) => {
    console.error('Error uploading video', err);
    message.error(this.props.translate('INTEGRATION_VIDEO.MESSAGE.UPLOAD_ERROR'));
  };

  /** Check file upload rules (file type, size, ...) */
  checkFile = (file: IUploadedVideoFile) => {
    // check file type
    const isValidType = ALLOWED_FILE_TYPES.some((pattern) => file.type.match(new RegExp(pattern)));
    if (!isValidType) {
      message.error(this.props.translate('INTEGRATION_VIDEO.MESSAGE.ERROR_FILE_TYPE'));
    }

    // check file size
    const isValidSize = file.size <= ALLOWED_MAX_SIZE;
    if (!isValidSize) {
      const maxFileSizeInMbs = NumberFormatService.formatBytes(ALLOWED_MAX_SIZE);
      message.error(this.props.translate('INTEGRATION_VIDEO.MESSAGE.ERROR_FILE_SIZE', { maxFileSize: maxFileSizeInMbs }));
    }

    return isValidType && isValidSize;
  };

  /** Do some errands before upload. */
  beforeUpload = (file: IUploadedVideoFile) => {
    return this.checkFile(file);
  };
}

export default withLocalize<IIntegrationVideoUploadFormOwnProps>(withPathResolver(IntegrationVideoUploadForm) as any);

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import CountyDataPicker from '@src/components/common/datapicker/CountyDataPicker';
import LocationDataPicker from '@src/components/common/datapicker/LocationDataPicker';
import UserDataPicker from '@src/components/common/datapicker/UserDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesStateProps } from '@src/components/common/role/withRoles';
import SortSelector from '@src/components/common/select/SortSelector';
import YesNoSelector from '@src/components/common/select/YesNoSelector';
import EducationApplicationStatusSelector from '@src/components/externalEducationApplication/filter/common/EducationApplicationStatusSelector';
import ExternalApplicationOwnershipStatusSelector, { EducationApplicationOwnershipStatusEnum } from '@src/components/externalEducationApplication/filter/common/ExternalApplicationOwnershipStatusSelector';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { Button, Col, Form, Row } from 'antd';
import React, { useCallback, useEffect } from 'react';

const FILTER_LAYOUT_DEFAULT = {
  xs: 24,
  sm: 12,
  lg: 8,
  xl: 4,
};

const FILTER_LAYOUT_LARGE = {
  xs: 24,
  sm: 12,
  lg: 8,
  xl: 6,
};

// -- Const
// ----------
const sortItems = [
  {
    id: 'createdDateTime,desc',
    translationKey: 'COMMON.ACTION_SORT_CREATED_NEWEST',
  },
  {
    id: 'createdDateTime,asc',
    translationKey: 'COMMON.ACTION_SORT_CREATED_OLDEST',
  },
  {
    id: 'submittedAt,desc',
    translationKey: 'COMMON.ACTION_SORT_SUBMITTED_NEWEST',
  },
  {
    id: 'submittedAt,asc',
    translationKey: 'COMMON.ACTION_SORT_SUBMITTED_OLDEST',
  },
];

// -- Prop types
// ----------

interface IExternalEducationApplicationListFilterHelperValues {
  externalApplicationOwnershipStatus: EducationApplicationOwnershipStatusEnum;
}

type IEducationApplicationListFilter = IExternalEducationApplicationListFilter & IExternalEducationApplicationListFilterHelperValues;

interface IEducationApplicationListFilterOwnProps {
  filter?: IExternalEducationApplicationListFilter;
  sortValue: string[];
  onFilterSubmit?: (filter: IExternalEducationApplicationListFilter) => void;
  onSortChange: (sort: string[]) => void;
}

type IEducationApplicationListFilterProps = IEducationApplicationListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithRolesStateProps;

// -- Component
// ----------
const EducationApplicationListFilter: React.FC<IEducationApplicationListFilterProps> = (props: IEducationApplicationListFilterProps) => {
  const [form] = Form.useForm<IEducationApplicationListFilter>();

  useEffect(() => {
    form.resetFields();
  }, [form.resetFields, props.filter]);

  const handleResetFilterSubmit = useCallback(() => {
    props.onFilterSubmit?.({});
  }, [props.onFilterSubmit]);

  const handleFinish = useCallback(
    (values: IEducationApplicationListFilter) => {
      const listFilter = {
        ...values,
        evaluator: values.externalApplicationOwnershipStatus === EducationApplicationOwnershipStatusEnum.MY_APPLICATIONS ? { id: props.currentUser.id } : undefined,
        withoutCoordinator: values.externalApplicationOwnershipStatus !== undefined && values.externalApplicationOwnershipStatus === EducationApplicationOwnershipStatusEnum.WITHOUT_COORDINATOR ? true : undefined,
        excludedEvaluator: values.externalApplicationOwnershipStatus === EducationApplicationOwnershipStatusEnum.OTHER_COORDINATORS_APPLICATIONS ? { id: props.currentUser.id } : undefined,
      };
      props.onFilterSubmit?.(listFilter);
    },
    [props.onFilterSubmit]
  );

  return (
    <Form<IEducationApplicationListFilter> form={form} onFinish={handleFinish} initialValues={props.filter} layout="vertical">
      <Row gutter={8}>
        <Col {...FILTER_LAYOUT_DEFAULT}>
          <Form.Item name={'pendingConsultation'} label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PENDING_CONSULTATION_LABEL')}>
            <YesNoSelector fieldName="pendingConsultation" />
          </Form.Item>
        </Col>

        <Col {...FILTER_LAYOUT_DEFAULT}>
          <Form.Item name={'externalApplicationOwnershipStatus'} label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.APPLICATION_OWNERSHIP_LABEL')}>
            <ExternalApplicationOwnershipStatusSelector />
          </Form.Item>
        </Col>

        <Col {...FILTER_LAYOUT_DEFAULT}>
          <Form.Item name={'assignee'} label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_LABEL')}>
            <UserDataPicker placeholder={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_PLACEHOLDER')} role={UserRoleEnum.TRAINEE} />
          </Form.Item>
        </Col>

        <Col {...FILTER_LAYOUT_LARGE}>
          <Form.Item name={'county'} label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_COUNTY_LABEL')}>
            <CountyDataPicker placeholder={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_COUNTY_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationFilter__county" />
          </Form.Item>
        </Col>

        <Col {...FILTER_LAYOUT_LARGE}>
          <Form.Item name={'districtOffice'} label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.DISTRICT_OFFICE_ADDRESS_LABEL')}>
            <LocationDataPicker />
          </Form.Item>
        </Col>

        <Col {...FILTER_LAYOUT_LARGE}>
          <Form.Item name={'phases'} label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.EDUCATION_APPLICATION_STATUS_LABEL')}>
            <EducationApplicationStatusSelector />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8} align="middle" justify="space-between">
        <Col {...FILTER_LAYOUT_LARGE}>
          <Form.Item label={props.translate('COMMON.ACTION_SORT_LABEL')}>
            <SortSelector sortItems={sortItems} onChange={props.onSortChange} value={props.sortValue ?? sortItems[0].id} />
          </Form.Item>
        </Col>

        {/* Action */}
        <Col>
          <Row gutter={8}>
            <Col>
              <Button onClick={form.submit} data-test-id="timun-externalEducationApplicationFilter__submitButton">
                {props.translate('COMMON.ACTION_FILTER')}
              </Button>
            </Col>
            <Col>
              <Button onClick={handleResetFilterSubmit} data-test-id="timun-externalEducationApplicationFilter__resetButton">
                {props.translate('COMMON.ACTION_RESET_FILTER')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default withRoles(withLocalize<IEducationApplicationListFilterOwnProps>(EducationApplicationListFilter as any));

import React, { useCallback } from 'react';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { Form, Row, Col, Input, Button } from 'antd';
import EducationApplicationStatusSelector from '@src/components/externalEducationApplication/filter/common/EducationApplicationStatusSelector';
import ExternalEducationTemplatePicker from '@src/components/common/datapicker/ExternalEducationTemplatePicker';
import YesNoSelector from '@src/components/common/select/YesNoSelector';
import { IOrganizationEducationApplicationRefListFilter } from '@src/service/business/organization/OrganizationBusinessStore';
import { useUpdateEffect } from 'usehooks-ts';

// -- Prop types
// ----------

export interface IOrganizationEducationApplicationListFilterOwnProps {
  filter?: IOrganizationEducationApplicationRefListFilter;
  onSubmit?: (filter?: IOrganizationEducationApplicationRefListFilter) => void;
  organizationId?: string;
}
type IOrganizationEducationApplicationListFilterProps = IOrganizationEducationApplicationListFilterOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const OrganizationEducationApplicationListFilter: React.FC<IOrganizationEducationApplicationListFilterProps> = (props: IOrganizationEducationApplicationListFilterProps) => {
  const [form] = Form.useForm<IOrganizationEducationApplicationRefListFilter>();

  useUpdateEffect(() => {
    if (props.filter) {
      form.setFieldsValue(props.filter);
    } else {
      form.resetFields();
    }
  }, [props.filter]);

  const onFinish = useCallback((values: IOrganizationEducationApplicationRefListFilter) => props.onSubmit?.(values), [props.onSubmit]);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log(errorInfo);
  }, []);

  const handleResetFilterSubmit = useCallback(() => {
    props.onSubmit?.();
  }, [props.onSubmit, form.resetFields]);

  return (
    <Form<IOrganizationEducationApplicationRefListFilter> form={form} name="organizationEducationApplicationShortInfoListFilter" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={props.filter} layout="vertical">
      <Row className="full-width" gutter={[8, 8]} align="top">
        <Col xs={24} md={8} lg={8}>
          <Form.Item name={['education']} label={props.translate('ORGANIZATION_EDUCATION_APPLICATION.LIST_FILTER.EDUCATION_LABEL')}>
            <ExternalEducationTemplatePicker filter={{ educationProvider: { id: props.organizationId ?? '' } }} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={4}>
          <Form.Item name={['offerCode']} label={props.translate('ORGANIZATION_EDUCATION_APPLICATION.LIST_FILTER.OFFER_CODE_LABEL')}>
            <Input suffix={<LemonIcon name="search" />} placeholder={props.translate('COMMON.LABEL.INPUT_SEARCH_PLACEHOLDER')} allowClear={true} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={4}>
          <Form.Item name={['paymentRequested']} label={props.translate('ORGANIZATION_EDUCATION_APPLICATION.LIST_FILTER.PAYMENT_REQUESTED_LABEL')}>
            <YesNoSelector fieldName="paymentRequested" />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={4}>
          <Form.Item name={['phases']} label={props.translate('ORGANIZATION_EDUCATION_APPLICATION.LIST_FILTER.EDUCATION_APPLICATION_PHASE_LABEL')}>
            <EducationApplicationStatusSelector />
          </Form.Item>
        </Col>
      </Row>
      <Row className="timun-wrapper__gutterBox--vertical" gutter={[8, 8]}>
        <Col>
          <Button type="primary" onClick={form.submit}>
            {props.translate('COMMON.ACTION_FILTER')}
          </Button>
        </Col>
        <Col>
          <Button onClick={handleResetFilterSubmit} data-test-id="timun-externalEducationApplicationFilter__resetButton">
            {props.translate('COMMON.ACTION_RESET_FILTER')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize<IOrganizationEducationApplicationListFilterOwnProps>(OrganizationEducationApplicationListFilter as any);

import { Observable } from 'rxjs';
import { ICollectionData, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { IProgressTrackResult } from '@src/model/progresstracker/IProgressTrackResult';

// -
// -------------------- Types&Consts
export interface IProgressTrackResultFilter {
  id?: string;
}

// -
// -------------------- Selectors

/** Returns ProgressTracker result from store. */
const getProgressTrackResult = (store: any): IProgressTrackResult => store.progressTrackerResult;

// -
// -------------------- Actions
const Actions = {
  PROGRESS_TRACK_RESULT_FETCH: 'PROGRESS_TRACK_RESULT_FETCH',
  PROGRESS_TRACK_RESULT_LOAD: 'PROGRESS_TRACK_RESULT_LOAD',
  PROGRESS_TRACK_RESULT_CLEAR: 'PROGRESS_TRACK_RESULT_CLEAR',
};

/** Featch ProgressTracker by id */
const fetchProgressTrackResult = (id: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.PROGRESS_TRACK_RESULT_FETCH,
    payload: id,
  };
};

/** Load ProgressTrackResult to the store */
const loadProgressTrackerResult = (data: ICollectionData<IProgressTrackResult>): IPayloadAction<ICollectionData<IProgressTrackResult>> => {
  return {
    type: Actions.PROGRESS_TRACK_RESULT_LOAD,
    payload: data,
  };
};
/** Clear ProgressTrackResults list from store. Eg. when leaving view. */
const clearProgressTrackResult = (): ILemonAction => {
  return {
    type: Actions.PROGRESS_TRACK_RESULT_CLEAR,
  };
};

// -
// -------------------- Side-effects
const fetchProgressTrackResultEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PROGRESS_TRACK_RESULT_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;
      return EntityApiServiceRegistry.getService('ProgressTracker')
        .fetchSubobject(id, 'result')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadProgressTrackerResult(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'PROGRESS_TRACKER.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching deactivated user list', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers
const progressTrackerResult = (state: IProgressTrackResult | null = null, action: IPayloadAction<IProgressTrackResult>) => {
  if (action.type === Actions.PROGRESS_TRACK_RESULT_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.PROGRESS_TRACK_RESULT_CLEAR) return null;

  return state;
};

// --
// -------------------- Business Store
export const ProgressTrackerBusinessStore = {
  actions: {
    fetchProgressTrackResult,
    loadProgressTrackerResult,
    clearProgressTrackResult,
  },

  selectors: {
    getProgressTrackResult,
  },

  effects: {
    fetchProgressTrackResultEffect,
  },

  reducer: {
    progressTrackerResult,
  },
};

// --
// ----- Exports

export default ProgressTrackerBusinessStore;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import CacheListBusinessStore, { CacheEnum, ICacheListFilter } from '@src/service/business/system/CacheListBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Button, Col, Row } from 'antd';

// -- Prop types
// ----------

export interface IAdminCacheListContainerOwnProps {
}
export interface IAdminCacheListContainerStateProps {
  cacheList: CacheEnum[];
}
export interface IAdminCacheListContainerDispatchProps {
  fetchCacheList: (filter: ICacheListFilter) => void;
  evictAllCache: () => ITrackableAction;
  evictCache: (cache: CacheEnum) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}
type IAdminCacheListContainerProps = IAdminCacheListContainerOwnProps & IAdminCacheListContainerStateProps & IAdminCacheListContainerDispatchProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const AdminCacheListContainer = (props: IAdminCacheListContainerProps) => {
  useEffect(() => {
    props.fetchCacheList({});
  }, []);

  const handleCacheEvict = (cache: CacheEnum) => {
    props.evictCache(cache).track().subscribe(
      // success
      () => props.reportMessage({ message: props.translate('ADMIN_CACHE_LIST.CACHE_EVICT_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS })
    );
  };

  const handleAllCacheEvict = () => {
    props.evictAllCache().track().subscribe(
      // success
      () => props.reportMessage({ message: props.translate('ADMIN_CACHE_LIST.CACHE_EVICT_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS })
    );
  };


  return (
    <React.Fragment>
      <Row justify="end">
        <Col>
          <Button onClick={handleAllCacheEvict}> {props.translate('ADMIN_CACHE_LIST.CACHE_EVICT_ALL')} </Button>
        </Col>
      </Row>
      {props.cacheList?.map((cache: CacheEnum) => {
        return (
          <Row justify="space-between" className="panel" key={cache}>
            <Col>
              {props.translate(`ADMIN_CACHE_LIST.CACHE_TITLE.${cache.toUpperCase()}`)}
            </Col>
            <Col>
              <Button onClick={() => handleCacheEvict(cache)}> {props.translate('ADMIN_CACHE_LIST.CACHE_EVICT')} </Button>
            </Col>
          </Row>
        );
      }
      )}</React.Fragment>
  );
};


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdminCacheListContainerOwnProps): IAdminCacheListContainerStateProps => ({
  cacheList: CacheListBusinessStore.selectors.getCacheList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminCacheListContainerDispatchProps => ({
  fetchCacheList: (filter: ICacheListFilter) => dispatch(CacheListBusinessStore.actions.fetchCacheList(filter)),
  evictAllCache: () => createTrackableAction(dispatch(CacheListBusinessStore.actions.deletCacheList())),
  evictCache: (cache: CacheEnum) => createTrackableAction(dispatch(CacheListBusinessStore.actions.deleteCache(cache))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});


export default connect<IAdminCacheListContainerStateProps, IAdminCacheListContainerDispatchProps, IAdminCacheListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(AdminCacheListContainer as any));

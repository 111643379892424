import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseListView from '@src/components/course/list/CourseListView';
import { ICourse } from '@src/model/course/Course';
import { ICollectionData } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

const repoPageSize = AppConfigService.getValue('api.paging.midPageSize');

// -- Prop types
// ----------

export interface IRepositoryCoursesContainerOwnProps {
  currentTags: string[];
}

export interface IRepositoryCoursesContainerStateProps {
  courseList: ICollectionData<ICourse>;
  courseListFilter: ICourseListFilter;
}

export interface IRepositoryCoursesContainerDispatchProps {
  fetchCourseList: (filter: ICourseListFilter, page: number, size: number, sort: string[]) => any;
  clearCourseList: () => void;
  storeCourseListFilter: (filter: ICourseListFilter) => void;
  clearListFilter: () => void;
}
type IRepositoryCoursesContainerProps = IRepositoryCoursesContainerOwnProps & IRepositoryCoursesContainerStateProps & IRepositoryCoursesContainerDispatchProps & IWithLocalizeOwnProps;

interface IRepositoryCoursesContainerState {
  sort: string;
  page: number;
  size: number;
}

// -- Component
// ----------

/** Repository courses container component */
class RepositoryCoursesContainer extends React.Component<IRepositoryCoursesContainerProps, IRepositoryCoursesContainerState> {
  state: IRepositoryCoursesContainerState = {
    sort: '',
    page: 0,
    size: repoPageSize,
  };
  componentDidMount() {
    this.props.storeCourseListFilter({ tags: this.props.currentTags });
  }

  componentDidUpdate(prevProps: IRepositoryCoursesContainerProps, prevState: IRepositoryCoursesContainerState) {
    if (this.props.currentTags !== prevProps.currentTags) {
      this.props.storeCourseListFilter({ ...this.props.courseListFilter, tags: this.props.currentTags });
    }
    if (this.props.courseListFilter !== prevProps.courseListFilter || this.state !== prevState) {
      this.updateList();
    }
  }

  componentWillUnmount = () => {
    this.props.clearCourseList();
    this.props.clearListFilter();
  };

  render = () => {
    return (
      <div className="timun-repository__pagination">
        <CourseListView courseList={this.props.courseList} showFilter={false} getPath={CourseHelperUtils.getTraineePath} onPageChange={this.handlePageChange} />
      </div>
    );
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  private updateList(page: number = this.state.page, size: number = this.state.size, sort: string = this.state.sort) {
    this.props.fetchCourseList(this.props.courseListFilter, page, size, [sort]);
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IRepositoryCoursesContainerStateProps => ({
  courseList: CourseListBusinessStore.selectors.getCourseList(state),
  courseListFilter: CourseListBusinessStore.selectors.getCourseListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IRepositoryCoursesContainerDispatchProps => ({
  fetchCourseList: (filter: ICourseListFilter, page: number, size: number, sort: string[]) => dispatch(CourseListBusinessStore.actions.fetchCourseList({ filter, page, size, sort })),
  clearCourseList: () => dispatch(CourseListBusinessStore.actions.clearCourseList()),
  storeCourseListFilter: (filter: ICourseListFilter) => dispatch(CourseListBusinessStore.actions.storeCourseListFilter(filter)),
  clearListFilter: () => dispatch(CourseListBusinessStore.actions.clearCourseListFilter()),
});

export default connect<IRepositoryCoursesContainerStateProps, IRepositoryCoursesContainerDispatchProps, IRepositoryCoursesContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(RepositoryCoursesContainer as any));

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faArrowsAlt,
  faBars,
  faBell,
  faBirthdayCake,
  faBook,
  faBookReader,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarPlus,
  faCaretRight,
  faCertificate,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboard,
  faClipboardCheck,
  faClock,
  faCloud,
  faCoffee,
  faCog,
  faCoins,
  faComment,
  faCommentDots,
  faCompress,
  faCreditCard,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExpand,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFolder,
  faForward,
  faHighlighter,
  faHome,
  faImage,
  faInbox,
  faInfoCircle,
  faLayerGroup,
  faLemon,
  faLink,
  faLock,
  faMapMarkerAlt,
  faMicrophone,
  faMicrophoneSlash,
  faMinus,
  faMobileAlt,
  faMoneyBillWave,
  faMoneyCheck,
  faMouse,
  faPaperclip,
  faPaperPlane,
  faPause,
  faPen,
  faPencilAlt,
  faPhone,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPoll,
  faQuestionCircle,
  faRedo,
  faSave,
  faSchool,
  faSearch,
  faShieldAlt,
  faSignOutAlt,
  faSitemap,
  faSortDown,
  faSortUp,
  faSpellCheck,
  faSpinner,
  faStamp,
  faStar,
  faStop,
  faStopwatch,
  faSuitcase,
  faSync,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUnlock,
  faUser,
  faUserGraduate,
  faUserPlus,
  faUsers,
  faVideo,
  faVideoSlash,
  faVolumeDown,
  faVolumeMute,
  faVolumeOff,
  faVolumeUp,
  faLanguage,
  faCopy,
  faPodcast,
} from '@fortawesome/free-solid-svg-icons';
import { ILemonApplicationIconSet } from '@src/service/common/icon/LemonApplicationIconSet';

library.add(
  faCopy,
  faArrowsAlt,
  faCoffee,
  faBars,
  faBell,
  faBook,
  faBuilding,
  faCalendar,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faCircle,
  faClock,
  faTimes,
  faBriefcase,
  faTimesCircle,
  faCloud,
  faCoins,
  faCompress,
  faTrashAlt,
  faChevronDown,
  faDownload,
  faEdit,
  faEllipsisH,
  faSignOutAlt,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faMicrophone,
  faMicrophoneSlash,
  faFile,
  faFileAlt,
  faFolder,
  faHighlighter,
  faHome,
  faInbox,
  faInfoCircle,
  faLemon,
  faCalendarPlus,
  faChevronLeft,
  faChevronCircleLeft,
  faLock,
  faEnvelope,
  faCommentDots,
  faMinus,
  faMobileAlt,
  faMoneyCheck,
  faPaperclip,
  faPencilAlt,
  faAddressCard,
  faPaperPlane,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faChevronRight,
  faChevronCircleRight,
  faSave,
  faSearch,
  faExpand,
  faCog,
  faSync,
  faUnlock,
  faChevronUp,
  faUser,
  faVideo,
  faVideoSlash,
  faUsers,
  faUserPlus,
  faStar,
  faVolumeMute,
  faVolumeOff,
  faVolumeDown,
  faVolumeUp,
  faVideo,
  faComment,
  faPause,
  faStop,
  faChalkboardTeacher,
  faSchool,
  faMapMarkerAlt,
  faBookReader,
  faPoll,
  faClipboard,
  faCoins,
  faMouse,
  faExclamation,
  faSpinner,
  faTimes,
  faRedo,
  faSuitcase,
  faQuestionCircle,
  faImage,
  faForward,
  faStopwatch,
  faSpellCheck,
  faSortDown,
  faSortUp,
  faLayerGroup,
  faLink,
  faPhone,
  faMoneyBillWave,
  faCertificate,
  faStamp,
  faClipboardCheck,
  faSitemap,
  faCheckDouble,
  faPen,
  faShieldAlt,
  faBuilding,
  faCreditCard,
  faUserGraduate,
  faBirthdayCake,
  faLanguage,
  faPodcast
);

/** Lemon application fontawesome icon set. */
export const FontAwesomeIconSet: ILemonApplicationIconSet = {
  // ----- actions
  actionSend: 'paper-plane',
  menu: 'bars',
  bell: 'bell',
  birthday: 'birthday-cake',
  book: 'book',
  building: 'building',
  calendar: 'calendar',
  camera: 'video',
  cameraOff: 'video-slash',
  caretRight: 'caret-right',
  check: 'check',
  checkCircle: 'check-circle',
  clockCircle: 'clock',
  close: 'times',
  closeCircle: 'times-circle',
  cloud: 'cloud',
  coins: 'coins',
  avatarFallback: 'user',
  delete: 'times',
  down: 'chevron-down',
  collapse: 'compress',
  downCircle: 'chevron-circle-down',
  download: 'download',
  edit: 'pen',
  ellipsis: 'ellipsis-h',
  export: 'sign-out-alt',
  eye: 'eye',
  eyeInvisible: 'eye-slash',
  error: 'exclamation-circle',
  file: 'file',
  fileText: 'file-alt',
  fileUploadDropzone: 'inbox',
  folder: 'folder',
  highlight: 'highlighter',
  home: 'home',
  infoCircle: 'info-circle',
  sessionCreated: 'calendar-plus',
  language: 'language',
  left: 'chevron-left',
  lock: 'lock',
  mail: 'envelope',
  message: 'comment-dots',
  microphone: 'microphone',
  microphoneOff: 'microphone-slash',
  minus: 'minus',
  mobile: 'mobile-alt',
  moneyCheck: 'money-check',
  moneyBill: 'money-bill-wave',
  notificationIndicator: 'circle',
  organization: 'sitemap',
  pan: 'arrows-alt',
  paperClip: 'paperclip',
  pencil: 'pencil-alt',
  picLeft: 'address-card',
  playCircle: 'play-circle',
  plus: 'plus',
  plusCircle: 'plus-circle',
  right: 'chevron-right',
  rollback: 'undo',
  rate: 'lemon',
  save: 'save',
  search: 'search',
  star: 'star',
  expand: 'expand',
  setting: 'cog',
  sync: 'sync',
  unlock: 'unlock',
  up: 'chevron-up',
  upCircle: 'chevron-circle-up',
  user: 'user',
  videoCamera: 'video',
  userAdd: 'user-plus',
  users: 'users',
  volumeMute: 'volume-mute',
  volumeOff: 'volume-off',
  volumeDown: 'volume-down',
  volumeUp: 'volume-up',
  video: 'video',
  comment: 'comment',
  pause: 'pause',
  stop: 'stop',
  externalEducation: 'chalkboard-teacher',
  educationProvider: 'school',
  education: 'user-graduate',
  address: 'map-marker-alt',
  bookReader: 'book-reader',
  outcome: 'poll',
  clipboard: 'clipboard',
  activities: 'mouse',
  exclamation: 'exclamation',
  spinner: 'spinner',
  times: 'times',
  redo: 'redo',
  suitcase: 'suitcase',
  questionCircle: 'question-circle',
  image: 'image',
  forward: 'forward',
  deadlineDate: 'stopwatch',
  spellCheck: 'spell-check',
  expandDown: 'sort-down',
  expandUp: 'sort-up',
  level: 'layer-group',
  link: 'link',
  phone: 'phone',
  certificate: 'certificate',
  certificateName: 'stamp',
  enrollmentRequirement: 'clipboard-check',
  workposition: 'briefcase',
  verification: 'check-double',
  shield: 'shield-alt',
  iban: 'credit-card',
  bank: 'building',
  copy: 'copy',
  podcast: 'podcast',
};

import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Modal } from 'antd';
import { LegacyButtonType } from 'antd/lib/button/button';

const confirm = Modal.confirm;

export interface IConfirmationDialogProps {
  onConfirm: () => any;
  onCancel?: () => any;
  title?: string;
  okText?: string;
  okType?: LegacyButtonType;
  content?: string;
}

// 'COMMON.CONFIRMATION_MESSAGE'
/** Prompt user with custom (or default) message to confirm an action */
export const confirmationDialog = (props: IConfirmationDialogProps) => {
  confirm({
    content: props.content,
    title: props.title ?? LocalizeService.translate('COMMON.CONFIRMATION_TITLE'),
    okText: props.okText ?? LocalizeService.translate('COMMON.ACTION_YES'),
    okType: props.okType ?? 'danger',
    cancelText: LocalizeService.translate('COMMON.ACTION_CANCEL'),
    maskClosable: false,
    autoFocusButton: 'cancel',
    onOk: () => {
      props.onConfirm();
    },
    onCancel: () => {
      props.onCancel && props.onCancel();
    },
  });
};

/**
 * Helper methods for all UI related issues
 *
 */

import { Globals } from '@src/service/util/Globals';

export default class UserInterfaceUtils {
  // scroll to top of page
  static scrollToTop = () => Globals.window.scrollTo({ top: 0, behavior: 'smooth' });
}

import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationView from '@src/components/externalEducationApplication/view/ExternalEducationApplicationView';
import { IActivityParticipant, ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum, EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IEducationApplicationAnnex } from '@src/model/externalEducationApplication/EducationApplicationAnnex';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationFilePreviewData } from '@src/model/externalEducationApplication/ExternalEducationApplicationFilePreviewData';
import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IFile } from '@src/model/file/File';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import activityBusinessStore, { IActivityParticipantCreatePayload, IActivityPhaseCreatePayload } from '@src/service/business/examtemplates/activityBusinessStore';
import ExternalEducationApplicationBusinessStore from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import ExternalEducationApplicationTimelineListBusinessStore from '@src/service/business/externalEducationApplication/ExternalEducationApplicationTimelineListBusinessStore';
import { IFileListFilter } from '@src/service/business/files/FileListBusinessStore';
import { IFileListsByType } from '@src/service/business/files/util';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Modal, Row, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';

const AUTH_DEFAULT_ROUTE = AppConfigService.getValue('routing.authDefaultRoute');

// -- Prop types
// ----------

interface IExternalEducationApplicationContainerOwnProps {
  id: string;
}

interface IExternalEducationApplicationContainerStateProps {
  traineeUser: IUserInfo;
  currentUser: IUserInfo;
  externalEducationApplication: IExternalEducationApplication;
  externalEducationApplicationFileList: IFileListsByType;
  externalEducationTimeline: ICollectionData<IExternalEducationApplicationTimelineActivity>;
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}

interface IExternalEducationApplicationContainerDispatchProps {
  fetchTraineeUser: (id: string) => ITrackableAction;
  clearTraineeUser: () => void;
  fetchExternalEducationApplication: (id: string) => ITrackableAction;
  updateExternalEducationApplication: (data: IExternalEducationApplication) => ITrackableAction;
  clearExternalEducationApplication: () => void;
  fetchExternalEducationApplicationFileList: (listFilter?: IFileListFilter) => ITrackableAction;
  addExternalEducationApplicationFile: (id: string, data: IFile[]) => ITrackableAction;
  removeExternalEducationApplicationFile: (id: string, data: IFile[]) => ITrackableAction;
  clearExternalEducationApplicationFileList: (externalEducationApplicationId: string) => void;
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => ITrackableAction;
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  removeActivityParticipant: (activityId: string, participant: IActivityParticipant) => ITrackableAction;
  fetchExternalEducationTimeline: (id: string, sort: string[]) => void;
  clearExternalEducationTimeline: () => void;
  finishConsultation: (applicationId: string) => ITrackableAction;
  createAnnex: (externalEducationApplicationId: string, data: IEducationApplicationAnnex) => ITrackableAction;
  fetchFilePreview: (externalEducationApplicationId: string, filePreviewData: IExternalEducationApplicationFilePreviewData) => ITrackableAction;
}

interface IExternalEducationApplicationContainerState {
  traineeUser?: IUserInfo;
}

type IExternalEducationApplicationContainerProps = IExternalEducationApplicationContainerOwnProps & IExternalEducationApplicationContainerStateProps & IExternalEducationApplicationContainerDispatchProps & WithRouterProps;

// -- Component
// ----------

class ExternalEducationApplicationContainer extends React.Component<IExternalEducationApplicationContainerProps, IExternalEducationApplicationContainerState> {
  state: IExternalEducationApplicationContainerState = {};

  componentDidMount = () => {
    this.fetchExternalEducationApplicationData();
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationContainerProps, prevState: IExternalEducationApplicationContainerState) => {
    if (this.props.id !== prevProps.id) {
      this.fetchExternalEducationApplicationData();
    }

    if (this.props.externalEducationApplication !== prevProps.externalEducationApplication) {
      this.setTraineeUser();
    }
  };

  componentWillUnmount = () => {
    this.props.clearExternalEducationApplication();
    this.props.clearExternalEducationApplicationFileList(this.props.id);
    this.props.clearExternalEducationTimeline();
    this.props.clearTraineeUser();
  };

  render() {
    return this.props.externalEducationApplication ? (
      <ExternalEducationApplicationView
        onUpdateExternalEducationApplication={this.handleExternalEducationApplicationUpdate}
        onExternalEducationTemplateUpdate={this.handleExternalEducationTemplateUpdate}
        applicationFiles={this.props.externalEducationApplicationFileList}
        currentCoordinator={this.getCurrentCoordinator()}
        traineeUser={this.state.traineeUser}
        currentUser={this.props.currentUser}
        onSubmit={this.handleExternalEducationApplicationSubmit}
        onUpdateAndChangePhase={this.handleUpdateDataWithNextPhase}
        onChangePhase={this.handleNextPhase}
        externalEducationApplication={this.props.externalEducationApplication}
        onFileUpload={this.handleFileUpload}
        onFileRemove={this.handleFileRemove}
        onCoordinatorAdd={this.handleAddCoordinator}
        onChangeCoordinator={this.handleChangeCoordinator}
        timeline={this.props.externalEducationTimeline}
        onCreateNote={this.handleCreateNote}
        onFinishConsultation={this.handleFinishConsultation}
        termsOfAgreement={this.props.tenantOrganizationConfiguration.configuration?.displayTerms}
        onAnnexCreate={this.handleAnnexCreate}
        onCancel={this.handleCancel}
        onFilePreview={this.handleFilePreview}
        onExpenseSubmit={this.handleExpenseSubmit}
      />
    ) : null;
  }

  handleExpenseSubmit = (data: IExternalEducationExpense[]) => this.props.updateExternalEducationApplication({ ...this.props.externalEducationApplication, expenses: data });

  handleChangeCoordinator = (nextCoordinatorId: string) => {
    const currentCoordinator = this.getCurrentCoordinator();
    const nextCoordinator: IActivityParticipantCreatePayload = {
      userId: nextCoordinatorId,
      participantRole: ParticipantRoleEnum.EVALUATOR,
    };
    const activityId = this.props.externalEducationApplication.activity.id;

    if (currentCoordinator) {
      this.props
        .removeActivityParticipant(activityId, currentCoordinator)
        .track()
        .subscribe(() =>
          this.props
            .addActivityParticipant(activityId, nextCoordinator)
            .track()
            .subscribe(() => this.fetchExternalEducationApplicationData())
        );
    } else {
      this.props
        .addActivityParticipant(activityId, nextCoordinator)
        .track()
        .subscribe(() => this.fetchExternalEducationApplicationData());
    }
  };

  getCurrentCoordinator = () => {
    return this.props.externalEducationApplication && EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.EVALUATOR, this.props.externalEducationApplication.activity);
  };

  handleCreateNote = () => {
    this.fetchExternalEducationTimeline();
  };

  handleFileUpload = (data: IFile[]) => {
    this.props
      .addExternalEducationApplicationFile(this.props.id, data)
      .track()
      .subscribe((res) => {
        this.props.fetchExternalEducationApplicationFileList();
      });
  };

  handleFileRemove = (data: IFile[]) => {
    this.props
      .removeExternalEducationApplicationFile(this.props.id, data)
      .track()
      .subscribe(() => {
        this.props.fetchExternalEducationApplicationFileList();
        this.props.reportMessage({ message: LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.FILE.FILE_REMOVED_SUCCESS', { fileName: data[0].name }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
      });
  };

  handleExternalEducationApplicationSubmit = (data: IExternalEducationApplication) => {
    confirmationDialog({
      okType: 'primary',
      onConfirm: () =>
        this.props
          .updateExternalEducationApplication(data)
          .track()
          .subscribe((res) => {
            const isCurrentUserCoordinator = RoleUtils.isInRoles([UserRoleEnum.COORDINATOR], this.props.currentUser.roles);
            let nextPhaseId = ActivityPhaseEnum.SUBMITTED;
            if (EducationApplicationHelperUtils.isInPhases(['RETURNED_TO_USER'], this.props.externalEducationApplication.activity)) {
              nextPhaseId = ActivityPhaseEnum.AWAITING_APPROVAL;
            }
            if (isCurrentUserCoordinator) {
              nextPhaseId = ActivityPhaseEnum.EXTERNAL_EDUCATION_TEMPLATE_SELECTION;
            }

            this.props
              .updateActivityNextPhase(this.props.externalEducationApplication.activity.id, { phaseId: nextPhaseId })
              .track()
              .subscribe(
                // success
                () => {
                  Modal.success({
                    className: 'lemon-modal__modal--md',
                    title: LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.SUBMIT_SUCCESSFUL_TITLE'),
                    content: (
                      <React.Fragment>
                        <Row>
                          <Typography.Text>{LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.SUBMIT_SUCCESSFUL_MESSAGE')}</Typography.Text>{' '}
                        </Row>
                        <Row>
                          <Typography.Text type="secondary">{LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.SUBMIT_SUCCESSFUL_DESCRIPTION')}</Typography.Text>
                        </Row>
                      </React.Fragment>
                    ),
                  });
                  this.fetchExternalEducationApplicationData();
                }
              );
          }),
    });
  };

  handleNextPhase = (nextPhaseId: EducationApplicationActivityPhaseEnum) => {
    this.props
      .updateActivityNextPhase(this.props.externalEducationApplication.activity.id, { phaseId: nextPhaseId })
      .track()
      .subscribe(
        // success
        () => this.fetchExternalEducationApplicationData()
      );
  };

  handleUpdateDataWithNextPhase = (nextPhaseId: EducationApplicationActivityPhaseEnum, data: IExternalEducationApplication) => {
    this.props
      .updateExternalEducationApplication(data)
      .track()
      .subscribe((res) => {
        this.handleNextPhase(nextPhaseId);
      });
  };

  handleExternalEducationTemplateUpdate = (data: IExternalEducationTemplate) => {
    const updatedApplication = { ...this.props.externalEducationApplication, externalEducationTemplate: data };
    this.handleExternalEducationApplicationUpdate(updatedApplication);
  };

  handleExternalEducationApplicationUpdate = (data: IExternalEducationApplication) => {
    this.props
      .updateExternalEducationApplication(data)
      .track()
      .subscribe(
        // success
        () => {
          this.fetchExternalEducationApplicationData();
          this.props.reportMessage({ message: LocalizeService.translate('GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        }
      );
  };

  handleCancel = () => {
    this.props
      .updateActivityNextPhase(this.props.externalEducationApplication.activity.id, { phaseId: EducationApplicationActivityPhaseEnum.USER_CANCELED })
      .track()
      .subscribe(
        // success
        () => this.props.router.replace(AUTH_DEFAULT_ROUTE)
      );
  };

  handleAddCoordinator = (userId: string) => {
    const participant: IActivityParticipantCreatePayload = {
      userId,
      participantRole: ParticipantRoleEnum.EVALUATOR,
    };

    this.props
      .addActivityParticipant(this.props.externalEducationApplication.activity.id, participant)
      .track()
      .subscribe((res) => {
        this.fetchExternalEducationApplicationData();
      });
  };

  handleFinishConsultation = () => {
    this.props
      .finishConsultation(this.props.id)
      .track()
      .subscribe(
        // success
        () => {
          this.fetchExternalEducationApplicationData();
        }
      );
  };

  handleAnnexCreate = (data: IEducationApplicationAnnex) => {
    this.props
      .createAnnex(this.props.externalEducationApplication.id, data)
      .track()
      .subscribe(() => {
        this.fetchExternalEducationApplicationData();
      });
  };

  handleFilePreview = (externalEducationApplicationId: string, filePreviewData: IExternalEducationApplicationFilePreviewData) => {
    return this.props.fetchFilePreview(externalEducationApplicationId, filePreviewData);
  };

  private setTraineeUser = () => {
    const traineeParticipant = EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.ASSIGNEE, this.props.externalEducationApplication.activity);
    if (traineeParticipant) {
      if (traineeParticipant.userId !== this.props.currentUser.id) {
        this.props
          .fetchTraineeUser(traineeParticipant.userId)
          .track()
          .subscribe((res) => {
            this.setState({ traineeUser: res });
          });
      } else {
        this.setState({ traineeUser: this.props.currentUser });
      }
    }
  };

  private fetchExternalEducationTimeline = () => {
    const sort = ['createdAt,desc'];
    this.props.fetchExternalEducationTimeline(this.props.id, sort);
  };

  private fetchExternalEducationApplicationData = () => {
    this.props.fetchExternalEducationApplication(this.props.id);
    this.props.fetchExternalEducationApplicationFileList();
    this.fetchExternalEducationTimeline();
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationApplicationContainerOwnProps): IExternalEducationApplicationContainerStateProps => {
  return {
    traineeUser: UserBusinessStore.selectors.getUser(state),
    currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
    externalEducationApplication: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplication(state),
    externalEducationApplicationFileList: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplicationFileList(state, ownProps.id),
    externalEducationTimeline: ExternalEducationApplicationTimelineListBusinessStore.selectors.getExternalEducationApplicationTimeline(state),
    tenantOrganizationConfiguration: PublicTenantOrganizationConfigurationBusinessStore.selectors.getTenantOrganizationConfiguration(state),
  };
};

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch, ownProps: IExternalEducationApplicationContainerOwnProps): IExternalEducationApplicationContainerDispatchProps => ({
  fetchTraineeUser: (id: string) => createTrackableAction(dispatch(UserBusinessStore.actions.fetchUser({ id }))),
  clearTraineeUser: () => dispatch(UserBusinessStore.actions.clearUser()),
  fetchExternalEducationApplication: (id: string) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.fetchExternalEducationApplication({ id }))),
  updateExternalEducationApplication: (data: IExternalEducationApplication) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.updateExternalEducationApplication(data))),
  clearExternalEducationApplication: () => dispatch(ExternalEducationApplicationBusinessStore.actions.clearExternalEducationApplication()),
  fetchExternalEducationApplicationFileList: (listFilter?: IFileListFilter) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.fetchExternalEducationApplicationFileList(ownProps.id, listFilter))),
  addExternalEducationApplicationFile: (id: string, data: IFile[]) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.addExternalEducationApplicationFile(id, data))),
  removeExternalEducationApplicationFile: (id: string, data: IFile[]) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.removeExternalEducationApplicationFile(id, data))),
  clearExternalEducationApplicationFileList: (externalEducationApplicationId: string) => dispatch(ExternalEducationApplicationBusinessStore.actions.clearExternalEducationApplicationFileList({ id: externalEducationApplicationId })),
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityPhase(id, data))),
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityParticipant(id, data))),
  removeActivityParticipant: (activityId: string, participant: IActivityParticipant) => createTrackableAction(dispatch(activityBusinessStore.actions.removeActivityParticipant(activityId, participant))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchExternalEducationTimeline: (id: string, sort: string[]) => dispatch(ExternalEducationApplicationTimelineListBusinessStore.actions.fetchExternalEducationApplicationTimelineList(id, undefined, undefined, sort)),
  clearExternalEducationTimeline: () => dispatch(ExternalEducationApplicationTimelineListBusinessStore.actions.clearExternalEducationApplicationTimelineList()),
  finishConsultation: (externalEducationApplicationId: string) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.finishConsultation({ id: externalEducationApplicationId }))),
  createAnnex: (externalEducationApplicationId: string, data: IEducationApplicationAnnex) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.createExternalEducationApplicatonAnnex(externalEducationApplicationId, data))),
  fetchFilePreview: (id: string, filePreviewData: IExternalEducationApplicationFilePreviewData) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.fetchExternalEducationApplicatonFilePreview(id, filePreviewData))),
});

export default connect<IExternalEducationApplicationContainerStateProps, IExternalEducationApplicationContainerDispatchProps, IExternalEducationApplicationContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRouter(ExternalEducationApplicationContainer as any));

import React from 'react';

import CityDataPicker from '@src/components/common/datapicker/externalEducationTemplate/CityDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import { Col, Form, Row } from 'antd';

// -- Prop types
// ----------
// -- Prop types
// ----------

export interface IExternalEducationTemplateDataFilterOwnProps {
  filter?: IExternalEducationTemplateListFilter;
  onFilterUpdate?: (partialFilter?: Partial<IExternalEducationTemplateListFilter>) => void;
}
type IExternalEducationTemplateDataFilterProps = IExternalEducationTemplateDataFilterOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display external education template data table picker filter */
const ExternalEducationTemplateDataFilter: React.FC<IExternalEducationTemplateDataFilterProps> = (props: IExternalEducationTemplateDataFilterProps) => {
  const [form] = Form.useForm<IExternalEducationTemplateListFilter>();
  return (
    <Form<IExternalEducationTemplateListFilter>
      component={false}
      form={form}
      onValuesChange={props.onFilterUpdate}
      initialValues={props.filter}
      layout="vertical"
      name="externalEducationTemplateListFilter">
      <Row>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE_DATA.FILTER.CITY_LABEL')} name="city">
            <CityDataPicker />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize<IExternalEducationTemplateDataFilterOwnProps>(ExternalEducationTemplateDataFilter as any);



import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import AdminUserManagementListFilter from '@src/components/user/filter/AdminUserManagementListFilter';
import UserManagementListView from '@src/components/user/view/UserManagementListView';
import { IUserInfo } from '@src/model/user/User';
import { IUserRole } from '@src/model/user/UserRole';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import TenantManager from '@src/service/business/tenant/TenantManager';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Tag } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const VIEW_NAME = '@@USER_MANAGMENT_LIST_CONTAINER';

// TODO: luoyuma specific code to hide oib fields, remove once/if ever handled differently
const tenant = TenantManager.getTenantCode();
{
  /* TODO: isLuyouma burn with fire */
}
const isLuyouma = tenant === 'luyouma' || tenant === 'Luyouma';

// -- Prop types
// ----------
export interface IAdminUserManagementListContainerOwnProps {}

export interface IAdminUserManagementListContainerStateProps {
  userListFilter: IUserListFilter;
  userList: ICollectionData<IUserInfo>;
  workPositionList: IWorkPosition[];
}

export interface IAdminUserManagementListContainerDispatchProps {
  createUser: (data: IUserInfo) => ITrackableAction;
  updateUser: (data: IUserInfo) => ITrackableAction;
  clearUserList: () => void;
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => void;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type IAdminUserManagementListContainerProps = IAdminUserManagementListContainerOwnProps & IAdminUserManagementListContainerStateProps & IAdminUserManagementListContainerDispatchProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------
/** Admin user management list container */
const AdminUserManagementListContainer: React.FC<IAdminUserManagementListContainerProps> = (props: IAdminUserManagementListContainerProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const initialCollectionValues = useMemo(() => ({ page: 0, size: AppConfigService.getValue('api.paging.defaultPageSize'), sort: [] }), []);
  const [, updateCollectionParams, onUpdateList] = useCollectionState<IUserListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchUserList,
    initialValues: initialCollectionValues,
  });

  const handleUserSubmit = useCallback(
    (data: IUserInfo) => {
      if (data.id) {
        return props
          .updateUser(data)
          .track()
          .subscribe(() => props.reportMessage({ message: props.translate('GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS }));
      } else {
        return props
          .createUser(data)
          .track()
          .subscribe(
            () => {
              // success
              onUpdateList();
              props.reportMessage({ message: props.translate('USER.CREATE_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
              setIsEditing(false);
            },
            () => {
              console.error('Error creating new user');
            }
          );
      }
    },
    [props.reportMessage, props.updateUser]
  );

  const additionalCols = useCallback((): ITimunDataTableCol<IUserInfo>[] => {
    const columns: ITimunDataTableCol<IUserInfo>[] = [
      {
        key: 'userRole',
        contentType: 'string',
        headerTitle: props.translate('USER_MANAGEMENT_LIST_VIEW.USER_ROLES_TITLE'),
        content: (record: IUserInfo) =>
          record.roles.map((role: IUserRole) => (
            <p key={role.id}>
              <Tag>{props.translate(`USER_MANAGEMENT_LIST_VIEW.USER_ROLE_${role.name}`)}</Tag>
            </p>
          )),
      },
    ];

    if (!isLuyouma) {
      columns.push({
        key: 'userOib',
        contentType: 'string',
        headerTitle: props.translate('USER_MANAGEMENT_LIST_VIEW.USER_IDENTIFICATION_NUMBER_TITLE'),
        content: (record: IUserInfo) => record.identificationNumber,
      });
    }
    return columns;
  }, [isLuyouma]);

  return (
    <React.Fragment>
      {/* Filter */}
      <AdminUserManagementListFilter showUserOibFilter={true} showUserActivityStatusFilter={true} showUserRoleFilter={true} onFilterSubmit={updateCollectionParams.onFilterSet} userListFilter={props.userListFilter} workPositionList={props.workPositionList} />
      {/* List */}
      {props.userList && <UserManagementListView isEditing={isEditing} setUserEdit={setIsEditing} canCreateUser={true} onUserSubmit={handleUserSubmit} workPositionList={props.workPositionList} onPageChange={updateCollectionParams.onPageChange} userList={props.userList} additionalCols={additionalCols} />}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAdminUserManagementListContainerStateProps => ({
  userListFilter: UserListBusinessStore.selectors.getUserListFilter(state),
  userList: UserListBusinessStore.selectors.getUserList(state),
  workPositionList: CollectionBusinessStore.selectors.getCollectionContent(state, 'WorkPosition'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminUserManagementListContainerDispatchProps => ({
  createUser: (data: IUserInfo) => dispatch(createTrackableAction(UserBusinessStore.actions.createUser(data))),
  updateUser: (data: IUserInfo) => dispatch(createTrackableAction(UserBusinessStore.actions.updateUser(data))),
  clearUserList: () => dispatch(UserListBusinessStore.actions.clearUserList()),
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => dispatch(UserListBusinessStore.actions.fetchUserList({ ...params })),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<IAdminUserManagementListContainerStateProps, IAdminUserManagementListContainerDispatchProps, IAdminUserManagementListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize<IAdminUserManagementListContainerOwnProps>(withTenantPropEnabled(AdminUserManagementListContainer) as any));

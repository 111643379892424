import IIdRef from '@src/model/common/IdRef';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ILecture, ILectureWithContent } from '@src/model/course/Lecture';
import { ICourseLectureGroup, ICourseLectureListElement } from '@src/model/course/LectureGroup';
import { IFile } from '@src/model/file/File';
import { ISkill } from '@src/model/skillgroup/Skill';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import courseViewBusinessStore, { ICourseLectureListFilter } from '@src/service/business/courses/courseViewBusinessStore';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

export interface ICourseCreatePayload {
  title: string;
  courseGroups: ICourseGroup[];
  content?: ICourseContentCreatePayload;
  duration?: number;
}

export interface ICourseContentCreatePayload {
  body?: string;
}

export interface ILectureGroupCreatePayload {
  course: IIdRef<string>;
  title: string;
}

export interface ILectureCreatePayload {
  title: string;
  lectureGroup: IIdRef<string>;
}

export type ICourseUpdate = ReplaceBy<ICourse, 'status', IIdRef<string>>;

// -
// -------------------- Selectors

/** Returns course from store. */
const getCourseContent = (store: any): ICourse => store.courseData;

/** Returns lecture from store. */
const getLectureContent = (store: any): ILectureWithContent => store.courseLecture;

/** Returns list of course lectures from store. */
const getCourseLectureList = (store: any): ICourseLectureListElement[] => store.courseLectureList;

const getLectureFileList = (store: any): ICollectionData<IFile> => store.lectureFileList;

// -
// -------------------- Actions

const Actions = {
  COURSE_CREATE: 'COURSE_CREATE',
  COURSE_UPDATE: 'COURSE_UPDATE',
  COURSE_DELETE: 'COURSE_DELETE',
  COURSE_COPY: 'COURSE_COPY',
  COURSE_COVER_IMAGE_UPLOAD: 'COURSE_COVER_IMAGE_UPLOAD',
  COURSE_SKILL_UPDATE: 'COURSE_SKILL_UPDATE',
  LECTURE_GROUP_CREATE: 'LECTURE_GROUP_CREATE',
  LECTURE_GROUP_UPDATE: 'LECTURE_GROUP_UPDATE',
  LECTURE_GROUP_DELETE: 'LECTURE_GROUP_DELETE',
  LECTURE_CREATE: 'LECTURE_CREATE',
  LECTURE_UPDATE: 'LECTURE_UPDATE',
  LECTURE_DELETE: 'LECTURE_DELETE',
  LECTURE_VIDEO_UPLOAD: 'LECTURE_VIDEO_UPLOAD',
  LECTURE_VIDEO_REMOVE: 'LECTURE_VIDEO_REMOVE',
  LECTURE_FILE_LIST_ADD_FILES: 'LECTURE_FILE_LIST_ADD_FILES',
  LECTURE_FILE_LIST_REMOVE_FILES: 'LECTURE_FILE_LIST_REMOVE_FILES',
};

/** Fetch course by ID. */
const fetchCourseContent = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return courseViewBusinessStore.actions.fetchCourseContent(params);
};

/** Load course to store. */
const loadCourseContent = (data: ICourse): IPayloadAction<ICourse> => {
  return courseViewBusinessStore.actions.loadCourseContent(data);
};

/** Clear course from store. Eg. when leaving view. */
const clearCourseContentData = (): ILemonAction => {
  return courseViewBusinessStore.actions.clearCourseContentData();
};

/** Fetch course lecture list by filter. */
const fetchCourseLectureList = (id: string, listFilter: ICourseLectureListFilter): IPayloadAction<IIdDataPayload<ICourseLectureListFilter>> => {
  return courseViewBusinessStore.actions.fetchCourseLectureList(id, listFilter);
};

/** Load course lecture list to store. */
const loadCourseLectureList = (data: ICollectionData<ICourse>): IPayloadAction<ICollectionData<ICourse>> => {
  return courseViewBusinessStore.actions.loadCourseLectureList(data);
};

/** Clear course lecture list from store. Eg. when leaving list view. */
const clearCourseLectureList = (): ILemonAction => {
  return courseViewBusinessStore.actions.clearCourseLectureList();
};

/** Fetch lecture by ID. */
const fetchLectureContent = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return courseViewBusinessStore.actions.fetchLectureContent(params);
};

/** Load lecture to store. */
const loadLectureContent = (data: ILectureWithContent): IPayloadAction<ILectureWithContent> => {
  return courseViewBusinessStore.actions.loadLectureContent(data);
};

/** Clear lecture from store. Eg. when leaving view. */
const clearLectureContentData = (): ILemonAction => {
  return courseViewBusinessStore.actions.clearLectureContentData();
};

/** Fetch lecture files by lecture ID. */
const fetchLectureFileList = (lectureId: string): IPayloadAction<IIdPayload> => {
  return courseViewBusinessStore.actions.fetchLectureFileList(lectureId);
};

/** Load lecture files to store. */
const loadLectureFileList = (data: ICollectionData<IFile>): IPayloadAction<ICollectionData<IFile>> => {
  return courseViewBusinessStore.actions.loadLectureFileList(data);
};

/** Clear lecture files from store. Eg. when leaving view. */
const clearLectureFileList = (): ILemonAction => {
  return courseViewBusinessStore.actions.clearLectureFileList();
};

/** Create new course. */
const createCourse = (data: ICourseCreatePayload): IPayloadAction<ICourseCreatePayload> => {
  return {
    type: Actions.COURSE_CREATE,
    payload: data,
  };
};

/** Update course by ID. */
const updateCourse = (data: ICourseUpdate): IPayloadAction<ICourseUpdate> => {
  return {
    type: Actions.COURSE_UPDATE,
    payload: data,
  };
};

/** Delete course by ID. */
const deleteCourse = (data: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.COURSE_DELETE,
    payload: data,
  };
};

/** Change cover image in a course */
const uploadCourseCover = (id: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.COURSE_COVER_IMAGE_UPLOAD,
    payload: {
      id,
      data,
    },
  };
};

/** Add course skill */
const updateCourseSkillList = (courseId: string, data: ISkill[]): IPayloadAction<IIdDataPayload<ISkill[]>> => {
  return {
    type: Actions.COURSE_SKILL_UPDATE,
    payload: {
      id: courseId,
      data,
    },
  };
};

/** Create new lecture. */
const createLectureGroup = (data: ILectureGroupCreatePayload): IPayloadAction<ILectureGroupCreatePayload> => {
  return {
    type: Actions.LECTURE_GROUP_CREATE,
    payload: data,
  };
};

/** Update lecture by ID. */
const updateLectureGroup = (data: ICourseLectureGroup): IPayloadAction<ICourseLectureGroup> => {
  return {
    type: Actions.LECTURE_GROUP_UPDATE,
    payload: data,
  };
};

/** Delete lecture by ID. */
const deleteLectureGroup = (data: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.LECTURE_GROUP_DELETE,
    payload: data,
  };
};

/** Create new lecture. */
const createLecture = (data: ILectureCreatePayload): IPayloadAction<ILectureCreatePayload> => {
  return {
    type: Actions.LECTURE_CREATE,
    payload: data,
  };
};

/** Update lecture by ID. */
const updateLecture = (data: ILecture): IPayloadAction<ILecture> => {
  return {
    type: Actions.LECTURE_UPDATE,
    payload: data,
  };
};

/** Delete lecture by ID. */
const deleteLecture = (data: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.LECTURE_DELETE,
    payload: data,
  };
};

const addLectureFile = (lectureId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.LECTURE_FILE_LIST_ADD_FILES,
    payload: {
      id: lectureId,
      data,
    },
  };
};

const removeLectureFile = (lectureId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.LECTURE_FILE_LIST_REMOVE_FILES,
    payload: {
      id: lectureId,
      data,
    },
  };
};

/** Change lecture cover video */
const uploadLectureVideo = (id: string, data: IIdRef<string>): IPayloadAction<IIdDataPayload<IIdRef<string>>> => {
  return {
    type: Actions.LECTURE_VIDEO_UPLOAD,
    payload: {
      id,
      data,
    },
  };
};

/** Remove lecture cover video */
const removeLectureVideo = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.LECTURE_VIDEO_REMOVE,
    payload: {
      id,
    },
  };
};

const copyCourseContent = (id: string): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.COURSE_COPY,
    payload: { id },
  };
};

// -
// -------------------- Side-effects

const createCourseEffect = (action$: Observable<IPayloadAction<ICourseCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseContent(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    // tslint:disable-next-line: no-duplicate-string
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating course', error);
      return o;
    })
  );
};

const updateCourseEffect = (action$: Observable<IPayloadAction<ICourseUpdate>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .updateEntity(data.id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseContent(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating course', error);
      return o;
    })
  );
};

const deleteCourseEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .deleteEntity(data.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((res) => {
      return { message: LocalizeService.translate('COURSE_VIEW.COURSE_DELETED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO };
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting course', error);
      return o;
    })
  );
};

const uploadCourseCoverEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_COVER_IMAGE_UPLOAD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .createSubobject(id, 'cover', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error uploading cover image', error);
      return o;
    })
  );
};

const updateCourseSkillEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ISkill[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_SKILL_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .updateSubentityList(id, 'skill', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating course skill list', error);
      return o;
    })
  );
};

const createLectureGroupEffect = (action$: Observable<IPayloadAction<ILectureCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_GROUP_CREATE;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('LectureGroup')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_GROUP_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating lecture group', error);
      return o;
    })
  );
};

const updateLectureGroupEffect = (action$: Observable<IPayloadAction<ILecture>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_GROUP_UPDATE;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('LectureGroup')
        .updateEntity(data.id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating lecture group', error);
      return o;
    })
  );
};

const deleteLectureGroupEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_GROUP_DELETE;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('LectureGroup')
        .deleteEntity(data.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting lecture group', error);
      return o;
    })
  );
};

const createLectureEffect = (action$: Observable<IPayloadAction<ILectureCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_CREATE;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Lecture')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadLectureContent(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating lecture', error);
      return o;
    })
  );
};

const updateLectureEffect = (action$: Observable<IPayloadAction<ILecture>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_UPDATE;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Lecture')
        .updateEntity(data.id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadLectureContent(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating lecture', error);
      return o;
    })
  );
};

const deleteLectureEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_DELETE;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Lecture')
        .deleteEntity(data.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting lecture', error);
      return o;
    })
  );
};

const addLectureFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_FILE_LIST_ADD_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Lecture')
        .createSubentityList(id, 'File', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, '', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error adding file to lecture', error);
      return o;
    })
  );
};

const removeLectureFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_FILE_LIST_REMOVE_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Lecture')
        .deleteSubentityList(id, 'File', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error removing file from lecture', error);
      return o;
    })
  );
};

const uploadLectureVideoEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IIdRef<string>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_VIDEO_UPLOAD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Lecture')
        .createSubobject(id, 'video', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error removing lecture video', error);
      return o;
    })
  );
};

const removeLectureVideoEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IIdPayload>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_VIDEO_REMOVE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Lecture')
        .deleteSubobject(id, 'video', {})
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LECTURE_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error uploading lecture video', error);
      return o;
    })
  );
};

const copyCourseContentEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_COPY;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .updateSubobject(id, 'copy', {})
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE_UPDATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error course update', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

// --
// -------------------- Business Store

export const courseUpdateBusinessStore = {
  actions: {
    fetchCourseContent,
    loadCourseContent,
    clearCourseContentData,
    fetchCourseLectureList,
    loadCourseLectureList,
    clearCourseLectureList,
    fetchLectureContent,
    loadLectureContent,
    clearLectureContentData,
    createCourse,
    updateCourse,
    deleteCourse,
    uploadCourseCover,
    createLectureGroup,
    updateLectureGroup,
    deleteLectureGroup,
    createLecture,
    updateLecture,
    deleteLecture,
    fetchLectureFileList,
    loadLectureFileList,
    clearLectureFileList,
    addLectureFile,
    removeLectureFile,
    uploadLectureVideo,
    removeLectureVideo,
    copyCourseContent,
    updateCourseSkillList,
  },

  selectors: {
    getCourseContent,
    getCourseLectureList,
    getLectureContent,
    getLectureFileList,
  },

  effects: {
    createCourseEffect,
    updateCourseEffect,
    deleteCourseEffect,
    uploadCourseCoverEffect,
    createLectureEffect,
    updateLectureEffect,
    deleteLectureEffect,
    createLectureGroupEffect,
    updateLectureGroupEffect,
    deleteLectureGroupEffect,
    addLectureFileEffect,
    removeLectureFileEffect,
    uploadLectureVideoEffect,
    removeLectureVideoEffect,
    copyCourseContentEffect,
    updateCourseSkillEffect,
  },

  reducers: {},
};

// --
// export business store
export default courseUpdateBusinessStore;

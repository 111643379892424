import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseCardList from '@src/components/course/list/CourseCardList';
import { ICourse } from '@src/model/course/Course';
import React from 'react';

// -- Prop types
// ----------

interface IAdditionalCourseListViewOwnProps {
  courseList: ICourse[];
  getPath: (course: ICourse) => string;
}
type IAdditionalCourseListViewProps = IAdditionalCourseListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** */
class AdditionalCourseListView extends React.Component<IAdditionalCourseListViewProps> {
  render = () => {
    return <div className="panel">
      <AppContent title={this.props.translate('COURSE_VIEW.VIEW.ADDITIONAL_COURSES_TITLE')}>
        <CourseCardList courses={this.props.courseList} getPath={this.props.getPath} />
      </AppContent>
    </div>;
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IAdditionalCourseListViewOwnProps>(AdditionalCourseListView as any);

import React, { useCallback } from 'react';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationInfoView from '@src/components/externalEducationApplication/view/ExternalEducationApplicationInfoView';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { IUserInfo } from '@src/model/user/User';
import { IUserDetails } from '@src/model/user/UserDetails';
import { IFileListsByType } from '@src/service/business/files/util';
import { Button, Col, Row } from 'antd';

// -- Prop types
// ----------

export interface IExternalEducationApplicationConsultationCoordinatorViewOwnProps {
  currentUser: IUserDetails;
  timeline: IExternalEducationApplicationTimelineActivity[];
  externalEducationApplication: IExternalEducationApplication;
  traineeUser?: IUserInfo;
  isCurrentUserCoordinator?: boolean;
  isCurrentUserTrainee?: boolean;
  applicationFiles: IFileListsByType;
  canParticipate?: boolean;
  onPhaseChange?: (phase: EducationApplicationActivityPhaseEnum) => void;
  onCoordinatorAdd?: () => void;
  onCreateNote?: () => void;
}
type IExternalEducationApplicationConsultationCoordinatorViewProps = IExternalEducationApplicationConsultationCoordinatorViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationApplicationConsultationCoordinatorView: React.FC<IExternalEducationApplicationConsultationCoordinatorViewProps> = (props: IExternalEducationApplicationConsultationCoordinatorViewProps) => {
  const handleConsultationTake = useCallback(() => props.onCoordinatorAdd?.(), []);
  const handleConsultationDone = useCallback(() => props.onPhaseChange?.(EducationApplicationActivityPhaseEnum.EXTERNAL_EDUCATION_APPLICATION_CREATION), []);
  const isApplicationInPhases = useCallback((phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => EducationApplicationHelperUtils.isInPhases(phases, props.externalEducationApplication.activity), [props.externalEducationApplication.activity, props.timeline]);

  return (
    <AppContent>
      <Row justify="start">
        {isApplicationInPhases(['CONSULTATION_REQUESTED']) && (
          <Col>
            <Button type="primary" onClick={handleConsultationTake}>
              {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_CONSULTATION.TAKE_CONSULTATION_BUTTON_LABEL')}
            </Button>
          </Col>
        )}
        {isApplicationInPhases(['CONSULTATION_STARTED']) && (
          <Col>
            <NoteCreateContainer
              disabled={!props.canParticipate}
              objectId={props.externalEducationApplication.id}
              objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
              objectPublic={true}
              buttonLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_CONSULTATION.MARK_AS_DONE_CONSULTATION')}
              buttonType="primary"
              modalTitle={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_CONSULTATION.NOTE_MODAL_TITLE')}
              onCreateNote={handleConsultationDone}
              hideIcon={true}
            />
          </Col>
        )}
      </Row>

      <ExternalEducationApplicationInfoView externalEducationApplication={props.externalEducationApplication} isCurrentUserCoordinator={true} timeline={props.timeline} traineeUser={props.traineeUser} applicationFiles={props.applicationFiles} panelsActive={true} onCreateNote={props.onCreateNote} />
    </AppContent>
  );
};

export default withLocalize<IExternalEducationApplicationConsultationCoordinatorViewOwnProps>(ExternalEducationApplicationConsultationCoordinatorView as any);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationReportListFilterForm from '@src/components/externalEducationApplication/filter/EducationApplicationReportListFilterForm';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { Form, Modal } from 'antd';
import React from 'react';

// -- Const
// ----------


// -- Prop types
// ----------
export interface IEducationApplicationReportListFilterModalOwnProps {
  onCancel: () => void;
  filter?: IExternalEducationApplicationListFilter;
  onSubmit: (listFilter: IExternalEducationApplicationListFilter) => void;
  okText?: string;
}

type IEducationApplicationReportListFilterModalProps = IEducationApplicationReportListFilterModalOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
const EducationApplicationReportListFilterModal = (props: IEducationApplicationReportListFilterModalProps) => {
  const [form] = Form.useForm<IExternalEducationApplicationListFilter>();

  return (
    <Modal
      className="lemon-modal__modal--md"
      title={props.translate('COMMON.LABEL.FILTER')}
      visible={true}
      onCancel={props.onCancel}
      okText={props.okText ?? props.translate('COMMON.ACTION_FILTER')}
      onOk={form.submit}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}
      maskClosable={false}
    >
      <EducationApplicationReportListFilterForm value={props.filter} onSubmit={props.onSubmit} formRef={form} />
    </Modal>
  );
};

export default withLocalize<IEducationApplicationReportListFilterModalOwnProps>(EducationApplicationReportListFilterModal as any);

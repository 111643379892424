import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import ListFilterHelper, { ListFilterQuery } from '@src/service/util/filter/ListFilterHelper';

/** Transform ExternalEducationApplication list filter to API filter */
export function transformExternalEducationApplicationListFilter(filter: IExternalEducationApplicationListFilter): ListFilterQuery {
  return ListFilterHelper.simpleFilterToQueryMapper(filter, (filterQuery, name, filterValue) => {
    // process "participant", "assingee", "evaluator", "education", "skill", "educationProvider", "coordinator", "districtOffice", "educationCategory"

    if (name === 'participant' || name === 'excludedEvaluator' || name === 'assignee' || name === 'evaluator' || name === 'education' || name === 'skill' || name === 'educationProvider' || name === 'districtOffice' || name === 'educationCategory' || name === 'county' || name === 'userCategory') {
      const value = filterValue[name]; // take filter value for current property

      if (value != null /* empty values should not be added to query */) {
        filterQuery[name] = value.id;
      }
      return true; // signal mapper that this prop has been manually processed
    }

    return false; // signal mapper that this prop has NOT been processed and mapper should apply default mapping
  });
}

import CodedGradeContainer from '@src/components/codebook/CodedGradeContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { Empty } from 'antd';
import React from 'react';

interface IAdminGradeCodebookPageOwnProps { }

type IAdminGradeCodebookPageProps = IWithRolesOwnProps & IWithTenantPropEnabledOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const AdminGradeCodebookPage = (props: IAdminGradeCodebookPageProps) => {
  const DisplayComponent = (props.isSuperAdmin() || props.isTenantPropEnabled('codebook_grade')) ? <CodedGradeContainer /> : <Empty description={props.translate('COMMON.LABEL.NOT_ALLOWED_TO_ACCESS')} />;
  return (
    <React.Fragment>
      {DisplayComponent}
    </React.Fragment>
  );
};

export default withTenantPropEnabled<IAdminGradeCodebookPageOwnProps>(withLocalize(withRoles(AdminGradeCodebookPage as any)));

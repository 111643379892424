import { Modal, Spin } from 'antd';
import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { IFile } from '@src/model/file/File';

import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonVideo from '@src/components/common/image/LemonVideo';
import FileUpload, { IUploadedFile, IUploadStatus } from '@src/components/common/upload/FileUpload';
import AppConfigService from '@src/service/common/AppConfigService';

const ALLOWED_FILE_TYPES: string[] = AppConfigService.getValue('api.upload.allowedVideoTypes');
const ALLOWED_MAX_SIZE: number = AppConfigService.getValue('api.upload.maxFileSize');

// -- Prop types
// ----------

export interface IVideoUploadFormOwnProps {
  defaultFileUrl?: string;

  onSubmit: (file: IFile) => void;
  onClose: () => void;
}
type IVideoUploadFormProps = IVideoUploadFormOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

interface IVideoUploadFormState {
  newFile?: IFile;
}

// -- Component
// ----------

/** Component with a modal form for uploading new video file. */
class VideoUploadForm extends React.Component<IVideoUploadFormProps, IVideoUploadFormState> {
  state: IVideoUploadFormState = {
    newFile: undefined,
  };

  checkFile = (file: IUploadedFile) => {
    return FileUtils.checkFileValidity(file, ALLOWED_FILE_TYPES, ALLOWED_MAX_SIZE);
  };

  handleUploadFile = (data: IFile) => {
    this.setState({
      newFile: data,
    });
  };

  handleSubmit = () => {
    if (this.state.newFile != null) {
      this.props.onSubmit(this.state.newFile);
    }

    this.handleClose();
  };

  handleClose = () => {
    // remove newFile when modal is closing, so it doen't remeber uploaded file upon next modal open
    this.setState({
      newFile: undefined,
    });

    this.props.onClose();
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          maskClosable={false}
          title={this.props.translate('FILE_UPLOAD.MODAL_TITLE')}
          className="timun-fileUploader__dialog"
          visible={true}
          onOk={this.handleSubmit}
          onCancel={this.handleClose}
          okText={this.props.translate('COMMON.ACTION_SAVE')}
          cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
          okButtonProps={{ disabled: !this.state.newFile }}
        >
          <div className="timun-fileUploader__previewPlaceholder">
            <FileUpload onUploadFile={this.handleUploadFile} dragger={true} uploadProps={{ multiple: false, beforeUpload: this.checkFile }}>
              {(status: IUploadStatus) => (
                <React.Fragment>
                  {status.isUploading && <Spin />}&nbsp;
                  {this.props.defaultFileUrl || this.state.newFile ? <LemonVideo src={this.state.newFile ? this.props.resolveApiFilePath(this.state.newFile.id) : this.props.defaultFileUrl ?? ''} className="timun-fileUploader__preview" /> : <LemonIcon name="plus" />}
                </React.Fragment>
              )}
            </FileUpload>
          </div>

          <div className="timun-fileUploader__uploadLinkContainer">
            <FileUpload onUploadFile={this.handleUploadFile} uploadProps={{ multiple: false, beforeUpload: this.checkFile }}>
              {(status: IUploadStatus) => (
                <React.Fragment>
                  {status.isUploading && <Spin />}&nbsp;
                  <a>
                    <LemonIcon name="paperClip" />
                    {this.props.translate('FILE_UPLOAD.MODAL_UPLOAD_BUTTON')}
                  </a>
                </React.Fragment>
              )}
            </FileUpload>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withLocalize<IVideoUploadFormOwnProps>(withPathResolver(VideoUploadForm) as any);

import { INotification, NotificationTypeEnum } from '@src/model/notification/Notification';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export interface INotificationListFilter {
  type?: NotificationTypeEnum;
  message?: string;
}

const NOTIFICATION_LIST_FILTER = '@@NOTIFICATION_LIST_FILTER';

// -
// -------------------- Selectors

const getNotificationList = (store: any): ICollectionData<INotification> => store.notifications;

const getNotificationListFilter = (store: any): INotificationListFilter => ListFilterBusinessStore.selectors.getListFilter(store, NOTIFICATION_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  NOTIFICATION_LIST_FETCH: 'NOTIFICATION_LIST_FETCH',
  NOTIFICATION_LIST_LOAD: 'NOTIFICATION_LIST_LOAD',
  NOTIFICATION_LIST_CLEAR: 'NOTIFICATION_LIST_CLEAR',
};

const fetchNotificationList = (params: ICollectionFetchPayload<INotificationListFilter>): IPayloadAction<ICollectionFetchPayload<INotificationListFilter>> => {
  return {
    type: Actions.NOTIFICATION_LIST_FETCH,
    payload: params,
  };
};

const loadNotificationList = (data: ICollectionData<INotification>): IPayloadAction<ICollectionData<INotification>> => {
  return {
    type: Actions.NOTIFICATION_LIST_LOAD,
    payload: data,
  };
};

const clearNotificationList = (): ILemonAction => {
  return {
    type: Actions.NOTIFICATION_LIST_CLEAR,
  };
};

const clearNotificationListFilter = () => {
  return ListFilterBusinessStore.actions.clearListFilter(NOTIFICATION_LIST_FILTER);
};

const storeNotificationListFilter = (listFilter: INotificationListFilter) => {
  return ListFilterBusinessStore.actions.storeListFilter(NOTIFICATION_LIST_FILTER, listFilter);
};

// -
// ------------------- Effects

const fetchNotificationListEffect = (action$: Observable<IPayloadAction<INotificationListFilter>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.NOTIFICATION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Notification')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadNotificationList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'NOTIFICATION.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching notification list', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

const notifications = (state: INotification | null = null, action: IPayloadAction<INotification>) => {
  if (action.type === Actions.NOTIFICATION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.NOTIFICATION_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

const notificationListBusinessStore = {
  actions: {
    fetchNotificationList,
    loadNotificationList,
    clearNotificationList,
    clearNotificationListFilter,
    storeNotificationListFilter,
  },

  selectors: {
    getNotificationList,
    getNotificationListFilter,
  },

  effects: {
    fetchNotificationListEffect,
  },

  reducers: {
    notifications,
  },
};
export default notificationListBusinessStore;

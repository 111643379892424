import AdministrationWorkPositionListView from '@src/components/administration/workposition/AdministrationWorkPositionListView';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import workPositionBusinessStore, { IWorkPositionCreatePayload, IWorkPositionListFilter } from '@src/service/business/workpositions/workPositionBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

const DEFAULT_SORT_VALUE = ['name,asc'];
const VIEW_NAME = '@@ADMINISTRATION_WORKPOSITION_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<IWorkPositionListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { sort: DEFAULT_SORT_VALUE },
};

// -- Prop types
// ----------

export interface IAdministrationWorkPositionListContainerOwnProps {
}

export interface IAdministrationWorkPositionListContainerStateProps {
  workPositionList: ICollectionData<IWorkPosition>;
}

export interface IAdministrationWorkPositionListContainerDispatchProps {
  fetchWorkPositionList: (params: ICollectionFetchPayload<IWorkPositionListFilter>) => void;
  createWorkPosition: (data: IWorkPositionCreatePayload) => ITrackableAction;
  updateWorkPosition: (data: IWorkPosition) => ITrackableAction;
  updateWorkPositionCourseList: (id: string, data: IIdRef<string>[]) => ITrackableAction;
}

type IWorkPositionListContainerProps = IAdministrationWorkPositionListContainerOwnProps & IAdministrationWorkPositionListContainerStateProps & IAdministrationWorkPositionListContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps & IWithCollectionStateOwnProps<IWorkPositionListFilter>;

// --
// ----- State types

interface IAdministrationWorkPositionListContainerState {
}

// -- Component
// ----------

class AdministrationWorkPositionListContainer extends React.Component<IWorkPositionListContainerProps, IAdministrationWorkPositionListContainerState> {
  state: IAdministrationWorkPositionListContainerState = {};

  componentDidMount() {
    this.updateList();
  }

  componentDidUpdate = (prevProps: IWorkPositionListContainerProps, prevState: IAdministrationWorkPositionListContainerState) => {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.updateList();
    }
  };

  render() {
    return (
      <AdministrationWorkPositionListView
        workPositionList={this.props.workPositionList}
        onUpdateWorkPosition={this.handleUpdateWorkPosition}
        onUpdateWorkPositionCourses={this.handleUpdateCourses}
        onPageChange={this.props.updateCollectionParams.onPageChange}
        onSortChange={this.props.updateCollectionParams.onSortChange}
        onCreateWorkPosition={this.handleCreateWorkPosition}
      />
    );
  }

  handleCreateWorkPosition = (name: string) => {
    this.props.createWorkPosition({ name }).track().subscribe(
      // success
      () => {
        this.updateList();
      }
    );
  };

  handleUpdateWorkPosition = (data: IWorkPosition) => {
    this.props.updateWorkPosition(data).track().subscribe(
      // success
      () => {
        this.updateList();
      }
    );
  };

  handleUpdateCourses = (id: string, data: IIdRef<string>[]) => {
    this.props.updateWorkPositionCourseList(id, data);
  };

  private updateList = () => {
    this.props.onUpdateList(this.props.fetchWorkPositionList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdministrationWorkPositionListContainerOwnProps): IAdministrationWorkPositionListContainerStateProps => ({
  workPositionList: workPositionBusinessStore.selectors.getWorkPositionList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdministrationWorkPositionListContainerDispatchProps => ({
  fetchWorkPositionList: (params: ICollectionFetchPayload<IWorkPositionListFilter>) => dispatch(workPositionBusinessStore.actions.fetchWorkPositionList(params)),
  createWorkPosition: (data: IWorkPositionCreatePayload) => createTrackableAction(dispatch(workPositionBusinessStore.actions.createWorkPosition(data))),
  updateWorkPosition: (data: IWorkPosition) => createTrackableAction(dispatch(workPositionBusinessStore.actions.updateWorkPosition(data))),
  updateWorkPositionCourseList: (id: string, data: IIdRef<string>[]) => createTrackableAction(dispatch(workPositionBusinessStore.actions.updateWorkPositionCourseList({ id, data }))),
});

export default connect<IAdministrationWorkPositionListContainerStateProps, IAdministrationWorkPositionListContainerDispatchProps, IAdministrationWorkPositionListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(withRouter(AdministrationWorkPositionListContainer as any))));

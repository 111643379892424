import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import React, { PropsWithChildren, useMemo } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch-scroll';
import { Button } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { LangUtils } from '@src/service/util/LangUtils';
import { classNames } from '@src/components/common/util/classNames';

// -- Const
// ----------

// -- Prop types
// ----------

export interface IZoomWrapperOwnProps {
  content?: React.ReactNode;
  children?: (props: { controls: React.ReactNode; content: React.ReactNode }) => React.ReactNode;
  className?: string;
}

type IZoomWrapperProps = IZoomWrapperOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------
const ZoomWrapper = (props: PropsWithChildren<IZoomWrapperProps>) => {
  const getClassName = useMemo(
    () =>
      classNames({
        'timun-zoomWrapper__container': true,
        [props.className || '']: true,
      }),
    [classNames, props.className]
  );

  return (
    <TransformWrapper
      defaultScale={0.5}
      wheel={{ step: 2 }}
      defaultPositionX={0}
      defaultPositionY={50}
      options={{
        centerContent: false,
        limitToWrapper: false,
        limitToBounds: false,
        minScale: 0.2,
      }}
      zoomIn={{
        step: 2,
      }}
      zoomOut={{
        step: 2,
      }}
    >
      {(transformProps: any) => {
        // lib has terrible TS definitions - handlers and props used from here: https://prc5.github.io/react-zoom-pan-pinch/?path=/docs/docs-handlers--page
        const controls = (
          <ButtonGroup className="timun-zoomWrapper__controlButtons">
            <Button
              className="timun-zoomWrapper__buttonPlus"
              type="primary"
              icon={<LemonIcon name="plus" />}
              onClick={() => {
                transformProps.setTransform(0, 50, transformProps.scale * 1.2);
              }}
            />
            <Button
              className="timun-zoomWrapper__buttonMinus"
              type="primary"
              icon={<LemonIcon name="minus" />}
              onClick={() => {
                transformProps.setTransform(0, 50, transformProps.scale * 0.8);
              }}
            />
            <Button className="timun-zoomWrapper__buttonReset" onClick={transformProps.resetTransform}>
              {props.translate('COMMON.ACTION_RESET')}
            </Button>
          </ButtonGroup>
        );
        const content = <TransformComponent>{props.content}</TransformComponent>;
        if (LangUtils.isFunction(props.children)) {
          return <div className={getClassName}>{props.children({ controls, content })}</div>;
        }
        return (
          <div className={getClassName}>
            {controls}
            {content}
          </div>
        );
      }}
    </TransformWrapper>
  );
};

// -- HOCs and exports
// ----------
export default withRoles(withLocalize<IZoomWrapperOwnProps>(ZoomWrapper as any));

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExamTemplateSider from '@src/components/exam/examtemplate/ExamTemplateSider';
import { ICourse } from '@src/model/course/Course';
import courseViewBusinessStore from '@src/service/business/courses/courseViewBusinessStore';
import { Layout, Typography } from 'antd';
import React, { PropsWithChildren, useEffect } from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const { Header, Sider, Content } = Layout;

// -- Prop types
// ----------

export interface IExamTemplateMenuLayoutContainerOwnProps {
  currentPage: string;
  examTemplateId: string;
  courseId: string;
  children: React.ReactNode;
}
export interface IExamTemplateMenuLayoutContainerStateProps {
  course: ICourse;
}
export interface IExamTemplateMenuLayoutContainerDispatchProps {
  fetchCourseContent: () => void;
}
type IExamTemplateMenuLayoutContainerProps = IExamTemplateMenuLayoutContainerOwnProps & IWithLocalizeOwnProps & IExamTemplateMenuLayoutContainerStateProps & IExamTemplateMenuLayoutContainerDispatchProps;

// -- Component
// ----------

/** Course administration page layout with Menu and Content display */
const ExamTemplateMenuLayoutContainer = (props: PropsWithChildren<IExamTemplateMenuLayoutContainerProps>) => {
  useEffect(() => {
    props.fetchCourseContent();
  }, [props.courseId]);

  return (
    <Layout>
      <Header className="timun-examTemplate__sider-courseTitle timun-wrapper__gutterBox--vertical">
        {props.course && <HeaderTitle link={CourseHelperUtils.getAdminPath(props.course)} title={props.translate('COURSE_ADMINISTRATION.VIEW.RETURN_TO_COURSE_LABEL')} icon={<LemonIcon name="left" />} />}
        <Typography.Title>{props.translate('COURSE_ADMINISTRATION.VIEW.EDIT_EXAM_TEMPLATE_HEADER_TITLE')} </Typography.Title>
      </Header>
      <Layout>
        <Sider className="timun-courseView__administrationSider" breakpoint="lg" collapsedWidth={0}>
          {props.course && <ExamTemplateSider courseId={props.course.id} examTemplateId={props.examTemplateId} currentPage={props.currentPage} showExamTemplateStatistics={CourseHelperUtils.hasExamTemplate(props.course)} />}
        </Sider>
        <Content>
          <React.Fragment>{props.children}</React.Fragment>
        </Content>
      </Layout>
    </Layout>
  );
};
// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExamTemplateMenuLayoutContainerOwnProps): IExamTemplateMenuLayoutContainerStateProps => ({
  course: courseViewBusinessStore.selectors.getCourseContent(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IExamTemplateMenuLayoutContainerOwnProps): IExamTemplateMenuLayoutContainerDispatchProps => ({
  fetchCourseContent: () => dispatch(courseViewBusinessStore.actions.fetchCourseContent({ id: ownProps.courseId })),
});
export default connect<IExamTemplateMenuLayoutContainerStateProps, IExamTemplateMenuLayoutContainerDispatchProps, IExamTemplateMenuLayoutContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ExamTemplateMenuLayoutContainer as any));

import { IExamInstance } from '@src/model/education/ExamInstance';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { IQuestionOutcomeStats } from '@lamarodigital/quizzler-lib-frontend/model/quiz/outcome/QuestionOutcomeStats';
import IIdRef from '@src/model/common/IdRef';
import { IEducationGroup } from '@src/model/education/EducationGroup';
import { IUserInfo } from '@src/model/user/User';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { transformExamInstanceListFilter } from '@src/service/business/examtemplates/examInstanceBussinesStoreUtil';
import { RequiredBy } from '@src/service/util/lang/type';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface IExamInstanceListFilter {
  template?: string;
  assignee?: RequiredBy<Partial<IUserInfo>, 'id'>;
}

export interface IExamInstanceCreatePayload {
  educationGroup: IEducationGroup;
  questionsPerOutcome?: IQuestionOutcomeStats[];
  totalNumberOfQuestions?: number;
}

// -
// -------------------- Selectors

/** Returns Exam instance instance list from store. */
const getExamInstanceList = (store: any): ICollectionData<IExamInstance> => store.examInstanceList;

/** Returns Exam instance instance from store. */
const getExamInstance = (store: any): IExamInstance => store.examInstance;

// -
// -------------------- Actions

const Actions = {
  EXAM_INSTANCE_FETCH: 'EXAM_INSTANCE_FETCH',
  EXAM_INSTANCE_LOAD: 'EXAM_INSTANCE_LOAD',
  EXAM_INSTANCE_CLEAR: 'EXAM_INSTANCE_CLEAR',
  EXAM_INSTANCE_CREATE: 'EXAM_INSTANCE_CREATE',
  EXAM_INSTANCE_LIST_FETCH: 'EXAM_INSTANCE_LIST_FETCH',
  EXAM_INSTANCE_LIST_LOAD: 'EXAM_INSTANCE_LIST_LOAD',
  EXAM_INSTANCE_LIST_CLEAR: 'EXAM_INSTANCE_LIST_CLEAR',
  EXAM_INSTANCE_SUBMIT: 'EXAM_INSTANCE_SUBMIT',
};

/** Create exam instance */
const createExamInstance = (id: string, data: IExamInstanceCreatePayload): IPayloadAction<IIdDataPayload<IExamInstanceCreatePayload>> => {
  return {
    type: Actions.EXAM_INSTANCE_CREATE,
    payload: {
      id,
      data,
    },
  };
};

/** Fetch ExamTemplate instance  by ID. */
const fetchExamInstance = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EXAM_INSTANCE_FETCH,
    payload: {
      id,
    },
  };
};

/** Load ExamTemplate instance to store. */
const loadExamInstance = (data: ICollectionData<IExamInstance>): IPayloadAction<ICollectionData<IExamInstance>> => {
  return {
    type: Actions.EXAM_INSTANCE_LOAD,
    payload: data,
  };
};

/** Clear ExamTemplate instance from store. Eg. when leaving view. */
const clearExamInstance = (): ILemonAction => {
  return {
    type: Actions.EXAM_INSTANCE_CLEAR,
  };
};

/** Fetch Exam  instance list by ID. */
const fetchExamInstanceList = (payload: ICollectionFetchPayload<IExamInstanceListFilter>): IPayloadAction<ICollectionFetchPayload<IExamInstanceListFilter>> => {
  return {
    type: Actions.EXAM_INSTANCE_LIST_FETCH,
    payload,
  };
};

/** Load ExamTemplate instance list to store. */
const loadExamInstanceList = (data: ICollectionData<IExamInstance>): IPayloadAction<ICollectionData<IExamInstance>> => {
  return {
    type: Actions.EXAM_INSTANCE_LIST_LOAD,
    payload: data,
  };
};

/** Clear ExamTemplate instance list from store. Eg. when leaving view. */
const clearExamInstanceList = (): ILemonAction => {
  return {
    type: Actions.EXAM_INSTANCE_LIST_CLEAR,
  };
};

/** Clear ExamTemplate instance list from store. Eg. when leaving view. */
const submitExamInstance = (examInstance: IExamInstance): IPayloadAction<IIdDataPayload<IExamInstance>> => {
  return {
    type: Actions.EXAM_INSTANCE_SUBMIT,
    payload: {
      id: examInstance.id,
      data: examInstance,
    },
  };
};

// -
// -------------------- Side-effects

const examInstanceCreateEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IExamInstanceCreatePayload>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXAM_INSTANCE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('ExamTemplate')
        .createSubentityList(id, 'generateinstance', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXAM_INSTANCE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating exam instance', error);
      return o;
    })
  );
};

const fetchExamInstanceListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IExamInstanceListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXAM_INSTANCE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = {
        // first copy entire payload
        ...action.payload,
        // then override filter with transformed value
        filter: action.payload.filter ? transformExamInstanceListFilter(action.payload.filter) : undefined,
      };

      return EntityApiServiceRegistry.getService('ExamInstance')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExamInstanceList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXAM_INSTANCE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching exam instance list', error);
      return o;
    })
  );
};

const fetchExamInstanceEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXAM_INSTANCE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('ExamInstance')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExamInstance(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXAM_INSTANCE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching exam instance', error);
      return o;
    })
  );
};

const submitExamInstanceEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IIdRef<IExamInstance>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXAM_INSTANCE_SUBMIT;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ExamInstance')
        .createSubobject(payload.id, 'submit', payload.data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXAM_INSTANCE.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error submiting exam instance', error);
      return o;
    })
  );
};
// -
// -------------------- Reducers

const examInstanceList = (state: ICollectionData<IExamInstance> | null = null, action: IPayloadAction<ICollectionData<IExamInstance>>) => {
  if (action.type === Actions.EXAM_INSTANCE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXAM_INSTANCE_LIST_CLEAR) {
    return null;
  }

  return state;
};

const examInstance = (state: IExamInstance | null = null, action: IPayloadAction<IExamInstance>) => {
  if (action.type === Actions.EXAM_INSTANCE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXAM_INSTANCE_CLEAR) {
    return null;
  }

  return state;
};
// --
// -------------------- Business Store

export const examInstanceBusinessStore = {
  actions: {
    createExamInstance,
    fetchExamInstanceList,
    loadExamInstanceList,
    clearExamInstanceList,
    fetchExamInstance,
    loadExamInstance,
    clearExamInstance,
    submitExamInstance,
  },

  selectors: {
    getExamInstanceList,
    getExamInstance,
  },

  effects: {
    examInstanceCreateEffect,
    fetchExamInstanceListEffect,
    fetchExamInstanceEffect,
    submitExamInstanceEffect,
  },

  reducers: {
    examInstanceList,
    examInstance,
  },
};

// --
// export business store
export default examInstanceBusinessStore;

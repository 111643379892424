import { Col, Row, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import LemonAvatar from '@src/components/common/image/LemonAvatar';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';
import { Link } from 'react-router';

// -- Prop types
// ----------
export interface IAppLogoContainerPublicProps {
  showTenantName?: boolean;
}
interface IAppLogoContainerStateProps {
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}
type IAppLogoContainerProps = IAppLogoContainerPublicProps & IWithLocalizeOwnProps & IAppLogoContainerStateProps & IWithPathResolverOwnProps;

// --
// ----- Component
const AppLogoContainer: React.FC<IAppLogoContainerProps> = (props) => {
  return (
    <Link to="/">
      <Row align="middle" className="flex-nowrap">
        <Col title={props.tenantOrganizationConfiguration.configuration?.displayName}>{props.tenantOrganizationConfiguration.configuration?.logoFileId && <LemonAvatar imagePath={props.resolveApiFilePath(props.tenantOrganizationConfiguration.configuration?.logoFileId, true)} />}</Col>
        {props.tenantOrganizationConfiguration.configuration && props.showTenantName && (
          <Col className="lemon-appLogoContainer__tenantNameContainer">
            <Typography.Title level={4} ellipsis={true} className="lemon-appLogoContainer__tenantName">
              &nbsp;&nbsp;
              {props.tenantOrganizationConfiguration.configuration?.displayName}
            </Typography.Title>
          </Col>
        )}
      </Row>
    </Link >
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAppLogoContainerPublicProps): IAppLogoContainerStateProps => ({
  tenantOrganizationConfiguration: PublicTenantOrganizationConfigurationBusinessStore.selectors.getTenantOrganizationConfiguration(state),
});

export default connect<IAppLogoContainerStateProps, undefined, IAppLogoContainerPublicProps>(mapStateToProps)(withLocalize(withPathResolver(AppLogoContainer as any)));

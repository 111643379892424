import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IAdminCodebookListViewOwnProps {
}

interface IAdminCodebookListViewStateProps {
  tenantConfig: ITenantConfiguration;
}
type IAdminCodebookListViewProps = IAdminCodebookListViewOwnProps & IAdminCodebookListViewStateProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------

/** Display list of codebook edit pages */
const AdminCodebookListView = (props: IAdminCodebookListViewProps) => {
  const setPath = (path: string) => {
    return props.router.push(`administration/codebooks/${path}`);
  };
  return (
    <React.Fragment>
      {props.tenantConfig?.configuration?.adminCodebook.adminCodebookList?.map((codebook: string) => {
        return (
          <Row justify="space-between" className="panel" key={codebook}>
            <Col>
              {props.translate(`CODEBOOK.TITLE_LABEL.${codebook}`)}
            </Col>
            <Col>
              <Button onClick={() => setPath(codebook)}> {props.translate('COMMON.ACTION_EDIT')} </Button>
            </Col>
          </Row>
        );
      })}
    </React.Fragment>
  );

};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdminCodebookListViewOwnProps): IAdminCodebookListViewStateProps => ({
  tenantConfig: PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state),
});

// -- HOCs and exports
// ----------

export default connect<IAdminCodebookListViewStateProps, {}, IAdminCodebookListViewOwnProps>(mapStateToProps)(withLocalize(withRouter(AdminCodebookListView as any)));

import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { IUserInfo } from '@src/model/user/User';

export interface InvalidUsers {
  id: string;
  user: IUserInfo;
  rowNumber: number;
  importUserErrorData: IImportUserErrorData[];
}

export interface IImportUserErrorData {
  timunImportUserError: ITimunImportUserError;
  data: IDataError;
}

export interface ITimunImportUserError extends ICodeBookEntry<TimunImportUserErrorEnum> {}
export interface IDataError extends ICodeBookEntry<DataErrorEnum> {}

export enum TimunImportUserErrorEnum {
  MISSING_MANDATORY_DATA = '1',
  INVALID_DATE_FORMAT = '2',
  USERNAME_ALREADY_IN_USE = '3',
  NON_EXISTING_WORK_POSITION = '4',
  PASSWORD_TOO_SHORT = '5',
  INVALID_ADDRESS = '6',
  INVALID_EMAIL_ADDRESS = '7',
  NON_EXISTING_CITY = '8',
}

export enum DataErrorEnum {
  USERNAME = '1',
  PASSWORD = '2',
  FIRST_NAME = '3',
  LAST_NAME = '4',
  DATE_OF_BIRTH = '5',
  WORK_START_DATE = '6',
  WORK_POSITION = '7',
  EMAIL = '8',
  PHONE_NUMBER = '9',
  MOBILE_PHONE = '10',
  STREET = '11',
  HOME_NUMBER = '12',
  CITY = '13',
  ZIP_CODE = '14',
  COUNTRY = '15',
}

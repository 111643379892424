import { RouterNavigationPromptContext } from '@src/components/common/route/prompt/RouterNavigationPromptContext';
import React, { PropsWithChildren, useEffect } from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------

export interface IRouterNavigationPromptContextProviderOwnProps {
  message: string;
}
type IRouterNavigationPromptContextProviderProps = IRouterNavigationPromptContextProviderOwnProps & WithRouterProps;

// -- Component
// ----------

/** Prompt user before leaving route */
const RouterNavigationPromptContextProvider = (props: PropsWithChildren<IRouterNavigationPromptContextProviderProps>) => {
  const [isRouterPromptActive, setIsRouterPromptActive] = React.useState<boolean>(false);
  useEffect(() => {
    const currentRoute = props.routes[props.routes.length - 1];
    props.router.setRouteLeaveHook(currentRoute, () => {
      if (isRouterPromptActive) {
        return props.message;
      } else {
        return true;
      }
    });
  }, [isRouterPromptActive]);

  return <RouterNavigationPromptContext.Provider value={{ isRouterPromptActive, setIsRouterPromptActive }}>{props.children}</RouterNavigationPromptContext.Provider>;
};

// -- HOCs and exports
// ----------

export default withRouter(RouterNavigationPromptContextProvider);

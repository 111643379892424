import IIdRef from '@src/model/common/IdRef';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { IFile } from '@src/model/file/File';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import CollectionHelperService from '@src/service/business/common/CollectionHelperService';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, ILemonAction, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

export interface ICourseGroupFilter {}

export interface ICourseGroupCreatePayload {
  title: string;
}

// -
// -------------------- Selectors

/** Returns list of coursegroups from store. */
const getCourseGroups = (store: any): ICollectionData<ICourseGroup> => store.courseGroupList;

// -
// -------------------- Actions

const Actions = {
  COURSE_GROUP_LIST_FETCH: 'COURSE_GROUP_LIST_FETCH',
  COURSE_GROUP_LIST_LOAD: 'COURSE_GROUP_LIST_LOAD',
  COURSE_GROUP_LIST_CLEAR: 'COURSE_GROUP_LIST_CLEAR',
  COURSE_GROUP_CREATE: 'COURSE_GROUP_CREATE',
  COURSE_GROUP_DELETE: 'COURSE_GROUP_DELETE',
  COURSE_GROUP_UPDATE: 'COURSE_GROUP_UPDATE',
  COURSE_GROUP_COVER_IMAGE_UPLOAD: ' COURSE_GROUP_COVER_IMAGE_UPLOAD',
};

/** Fetch coursegroup list by filter. */
const fetchCourseGroups = (collectionParams: ICollectionFetchPayload<ICourseGroupFilter>): IPayloadAction<ICollectionFetchPayload<ICourseGroupFilter>> => {
  return {
    type: Actions.COURSE_GROUP_LIST_FETCH,
    payload: collectionParams,
  };
};

/** Load courseGroup to store. */
const loadCourseGroupList = (data: ICollectionData<ICourseGroup>): IPayloadAction<ICollectionData<ICourseGroup>> => {
  return {
    type: Actions.COURSE_GROUP_LIST_LOAD,
    payload: data,
  };
};

const clearCourseGroupList = (): ILemonAction => {
  return {
    type: Actions.COURSE_GROUP_LIST_CLEAR,
  };
};

/** Create new coursegroup */
const createCourseGroup = (data: ICourseGroupCreatePayload): IPayloadAction<ICourseGroupCreatePayload> => {
  return {
    type: Actions.COURSE_GROUP_CREATE,
    payload: data,
  };
};

/** Delete coursegroup by ID */
const deleteCourseGroup = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.COURSE_GROUP_DELETE,
    payload: data,
  };
};

/** Update coursegroup by ID */
const updateCourseGroup = (data: IIdDataPayload<ICourseGroupCreatePayload>): IPayloadAction<IIdDataPayload<ICourseGroupCreatePayload>> => {
  return {
    type: Actions.COURSE_GROUP_UPDATE,
    payload: data,
  };
};

/** Upload cover image in a coursegroup */
const uploadCourseGroupCover = (id: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.COURSE_GROUP_COVER_IMAGE_UPLOAD,
    payload: {
      id,
      data,
    },
  };
};
// -
// -------------------- Side-effects

const fetchCourseGroupListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ICourseGroupFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_GROUP_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('CourseGroup')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseGroupList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching coursegroup list', error);
      return o;
    })
  );
};

const createCourseGroupEffect = (action$: Observable<IPayloadAction<string>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_GROUP_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('CourseGroup')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    tap(() => {
      // CourseGroup list is a collection loaded on start of the app, should also be updated when something changes with elements it lists
      CollectionHelperService.fetchCollection<ICourseGroup>('CourseGroup');
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating coursegroup', error);
      return o;
    })
  );
};

const deleteCourseGroupEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_GROUP_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('CourseGroup')
        .deleteEntity(data.id)
        .pipe(actionThunk(action));
    }),

    tap(() => {
      // CourseGroup list is a collection loaded on start of the app, should also be updated when something changes with elements it lists
      CollectionHelperService.fetchCollection<ICourseGroup>('CourseGroup');
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      return { message: LocalizeService.translate('COURSE_GROUP.DELETED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO };
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting coursegroup', error);
      return o;
    })
  );
};

const updateCourseGroupEffect = (action$: Observable<IPayloadAction<IIdDataPayload<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_GROUP_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('CourseGroup')
        .updateEntity(data.id, data.data)
        .pipe(actionThunk(action));
    }),

    tap(() => {
      // CourseGroup list is a collection loaded on start of the app, should also be updated when something changes with elements it lists
      CollectionHelperService.fetchCollection<ICourseGroup>('CourseGroup');
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating coursegroup', error);
      return o;
    })
  );
};

const uploadCourseGroupCoverEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_GROUP_COVER_IMAGE_UPLOAD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('CourseGroup')
        .createSubobject(id, 'cover', data)
        .pipe(actionThunk(action));
    }),

    tap(() => {
      // CourseGroup list is a collection loaded on start of the app, should also be updated when something changes with elements it lists
      CollectionHelperService.fetchCollection<ICourseGroup>('CourseGroup');
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error uploading cover image', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const courseGroupList = (state: ICollectionData<ICourseGroup> | null = null, action: IPayloadAction<ICollectionData<ICourseGroup>>) => {
  if (action.type === Actions.COURSE_GROUP_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.COURSE_GROUP_LIST_CLEAR) {
    return null;
  }
  return state;
};

// --
// -------------------- Business Store

export const CourseGroupBusinessStore = {
  actions: {
    fetchCourseGroups,
    loadCourseGroupList,
    clearCourseGroupList,
    createCourseGroup,
    deleteCourseGroup,
    updateCourseGroup,
    uploadCourseGroupCover,
  },
  selectors: {
    getCourseGroups,
  },
  effects: {
    fetchCourseGroupListEffect,
    createCourseGroupEffect,
    deleteCourseGroupEffect,
    updateCourseGroupEffect,
    uploadCourseGroupCoverEffect,
  },
  reducers: {
    courseGroupList,
  },
};

// --
// export business store
export default CourseGroupBusinessStore;

import ExamTemplateMenuLayoutContainer from '@src/components/exam/examtemplate/ExamTemplateMenuLayoutContainer';
import React, { PropsWithChildren } from 'react';
import { withRouter, WithRouterProps } from 'react-router';


// -- Prop types
// ----------
type IExamTemplateLayoutMenuPageProps = WithRouterProps;

const ExamTemplateLayoutMenuPage = (props: PropsWithChildren<IExamTemplateLayoutMenuPageProps>) => {
  // TODO: add querry param instead of slicing the pathname string
  const getExamTemplatePathId = (): string => props.location.pathname.slice(props.location.pathname.lastIndexOf('/') + 1);
  return (
    <ExamTemplateMenuLayoutContainer currentPage={getExamTemplatePathId()} examTemplateId={props.params.examTemplateId} courseId={props.params.courseId}>
      {props.children}
    </ExamTemplateMenuLayoutContainer>
  );
};

export default withRouter<IExamTemplateLayoutMenuPageProps>(ExamTemplateLayoutMenuPage as any);

import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import CodebookShortInfoForm from '@src/components/codebook/form/CodebookShortInfoForm';
import CodeBookListView from '@src/components/codebook/view/CodebookList';
import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEducationSector } from '@src/model/educationsector/EducationSector';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import EducationSectorBusinessStore, { IEducationSectorCreatePayload, IEducationSectorListFilter } from '@src/service/business/educationsector/educationSectorBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const VIEW_NAME = '@@EDUCATION_SECTOR_CODEBOOK';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');

// -- Prop types
// ----------
export interface IEducationSectorCodebookContainerOwnProps { }
export interface IEducationSectorCodebookContainerStateProps {
  educationSectorList: ICollectionData<IEducationSector>;
}
export interface IEducationSectorCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchEducationSectorList: (params: ICollectionFetchPayload<IEducationSectorListFilter>) => ITrackableAction;
  createEducationSector: (data: IEducationSectorCreatePayload) => ITrackableAction;
  updateEducationSector: (data: IEducationSector) => ITrackableAction;
}
type IEducationSectorCodebookContainerProps = IEducationSectorCodebookContainerOwnProps & IEducationSectorCodebookContainerStateProps & IEducationSectorCodebookContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Coded grade container */
const EducationSectorCodebookContainer = (props: IEducationSectorCodebookContainerProps) => {
  const [educationSectorModalsState, toggleEducationSectorModals] = useEntityModalsState<IEducationSector>();
  const [, updateCollectionParams, onUpdateList] = useCollectionState<IEducationSectorListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchEducationSectorList,
    initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
  });
  const handleEducationSectorCreateSubmit = useCallback((data: IEducationSectorCreatePayload) => {
    props.createEducationSector(data).track().subscribe(
      // success
      () => {
        props.reportMessage({ message: props.translate('EDUCATION_SECTOR.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        onUpdateList();
        toggleEducationSectorModals.onCloseCreate();
      }
    );
  }, [props.createEducationSector, props.reportMessage, onUpdateList, toggleEducationSectorModals]);

  const handleEducationSectorUpdateSubmit = useCallback((data: IEducationSector) => {
    props.updateEducationSector(data).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleEducationSectorModals.onCloseUpdate();
      }
    );
  }, [props.updateEducationSector, onUpdateList, toggleEducationSectorModals]);

  return (
    <React.Fragment>
      {props.educationSectorList && <CodeBookListView<IEducationSector>
        showBackColumn={true}
        title={props.translate(`CODEBOOK.TITLE_LABEL.${CodebookEnum.EDUCATION_SECTOR}`)}
        descriptionPrefix={'EDUCATION_SECTOR.DESCRIPTION_LABEL'}
        buttonLabel={props.translate('EDUCATION_SECTOR.CREATE_BUTTON_LABEL')}
        codebookItemList={props.educationSectorList}
        canEdit={true}
        onEditCodebookItemClick={toggleEducationSectorModals.onOpenUpdate}
        onCreateCodebookItemClick={toggleEducationSectorModals.onOpenCreate}
        onPageChange={updateCollectionParams.onPageChange}
      />}

      {(educationSectorModalsState.isCreateModalVisible || educationSectorModalsState.isUpdateModalVisible) && <CodebookShortInfoForm<IEducationSector, IEducationSectorCreatePayload>
        value={educationSectorModalsState.selectedEntity}
        onCancel={toggleEducationSectorModals.onCloseCreate}
        onUpdate={handleEducationSectorUpdateSubmit}
        onCreate={handleEducationSectorCreateSubmit} />}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationSectorCodebookContainerOwnProps): IEducationSectorCodebookContainerStateProps => ({
  educationSectorList: EducationSectorBusinessStore.selectors.getEducationSectorList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationSectorCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchEducationSectorList: (params: ICollectionFetchPayload<IEducationSectorListFilter>) => dispatch(createTrackableAction(EducationSectorBusinessStore.actions.fetchEducationSectorList(params))),
  createEducationSector: (data: IEducationSectorCreatePayload) => dispatch(createTrackableAction(EducationSectorBusinessStore.actions.createEducationSector(data))),
  updateEducationSector: (data: IEducationSector) => dispatch(createTrackableAction(EducationSectorBusinessStore.actions.updateEducationSector(data))),
});

export default connect<IEducationSectorCodebookContainerStateProps, IEducationSectorCodebookContainerDispatchProps, IEducationSectorCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(EducationSectorCodebookContainer as any));

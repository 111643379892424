import React, { useCallback } from 'react';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SkillTreePicker from '@src/components/common/datapicker/SkillTreePicker';
import { ISkill } from '@src/model/skillgroup/Skill';
import { Button, Col, Form, Row } from 'antd';
import FormDataItemList from '@src/components/common/form/FormDataItemList';

// -- Const
// ----------

// -- Prop types
// ----------

export interface ICourseSkillListEditViewOwnProps {
  courseSkillList: ISkill[];
  onUpdateSkillList: (data: ISkill[]) => void;
  onEditingChange: () => void;
}

export interface ISkillSelectForm {
  skills: ISkill[];
}

type ICourseSkillListEditViewProps = ICourseSkillListEditViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const CourseSkillListEditView = (props: ICourseSkillListEditViewProps) => {
  const [form] = Form.useForm<ISkillSelectForm>();

  const handleFinish = useCallback(
    (values: ISkillSelectForm) => {
      props.onUpdateSkillList(values.skills);
    },
    [props.onUpdateSkillList]
  );

  return (
    <React.Fragment>
      <Form<ISkillSelectForm> form={form} onFinish={handleFinish} layout="vertical" initialValues={{ skills: props.courseSkillList }}>
        <Form.Item label={props.translate('COURSE_ADMINISTRATION.VIEW.SKILLS_LABEL')}>
          <FormDataItemList formRef={form} name={'skills'}>
            <SkillTreePicker />
          </FormDataItemList>
        </Form.Item>

        <Row gutter={12}>
          <Col>
            <Button type="primary" data-test-id="timun-courseSkillListEditView__cancelButton" onClick={props.onEditingChange}>
              {props.translate('COMMON.ACTION_CANCEL')}
            </Button>
          </Col>
          <Col>
            <Form.Item shouldUpdate={true}>
              {() => (
                <Button type="primary" htmlType="submit" data-test-id="timun-courseSkillListEditView__submitButton" disabled={!form.isFieldsTouched()}>
                  {props.translate('COURSE_ADMINISTRATION.VIEW.ASSIGN_SKILL_LABEL')}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default withLocalize<ICourseSkillListEditViewOwnProps>(CourseSkillListEditView as any);

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import DeleteButton from '@src/components/common/button/DeleteButton';
import EditableWrapper from '@src/components/common/container/EditableWrapper';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import { formatUrl, parseUrl } from '@src/components/common/util/UrlUtils';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { IFile } from '@src/model/file/File';
import { IExternalContentCreatePayload } from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import { Button, Col, Input, Row, Form } from 'antd';
import React, { useState, useCallback } from 'react';

// -- Prop types
// ----------

interface IExternalContentDataFormOwnProps {
  externalContent?: IExternalContent;
  coverImageId?: string;
  onSubmit?: (data: IExternalContentCreatePayload) => void;
  onCancel?: (editing: boolean) => void;
  onDelete?: () => void;
  onCoverSubmit: (data: IFile) => void;
}

type IExternalContentDataFormProps = IExternalContentDataFormOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithRolesOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

const ExternalContentDataForm: React.FC<IExternalContentDataFormProps> = (props: IExternalContentDataFormProps) => {
  const [isEditingCover, setIsEditingCover] = useState<boolean>(false);
  const [form] = Form.useForm<IExternalContent>();

  const toggleCoverEdit = useCallback(() => {
    setIsEditingCover(!isEditingCover);
  }, [setIsEditingCover, isEditingCover]);

  const handleFinish = useCallback(
    (values: IExternalContent) => {
      if (props.onSubmit) {
        const url = formatUrl(values.url);
        const data: IExternalContentCreatePayload = {
          title: values.title,
          url,
          description: values.description,
          coverImageUrl: values.coverImageUrl,
        };
        props.onSubmit(data);
      }
    },
    [props.onSubmit, formatUrl]
  );

  return (
    <Form<IExternalContent> layout="vertical" form={form} onFinish={handleFinish} initialValues={{ title: props.externalContent?.title, url: parseUrl(props.externalContent?.url), description: props.externalContent?.description, coverImageUrl: props.externalContent?.coverImageUrl }}>
      <Form.Item
        label={props.translate('EXTERNAL_CONTENT.FORM.TITLE_LABEL')}
        name={'title'}
        rules={[
          { whitespace: true, message: props.translate('EXTERNAL_CONTENT.FORM.TITLE_WHITESPACE_MESSAGE') },
          { required: true, message: props.translate('EXTERNAL_CONTENT.FORM.TITLE_REQUIRED_MESSAGE') },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_CONTENT.FORM.URL_LABEL')} name={'url'} rules={[{ required: true, message: props.translate('EXTERNAL_CONTENT.FORM.URL_REQUIRED_MESSAGE') }]}>
        <Input addonBefore={'https://'} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_CONTENT.FORM.DESCRIPTION_LABEL')} name={'description'} rules={[{ whitespace: true, message: props.translate('EXTERNAL_CONTENT.FORM.DESCRIPTION_WHITESPACE_MESSAGE') }]}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_CONTENT.FORM.COVER_LABEL')} name={'coverImageUrl'}>
        <React.Fragment>
          {props.externalContent?.coverImageUrl || props.coverImageId ? (
            <EditableWrapper className="timun-externalContentForm__imageWrapper" onEdit={toggleCoverEdit} showMask={false} showRemove={false}>
              <LemonImage imagePath={props.externalContent?.coverImageUrl || (props.coverImageId && props.resolveApiFilePath(props.coverImageId)) || 'cover-placeholder.png'} className="timun-externalContentForm__coverImage" />
            </EditableWrapper>
          ) : (
            <Button type="primary" icon={<LemonIcon name="paperClip" />} onClick={toggleCoverEdit}>
              {props.translate('FILE_UPLOAD.MODAL_UPLOAD_BUTTON')}
            </Button>
          )}
          {isEditingCover && <ImageUploadForm avatar={true} defaultImageLink={props.externalContent?.coverImageUrl} onSubmit={props.onCoverSubmit} onClose={toggleCoverEdit} />}
        </React.Fragment>
      </Form.Item>
      <Form.Item>
        <Row justify="end" gutter={12}>
          {props.onDelete && (
            <React.Fragment>
              <Col>
                <DeleteButton buttonText={props.translate('COMMON.ACTION_DELETE')} onConfirm={props.onDelete} />
              </Col>
              &nbsp;&nbsp;
            </React.Fragment>
          )}
          <Col>
            <Button icon={<LemonIcon name="times" />} onClick={() => props.onCancel?.(false)}>
              {props.translate('COMMON.ACTION_CANCEL')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" icon={<LemonIcon name="check" />} htmlType="submit">
              {props.translate('COMMON.ACTION_SAVE')}
            </Button>
            &nbsp;
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

// -- HOCs and exports
export default withLocalize<IExternalContentDataFormOwnProps>(withRoles(withPathResolver(ExternalContentDataForm as any)));

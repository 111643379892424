import { ExternalEducationApplicationFileTypeEnum } from '@src/model/file/FileType';

/** contract type property is mandatory only if file types is "CONTRACT_ANNEX" or "FINANCING_AGREEMENT" because we do not save that information on education application  */
export interface IExternalEducationApplicationFilePreviewData {
  fileType: ExternalEducationApplicationFileTypeEnum;
  contractType?: ContractTypeEnum;
}

export enum FinancingAgreemntTypeEnum {
  EMPLOYED = 'EMPLOYED',
  EMPLOYED_CO_FINANCED_BY_EMPLOYER = 'EMPLOYED_CO_FINANCED_BY_EMPLOYER',
  EMPLOYED_CO_FINANCED_BY_USER = 'EMPLOYED_CO_FINANCED_BY_USER',
  UNEMPLOYED = 'UNEMPLOYED',
  UNEMPLOYED_CO_FINANCED = 'UNEMPLOYED_CO_FINANCED',
  UNEMPLOYED_WAIVER_OF_FINANCIAL_AID = 'UNEMPLOYED_WAIVER_OF_FINANCIAL_AID',
  UNEMPLOYED_CO_FINANCED_WAIVER_OF_FINANCIAL_AID = 'UNEMPLOYED_CO_FINANCED_WAIVER_OF_FINANCIAL_AID',
}

export enum ContractAnnexTypeEnum {
  CUSTOM = 'CUSTOM',
  FINANCING_AGREEMENT_EMPLOYED_CORRECTION = 'FINANCING_AGREEMENT_EMPLOYED_CORRECTION',
  FINANCING_AGREEMENT_UNEMPLOYED_CORRECTION = 'FINANCING_AGREEMENT_UNEMPLOYED_CORRECTION',
  START_DATE_TIME_CORRECTION_UNEMPLOYED = 'START_DATE_TIME_CORRECTION_UNEMPLOYED',
  START_DATE_TIME_CORRECTION_EMPLOYED = 'START_DATE_TIME_CORRECTION_EMPLOYED',
  EMPLOYED_TO_UNEMPLOYED = 'EMPLOYED_TO_UNEMPLOYED',
  UNEMPLOYED_TO_EMPLOYED = 'UNEMPLOYED_TO_EMPLOYED',
}

export type ContractTypeEnum = FinancingAgreemntTypeEnum | ContractAnnexTypeEnum;

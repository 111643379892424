import React from 'react';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import ExternalEducationTemplateListFilter from '@src/components/externaleducation/list/ExternalEducationTemplateListFilter';
import ExternalEducationTemplateList from '@src/components/externaleducation/list/ExternalEducationTemplateList';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICollectionData } from '@src/service/business/common/types';
import { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';

// -- Prop types
// ----------

export interface ICoordinatorExternalEducationTemplateListViewOwnProps {
  externalEducationTemplateList: ICollectionData<IExternalEducationTemplate>;
  onPageChange?: (page: number, pageSize?: number) => void;
  onFilterChange?: (filter: IExternalEducationTemplateListFilter) => void;
  additionalCols?: () => Array<ITimunDataTableCol<IExternalEducationTemplate>>;
  externalEducationTemplateFilter?: IExternalEducationTemplateListFilter;
  showFilter?: boolean;
}
type ICoordinatorExternalEducationTemplateListViewProps = ICoordinatorExternalEducationTemplateListViewOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display coordinator external education template table  */
const CoordinatorExternalEducationTemplateListView: React.FC<ICoordinatorExternalEducationTemplateListViewProps> = (props: ICoordinatorExternalEducationTemplateListViewProps) => {

  return (
    <AppContent>
      {props.showFilter && <ExternalEducationTemplateListFilter filter={props.externalEducationTemplateFilter} onFilterChange={props.onFilterChange} />}

      <ExternalEducationTemplateList
        externalEducationTemplateList={props.externalEducationTemplateList}
        onPageChange={props.onPageChange}
      />
    </AppContent>
  );
};

export default withLocalize<ICoordinatorExternalEducationTemplateListViewOwnProps>(CoordinatorExternalEducationTemplateListView as any);

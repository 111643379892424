import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamTemplateQuizCreatorView from '@src/components/exam/view/ExamTemplateQuizCreatorView';
import { IExamTemplate } from '@src/model/education/ExamTemplate';
import examTemplateBusinessStore from '@src/service/business/examtemplates/examTemplateBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------

export interface IExamTemplateQuizCreatorContainerOwnProps {
  examTemplateId: string;
}

export interface IExamTemplateQuizCreatorContainerStateProps {
  examTemplate: IExamTemplate;
}

export interface IExamTemplateQuizCreatorContainerDispatchProps {
  fetchExamTemplate: () => ITrackableAction;
  updateExamTemplate: (examTemplate: IExamTemplate) => ITrackableAction;
}

type IExamTemplateQuizCreatorContainerProps = IExamTemplateQuizCreatorContainerOwnProps & IExamTemplateQuizCreatorContainerStateProps & IExamTemplateQuizCreatorContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------

/** Container component for displaying exam template exam creator */
const ExamTemplateQuizCreatorContainer = (props: IExamTemplateQuizCreatorContainerProps) => {
  useEffect(() => {
    props.fetchExamTemplate();
  }, [props.examTemplateId]);

  const handleUpdatedExamTemplateSubmit = (examTemplateData: IExamTemplate) => {
    const newExamTemplate = { ...props.examTemplate, ...examTemplateData };
    props
      .updateExamTemplate(newExamTemplate)
      .track()
      .subscribe(() => {
        props.fetchExamTemplate();
      });
  };

  const handleQuizCollectionSubmit = (quizData: IQuiz) => {
    if (props.examTemplate) {
      const newExamTemplateCollection: IExamTemplate = { ...props.examTemplate };
      newExamTemplateCollection.collection = quizData;
      handleUpdatedExamTemplateSubmit(newExamTemplateCollection);
    }
  };
  return <React.Fragment>{props.examTemplate?.collection && <ExamTemplateQuizCreatorView examTemplate={props.examTemplate} onQuizCollectionSubmit={handleQuizCollectionSubmit} />}</React.Fragment>;
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExamTemplateQuizCreatorContainerOwnProps): IExamTemplateQuizCreatorContainerStateProps => ({
  examTemplate: examTemplateBusinessStore.selectors.getExamTemplate(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IExamTemplateQuizCreatorContainerOwnProps): IExamTemplateQuizCreatorContainerDispatchProps => ({
  fetchExamTemplate: () => createTrackableAction(dispatch(examTemplateBusinessStore.actions.fetchExamTemplate(ownProps.examTemplateId))),
  updateExamTemplate: (examTemplate: IExamTemplate) => createTrackableAction(dispatch(examTemplateBusinessStore.actions.updateExamTemplate(examTemplate))),
});

// -- HOCs and exports
// ----------
export default connect<IExamTemplateQuizCreatorContainerStateProps, IExamTemplateQuizCreatorContainerDispatchProps, IExamTemplateQuizCreatorContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(ExamTemplateQuizCreatorContainer as any)));

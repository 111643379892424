import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseListView from '@src/components/course/list/CourseListView';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ICollectionData } from '@src/service/business/common/types';
import { IPublicCourseListFilter } from '@src/service/business/courses/publicCourseListBusinessStore';
import React from 'react';

// -- Prop types
// ----------

interface IPublicCourseListViewOwnProps {
  courseList: ICollectionData<ICourse>;
  courseListFilter: IPublicCourseListFilter;
  courseGroups: ICourseGroup[];
  sortValue: string[];
  onPageChange: (page: number, size?: number) => void;
  onSortChange: (sort: string[]) => void;
  onFilterChange: (data: IPublicCourseListFilter) => void;
  getPath: (course: ICourse) => string;
}
type IPublicCourseListViewProps = IPublicCourseListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/**  */
class PublicCourseListView extends React.Component<IPublicCourseListViewProps> {
  render = () => {
    return (
      <AppContent title={this.props.translate('COURSE_VIEW_PUBLIC.TITLE')}>
        <CourseListView
          courseList={this.props.courseList}
          courseListFilter={this.props.courseListFilter}
          courseGroups={this.props.courseGroups}
          sortValue={this.props.sortValue}
          onSortChange={this.props.onSortChange}
          onPageChange={this.props.onPageChange}
          onFilterChange={this.props.onFilterChange}
          getPath={this.props.getPath}
        />
      </AppContent>
    );
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IPublicCourseListViewOwnProps>(PublicCourseListView as any);

import React from 'react';
import { connect } from 'react-redux';

import CodebookShortInfoForm from '@src/components/codebook/form/CodebookShortInfoForm';
import CodeBookListView from '@src/components/codebook/view/CodebookList';
import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserCategory } from '@src/model/user/UserCategory';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserCategoryBusinessStore, { IUserCategoryCreatePayload, IUserCategoryListFilter } from '@src/service/business/user/userCategoryBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';


// -- Const
// ----------
const VIEW_NAME = '@@USER_CATEGORY_CODEBOOK_CONTAINER';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');

// -- Prop types
// ----------

export interface IUserCategoryCodebookContainerOwnProps {
}
export interface IUserCategoryCodebookContainerStateProps {
  userCategoryList: ICollectionData<IUserCategory>;
}
export interface IUserCategoryCodebookContainerDispatchProps {
  updateUserCategory: (data: IUserCategory) => ITrackableAction;
  createUserCategory: (data: IUserCategoryCreatePayload) => ITrackableAction;
  fetchUserCategoryList: (params: ICollectionFetchPayload<IUserCategoryListFilter>) => void;
}
type IUserCategoryCodebookContainerProps = IUserCategoryCodebookContainerOwnProps & IUserCategoryCodebookContainerStateProps & IUserCategoryCodebookContainerDispatchProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const UserCategoryCodebookContainer = (props: IUserCategoryCodebookContainerProps) => {
  const [userCategoryModalsState, toggleUserCategoryModals] = useEntityModalsState<IUserCategory>();
  const [, updateCollectionParams, onUpdateList] = useCollectionState<IUserCategoryListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchUserCategoryList,
    initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
  });
  const handleUpdate = (data: IUserCategory) => {
    props.updateUserCategory(data).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleUserCategoryModals.onCloseUpdate();
      }
    );
  };
  const handleCreate = (data: IUserCategoryCreatePayload) => {
    props.createUserCategory(data).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleUserCategoryModals.onCloseCreate();
      }
    );
  };
  return (
    <React.Fragment>
      {props.userCategoryList && <CodeBookListView<IUserCategory>
        showBackColumn={true}
        title={props.translate(`CODEBOOK.TITLE_LABEL.${CodebookEnum.USER_CATEGORY}`)}
        buttonLabel={props.translate('USER_CATEGORY.CREATE_BUTTON_LABEL')}
        codebookItemList={props.userCategoryList}
        canEdit={true}
        onEditCodebookItemClick={toggleUserCategoryModals.onOpenUpdate}
        onCreateCodebookItemClick={toggleUserCategoryModals.onOpenCreate}
        onPageChange={updateCollectionParams.onPageChange}
      />}
      {(userCategoryModalsState.isCreateModalVisible || userCategoryModalsState.isUpdateModalVisible) && <CodebookShortInfoForm<IUserCategory, IUserCategoryCreatePayload>
        value={userCategoryModalsState.selectedEntity}
        onCancel={toggleUserCategoryModals.onCloseCreate}
        onUpdate={handleUpdate}
        onCreate={handleCreate} />}
    </React.Fragment>
  );

};


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserCategoryCodebookContainerOwnProps): IUserCategoryCodebookContainerStateProps => ({
  userCategoryList: UserCategoryBusinessStore.selectors.getUserCategoryList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserCategoryCodebookContainerDispatchProps => ({
  updateUserCategory: (data: IUserCategory) => createTrackableAction(dispatch(UserCategoryBusinessStore.actions.updateUserCategory(data))),
  createUserCategory: (data: IUserCategoryCreatePayload) => createTrackableAction(dispatch(UserCategoryBusinessStore.actions.createUserCategory(data))),
  fetchUserCategoryList: (params: ICollectionFetchPayload<IUserCategoryListFilter>) => dispatch(UserCategoryBusinessStore.actions.fetchUserCategoryList(params)),
});


export default connect<IUserCategoryCodebookContainerStateProps, IUserCategoryCodebookContainerDispatchProps, IUserCategoryCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(UserCategoryCodebookContainer as any));

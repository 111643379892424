import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseSkillListView from '@src/components/course/skillList/CourseSkillListView';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import courseUpdateBusinessStore from '@src/service/business/courses/courseUpdateBusinessStore';
import { ISkill } from '@src/model/skillgroup/Skill';
import courseViewBusinessStore from '@src/service/business/courses/courseViewBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';
import { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import { ISkillListFilter } from '@src/service/business/skillgroup/SkillBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import CourseSkillListEditView from '@src/components/course/skillList/CourseSkillListEditView';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';

// -- Const
// ----------

const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.paging.midPageSize');
const VIEW_NAME = '@@COURSE_SKILL_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<ISkillListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
};

// -- Prop types
// ----------

export interface ICourseSkillListContainerOwnProps {
  courseId: string;
}
export interface ICourseSkillListContainerStateProps {
  courseSkillList: ICollectionData<ISkill>;
}

export interface ICourseSkillListContainerDispatchProps {
  fetchCourseSkillList: () => ITrackableAction;
  clearCourseSkillList: () => void;
  updateCourseSkillList: (data: ISkill[]) => ITrackableAction;
}
type ICourseSkillListContainerProps = ICourseSkillListContainerOwnProps & ICourseSkillListContainerStateProps & ICourseSkillListContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<ISkillListFilter>;

// -- Component
// ----------

/** Describe your component ... */
const CourseSkillListContainer = (props: ICourseSkillListContainerProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const updateGroupList = () => {
    onUpdateList(() =>
      props
        .fetchCourseSkillList()
        .track()
        .subscribe(() => setEditing(false))
    );
  };
  const [, updateCollectionParams, onUpdateList] = useCollectionState<ISkillListFilter>({ ...collectionDefaults, updateFn: updateGroupList });

  const handleUpdateSkillList = useCallback(
    (data: ISkill[]) => {
      props
        .updateCourseSkillList(data)
        .track()
        .subscribe(
          // success
          () => {
            onUpdateList();
          }
        );
    },
    [props.updateCourseSkillList, onUpdateList, setEditing]
  );
  return (
    <React.Fragment>
      {editing ? (
        <CourseSkillListEditView courseSkillList={props.courseSkillList.content} onUpdateSkillList={handleUpdateSkillList} onEditingChange={() => setEditing(false)} />
      ) : (
        <CourseSkillListView courseSkillList={props.courseSkillList.content} paginationData={props.courseSkillList.page} onPageChange={updateCollectionParams.onPageChange} onEditingChange={() => setEditing(true)} />
      )}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): ICourseSkillListContainerStateProps => ({
  courseSkillList: courseViewBusinessStore.selectors.getCourseSkillList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: ICourseSkillListContainerOwnProps): ICourseSkillListContainerDispatchProps => ({
  fetchCourseSkillList: () => createTrackableAction(dispatch(courseViewBusinessStore.actions.fetchCourseSkillList({ id: ownProps.courseId }))),
  clearCourseSkillList: () => dispatch(courseViewBusinessStore.actions.clearCourseSkillList()),
  updateCourseSkillList: (data: ISkill[]) => createTrackableAction(dispatch(courseUpdateBusinessStore.actions.updateCourseSkillList(ownProps.courseId, data))),
});

export default connect<ICourseSkillListContainerStateProps, ICourseSkillListContainerDispatchProps, ICourseSkillListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(CourseSkillListContainer as any));

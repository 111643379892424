import { QuizDisplay } from '@lamarodigital/quizzler-lib-frontend';
import { IQuizzlerUploadProps } from '@lamarodigital/quizzler-lib-frontend/components';
import { IQuestion } from '@lamarodigital/quizzler-lib-frontend/model/quiz/question/Question';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import useUserSettingsStore from '@src/components/common/usersettings/useUserSettings';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExamInstanceHelperUtils from '@src/components/exam/common/ExamInstanceHelperUtils';
import { QuizHelperUtils } from '@src/components/exam/quiz/QuizHelperUtils';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { Button, Col, Divider, Layout, Row, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// -- Const
// ----------
const { Header, Content, Footer } = Layout;
// -- Prop types
// ----------
export interface IExamInstanceFormOwnProps {
  examInstance?: IExamInstance;
  showQuestionIntroActionButtons: boolean;
  showEvaluationFeedback: boolean;
  onSubmit?: (examInstance: IExamInstance) => void;
  examListRoute: string;
}

type IExamInstanceFormProps = IExamInstanceFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
const ExamInstanceForm = (props: IExamInstanceFormProps) => {
  const [examInstance, setExamInstance] = useState<IExamInstance | undefined>();
  const [isQuizFinished, setIsQuizFinished] = useState<boolean>(false);
  const [userSettings] = useUserSettingsStore();

  useEffect(() => {
    if (props.examInstance) {
      setExamInstance(props.examInstance);
    }
  }, [props.examInstance]);

  const handleSubmit = useCallback(() => {
    if (examInstance) {
      props.onSubmit?.(examInstance);
    }
  }, [examInstance]);

  const handleIfQuizFinished = useCallback(
    (updatedExamInstance: IExamInstance) => {
      const isFinised: boolean = updatedExamInstance.collection.questions.every((question: IQuestion) => question.answerSubmission && question.answerSubmission.answers.length >= 1);
      setIsQuizFinished(isFinised);
    },
    [setIsQuizFinished]
  );

  const handleQuizValuesChange = useCallback(
    (quiz: IQuiz) => {
      const updatedExamInstance = { ...props.examInstance, collection: quiz } as IExamInstance;
      setExamInstance(updatedExamInstance);
      handleIfQuizFinished(updatedExamInstance);
    },
    [props.examInstance, setExamInstance, handleIfQuizFinished]
  );

  const uploadProps: IQuizzlerUploadProps = {
    ...FileUploadHelper.getUploadDefaultProps(),
    onChange: FileUploadHelper.handleAntdUploadChange,
    getQuizzlerFile: QuizHelperUtils.getQuizzlerFile,
  };

  const examInstanceFirstActivity = useMemo(() => props.examInstance?.activities?.slice(0, 1).shift(), [props.examInstance]);

  return (
    <React.Fragment>
      <Layout className="panel">
        <Header>
          <HeaderTitle link={props.examListRoute} title={props.examInstance?.title ?? ''} icon={<LemonIcon name="left" />} />
          <Row justify="space-between">
            <Col>
              {props.examInstance && (
                <Typography.Title level={2}>
                  {props.translate('EXAM_INSTANCE_FORM.ASSIGNEE')} {examInstanceFirstActivity && ExamInstanceHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.ASSIGNEE, examInstanceFirstActivity)}{' '}
                </Typography.Title>
              )}
            </Col>
            <Col>
              {props.examInstance?.additionalData?.totalEarnedPoints != undefined && (
                <Typography.Title level={2}> {props.translate('EXAM_INSTANCE_FORM.TOTAL_EARNED_POINTS_LABEL', { totalEarnedPoints: props.examInstance?.additionalData?.totalEarnedPoints, totalPoints: props.examInstance?.additionalData?.totalPoints })}</Typography.Title>
              )}
            </Col>
          </Row>
        </Header>
        <Divider />
        <Layout>
          <Content className="timun-examInstanceForm__content">
            <QuizDisplay
              locale={userSettings?.locale}
              appUploadProps={uploadProps}
              resolveFileUrl={QuizHelperUtils.resolveFileUrl}
              showHelpIntroActionButton={false}
              showRedoIntroActionButton={props.showQuestionIntroActionButtons}
              showEvaluationFeedback={props.showEvaluationFeedback}
              onChange={handleQuizValuesChange}
              quiz={props.examInstance?.collection}
            />
          </Content>
        </Layout>
        {props.onSubmit && (
          <React.Fragment>
            <Divider />
            <Footer>
              <Button disabled={!isQuizFinished} onClick={handleSubmit}>
                {props.translate('EXAM_INSTANCE.BUTTON_SUBMIT_LABEL')}
              </Button>
            </Footer>
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IExamInstanceFormOwnProps>(ExamInstanceForm as any);

import { Editor } from '@src/components/common/editor/Editor';
import { TimunEditorConfig } from '@src/components/common/editor/TimunEditorConfigs';
import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationFileListView from '@src/components/externalEducationApplication/common/EducationApplicationFileListView';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationFileListUpload from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFileListUpload';
import ExternalEducationApplicationFilePreview from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFilePreview';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IEducationApplicationAnnex } from '@src/model/externalEducationApplication/EducationApplicationAnnex';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ContractAnnexTypeEnum, IExternalEducationApplicationFilePreviewData } from '@src/model/externalEducationApplication/ExternalEducationApplicationFilePreviewData';
import { ExternalEducationApplicationFileTypeEnum } from '@src/model/file/FileType';
import { IFile } from '@src/model/file/File';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { IFileListsByType } from '@src/service/business/files/util';
import { ITrackableAction } from '@src/service/util/action/trackAction';
import { Button, Col, Form, Row, Select, Typography } from 'antd';

import React, { useState } from 'react';

// -- Const
// ----------
const LAYOUT_BUTTONS_1 = {
  sm: 24,
  xs: 24,
  md: 12,
};

const Option = Select.Option;

// -- Prop types
// ----------
interface IExternalEducationApplicationCoordinatorDurationViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  canParticipate?: boolean;
  isCurrentUserAdmin?: boolean;
  onUpdate?: (data: IExternalEducationApplication) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: EducationApplicationActivityPhaseEnum, save?: boolean) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onFilePreview: (externalEducationApplicationId: string, filePreviewData: IExternalEducationApplicationFilePreviewData) => ITrackableAction;
  onAnnexCreate?: (data: IEducationApplicationAnnex) => void;
}

type IExternalEducationApplicationCoordinatorDurationViewProps = IExternalEducationApplicationCoordinatorDurationViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

const ExternalEducationApplicationCoordinatorDurationView = (props: IExternalEducationApplicationCoordinatorDurationViewProps) => {
  const [form] = Form.useForm();
  const [isAnnexFormVisible, setIsAnnexFormVisible] = useState<boolean>(false);
  const [annexPreview, setAnnexPreview] = useState<string | undefined>();
  const [contractAnnexType, setContractAnnexType] = useState<ContractAnnexTypeEnum | undefined>();
  const [isAnnexPreviewVisible, setIsAnnexPreviewVisible] = useState<boolean>(false);
  const isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => EducationApplicationHelperUtils.isInPhases(phases, props.externalEducationApplication.activity);
  const handleChangePhase = (phase: EducationApplicationActivityPhaseEnum) => props.onChangePhase?.(phase);
  const handleSubmit = () => form.submit();
  const handleAnnexCreate = () => {
    form
      .validateFields()
      .then((values: IEducationApplicationAnnex) => props.onAnnexCreate?.(values))
      .catch((errorMsg: string) => console.warn(errorMsg));
  };

  const handleContractFilePreview = () => {
    props
      .onFilePreview(props.externalEducationApplication.id, { fileType: ExternalEducationApplicationFileTypeEnum.CONTRACT_ANNEX, contractType: contractAnnexType })
      .track()
      .subscribe((response) => {
        setAnnexPreview(response.content);
        setIsAnnexPreviewVisible(true);
      });
  };

  return (
    <React.Fragment>
      {/* common duration expense button */}
      {isApplicationInPhases(['EDUCATION_IN_PROGRESS', 'CONTRACT_ANNEX_REQUESTED', 'CONTRACT_ANNEX_SUBMITTED']) && (
        <Row className="timun-wrapper__gutterBox--vertical" justify="end" align="middle" gutter={[8, 8]}>
          <Col>{props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENTER_USER_TRAVEL_EXPENSE_LABEL')}</Col>
          <Col>
            <Button disabled={!props.canParticipate} type="primary" block={false} onClick={() => console.log('scrollTODO:')}>
              {/* TODO: scroll to */}
              {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENTER_USER_TRAVEL_EXPENSE_BUTTON_LABEL')}
            </Button>
          </Col>
        </Row>
      )}

      {isApplicationInPhases(['CONTRACT_ANNEX_SUBMITTED']) && (
        <React.Fragment>
          <Row>
            <Col span={24}>{props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.SIGNED_ANNEX_CONTRACT_UPLOADED_TEXT')}</Col>
          </Row>
          <GridItem label={props.translate('FILES.TYPE_ENUM.LABEL.SIGNED_CONTRACT_ANNEX')}>
            <EducationApplicationFileListView files={props.applicationFiles} displayTypes={['SIGNED_CONTRACT_ANNEX']} />
          </GridItem>

          <Typography.Paragraph> {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.MUTUALLY_SIGNED_CONTRACT_ANNEX_TEXT')} </Typography.Paragraph>

          <GridItem label={props.translate('FILES.TYPE_ENUM.LABEL.MUTUALLY_SIGNED_CONTRACT_ANNEX')} requiredMark={true}>
            <ExternalEducationApplicationFileListUpload applicationFiles={props.applicationFiles} fileType="MUTUALLY_SIGNED_CONTRACT_ANNEX" onFileUpload={props.onFileUpload} onFileRemove={props.onFileRemove} />
          </GridItem>

          <Row>
            <Col {...LAYOUT_BUTTONS_1}>
              <Button
                type="primary"
                block={false}
                onClick={() => handleChangePhase(EducationApplicationActivityPhaseEnum.EDUCATION_IN_PROGRESS)}
                disabled={!EducationApplicationHelperUtils.hasFilesInAllTypes(props.applicationFiles, ['MUTUALLY_SIGNED_CONTRACT_ANNEX']) || !props.canParticipate}
                data-test-id="timun-externalEducationApplicationView__enableEducationButton"
              >
                {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.SEND_MUTUALY_SIGNED_FINANCING_AGREEMENT_BUTTON_TEXT')}
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      )}

      {isApplicationInPhases(['EDUCATION_IN_PROGRESS']) && (
        <React.Fragment>
          <Row justify="end" align="middle" gutter={[8, 8]}>
            <Col>{props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_ANNEX_REQUEST_TEXT')}</Col>
            <Col>
              <Button disabled={!props.canParticipate && !props.isCurrentUserAdmin} block={false} onClick={() => setIsAnnexFormVisible(!isAnnexFormVisible)}>
                {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_ANNEX_REQUESTED_BUTTON_TEXT')}
              </Button>
            </Col>
          </Row>
          {isAnnexFormVisible && (
            <React.Fragment>
              <Typography.Title>{props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_ANNEX_REQUESTED_LABEL')}</Typography.Title>
              <Form form={form} name="annex" layout="vertical" onFinish={handleAnnexCreate}>
                <Form.Item label={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_ANNEX_TYPE_LABEL')} name={['contractAnnexType']} rules={[{ required: true, message: props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_ANNEX_TYPE_MESSAGE') }]} required={true}>
                  <Select onChange={setContractAnnexType} placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_ANNEX_TYPE_PLACEHOLDER')}>
                    {Object.keys(ContractAnnexTypeEnum).map((type) => (
                      <Option key={type} value={ContractAnnexTypeEnum[type as keyof typeof ContractAnnexTypeEnum]}>
                        {props.translate(`EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_ANNEX_TYPE_LABEL.${type}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {contractAnnexType === ContractAnnexTypeEnum.CUSTOM && (
                  <Form.Item label={props.translate('COMMON.ENTER_TEXT_LABEL')} name="content">
                    <Editor
                      init={{
                        ...TimunEditorConfig.defaultConfig(),
                        height: 500,
                        menubar: true,
                      }}
                    />
                  </Form.Item>
                )}
                <Row justify="start" align="middle" gutter={[8, 8]} className="timun-wrapper__gutterBox--vertical">
                  <Col>
                    <Button type="default" disabled={!contractAnnexType} onClick={handleContractFilePreview}>
                      {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_ANNEX_TEMPLATE_BUTTON_TEXT')}
                    </Button>
                  </Col>
                  <Col>
                    <Button type="primary" disabled={!props.canParticipate} onClick={handleSubmit}>
                      {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REQUESTED_SIGNED_ANNEX_BUTTON_TEXT')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {/* Preview contract modal */}
      {isAnnexPreviewVisible && annexPreview && <ExternalEducationApplicationFilePreview title={props.translate('FILES.TYPE.CONTRACT_ANEX_TEMPLATE_LABEL')} htmlFilePreview={annexPreview} onCancel={() => setIsAnnexPreviewVisible(false)} />}

      {isApplicationInPhases(['CONTRACT_ANNEX_REQUESTED']) && (
        <React.Fragment>
          <Typography.Paragraph> {props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_ANNEX_REQUESTED_TEXT')} </Typography.Paragraph>
        </React.Fragment>
      )}

      {/* common duration complete education button */}
      {isApplicationInPhases(['EDUCATION_IN_PROGRESS', 'CONTRACT_ANNEX_REQUESTED', 'CONTRACT_ANNEX_SUBMITTED']) && (
        <Row className="timun-wrapper__gutterBox--vertical" justify="end" align="middle" gutter={[8, 8]}>
          <Col>
            <NoteCreateContainer
              disabled={!props.canParticipate}
              objectId={props.externalEducationApplication.id}
              objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
              objectPublic={true}
              buttonType={'primary'}
              buttonLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_TERMINATED_BUTTON_TEXT')}
              modalTitle={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_TERMINATED_MODAL_TITLE')}
              modalOkButtonLabel={props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_TERMINATED_CONFIRM_BUTTON_TEXT')}
              onCreateNote={() => handleChangePhase(EducationApplicationActivityPhaseEnum.CONTRACT_TERMINATED)}
              hideIcon={true}
              data-test-id="timun-externalEducationApplicationView__educationCompletedButton"
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationCoordinatorDurationViewOwnProps>(ExternalEducationApplicationCoordinatorDurationView as any));

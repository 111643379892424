import React from 'react';

import { Editor } from '@src/components/common/editor/Editor';
import { TimunEditorConfig } from '@src/components/common/editor/TimunEditorConfigs';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExamTemplate } from '@src/model/education/ExamTemplate';

import { Button, Checkbox, Form, InputNumber } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

// -- Prop types
// ----------
export interface IExamTemplateFormOwnProps {
  examTemplate: IExamTemplate;
  disabled?: boolean;
  onSubmit?: (updatedExamTemplate: IExamTemplate) => void;
}

type IExamTemplateFormProps = IExamTemplateFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExamTemplateForm: React.FC<IExamTemplateFormProps> = (props: IExamTemplateFormProps) => {
  const [form] = Form.useForm<IExamTemplate>();
  const numberOfQuestions = props.examTemplate.collection?.questions?.length ?? 0;
  const handleSubmit = (values: IExamTemplate) => props.onSubmit?.(values);
  const initialValues = props.examTemplate.settings
    ? props.examTemplate
    : {
        settings: {
          numberOfQuestions,
          gradeThreshold: 0,
          answersVisible: false,
          attemptsNumber: 1,
        },
      };

  return (
    <Form<IExamTemplate> form={form} name="examTemplate" layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
      {/* Exam template Instruction text area */}
      <FormItem name="instructions" label={props.translate('EXAM_TEMPLATE.FORM_INSTRUCTION_TITLE')}>
        <Editor
          disabled={props.disabled}
          init={{
            ...TimunEditorConfig.defaultConfig(),
            height: 500,
            menubar: true,
          }}
        />
      </FormItem>

      {/* Input for number of questions in exam template settings*/}
      <FormItem shouldUpdate={true}>
        {() => {
          return (
            <FormItem
              name={['settings', 'numberOfQuestions']}
              required={true}
              requiredMark={true}
              label={props.translate('EXAM_TEMPLATE.FORM_SETTINGS_NUMBER_OF_QUESTIONS_TITLE', { numberOfQuestions })}
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value > numberOfQuestions) {
                      callback(props.translate('EXAM_TEMPLATE.FORM_SETTINGS.NUMBER_OF_QUESTIONS_LOW_VALIDATION'));
                    } else if (numberOfQuestions < 1) {
                      callback(props.translate('EXAM_TEMPLATE.FORM_SETTINGS.NUMBER_OF_QUESTIONS_NONE_VALIDATION'));
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              <InputNumber disabled={props.disabled} min={1} max={numberOfQuestions} />
            </FormItem>
          );
        }}
      </FormItem>

      {/* Minimum number of correct questions for exam to pass */}
      <FormItem name={['settings', 'gradeThreshold']} label={props.translate('EXAM_TEMPLATE.FORM_SETTINGS_GRADE_THRESHOLD_TITLE')}>
        <InputNumber disabled={props.disabled} step={10} addonAfter={'%'} precision={2} min={0} max={100} />
      </FormItem>

      {/* Checkbox for displaying answers in exam template settings */}
      <FormItem name={['settings', 'answersVisible']} label={props.translate('EXAM_TEMPLATE.FORM_SETTINGS_ANSWERS_VISIBLE_TITLE')} valuePropName="checked">
        <Checkbox disabled={props.disabled} />
      </FormItem>

      {/* Checkbox for setting evaluation phase in exam template settings */}
      <FormItem name={['settings', 'skipEvaluationPhase']} label={props.translate('EXAM_TEMPLATE.FORM_SETTINGS_SKIP_EVALUATION_TITLE')} valuePropName="checked">
        <Checkbox disabled={props.disabled} />
      </FormItem>

      {/* Enable random question order */}
      <FormItem name={['settings', 'randomizeQuestionOrder']} label={props.translate('EXAM_TEMPLATE.FORM_SETTINGS_RANDOM_QUESTION_ORDER_TITLE')} valuePropName="checked">
        <Checkbox disabled={props.disabled} />
      </FormItem>

      {/* Enable mulltiple exam solving */}
      <FormItem name={['settings', 'allowExamRevision']} label={props.translate('EXAM_TEMPLATE.FORM_SETTINGS_ALLOW_EXAM_REVISION_TITLE')} valuePropName="checked">
        <Checkbox disabled={props.disabled} />
      </FormItem>

      {/* Minimum number of exam attempts */}
      <FormItem noStyle={true} shouldUpdate={(prevValues, curValues) => prevValues.settings?.allowExamRevision !== curValues.settings?.allowExamRevision}>
        {({ getFieldValue }) =>
          getFieldValue(['settings', 'allowExamRevision']) === true ? (
            <Form.Item name={['settings', 'attemptsNumber']} label={props.translate('EXAM_TEMPLATE.FORM_SETTINGS_ATTEMPTS_NUMBER_TITLE')} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
              <InputNumber disabled={props.disabled} min={1} />
            </Form.Item>
          ) : (
            <Form.Item hidden={true} noStyle={true} name={['settings', 'attemptsNumber']}>
              <InputNumber disabled={props.disabled} />
            </Form.Item>
          )
        }
      </FormItem>
      <FormItem>
        <Button disabled={props.disabled} type="primary" onClick={form.submit}>
          {props.translate('COMMON.ACTION_SAVE')}
        </Button>
      </FormItem>
    </Form>
  );
};

export default withLocalize<IExamTemplateFormOwnProps>(ExamTemplateForm as any);

import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { ISkillLevelClassification } from '@src/model/skillgroup/SkillLevelClassification';
import { IUserDetails } from '@src/model/user/UserDetails';

export interface IUserSkillEvaluation {
  id: string;
  userSkillEvaluationType: ICodeBookEntry<UserSkillEvaluationTypeEnum>;
  user: IUserDetails;
  evaluatedBy: IUserDetails;
  skillLevelClassification: ISkillLevelClassification;
  createdDateTime: string;
  updatedDateTime: string;
}

export enum UserSkillEvaluationTypeEnum {
  PEER = '1',
  HUMAN_MANAGER = '2',
  MANAGER = '3',
  SELF = '4',
}

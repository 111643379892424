import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';

export interface IExternalEducationExpense {
  expenseType: ICodeBookEntry<ExpeneseTypeEnum>;
  externalEducationApplication: IExternalEducationApplication;
  id: string;
  paymentDate: string;
  value: number;
}

export enum ExpeneseTypeEnum {
  EDUCATION_OFFER_PRICE = '1',
  TRAVEL_EXPENSE = '2',
  CO_FINANCED_EDUCATION_PRICE = '3',
}

/**
 * Helper methods for URL parsing
 *
 */
const httpsPrefix = 'https://';
export const formatUrl = (url: string) => {
  // add https to input url
  return httpsPrefix + url;
};

export const parseUrl = (url?: string) => {
  // remove https from url
  return url?.replace(httpsPrefix, '');
};

import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IAddress } from '@src/model/user/Address';

export interface IExternalEducationInstance {
  id: string;
  description: string;
  executionMethod: ICodeBookEntry<ExecutionMethodEnum>;
  addresses: IAddress[];
  status: ICodeBookEntry<ExternalEducationInstanceStatusEnum>;
  externalEducationTemplate: IExternalEducationTemplate;
}

export enum ExecutionMethodEnum {
  ONLINE = '1',
  EDUCATION_PROVIDER = '2',
  EMPLOYER = '3',
  COMBINED = '4',
}

export enum ExternalEducationInstanceStatusEnum {
  IN_CREATION = '1',
  CREATION_FINISHED = '2',
  PUBLISHED = '3',
  ARCHIVED = '4',
}

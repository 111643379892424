import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICollectionDataCount } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Pagination } from 'antd';
import React from 'react';

// -- Const
// ----------
const MAX_PAGE_SIZE = AppConfigService.getValue('api.paging.maxPageSize');

// -- PropTypes
// ----------
export enum ListPaginationTypeEnum {
  NUMERIC = 'numeric',
  LOAD_MORE = 'loadMore',
}

export interface IListPaginationOwnProps {
  paginationType?: ListPaginationTypeEnum;
  page: ICollectionDataCount;
  showSizeChanger?: boolean;
  onChange?: (page: number, pageSize?: number) => void;
  onLoadMore?: () => void;
}

type IListPaginationProps = IListPaginationOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
class ListPagination extends React.Component<IListPaginationProps> {
  renderPaginationByType = () => {
    const allElementsLoaded = this.props.page.size >= this.props.page.totalElements;
    const onlySinglePage = this.props.page.totalPages === 1;

    // paging not required
    if (onlySinglePage) {
      return null;
    }

    if (this.props.paginationType === ListPaginationTypeEnum.LOAD_MORE) {
      return !allElementsLoaded ? (
        <Button disabled={this.props.page.size === MAX_PAGE_SIZE} onClick={() => this.props.onLoadMore && this.props.onLoadMore()}>
          {this.props.translate('COMMON.ACTION_LOAD_MORE_ITEMS')}
        </Button>
      ) : null;
    } else {
      return <Pagination total={this.props.page.totalElements} pageSize={this.props.page.size || 0} current={this.props.page.number + 1} showSizeChanger={this.props.showSizeChanger} hideOnSinglePage={true} onChange={this.props.onChange} onShowSizeChange={this.props.onChange} data-test-id="timun-pagination" />;
    }
  };

  render() {
    const pagination = this.renderPaginationByType();

    if (pagination != null) {
      return (
        <div className="timun-pagination__container" data-test-id="timun-pagination__container">
          {pagination}
        </div>
      );
    } else {
      return null;
    }
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<IListPaginationOwnProps>(ListPagination as any);

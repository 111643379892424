import React from 'react';

import EducationCategoryCheckboxDataPicker from '@src/components/common/datapicker/EducationCategoryCheckboxDataPicker';
import EducationSectorDataPicker from '@src/components/common/datapicker/EducationSectorDataPicker';
import EnrollmentRequirementDataPicker from '@src/components/common/datapicker/EnrollmentRequirementDataPicker';
import SkillTreePicker from '@src/components/common/datapicker/SkillTreePicker';
import { Editor } from '@src/components/common/editor/Editor';
import { TimunEditorConfig } from '@src/components/common/editor/TimunEditorConfigs';
import FormDataItemList from '@src/components/common/form/FormDataItemList';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import FormObjectField from '@src/components/common/form/FormObjectField';
import CurrencyInput from '@src/components/common/input/CurrencyInput';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { ExternalEducationTemplateHelperUtils } from '@src/components/externaleducation/common/ExternalEducationTemplateHelperUtils';
import { ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { ExternalEducationTemplateStatusEnum, IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { UserRoleEnum } from '@src/model/user/UserRole';
import AppConfigService from '@src/service/common/AppConfigService';
import { ActivityUtils } from '@src/service/util/activity/ActivityUtils';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Col, Form, FormInstance, Input, Radio, Row } from 'antd';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.essayCharacterLengthInput');
const INPUT_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const MIN_INPUT_NUMBER = 1;

// -- Prop types
// ----------

export interface IExternalEducationTemplateFormOwnProps {
  value?: IExternalEducationTemplate;
  onSubmit?: (data: IExternalEducationTemplate) => void;
  formRef?: FormInstance<IExternalEducationTemplate>;
  disabled?: boolean;
}
type IExternalEducationTemplateFormProps = IExternalEducationTemplateFormOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Display external education template form */
const ExternalEducationTemplateForm: React.FC<IExternalEducationTemplateFormProps> = (props: IExternalEducationTemplateFormProps) => {
  const [form] = Form.useForm<IExternalEducationTemplate>(props.formRef);

  const showMaxVoucherField = props.value?.activity && RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && ActivityUtils.isInPhases([ExternalEducationTemplateActivityPhaseEnum.APPROVAL_REQUESTED, ExternalEducationTemplateActivityPhaseEnum.APPROVED], props.value.activity);
  const isPublished = props.value && ExternalEducationTemplateHelperUtils.isEducationTemplateInStatus(props.value, ['PUBLISHED']);
  const isInCreation = props.value && ExternalEducationTemplateHelperUtils.isEducationTemplateInStatus(props.value, ['IN_CREATION']);
  const isCreationFinshed = props.value && ExternalEducationTemplateHelperUtils.isEducationTemplateInStatus(props.value, ['CREATION_FINISHED']);

  return (
    <Form<IExternalEducationTemplate> initialValues={props.value} layout="vertical" form={form} name="externalEducationTemplate" onFinish={props.onSubmit}>
      <Form.Item noStyle={true} hidden={true} name={'educationProvider'}>
        <Input />
      </Form.Item>

      <Form.Item noStyle={true} hidden={true} name={['id']}>
        <Input />
      </Form.Item>

      <Form.Item
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.TITLE_LABEL')} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
        name={['title']}
        rules={[
          { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
        ]}
      >
        <Input disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.TITLE_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.SKILLS_LABEL')} required={true}>
        <FormDataItemList disabled={props.disabled} formRef={form} initialCount={1} min={1} name={'skills'}>
          <SkillTreePicker />
        </FormDataItemList>
      </Form.Item>

      <Form.Item
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.DESCRIPTION_LABEL')} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
        name={['description']}
        rules={[
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
        ]}
      >
        <Editor
          init={{
            ...TimunEditorConfig.shortConfig(),
            height: 300,
            menubar: false,
          }}
          disabled={props.disabled}
          placeholder={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.DESCRIPTION_PLACEHOLDER')}
        />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_CATEGORY_LABEL')} name={['educationCategories']}>
        <EducationCategoryCheckboxDataPicker disabled={props.disabled} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_SECTOR_LABEL')} name={['educationSector']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <EducationSectorDataPicker disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_SECTOR_PLACEHOLDER')} />
      </Form.Item>

      <Row gutter={26}>
        <Col xs={24} md={6}>
          <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.DURATION_LABEL')} name={['duration']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
            <Input disabled={props.disabled} min={MIN_INPUT_NUMBER} type="number" placeholder={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_DURATION_PLACEHOLDER')} addonAfter={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_DURATION_SUFFIX')} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={26}>
        <Col xs={24} md={6}>
          <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.AVERAGE_PRICE_LABEL')} name={['averagePrice']}>
            <CurrencyInput disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.AVERAGE_PRICE_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        {showMaxVoucherField && (
          <Col xs={24} md={6}>
            <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.MAX_VOUCHER_PRICE_LABEL')} name={['maxVoucherPrice']}>
              <CurrencyInput disabled={props.disabled} placeholder={props.translate('COMMON.LABEL.MAXIMUM')} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_OUTCOMES_LABEL')} required={true}>
        <FormDataItemList disabled={props.disabled} formRef={form} initialCount={1} min={1} name={'educationOutcomes'}>
          {(field) => (
            <FormObjectField
              objectNamePath={[field.name]}
              valuePropPath={['title']}
              rules={[
                { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
              ]}
            >
              <Input placeholder={props.translate('EDUCATION_OUTCOME.PICKER.OUTCOME_PLACEHOLDER')} disabled={props.disabled || form.getFieldValue(['educationOutcomes', field.name, 'id']) != null} />
            </FormObjectField>
          )}
        </FormDataItemList>
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.ENROLLMENT_REQUIREMENTS_LABEL')} required={true}>
        <FormDataItemList disabled={props.disabled} formRef={form} initialCount={1} min={1} name="enrollmentRequirements">
          <EnrollmentRequirementDataPicker disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.ENROLLMENT_REQUIREMENTS_PLACEHOLDER')} />
        </FormDataItemList>
      </Form.Item>

      <Form.Item
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.CERTIFICATE_NAME_LABEL')} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
        name={'certificateName'}
        rules={[
          { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
        ]}
      >
        <Input disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.CERTIFICATE_NAME_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item
        label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.CONTRACTOR_QUALIFICATION_LABEL')}
        name={'contractorQualification'}
        rules={[
          { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
        ]}
      >
        <Input disabled={props.disabled} placeholder={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.CONTRACTOR_QUALIFICATION_PLACEHOLDER')} />
      </Form.Item>

      {props.value && (
        <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.STATUS_LABEL')} hidden={!RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN], props.currentUser.roles)} noStyle={!RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN], props.currentUser.roles)} name={['status', 'id']}>
          <Radio.Group>
            <Radio.Button disabled={isInCreation || isCreationFinshed} value={ExternalEducationTemplateStatusEnum.PUBLISHED}>
              {props.translate('COMMON.STATUS_LABEL.PUBLISHED')}
            </Radio.Button>
            <Radio.Button disabled={!isPublished} value={ExternalEducationTemplateStatusEnum.ARCHIVED}>
              {props.translate('COMMON.STATUS_LABEL.ARCHIVED')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      )}
    </Form>
  );
};

export default withLocalize<IExternalEducationTemplateFormOwnProps>(withRoles(ExternalEducationTemplateForm as any));

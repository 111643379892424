import React from 'react';
import { connect } from 'react-redux';

import CoursesTreeModalContainer from '@src/components/administration/common/CoursesTreeModalContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { ICourse } from '@src/model/course/Course';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ICollectionData } from '@src/service/business/common/types';
import workPositionBusinessStore from '@src/service/business/workpositions/workPositionBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface IWorkPositionCoursesTreeModalContainerOwnProps {
  workPosition: IWorkPosition;
  onSubmit: (data: Array<IIdRef<string>>) => void;
  onCloseModal: () => void;
}

export interface IWorkPositionCoursesTreeModalContainerDispatchProps {
  fetchWorkPositionCourseList: (id: string) => ITrackableAction;
  clearWorkPositionCourseList: () => void;
}

export interface IWorkPositionCoursesTreeModalContainerStateProps {
  workPositionCourseList: ICollectionData<ICourse>;
}

export interface IWorkPositionCoursesTreeModalContainerState {
}
type IWorkPositionCoursesTreeModalContainerProps = IWorkPositionCoursesTreeModalContainerOwnProps & IWorkPositionCoursesTreeModalContainerDispatchProps & IWorkPositionCoursesTreeModalContainerStateProps & IWithLocalizeOwnProps;

// -- State types
// ----------

// -- Component
// ----------

/** Component for selecting courses for a workposition */
class WorkPositionCoursesTreeModalContainer extends React.Component<IWorkPositionCoursesTreeModalContainerProps, IWorkPositionCoursesTreeModalContainerState> {
  componentDidMount = () => {
    this.props.fetchWorkPositionCourseList(this.props.workPosition.id);
  };

  componentDidUpdate = (prevProps: IWorkPositionCoursesTreeModalContainerProps, prevState: IWorkPositionCoursesTreeModalContainerState) => {
    if (this.props.workPosition.id !== prevProps.workPosition.id) {
      this.props.fetchWorkPositionCourseList(this.props.workPosition.id);
    }
  };

  componentWillUnmount = () => {
    this.props.clearWorkPositionCourseList();
  };

  render = () => {
    return (
      <React.Fragment>
        {this.props.workPositionCourseList && (
          <CoursesTreeModalContainer
            onCloseModal={this.props.onCloseModal}
            assignedCourses={this.props.workPositionCourseList.content}
            onSubmit={this.props.onSubmit}
            isPicker={false}
            modalTitle={this.props.workPosition.name}
          />
        )}
      </React.Fragment>
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IWorkPositionCoursesTreeModalContainerOwnProps): IWorkPositionCoursesTreeModalContainerStateProps => ({
  workPositionCourseList: workPositionBusinessStore.selectors.getWorkPositionCourseList(state),
});

const mapDispatchToProps = (dispatch: any): IWorkPositionCoursesTreeModalContainerDispatchProps => ({
  fetchWorkPositionCourseList: (id: string) => createTrackableAction(dispatch(workPositionBusinessStore.actions.fetchWorkPositionCourseList({ id }))),
  clearWorkPositionCourseList: () => dispatch(workPositionBusinessStore.actions.clearWorkPositionCourseList()),
});

export default connect<IWorkPositionCoursesTreeModalContainerStateProps, IWorkPositionCoursesTreeModalContainerDispatchProps, IWorkPositionCoursesTreeModalContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(WorkPositionCoursesTreeModalContainer as any));

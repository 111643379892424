import codedGradeBusinessStore from '@src/service/business/codedgrade/codedGradeBusinessStore';
import CommentsBusinessStore from '@src/service/business/comment/commentsBusinessStore';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import CollectionParamsBusinessStore from '@src/service/business/common/collectionParamsBusinessStore';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ILemonAction } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import CompanyListBusinessStore from '@src/service/business/company/CompanyListBusinessStore';
import CourseGroupBusinessStore from '@src/service/business/coursegroups/courseGroupBusinessStore';
import AdditionalCourseListBusinessStore from '@src/service/business/courses/additionalCourseListBusinessStore';
import CourseListBusinessStore from '@src/service/business/courses/courseListBusinessStore';
import courseUpdateBusinessStore from '@src/service/business/courses/courseUpdateBusinessStore';
import courseViewBusinessStore from '@src/service/business/courses/courseViewBusinessStore';
import PublicCourseListBusinessStore from '@src/service/business/courses/publicCourseListBusinessStore';
// TODO: add redux dev tools?
import PublicCourseViewBusinessStore from '@src/service/business/courses/publicCourseViewBusinessStore';
import ScormCourseViewBusinessStore from '@src/service/business/courses/scormCourseViewBusinessStore';
import UsersCoursesUpdateBusinessStore from '@src/service/business/courses/UsersCoursesUpdateBusinessStore';
import EducationCategoryBusinessStore from '@src/service/business/educationcategory/educationCategoryBusinessStore';
import educationGroupBusinessStore from '@src/service/business/educationgroups/educationGroupBusinessStore';
import EducationQualificationBusinessStore from '@src/service/business/educationqualification/EducationQualificationBusinessStore';
import EducationSectorBusinessStore from '@src/service/business/educationsector/educationSectorBusinessStore';
import EmailTemplateBusinessStore from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';
import EnrollmentRequirementBusinessStore from '@src/service/business/enrollmentrequirements/enrollmentRequirementBusinessStore';
import activityBusinessStore from '@src/service/business/examtemplates/activityBusinessStore';
import examInstanceBusinessStore from '@src/service/business/examtemplates/examInstanceBusinessStore';
import examTemplateBusinessStore from '@src/service/business/examtemplates/examTemplateBusinessStore';
import ExternalContentListBusinessStore from '@src/service/business/externalcontent/ExternalContentListBusinessStore';
import ExternalContentViewBusinessStore from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import ExternalEducationApplicationBusinessStore from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import ExternalEducationApplicationTimelineListBusinessStore from '@src/service/business/externalEducationApplication/ExternalEducationApplicationTimelineListBusinessStore';
import ExternalEducationInstanceBusinessStore from '@src/service/business/externaleducations/ExternalEducationInstanceBusinessStore';
import { ExternalEducationInstanceListBusinessStore } from '@src/service/business/externaleducations/ExternalEducationInstanceListBusinessStore';
import ExternalEducationTemplateBusinessStore from '@src/service/business/externaleducations/ExternalEducationTemplateBusinessStore';
import ExternalEducationTemplateListBusinessStore from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import ExternalEducationTemplateTimelineListBusinessStore from '@src/service/business/externaleducations/ExternalEducationTemplateTimelineBusinessStore';
import ExternalEducationOutcomeListBusinessStore from '@src/service/business/externaleducations/outcomes/ExternalEducationOutcomeListBusinessStore';
import FileListBusinessStore from '@src/service/business/files/FileListBusinessStore';
import IntegrationVideoBusinessStore from '@src/service/business/integrationvideo/IntegrationVideoBusinessStore';
import LocationBusinessStore from '@src/service/business/location/LocationBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import notificationListBusinessStore from '@src/service/business/notification/notificationListBusinessStore';
import notificationViewBusinessStore from '@src/service/business/notification/notificationViewBusinessStore';
import OrganizationBusinessStore from '@src/service/business/organization/OrganizationBusinessStore';
import OrganizationListBusinessStore from '@src/service/business/organization/OrganizationListBusinessStore';
import QuestionBusinessStore from '@src/service/business/quiz/questionBusinessStore';
import questionOutcomeListBusinessStore from '@src/service/business/quiz/questionOutcomeListBusinessStore';
import QuizCollectionBusinessStore from '@src/service/business/quiz/quizCollectionBusinessStore';
import ReminderBusinessStore from '@src/service/business/reminder/ReminderBusinessStore';
import ReminderListBusinessStore from '@src/service/business/reminder/reminderListBusinessStore';
import ReportsBusinessStore from '@src/service/business/reports/reportsBusinessStore';
import SkillBasedRecommendationsBusinessStore from '@src/service/business/skillbasedrecommendations/SkillBasedRecommendationsBusinessStore';
import SkillBusinessStore from '@src/service/business/skillgroup/SkillBusinessStore';
import SkillGroupBusinessStore from '@src/service/business/skillgroup/SkillGroupBusinessStore';
import SkillLevelBusinessStore from '@src/service/business/skilllevel/SkillLevelBusinessStore';
import SkillLevelClassificationListBusinessStore from '@src/service/business/skilllevelclassification/SkillLevelClassificationBusinessStore';
import PublicSurveyInstanceBusinessStore from '@src/service/business/survey/PublicSurveyInstanceBusinessStore';
import SurveyInstanceBusinessStore from '@src/service/business/survey/SurveyInstanceBusinessStore';
import SurveyTemplateBusinessStore from '@src/service/business/survey/SurveyTemplateBusinessStore';
import SurveyTemplateListBusinessStore from '@src/service/business/survey/SurveyTemplateListBusinessStore';
import CacheListBusinessStore from '@src/service/business/system/CacheListBusinessStore';
import TagBusinessStore from '@src/service/business/tag/TagBusinessStore';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';
import TenantConfigurationBusinessStore from '@src/service/business/tenant/tenantConfigurationBusinessService';
import TenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/tenantOrganizationConfigurationBussinessService';
import userActivityListBusinessStore from '@src/service/business/user/userActivityListBusinessStore';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import UserCategoryBusinessStore from '@src/service/business/user/userCategoryBusinessStore';
import UserListBusinessStore from '@src/service/business/user/userListBusinessStore';
import userSkillEvaluationBusinessStore from '@src/service/business/user/userSkillEvaluationBusinessStore';
import userSkillEvaluationListBusinessStore from '@src/service/business/user/userSkillEvaluationListBusinessStore';
import UserGroupBusinessStore from '@src/service/business/usergroup/UserGroupBusinessStore';
import UserGroupListBusinessStore from '@src/service/business/usergroup/UserGroupListBusinessStore';
import UserGroupReferencedObjectsBusinessStore from '@src/service/business/usergroup/UserGroupReferencedObjectsBusinessStore';
import UserNoteBusinessStore from '@src/service/business/usernotes/userNoteBusinessStore';
import UserSettingsBusinessStore from '@src/service/business/usersettings/userSettingsBusinessStore';
import WebinarListBusinessStore from '@src/service/business/webinars/webinarListBusinessStore';
import webinarViewBusinessStore from '@src/service/business/webinars/webinarViewBusinessStore';
import workPositionBusinessStore from '@src/service/business/workpositions/workPositionBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createStorage } from '@src/service/util/persist/storage';
import { PersistStorageType } from '@src/service/util/persist/types';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import { CityListBusinessStore } from '@src/service/business/city/cityBussinesStore';
import { ProgressTrackerBusinessStore } from '@src/service/business/progresstracker/ProgressTrackerBuisnessStore';
import ProgressTrackerBuisnessStore from '@src/service/business/progresstracker/ProgressTrackerListBuisnessStore';

// list of all applied middlewares
const storeMiddleware = [];

const epicMiddleware = createEpicMiddleware();
storeMiddleware.push(epicMiddleware);

const rootEpic = combineEpics(
  // TODO: fix typings - forcing epis to "any" type is just a quick fix because redux'es Action is not assignable to our own IPayloadAction
  ...Object.keys(PublicTenantConfigurationBusinessStore.effects).map((key) => (PublicTenantConfigurationBusinessStore.effects as any)[key]),
  ...Object.keys(TenantConfigurationBusinessStore.effects).map((key) => (TenantConfigurationBusinessStore.effects as any)[key]),
  ...Object.keys(CollectionBusinessStore.effects).map((key) => (CollectionBusinessStore.effects as any)[key]),
  ...Object.keys(UserFeedbackBusinessStore.effects).map((key) => (UserFeedbackBusinessStore.effects as any)[key]),
  ...Object.keys(TenantOrganizationConfigurationBusinessStore.effects).map((key) => (TenantOrganizationConfigurationBusinessStore.effects as any)[key]),
  ...Object.keys(PublicTenantOrganizationConfigurationBusinessStore.effects).map((key) => (PublicTenantOrganizationConfigurationBusinessStore.effects as any)[key]),
  ...Object.keys(CacheListBusinessStore.effects).map((key) => (CacheListBusinessStore.effects as any)[key]),

  ...Object.keys(PublicCourseListBusinessStore.effects).map((key) => (PublicCourseListBusinessStore.effects as any)[key]),
  ...Object.keys(LoginBusinessStore.effects).map((key) => (LoginBusinessStore.effects as any)[key]),
  ...Object.keys(CourseListBusinessStore.effects).map((key) => (CourseListBusinessStore.effects as any)[key]),
  ...Object.keys(courseViewBusinessStore.effects).map((key) => (courseViewBusinessStore.effects as any)[key]),
  ...Object.keys(UserBusinessStore.effects).map((key) => (UserBusinessStore.effects as any)[key]),
  ...Object.keys(courseUpdateBusinessStore.effects).map((key) => (courseUpdateBusinessStore.effects as any)[key]),
  ...Object.keys(UserListBusinessStore.effects).map((key) => (UserListBusinessStore.effects as any)[key]),
  ...Object.keys(UsersCoursesUpdateBusinessStore.effects).map((key) => (UsersCoursesUpdateBusinessStore.effects as any)[key]),
  ...Object.keys(workPositionBusinessStore.effects).map((key) => (workPositionBusinessStore.effects as any)[key]),
  ...Object.keys(ReportsBusinessStore.effects).map((key) => (ReportsBusinessStore.effects as any)[key]),
  ...Object.keys(CourseGroupBusinessStore.effects).map((key) => (CourseGroupBusinessStore.effects as any)[key]),
  ...Object.keys(CommentsBusinessStore.effects).map((key) => (CommentsBusinessStore.effects as any)[key]),
  ...Object.keys(UserSettingsBusinessStore.effects).map((key) => (UserSettingsBusinessStore.effects as any)[key]),
  ...Object.keys(QuizCollectionBusinessStore.effects).map((key) => (QuizCollectionBusinessStore.effects as any)[key]),
  ...Object.keys(QuestionBusinessStore.effects).map((key) => (QuestionBusinessStore.effects as any)[key]),
  ...Object.keys(educationGroupBusinessStore.effects).map((key) => (educationGroupBusinessStore.effects as any)[key]),
  ...Object.keys(examTemplateBusinessStore.effects).map((key) => (examTemplateBusinessStore.effects as any)[key]),
  ...Object.keys(codedGradeBusinessStore.effects).map((key) => (codedGradeBusinessStore.effects as any)[key]),
  ...Object.keys(UserNoteBusinessStore.effects).map((key) => (UserNoteBusinessStore.effects as any)[key]),
  ...Object.keys(ReminderBusinessStore.effects).map((key) => (ReminderBusinessStore.effects as any)[key]),
  ...Object.keys(WebinarListBusinessStore.effects).map((key) => (WebinarListBusinessStore.effects as any)[key]),
  ...Object.keys(webinarViewBusinessStore.effects).map((key) => (webinarViewBusinessStore.effects as any)[key]),
  ...Object.keys(ReminderListBusinessStore.effects).map((key) => (ReminderListBusinessStore.effects as any)[key]),
  ...Object.keys(notificationListBusinessStore.effects).map((key) => (notificationListBusinessStore.effects as any)[key]),
  ...Object.keys(userActivityListBusinessStore.effects).map((key) => (userActivityListBusinessStore.effects as any)[key]),
  ...Object.keys(FileListBusinessStore.effects).map((key) => (FileListBusinessStore.effects as any)[key]),
  ...Object.keys(notificationViewBusinessStore.effects).map((key) => (notificationViewBusinessStore.effects as any)[key]),
  ...Object.keys(TagBusinessStore.effects).map((key) => (TagBusinessStore.effects as any)[key]),
  ...Object.keys(examInstanceBusinessStore.effects).map((key) => (examInstanceBusinessStore.effects as any)[key]),
  ...Object.keys(activityBusinessStore.effects).map((key) => (activityBusinessStore.effects as any)[key]),
  ...Object.keys(PublicCourseViewBusinessStore.effects).map((key) => (PublicCourseViewBusinessStore.effects as any)[key]),
  ...Object.keys(AdditionalCourseListBusinessStore.effects).map((key) => (AdditionalCourseListBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalContentListBusinessStore.effects).map((key) => (ExternalContentListBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalContentViewBusinessStore.effects).map((key) => (ExternalContentViewBusinessStore.effects as any)[key]),
  ...Object.keys(EmailTemplateBusinessStore.effects).map((key) => (EmailTemplateBusinessStore.effects as any)[key]),
  ...Object.keys(UserGroupBusinessStore.effects).map((key) => (UserGroupBusinessStore.effects as any)[key]),
  ...Object.keys(UserGroupListBusinessStore.effects).map((key) => (UserGroupListBusinessStore.effects as any)[key]),
  ...Object.keys(UserGroupReferencedObjectsBusinessStore.effects).map((key) => (UserGroupReferencedObjectsBusinessStore.effects as any)[key]),
  ...Object.keys(SkillGroupBusinessStore.effects).map((key) => (SkillGroupBusinessStore.effects as any)[key]),
  ...Object.keys(SkillBusinessStore.effects).map((key) => (SkillBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalEducationApplicationBusinessStore.effects).map((key) => (ExternalEducationApplicationBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalEducationTemplateBusinessStore.effects).map((key) => (ExternalEducationTemplateBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalEducationTemplateListBusinessStore.effects).map((key) => (ExternalEducationTemplateListBusinessStore.effects as any)[key]),
  ...Object.keys(CompanyListBusinessStore.effects).map((key) => (CompanyListBusinessStore.effects as any)[key]),
  ...Object.keys(SurveyTemplateBusinessStore.effects).map((key) => (SurveyTemplateBusinessStore.effects as any)[key]),
  ...Object.keys(SurveyTemplateListBusinessStore.effects).map((key) => (SurveyTemplateListBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalEducationOutcomeListBusinessStore.effects).map((key) => (ExternalEducationOutcomeListBusinessStore.effects as any)[key]),
  ...Object.keys(SurveyTemplateListBusinessStore.effects).map((key) => (SurveyTemplateListBusinessStore.effects as any)[key]),
  ...Object.keys(PublicSurveyInstanceBusinessStore.effects).map((key) => (PublicSurveyInstanceBusinessStore.effects as any)[key]),
  ...Object.keys(SurveyInstanceBusinessStore.effects).map((key) => (SurveyInstanceBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalEducationApplicationTimelineListBusinessStore.effects).map((key) => (ExternalEducationApplicationTimelineListBusinessStore.effects as any)[key]),
  ...Object.keys(EducationQualificationBusinessStore.effects).map((key) => (EducationQualificationBusinessStore.effects as any)[key]),
  ...Object.keys(LocationBusinessStore.effects).map((key) => (LocationBusinessStore.effects as any)[key]),
  ...Object.keys(IntegrationVideoBusinessStore.effects).map((key) => (IntegrationVideoBusinessStore.effects as any)[key]),
  ...Object.keys(SkillLevelClassificationListBusinessStore.effects).map((key) => (SkillLevelClassificationListBusinessStore.effects as any)[key]),
  ...Object.keys(userSkillEvaluationListBusinessStore.effects).map((key) => (userSkillEvaluationListBusinessStore.effects as any)[key]),
  ...Object.keys(userSkillEvaluationBusinessStore.effects).map((key) => (userSkillEvaluationBusinessStore.effects as any)[key]),
  ...Object.keys(OrganizationBusinessStore.effects).map((key) => (OrganizationBusinessStore.effects as any)[key]),
  ...Object.keys(OrganizationListBusinessStore.effects).map((key) => (OrganizationListBusinessStore.effects as any)[key]),
  ...Object.keys(SkillLevelBusinessStore.effects).map((key) => (SkillLevelBusinessStore.effects as any)[key]),
  ...Object.keys(EnrollmentRequirementBusinessStore.effects).map((key) => (EnrollmentRequirementBusinessStore.effects as any)[key]),
  ...Object.keys(EducationSectorBusinessStore.effects).map((key) => (EducationSectorBusinessStore.effects as any)[key]),
  ...Object.keys(EducationCategoryBusinessStore.effects).map((key) => (EducationCategoryBusinessStore.effects as any)[key]),
  ...Object.keys(questionOutcomeListBusinessStore.effects).map((key) => (questionOutcomeListBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalEducationInstanceListBusinessStore.effects).map((key) => (ExternalEducationInstanceListBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalEducationInstanceBusinessStore.effects).map((key) => (ExternalEducationInstanceBusinessStore.effects as any)[key]),
  ...Object.keys(SkillBasedRecommendationsBusinessStore.effects).map((key) => (SkillBasedRecommendationsBusinessStore.effects as any)[key]),
  ...Object.keys(UserCategoryBusinessStore.effects).map((key) => (UserCategoryBusinessStore.effects as any)[key]),
  ...Object.keys(ScormCourseViewBusinessStore.effects).map((key) => (ScormCourseViewBusinessStore.effects as any)[key]),
  ...Object.keys(ExternalEducationTemplateTimelineListBusinessStore.effects).map((key) => (ExternalEducationTemplateTimelineListBusinessStore.effects as any)[key]),
  ...Object.keys(CityListBusinessStore.effects).map((key) => (CityListBusinessStore.effects as any)[key]),
  ...Object.keys(ProgressTrackerBusinessStore.effects).map((key) => (ProgressTrackerBusinessStore.effects as any)[key]),
  ...Object.keys(ProgressTrackerBuisnessStore.effects).map((key) => (ProgressTrackerBuisnessStore.effects as any)[key])
);

const rootReducer = combineReducers({
  ...PublicTenantConfigurationBusinessStore.reducers,
  ...CollectionBusinessStore.reducers,
  ...CollectionParamsBusinessStore.reducers,
  ...UserFeedbackBusinessStore.reducers,
  ...PublicCourseListBusinessStore.reducers,
  ...CourseListBusinessStore.reducers,
  ...CourseGroupBusinessStore.reducers,
  ...ListFilterBusinessStore.reducers,
  ...LoginBusinessStore.reducers,
  ...CourseListBusinessStore.reducers,
  ...courseViewBusinessStore.reducers,
  ...UserBusinessStore.reducers,
  ...courseUpdateBusinessStore.reducers,
  ...UserListBusinessStore.reducers,
  ...UsersCoursesUpdateBusinessStore.reducers,
  ...workPositionBusinessStore.reducers,
  ...ReportsBusinessStore.reducers,
  ...CommentsBusinessStore.reducers,
  ...UserSettingsBusinessStore.reducers,
  ...QuizCollectionBusinessStore.reducers,
  ...QuestionBusinessStore.reducers,
  ...educationGroupBusinessStore.reducers,
  ...examTemplateBusinessStore.reducers,
  ...codedGradeBusinessStore.reducers,
  ...UserNoteBusinessStore.reducers,
  ...ReminderBusinessStore.reducers,
  ...WebinarListBusinessStore.reducers,
  ...webinarViewBusinessStore.reducers,
  ...ReminderListBusinessStore.reducers,
  ...notificationViewBusinessStore.reducers,
  ...notificationListBusinessStore.reducers,
  ...userActivityListBusinessStore.reducers,
  ...FileListBusinessStore.reducers,
  ...TagBusinessStore.reducers,
  ...examInstanceBusinessStore.reducers,
  ...activityBusinessStore.reducers,
  ...PublicCourseViewBusinessStore.reducers,
  ...AdditionalCourseListBusinessStore.reducers,
  ...ExternalContentListBusinessStore.reducers,
  ...ExternalContentViewBusinessStore.reducers,
  ...EmailTemplateBusinessStore.reducers,
  ...UserGroupBusinessStore.reducers,
  ...UserGroupListBusinessStore.reducers,
  ...UserGroupReferencedObjectsBusinessStore.reducers,
  ...SkillGroupBusinessStore.reducers,
  ...SkillBusinessStore.reducers,
  ...ExternalEducationApplicationBusinessStore.reducers,
  ...ExternalEducationTemplateListBusinessStore.reducers,
  ...ExternalEducationTemplateBusinessStore.reducers,
  ...CompanyListBusinessStore.reducers,
  ...SurveyTemplateBusinessStore.reducers,
  ...SurveyTemplateListBusinessStore.reducers,
  ...ExternalEducationOutcomeListBusinessStore.reducers,
  ...PublicSurveyInstanceBusinessStore.reducers,
  ...SurveyInstanceBusinessStore.reducers,
  ...ExternalEducationApplicationTimelineListBusinessStore.reducers,
  ...EducationQualificationBusinessStore.reducers,
  ...LocationBusinessStore.reducers,
  ...SkillLevelClassificationListBusinessStore.reducers,
  ...PublicTenantOrganizationConfigurationBusinessStore.reducers,
  ...userSkillEvaluationListBusinessStore.reducers,
  ...userSkillEvaluationBusinessStore.reducers,
  ...OrganizationBusinessStore.reducers,
  ...OrganizationListBusinessStore.reducers,
  ...SkillLevelBusinessStore.reducers,
  ...EnrollmentRequirementBusinessStore.reducers,
  ...EducationSectorBusinessStore.reducers,
  ...EducationCategoryBusinessStore.reducers,
  ...SkillBasedRecommendationsBusinessStore.reducers,
  ...questionOutcomeListBusinessStore.reducers,
  ...ExternalEducationInstanceListBusinessStore.reducers,
  ...ExternalEducationInstanceBusinessStore.reducers,
  ...UserCategoryBusinessStore.reducers,
  ...ScormCourseViewBusinessStore.reducers,
  ...ExternalEducationTemplateTimelineListBusinessStore.reducers,
  ...CityListBusinessStore.reducers,
  ...CacheListBusinessStore.reducers,
  ...ProgressTrackerBusinessStore.reducer,
  ...ProgressTrackerBuisnessStore.reducer,
});

// logger
const reduxLoggerEnabled = AppConfigService.getValue('logging.redux.enabled');
if (!!reduxLoggerEnabled) {
  const loggerMiddleware = createLogger(AppConfigService.getValue('logging.redux.config'));
  storeMiddleware.push(loggerMiddleware);
}

// persist
// TODO: storage DB names should be synched with ServiceWorkers
const storage = createStorage(PersistStorageType.IndexedDb, {
  name: 'Timun Offline Storage',
  storeName: 'timun_offline_store_db',
});

const persistConfig = {
  key: 'appStore',
  storage,
  // stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
  whitelist: ['messagingMessageList', 'userSettingsView'],
};
const persistedRootReducer = persistReducer(persistConfig, rootReducer);

// store type - is of undefined type/form, hence {}
// actions type - all reducers declare that they expect IPayloadAction so that is innduced as action type, but that's not true cause ILemonAction *is* the basic type so we use "any" to prevent it and force ILemonAction
// TODO: probably all reducers should be changed to decalre ILemon action insetead of the IPayloadAction, but that's kind of complicated
const store: Store<{}, any> = createStore(persistedRootReducer, compose(applyMiddleware(...storeMiddleware)));

const persistor = persistStore(store);

epicMiddleware.run(rootEpic);

export const getStore = (): Store<{}, ILemonAction> => {
  return store;
};

export const getPersistor = () => {
  return persistor;
};

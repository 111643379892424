import CourseAdministrationMenuLayoutContainer from '@src/components/app/layout/CourseAdministrationMenuLayoutContainer';
import React, { PropsWithChildren } from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type ICourseAdministrationMenuLayoutPageProps = WithRouterProps;

const CourseAdministrationMenuLayoutPage = (props: PropsWithChildren<ICourseAdministrationMenuLayoutPageProps>) => {
  // TODO: add querry param instead of slicing the pathname string
  const getAdministrationPathId = (): string => props.location.pathname.slice(props.location.pathname.lastIndexOf('/') + 1);
  return (
    <CourseAdministrationMenuLayoutContainer administrationPathId={getAdministrationPathId()} courseId={props.params.courseId}>
      {props.children}
    </CourseAdministrationMenuLayoutContainer>
  );
};

export default withRouter<ICourseAdministrationMenuLayoutPageProps>(CourseAdministrationMenuLayoutPage as any);

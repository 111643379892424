import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserGroupListBusinessStore, { IUserGroupListFilter } from '@src/service/business/usergroup/UserGroupListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// -- Const
// ----------
const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');
const MAX_PAGE_SIZE = AppConfigService.getValue('api.collectionDefaultLimit');

export interface IUserGroupDataPickerOwnProps {
  value?: IUserGroup;
  onChange?: (value?: IUserGroup) => void;
}

export interface IUserGroupDataPickerStateProps {}

export interface IUserGroupDataPickerDispatchProps {
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => ITrackableAction;
}

type IUserGroupDataPickerProps = IUserGroupDataPickerOwnProps & IUserGroupDataPickerStateProps & IUserGroupDataPickerDispatchProps;

interface IUserGroupDataPickerState {
  userGroupList?: IUserGroup[];
}

class UserGroupDataPicker extends React.Component<IUserGroupDataPickerProps, IUserGroupDataPickerState> {
  state: IUserGroupDataPickerState = {
    userGroupList: undefined,
  };

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.userGroupList ? this.state.userGroupList.map(this.mapToItem) : this.props.value ? [this.mapToItem(this.props.value)] : [];

    return <DataPicker onChange={this.handleDataPickerChange} value={pickerValue} items={pickerItems} onSearch={this.handleSearch} />;
  };

  handleSearch = (value: string) => {
    const filter: IUserGroupListFilter = { name: value, type: UserGroupTypeEnum.ORGANIZATION };
    if (value.length >= minSearchLength) {
      this.updateList(filter);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  };

  handleDataPickerChange = (value?: IDataPickerItem) => {
    this.props.onChange?.(value?.data);
    this.storeList(); // clear search results
  };

  private mapToItem = (userGroup: IUserGroup): IDataPickerItem => {
    return {
      value: userGroup.name,
      name: userGroup.name,
      data: userGroup,
    };
  };

  private updateList = (filter: IUserGroupListFilter = {}) => {
    this.props
      .fetchUserGroupList({
        filter,
        page: 0,
        sort: [],
        size: MAX_PAGE_SIZE,
      })
      .track()
      .subscribe((data: ICollectionData<IUserGroup>) => this.storeList(data.content));
  };

  private storeList = (userGroupList?: IUserGroup[]) => {
    this.setState({ userGroupList: userGroupList ?? [] });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserGroupDataPickerOwnProps): IUserGroupDataPickerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IUserGroupDataPickerDispatchProps => ({
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => createTrackableAction(dispatch(UserGroupListBusinessStore.actions.fetchUserGroupPickerList(params))),
});

export default connect<IUserGroupDataPickerStateProps, IUserGroupDataPickerDispatchProps, IUserGroupDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(UserGroupDataPicker);

import { SettingOutlined } from '@ant-design/icons';
import DataPickerHelperUtils, { IVisibleColumn } from '@src/components/common/datapicker/utils/DataPickerHelperUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';

import ActionMenu, { IActionMenuItem } from '@src/components/common/table/ActionMenu';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { classNames } from '@src/components/common/util/classNames';
import IIdRef from '@src/model/common/IdRef';
import { ICollectionDataCount } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { LangUtils } from '@src/service/util/LangUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Checkbox, Col, Dropdown, Empty, Menu, Row, Table } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { TablePaginationConfig } from 'antd/lib/table';
import { ExpandableConfig, FilterValue, SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import React from 'react';

const TABLE_SCROLL_SIZE = AppConfigService.getValue('layout.table.scrollSize');

export interface IDataTableOwnProps<T> {
  items: T[];
  columns: ITimunDataTableCol<T>[];
  paginationData?: ICollectionDataCount;
  paginationConfig?: Exclude<TablePaginationConfig, 'current' | 'total' | 'pageSize'>;
  className?: string;
  showActionMenu?: boolean | ((item: T) => boolean);
  showSelectedElementsPanel?: boolean;
  actionMenuItems?: IActionMenuItem<T>[];
  scrollable?: boolean;
  selectable?: boolean;
  groupActions?: IActionMenuItem<T[]>[];
  emptyText?: string;
  additionalHeaderActions?: React.ReactNode | ((selectedElements: T[], selectChangeFn: (data: T[]) => void) => React.ReactNode);
  onSelectedChange?: (data: T[]) => void;
  expandable?: ExpandableConfig<T>;
  // onSortChange?: (key: string, order: 'ascend' | 'descend') => void; // TODO: set table sortimg string | string[]
}
type IDataTableProps<T> = IDataTableOwnProps<T>;
interface IDataTableState<T> {
  selectedElements: T[];
  visibleColumns: IVisibleColumn<T>;
  columnDropdownVisible: boolean;
  showDropdownButton: boolean;
}

class DataTable<T extends IIdRef<string>> extends React.Component<IDataTableProps<T>, IDataTableState<T>> {
  state: IDataTableState<T> = {
    selectedElements: [],
    visibleColumns: {},
    columnDropdownVisible: false,
    showDropdownButton: true,
  };

  componentDidMount = () => {
    if (this.props.columns) {
      this.setVisibleColumns();
    }
  };

  componentDidUpdate = (prevProps: IDataTableProps<T>, prevState: IDataTableState<T>) => {
    if (this.props.columns !== prevProps.columns) {
      this.setVisibleColumns();
    }
  };

  setVisibleColumns = () => {
    const visibleColumns: IVisibleColumn<T> = {};
    const allVisible = this.props.columns.every((column) => !column.columnOptional);

    this.props.columns.forEach((column) => {
      visibleColumns[column.key] = !column.columnOptional && !column.defaultHidden;
    });

    this.setState({ visibleColumns, showDropdownButton: !allVisible });
  };

  handleColumnDropdownVisibleChange = (flag: boolean) => {
    this.setState({ columnDropdownVisible: flag });
  };

  renderColumnDropdownButton = () => {
    const dropdownColumnMenu = (
      <Menu>
        {this.props.columns.map((column) => (
          <Menu.Item disabled={!column.columnOptional} onClick={() => this.toggleColumnVisible(column.key)} key={column.key.toString()}>
            <React.Fragment>
              <Checkbox checked={this.state.visibleColumns[column.key]} />
              &nbsp;
              {column.headerTitle}
            </React.Fragment>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown overlay={dropdownColumnMenu} visible={this.state.columnDropdownVisible} /** dropdown is in controlled mode to disable closing on item click */ onVisibleChange={this.handleColumnDropdownVisibleChange}>
        <SettingOutlined />
      </Dropdown>
    );
  };

  getColumns = () => {
    const columns = DataPickerHelperUtils.setColumns(this.props.columns, this.state.visibleColumns);
    if (this.props.showActionMenu && this.props.actionMenuItems?.length) {
      columns.push({
        key: 'actionMenu',
        width: '5%',
        render: (record: T) =>
          this.props.actionMenuItems && (
            <ActionMenu record={record} id={this.getRowKey(record)} actions={this.props.actionMenuItems}>
              <Button>
                <LemonIcon name="ellipsis" />
              </Button>
            </ActionMenu>
          ),
      });
    }

    return columns;
  };

  toggleColumnVisible = (key: string) => {
    const visibleColumns = {
      ...this.state.visibleColumns,
      [key]: !this.state.visibleColumns[key],
    };

    this.setState({ visibleColumns });
  };

  render() {
    const columns = this.getColumns();
    const currentPageNumber = this.props.paginationData ? this.props.paginationData?.pageable.pageNumber + 1 : undefined;
    return (
      <React.Fragment>
        {this.props.showSelectedElementsPanel && this.state.selectedElements?.length !== 0 && this.props.paginationData && (
          <Row justify="space-between" className="timun-dataTable__selectedRowsPanel">
            <Col>
              {LocalizeService.translate('DATA_TABLE.SELECTED_ROWS_COUNT_LABEL')}: {this.state.selectedElements?.length}
            </Col>
            <Col>
              {this.props.groupActions && (
                <ActionMenu record={this.state.selectedElements} id={'groupActions'} actions={this.props.groupActions}>
                  <Button type="ghost">{LocalizeService.translate('DATA_TABLE.SELECTED_ROWS_MORE_ACTIONS_LABEL')}</Button>
                </ActionMenu>
              )}
              <Button onClick={() => this.handleSelectChange([])} type="link">
                {LocalizeService.translate('COMMON.ACTION_CLEAR')}
              </Button>
            </Col>
          </Row>
        )}
        {(this.props.additionalHeaderActions || this.state.showDropdownButton) && (
          <Row justify="end" gutter={8} className="timun-dataTable__header">
            {this.state.showDropdownButton && <Col>{this.renderColumnDropdownButton()}</Col>}
            {this.props.additionalHeaderActions && <Col className="timun-dataTable__additionalAction">{LangUtils.isFunction(this.props.additionalHeaderActions) ? this.props.additionalHeaderActions(this.state.selectedElements, this.handleSelectChange) : this.props.additionalHeaderActions}</Col>}
          </Row>
        )}

        <Table
          className={this.getClassName()}
          columns={columns}
          dataSource={this.props.items}
          rowSelection={this.rowSelection()}
          rowKey={(record) => record.id}
          onChange={this.handleTableChange}
          scroll={this.props.scrollable ? { x: TABLE_SCROLL_SIZE } : undefined}
          locale={{
            emptyText: <Empty description={this.props.emptyText ?? LocalizeService.translate('COMMON.MESSAGE_NO_DATA')} />,
          }}
          expandable={this.props.expandable}
          pagination={{
            position: ['topRight', 'bottomRight'],
            hideOnSinglePage: false,
            showSizeChanger: false,
            total: this.props.paginationData?.totalElements,
            pageSize: this.props.paginationData?.size,
            current: currentPageNumber,
            responsive: true,

            showTotal: (total, range) => LocalizeService.translate('DATA_TABLE.PAGINATION_SHOW_TOTAL_LABEL', { rangeOne: range[0], rangeTwo: range[1], total }),
            ...this.props.paginationConfig,
          }}
        />
      </React.Fragment>
    );
  }

  rowSelection = (): TableRowSelection<T> | undefined => {
    if (this.props.selectable) {
      return {
        preserveSelectedRowKeys: true,
        columnWidth: '5%',
        selectedRowKeys: this.state.selectedElements.map((item) => this.getRowKey(item)),
        onChange: (selectedRowKeys: any, selectedRows: T[]) => {
          this.handleSelectChange(selectedRows);
        },
        getCheckboxProps: (record: T) => ({
          //
        }),
      };
    }
    return undefined;
  };

  handleSelectChange = (records: T[]) => {
    this.setState({
      selectedElements: records,
    });
    if (this.props.onSelectedChange) {
      this.props.onSelectedChange(records);
    }
  };

  handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<T> | SorterResult<T>[]) => {
    if (pagination.current && pagination.pageSize && this.props.paginationConfig?.onChange) {
      this.props.paginationConfig?.onChange(pagination.current, pagination.pageSize);
    }
    // TODO: set table sortimg string | string[]
    /* if (sorter?.columnKey && typeof sorter.columnKey === 'string' && sorter?.order && this.props.onSortChange) {
      this.props.onSortChange(sorter.columnKey, sorter.order);
    } */
  };

  getClassName() {
    return classNames({
      [this.props.className || '']: true,
    });
  }

  private getRowKey = (record: T) => {
    return record.id;
  };
}

export default DataTable;

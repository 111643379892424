import LemonIcon from '@src/components/common/image/LemonIcon';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import ZoomWrapper from '@src/components/common/zoom/ZoomWrapper';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import UserGroupHierarchyGraph from '@src/components/usergroup/hierarchy/UserGroupHierarchyGraph';
import UserGroupList from '@src/components/usergroup/list/UserGroupList';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import { ICollectionData } from '@src/service/business/common/types';
import { IUserGroupListFilter } from '@src/service/business/usergroup/UserGroupListBusinessStore';
import { Col, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------

interface IUserGroupListViewOwnProps {
  emptyMessage: string;
  userGroupList: ICollectionData<IUserGroup>;
  userGroupListFilter?: IUserGroupListFilter;
  showButtons: boolean;
  basePath: string;
  returnRoute?: string;
  userGroupHierarchy?: IUserGroupHierarchy;
  onUserGroupHierachyUpdate?: () => void;
  onPageChange: (page: number, size?: number) => void;
  onUserGroupEdit?: (userGroup: IUserGroup) => void;
  onUserGroupCoverEdit?: (userGroup: IUserGroup) => void;
  onUserGroupDelete?: (userGroupId: string) => void;
}
type IUserGroupListViewProps = IUserGroupListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class UserGroupListView extends React.Component<IUserGroupListViewProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.userGroupHierarchy && (
          <ZoomWrapper content={<UserGroupHierarchyGraph basePath={this.props.basePath} returnRoute={this.props.returnRoute} userGroupHierarchy={this.props.userGroupHierarchy} onUserGroupHierachyUpdate={this.props.onUserGroupHierachyUpdate} canAdd={this.props.showButtons} canEdit={this.props.showButtons} />}>
            {({ controls, content }) => {
              return (
                <ViewSectionItem
                  className="timun-userGroupList__hierarchyPanel"
                  header={<HeaderTitle title={this.props.translate('USER_GROUP.LIST_VIEW.HIERARCHY_TREE_TITLE')} icon={<LemonIcon name="organization" size="large" />} />}
                  collapsible={true}
                  active={false}
                  forceRender={true} // needed here to have transform content rendered, as wrapper needs it to calculate params. wrapper breaks if content isn't rendered
                  bordered={true}
                  contentHeader={controls}
                >
                  {content}
                </ViewSectionItem>
              );
            }}
          </ZoomWrapper>
        )}
        <Row justify="space-between">
          {/* List filter */}
          <Col xs={24} md={12}>
            {/* TODO: add <UserGroupListFilter /> */}
          </Col>
          {/* List pagination */}
          <Col xs={24} md={12}>
            {this.props.userGroupList && <ListPagination page={this.props.userGroupList.page} onChange={this.props.onPageChange} />}
          </Col>
        </Row>

        {/* ----- User group List ----- */}
        <UserGroupList
          emptyMessage={this.props.emptyMessage}
          basePath={this.props.basePath}
          returnRoute={this.props.returnRoute}
          showButtons={this.props.showButtons}
          userGroups={this.props.userGroupList.content}
          onUserGroupEdit={this.props.onUserGroupEdit}
          onUserGroupCoverEdit={this.props.onUserGroupCoverEdit}
          onUserGroupDelete={this.props.onUserGroupDelete}
        />
      </React.Fragment>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<IUserGroupListViewOwnProps>(UserGroupListView as any);

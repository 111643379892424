import IIdRef from '@src/model/common/IdRef';
import { ISkillLevelClassification } from '@src/model/skillgroup/SkillLevelClassification';
import { IUserSkillEvaluation } from '@src/model/user/UserSkillEvaluation';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IIdPayload, ILemonAction, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export interface IUserSkillEvaluationCreatePayload {
  skillLevelClassification: ISkillLevelClassification;
  user: IIdRef<string>;
}

// -
// -------------------- Selectors

/** Returns UserSkillEvaluation from store. */
const getUserSkillEvaluation = (store: any): IUserSkillEvaluation => store.userSkillEvaluation;

// -
// -------------------- Actions

const Actions = {
  USER_SKILL_EVALUATION_FETCH: 'USER_SKILL_EVALUATION_FETCH',
  USER_SKILL_EVALUATION_LOAD: 'USER_SKILL_EVALUATION_LOAD',
  USER_SKILL_EVALUATION_CLEAR: 'USER_SKILL_EVALUATION_CLEAR',
  USER_SKILL_EVALUATION_CREATE: 'USER_SKILL_EVALUATION_CREATE',
  USER_SKILL_EVALUATION_UPDATE: 'USER_SKILL_EVALUATION_UPDATE',
  USER_SKILL_EVALUATION_DELETE: 'USER_SKILL_EVALUATION_DELETE',
};

/** Fetch UserSkillEvaluation by ID. */
const fetchUserSkillEvaluation = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_SKILL_EVALUATION_FETCH,
    payload: params,
  };
};

/** Load UserSkillEvaluation to store. */
const loadUserSkillEvaluation = (data: IUserSkillEvaluation): IPayloadAction<IUserSkillEvaluation> => {
  return {
    type: Actions.USER_SKILL_EVALUATION_LOAD,
    payload: data,
  };
};

/** Clear UserSkillEvaluation from store. Eg. when leaving view. */
const clearUserSkillEvaluationData = (): ILemonAction => {
  return {
    type: Actions.USER_SKILL_EVALUATION_CLEAR,
  };
};

/** Create new UserSkillEvaluation. */
const createUserSkillEvaluation = (data: IUserSkillEvaluationCreatePayload): IPayloadAction<IUserSkillEvaluationCreatePayload> => {
  return {
    type: Actions.USER_SKILL_EVALUATION_CREATE,
    payload: data,
  };
};

/** Update UserSkillEvaluation by ID. */
const updateUserSkillEvaluation = (data: IUserSkillEvaluation): IPayloadAction<IUserSkillEvaluation> => {
  return {
    type: Actions.USER_SKILL_EVALUATION_UPDATE,
    payload: data,
  };
};

/** Delete UserSkillEvaluation by ID. */
const deleteUserSkillEvaluation = (id: string): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.USER_SKILL_EVALUATION_DELETE,
    payload: { id },
  };
};

// -
// -------------------- Side-effects

const fetchUserSkillEvaluationEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_SKILL_EVALUATION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('UserSkillEvaluation')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserSkillEvaluation(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'UserSkillEvaluation_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching UserSkillEvaluation', error);
      return o;
    }),
  );
};

const createUserSkillEvaluationEffect = (action$: Observable<IPayloadAction<IUserSkillEvaluationCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_SKILL_EVALUATION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('UserSkillEvaluation')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      return { message: LocalizeService.translate('USER_SKILL_EVALUATION.NOTIFICATION.USER_SKILL_EVALUATION_CREATED'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS };
    }),

    map((data) => {
      return loadUserSkillEvaluation(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_SKILL_EVALUATION_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating UserSkillEvaluation', error);
      return o;
    }),
  );
};

const updateUserSkillEvaluationEffect = (action$: Observable<IPayloadAction<IUserSkillEvaluation>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_SKILL_EVALUATION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('UserSkillEvaluation')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserSkillEvaluation(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_SKILL_EVALUATION_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating UserSkillEvaluation', error);
      return o;
    }),
  );
};

const deleteUserSkillEvaluationEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_SKILL_EVALUATION_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('UserSkillEvaluation')
        .deleteEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      return { message: LocalizeService.translate('USER_SKILL_EVALUATION.NOTIFICATION.USER_SKILL_EVALUATION_DELETED'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO };
    }),

    map((data) => {
      return loadUserSkillEvaluation(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_SKILL_EVALUATION_DELETE.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting UserSkillEvaluation', error);
      return o;
    }),
  );
};

// -
// -------------------- Reducers

const userSkillEvaluation = (state: IUserSkillEvaluation | null = null, action: IPayloadAction<IUserSkillEvaluation>) => {
  if (action.type === Actions.USER_SKILL_EVALUATION_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_SKILL_EVALUATION_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const UserSkillEvaluationBusinessStore = {
  actions: {
    fetchUserSkillEvaluation,
    loadUserSkillEvaluation,
    clearUserSkillEvaluationData,
    createUserSkillEvaluation,
    updateUserSkillEvaluation,
    deleteUserSkillEvaluation,
  },

  selectors: {
    getUserSkillEvaluation,
  },

  effects: {
    fetchUserSkillEvaluationEffect,
    createUserSkillEvaluationEffect,
    updateUserSkillEvaluationEffect,
    deleteUserSkillEvaluationEffect,
  },

  reducers: {
    userSkillEvaluation,
  },
};

// --
// export business store
export default UserSkillEvaluationBusinessStore;

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IOrganization } from '@src/model/organization/Organization';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import OrganizationListBusinessStore, { IOrganizationListFilter } from '@src/service/business/organization/OrganizationListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');
const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

// -- Prop types
// ----------

interface IOrganizationDataPickerOwnProps {
  value?: IOrganization;
  onChange?: (data?: IOrganization) => void;
  disabled?: boolean;
}

interface IOrganizationDataPickerStateProps {
}

interface IOrganizationDataPickerDispatchProps {
  fetchOrganizationList: (params: ICollectionFetchPayload<IOrganizationListFilter>) => ITrackableAction;
}
type IOrganizationDataPickerProps = IOrganizationDataPickerOwnProps & IOrganizationDataPickerStateProps & IWithLocalizeOwnProps & IOrganizationDataPickerDispatchProps;

interface IOrganizationDataPickerState {
  organizationList?: IOrganization[];
}

// -- Component
// ----------

class OrganizationDataPicker extends React.Component<IOrganizationDataPickerProps, IOrganizationDataPickerState> {
  state: IOrganizationDataPickerState = {
    organizationList: [],
  };

  handleSearch = debounce((value: string) => {
    const filter: IOrganizationListFilter = { name: value };
    if (value.length >= minSearchLength) {
      this.updateList(filter);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  }, debounceTimeout);

  render = () => {
    const pickerItems = this.state.organizationList?.map(this.mapToItem) || [];
    const pickerValue = this.props.value && this.mapToItem(this.props.value);

    return (
      <DataPicker
        value={pickerValue}
        items={pickerItems}
        disabled={this.props.disabled}
        onChange={this.handleDataPickerChange}
        onSearch={this.handleSearch}
        placeholderSuffix={this.props.translate('ORGANIZATION.DATA_PICKER.ORGANIZATION_PLACEHOLDER')}
        dataTestIdPrefix="timun-educationOutcomePicker"
      />
    );
  };


  handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  private mapToItem = (organization: IOrganization): IDataPickerItem => {
    return {
      value: organization.id,
      name: organization.company.name,
      data: organization,
    };
  };

  private updateList = (filter: IOrganizationListFilter = {}) => {
    const params = { filter };
    this.props.fetchOrganizationList(params).track().subscribe(
      (data: ICollectionData<IOrganization>) => this.storeList(data.content)
    );
  };

  private storeList = (organizationList?: IOrganization[]) => {
    this.setState({ organizationList });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IOrganizationDataPickerOwnProps): IOrganizationDataPickerStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IOrganizationDataPickerDispatchProps => ({
  fetchOrganizationList: (params: ICollectionFetchPayload<IOrganizationListFilter>) => createTrackableAction(dispatch(OrganizationListBusinessStore.actions.fetchOrganizationList(params))),
});

export default connect<IOrganizationDataPickerStateProps, IOrganizationDataPickerDispatchProps, IOrganizationDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(OrganizationDataPicker as any));

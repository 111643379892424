import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withNumberFormat, { IWithNumberFormatOwnProps } from '@src/components/common/numberformat/withNumberFormat';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import CoFinancedEducationPriceModalForm from '@src/components/externalEducationApplication/form/CoFinancedEducationPriceModalForm';
import ExternalEducationExpensesForm from '@src/components/externalEducationApplication/form/ExternalEducationExpensesForm';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { IFile } from '@src/model/file/File';
import { UserRoleEnum } from '@src/model/user/UserRole';
import AppConfigService from '@src/service/common/AppConfigService';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Button, Col, Empty, Row } from 'antd';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const eurToKunaExchangeRate = AppConfigService.getValue('currencyExchangeRate.eurToHrk');
const currencySymbolEur = AppConfigService.getValue('currencyFormat.currencySymbolEur');
const exchangeRate = AppConfigService.getValue('numberFormat.exchangeRate');

interface IEducationApplicationExpensesViewSectionOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  isCurrentUserCoordinator?: boolean;
  canUpload?: boolean;
  disabled?: boolean;
  active?: boolean;
  onExpenseSubmit?: (data: IExternalEducationExpense[]) => void;
  onFileUpload?: (file: IFile[]) => void;
}
type IEducationApplicationExpensesViewSectionProps = IEducationApplicationExpensesViewSectionOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & IWithNumberFormatOwnProps;

interface IEducationApplicationExpensesViewSectionState {
  isFileListUploadModalVisible: boolean;
}

class EducationApplicationExpensesViewSection extends React.Component<IEducationApplicationExpensesViewSectionProps, IEducationApplicationExpensesViewSectionState> {
  state: IEducationApplicationExpensesViewSectionState = {
    isFileListUploadModalVisible: false,
  };

  getExpenseList = (type: ExpeneseTypeEnum): IExternalEducationExpense[] | undefined => {
    return this.props.externalEducationApplication?.expenses?.filter((expense) => expense.expenseType.id === type);
  };

  isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };
  render = () => {
    return (
      <React.Fragment>
        <ViewSectionItem
          bordered={true}
          header={<HeaderTitle title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.PANEL_TITLE')} icon={<LemonIcon name="coins" />} />}
          collapsible={true}
          active={this.props.active}
          actions={
            this.props.isCurrentUserCoordinator && this.isApplicationInPhases(['EDUCATION_IN_PROGRESS', 'CONTRACT_ANNEX_REQUESTED', 'CONTRACT_ANNEX_REQUESTED']) && <Button onClick={this.handleModalOpen}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.CO_FINANCED_EDUCATION_PRICE_BUTTON_LABEL')}</Button>
          }
        >
          <Row>
            <Col xs={24} lg={8}>
              <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_EXPENSE_HEADER_TITLE')}>{this.renderexpenses(this.getExpenseList(ExpeneseTypeEnum.EDUCATION_OFFER_PRICE))}</GridItem>
            </Col>
            <Col xs={24} lg={8}>
              <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.CO_FINANCED_EDUCATION_PRICE_HEADER_TITLE')}>{this.renderexpenses(this.getExpenseList(ExpeneseTypeEnum.CO_FINANCED_EDUCATION_PRICE))}</GridItem>
            </Col>
            <Col xs={24} lg={8}>
              <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EUR_TO_HR_EXCHANGE_RATE_LABEL')}>
                <ViewSectionContentItem
                  key={currencySymbolEur}
                  icon={<LemonIcon name="coins" size="xlarge" />}
                  title={
                    <React.Fragment>
                      <Row>
                        {this.props.formatCurrency(1, undefined, currencySymbolEur)} = {this.props.formatCurrency(eurToKunaExchangeRate, exchangeRate)}
                      </Row>
                    </React.Fragment>
                  }
                />
              </GridItem>
            </Col>
          </Row>

          <Row>
            <Col xs={24} lg={8}>
              <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.TRAVEL_EXPENSE_HEADER_TITLE')}>{this.renderexpenses(this.getExpenseList(ExpeneseTypeEnum.TRAVEL_EXPENSE))}</GridItem>
            </Col>
          </Row>
          {this.isApplicationInPhases(['EDUCATION_IN_PROGRESS', 'CONTRACT_ANNEX_REQUESTED', 'CONTRACT_ANNEX_REQUESTED']) && RoleUtils.allowedRoles([UserRoleEnum.COORDINATOR], this.props.currentUser.roles) && (
            <Row>
              <Row>
                <GridItem className="panel" label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.TRAVEL_EXPENSE_FORM_LABEL')}>
                  <ExternalEducationExpensesForm disabled={this.props.disabled} value={this.props.externalEducationApplication} onSubmit={this.props.onExpenseSubmit} />
                </GridItem>
              </Row>
            </Row>
          )}
        </ViewSectionItem>

        {this.state.isFileListUploadModalVisible && <CoFinancedEducationPriceModalForm value={this.props.externalEducationApplication} onCancel={this.handleModalClose} onSubmit={this.props.onExpenseSubmit} />}
      </React.Fragment>
    );
  };

  handleModalOpen = (event: SyntheticEvent) => {
    event.stopPropagation();
    this.setState({ isFileListUploadModalVisible: true });
  };
  handleModalClose = () => this.setState({ isFileListUploadModalVisible: false });

  handleFileAdd = (file: IFile[]) => {
    this.props.onFileUpload?.(file);
    this.handleModalClose();
  };

  renderexpenses = (expenses?: IExternalEducationExpense[]) => {
    if (expenses != null && expenses.length > 0) {
      return expenses.map((expense) => {
        return (
          <ViewSectionContentItem
            key={expense.id}
            icon={<LemonIcon name="coins" size="xlarge" />}
            title={
              <React.Fragment>
                <Row className="timun-educationApplicationCurrency__fontSize--primary">{this.props.formatCurrency(expense.value / eurToKunaExchangeRate, undefined, currencySymbolEur)}</Row>
                <Row className="timun-educationApplicationCurrency__fontSize--secondary">{this.props.formatCurrency(expense.value)}</Row>
              </React.Fragment>
            }
            description={moment(expense.paymentDate).format(dateFormat)}
          />
        );
      });
    } else {
      return <Empty description={this.props.translate('COMMON.MESSAGE_NO_DATA')} />;
    }
  };
}

export default withRoles(withNumberFormat(withLocalize<IEducationApplicationExpensesViewSectionOwnProps>(EducationApplicationExpensesViewSection as any)));

import { Col, Dropdown, Menu, Row } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { withRouter, WithRouterProps } from 'react-router';

import LocalePickerContainer from '@src/components/app/locale/LocalePickerContainer';
import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IUserInfo } from '@src/model/user/User';
import LemonIcon from '@src/components/common/image/LemonIcon';
// -- Types&Interfaces
// ----------

enum MenuKeys {
  // Admin,
  MyProfile,
  ChangePassword,
  ChangeLanguage,

  Logout,
  Notifications,
}

// cannot import ClickParam from antd so we recreate it only with things we need
// tslint:disable-next-line: interface-name
interface MenuItemClickEvent {
  key: string;
}

// -- Prop types
// ----------
export interface IUserMenuPublicProps {
  user: IUserInfo;

  onDoLogout?: () => void;
}
type IUserMenuProps = IUserMenuPublicProps & IWithLocalizeOwnProps & WithRouterProps & IWithTenantPropEnabledOwnProps;

// TODO: protection against long user names (some container with overflow and ellipsis)

// --
// ----- Component
const UserMenu = (props: IUserMenuProps) => {
  const [isMenuOpened, setMenuOpened] = useState<boolean>(false);

  const onMenuClick = useCallback((event: MenuItemClickEvent) => {
    const eventKey = Number.parseInt(event.key, undefined);

    // -- Logout
    if (MenuKeys[MenuKeys.Logout] === MenuKeys[eventKey]) {
      props.onDoLogout?.();
    }
    // -- MyProfile
    else if (MenuKeys[MenuKeys.MyProfile] === MenuKeys[eventKey]) {
      props.router.push(`/user/${props.user.id}`);
    }
    // -- Change Password
    else if (MenuKeys[MenuKeys.ChangePassword] === MenuKeys[eventKey]) {
      props.router.push('/changepassword');
    }
    // -- Notifications
    else if (MenuKeys[MenuKeys.Notifications] === MenuKeys[eventKey]) {
      props.router.push('/notifications');
    }
    // sanity check
    else {
      console.warn(`Unhandled menu item clicked: ${event.key}`);
    }
  }, [props.onDoLogout, props.router]);

  const userDisplayName = useMemo((): string => {
    return `${props.user.firstName} ${props.user.lastName}`;
  }, [props.user]);

  const dropdownTitle = useMemo(() => (
    <Row className="lemon-userMenu__loggedUserContainer" gutter={8} align="middle">
      <Col>
        <AppUserAvatar imagePath={props.user.profileImageUrl} size="small" />
      </Col>
      <Col>
        {isMenuOpened ?
          <LemonIcon name="up" className="lemon-userMenu__menuOpener" /> :
          <LemonIcon name="down" className="lemon-userMenu__menuOpener" />
        }
      </Col>
    </Row>
  ), [props.user.profileImageUrl, isMenuOpened]);

  // TODO: pass menu items as list, rendering as children is deprecated, locale picker etc needs a rewrite also... classname is missing as list prop??
  /* const menuItems = useMemo(() => {
    return [
      {
        key: 'LoggedUser',
        label: <Row gutter={8} className="lemon-userMenu__loggedUserMenuItem">
          <Col>
            <AppUserAvatar imagePath={props.user.profileImageUrl} size="small" />
          </Col>
          <Col>
            {userDisplayName}
          </Col>
        </Row>,
        disabled: true,
      },
    ];
  }, []); */

  return (
    <div>
      <Dropdown
        onOpenChange={setMenuOpened}
        trigger={['hover', 'click']}
        className="lemon-appLayout__headerMenu"
        overlay={
          <Menu className="lemon-userMenu__menuContainer" overflowedIndicator={null} selectable={false} onClick={onMenuClick} data-test-id="lemon-userMenu">
            <Menu.Item key={'LoggedUser'} disabled={true} className="lemon-userMenu__loggedUserMenuItem">
              <Row gutter={8} align="middle">
                <Col>
                  <AppUserAvatar imagePath={props.user.profileImageUrl} size="small" />
                </Col>
                <Col>
                  {userDisplayName}
                </Col>
              </Row>
            </Menu.Item>

            {/* TODO: HZZ demo hide  */}
            {/* <Menu.Item key={MenuKeys.MyProfile} data-test-id="lemon-userMenu__itemMyProfile">
              {props.translate('USER_MENU.MY_PROFILE')}
            </Menu.Item> */}

            <LocalePickerContainer key={MenuKeys.ChangeLanguage} />

            {props.isTenantPropEnabled('admin_notifications') && (
              <Menu.Item key={MenuKeys.Notifications} data-test-id="lemon-userMenu__itemLogout">
                {props.translate('USER_MENU.NOTIFICATIONS')}
              </Menu.Item>
            )}

            <Menu.Item key={MenuKeys.Logout} data-test-id="lemon-userMenu__itemLogout">
              {props.translate('USER_MENU.LOGOUT_LABEL')}
            </Menu.Item>
          </Menu>
        }
      >
        {dropdownTitle}
      </Dropdown>
    </div>
  );

};

export default withLocalize<IUserMenuPublicProps>(withRouter(withTenantPropEnabled(UserMenu) as any));

import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseListEditContainer from '@src/components/course/list/CourseListEditContainer';
import CourseListView from '@src/components/course/list/CourseListView';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { UserStatusEnum } from '@src/model/user/UserStatus';
import { ICollectionData } from '@src/service/business/common/types';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button } from 'antd';
import React from 'react';

const MANUAL_PARTICIPATION_ENABLED = AppConfigService.getValue('courseManagement.manualParticipationEnabled');

interface IUserCourseListViewOwnProps {
  user: IUserInfo;
  courseGroups: ICourseGroup[];
  courseList: ICollectionData<ICourse>;
  courseListFilter: ICourseListFilter;
  sortValue: string[];
  onUpdateCourseList: () => void;
  onSortChange: (sort: string[]) => void;
  onPageChange: (page: number, size?: number) => void;
  onFilterChange: (filter: ICourseListFilter) => void;
}

type IUserCourseListViewProps = IUserCourseListViewOwnProps & IWithRolesOwnProps & IWithLocalizeOwnProps;

interface IUserCourseListViewState {
  isEditing: boolean;
}

class UserCourseListView extends React.Component<IUserCourseListViewProps, IUserCourseListViewState> {
  state: IUserCourseListViewState = {
    isEditing: false,
  };

  render = () => {
    const userIsActive = this.props.user && this.props.user.userStatus?.id === UserStatusEnum.ACTIVE;
    const userHasCourses = this.props.courseList && this.props.courseList.content.length;

    return (
      <React.Fragment>
        <AppContent
          title={
            this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) &&
            userIsActive &&
            MANUAL_PARTICIPATION_ENABLED &&
            this.props.courseList && (
              <div className="text-right">
                <Button type="primary" onClick={this.toggleEdit}>
                  <LemonIcon name={userHasCourses ? 'edit' : 'plus'} />
                  {this.props.translate(userHasCourses ? 'COURSE_LIST.EDIT_COURSES_LABEL' : 'COURSE_LIST.ADD_COURSES_LABEL')}
                </Button>
              </div>
            )
          }
        >
          <CourseListView
            courseList={this.props.courseList}
            getPath={CourseHelperUtils.getTraineePath}
            courseGroups={this.props.courseGroups}
            courseListFilter={this.props.courseListFilter}
            sortValue={this.props.sortValue}
            onSortChange={this.props.onSortChange}
            onFilterChange={this.props.onFilterChange}
            onPageChange={this.props.onPageChange}
          />
        </AppContent>
        {this.state.isEditing && <CourseListEditContainer user={this.props.user} userCourseList={this.props.courseList.content} updateCourseList={this.props.onUpdateCourseList} onCancel={this.toggleEdit} />}
      </React.Fragment>
    );
  };

  private toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };
}

export default withRoles(withLocalize<IUserCourseListViewOwnProps>(UserCourseListView as any));

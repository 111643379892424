import { IActivity } from '@src/model/activity/Activity';
import { IActivityParticipant, ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamInstance } from '@src/model/education/ExamInstance';

export default class ExamInstanceHelperUtils {
  /** Check if exam instance is in one of given statuses */
  static isExamInstancenInPhase = (exam: IExamInstance, requestedPhases: ActivityPhaseEnum[]): boolean => {
    return requestedPhases.some((phase) => exam.activities.some((examActivity: IActivity) => examActivity.currentPhase.id === phase));
  };

  /** Returns participant name by requested role. */
  static getActivityParticipantNameByRole(requestedRole: ParticipantRoleEnum, activity: IActivity): any {
    const participant = activity.participants.find((paritcipant: IActivityParticipant) => paritcipant.participantRole === requestedRole);
    return `${participant?.firstName} ${participant?.lastName}`;
  }

  /** Returns user earned and total points on submited exam instance */
  static getTotalAndEarnedPointsRatio(examInstance: IExamInstance): string {
    return `${examInstance.additionalData.totalEarnedPoints}/${examInstance.additionalData.totalPoints}`;
  }
}

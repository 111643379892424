import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICourseLectureGroup, ICourseLectureListElement } from '@src/model/course/LectureGroup';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import AddElementLink from '@src/components/course/update/AddElementLink';

const NAME_LENGTH_MAX = AppConfigService.getValue('userValidation.nameLengthMax');
const MIN_GROUP_ORDERING_VALUE = AppConfigService.getValue('components.courses.minOrderingValue');

export interface ICourseLectureFormOwnProps {
  lectureList: ICourseLectureListElement[];
  canEditStructure: boolean;
  onLectureGroupUpdate: (data: ICourseLectureGroup) => void;
  onLectureGroupDelete: (data: ICourseLectureGroup) => void;
  onLectureGroupAdd: (title: string) => void;
}

type ICourseLectureFormProps = ICourseLectureFormOwnProps & IWithLocalizeOwnProps;

const CourseLectureListForm: React.FC<ICourseLectureFormProps> = (props: ICourseLectureFormProps) => {
  const [form] = Form.useForm<ICourseLectureListElement>();

  useEffect(() => {
    form.setFieldValue('lectureList', props.lectureList);
  }, [props.lectureList]);

  const maxGroupOrderingValue = useMemo(() => props.lectureList?.length, [props.lectureList]);

  const handleLectureGroupSubmit = useCallback(
    (values: ICourseLectureGroup) => {
      props.onLectureGroupUpdate(values);
    },
    [props.onLectureGroupUpdate]
  );

  return (
    <Form hideRequiredMark={true} form={form}>
      <Form.List name="lectureList" initialValue={props.lectureList}>
        {(fields) => {
          return (
            <React.Fragment>
              {fields.map((field) => {
                return (
                  <Row gutter={[16, 16]} key={field.key}>
                    <Col>
                      <Form.Item
                        name={[field.name, 'ordering']}
                        key={field.key}
                        fieldKey={[field.key, 'ordering']}
                        label={props.translate('COURSE_VIEW.UPDATE.COURSE_LECTURE_GROUP_ORDERING_FORM_LABEL')}
                        rules={[
                          { min: MIN_GROUP_ORDERING_VALUE, max: maxGroupOrderingValue, type: 'number', message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                          { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
                        ]}
                      >
                        <InputNumber min={MIN_GROUP_ORDERING_VALUE} max={maxGroupOrderingValue} disabled={!props.canEditStructure} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label={props.translate('COURSE_VIEW.UPDATE.COURSE_LECTURE_GROUP_TITLE_FORM_LABEL')}
                        name={[field.name, 'title']}
                        key={field.key}
                        fieldKey={[field.key, 'title']}
                        rules={[
                          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                          { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
                        ]}
                      >
                        <Input maxLength={NAME_LENGTH_MAX} />
                      </Form.Item>
                    </Col>
                    <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.lectureList?.[field.name] !== nextValues.lectureList?.[field.name]}>
                      {() => {
                        const canSave = form.isFieldsTouched() && (props.lectureList[field.name]?.title !== form.getFieldValue('lectureList')[field.name]?.title || props.lectureList[field.name]?.ordering !== form.getFieldValue('lectureList')[field.name]?.ordering);
                        return (
                          <React.Fragment>
                            {canSave ? (
                              <Form.Item>
                                <Space size={'small'}>
                                  <Button type="primary" onClick={() => handleLectureGroupSubmit(form.getFieldValue('lectureList')[field.name])}>
                                    {props.translate('COMMON.ACTION_SAVE')}
                                  </Button>
                                  <Button onClick={() => form.resetFields()}>{props.translate('COMMON.ACTION_CANCEL')}</Button>
                                </Space>
                              </Form.Item>
                            ) : null}
                            {props.canEditStructure && !canSave && <DeleteElementButton<ICourseLectureGroup> icon={<LemonIcon name="delete" />} item={form.getFieldValue('lectureList')[field.name]} onDelete={props.onLectureGroupDelete} />}
                          </React.Fragment>
                        );
                      }}
                    </Form.Item>
                  </Row>
                );
              })}
            </React.Fragment>
          );
        }}
      </Form.List>
      {props.canEditStructure && (
        <React.Fragment>
          <br />
          <Row justify="center">
            <Col>
              <AddElementLink onSubmit={props.onLectureGroupAdd} title={props.translate('COURSE_VIEW.UPDATE.GROUP_NAME_INPUT_TITLE')} />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Form>
  );
};

export default withLocalize<ICourseLectureFormOwnProps>(CourseLectureListForm as any);

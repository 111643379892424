import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap, ignoreElements } from 'rxjs/operators';

import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICompany } from '@src/model/company/Company';

// -
// -------------------- Types&Consts

export interface ICompanyListFilter {
  name?: string;
  identificationNumber?: string;
}

// List filter ID
const COMPANY_LIST_FILTER = '@@COMPANY_LIST_FILTER';

// -
// -------------------- Selectors

const getCompanyList = (store: any): ICompany[] => store.companyList;
const getCompanyListFilter = (store: any): ICompanyListFilter => ListFilterBusinessStore.selectors.getListFilter(store, COMPANY_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  COMPANY_LIST_FETCH: 'COMPANY_LIST_FETCH',
  COMPANY_LIST_PICKER_FETCH: 'COMPANY_LIST_PICKER_FETCH',
  COMPANY_LIST_LOAD: 'COMPANY_LIST_LOAD',
  COMPANY_LIST_CLEAR: 'COMPANY_LIST_CLEAR',
};

const fetchCompanyList = (filter: ICompanyListFilter): IPayloadAction<ICompanyListFilter> => {
  return {
    type: Actions.COMPANY_LIST_FETCH,
    payload: filter,
  };
};

const fetchPickerCompanyList = (filter: ICompanyListFilter): IPayloadAction<ICompanyListFilter> => {
  return {
    type: Actions.COMPANY_LIST_PICKER_FETCH,
    payload: filter,
  };
};

const loadCompanyList = (data: ICompany[]): IPayloadAction<ICompany[]> => {
  return {
    type: Actions.COMPANY_LIST_LOAD,
    payload: data,
  };
};

const clearCompanyList = (): ILemonAction => {
  return {
    type: Actions.COMPANY_LIST_CLEAR,
  };
};

const storeCompanyListFilter = (filter: ICompanyListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(COMPANY_LIST_FILTER, filter);
};

const clearCompanyListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(COMPANY_LIST_FILTER);
};

// -
// -------------------- Side-effects

const fetchPickerCompanyListEffect = (action$: Observable<IPayloadAction<ICompanyListFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COMPANY_LIST_PICKER_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Company')
        .fetchNoMethod(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COMMENTS_VIEW.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching company list', error);
      return o;
    })
  );
};

const fetchCompanyListEffect = (action$: Observable<IPayloadAction<ICompanyListFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COMPANY_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Company')
        .fetchNoMethod(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCompanyList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COMMENTS_VIEW.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching company list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const companyList = (state: ICompany[] | null = null, action: IPayloadAction<ICompany[]>) => {
  if (action.type === Actions.COMPANY_LIST_LOAD) {
    return [...action.payload];
  } else if (action.type === Actions.COMPANY_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const CompanyListBusinessStore = {
  actions: {
    fetchCompanyList,
    fetchPickerCompanyList,
    clearCompanyList,
    storeCompanyListFilter,
    clearCompanyListFilter,
  },

  selectors: {
    getCompanyList,
    getCompanyListFilter,
  },

  effects: {
    fetchCompanyListEffect,
    fetchPickerCompanyListEffect,
  },

  reducers: {
    companyList,
  },
};

// --
// export business store
export default CompanyListBusinessStore;

import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import ExternalEducationTemplateModalDataPicker from '@src/components/common/datapicker/externalEducationTemplate/ExternalEducationTemplateModalDataPicker';
import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { ExternalEducationTemplateHelperUtils } from '@src/components/externaleducation/common/ExternalEducationTemplateHelperUtils';
import EducationApplicationFileListView from '@src/components/externalEducationApplication/common/EducationApplicationFileListView';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationFileListUpload from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFileListUpload';
import ExternalEducaitonContractGenerationFrom from '@src/components/externalEducationApplication/form/ExternalEducaitonContractGenerationFrom';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { FinancingAgreemntTypeEnum } from '@src/model/externalEducationApplication/ExternalEducationApplicationFilePreviewData';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IFile } from '@src/model/file/File';
import { IFileListsByType } from '@src/service/business/files/util';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { Button, Col, Row, Typography } from 'antd';
import React from 'react';

// -- Prop types
// ----------
interface IExternalEducationApplicationTraineeApprovalViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  canParticipate?: boolean;
  onUpdate?: (data: IExternalEducationApplication) => void;
  onExternalEducationTemplateUpdate: (data: IExternalEducationTemplate) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: EducationApplicationActivityPhaseEnum, save?: boolean) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onCancelApplication?: () => void;
}

type IExternalEducationApplicationTraineeApprovalViewProps = IExternalEducationApplicationTraineeApprovalViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & FormComponentProps;

interface IExternalEducationApplicationTraineeApprovalViewState {
  isEducationTemplateModalVisible: boolean;
}

// -- Component
// ----------

class ExternalEducationApplicationTraineeApprovalView extends React.Component<IExternalEducationApplicationTraineeApprovalViewProps, IExternalEducationApplicationTraineeApprovalViewState> {
  state: IExternalEducationApplicationTraineeApprovalViewState = {
    isEducationTemplateModalVisible: false,
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationTraineeApprovalViewProps, prevState: IExternalEducationApplicationTraineeApprovalViewState) => {
    //
  };

  isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  isApplicationFinancingAgreementInTypes = (types: (keyof typeof FinancingAgreemntTypeEnum)[]): boolean => {
    return EducationApplicationHelperUtils.isFinancingAgreementInTypes(types, this.props.externalEducationApplication.financingAgreementType);
  };

  handleChangePhase = (phase: EducationApplicationActivityPhaseEnum, save: boolean = false) => {
    if (this.props.onChangePhase) {
      this.props.onChangePhase(phase, save);
    }
  };

  render() {
    const canSubmitSignedContract = EducationApplicationHelperUtils.hasFilesInAllTypes(this.props.applicationFiles, ['SIGNED_FINANCING_AGREEMENT']);
    const canSubmitOffer = EducationApplicationHelperUtils.hasFilesInTypes(this.props.applicationFiles, ['OFFER']);
    const financingAgreementAddedDateTime = DateUtils.toViewDateFormat(EducationApplicationHelperUtils.getFirstFileByType(this.props.applicationFiles, 'FINANCING_AGREEMENT')?.addedDateTime, 'dateWithTime');
    return (
      <React.Fragment>
        {this.isApplicationInPhases(['USER_CANCELED']) && (
          <Row>
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.APPLICATION_CANCELED_TEXT')}</Col>
          </Row>
        )}

        {this.isApplicationInPhases(['EXTERNAL_EDUCATION_APPLICATION_DENIED']) && (
          <Row>
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DENIED_TEXT')}</Col>
          </Row>
        )}

        {this.isApplicationInPhases(['SUBMITTED', 'AWAITING_APPROVAL', 'CONTRACT_DATA_PROCESSING']) && (
          <React.Fragment>
            <Row>
              <Col>{this.isApplicationInPhases(['CONTRACT_DATA_PROCESSING']) ? this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CONTRACT_DATA_PROCESSING_COORDINATOR_TEXT') : this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMITTED_TEXT')}</Col>
            </Row>
          </React.Fragment>
        )}

        {/* User EXTERNAL_EDUCATION_TEMPLATE_SELECTION */}
        {this.isApplicationInPhases(['EXTERNAL_EDUCATION_TEMPLATE_SELECTION']) && this.props.externalEducationApplication.externalEducationTemplate && (
          <React.Fragment>
            <Typography.Paragraph strong={true}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SELECTED_EXTERNAL_EDUCATION_TEMPLATE_TEXT', { educationTemplateTitle: this.props.externalEducationApplication.externalEducationTemplate?.title })}</Typography.Paragraph>
            <Typography.Paragraph>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EXTERNAL_EDUCATION_TEMPLATE_SELECTION_GUIDE_TEXT')}</Typography.Paragraph>
            <Row justify="start">
              <Col>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CHANGE_EDUCATION_APPLICATION_LABEL')}
                &nbsp;
                <Button block={false} onClick={this.handleEducationTemplateModalOpen} data-test-id="timun-externalEducationApplicationView__changeProgramButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CHANGE_EDUCATION_APPLICATION_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
            &nbsp;
            <Row gutter={4}>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EXTERNAL_EDUCATION_TEMPLATE_SELECTION_TEXT')}</Col>
              <Col>
                <a href={ExternalEducationTemplateHelperUtils.getEmailTemplate(this.props.externalEducationApplication.externalEducationTemplate, this.props.currentUser)}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EXTERNAL_EDUCATION_TEMPLATE_SELECTION_LABEL').toUpperCase()}</a>
              </Col>
            </Row>
            <GridItem label={this.props.translate('FILES.TYPE_ENUM.LABEL.OFFER')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.OFFER_INFO_LABEL')} requiredMark={true}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="OFFER" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>
            <Row>
              <Col>
                <Button block={false} disabled={!canSubmitOffer} onClick={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.CONTRACT_DATA_PROCESSING)} data-test-id="timun-externalEducationApplicationView__cancelApplicationButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMIT_SIGNED_OFFER_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
            &nbsp;
            {this.props.externalEducationApplication.skill && this.state.isEducationTemplateModalVisible && <ExternalEducationTemplateModalDataPicker onCancel={this.handleEducationTemplateModalClose} onSubmit={this.props.onExternalEducationTemplateUpdate} skill={this.props.externalEducationApplication.skill} />}
          </React.Fragment>
        )}
        {this.isApplicationInPhases(['CONTRACT_GENERATION']) && (
          <React.Fragment>
            <Row>
              <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.APPROVED_AND_CONTRACT_IN_GENERATION_TEXT')}</Col>
            </Row>

            <br />

            {this.isApplicationFinancingAgreementInTypes(['EMPLOYED', 'EMPLOYED_CO_FINANCED_BY_EMPLOYER', 'EMPLOYED_CO_FINANCED_BY_USER']) && (
              <Row>
                <Col span={24}>
                  <Typography.Paragraph> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_START_DATE_TEXT')} </Typography.Paragraph>
                </Col>
                <Col span={24}>
                  <Typography.Paragraph strong={true}> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_START_DATE_NOTE_TEXT')} </Typography.Paragraph>
                </Col>
                <Col span={24}>
                  <ExternalEducaitonContractGenerationFrom hidePaymentInfo={true} externalEducationApplication={this.props.externalEducationApplication} onSubmit={this.props.onUpdate} />
                </Col>
                &nbsp;
              </Row>
            )}

            {this.isApplicationFinancingAgreementInTypes(['UNEMPLOYED', 'UNEMPLOYED_CO_FINANCED', 'UNEMPLOYED_WAIVER_OF_FINANCIAL_AID', 'UNEMPLOYED_CO_FINANCED_WAIVER_OF_FINANCIAL_AID']) && (
              <Row>
                <Col span={24}>
                  <Typography.Paragraph> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_PAYMENT_INFO_TEXT')} </Typography.Paragraph>
                </Col>
                <Col span={24}>
                  <Typography.Paragraph strong={true}> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_START_DATE_NOTE_TEXT')} </Typography.Paragraph>
                </Col>
                <Col span={24}>
                  <ExternalEducaitonContractGenerationFrom externalEducationApplication={this.props.externalEducationApplication} onSubmit={this.props.onUpdate} />
                </Col>
                &nbsp;
              </Row>
            )}

            {EducationApplicationHelperUtils.hasFilesInAllTypes(this.props.applicationFiles, ['FINANCING_AGREEMENT']) && (
              <React.Fragment>
                <Typography.Paragraph> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.FINANCING_AGREEMENT_TEXT')} </Typography.Paragraph>

                <GridItem label={this.props.translate('FILES.TYPE_ENUM.LABEL.FINANCING_AGREEMENT')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CONTRACT_GENERATION_INFO')}>
                  <EducationApplicationFileListView files={this.props.applicationFiles} displayTypes={['FINANCING_AGREEMENT']} />
                  <Typography.Paragraph className="timun-wrapper__gutterBox--vertical"> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.FINANCING_AGREEMENT_ADDED_DATE_TIME_TEXT', { addedDateTime: financingAgreementAddedDateTime })} </Typography.Paragraph>
                </GridItem>

                <GridItem label={this.props.translate('FILES.TYPE_ENUM.LABEL.SIGNED_FINANCING_AGREEMENT')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.SIGNED_FINANCING_AGREEMENT_INFO')} requiredMark={true}>
                  <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="SIGNED_FINANCING_AGREEMENT" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
                  <Typography.Paragraph className="timun-wrapper__gutterBox--vertical"> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SIGNED_FINANCING_AGREEMENT_TEXT')} </Typography.Paragraph>
                </GridItem>

                <Row>
                  <Col>
                    <Button type="primary" block={true} disabled={!canSubmitSignedContract} onClick={() => this.handleChangePhase(EducationApplicationActivityPhaseEnum.AWAITING_SIGNED_CONTRACT, true)} data-test-id="timun-externalEducationApplicationView__generateContractButton">
                      {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.REQUEST_SIGNED_FINANCING_AGREEMENT_BUTTON_TEXT')}
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {this.isApplicationInPhases(['AWAITING_PAYMENT', 'AWAITING_SIGNED_CONTRACT']) && (
          <React.Fragment>
            {EducationApplicationHelperUtils.hasFilesInAllTypes(this.props.applicationFiles, ['MUTUALLY_SIGNED_FINANCING_AGREEMENT']) && <React.Fragment>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.MUTUALY_SIGNED_FINANCIG_AGREEMENT_TEXT')}</React.Fragment>}
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.AWAITING_PAYMENT_TEXT')}</Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases(['CONTRACT_DATA_PROCESSING', 'CONTRACT_GENERATION']) && (
          <Row className="timun-wrapper__gutterBox--vertical" gutter={4} justify="end">
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.RETURN_TO_EDUCATION_TEMPLATE_SELECTION_LABEL')}</Col>
            <Col>
              <Button block={true} onClick={this.handleReturnToEducationTemplateSelection} data-test-id="timun-externalEducationApplicationView__returnToEducationTemplateSelection">
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.RETURN_TO_EDUCATION_TEMPLATE_SELECTION_BUTTON_TEXT')}
              </Button>
            </Col>
          </Row>
        )}

        {/* user common cancelled button */}
        {this.isApplicationInPhases(['SUBMITTED', 'AWAITING_APPROVAL', 'CONTRACT_DATA_PROCESSING', 'CONTRACT_GENERATION', 'EXTERNAL_EDUCATION_TEMPLATE_SELECTION']) && (
          <Row justify="end">
            <Col>
              {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_LABEL')}
              &nbsp;
              <Button block={false} onClick={this.handleCancelApplication} data-test-id="timun-externalEducationApplicationView__cancelApplicationButton">
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_BUTTON_TEXT')}
              </Button>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }

  handleReturnToEducationTemplateSelection = () => {
    confirmationDialog({
      title: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_TO_EDUCATION_TEMPLATE_SELECTION_CONFIRMATION_TITLE'),
      content: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_TO_EDUCATION_TEMPLATE_SELECTION_CONFIRMATION_TEXT'),
      okType: 'default',
      onConfirm: () => this.handleChangePhase(EducationApplicationActivityPhaseEnum.EXTERNAL_EDUCATION_TEMPLATE_SELECTION),
    });
  };

  handleCancelApplication = () => {
    confirmationDialog({
      onConfirm: () => this.props.onCancelApplication?.(),
    });
  };

  handleEducationTemplateModalOpen = () => {
    this.setState({ isEducationTemplateModalVisible: true });
  };
  handleEducationTemplateModalClose = () => {
    this.setState({ isEducationTemplateModalVisible: false });
  };
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationTraineeApprovalViewOwnProps>(ExternalEducationApplicationTraineeApprovalView as any));

import React, { useCallback } from 'react';

import { Select } from 'antd';
import { LangUtils } from '@src/service/util/LangUtils';
import IIdRef from '@src/model/common/IdRef';

// -- Prop types
// ----------

export interface IDataSelectOwnProps<T> {
  value?: T;
  onChange?: (data: Partial<T> | Partial<T>[]) => void;
  placeholder?: string;
  options: ISelectOption<T>[];
  dataTestId?: string;
  showArrow?: boolean;
  allowClear?: boolean;
  enableFilter?: boolean;
  mode?: 'multiple';
}
type IDataSelectProps<T> = IDataSelectOwnProps<T>;
interface ISelectOption<T> {
  value: string;
  data: Partial<T>;
  key: string;
  dataTestId?: string;
  label: string;
}

// -- Component
// ----------

/** Display data select generic component */
const DataSelect = <T extends IIdRef<string>>(props: IDataSelectProps<T>) => {
  const handleChange = useCallback((value: string, selected: ISelectOption<T> | ISelectOption<T>[]) => {
    if (LangUtils.isArray(selected)) {
      const selectedDataList = selected.map((select) => select.data);
      props.onChange?.(selectedDataList);
    } else {
      props.onChange?.(selected.data);
    }
  }, []);
  return (
    <Select<string, ISelectOption<T>>
      showArrow={props.showArrow}
      allowClear={props.allowClear}
      filterOption={props.enableFilter ? (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) : undefined}
      mode={props.mode}
      value={props.value?.id}
      onChange={handleChange}
      options={props.options}
      placeholder={props.placeholder}
      data-test-id={props.dataTestId}
    />
  );
};

export default DataSelect;

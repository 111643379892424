import React from 'react';

import AdminCodebookListView from '@src/components/codebook/view/AdminCodebookListView';
import SuperAdminCodebookListView from '@src/components/codebook/view/SuperAdminCodebookListView';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { WithRouterProps } from 'react-router';


// -- Prop types
// ----------
export interface IAdminCodebooksPagePublicProps { }
interface IAdminCodebooksPageStateProps { }
type IAdminCodebooksPageProps = IAdminCodebooksPagePublicProps & IAdminCodebooksPageStateProps & WithRouterProps & IWithRolesOwnProps;

// -- Component
// ----------
const AdminCodebooksPage = (props: IAdminCodebooksPageProps) => {
  /** Super admin is allowed to see codebooks at any time */
  const DisplayComponent = props.isSuperAdmin() ? <SuperAdminCodebookListView /> : <AdminCodebookListView />;
  return (
    <React.Fragment>
      {DisplayComponent}
    </React.Fragment>
  );
};

export default withRoles<IAdminCodebooksPagePublicProps>(AdminCodebooksPage as any);

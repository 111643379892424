import React, { useCallback, useMemo } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Modal, Form, DatePicker, Radio, Input } from 'antd';
import CityDataPicker from '@src/components/common/datapicker/externalEducationTemplate/CityDataPicker';
import { IExternalEducationApplicationBaseInfo } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';

// -- Prop types
// ----------

export interface IOrganizationPayementRequestModalOwnProps {
  value?: IExternalEducationApplicationBaseInfo;
  onCancel?: () => void;
  onSubmit?: (value: IExternalEducationApplicationBaseInfo) => void;
}
type IOrganizationPayementRequestModalProps = IOrganizationPayementRequestModalOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const OrganizationPayementRequestModal: React.FC<IOrganizationPayementRequestModalProps> = (props: IOrganizationPayementRequestModalProps) => {
  const initialValues = useMemo(() => ({ ...props.value, isOnline: true }), [props.value]);
  const [form] = Form.useForm<IExternalEducationApplicationBaseInfo>();
  const isEducationOnline = Form.useWatch('isOnline', form);
  const handleFinish = useCallback(
    (values: IExternalEducationApplicationBaseInfo) => {
      props.onSubmit?.({ ...values, endDateTime: DateUtils.toBackendDateFormat(values.endDateTime, 'backendDate') });
    },
    [props.onSubmit]
  );

  const handleModalOk = useCallback(
    () =>
      confirmationDialog({
        okType: 'primary',
        onConfirm: () => {
          form.submit();
        },
        title: props.translate('COMMON.CONFIRMATION_TITLE'),
      }),
    [confirmationDialog, form.submit, props.translate]
  );

  return (
    <Modal className="lemon-modal__modal--sm" title={props.translate('ORGANIZATION_PAYMENT_REQUEST.MODAL.TITLE')} visible={true} onOk={handleModalOk} onCancel={props.onCancel} maskClosable={false}>
      <Form<IExternalEducationApplicationBaseInfo> initialValues={initialValues} layout="vertical" form={form} name="externalEducationApplication" onFinish={handleFinish}>
        <Form.Item name={'id'} noStyle={true} hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name={'endDateTime'} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]} label={props.translate('ORGANIZATION_PAYMENT_REQUEST.FORM.PAYMENT_REQUESTED_DATE_TIME_LABEL')}>
          <DatePicker disabledDate={DateUtils.disableFutureDate} format={DateUtils.getDateFormat('dateWithYear')} className="full-width" />
        </Form.Item>
        <Form.Item name={'isOnline'} label={props.translate('ORGANIZATION_PAYMENT_REQUEST.FORM.IS_ONLINE_LABEL')}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={true}>{props.translate('COMMON.ACTION_YES')}</Radio.Button>
            <Radio.Button value={false}>{props.translate('COMMON.ACTION_NO')}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {!isEducationOnline && (
          <Form.Item name={'educationLocation'} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]} label={props.translate('ORGANIZATION_PAYMENT_REQUEST.FORM.EDUCATION_LOCATION_LABEL')}>
            <CityDataPicker />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default withLocalize<IOrganizationPayementRequestModalOwnProps>(OrganizationPayementRequestModal as any);

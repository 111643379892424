import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RepositoryFileListContainer from '@src/components/repository/file/RepositoryFileListContainer';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IFile } from '@src/model/file/File';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import { Button, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

interface IRepositoryFileListPickerOwnProps {
  onFileSelect?: (data: IFile) => void;
}

interface IRepositoryFileListPickerStateProps {
}

interface IRepositoryFileListPickerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type IRepositoryFileListPickerProps = IRepositoryFileListPickerOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps & IRepositoryFileListPickerStateProps
  & IRepositoryFileListPickerDispatchProps;

interface IRepositoryFileListPickerState {
  isModalVisible: boolean;
  selectedFile?: IFile;
}

// -- Component
// ----------

class RepositoryFileListPicker extends React.Component<IRepositoryFileListPickerProps, IRepositoryFileListPickerState> {
  state: IRepositoryFileListPickerState = {
    isModalVisible: false,
    selectedFile: undefined,
  };

  render = () => {
    return (
      <React.Fragment>
        <Button className="timun-repositoryModal__button" onClick={this.openModal} icon={<LemonIcon name="paperClip" />}>
          {this.props.translate('COURSE_VIEW.UPDATE.KNOWLEDGE_BASE_BUTTON')}
        </Button>
        {this.state.isModalVisible &&
          <Modal className="lemon-modal__modal--full" visible={true} onCancel={this.closeModal} onOk={this.handleSubmit} maskClosable={false}>
            <RepositoryFileListContainer onFileSelect={this.handleChange} selectedFile={this.state.selectedFile} />
          </Modal>
        }
      </React.Fragment>
    );
  };

  handleChange = (data: IFile) => {
    this.setState({
      selectedFile: data,
    });
  };

  handleSubmit = () => {
    if (this.state.selectedFile) {
      this.props.onFileSelect?.(this.state.selectedFile);
      this.props.reportMessage({ message: this.props.translate('FILE_UPLOAD.FILE_ADDED_MESSAGE', { filename: this.state.selectedFile.name }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
    }

    this.closeModal();
  };

  openModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IRepositoryFileListPickerStateProps => ({});
// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IRepositoryFileListPickerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<IRepositoryFileListPickerStateProps, IRepositoryFileListPickerDispatchProps, IRepositoryFileListPickerOwnProps>(mapStateToProps, mapDispatchToProps)(withTenantPropEnabled(withLocalize(RepositoryFileListPicker) as any));

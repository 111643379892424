import { FormComponentProps } from '@ant-design/compatible/lib/form';
import CityDataPicker from '@src/components/common/datapicker/externalEducationTemplate/CityDataPicker';
import { validateOib } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICompany } from '@src/model/company/Company';
import { ContactDataTypeEnum } from '@src/model/user/ContactData';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Input, Row, Form } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

// -- Const
// ----------
const EMAIL_REGEX = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

// -- Prop types
// ----------
export interface ICompanyDataFormOwnProps {
  company?: ICompany;
  onChange?: (data: ICompany) => void;
}

type ICompanyDataFormProps = ICompanyDataFormOwnProps & FormComponentProps & IWithLocalizeOwnProps;

// -- Component
// ----------
/** Company data form that triggers onChange once all required fields are validated */
const CompanyDataForm: React.FC<ICompanyDataFormProps> = (props: ICompanyDataFormProps) => {
  const [form] = Form.useForm<ICompany>();

  useEffect(() => {
    form.resetFields();
    if (props.company) {
      form.validateFields();
    }
  }, [props.company]);

  const onValuesChange = useCallback(
    debounce(() => {
      form.validateFields().then((values: ICompany) => {
        props.onChange?.(values);
      });
    }, AppConfigService.getValue('components.common.debounceTimeout')),
    [props.onChange, form.validateFields]
  );

  const formDisabled = useMemo(() => props.company?.cesCompanyId != null || props.company?.id != null, [props.company]);

  const getEmailValue = useCallback((): string => {
    if (props.company) {
      const email = props.company.contactData?.find((contact) => contact.type.id === ContactDataTypeEnum.EMAIL);
      if (email) {
        return email.value;
      }
    }
    return '';
  }, [props.company]);

  const initialValues = useMemo(() => ({ ...props.company, contactData: props.company ? props.company.contactData.map(() => ({ value: getEmailValue(), type: { id: ContactDataTypeEnum.EMAIL } })) : [{ type: { id: ContactDataTypeEnum.EMAIL } }] }), [props.company, getEmailValue]);

  return (
    <Form<ICompany> form={form} initialValues={initialValues} onValuesChange={onValuesChange} layout="vertical" name="company">
      <Row gutter={12}>
        {/* Company name */}
        <Col xs={24} md={12}>
          <Form.Item name={'name'} rules={[{ required: true, message: props.translate('COMPANY.FORM.NAME_VALIDATION_MESSAGE') }]} label={props.translate('COMPANY.FORM.NAME_LABEL')}>
            <Input placeholder={props.translate('COMPANY.FORM.NAME_PLACEHOLDERLABEL')} disabled={formDisabled} data-test-id="timun-companyForm__name" />
          </Form.Item>
        </Col>

        {/* Company identification number */}
        <Col xs={24} md={12}>
          <Form.Item
            name="identificationNumber"
            label={props.translate('COMPANY.FORM.ID_LABEL')}
            rules={[
              { validator: validateOib, message: props.translate('COMPANY.FORM.ID_VALIDATION_MESSAGE') },
              { required: true, message: props.translate('COMPANY.FORM.ID_VALIDATION_MESSAGE') },
            ]}
          >
            <Input placeholder={props.translate('COMPANY.FORM.ID_PLACEHOLDERLABEL')} disabled={formDisabled} data-test-id="timun-companyForm__identificationNumber" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={16}>
          {/* Company city location */}
          <Form.Item name={['address', 'city']} label={props.translate('COMPANY.FORM.CITY_LABEL')} rules={[{ required: true, message: props.translate('COMPANY.FORM.CITY_VALIDATION_MESSAGE') }]}>
            <CityDataPicker placeholder={props.translate('COMPANY.FORM.CITY_PLACEHOLDER_LABEL')} disabled={formDisabled} data-test-id="timun-companyForm__city" />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          {/* Company zip code */}
          <Form.Item name={['address', 'zip']} label={props.translate('COMPANY.FORM.ZIP_LABEL')} rules={[{ required: true, message: props.translate('COMPANY.FORM.ZIP_VALIDATION_MESSAGE') }]}>
            <Input placeholder={props.translate('COMPANY.FORM.ZIP_PLACEHOLDER_LABEL')} disabled={formDisabled} data-test-id="timun-companyForm__zip" />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          {/* Company address */}
          <Form.Item name={['address', 'streetWithHomeNumber']} label={props.translate('COMPANY.FORM.STREET_LABEL')} rules={[{ required: true, message: props.translate('COMPANY.FORM.ADDRESS_VALIDATION_MESSAGE') }]}>
            <Input placeholder={props.translate('COMPANY.FORM.STREET_PLACEHOLDER_LABEL')} disabled={formDisabled} data-test-id="timun-companyForm__streetWithHomeNumber" />
          </Form.Item>
        </Col>
        {/* Company country */}
        <Form.Item name={['address', 'country']} noStyle={true} hidden={true}>
          <Input />
        </Form.Item>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={12}>
          {/* Company e-mail */}
          <Form.Item
            name={['contactData', 0, 'value']}
            label={props.translate('COMPANY.FORM.CONTACT_LABEL')}
            rules={[
              { required: true, message: props.translate('COMPANY.FORM.CONTACT_VALIDATION_MESSAGE') },
              { pattern: EMAIL_REGEX, message: props.translate('COMPANY.FORM.VALID_EMAIL_MESSAGE') },
            ]}
          >
            <Input placeholder={props.translate('COMPANY.FORM.CONTACT_PLACEHOLDER_LABEL')} data-test-id="timun-companyForm__contactDataValue" />
          </Form.Item>
        </Col>
        <Form.Item name={['contactData', 0, 'type', 'id']} noStyle={true} hidden={true}>
          <Input />
        </Form.Item>
      </Row>
    </Form>
  );
};
export default withLocalize<ICompanyDataFormOwnProps>(CompanyDataForm as any);

import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { ExecutionMethodEnum, ExternalEducationInstanceStatusEnum, IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IAddress } from '@src/model/user/Address';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface IExternalEducationInstanceCreatePayload {
  executionMethod: Pick<ICodeBookEntry<ExecutionMethodEnum>, 'id'>;
  addresses: IAddress[];
  status: Pick<ICodeBookEntry<ExternalEducationInstanceStatusEnum>, 'id'>;
  externalEducationTemplate: Pick<IExternalEducationTemplate, 'id'>;
}

// -
// -------------------- Selectors

/** Returns ExternalEducationInstance from store. */
const getExternalEducationInstance = (store: any): IExternalEducationInstance => store.externalEducationInstance;

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_EDUCATION_INSTANCE_FETCH: 'EXTERNAL_EDUCATION_INSTANCE_FETCH',
  EXTERNAL_EDUCATION_INSTANCE_LOAD: 'EXTERNAL_EDUCATION_INSTANCE_LOAD',
  EXTERNAL_EDUCATION_INSTANCE_CLEAR: 'EXTERNAL_EDUCATION_INSTANCE_CLEAR',
  EXTERNAL_EDUCATION_INSTANCE_CREATE: 'EXTERNAL_EDUCATION_INSTANCE_CREATE',
  EXTERNAL_EDUCATION_INSTANCE_UPDATE: 'EXTERNAL_EDUCATION_INSTANCE_UPDATE',
};

/** Fetch external education instance by ID. */
const fetchExternalEducationInstance = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_INSTANCE_FETCH,
    payload: params,
  };
};

/** Load external education instance to store. */
const loadExternalEducationInstance = (data: IExternalEducationInstance): IPayloadAction<IExternalEducationInstance> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_INSTANCE_LOAD,
    payload: data,
  };
};

/** Clear external education instance from store. Eg. when leaving view. */
const clearExternalEducationInstanceData = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_INSTANCE_CLEAR,
  };
};

/** Create new external education instance. */
const createExternalEducationInstance = (data: IExternalEducationInstanceCreatePayload): IPayloadAction<IExternalEducationInstanceCreatePayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_INSTANCE_CREATE,
    payload: data,
  };
};

/** Update external education instance by ID. */
const updateExternalEducationInstance = (data: IExternalEducationInstance): IPayloadAction<IExternalEducationInstance> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_INSTANCE_UPDATE,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const fetchExternalEducationInstanceEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_INSTANCE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationInstance')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationInstance(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'ExternalEducationInstance_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXTERNAL_EDUCATION_INSTANCE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching external education instance', error);
      return o;
    })
  );
};

const createExternalEducationInstanceEffect = (action$: Observable<IPayloadAction<IExternalEducationInstanceCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_INSTANCE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationInstance')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationInstance(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'ExternalEducationInstance_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXTERNAL_EDUCATION_INSTANCE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating external education instance', error);
      return o;
    })
  );
};

const updateExternalEducationInstanceEffect = (action$: Observable<IPayloadAction<IExternalEducationInstance>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_INSTANCE_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationInstance')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationInstance(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXTERNAL_EDUCATION_INSTANCE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating external education instance', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const ExternalEducationInstanceView = (state: IExternalEducationInstance | null = null, action: IPayloadAction<IExternalEducationInstance>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_INSTANCE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_INSTANCE_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const ExternalEducationInstanceBusinessStore = {
  actions: {
    fetchExternalEducationInstance,
    loadExternalEducationInstance,
    clearExternalEducationInstanceData,
    createExternalEducationInstance,
    updateExternalEducationInstance,
  },

  selectors: {
    getExternalEducationInstance,
  },

  effects: {
    fetchExternalEducationInstanceEffect,
    createExternalEducationInstanceEffect,
    updateExternalEducationInstanceEffect,
  },

  reducers: {
    ExternalEducationInstanceView,
  },
};

// --
// export business store
export default ExternalEducationInstanceBusinessStore;

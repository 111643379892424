
import CurrencyInput from '@src/components/common/input/CurrencyInput';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Form, FormInstance, Input, Row } from 'antd';
import moment from 'moment';
import React from 'react';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');

interface IExpenseFormOwnProps {
  value?: IExternalEducationExpense;
  disabled?: boolean;
  expenseTypeId?: ExpeneseTypeEnum;
  formRef?: FormInstance<IExternalEducationExpense>;
  onSubmit?: (data: IExternalEducationExpense) => void;
}

type IExpenseFormProps = IExpenseFormOwnProps & IWithLocalizeOwnProps;

const ExpenseForm = (props: IExpenseFormProps) => {
  const [form] = Form.useForm<IExternalEducationExpense>(props.formRef);
  const selectedExpenseType = Form.useWatch(['expenseType', 'id'], form);
  const handleFinish = (values: IExternalEducationExpense) => props.onSubmit?.({ ...values, paymentDate: moment(values.paymentDate).format(backendDate) });
  const initialValues = {
    ...props.value,
    expenseType: { id: props.value?.expenseType.id ?? props.expenseTypeId },
    paymentDate: props.value?.paymentDate ? moment(props.value.paymentDate) : undefined,
  };
  return (
    <Form<IExternalEducationExpense>
      name="expense"
      form={form}
      layout="vertical"
      colon={false}
      onFinish={handleFinish}
      initialValues={initialValues}>

      <Form.Item
        name={['id']}
        noStyle={true}
        hidden={true}>
        <Input />
      </Form.Item>

      <Form.Item
        name={['expenseType', 'id']}
        hidden={true}
        noStyle={true}>
        <Input />
      </Form.Item>

      <Row gutter={[16, 16]} align="bottom">
        <Col xs={24} sm={12} md={12}>
          <Form.Item
            name={['value']}
            label={props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.VALUE_LABEL')}
            rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
            <CurrencyInput placeholder={props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.VALUE_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationExpensesForm__value" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={12}>
          <Form.Item
            hidden={selectedExpenseType === ExpeneseTypeEnum.CO_FINANCED_EDUCATION_PRICE}
            noStyle={selectedExpenseType === ExpeneseTypeEnum.CO_FINANCED_EDUCATION_PRICE}
            name={['paymentDate']}
            label={props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.DATE_LABEL')}
            rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
            <DatePicker format={dateFormat} className="full-width" data-test-id="timun-externalEducationApplicationExpensesForm__paymentDate" />
          </Form.Item>
        </Col>
      </Row>

      {!props.formRef && <Row>
        <Col>
          <Button type="primary" onClick={form.submit} disabled={props.disabled} data-test-id="timun-externalEducationApplicationExpensesForm__submitButton">
            {props.translate('COMMON.ACTION_SAVE')}
          </Button>
        </Col>
      </Row>}
    </Form >
  );

};


export default withLocalize<IExpenseFormOwnProps>(ExpenseForm as any);

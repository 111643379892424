import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { INote } from '@src/model/user/Note';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Card, Col, Empty, Row, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import moment from 'moment';
import React from 'react';

// -- Const
// ----------
const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithTime');

// -- Prop types
// ----------

export interface INoteListViewOwnProps {
  notes: INote[];
  canEditnote: boolean | ((note: INote) => boolean);
  onDeleteNote: (note: INote) => void;
  onEditNote: (selectedNote: INote) => void;
}

// -- State types
// ----------

type NoteListViewProps = INoteListViewOwnProps & IWithLocalizeOwnProps;

export interface INoteListViewState {}

// -- Component
// ----------

/** Component with filter that display list of notes */
class NoteListView extends React.Component<NoteListViewProps, INoteListViewState> {
  state: INoteListViewState = {};
  render() {
    return (
      <React.Fragment>
        {/*Display user notes list */}
        {this.props.notes.length ? (
          this.props.notes.map((note: INote) => {
            return (
              <Row className="timun-noteListView__cardRow" key={note.id}>
                <Card className="timun-noteListView__card">
                  <div dangerouslySetInnerHTML={{ __html: note.text }} />
                  <Meta
                    description={
                      <Tooltip placement="bottom" title={moment(note.addedDateTime).format(DATE_FORMAT)}>
                        <span>{moment(note.addedDateTime).fromNow()}</span>
                      </Tooltip>
                    }
                  />
                  {typeof this.props.canEditnote === 'boolean'
                    ? this.props.canEditnote
                    : this.props.canEditnote(note) && (
                        <Row justify="end" gutter={12}>
                          <Col>
                            <Button icon={<LemonIcon name="edit" />} key="edit" onClick={() => this.props.onEditNote(note)} />
                          </Col>
                          <Col>
                            <DeleteElementButton<INote> item={note} onDelete={this.props.onDeleteNote} icon={<LemonIcon name="delete" />} />
                          </Col>
                        </Row>
                      )}
                </Card>
              </Row>
            );
          })
        ) : (
          <Empty description={this.props.translate('NOTE_LIST.NO_DATA')} />
        )}
      </React.Fragment>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<INoteListViewOwnProps>(NoteListView as any);

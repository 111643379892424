import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { ActivityUtils } from '@src/service/util/activity/ActivityUtils';
import { Button, Card, Col, Row } from 'antd';
import React, { useCallback } from 'react';

// -- Prop types
// ----------

export interface IExternalEducationTemplateCoordinatorApprovalPanelOwnProps {
  externalEducationTemplate: IExternalEducationTemplate;
  isCurrentUserEvalutor?: boolean;
  onActivityPhaseChange?: (nextId: ExternalEducationTemplateActivityPhaseEnum) => void;
  onCoordinatorAdd?: () => void;
}

type IExternalEducationTemplateCoordinatorApprovalPanelProps = IExternalEducationTemplateCoordinatorApprovalPanelOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display coordinator approval panel with text info and action buttons */
const ExternalEducationTemplateCoordinatorApprovalPanel: React.FC<IExternalEducationTemplateCoordinatorApprovalPanelProps> = (props: IExternalEducationTemplateCoordinatorApprovalPanelProps) => {
  const handleApprovalSubmit = useCallback(() => props.onActivityPhaseChange?.(ExternalEducationTemplateActivityPhaseEnum.APPROVED), [props.onActivityPhaseChange]);
  const handleReturnSubmit = useCallback(() => props.onActivityPhaseChange?.(ExternalEducationTemplateActivityPhaseEnum.CHANGE_REQUESTED), [props.onActivityPhaseChange]);
  const handleDeniedSubmit = useCallback(() => props.onActivityPhaseChange?.(ExternalEducationTemplateActivityPhaseEnum.EXTERNAL_EDUCATION_TEMPLATE_DENIED), [props.onActivityPhaseChange]);
  const handleApprovalInProcessSubmit = useCallback(() => props.onActivityPhaseChange?.(ExternalEducationTemplateActivityPhaseEnum.APPROVAL_IN_PROCESS), [props.onActivityPhaseChange]);
  const isEducationTemplateInPhase = (phases: ExternalEducationTemplateActivityPhaseEnum[]) => props.externalEducationTemplate.activity && ActivityUtils.isInPhases(phases, props.externalEducationTemplate.activity);

  return (
    <Card className="lemon-card--fullWidthBody timun-externalEducationTemplateView__card">
      <Col className="timun-externalEducationTemplateView__column">
        <Row className="timun-externalEducationTemplateView__row">
          <HeaderTitle title={props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.VERIFICATION_HEADER_TITLE')} icon={<LemonIcon name="verification" />} />
        </Row>

        {/* Approval requested */}
        {isEducationTemplateInPhase([ExternalEducationTemplateActivityPhaseEnum.APPROVAL_REQUESTED, ExternalEducationTemplateActivityPhaseEnum.CHANGE_SUBMITTED]) && (
          <React.Fragment>
            <Row className="timun-externalEducationTemplateView__row">{props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.APPROVAL_REQUESTED_TEXT')}</Row>
            <Row gutter={16} className="timun-externalEducationTemplateView__row">
              {/**
               * Verification of education template for the first time need adding evaluator to the activity participants
               * Verification retake need existing evaluator participant and activity phase change.
               */}
              {props.externalEducationTemplate.activity && (
                <Col>
                  {props.isCurrentUserEvalutor ? (
                    <Button onClick={handleApprovalInProcessSubmit} type="default">
                      {props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.TAKE_EDUCATION_TEMPLATE_BUTTON_TEXT')}
                    </Button>
                  ) : (
                    <Button onClick={props.onCoordinatorAdd} type="default">
                      {props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.TAKE_EDUCATION_TEMPLATE_BUTTON_TEXT')}
                    </Button>
                  )}
                </Col>
              )}
            </Row>
          </React.Fragment>
        )}

        {/* Approval in process */}
        {isEducationTemplateInPhase([ExternalEducationTemplateActivityPhaseEnum.APPROVAL_IN_PROCESS]) && (
          <React.Fragment>
            <Row className="timun-externalEducationTemplateView__row">{props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.APPROVAL_IN_PROCCESS_TEXT')}</Row>
            <Row gutter={16} className="timun-externalEducationTemplateView__row">
              <Col>
                <NoteCreateContainer
                  objectId={props.externalEducationTemplate.id}
                  objectTypeId={NoteObjectTypeEnum.EXTERNAL_EDUCATION_TEMPLATE}
                  objectPublic={true}
                  buttonLabel={props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.REJECT_VERIFICATION_EDUCATION_TEMPLATE_BUTTON_TEXT')}
                  modalTitle={props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.REJECT_VERIFICATION_EDUCATION_TEMPLATE_MODAL_TITLE')}
                  modalOkButtonLabel={props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.REJECT_VERIFICATION_EDUCATION_TEMPLATE_BUTTON_TEXT')}
                  onCreateNote={handleDeniedSubmit}
                />
              </Col>
              <Col>
                <NoteCreateContainer
                  objectId={props.externalEducationTemplate.id}
                  objectTypeId={NoteObjectTypeEnum.EXTERNAL_EDUCATION_TEMPLATE}
                  objectPublic={true}
                  buttonLabel={props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.RETURN_EDUCATION_TEMPLATE_BUTTON_TEXT')}
                  modalTitle={props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.RETURN_EDUCATION_TEMPLATE_MODAL_TITLE')}
                  modalOkButtonLabel={props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.RETURN_EDUCATION_TEMPLATE_BUTTON_TEXT')}
                  onCreateNote={handleReturnSubmit}
                />
              </Col>
              <Col>
                <Button type="primary" onClick={handleApprovalSubmit}>
                  {props.translate('EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.VERIFY_EDUCATION_TEMPLATE_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Col>
    </Card>
  );
};

export default withLocalize<IExternalEducationTemplateCoordinatorApprovalPanelOwnProps>(ExternalEducationTemplateCoordinatorApprovalPanel as any);

import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';

import { IEducationCategory } from '@src/model/educationcategory/EducationCategory';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import CollectionHelperService from '@src/service/business/common/CollectionHelperService';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export type IEducationCategoryCreatePayload = Omit<IEducationCategory, 'id' | 'description'>;

export interface IEducationCategoryListFilter {
  name?: string;
  active?: boolean;
}

// List filter ID
const EDUCATION_CATEGORY_LIST_FILTER = '@@EDUCATION_CATEGORY_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns EducationCategory from store. */
const getEducationCategory = (store: any): IEducationCategory => store.educationCategory;

/** Returns list of EducationCategorys from store. */
const getEducationCategoryList = (store: any): ICollectionData<IEducationCategory> => store.educationCategoryList;

/** Returns EducationCategory list filter. */
const getEducationCategoryListFilter = (store: any): IEducationCategoryListFilter => ListFilterBusinessStore.selectors.getListFilter(store, EDUCATION_CATEGORY_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  EDUCATION_CATEGORY_FETCH: 'EDUCATION_CATEGORY_FETCH',
  EDUCATION_CATEGORY_LOAD: 'EDUCATION_CATEGORY_LOAD',
  EDUCATION_CATEGORY_CLEAR: 'EDUCATION_CATEGORY_CLEAR',
  EDUCATION_CATEGORY_CREATE: 'EDUCATION_CATEGORY_CREATE',
  EDUCATION_CATEGORY_UPDATE: 'EDUCATION_CATEGORY_UPDATE',
  EDUCATION_CATEGORY_LIST_FETCH: 'EDUCATION_CATEGORY_LIST_FETCH',
  EDUCATION_CATEGORY_LIST_LOAD: 'EDUCATION_CATEGORY_LIST_LOAD',
  EDUCATION_CATEGORY_LIST_CLEAR: 'EDUCATION_CATEGORY_LIST_CLEAR',
};

/** Fetch EducationCategory by ID. */
const fetchEducationCategory = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EDUCATION_CATEGORY_FETCH,
    payload: params,
  };
};

/** Load EducationCategory to store. */
const loadEducationCategory = (data: IEducationCategory): IPayloadAction<IEducationCategory> => {
  return {
    type: Actions.EDUCATION_CATEGORY_LOAD,
    payload: data,
  };
};

/** Clear EducationCategory from store. Eg. when leaving view. */
const clearEducationCategoryData = (): ILemonAction => {
  return {
    type: Actions.EDUCATION_CATEGORY_CLEAR,
  };
};

/** Create new EducationCategory. */
const createEducationCategory = (data: IEducationCategoryCreatePayload): IPayloadAction<IEducationCategoryCreatePayload> => {
  return {
    type: Actions.EDUCATION_CATEGORY_CREATE,
    payload: data,
  };
};

/** Update EducationCategory by ID. */
const updateEducationCategory = (data: IEducationCategory): IPayloadAction<IEducationCategory> => {
  return {
    type: Actions.EDUCATION_CATEGORY_UPDATE,
    payload: data,
  };
};

/** Fetch EducationCategory list by filter. */
const fetchEducationCategoryList = (params: ICollectionFetchPayload<IEducationCategoryListFilter>): IPayloadAction<ICollectionFetchPayload<IEducationCategoryListFilter>> => {
  return {
    type: Actions.EDUCATION_CATEGORY_LIST_FETCH,
    payload: params,
  };
};

/** Load EducationCategory list to store. */
const loadEducationCategoryList = (data: ICollectionData<IEducationCategory>): IPayloadAction<ICollectionData<IEducationCategory>> => {
  return {
    type: Actions.EDUCATION_CATEGORY_LIST_LOAD,
    payload: data,
  };
};

/** Clear EducationCategory list from store. Eg. when leaving list view. */
const clearEducationCategoryList = (): ILemonAction => {
  return {
    type: Actions.EDUCATION_CATEGORY_LIST_CLEAR,
  };
};

/** Store EducationCategory list filter to store. */
const storeEducationCategoryListFilter = (listFilter: IEducationCategoryListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(EDUCATION_CATEGORY_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchEducationCategoryEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_CATEGORY_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('EducationCategory')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationCategory(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EducationCategory_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_CATEGORY.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching EducationCategory', error);
      return o;
    })
  );
};

const createEducationCategoryEffect = (action$: Observable<IPayloadAction<IEducationCategoryCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_CATEGORY_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationCategory')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    tap(() => {
      // Education category list is a collection loaded on start of the app, should also be updated when something changes with elements it lists
      CollectionHelperService.fetchCollection<IEducationCategory>('EducationCategory');
    }),

    stopGlobalProgress(),

    ignoreElements(),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EducationCategory_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_CATEGORY.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating EducationCategory', error);
      return o;
    })
  );
};

const updateEducationCategoryEffect = (action$: Observable<IPayloadAction<IEducationCategory>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_CATEGORY_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('EducationCategory')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    tap(() => {
      // Education category list is a collection loaded on start of the app, should also be updated when something changes with elements it lists
      CollectionHelperService.fetchCollection<IEducationCategory>('EducationCategory');
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_CATEGORY.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating EducationCategory', error);
      return o;
    })
  );
};

const fetchEducationCategoryListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IEducationCategoryListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_CATEGORY_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationCategory')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationCategoryList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_CATEGORY.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching EducationCategory list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const educationCategory = (state: IEducationCategory | null = null, action: IPayloadAction<IEducationCategory>) => {
  if (action.type === Actions.EDUCATION_CATEGORY_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EDUCATION_CATEGORY_CLEAR) {
    return null;
  }

  return state;
};

const educationCategoryList = (state: ICollectionData<IEducationCategory> | null = null, action: IPayloadAction<ICollectionData<IEducationCategory>>) => {
  if (action.type === Actions.EDUCATION_CATEGORY_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EDUCATION_CATEGORY_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const EducationCategoryBusinessStore = {
  actions: {
    fetchEducationCategory,
    loadEducationCategory,
    clearEducationCategoryData,
    createEducationCategory,
    updateEducationCategory,
    fetchEducationCategoryList,
    loadEducationCategoryList,
    clearEducationCategoryList,
    storeEducationCategoryListFilter,
  },

  selectors: {
    getEducationCategory,
    getEducationCategoryList,
    getEducationCategoryListFilter,
  },

  effects: {
    fetchEducationCategoryEffect,
    createEducationCategoryEffect,
    updateEducationCategoryEffect,
    fetchEducationCategoryListEffect,
  },

  reducers: {
    educationCategory,
    educationCategoryList,
  },
};

// --
// export business store
export default EducationCategoryBusinessStore;

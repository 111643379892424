import React from 'react';
import { Link } from 'react-router';

import { ICompletedCourseUserActivity, ICreatedReminderUserActivity, ILeftCommentUserActivity, ILeftCommentUserActivityContent, IUserActivity, IVoteUserActivity, UserActivityItemTypeEnum } from '@src/model/activity/UserActivity';
import { CommentObjectTypeEnum } from '@src/model/comment/CommentObjectType';
import { VoteObjectTypeEnum } from '@src/model/comment/VoteObjectType';
import { IUserInfo } from '@src/model/user/User';
import LocalizeService from '@src/service/util/localize/LocalizeService';

export default class UserActivityUiHelper {
  /** Get user activity name. */
  static getActivityTitle = (activityItem: IUserActivity, currentUser: IUserInfo | undefined = undefined): React.ReactNode => {
    const isVoteActivity = UserActivityItemTypeEnum.ADDED_VOTE === activityItem.type?.id ||
      UserActivityItemTypeEnum.REMOVED_VOTE === activityItem.type?.id ||
      UserActivityItemTypeEnum.CHANGED_VOTE === activityItem.type?.id;
    let text;

    // --- completed course
    if (UserActivityItemTypeEnum.LEFT_COMMENT === activityItem.type?.id) {
      const item = activityItem as ILeftCommentUserActivity;

      // build title
      const creator = `${item.createdBy.firstName} ${item.createdBy.lastName}`;
      const action = LocalizeService.translate(`USER_ACTIVITY_LIST.TIMELINE_ACTION.${item.type?.name}`);
      const targetLink = UserActivityUiHelper.getLinkToObject(activityItem);
      const targetObject = targetLink ? <Link to={targetLink}>{item.content.object.name}</Link> : item.content.object.name;
      text = (
        <React.Fragment>
          {creator}&nbsp;{action}&nbsp;{targetObject}
        </React.Fragment>
      );
    }
    // --- completed course
    else if (UserActivityItemTypeEnum.COMPLETED_COURSE === activityItem.type?.id) {
      const item = activityItem as ICompletedCourseUserActivity;

      // build title
      const creator = `${item.createdBy.firstName} ${item.createdBy.lastName}`;
      const action = LocalizeService.translate(`USER_ACTIVITY_LIST.TIMELINE_ACTION.${item.type?.name}`);
      const targetLink = UserActivityUiHelper.getLinkToObject(activityItem);
      const targetObject = targetLink ? <Link to={targetLink}>{item.content.courseTitle}</Link> : item.content.courseTitle;
      text = (
        <React.Fragment>
          {creator}&nbsp;{action}&nbsp;{targetObject}
        </React.Fragment>
      );
    }
    // --- created reminder
    else if (UserActivityItemTypeEnum.CREATED_REMINDER === activityItem.type?.id) {
      const item = activityItem as ICreatedReminderUserActivity;

      // build title
      const creator = `${item.createdBy.firstName} ${item.createdBy.lastName}`;
      const action = LocalizeService.translate(`USER_ACTIVITY_LIST.TIMELINE_ACTION.${item.type?.name}`);

      text = (
        <React.Fragment>
          {creator}&nbsp;{action}
        </React.Fragment>
      );
    }
    // --- added/changed/removed vote
    else if (isVoteActivity) {
      const item = activityItem as IVoteUserActivity;

      // build title
      const creator = `${item.createdBy.firstName} ${item.createdBy.lastName}`;
      const action = LocalizeService.translate(`USER_ACTIVITY_LIST.TIMELINE_ACTION.${item.type?.name}`);
      const targetLink = UserActivityUiHelper.getLinkToObject(activityItem);
      const targetObject = targetLink ? <Link to={targetLink}>{item.content.object.name}</Link> : item.content.object.name;

      text = (
        <React.Fragment>
          {creator}&nbsp;{action}&nbsp;{targetObject}
        </React.Fragment>
      );
    }
    // --- unknown?!
    else {
      text = `${activityItem.createdBy.firstName} ${activityItem.createdBy.lastName} ---`;
      console.warn(`Cannot resolve name for unknown user activity type: ${activityItem.type?.id}`);
    }

    return text;
  };

  /** Get link to user activity's target object. */
  static getLinkToObject = (activityItem: IUserActivity): string | undefined => {
    const isVoteActivity = UserActivityItemTypeEnum.ADDED_VOTE === activityItem.type?.id ||
      UserActivityItemTypeEnum.REMOVED_VOTE === activityItem.type?.id ||
      UserActivityItemTypeEnum.CHANGED_VOTE === activityItem.type?.id;
    let link;

    // --- completed course
    if (UserActivityItemTypeEnum.LEFT_COMMENT === activityItem.type?.id) {
      // TODO: activity.content.objectType should contain type id, not name - then remove (CommentObjectTypeEnum as any)

      const item = activityItem as ILeftCommentUserActivity;
      // --- link to course
      if (CommentObjectTypeEnum.COURSE === item.content.objectType.id) {
        link = `course/${item.content.object.id}`;
      }
      // --- link to course lecture
      else if (CommentObjectTypeEnum.LECTURE === item.content.objectType.id) {
        link = `course/lecture/${item.content.object.id}`;
      }
      // --- link to course lecture
      else if (CommentObjectTypeEnum.WEBINAR === item.content.objectType.id) {
        link = `webinar/${item.content.object.id}`;
      }
      // unknown?
      else {
        console.warn(`Cannot create link to unknown LEFT_COMMENT user activity object type: ${item.content.objectType}`);
      }
    }
    // --- completed course
    else if (UserActivityItemTypeEnum.COMPLETED_COURSE === activityItem.type?.id) {
      const item = activityItem as ICompletedCourseUserActivity;
      link = `course/${item.content.courseId}`;
    }
    // --- added/changed/removed vote
    else if (isVoteActivity) {
      const item = activityItem as IVoteUserActivity;
      // --- link to course
      if (VoteObjectTypeEnum.COURSE === item.content.objectType.id) {
        link = `course/${item.content.object.id}`;
      }
      // --- link to course lecture
      else if (VoteObjectTypeEnum.LECTURE === item.content.objectType.id) {
        link = `course/lecture/${item.content.object.id}`;
      }
      // --- link to webinar
      else if (VoteObjectTypeEnum.WEBINAR === item.content.objectType.id) {
        link = `webinar/${item.content.object.id}`;
      }
      // unknown?
      else {
        console.warn(`Cannot create link to unknown VOTE user activity object type: ${item.content.objectType}`);
      }
    }
    // --- unknown?!
    else {
      // NOOP
      console.warn(`Cannot create link to unknown user activity type: ${activityItem.type?.id}`);
    }

    return link;
  };

  static getActivityContent = (activityItem: IUserActivity): string => {
    if (UserActivityItemTypeEnum.LEFT_COMMENT === activityItem.type?.id) {
      const content = activityItem.content as ILeftCommentUserActivityContent;
      return content.commentContent;
    } else {
      return '';
    }
  }
}

import ListPagination from '@src/components/common/list/ListPagination';
import RepositoryUserList from '@src/components/repository/user/RepositoryUserList';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React from 'react';
import { connect } from 'react-redux';

const repoPageSize = AppConfigService.getValue('api.paging.midPageSize');

// -- Prop types
// ----------

export interface IRepositoryUsersContainerOwnProps {
  currentTags: string[];
}

export interface IRepositoryUsersContainerStateProps {
  userList: ICollectionData<IUserInfo>;
  userListFilter: IUserListFilter;
}

export interface IRepositoryUsersContainerDispatchProps {
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => any;
  clearUserList: () => void;
  storeUserListFilter: (filter: IUserListFilter) => void;
  clearUserListFilter: () => void;
}
type IRepositoryUsersContainerProps = IRepositoryUsersContainerOwnProps & IRepositoryUsersContainerStateProps & IRepositoryUsersContainerDispatchProps;

interface IRepositoryUsersContainerState {
  page: number;
  size: number;
  sort: string[];
}

// -- Component
// ----------

/** Repository users container component */
class RepositoryUsersContainer extends React.Component<IRepositoryUsersContainerProps> {
  state: IRepositoryUsersContainerState = {
    page: 0,
    size: repoPageSize,
    sort: [],
  };

  componentDidMount = () => {
    this.props.storeUserListFilter({ tags: this.props.currentTags });
  }

  componentDidUpdate = (prevProps: IRepositoryUsersContainerProps, prevState: IRepositoryUsersContainerState) => {
    if (this.props.currentTags !== prevProps.currentTags) {
      this.props.storeUserListFilter({ ...this.props.userListFilter, tags: this.props.currentTags });
    }
    if (this.state !== prevState || this.props.userListFilter !== prevProps.userListFilter) {
      this.updateList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearUserList();
    this.props.clearUserListFilter();
  }

  render = () => {
    return <React.Fragment>
      <div className="timun-repository__pagination">
        {this.props.userList && <ListPagination page={this.props.userList.page} onChange={this.handlePageChange} />}
      </div>
      {this.props.userList && <RepositoryUserList userList={this.props.userList.content} />}
      {this.props.userList && <ListPagination page={this.props.userList.page} onChange={this.handlePageChange} />}
    </React.Fragment>;
  }

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  }

  private updateList() {
    this.props.fetchUserList({
      filter: { ...this.props.userListFilter },
      page: this.state.page,
      size: this.state.size,
      sort: this.state.sort,
    });
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IRepositoryUsersContainerStateProps => ({
  userList: UserListBusinessStore.selectors.getUserList(state),
  userListFilter: UserListBusinessStore.selectors.getUserListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IRepositoryUsersContainerDispatchProps => ({
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => dispatch(UserListBusinessStore.actions.fetchUserList({ ...params })),
  clearUserList: () => dispatch(UserListBusinessStore.actions.clearUserList()),
  storeUserListFilter: (filter: IUserListFilter) => dispatch(UserListBusinessStore.actions.storeUserListFilter(filter)),
  clearUserListFilter: () => dispatch(UserListBusinessStore.actions.clearUserListFilter()),
});

export default connect<IRepositoryUsersContainerStateProps, IRepositoryUsersContainerDispatchProps, IRepositoryUsersContainerOwnProps>(mapStateToProps, mapDispatchToProps)(RepositoryUsersContainer);

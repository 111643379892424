import LemonIcon from '@src/components/common/image/LemonIcon';
import { IListType } from '@src/components/common/list/ListTypePicker';
import MemberCardList from '@src/components/common/member/MemberCardList';
import MemberFormModal from '@src/components/common/member/MemberFormModal';
import MemberTableList from '@src/components/common/member/MemberTableList';
import { IMemberUser } from '@src/model/common/MemberUser';
import { IReference } from '@src/model/common/Reference';
import { ICollectionData } from '@src/service/business/common/types';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Col, Divider, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface IRoleTypes {
  value: string;
  label: string;
}

export interface IMemberListOwnProps<T> {
  memberList: ICollectionData<IMemberUser<T>>;
  canOpenProfile?: boolean;
  canAddMembers?: boolean;
  canEditMember?: (member: IMemberUser<T>) => boolean;
  defaultListType: IListType;
  roleTypes: IRoleTypes[];
  onPageChange?: (page?: number, size?: number) => void;
  onAddMember?: (user: IMemberUser<T>) => void;
  onUpdateMember?: (user: IMemberUser<T>) => void;
  onDeleteMember?: (user: IMemberUser<T>) => void;

}

type IMemberListProps<T> = IMemberListOwnProps<T>;

interface IMemberListState<T> {
  listType: IListType;
  isEditModalVisible: boolean;
  isCreateModalVisible: boolean;
  selectedMember?: IMemberUser<T>;
}

// -- Component
// ----------

class MemberList<T extends IReference> extends React.Component<IMemberListProps<T>, IMemberListState<T>> {
  state: IMemberListState<T> = {
    listType: this.props.defaultListType ?? 'card',
    isEditModalVisible: false,
    isCreateModalVisible: false,
    selectedMember: undefined,
  };

  toggleUpdateOrganizationModal = (member?: IMemberUser<T>) => {
    this.setState({ selectedMember: member, isEditModalVisible: !!member });
  };

  toggleCreateOrganizationModal = (isVisible: boolean) => {
    this.setState({ isCreateModalVisible: isVisible });
  };

  handleCreateModalClose = () => {
    this.toggleCreateOrganizationModal(false);
  };

  handleUpdateModalClose = () => {
    this.toggleUpdateOrganizationModal();
  };

  handleListTypeChange = (listType: IListType) => {
    this.setState({ listType });
  };

  render = () => {
    return (
      <React.Fragment>

        <React.Fragment>
          <Row justify="space-between" className="timun-wrapper__gutterBox">
            <Col>
              {/* <ListTypePicker value={this.state.listType} onChange={this.handleListTypeChange} /> TODO: define this for whole app*/}
            </Col>
            {this.props.canAddMembers &&
              <Col>
                <Button type="primary" icon={<LemonIcon name="plus" />} onClick={() => this.toggleCreateOrganizationModal(true)}>
                  {LocalizeService.translate('ORGANIZATION_VIEW.NEW_MEMBER_BUTTON')}
                </Button>
              </Col>
            }
          </Row>
          <Divider />
        </React.Fragment>


        {this.state.listType === 'card' &&
          <MemberCardList<T>
            memberList={this.props.memberList}
            roleTypes={this.props.roleTypes}
            onPageChange={this.props.onPageChange}
            canEditMember={this.props.canEditMember}
            onUpdateMember={this.toggleUpdateOrganizationModal}
            onDeleteMember={this.props.onDeleteMember}
            canOpenProfile={this.props.canOpenProfile}
          />
        }

        {this.state.listType === 'table' &&
          <MemberTableList<T>
            memberList={this.props.memberList}
            roleTypes={this.props.roleTypes}
            onPageChange={this.props.onPageChange}
            canEditMember={this.props.canEditMember}
            onUpdateMember={this.toggleUpdateOrganizationModal}
            onDeleteMember={this.props.onDeleteMember}
            canOpenProfile={this.props.canOpenProfile}
          />
        }

        {/* Modals */}
        {this.state.isCreateModalVisible &&
          <MemberFormModal
            roleTypes={this.props.roleTypes}
            title={LocalizeService.translate('COMMON.ACTION_ADD')}
            onCancel={() => this.toggleCreateOrganizationModal(false)}
            onSubmit={this.onAddMember}
          />
        }
        {this.state.isEditModalVisible && this.state.selectedMember &&
          <MemberFormModal
            member={this.state.selectedMember}
            roleTypes={this.props.roleTypes}
            title={LocalizeService.translate('COMMON.ACTION_EDIT')}
            onCancel={() => this.toggleUpdateOrganizationModal()}
            onSubmit={this.onUpdateMember}
          />
        }
      </React.Fragment>
    );
  };

  onAddMember = (member: IMemberUser<T>) => {
    this.props.onAddMember?.(member);
    this.toggleCreateOrganizationModal(false);
  };

  onUpdateMember = (member: IMemberUser<T>) => {
    this.props.onUpdateMember?.(member);
    this.toggleUpdateOrganizationModal();
  };

}

// -- HOCs and exports
// ----------

export default MemberList;

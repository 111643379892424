import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export interface IExternalEducationApplicationTimelineActivityListFilter {}

// -
// -------------------- Selectors
const getExternalEducationApplicationTimeline = (store: any): ICollectionData<IExternalEducationApplicationTimelineActivity> => store.externalEducationApplicationTimelineList;

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_FETCH: 'EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_FETCH',
  EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_LOAD: 'EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_LOAD',
  EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_CLEAR: 'EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_CLEAR',
};

// NOTE: remove Partial from payload type if timeline has to turn to proper list
const fetchExternalEducationApplicationTimelineList = (id: string, page?: number, size?: number, sort?: string[], filter?: IExternalEducationApplicationTimelineActivityListFilter): IPayloadAction<IIdDataPayload<Partial<ICollectionFetchPayload<IExternalEducationApplicationTimelineActivityListFilter>>>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_FETCH,
    payload: {
      id,
      data: {
        filter,
        page,
        size,
        sort,
      },
    },
  };
};

const loadExternalEducationApplicationTimelineList = (data: ICollectionData<IExternalEducationApplicationTimelineActivity>): IPayloadAction<ICollectionData<IExternalEducationApplicationTimelineActivity>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_LOAD,
    payload: data,
  };
};

const clearExternalEducationApplicationTimelineList = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchExternalEducationApplicationTimelineListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IExternalEducationApplicationTimelineActivityListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;
      const params = action.payload.data;

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .fetchSubentityList(id, 'timeline', params)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationApplicationTimelineList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching externalEducationApplication timeline list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const externalEducationApplicationTimelineList = (state: ICollectionData<IExternalEducationApplicationTimelineActivity> | null = null, action: IPayloadAction<ICollectionData<IExternalEducationApplicationTimelineActivity>>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_LOAD) {
    return { ...action.payload };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_TIMELINE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

const TimelineBusinessStore = {
  actions: {
    fetchExternalEducationApplicationTimelineList,
    clearExternalEducationApplicationTimelineList,
  },

  selectors: {
    getExternalEducationApplicationTimeline,
  },

  effects: {
    fetchExternalEducationApplicationTimelineListEffect,
  },

  reducers: {
    externalEducationApplicationTimelineList,
  },
};

// --
// export business store

export default TimelineBusinessStore;

import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NoteListFilter from '@src/components/user/update/NoteListFilter';
import NoteUpdateContainer from '@src/components/user/update/NoteUpdateContainer';
import NoteListExportButton from '@src/components/user/view/NoteListExportButton';
import NoteListView from '@src/components/user/view/NoteListView';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { IFile } from '@src/model/file/File';
import { INote, NoteObjectTypeEnum } from '@src/model/user/Note';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import UserNoteBusinessStore, { INoteListFileCreatePayload, INoteListFilter } from '@src/service/business/usernotes/userNoteBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const DEFAULT_SORT_VALUE = 'addedDateTime,desc';

// --
// ----- Prop types
interface INoteListViewContainerOwnProps {
  objectId: string;
  type: NoteObjectTypeEnum;
  canSetPrivateNote?: boolean;
  canEditnote: boolean | ((note: INote) => boolean);
  canAddNote?: boolean;
}

interface INoteListViewContainerStateProps {
  notes: ICollectionData<INote>;
  noteListFilter: INoteListFilter;
}

interface INoteListViewContainerDispatchProps {
  fetchNoteList: (noteListFilter: INoteListFilter, size: number, page: number, sort: string[]) => void;
  storeFilter: (noteListFilter: INoteListFilter) => void;
  deleteNote: (id: string) => ITrackableAction;
  createNoteListFile: (noteListRefObject: INoteListFileCreatePayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type INoteListViewContainerProps = INoteListViewContainerOwnProps & INoteListViewContainerStateProps & INoteListViewContainerDispatchProps & IWithLocalizeOwnProps;
// --
// ----- State types
interface INoteListViewContainerState {
  page: number;
  size: number;
  sort: string;
  isEditModalVisible: boolean;
  selectedNote?: INote;
  noteListFileId?: string;
}

// --
// ----- Component
class NoteListViewContainer extends React.Component<INoteListViewContainerProps, INoteListViewContainerState> {
  state: INoteListViewContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: DEFAULT_SORT_VALUE,
    isEditModalVisible: false,
    selectedNote: undefined,
    noteListFileId: undefined,
  };

  componentDidMount() {
    let noteListFilter: INoteListFilter = { ...this.props.noteListFilter }; // because if noteListFilter gets here with some data in it, we want to have it
    if (this.props.objectId) {
      noteListFilter = {
        objectId: parseInt(this.props.objectId),
        type: this.props.type,
      };
      this.handleFilterChange(noteListFilter);
    }
  }

  componentDidUpdate(prevProps: INoteListViewContainerProps, prevState: INoteListViewContainerState) {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || this.props.noteListFilter !== prevProps.noteListFilter) {
      this.fetchNoteList();
    }
  }

  render() {
    return (
      <div className="timun-noteListViewContainer__content">
        {this.props.canAddNote && <NoteCreateContainer className="timun-wrapper__gutterBox--vertical float-right" buttonType="primary" objectId={this.props.objectId} objectTypeId={this.props.type} canSetPrivateNote={this.props.canSetPrivateNote} onCreateNote={this.handleCreateNote} />}

        {/* Notes list filter */}
        <NoteListFilter onSortChange={this.handleSortChange} />
        {/* Pagination */}
        {this.props.notes && <ListPagination page={this.props.notes.page} onChange={this.handlePageChange} />}
        <NoteListView canEditnote={this.props.canEditnote} onEditNote={this.handleEditNote} onDeleteNote={this.handleDeleteNote} notes={this.props.notes ? this.props.notes.content : []} />

        {/* Pagination */}
        {this.props.notes && <ListPagination page={this.props.notes.page} onChange={this.handlePageChange} />}

        {/* Notes list export button */}
        {this.props.notes && <NoteListExportButton numberOfNotes={this.props.notes.page.totalElements} noteListId={this.props.objectId} objectTypeId={this.props.type} onExport={this.handleNoteListFileExport} noteListFileId={this.state.noteListFileId} />}

        {/* Modal for note editing */}
        {this.state.isEditModalVisible && <NoteUpdateContainer onChange={this.handleNoteChange} note={this.state.selectedNote} onCancel={this.handleClose} canSetPrivateNote={this.props.canSetPrivateNote} />}
      </div>
    );
  }

  handleNoteChange = () => {
    this.fetchNoteList();
    this.handleClose();
  };

  handleClose = () => {
    this.toggleUserNoteEditModal(false);
  };

  toggleUserNoteEditModal = (isVisible: boolean) => {
    this.setState({ isEditModalVisible: isVisible });
  };

  handleCreateNote = () => {
    this.fetchNoteList();
  };

  handleEditNote = (selectedNote: INote) => {
    this.setState({ selectedNote }, () => {
      this.toggleUserNoteEditModal(true);
    });
  };

  handleDeleteNote = (note: INote) => {
    this.props
      .deleteNote(note.id)
      .track()
      .subscribe(() => {
        this.fetchNoteList();
        this.props.reportMessage({ message: this.props.translate('NOTE_CONTAINER.NOTE_DELETED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleFilterChange = (noteListFilter: INoteListFilter) => {
    this.props.storeFilter(noteListFilter);
    this.setState({ page: 0 });
  };

  handleSortChange = (sort: string) => {
    this.setState({ page: 0, sort });
  };

  handleNoteListFileExport = (noteListRefObject: INoteListFileCreatePayload) => {
    this.props
      .createNoteListFile(noteListRefObject)
      .track()
      .subscribe(
        // success
        (response: IFile) => this.setState({ noteListFileId: response.id })
      );
  };

  private fetchNoteList = (filter = this.props.noteListFilter, pageNumber = this.state.page, pageSize = this.state.size, sort = this.state.sort): void => {
    this.setState({ noteListFileId: undefined });
    this.props.fetchNoteList(filter, pageSize, pageNumber - 1, [sort]);
  };
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: INoteListViewContainerOwnProps): INoteListViewContainerStateProps => ({
  notes: UserNoteBusinessStore.selectors.getUserNotes(state),
  noteListFilter: UserNoteBusinessStore.selectors.getNoteListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: INoteListViewContainerOwnProps): INoteListViewContainerDispatchProps => ({
  fetchNoteList: (noteListFilter: INoteListFilter, size: number, page: number, sort: string[]) => dispatch(UserNoteBusinessStore.actions.fetchUserNoteList(noteListFilter, size, page, sort)),
  storeFilter: (noteListFilter: INoteListFilter) => dispatch(UserNoteBusinessStore.actions.storeNoteListFilter(noteListFilter)),
  deleteNote: (id: string) => createTrackableAction(dispatch(UserNoteBusinessStore.actions.deleteUserNote(id))),
  createNoteListFile: (noteListRefObject: INoteListFileCreatePayload) => createTrackableAction(dispatch(UserNoteBusinessStore.actions.createNoteListFile(noteListRefObject))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<INoteListViewContainerStateProps, INoteListViewContainerDispatchProps, INoteListViewContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(NoteListViewContainer as any));

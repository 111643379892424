import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import PublicSurveyInstanceView from '@src/components/survey/view/PublicSurveyInstanceView';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';
import PublicSurveyInstanceBusinessStore, { ISurveyInstanceSubmitPayload } from '@src/service/business/survey/PublicSurveyInstanceBusinessStore';
import UserSettingsBusinessStore, { IUserSettings } from '@src/service/business/usersettings/userSettingsBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IPublicSurveyInstanceContainerOwnProps {
  surveyInstanceToken: string;
}

export interface IPublicSurveyInstanceContainerStateProps {
  surveyInstance: ISurveyInstance;
  userSettings: IUserSettings;
}

export interface IPublicSurveyInstanceContainerDispatchProps {
  fetchSurveyInstance: (token: string) => ITrackableAction;
  submitSurveyInstance: (data: ISurveyInstanceSubmitPayload) => ITrackableAction;
}

type IPublicSurveyInstanceContainerProps = IPublicSurveyInstanceContainerOwnProps & IPublicSurveyInstanceContainerStateProps & IPublicSurveyInstanceContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

interface IPublicSurveyInstanceContainerState {}

// -- Component
// ----------

/** Container component for displaying survey instance */
class PublicSurveyInstanceContainer extends React.Component<IPublicSurveyInstanceContainerProps, IPublicSurveyInstanceContainerState> {
  state: IPublicSurveyInstanceContainerState = {};

  componentDidMount() {
    this.fetchSurveyInstance();
  }

  componentDidUpdate(prevProps: IPublicSurveyInstanceContainerProps, prevState: IPublicSurveyInstanceContainerState) {
    if (prevProps.surveyInstanceToken !== this.props.surveyInstanceToken) {
      this.fetchSurveyInstance();
    }
  }

  render = () => {
    return <React.Fragment>{this.props.surveyInstance && <PublicSurveyInstanceView locale={this.props.userSettings.locale} showEvaluationFeedback={false} surveyInstance={this.props.surveyInstance} onSubmit={this.handleSurveyInstanceSubmit} />}</React.Fragment>;
  };

  handleSurveyInstanceSubmit = (surveyInstance: ISurveyInstance) => {
    const surveyInstanceData: ISurveyInstanceSubmitPayload = {
      token: this.props.surveyInstanceToken,
      surveyInstance,
    };
    this.props
      .submitSurveyInstance(surveyInstanceData)
      .track()
      .subscribe(
        // success
        () => this.props.router.push('/public/surveyinstance/notification')
      );
  };

  fetchSurveyInstance = () => {
    if (this.props.surveyInstanceToken) {
      this.props.fetchSurveyInstance(this.props.surveyInstanceToken);
    }
  };
}
// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IPublicSurveyInstanceContainerOwnProps): IPublicSurveyInstanceContainerStateProps => ({
  surveyInstance: PublicSurveyInstanceBusinessStore.selectors.getPublicSurveyInstance(state),
  userSettings: UserSettingsBusinessStore.selectors.getUserSettings(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IPublicSurveyInstanceContainerDispatchProps => ({
  fetchSurveyInstance: (token: string) => createTrackableAction(dispatch(PublicSurveyInstanceBusinessStore.actions.fetchPublicSurveyInstance(token))),
  submitSurveyInstance: (data: ISurveyInstanceSubmitPayload) => createTrackableAction(dispatch(PublicSurveyInstanceBusinessStore.actions.submitPublicSurveyInstance(data))),
});

// -- HOCs and exports
// ----------
export default connect<IPublicSurveyInstanceContainerStateProps, IPublicSurveyInstanceContainerDispatchProps, IPublicSurveyInstanceContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(PublicSurveyInstanceContainer as any)));

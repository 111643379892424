import IIdRef from '@src/model/common/IdRef';
import { IReport, IReportTypeEnum } from '@src/model/report/Report';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

export interface IReportListFilter {
  // for possible future use
}

export interface IReportCreatePayload {
  reportType: IReportTypeEnum;
  filters: IReportFilterSearchCriteria;
}

export interface IReportFilterSearchCriteria extends IExternalEducationApplicationListFilter {
  userGroupIds?: string[];
  courseId?: string;
}

// List filter ID
const REPORT_LIST_FILTER = '@@REPORTS_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns list of reports from store. */
const getReportList = (store: any): ICollectionData<IReport> => store.reportList;

/** Returns report list filter. */
const getReportListFilter = (store: any): IReportListFilter => ListFilterBusinessStore.selectors.getListFilter(store, REPORT_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  REPORT_LIST_FETCH: 'REPORT_LIST_FETCH',
  REPORT_LIST_LOAD: 'REPORT_LIST_LOAD',
  REPORT_CREATE: 'REPORT_CREATE',
  REPORT_DELETE: 'REPORT_DELETE',
};

/** Fetch report list by filter. Forces default REPORT_LIST_PAYLOAD_BASE. */
const fetchReportList = (params: ICollectionFetchPayload<IReportListFilter>): IPayloadAction<ICollectionFetchPayload<IReportListFilter>> => {
  return {
    type: Actions.REPORT_LIST_FETCH,
    payload: params,
  };
};

/** Load report list to store. */
const loadReportList = (data: ICollectionData<IReport>): IPayloadAction<ICollectionData<IReport>> => {
  return {
    type: Actions.REPORT_LIST_LOAD,
    payload: data,
  };
};

/** Create new report */
const createReport = (data: IReportCreatePayload): IPayloadAction<IReportCreatePayload> => {
  return {
    type: Actions.REPORT_CREATE,
    payload: data,
  };
};

/** Delete report by ID */
const deleteReport = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.REPORT_DELETE,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const fetchReportListEffect = (action$: Observable<IPayloadAction<IReportListFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPORT_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Report')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadReportList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'REPORTS_LIST.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching report list', error);
      return o;
    })
  );
};

const createReportEffect = (action$: Observable<IPayloadAction<IReportCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPORT_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Report')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'REPORTS_LIST.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating report', error);
      return o;
    })
  );
};

const deleteReportEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPORT_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Report')
        .deleteEntity(data.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => ({ message: LocalizeService.translate('REPORTS.DELETED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO })),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'REPORTS_LIST.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting report', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const reportList = (state: ICollectionData<IReport> | null = null, action: IPayloadAction<ICollectionData<IReport>>) => {
  if (action.type === Actions.REPORT_LIST_LOAD) {
    return {
      ...action.payload,
    };
  }
  return state;
};

// --
// -------------------- Business Store

export const ReportsBusinessStore = {
  actions: {
    fetchReportList,
    loadReportList,
    createReport,
    deleteReport,
  },
  selectors: {
    getReportList,
    getReportListFilter,
  },
  effects: {
    fetchReportListEffect,
    createReportEffect,
    deleteReportEffect,
  },
  reducers: {
    reportList,
  },
};

// --
// export business store
export default ReportsBusinessStore;

import { IBaseUserActivity } from '@src/model/activity/UserActivity';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';

export interface IExternalEducationTemplateTimelineActivity {
  content: IBaseUserActivity<ICodeBookEntry<ExternalEducationTemplateTimelineActivityItemTypeEnum>, null>;
  type: ExternalEducationTemplateTimelineActivityTypeEnum;
  createdAt: string;
}

export enum ExternalEducationTemplateTimelineActivityTypeEnum {
  ACTION = 'ACTION',
  NOTE = 'NOTE',
}

export enum ExternalEducationTemplateTimelineActivityItemTypeEnum {
  APPROVAL_REQUESTED = '1',
  APPROVAL_IN_PROCESS = '2',
  CHANGE_REQUESTED = '3',
  CHANGE_SUBMITTED = '4',
  DENIED = '5',
  APPROVED = '6',
  CANCELED = '7',
}

import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamInstanceHelperUtils from '@src/components/exam/common/ExamInstanceHelperUtils';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { ICourseProgress } from '@src/model/course/Course';
import { ICourseExam } from '@src/model/course/CourseExam';
import { Col, Row, Tooltip } from 'antd';

// -- Prop types
// ----------

export interface ICourseProgressInfoOwnProps {
  progress?: ICourseProgress;
  exam?: ICourseExam;
}
type ICourseProgressInfoProps = ICourseProgressInfoOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display course deadline date and exam results */
const CourseProgressInfo: React.FC<ICourseProgressInfoProps> = (props: ICourseProgressInfoProps) => {
  return (
    <Row align="bottom" justify="space-between">
      <Col className={props.progress?.deadlineDate ? '' : 'visibilty-hidden'}>
        {
          <Tooltip title={props.translate('COURSE_PROGRESS_INFO.DEADLINE_DATE_TOOLTIP_LABEL')}>
            <LemonIcon name="deadlineDate" /> {props.progress?.deadlineDate}
          </Tooltip>
        }
      </Col>
      <Col className={props.exam?.examInstance?.activities && ExamInstanceHelperUtils.isExamInstancenInPhase(props.exam.examInstance, [ActivityPhaseEnum.EVALUATED]) ? '' : 'visibilty-hidden'}>
        {props.exam?.examInstance?.activities && (
          <Tooltip title={props.translate('COURSE_PROGRESS_INFO.EARNED_POINTS_TOOLTIP_LABEL')}>
            <LemonIcon name="spellCheck" /> {ExamInstanceHelperUtils.getTotalAndEarnedPointsRatio(props.exam.examInstance)}
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

export default withLocalize<ICourseProgressInfoOwnProps>(CourseProgressInfo as any);

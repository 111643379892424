import React from 'react';

import { HtmlViewer } from '@src/components/common/htmlviewer/HtmlViewer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Button, Modal } from 'antd';


// -- Prop types
// ----------

export interface IExternalEducationApplicationFilePreviewOwnProps {
  title: string;
  htmlFilePreview: string;
  onCancel?: () => void;
}
type IExternalEducationApplicationFilePreviewProps = IExternalEducationApplicationFilePreviewOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationApplicationFilePreview: React.FC<IExternalEducationApplicationFilePreviewProps> = (props: IExternalEducationApplicationFilePreviewProps) => {

  return (
    <Modal
      className="lemon-modal__modal--lg"
      title={props.title}
      visible={true}
      maskClosable={false}
      onCancel={props.onCancel}
      footer={[
        <Button key="back" onClick={props.onCancel}>
          {props.translate('COMMON.ACTION_OK')}
        </Button>]}
    >
      <HtmlViewer size='large' dangerousValue={props.htmlFilePreview} />
    </Modal >
  );
};

export default withLocalize<IExternalEducationApplicationFilePreviewOwnProps>(ExternalEducationApplicationFilePreview as any);

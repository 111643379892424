import React from 'react';

import FileTypeUploadField from '@src/components/common/file/FileTypeUploadField';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { FileTypeEnum } from '@src/model/file/FileType';
import { IFile } from '@src/model/file/File';
import { IFileListsByType } from '@src/service/business/files/util';
import { Modal } from 'antd';


// -- Prop types
// ----------

export interface IFileTypeUploadModalOwnProps {
  fileList?: IFileListsByType;
  defaultFileType?: keyof typeof FileTypeEnum;
  allowedFileTypes?: (keyof typeof FileTypeEnum)[];
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onCancel?: () => void;
}
type IFileTypeUploadModalProps = IFileTypeUploadModalOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** File list modal for file upload and remove for specific file type */
const FileTypeUploadModal: React.FC<IFileTypeUploadModalProps> = (props: IFileTypeUploadModalProps) => {

  return (
    <Modal
      className="lemon-modal__modal--md"
      title={props.translate('COMMON.LABEL.FILE_UPLOAD')}
      visible={true}
      onCancel={props.onCancel}
      footer={null}
    >
      <FileTypeUploadField
        fileList={props.fileList}
        defaultFileType={props.defaultFileType}
        allowedFileTypes={props.allowedFileTypes}
        onFileUpload={props.onFileUpload}
        onFileRemove={props.onFileRemove}
      />
    </Modal>
  );
};

export default withLocalize<IFileTypeUploadModalOwnProps>(FileTypeUploadModal as any);

import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import OrganizationMemberList from '@src/components/organization/members/OrganizationMemberList';
import { IOrganization } from '@src/model/organization/Organization';
import { IOrganizationMember } from '@src/model/organization/OrganizationMember';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import OrganizationBusinessStore, { IOrganizationMemberCreatePayload, IOrganizationMemberListFilter } from '@src/service/business/organization/OrganizationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.paging.midPageSize');
const VIEW_NAME = '@@ORGANIZATION_MEMBER_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<IOrganizationMemberListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
};

// -- Prop types
// ----------
export interface IOrganizationMemberListContainerOwnProps {
  organization: IOrganization;
}

export interface IOrganizationMemberListContainerStateProps {
  organizationMembers: ICollectionData<IOrganizationMember>;
}
export interface IOrganizationMemberListContainerDispatchProps {
  fetchOrganizationMembers: (organizationId: string, params: ICollectionFetchPayload<IOrganizationMemberListFilter>) => void;
  addOrganizationMembers: (profileId: string, data: IOrganizationMemberCreatePayload[]) => ITrackableAction;
  updateOrganizationMembers: (profileId: string, data: IOrganizationMember) => ITrackableAction;
  removeOrganizationMembers: (profileId: string, data: IOrganizationMember[]) => ITrackableAction;
  clearOrganizationMembers: () => void;
}

type IOrganizationMemberListContainerProps = IOrganizationMemberListContainerOwnProps & IOrganizationMemberListContainerStateProps & IOrganizationMemberListContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IOrganizationMemberListFilter>;

interface IOrganizationMemberListContainerState {
}
// -- Component
// ----------

class OrganizationMemberListContainer extends React.Component<IOrganizationMemberListContainerProps, IOrganizationMemberListContainerState> {
  state = {
  };


  componentDidMount() {
    this.updateList();
  }

  componentDidUpdate(prevProps: IOrganizationMemberListContainerProps, prevState: IOrganizationMemberListContainerState) {
    if (this.props.collectionParams !== prevProps.collectionParams || this.props.organization.id !== prevProps.organization.id) {
      this.updateList();
    }
  }

  componentWillUnmount = () => {
    this.props.clearOrganizationMembers();
  };


  render() {
    return (
      <React.Fragment>
        {this.props.organizationMembers &&
          <OrganizationMemberList
            organizationMemberList={this.props.organizationMembers}
            organization={this.props.organization}
            onPageChange={this.props.updateCollectionParams.onPageChange}
            onAddMember={this.handleMemberAdd}
            onUpdateMember={this.handleMemberUpdate}
            onDeleteMember={this.handleMemberDelete}
          />
        }
      </React.Fragment>
    );
  }

  handleMemberAdd = (member: IOrganizationMember) => {
    this.props.addOrganizationMembers(this.props.organization.id, [member]).track().subscribe(
      // success
      () => this.updateList()
    );
  };

  handleMemberUpdate = (member: IOrganizationMember) => {
    this.props.updateOrganizationMembers(this.props.organization.id, member).track().subscribe(
      // success
      () => this.updateList()
    );
  };

  handleMemberDelete = (member: IOrganizationMember) => {
    this.props.removeOrganizationMembers(this.props.organization.id, [member]).track().subscribe(
      // success
      () => this.updateList()
    );
  };

  private fetchOrganizationMembers = (params: ICollectionFetchPayload<IOrganizationMemberListFilter>) => {
    this.props.fetchOrganizationMembers(
      this.props.organization.id,
      params);
  };

  private updateList = () => {
    this.props.onUpdateList(this.fetchOrganizationMembers);
  };

}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IOrganizationMemberListContainerStateProps => ({
  organizationMembers: OrganizationBusinessStore.selectors.getOrganizationMemberList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IOrganizationMemberListContainerDispatchProps => ({
  fetchOrganizationMembers: (organizationId: string, params: ICollectionFetchPayload<IOrganizationMemberListFilter>) => dispatch(OrganizationBusinessStore.actions.fetchOrganizationMemberList(organizationId, params)),
  addOrganizationMembers: (organizationId: string, data: IOrganizationMemberCreatePayload[]) => createTrackableAction(dispatch(OrganizationBusinessStore.actions.addOrganizationMembers(organizationId, data))),
  updateOrganizationMembers: (organizationId: string, data: IOrganizationMember) => createTrackableAction(dispatch(OrganizationBusinessStore.actions.updateOrganizationMembers(organizationId, data))),
  removeOrganizationMembers: (organizationId: string, data: IOrganizationMember[]) => createTrackableAction(dispatch(OrganizationBusinessStore.actions.removeOrganizationMembers(organizationId, data))),
  clearOrganizationMembers: () => dispatch(OrganizationBusinessStore.actions.clearOrganizationMemberList()),
});

export default connect<IOrganizationMemberListContainerStateProps, IOrganizationMemberListContainerDispatchProps, IOrganizationMemberListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(OrganizationMemberListContainer as any)));

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { Layout, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router';


const { Item } = Menu;
const { Content } = Layout;


// -- Prop types
// ----------
enum AdministrationSkillsRouteEnum {
  SKILL_GROUP_ROUTE = 'skillgroup',
  SKILLS_GAP_ROUTE = 'skillsgap',
}

export interface IAdministrationSkillViewSiderOwnProps {
  selected: string;
}

type IAdministrationSkillViewSiderProps = IAdministrationSkillViewSiderOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

const IAdministrationSkillViewSider: React.FC<IAdministrationSkillViewSiderProps> = (props: IAdministrationSkillViewSiderProps) => {
  return (
    <Layout>
      <Content>
        <Menu className="timun-courseView__siderMenu" mode="inline" selectedKeys={[props.selected]}>
          {props.isTenantPropEnabled('admin_skills') && <Item key={AdministrationSkillsRouteEnum.SKILL_GROUP_ROUTE} data-test-id="timun-courseViewSider__skillGroupMenuItem">
            <Link to="skillsadministration/skillgroup">
              {props.translate('ADMINISTRATION_SKILL_VIEW.SIDER_MENU.SKILL_GROUP')}
            </Link>
          </Item>}

          {props.isTenantPropEnabled('skillsgap') && <Item key={AdministrationSkillsRouteEnum.SKILLS_GAP_ROUTE} data-test-id="timun-courseViewSider__skillsGapMenuItem">
            <Link to="skillsadministration/skillsgap">
              {props.translate('ADMINISTRATION_SKILL_VIEW.SIDER_MENU.SKILLS_GAP')}
            </Link>
          </Item>}

        </Menu>
      </Content>
    </Layout>
  );
};

export default withLocalize<IAdministrationSkillViewSiderOwnProps>(withTenantPropEnabled(IAdministrationSkillViewSider) as any);
import AppContent from '@src/components/common/container/AppContent';
import AdminSurveyTemplateList from '@src/components/survey/AdminSurveyTemplateList';
import { ISurveyTemplate, SurveyTypeEnum } from '@src/model/survey/SurveyTemplate';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SurveyTemplateBusinessStore, { ISurveyTemplateCreatePayload } from '@src/service/business/survey/SurveyTemplateBusinessStore';
import SurveyTemplateListBusinessStore, { ISurveyTemplateListFilter } from '@src/service/business/survey/SurveyTemplateListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const DEFAULT_SORT_VALUE = '';

// -- Prop types
// ----------
export interface IAdminSurveyTemplateListContainerOwnProps {
}

export interface IAdminSurveyTemplateListContainerStateProps {
  surveyTemplateList: ICollectionData<ISurveyTemplate>;
  surveyTemplateListFilter: ISurveyTemplateListFilter;
}

export interface IAdminSurveyTemplateListContainerDispatchProps {
  fetchSurveyTemplateList: (params: ICollectionFetchPayload<ISurveyTemplateListFilter>) => ITrackableAction;
  createSurveyTemplate: (data: ISurveyTemplateCreatePayload) => ITrackableAction;
  updateSurveyTemplate: (data: ISurveyTemplate) => void;
}

type IAdminSurveyTemplateListContainerProps = IAdminSurveyTemplateListContainerOwnProps & IAdminSurveyTemplateListContainerStateProps & IAdminSurveyTemplateListContainerDispatchProps & WithRouterProps;

// --
// ----- State types
interface IAdminSurveyTemplateListContainerState {
  page: number;
  size: number;
  sort: string;
}

// -- Component
// ----------
class AdminSurveyTemplateListContainer extends React.Component<IAdminSurveyTemplateListContainerProps, IAdminSurveyTemplateListContainerState> {
  state: IAdminSurveyTemplateListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: DEFAULT_SORT_VALUE,
  };

  componentDidMount() {
    this.fetchSurveyTemplateList();
  }

  componentDidUpdate = (prevProps: IAdminSurveyTemplateListContainerProps, prevState: IAdminSurveyTemplateListContainerState) => {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || this.props.surveyTemplateListFilter !== prevProps.surveyTemplateListFilter) {
      this.fetchSurveyTemplateList();
    }
  };

  render() {
    return (
      <AppContent>
        {this.props.surveyTemplateList && <AdminSurveyTemplateList onSurveyTemplateCreate={this.handleSurveyTemplateCreate} onSurveyTemplateUpdate={this.handleSurveyTemplateUpdate} surveyTemplateList={this.props.surveyTemplateList.content} />}
      </AppContent>
    );
  }


  handleSurveyTemplateCreate = (title: string, surveyTypeId: SurveyTypeEnum) => {
    this.props.createSurveyTemplate({ title, type: { id: surveyTypeId } });
  }

  handleSurveyTemplateUpdate = (surveyTemplate: ISurveyTemplate) => {
    this.props.updateSurveyTemplate(surveyTemplate);
    this.fetchSurveyTemplateList();
  }

  fetchSurveyTemplateList = () => {
    this.props.fetchSurveyTemplateList(
      {
        page: this.state.page,
        sort: [this.state.sort],
        size: this.state.size,
        filter: this.props.surveyTemplateListFilter,
      }
    );
  }
}
// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdminSurveyTemplateListContainerOwnProps): IAdminSurveyTemplateListContainerStateProps => ({
  surveyTemplateList: SurveyTemplateListBusinessStore.selectors.getSurveyTemplateList(state),
  surveyTemplateListFilter: SurveyTemplateListBusinessStore.selectors.getSurveyTemplateListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminSurveyTemplateListContainerDispatchProps => ({
  fetchSurveyTemplateList: (params: ICollectionFetchPayload<ISurveyTemplateListFilter>) => createTrackableAction(dispatch(SurveyTemplateListBusinessStore.actions.fetchSurveyTemplateList(params))),
  createSurveyTemplate: (data: ISurveyTemplateCreatePayload) => createTrackableAction(dispatch(SurveyTemplateBusinessStore.actions.createSurveyTemplate(data))),
  updateSurveyTemplate: (data: ISurveyTemplate) => dispatch(SurveyTemplateBusinessStore.actions.updateSurveyTemplate(data)),
});

export default connect<IAdminSurveyTemplateListContainerStateProps, IAdminSurveyTemplateListContainerDispatchProps, IAdminSurveyTemplateListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRouter(AdminSurveyTemplateListContainer as any));

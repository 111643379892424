import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserGroupWorkpositionTree from '@src/components/usergroup/update/UserGroupWorkpositionTree';
import { ICourseUserGroup } from '@src/model/course/CourseUserGroup';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseUserGroupPayload } from '@src/service/business/courses/courseListBusinessStore';
import UserGroupListBusinessStore, { IUserGroupListFilter } from '@src/service/business/usergroup/UserGroupListBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Typography } from 'antd';
import workPositionBusinessStore, { IWorkPositionListFilter } from '@src/service/business/workpositions/workPositionBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import RouterNavigationPromptContextProvider from '@src/components/common/route/prompt/RouterNavigationPromptContextProvider';

// -- Const
// ----------
const USER_GROUP_VIEW_NAME = '@@COURSE_USER_GROUP_TREE_CONTAINER';
const WORKPOSITION_VIEW_NAME = '@@WORKPOSITION_LIST_USER_GROUP_TREE_CONTAINER';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');
// -- Prop types
// ----------

export interface IUserGroupWorkpositionTreeContainerOwnProps {
  courseId: string;
}
export interface IUserGroupWorkpositionTreeContainerStateProps {
  userGroupList: ICollectionData<IUserGroup>;
  userGroupHierarchy: IUserGroupHierarchy;
  courseUserGroupWorkPositionList: ICollectionData<ICourseUserGroup>;
  workPositionList: ICollectionData<IWorkPosition>;
}
export interface IUserGroupWorkpositionTreeContainerDispatchProps {
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => ITrackableAction;
  fetchUserGroupHierarchy: () => void;
  clearUserGroupHierarchy: () => void;
  fetchCourseUserGroupWorkPositionList: () => ITrackableAction;
  clearCourseUserGroupWorkPositionList: () => void;
  updateCourseUserGroupWorkPositionList: (data: ICourseUserGroupPayload[]) => ITrackableAction;
  fetchWorkPositionList: (params: ICollectionFetchPayload<IWorkPositionListFilter>) => void;
}
type IUserGroupWorkpositionTreeContainerProps = IUserGroupWorkpositionTreeContainerOwnProps & IUserGroupWorkpositionTreeContainerStateProps & IUserGroupWorkpositionTreeContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component for selecting usergroup with workplaces. OnSubmit returns an array of IIdRefs */
const UserGroupWorkpositionTreeContainer = (props: IUserGroupWorkpositionTreeContainerProps) => {
  const initialUserGroupCollectionValues = { filter: { type: UserGroupTypeEnum.ORGANIZATION } };
  const initialWorkPositionCollectionValues = { size: DEFAULT_PAGE_SIZE_VALUE };
  const [] = useCollectionState<IUserGroupListFilter>(
    {
      viewName: USER_GROUP_VIEW_NAME,
      updateFn: props.fetchUserGroupList,
      initialValues: initialUserGroupCollectionValues,
    },
    [props.courseId]
  );

  const [, updateWorkPositionCollectionParams] = useCollectionState<IWorkPositionListFilter>(
    {
      viewName: WORKPOSITION_VIEW_NAME,
      updateFn: props.fetchWorkPositionList,
      initialValues: initialWorkPositionCollectionValues,
    },
    [props.courseId]
  );

  useEffect(() => {
    props.fetchUserGroupHierarchy();
    props.fetchCourseUserGroupWorkPositionList();
  }, [props.courseId]);

  const handleSubmit = (data: ICourseUserGroupPayload[]) => props.updateCourseUserGroupWorkPositionList(data);

  return (
    <RouterNavigationPromptContextProvider message={props.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION')}>
      <div className="timun-siderMenu__content">
        <Typography.Paragraph>{props.translate('USER_GROUP_WORKPOSITION.TREE.INFO_TEXT_LABEL')}</Typography.Paragraph>
        <Typography.Paragraph strong={true}>{props.translate('USER_GROUP_WORKPOSITION.TREE.DISCLAIMER_INFO_TEXT_LABEL')}</Typography.Paragraph>
        {props.userGroupList && props.courseUserGroupWorkPositionList && props.userGroupHierarchy && props.workPositionList && (
          <UserGroupWorkpositionTree
            userGroupHierarchy={props.userGroupHierarchy}
            userGroupList={props.userGroupList.content}
            workPositionList={props.workPositionList.content}
            assignedUserGroupWorkPositions={props.courseUserGroupWorkPositionList.content}
            onFilterSubmit={updateWorkPositionCollectionParams.onFilterSet}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </RouterNavigationPromptContextProvider>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserGroupWorkpositionTreeContainerOwnProps): IUserGroupWorkpositionTreeContainerStateProps => ({
  userGroupList: UserGroupListBusinessStore.selectors.getUserGroupList(state),
  userGroupHierarchy: UserGroupListBusinessStore.selectors.getUserGroupHierarchy(state),
  courseUserGroupWorkPositionList: CourseListBusinessStore.selectors.getCourseUserGroupWorkPositionList(state),
  workPositionList: workPositionBusinessStore.selectors.getWorkPositionList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IUserGroupWorkpositionTreeContainerOwnProps): IUserGroupWorkpositionTreeContainerDispatchProps => ({
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => createTrackableAction(dispatch(UserGroupListBusinessStore.actions.fetchUserGroupList(params))),
  fetchUserGroupHierarchy: () => dispatch(UserGroupListBusinessStore.actions.fetchUserGroupHierarchy()),
  clearUserGroupHierarchy: () => dispatch(UserGroupListBusinessStore.actions.clearUserGroupHierarchy()),
  fetchCourseUserGroupWorkPositionList: () => createTrackableAction(dispatch(CourseListBusinessStore.actions.fetchCourseUserGroupWorkPositionList({ id: ownProps.courseId }))),
  clearCourseUserGroupWorkPositionList: () => dispatch(CourseListBusinessStore.actions.clearCourseUserGroupWorkPositionList()),
  updateCourseUserGroupWorkPositionList: (data: ICourseUserGroupPayload[]) => createTrackableAction(dispatch(CourseListBusinessStore.actions.updateCourseUserGroupWorkPositionList({ id: ownProps.courseId, data }))),
  fetchWorkPositionList: (params: ICollectionFetchPayload<IWorkPositionListFilter>) => dispatch(workPositionBusinessStore.actions.fetchWorkPositionList(params)),
});

export default connect<IUserGroupWorkpositionTreeContainerStateProps, IUserGroupWorkpositionTreeContainerDispatchProps, IUserGroupWorkpositionTreeContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(UserGroupWorkpositionTreeContainer as any));

import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AllowedUserRoles from '@src/components/common/role/AllowedUserRoles';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import WebinarListContainer from '@src/components/webinar/list/WebinarListContainer';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { WebinarStatusEnum } from '@src/model/webinar/Webinar';
import { Button, Row, Tabs } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
// ----- Constants
const TabPane = Tabs.TabPane;
export enum WebinarListNavigationMenuKeys {
  Anounced = '1',
  Recorded = '2',
}
export interface IWebinarListPageContainerOwnProps {}

type IWebinarListPageContainerProps = IWebinarListPageContainerOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & WithRouterProps;

const WebinarListPageContainer: React.FC<IWebinarListPageContainerProps> = (props: IWebinarListPageContainerProps) => {
  const [selectedKey, setSelectedKey] = useState<string>(WebinarListNavigationMenuKeys.Anounced);

  const anouncedTabLabel = useMemo(() => (props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]) ? 'WEBINAR_LIST_VIEW.ALL_WEBINARS' : 'WEBINAR_LIST_VIEW.TABS.ANOUNCED'), [props.isInRoles]);

  const handleCreateWebinarButtonClick = useCallback(() => {
    props.router.push('/webinar/create');
  }, [props.router.push]);

  const onMenuClick = useCallback(
    (tabKey: string) => {
      setSelectedKey(tabKey);
    },
    [setSelectedKey]
  );

  return (
    <AppContent
      level={1}
      title={
        <Row justify="space-between">
          {props.translate('WEBINAR_LIST.VIEW_TITLE')}
          <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
            <Button type="primary" icon={<LemonIcon name="plus" />} className="text-right" onClick={handleCreateWebinarButtonClick}>
              {props.translate('WEBINAR_LIST_VIEW.CREATE_WEBINAR')}
            </Button>
          </AllowedUserRoles>
        </Row>
      }
    >
      <Tabs activeKey={selectedKey} onChange={onMenuClick} destroyInactiveTabPane={true}>
        <TabPane tab={props.translate(anouncedTabLabel)} key={WebinarListNavigationMenuKeys.Anounced}>
          <WebinarListContainer viewName="@@WEBINAR_GROUP_LIST.ALL" showStatusFilter={true} />
        </TabPane>
        <TabPane tab={props.translate('WEBINAR_LIST_VIEW.TABS.FINISHED')} key={WebinarListNavigationMenuKeys.Recorded}>
          <WebinarListContainer viewName="@@WEBINAR_GROUP_LIST.ANNOUNCED" showStatusFilter={false} predefinedListFilter={{ statuses: [WebinarStatusEnum.FINISHED] }} />
        </TabPane>
      </Tabs>
    </AppContent>
  );
};

export default withRouter(withRoles(withLocalize<IWebinarListPageContainerOwnProps>(WebinarListPageContainer as any)));

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ProgressBar from '@src/components/common/progressbar/ProgressBar';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import { CourseCompletionStatusEnum, ICourse } from '@src/model/course/Course';
import React from 'react';

// -- Prop types
// ----------

export interface ICourseProgressBarOwnProps {
  course: ICourse;
}
type ICourseProgressBarProps = ICourseProgressBarOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Component display education information */
class CourseProgressBar extends React.Component<ICourseProgressBarProps> {
  render() {
    const currentLecture = CourseHelperUtils.getCurrentLecture(this.props.course);
    const showProgressBar = CourseHelperUtils.isLectureCourse(this.props.course) || this.props.course.progress?.completionStatus.id === CourseCompletionStatusEnum.COMPLETED;

    return (
      <div>
        {currentLecture?.title}
        {showProgressBar && (
          <div>
            <ProgressBar progressProps={{ size: 'small' }} progress={this.props.course.progress} hasExam={this.props.course.exam !== null} />
          </div>
        )}
      </div>
    );
  }
}

export default withRoles(withLocalize<ICourseProgressBarOwnProps>(CourseProgressBar as any));

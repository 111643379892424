import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationInstanceForm from '@src/components/externaleducationistance/form/ExternalEducationInstanceForm';
import { IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { IExternalEducationInstanceCreatePayload } from '@src/service/business/externaleducations/ExternalEducationInstanceBusinessStore';
import { Form, Modal } from 'antd';

// -- Prop types
// ----------
export interface IExternalEducationInstanceModalFormOwnProps {
  externalEducationTemplateId: string;
  externalEducationInstance?: IExternalEducationInstance;
  onCreate?: (externalEducationInstance: IExternalEducationInstanceCreatePayload) => void;
  onUpdate?: (externalEducationInstance: IExternalEducationInstance) => void;
  onCancel?: () => void;
}

type IExternalEducationInstanceModalFormProps = IExternalEducationInstanceModalFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education instance form in modal */
const ExternalEducationInstanceModalForm: React.FC<IExternalEducationInstanceModalFormProps> = (props: IExternalEducationInstanceModalFormProps) => {
  const [form] = Form.useForm<IExternalEducationInstance>();

  const handleSubmit = (value: IExternalEducationInstance) => {
    if (props.externalEducationInstance?.id) {
      //  update
      props.onUpdate?.(value);
    } else if (props.onCreate) {
      // create
      const createData = { ...value, externalEducationTemplate: { id: props.externalEducationTemplateId } };
      props.onCreate(createData);
    }
  };
  return (
    <Modal
      className="lemon-modal__modal--md"
      title={props.externalEducationInstance?.id ? props.translate('EXTERNAL_EDUCATION_INSTANCE.MODAL.TITLE') : props.translate('EXTERNAL_EDUCATION_INSTANCE.MODAL.CREATE_TITLE')}
      visible={true}
      maskClosable={false}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <ExternalEducationInstanceForm formRef={form} value={props.externalEducationInstance} onSubmit={handleSubmit} />
    </Modal >
  );
};

export default withLocalize<IExternalEducationInstanceModalFormOwnProps>(ExternalEducationInstanceModalForm as any);

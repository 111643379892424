import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NoteForm from '@src/components/user/form/NoteForm';
import { INote, NoteObjectTypeEnum } from '@src/model/user/Note';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import UserNoteBusinessStore, { INoteCreatePayload } from '@src/service/business/usernotes/userNoteBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// -- Prop types
// ----------
interface INoteCreateContainerOwnProps {
  className?: string;
  objectId: string;
  objectTypeId: NoteObjectTypeEnum;
  objectPublic?: boolean;
  canSetPrivateNote?: boolean;
  onCreateNote?: () => void;
  buttonLabel?: string;
  buttonType?: ButtonType;
  modalTitle?: string;
  modalOkButtonLabel?: string;
  modalCancelButtonLabel?: string;
  hideIcon?: boolean;
  disabled?: boolean;
}

interface INoteCreateContainerStateProps {}

interface INoteCreateContainerDispatchProps {
  createUserNote: (data: INoteCreatePayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

// -- State types
// ----------
type INoteCreateContainerContentProps = INoteCreateContainerOwnProps & INoteCreateContainerStateProps & INoteCreateContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component for note creation with callback for various objects */
const NoteCreateContainer: React.FC<INoteCreateContainerContentProps> = (props: INoteCreateContainerContentProps) => {
  const [isNoteModalVisible, setIsNoteModalVisible] = useState<boolean>(false);

  const handleCreateUserNote = (noteData: INote) => {
    props
      .createUserNote(noteData)
      .track()
      .subscribe(
        // success
        () => {
          setIsNoteModalVisible(false);
          props.reportMessage({ message: props.translate('NOTE_CONTAINER.SUCCESS_CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
          props.onCreateNote?.();
        }
      );
  };

  return (
    <React.Fragment>
      <Button className={props.className} disabled={props.disabled} type={props.buttonType} icon={props.hideIcon ? null : <LemonIcon name="plus" />} block={false} onClick={() => setIsNoteModalVisible(true)} data-test-id="timun-notes__openNotesFormButton">
        {props.buttonLabel || props.translate('COURSE_VIEW.CREATE_NEW_NOTE')}
      </Button>
      {isNoteModalVisible && (
        <NoteForm
          canSetPrivateNote={props.canSetPrivateNote}
          objectId={props.objectId}
          objectTypeId={props.objectTypeId}
          objectPublic={props.objectPublic}
          title={props.modalTitle || props.translate('COURSE_VIEW.CREATE_NEW_NOTE')}
          okButtonLabel={props.modalOkButtonLabel}
          cancelButtonLabel={props.modalCancelButtonLabel}
          onCancel={() => setIsNoteModalVisible(false)}
          onSubmit={handleCreateUserNote}
        />
      )}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: INoteCreateContainerOwnProps): INoteCreateContainerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): INoteCreateContainerDispatchProps => ({
  createUserNote: (data: INoteCreatePayload) => createTrackableAction(dispatch(UserNoteBusinessStore.actions.createUserNote(data))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<INoteCreateContainerStateProps, INoteCreateContainerDispatchProps, INoteCreateContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(NoteCreateContainer as any));

import React, { useEffect } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationTemplateTimelineView from '@src/components/externaleducation/list/ExternalEducationTemplateTimelineView';
import { IExternalEducationTemplateTimelineActivity } from '@src/model/externalEducationTemplate/ExternalEducationTemplateTimelineActivity';
import { ICollectionData } from '@src/service/business/common/types';
import ExternalEducationTemplateTimelineListBusinessStore from '@src/service/business/externaleducations/ExternalEducationTemplateTimelineBusinessStore';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface IExternalEducationTemplateTimelineContainerOwnProps {
  externalEducationTemplateId: string;
  externalEducationTemplatPhaseId?: string;
  isCurrentUserCoordinator: boolean;
}

export interface IExternalEducationTemplateTimelineContainerStateProps {
  timeline: ICollectionData<IExternalEducationTemplateTimelineActivity>;
}

export interface IExternalEducationTemplateTimelineContainerDispatchProps {
  fetchTimeline: (id: string) => void;
}

type IExternalEducationTemplateTimelineContainerProps = IExternalEducationTemplateTimelineContainerOwnProps & IExternalEducationTemplateTimelineContainerStateProps & IExternalEducationTemplateTimelineContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display activity history list */
const ExternalEducationTemplateTimelineContainer = (props: IExternalEducationTemplateTimelineContainerProps) => {

  useEffect(() => {
    fetchExternalEducationTemplateActivities();
  }, [props.externalEducationTemplateId, props.externalEducationTemplatPhaseId]);

  const fetchExternalEducationTemplateActivities = () => props.fetchTimeline(props.externalEducationTemplateId);

  return (
    <React.Fragment>
      {props.timeline && <ExternalEducationTemplateTimelineView id={props.externalEducationTemplateId} onCreateNote={fetchExternalEducationTemplateActivities} canCreateNotes={props.isCurrentUserCoordinator} timeline={props.timeline.content} />}
    </React.Fragment>
  );
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationTemplateTimelineContainerOwnProps): IExternalEducationTemplateTimelineContainerStateProps => ({
  timeline: ExternalEducationTemplateTimelineListBusinessStore.selectors.getExternalEducationTemplateTimelineList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExternalEducationTemplateTimelineContainerDispatchProps => ({
  fetchTimeline: (id: string) => dispatch(ExternalEducationTemplateTimelineListBusinessStore.actions.fetchExternalEducationTemplateTimelineList(id)),
});

export default connect<IExternalEducationTemplateTimelineContainerStateProps, IExternalEducationTemplateTimelineContainerDispatchProps, IExternalEducationTemplateTimelineContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ExternalEducationTemplateTimelineContainer as any));

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExternalContentDataForm from '@src/components/externalcontent/create/ExternalContentDataForm';
import { IFile } from '@src/model/file/File';
import { IExternalContentCreatePayload } from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import { Layout } from 'antd';
import React, { useState } from 'react';

// -- Prop types
// ----------

const { Header, Content } = Layout;

interface IExternalContentCreateViewOwnProps {
  onCreate: (data: IExternalContentCreatePayload, coverImage?: IFile) => void;
  onCancel: () => void;
}

type IExternalContentCreateViewProps = IExternalContentCreateViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const ExternalContentCreateView: React.FC<IExternalContentCreateViewProps> = (props: IExternalContentCreateViewProps) => {
  const [coverImage, setCoverImage] = useState<IFile>();

  const handleSubmit = (data: IExternalContentCreatePayload) => {
    props.onCreate(data, coverImage);
  };

  const handleCoverPick = (data: IFile) => {
    if (data) {
      setCoverImage(data);
    }
  };

  return (
    <Layout>
      <Header className="timun-externalContentView__header">
        <HeaderTitle link={'/externalcontents'} icon={<LemonIcon name="left" />} title={props.translate('EXTERNAL_CONTENT.CREATE.TITLE')} />
      </Header>
      <Content className="panel">
        <ExternalContentDataForm coverImageId={coverImage?.id} onCoverSubmit={handleCoverPick} onCancel={props.onCancel} onSubmit={handleSubmit} />
      </Content>
    </Layout>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IExternalContentCreateViewOwnProps>(ExternalContentCreateView as any);

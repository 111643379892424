import UserActivityList from '@src/components/activity/list/UserActivityList';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserActivity } from '@src/model/activity/UserActivity';
import { Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

interface IActivityShortListViewOwnProps {
  userActivityList: IUserActivity[];
}

type IActivityShortListViewProps = IActivityShortListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class ActivityShortListView extends React.Component<IActivityShortListViewProps> {
  render = () => {
    return <div className="panel">
      <Row className="text-center timun-userActivityList__showMore" justify="space-between">
        <Typography.Title level={3}>{this.props.translate('USER_ACTIVITY.LIST_LABEL')}</Typography.Title>
      </Row>
      <UserActivityList userActivities={this.props.userActivityList}/>
      &nbsp;
      <Row justify="end">
        <Link to={'/reports/useractivities'}>{this.props.translate('COMMON.ACTION_LOAD_ALL')}</Link>
      </Row>
    </div>;
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IActivityShortListViewOwnProps>(ActivityShortListView as any);

import { ICode } from '@src/model/common/Code';

export interface IContactData {
  id: string;
  value: string;
  type: IContactDataType;
}

export interface IContactDataType extends ICode<string> {}

export enum ContactDataTypeEnum {
  EMAIL = '1',
  PHONE = '2',
  MOBILE_PHONE = '3',
}

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamTemplateHelperUtils from '@src/components/exam/common/ExamTemplateHelperUtils';
import ExamTemplateForm from '@src/components/exam/examtemplate/ExamTemplateForm';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamTemplate } from '@src/model/education/ExamTemplate';
import { Typography } from 'antd';

// -- Prop types
// ----------

export interface IExamTemplateSettingsViewOwnProps {
  examTemplate: IExamTemplate;
  onSubmit?: (data: IExamTemplate) => void;
}
type IExamTemplateSettingsViewProps = IExamTemplateSettingsViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display exam template question collection as exam */
const ExamTemplateSettingsView: React.FC<IExamTemplateSettingsViewProps> = (props: IExamTemplateSettingsViewProps) => {
  const settingsDisabled = ExamTemplateHelperUtils.isExamTemplateInPhase(props.examTemplate, [ActivityPhaseEnum.FINISHED]);

  return (
    <React.Fragment>
      <Typography.Paragraph strong={true}>{props.translate('EXAM_TEMPLATE.SETTINGS.INFO_TEXT_LABEL')}</Typography.Paragraph>
      <ExamTemplateForm disabled={settingsDisabled} examTemplate={props.examTemplate} onSubmit={props.onSubmit} />
    </React.Fragment>
  );
};

export default withLocalize<IExamTemplateSettingsViewOwnProps>(ExamTemplateSettingsView as any);

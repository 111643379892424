import { IActivity } from '@src/model/activity/Activity';
import { IActivityParticipant, ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { FinancingAgreemntTypeEnum } from '@src/model/externalEducationApplication/ExternalEducationApplicationFilePreviewData';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { IFile } from '@src/model/file/File';
import { ExternalEducationApplicationFileTypeEnum } from '@src/model/file/FileType';
import { ICity } from '@src/model/location/City';
import { getFirstFileByType, hasFilesInAllTypes, hasFilesInTypes, IFileListsByType } from '@src/service/business/files/util';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import _ from 'lodash';

export default class EducationApplicationHelperUtils {
  /** Return participant by requested role. */
  static getActivityParticipantByRole(requestedRole: ParticipantRoleEnum, activity: IActivity): IActivityParticipant | undefined {
    return activity.participants.find((paritcipant: IActivityParticipant) => paritcipant.participantRole === requestedRole);
  }

  /** Returns participant name by requested role. */
  static getActivityParticipantNameByRole(requestedRole: ParticipantRoleEnum, activity: IActivity): string | undefined {
    const participant = activity.participants.find((paritcipant: IActivityParticipant) => paritcipant.participantRole === requestedRole);
    if (participant) {
      return `${participant?.firstName} ${participant?.lastName}`;
    } else {
      return undefined;
    }
  }

  /** Check if application has a coordinator assigned. */
  static hasCoordinator = (activity: IActivity) => {
    return EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.EVALUATOR, activity) != null;
  };

  /** Return education application expense value by requested expense type id. */
  static getEducationApplicationExpenseValueByExpenseType(requestedExpenseTypeId: ExpeneseTypeEnum, educationApplicationExpense: IExternalEducationExpense[]): number | undefined {
    const requestedExpense = educationApplicationExpense.find((expense: IExternalEducationExpense) => expense.expenseType.id === requestedExpenseTypeId);
    return requestedExpense?.value;
  }

  /** Return education application expense by expense type. */
  static getExpenseByType = (expenses?: IExternalEducationExpense[], requestedType?: ExpeneseTypeEnum): IExternalEducationExpense | undefined => {
    return expenses?.find((expense: IExternalEducationExpense) => expense.expenseType.id === requestedType);
  };

  /** Check if activity is in one of listed phases. */
  static isInPhases(requestedPhases: (keyof typeof EducationApplicationActivityPhaseEnum)[], activity: IActivity): boolean {
    return requestedPhases.some((phase) => activity.currentPhase.id === EducationApplicationActivityPhaseEnum[phase]);
  }

  /** Check if there are files for all listed types. */
  static hasFilesInAllTypes = (files: IFileListsByType, types: (keyof typeof ExternalEducationApplicationFileTypeEnum)[]) => {
    return hasFilesInAllTypes(files, types);
  };

  /** Check if there are files for any listed types. */
  static hasFilesInTypes = (files: IFileListsByType, types: (keyof typeof ExternalEducationApplicationFileTypeEnum)[]) => {
    return hasFilesInTypes(files, types);
  };

  /** Take the first file in the list and ignore other entries */
  static getFirstFileByType = (files: IFileListsByType, type: keyof typeof ExternalEducationApplicationFileTypeEnum): IFile | undefined => {
    return getFirstFileByType(files, type);
  };

  /** Check if education application financing agreemnt is in one of listed types. */
  static isFinancingAgreementInTypes(requestedTypes: (keyof typeof FinancingAgreemntTypeEnum)[], financingAgreementType: FinancingAgreemntTypeEnum): boolean {
    return requestedTypes.some((type) => financingAgreementType === FinancingAgreemntTypeEnum[type]);
  }

  /** Return education program location name otherwise it's online */
  static getEducationProgamLocation = (educationLocation?: ICity): string => {
    return educationLocation?.name ?? LocalizeService.translate('COMMON.LABEL.ONLINE');
  };
}

import { AxiosRequestConfig } from 'axios';

import HttpRestClient, { HttpMethod } from '@src/service/api/rest/HttpRestClient';
import { IHttpRestClientOptions } from '@src/service/api/rest/IHttpRestClient';
import AuthenticationManager from '@src/service/util/auth/AuthenticationManager';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/** HTTP REST client which automatically and unconditionally adds authentication header. */
export default class AuthHttpRestClient extends HttpRestClient {
  protected useDefaultOptions(options?: IHttpRestClientOptions): AxiosRequestConfig {
    const defaultOptions = super.useDefaultOptions(options);

    return {
      ...(defaultOptions || {}),

      headers: {
        // other headers
        ...(defaultOptions?.headers || {}),

        // auth token
        Authorization: `Bearer ${AuthenticationManager.getToken()}`,
      },
    };
  }

  protected doRequest(request: { uri: string; method: HttpMethod; options?: AxiosRequestConfig }): Observable<any> {
    return of(true).pipe(
      // trigger possible token update before making request
      mergeMap(() => from(AuthenticationManager.updateToken())),
      mergeMap(() => super.doRequest(request))
    );
  }
}

import { classNames } from '@src/components/common/util/classNames';
import React from 'react';

// -- Const
// ----------
const HTML_VIEWER_TEXT_AREA_BASE = 'timun-htmlViewer__textArea';
const HTML_VIEWER_TEXT_AREA_VH_SMALL = 'timun-htmlViewer__scrollbar--sm';
const HTML_VIEWER_TEXT_AREA_VH_LARGE = 'timun-htmlViewer__scrollbar--lg';
const HTML_VIEWER_SCROLL_BAR_BASE = 'timun-htmlViewer__scrollbar';
const HTML_VIEWER_SCROLL_BAR_VH_SMALL = 'timun-htmlViewer__scrollbar--sm';
const HTML_VIEWER_SCROLL_BAR_VH_LARGE = 'timun-htmlViewer__scrollbar--lg';

// -- Prop types
// ----------
export interface IHtmlViewerProps {
  dangerousValue?: string | null;
  size: HtmlViewHeight;
}

export type HtmlViewHeight = 'small' | 'large';

/** Display html tags as TextArea with scrollbar */
export const HtmlViewer = (props: IHtmlViewerProps) => {
  const getTextAreaClassName = (size: HtmlViewHeight) => {
    return classNames({
      [HTML_VIEWER_TEXT_AREA_BASE]: true,
      [HTML_VIEWER_TEXT_AREA_VH_SMALL]: size === 'small',
      [HTML_VIEWER_TEXT_AREA_VH_LARGE]: size === 'large',
    });
  };
  const getScrollBarClassName = (size: HtmlViewHeight) => {
    return classNames({
      [HTML_VIEWER_SCROLL_BAR_BASE]: true,
      [HTML_VIEWER_SCROLL_BAR_VH_SMALL]: size === 'small',
      [HTML_VIEWER_SCROLL_BAR_VH_LARGE]: size === 'large',
    });
  };

  return (
    props.dangerousValue ? <div className={getTextAreaClassName(props.size)}>
      <div className={getScrollBarClassName(props.size)} dangerouslySetInnerHTML={{ __html: props.dangerousValue }} />
    </div> : null
  );
};

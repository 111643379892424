import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Input, Modal, Form } from 'antd';
import React, { useCallback, useState } from 'react';

const FormItem = Form.Item;

// -- Prop types
// ----------
interface IUserGroupAccessFormOwnProps {
  onCancel?: () => void;
  onSubmit?: (accessCode: string) => void;
  title: string;
}
export interface IAccessCodeInput {
  accessCode: string;
}

type IUserGroupAccessFormProps = IUserGroupAccessFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------
const UserGroupAccessFormWrapper: React.FC<IUserGroupAccessFormProps> = (props: IUserGroupAccessFormProps) => {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState<boolean>(false);

  const handleFinish = useCallback(
    (value: IAccessCodeInput) => {
      props.onSubmit?.(value.accessCode);
    },
    [props.onSubmit]
  );

  const handleFormChange = useCallback(() => {
    setDisabledSave(form.isFieldsTouched());
  }, [setDisabledSave]);

  return (
    <Modal title={props.title} visible={true} onCancel={props.onCancel} okButtonProps={{ disabled: !disabledSave }} onOk={form.submit} okText={props.translate('COMMON.ACTION_SAVE')} cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form form={form} onFinish={handleFinish} onChange={handleFormChange}>
        <FormItem name="accessCode" label={props.translate('ACCESS_CODE_FORM.ACCESS_CODE_LABEL')}>
          <Input />
        </FormItem>
      </Form>
    </Modal>
  );
};

// -- HOCs and exports
// ----------
export default withLocalize<IUserGroupAccessFormOwnProps>(UserGroupAccessFormWrapper as any);

import { IReminder, ReminderTypeEnum } from '@src/model/reminder/Reminder';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { isTenantPropsEnabled } from '@src/service/business/tenant/tenantPropEnabled';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

export interface IReminderListFilter {
  startDateTime: string;
  endDateTime: string;
  types?: ReminderTypeEnum[];
}

// List filter ID
const REMINDER_LIST_FILTER = '@@REMINDER_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns list of reminders from store. */
const getReminderList = (store: any): ICollectionData<IReminder> => store.reminderList;

/** Returns reminder list filter. */
const getReminderListFilter = (store: any): IReminderListFilter => ListFilterBusinessStore.selectors.getListFilter(store, REMINDER_LIST_FILTER);

/** Return reminder list "types" filter value depending on features enabled on tenant. Filter is returned as empty value (undefined) if all types are enabled. */
const getAllowedTypesList = (store: any): ReminderTypeEnum[] | undefined => {
  const webinarsEnabled = isTenantPropsEnabled('webinars', store);
  const allTypesEnabled = webinarsEnabled;

  // allow all
  if (allTypesEnabled) {
    return; // return empty, no need to filter
  }
  // filter some types
  else {
    // first take all the values then remove if necessary
    const typeList = Object.values(ReminderTypeEnum);
    if (!webinarsEnabled) {
      typeList.splice(typeList.indexOf(ReminderTypeEnum.WEBINAR), 1);
    }

    return typeList;
  }
};

// -
// -------------------- Actions

const Actions = {
  REMINDER_LIST_FETCH: 'REMINDER_LIST_FETCH',
  REMINDER_LIST_SELECTED_FETCH: 'REMINDER_LIST_SELECTED_FETCH',
  REMINDER_LIST_LOAD: 'REMINDER_LIST_LOAD',
};

/** Fetch reminder list by filter */
const fetchReminderList = (payload: ICollectionFetchPayload<IReminderListFilter>): IPayloadAction<ICollectionFetchPayload<IReminderListFilter>> => {
  return {
    type: Actions.REMINDER_LIST_FETCH,
    payload,
  };
};

const fetchSelectedReminderList = (payload: ICollectionFetchPayload<IReminderListFilter>): IPayloadAction<ICollectionFetchPayload<IReminderListFilter>> => {
  return {
    type: Actions.REMINDER_LIST_SELECTED_FETCH,
    payload,
  };
};

/** Load reminder list to store. */
const loadReminderList = (data: ICollectionData<IReminder>): IPayloadAction<ICollectionData<IReminder>> => {
  return {
    type: Actions.REMINDER_LIST_LOAD,
    payload: data,
  };
};

/** Store reminder list filter to store. */
const storeReminderListFilter = (listFilter: IReminderListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(REMINDER_LIST_FILTER, listFilter);
};

// -
// -------------------- Effects

const fetchReminderListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IReminderListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REMINDER_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Reminder')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadReminderList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'REMINDER.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching reminder list', error);
      return o;
    })
  );
};

const fetchSelectedReminderListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IReminderListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REMINDER_LIST_SELECTED_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Reminder')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'REMINDER.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching selected reminder list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const reminderList = (state: ICollectionData<IReminder> | null = null, action: IPayloadAction<ICollectionData<IReminder>>) => {
  if (action.type === Actions.REMINDER_LIST_LOAD) {
    return {
      ...action.payload,
    };
  }
  return state;
};

// --
// -------------------- Business Store

const ReminderListBusinessStore = {
  actions: {
    fetchReminderList,
    fetchSelectedReminderList,
    loadReminderList,
    storeReminderListFilter,
  },

  selectors: {
    getReminderList,
    getReminderListFilter,
    getAllowedTypesList,
  },

  effects: {
    fetchReminderListEffect,
    fetchSelectedReminderListEffect,
  },

  reducers: {
    reminderList,
  },
};

export default ReminderListBusinessStore;

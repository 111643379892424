import React from 'react';
import { connect } from 'react-redux';

import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { IUserInfo } from '@src/model/user/User';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import UserCourseListView from '@src/components/user/view/UserCourseListView';
import AppConfigService from '@src/service/common/AppConfigService';

const defaultSortValue = ['title,asc'];

// -- Prop types
// ----------

interface IUserCourseListContainerOwnProps {
  user: IUserInfo;
}
interface IUserCourseListContainerStateProps {
  courseGroups: ICourseGroup[];
  courseList: ICollectionData<ICourse>;
  courseListFilter: ICourseListFilter;
}
interface IUserCourseListContainerDispatchProps {
  fetchCourseList: (id: string, filter: ICourseListFilter, page: number, size: number, sort: string[]) => any;
  clearCourseList: () => void;

  storeCourseListFilter: (filter: ICourseListFilter) => void;
  clearCourseListFilter: () => void;
}
type IUserCourseListContainerProps = IUserCourseListContainerOwnProps & IUserCourseListContainerStateProps & IUserCourseListContainerDispatchProps;

interface IUserCourseListContainerState {
  page: number;
  size: number;
  sort: string[];
}

// -- Component
// ----------

/** Container component for list of all courses for a specific user. */
class UserCourseListContainer extends React.Component<IUserCourseListContainerProps, IUserCourseListContainerState> {
  state: IUserCourseListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: defaultSortValue,
  };

  componentDidMount() {
    // initial list update
    this.updateList();
  }

  componentDidUpdate(prevProps: IUserCourseListContainerProps, prevState: IUserCourseListContainerState) {
    if (this.props.courseListFilter !== prevProps.courseListFilter || this.state !== prevState) {
      this.updateList();
    }
  }

  componentWillUnmount = () => {
    this.props.clearCourseList();
    this.props.clearCourseListFilter();
  };

  render = () => {
    return (
      <UserCourseListView
        user={this.props.user}
        courseGroups={this.props.courseGroups}
        courseList={this.props.courseList}
        courseListFilter={this.props.courseListFilter}
        sortValue={this.state.sort}
        onUpdateCourseList={this.updateList}
        onSortChange={this.handleSortChange}
        onPageChange={this.handlePageChange}
        onFilterChange={this.handleFilterChange}
      />
    );
  };

  handleFilterChange = (filter: ICourseListFilter) => {
    this.props.storeCourseListFilter(filter);
    this.setState({ page: 0 });
  };

  handleSortChange = (sort: string[]) => {
    this.setState({ page: 0, sort });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  private updateList = () => {
    this.props.fetchCourseList(this.props.user.id, this.props.courseListFilter, this.state.page, this.state.size, this.state.sort);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserCourseListContainerOwnProps): IUserCourseListContainerStateProps => ({
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
  courseList: CourseListBusinessStore.selectors.getCourseList(state),
  courseListFilter: CourseListBusinessStore.selectors.getCourseListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserCourseListContainerDispatchProps => ({
  fetchCourseList: (id: string, filter: ICourseListFilter, page: number, size: number, sort: string[]) => dispatch(CourseListBusinessStore.actions.fetchTraineeCourseList(id, filter, page, size, sort)),
  clearCourseList: () => dispatch(CourseListBusinessStore.actions.clearCourseList()),

  storeCourseListFilter: (filter: ICourseListFilter) => dispatch(CourseListBusinessStore.actions.storeCourseListFilter(filter)),
  clearCourseListFilter: () => dispatch(CourseListBusinessStore.actions.clearCourseListFilter()),
});

export default connect<IUserCourseListContainerStateProps, IUserCourseListContainerDispatchProps, IUserCourseListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(UserCourseListContainer);

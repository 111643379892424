import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { IUserCategory } from '@src/model/user/UserCategory';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export type IUserCategoryCreatePayload = Omit<IUserCategory, 'id' | 'description'>;

export interface IUserCategoryListFilter {
  name?: string;
  active?: boolean;
}

// List filter ID
const USER_CATEGORY_LIST_FILTER = '@@USER_CATEGORY_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns UserCategory from store. */
const getUserCategory = (store: any): IUserCategory => store.userCategoryView;

/** Returns list of UserCategorys from store. */
const getUserCategoryList = (store: any): ICollectionData<IUserCategory> => store.userCategoryList;

/** Returns UserCategory list filter. */
const getUserCategoryListFilter = (store: any): IUserCategoryListFilter => ListFilterBusinessStore.selectors.getListFilter(store, USER_CATEGORY_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  USER_CATEGORY_FETCH: 'USER_CATEGORY_FETCH',
  USER_CATEGORY_LOAD: 'USER_CATEGORY_LOAD',
  USER_CATEGORY_CLEAR: 'USER_CATEGORY_CLEAR',
  USER_CATEGORY_CREATE: 'USER_CATEGORY_CREATE',
  USER_CATEGORY_UPDATE: 'USER_CATEGORY_UPDATE',
  USER_CATEGORY_LIST_FETCH: 'USER_CATEGORY_LIST_FETCH',
  USER_CATEGORY_LIST_LOAD: 'USER_CATEGORY_LIST_LOAD',
  USER_CATEGORY_LIST_CLEAR: 'USER_CATEGORY_LIST_CLEAR',
};

/** Fetch UserCategory by ID. */
const fetchUserCategory = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_CATEGORY_FETCH,
    payload: params,
  };
};

/** Load UserCategory to store. */
const loadUserCategory = (data: IUserCategory): IPayloadAction<IUserCategory> => {
  return {
    type: Actions.USER_CATEGORY_LOAD,
    payload: data,
  };
};

/** Clear UserCategory from store. Eg. when leaving view. */
const clearUserCategoryData = (): ILemonAction => {
  return {
    type: Actions.USER_CATEGORY_CLEAR,
  };
};

/** Create new UserCategory. */
const createUserCategory = (data: IUserCategoryCreatePayload): IPayloadAction<IUserCategoryCreatePayload> => {
  return {
    type: Actions.USER_CATEGORY_CREATE,
    payload: data,
  };
};

/** Update UserCategory by ID. */
const updateUserCategory = (data: IUserCategory): IPayloadAction<IUserCategory> => {
  return {
    type: Actions.USER_CATEGORY_UPDATE,
    payload: data,
  };
};

/** Fetch UserCategory list by filter. */
const fetchUserCategoryList = (params: ICollectionFetchPayload<IUserCategoryListFilter>): IPayloadAction<ICollectionFetchPayload<IUserCategoryListFilter>> => {
  return {
    type: Actions.USER_CATEGORY_LIST_FETCH,
    payload: params,
  };
};

/** Load UserCategory list to store. */
const loadUserCategoryList = (data: ICollectionData<IUserCategory>): IPayloadAction<ICollectionData<IUserCategory>> => {
  return {
    type: Actions.USER_CATEGORY_LIST_LOAD,
    payload: data,
  };
};

/** Clear UserCategory list from store. Eg. when leaving list view. */
const clearUserCategoryList = (): ILemonAction => {
  return {
    type: Actions.USER_CATEGORY_LIST_CLEAR,
  };
};

/** Store UserCategory list filter to store. */
const storeUserCategoryListFilter = (listFilter: IUserCategoryListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(USER_CATEGORY_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchUserCategoryEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_CATEGORY_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('UserCategory')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserCategory(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'UserCategory_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching UserCategory', error);
      return o;
    })
  );
};

const createUserCategoryEffect = (action$: Observable<IPayloadAction<IUserCategoryCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_CATEGORY_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('UserCategory')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserCategory(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_CATEGORY_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    //reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating UserCategory', error);
      return o;
    })
  );
};

const updateUserCategoryEffect = (action$: Observable<IPayloadAction<IUserCategory>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_CATEGORY_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('UserCategory')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserCategory(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_CATEGORY_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating UserCategory', error);
      return o;
    })
  );
};

const fetchUserCategoryListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IUserCategoryListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_CATEGORY_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('UserCategory')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserCategoryList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching UserCategory list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const userCategoryView = (state: IUserCategory | null = null, action: IPayloadAction<IUserCategory>) => {
  if (action.type === Actions.USER_CATEGORY_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_CATEGORY_CLEAR) {
    return null;
  }

  return state;
};

const userCategoryList = (state: ICollectionData<IUserCategory> | null = null, action: IPayloadAction<ICollectionData<IUserCategory>>) => {
  if (action.type === Actions.USER_CATEGORY_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_CATEGORY_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const UserCategoryBusinessStore = {
  actions: {
    fetchUserCategory,
    loadUserCategory,
    clearUserCategoryData,
    createUserCategory,
    updateUserCategory,
    fetchUserCategoryList,
    loadUserCategoryList,
    clearUserCategoryList,
    storeUserCategoryListFilter,
  },

  selectors: {
    getUserCategory,
    getUserCategoryList,
    getUserCategoryListFilter,
  },

  effects: {
    fetchUserCategoryEffect,
    createUserCategoryEffect,
    updateUserCategoryEffect,
    fetchUserCategoryListEffect,
  },

  reducers: {
    userCategoryView,
    userCategoryList,
  },
};

// --
// export business store
export default UserCategoryBusinessStore;

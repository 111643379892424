import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import UserListContainer from '@src/components/user/list/UserListContainer';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import React, { useCallback } from 'react';

// -- Const
// ----------
const predefinedListFilter: Readonly<IUserListFilter> = { role: UserRoleEnum.TRAINEE, withCoursesStats: true };

// -- Prop types
// ----------

export interface IUsersCourseProgressOwnProps {}

type IUsersCourseProgressProps = IUsersCourseProgressOwnProps & IWithLocalizeOwnProps;

const UsersCourseProgress: React.FC<IUsersCourseProgressProps> = (props: IUsersCourseProgressProps) => {
  const additionalCols = useCallback((): ITimunDataTableCol<IUserInfo>[] => {
    return [
      {
        key: 'workPosition',
        contentType: 'string',
        headerTitle: props.translate('USER_LIST.WORKPOSITION_LABEL'),
        content: (user: IUserInfo) => (user.workData?.workPosition ? user.workData.workPosition.name : null),
      },
      {
        key: 'coursesStats',
        contentType: 'string',
        headerTitle: props.translate('USER_LIST.COMPLETED_COURSES_LABEL'),
        content: (user: IUserInfo) => (user.additionalData && user.additionalData.coursesStats ? `${+user.additionalData.coursesStats.completedCount} / ${+user.additionalData.coursesStats.enrolledCount}` : null), // TODO: add course progress status
      },
    ];
  }, [props.translate]);

  return (
    <AppContent>
      <UserListContainer additionalCols={additionalCols} additionalSearchParams={false} predefinedListFilter={predefinedListFilter} />
    </AppContent>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IUsersCourseProgressOwnProps>(UsersCourseProgress as any);

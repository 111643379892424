import { ExternalEducationInstanceStatusEnum, IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { ExternalEducationTemplateStatusEnum, IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ExternalEducationTemplateFileTypeEnum } from '@src/model/file/FileType';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { hasFilesInAllTypes, hasFilesInTypes, IFileListsByType } from '@src/service/business/files/util';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { AddressUtils } from '@src/service/util/location/AddressUtils';
import RoleUtils from '@src/service/util/role/RoleUtils';

export class ExternalEducationTemplateHelperUtils {
  static getEmailTemplate = (externalEducationTemplate: IExternalEducationTemplate, user: IUserInfo): string => {
    const subject = `${LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.EMAIL_TEMPLATE.SUBJECT_LABEL', { educationTemplateTitle: externalEducationTemplate.title })}`;
    const body = `${LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.EMAIL_TEMPLATE.BODY_LABEL', {
      firstName: user.firstName,
      lastName: user.lastName,
      oib: user.identificationNumber,
      address: AddressUtils.getFullAddress(user.residenceAddress?.address),
    })}`;

    const getEmailValue = (): string => {
      if (externalEducationTemplate.educationProvider.company) {
        const email = externalEducationTemplate.educationProvider.company.contactData?.find((contact: IContactData) => contact.type.id === ContactDataTypeEnum.EMAIL);
        if (email) {
          return email.value;
        }
      }
      return '';
    };
    return `mailto:${getEmailValue()}?subject=${subject}&body=${body}`;
  };

  /** Check if education template is in one of given statuses */
  static isEducationTemplateInStatus = (educationTemplate: IExternalEducationTemplate, requestedStatuses: Array<keyof typeof ExternalEducationTemplateStatusEnum>): boolean => {
    return requestedStatuses.find((status) => educationTemplate && educationTemplate.status.id === ExternalEducationTemplateStatusEnum[status]) != null;
  };

  /** Check if education instance is in one of given statuses */
  static isEducationInstanceInStatus = (educationInstance: IExternalEducationInstance, requestedStatuses: Array<keyof typeof ExternalEducationInstanceStatusEnum>): boolean => {
    return requestedStatuses.find((status) => educationInstance && educationInstance.status.id === ExternalEducationInstanceStatusEnum[status]) != null;
  };

  /** Check if education instance is editable by user */
  static isEducationTemplateEditable = (educationTemplate: IExternalEducationTemplate): boolean => {
    return ExternalEducationTemplateHelperUtils.isEducationTemplateInStatus(educationTemplate, ['IN_CREATION']) || (ExternalEducationTemplateHelperUtils.isEducationTemplateInStatus(educationTemplate, ['IN_CREATION', 'PUBLISHED', 'ARCHIVED']) && RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]));
  };

  /** Check if there are files for any listed types. */
  static hasFilesInTypes = (files: IFileListsByType, types: Array<keyof typeof ExternalEducationTemplateFileTypeEnum>) => {
    return hasFilesInTypes(files, types);
  };

  /** Check if there are files for all listed types. */
  static hasFilesInAllTypes = (files: IFileListsByType, types: Array<keyof typeof ExternalEducationTemplateFileTypeEnum>) => {
    return hasFilesInAllTypes(files, types);
  };
}

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SkillLevelClassificationInputLevelFields from '@src/components/skilllevelclassification/form/SkillLevelClassificationInputLevelFields';
import { ISkillLevel, ISkillLevelClassificationShortInfo } from '@src/model/skillgroup/SkillLevelClassification';
import { Empty, Form, FormInstance } from 'antd';
import React from 'react';

// -- Prop types
// ----------
export interface ISkillLevelClassificationData {
  skillLevelClassificationList: ISkillLevelClassificationShortInfo[];
}

export interface ISkillLevelClassificationFormOwnProps {
  skillLevelList: ISkillLevel[];
  skillLevelClassificationList: ISkillLevelClassificationShortInfo[];
  onSubmit?: (value: ISkillLevelClassificationShortInfo[]) => void;
  formRef?: FormInstance<ISkillLevelClassificationData>;
}

type ISkillLevelClassificationFormProps = ISkillLevelClassificationFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const SkillLevelClassificationForm: React.FC<ISkillLevelClassificationFormProps> = (props: ISkillLevelClassificationFormProps) => {
  const [form] = Form.useForm<ISkillLevelClassificationData>(props.formRef);

  const handleSubmit = (value: ISkillLevelClassificationData) => {
    props.onSubmit?.(value.skillLevelClassificationList);
  };

  const defaultSkillLevelValues = {
    skillLevelClassificationList: props.skillLevelList.map((item: any) => ({ skillLevel: { id: item.id } })),
  };

  const initialValues = () => {
    let initalValues;
    if (props.skillLevelClassificationList?.length > 1) {
      initalValues = { skillLevelClassificationList: props.skillLevelClassificationList };
    } else {
      initalValues = defaultSkillLevelValues;
    }
    return initalValues;
  };

  return (
    <Form<ISkillLevelClassificationData> labelCol={{ span: 24 }} component={false} initialValues={initialValues()} layout="vertical" form={form} onFinish={handleSubmit}>
      {props.skillLevelList.length ? <SkillLevelClassificationInputLevelFields /> : <Empty description={props.translate('SKILL_LEVEL_CLASSIFICATION_LIST.NO_DATA')} />}
    </Form>
  );
};

export default withLocalize<ISkillLevelClassificationFormOwnProps>(SkillLevelClassificationForm as any);

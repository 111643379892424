import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TraineeCourseListContainer from '@src/components/course/list/catalog/TraineeCourseListContainer';
import TraineeStartedCourseListContainer from '@src/components/course/list/catalog/TraineeStartedCourseListContainer';

// -- Prop types
// ----------

interface ITraineeMyCourseListViewOwnProps { }
type ITraineeMyCourseListViewProps = ITraineeMyCourseListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** View of trainee's "my" courses page */
const TraineeMyCourseListView: React.FC<ITraineeMyCourseListViewProps> = (props) => {
  return (
    <React.Fragment>
      <TraineeStartedCourseListContainer />

      <TraineeCourseListContainer />
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<ITraineeMyCourseListViewOwnProps>(TraineeMyCourseListView as any);

import StatusBadgeWrapper from '@src/components/common/card/StatusBadgeWrapper';
import IIdTitleRef from '@src/model/common/IdTitleRef';
import { ICourse } from '@src/model/course/Course';
import { IWebinar } from '@src/model/webinar/Webinar';
import { Card, Col, Row, Typography } from 'antd';

import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------
export interface IEducationCardOwnProps<T> {
  education: T;
  showStatusBadge?: boolean;
  type: 'COURSE' | 'WEBINAR';
  cardCover: React.ReactNode;
  cardTitleSuffix?: React.ReactNode;
  cardDescription: React.ReactNode;
  currentLecture?: IIdTitleRef;
  getPath: (education: T, lecture?: string) => string;
  onSetReminder?: (webinar: IWebinar) => void;
  reminderSet?: boolean;
}

type IEducationCardProps<T> = IEducationCardOwnProps<T>;

// -- Component
// ----------
/** Component display education information */
const EducationCard = <T extends ICourse | IWebinar>(props: IEducationCardProps<T>) => {
  return (
    <Link to={props.getPath(props.education, props.currentLecture?.id)} data-test-id={`timun-educationCard__link_${props.education.id}:lecture_${props.currentLecture?.id}`}>
      <StatusBadgeWrapper status={props.education.status} showBadge={props.showStatusBadge} type={props.type}>
        <Card hoverable={true} cover={props.cardCover} className="timun-educationCard">
          <Card.Meta
            title={
              <div className="text-center">
                <Row justify="space-between">
                  <Col>
                    <Typography.Text ellipsis={{ tooltip: props.education.title }}> {props.education.title}</Typography.Text>
                  </Col>
                  {props.cardTitleSuffix}
                </Row>
              </div>
            }
            description={props.cardDescription}
          />
        </Card>
      </StatusBadgeWrapper>
    </Link>
  );
};

export default EducationCard;

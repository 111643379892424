import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import ExamInstanceHelperUtils from '@src/components/exam/common/ExamInstanceHelperUtils';
import ExamInstanceStatus from '@src/components/exam/common/ExamInstanceStatus';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { Button } from 'antd';
import moment from 'moment';
import { withRouter, WithRouterProps } from 'react-router';
import { ICourse } from '@src/model/course/Course';
import ExamInstanceInfoPanel from '@src/components/exam/examinstancelist/ExamInstanceInfoPanel';

// -- Const
// ----------
const timeFormat = AppConfigService.getValue('dateTimeFormat.timeWithSeconds');

// -- Prop types
// ----------

export interface ITraineeExamInstanceListOwnProps {
  course: ICourse;
  examInstances: ICollectionData<IExamInstance>;
  onPageChange?: (page: number, pageSize?: number) => void;
  onExamStart?: (examInstance: IExamInstance) => void;
  additionalCols?: () => ITimunDataTableCol<IExamInstance>[];
}
type ITraineeExamInstanceListProps = ITraineeExamInstanceListOwnProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------

/** Describe your component ... */
const TraineeExamInstanceList: React.FC<ITraineeExamInstanceListProps> = (props: ITraineeExamInstanceListProps) => {
  // examAttemptNumber & isExamRevisionAllowed are same for every instance in list so it's picked first from array
  const examAttemptNumber = props.examInstances?.content[0]?.examTemplate.settings?.attemptsNumber;
  const examRemainingAttemptNumber = examAttemptNumber && examAttemptNumber - props.examInstances.content.length + 1;
  const getExamPercentage = (totalPoints: number, totalEarnedPoints: number) => `${((totalEarnedPoints / totalPoints) * 100).toFixed()}%`;
  const getExamGradeThreshold = (gradeThreshold: number) => `${gradeThreshold}%`;
  const renderColumns = () => {
    const columns: ITimunDataTableCol<IExamInstance>[] = [
      {
        key: 'submittedAt',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.SUBMITTED_AT_LABEL'),
        content: (record: IExamInstance) => (record.additionalData.submittedAt ? DateUtils.toViewDateFormat(record.additionalData.submittedAt, 'dateWithTime') : '/'),
      },
      {
        key: 'currentPhase',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.STATUS_LABEL'),
        content: (record: IExamInstance) => <ExamInstanceStatus activity={record.activities[0]} />,
      },
      {
        key: 'earnedPoints',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.EARNED_POINTS_LABEL'),
        content: (record: IExamInstance) => `${record.additionalData.totalEarnedPoints}/${record.additionalData.totalPoints} - ${getExamPercentage(record.additionalData.totalPoints, record.additionalData.totalEarnedPoints)}`,
      },
      {
        key: 'gradeThreshold',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.GRADE_THRESHOLD_LABEL'),
        content: (record: IExamInstance) => (record.examTemplate.settings?.gradeThreshold ? getExamGradeThreshold(record.examTemplate.settings?.gradeThreshold) : '/'),
      },
      {
        key: 'examPassed',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.EXAM_PASSED_LABEL'),
        content: (record: IExamInstance) => ExamInstanceHelperUtils.isExamInstancenInPhase(record, [ActivityPhaseEnum.EVALUATED]) && (record.additionalData.examPassed ? props.translate('COMMON.LABEL.YES') : props.translate('COMMON.LABEL.NO')),
      },
      {
        key: 'timeOfCompletion',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.TIME_LABEL'),
        content: (record: IExamInstance) =>
          moment()
            .startOf('day')
            .milliseconds(record.additionalData.completionTimeInMs)
            .format(timeFormat), // can't find a normal way to display time format from miliseconds
      },
      {
        key: 'action',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.ACTION_LABEL'),
        content: (record: IExamInstance) => {
          if (ExamInstanceHelperUtils.isExamInstancenInPhase(record, [ActivityPhaseEnum.READY])) {
            return <Button onClick={() => props.onExamStart?.(record)}> {props.translate('EXAM_INSTANCE.LIST_ITEM.BUTTON.START_THE_EXAM_LABEL')} </Button>;
          }
          if (ExamInstanceHelperUtils.isExamInstancenInPhase(record, [ActivityPhaseEnum.ANSWERING_STARTED])) {
            return <Button onClick={() => props.router.push(`course/${props.course.id}/exam/${record.id}`)}> {props.translate('EXAM_INSTANCE.LIST_ITEM.BUTTON.CONTINUE_SOLVING_EXAM_LABEL')} </Button>;
          }
          if (ExamInstanceHelperUtils.isExamInstancenInPhase(record, [ActivityPhaseEnum.EVALUATED])) {
            return <Button onClick={() => props.router.push(`course/${props.course.id}/exam/${record.id}`)}> {props.translate('EXAM_INSTANCE.LIST_ITEM.BUTTON.VIEW_EVALUATION_LABEL')} </Button>;
          }
          if (ExamInstanceHelperUtils.isExamInstancenInPhase(record, [ActivityPhaseEnum.ANSWERS_SUBMITTED])) {
            return <Button onClick={() => props.router.push(`course/${props.course.id}/exam/${record.id}`)}> {props.translate('EXAM_INSTANCE.LIST_ITEM.BUTTON.VIEW_EVALUATION_LABEL')} </Button>;
          }
          return null;
        },
      },
    ];
    if (props.additionalCols) {
      columns.push(...props.additionalCols());
    }
    return columns;
  };
  return (
    <React.Fragment>
      <ExamInstanceInfoPanel courseCompletionStatus={props.course.progress?.completionStatus} attemptNumber={examAttemptNumber} remainingAttemptNumber={examRemainingAttemptNumber} />
      <DataTable<IExamInstance> items={props.examInstances.content} paginationData={props.examInstances.page} columns={renderColumns()} scrollable={true} paginationConfig={{ onChange: props.onPageChange }} showActionMenu={true} />
    </React.Fragment>
  );
};

export default withRouter<ITraineeExamInstanceListOwnProps>(withLocalize(TraineeExamInstanceList as any));

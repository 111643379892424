import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { NotificationTypeEnum } from '@src/model/notification/Notification';
import { INotificationListFilter } from '@src/service/business/notification/notificationListBusinessStore';
import { Col, Form, Row, Select } from 'antd';
import React, { useCallback } from 'react';

const FormItem = Form.Item;

const { Option } = Select;

interface INotificationListFilterOwnProps {
  listFilter?: INotificationListFilter;
  onChangeFilter: (listFilter: INotificationListFilter) => void;
}

type INotificationListFilterProps = INotificationListFilterOwnProps & IWithLocalizeOwnProps;

const NotificationListFilter: React.FC<INotificationListFilterProps> = (props: INotificationListFilterProps) => {
  const [form] = Form.useForm<INotificationListFilter>();

  const handleFilterSubmit = useCallback(
    (values: INotificationListFilter) => {
      props.onChangeFilter(values);
    },
    [props.onChangeFilter]
  );

  const handleClear = useCallback(() => {
    props.onChangeFilter({});
  }, [props.onChangeFilter]);

  return (
    <Form<INotificationListFilter> form={form} initialValues={props.listFilter}>
      <Row align="middle">
        {/**
           this is here until we decide to use search for filtering too
           */}
        {/*<Col span={14}>
              <FormItem>
                {getFieldDecorator('searchInput', {})(<Input prefix={<Icon type="search"/>}/>)}
              </FormItem>
            </Col>*/}
        <Col xs={24} md={12} lg={8}>
          <FormItem name={'type'} label={props.translate('NOTIFICATION_LIST_FILTER.SORT_BY_TYPE')} colon={false}>
            <Select onSelect={handleFilterSubmit} placeholder={props.translate('NOTIFICATION_LIST_FILTER.SELECTOR_OPTION.ALL_NOTIFICATIONS')} allowClear={true} onClear={handleClear}>
              {/* TODO: commented for EOS instructions purpose. Only generic type is available
                   {Object.keys(NotificationTypeEnum).map((item: any) => (
                    <Option value={NotificationTypeEnum[item as keyof typeof NotificationTypeEnum]} key={NotificationTypeEnum[item as keyof typeof NotificationTypeEnum]}>
                      { props.translate(`NOTIFICATION_FORM.${item}_TYPE`)}
                    </Option>
                  ))} */}
              <Option value={NotificationTypeEnum.GENERIC} key={NotificationTypeEnum.GENERIC}>
                {props.translate('NOTIFICATION_FORM.GENERIC_TYPE')}
              </Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize<INotificationListFilterOwnProps>(NotificationListFilter as any);

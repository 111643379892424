import React from 'react';

import ExternalEducationTemplateDataTablePicker from '@src/components/common/datapicker/externalEducationTemplate/ExternalEducationTemplateDataTablePicker';
import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ISkill } from '@src/model/skillgroup/Skill';
import { Button, Modal } from 'antd';

// -- Prop types
// ----------

export interface IExternalEducationTemplateModalDataPickerOwnProps {
  skill: ISkill;
  onSubmit?: (externalEducationTemplate: IExternalEducationTemplate) => void;
  onCancel?: () => void;
}
type IExternalEducationTemplateModalDataPickerProps = IExternalEducationTemplateModalDataPickerOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationTemplateModalDataPicker = (props: IExternalEducationTemplateModalDataPickerProps) => {

  const handleChange = (value?: IExternalEducationTemplate) => {
    if (value) {
      props.onSubmit?.(value);
      props.onCancel?.();
    }
  };

  return (
    <Modal
      className="lemon-modal__modal--lg"
      title={props.translate('EXTERNAL_EDUCATION_TEMPLATE.MODAL_DATA_PICKER.TITLE')}
      visible={true}
      onCancel={props.onCancel}
      footer={[
        <Button onClick={props.onCancel}>{props.translate('COMMON.ACTION_CLOSE')}</Button>,
      ]}>
      <GridItem label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.MODAL_DATA_PICKER.AVAILABLE_EDUCATION_TEMPLATE_LABEL')}>
        {props.translate('EXTERNAL_EDUCATION_TEMPLATE.MODAL_DATA_PICKER.AVAILABLE_EDUCATION_TEMPLATE_TEXT')}
      </GridItem>
      <ExternalEducationTemplateDataTablePicker
        skill={props.skill}
        onChange={handleChange}
      />
    </Modal >
  );
};

export default withLocalize<IExternalEducationTemplateModalDataPickerOwnProps>(ExternalEducationTemplateModalDataPicker as any);

import React from 'react';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AppConfigService from '@src/service/common/AppConfigService';
import { Form, Input, InputNumber, Modal, Radio } from 'antd';
import { ISkillLevel } from '@src/model/skillgroup/SkillLevelClassification';
import { ISkillLevelCreatePayload } from '@src/service/business/skilllevel/SkillLevelBusinessStore';

// -- Const
// ----------
const INPUT_MAX_LENGTH = AppConfigService.getValue('components.common.mediumCharacterLengthInput');

// -- Prop types
// ----------
export interface ISkillLevelFormOwnProps {
  value?: ISkillLevel;
  onSkillLevelCreateSubmit?: (locationCodebook: ISkillLevelCreatePayload) => void;
  onSkillLevelUpdateSubmit?: (locationCodebook: ISkillLevel) => void;
  onCancel?: () => void;
}

type ISkillLevelFormProps = ISkillLevelFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display form for create and update location codebook */
const SkillLevelForm: React.FC<ISkillLevelFormProps> = (props: ISkillLevelFormProps) => {
  const [form] = Form.useForm<ISkillLevel>();

  const handleFinish = (value: ISkillLevel) => {
    if (props.value?.id) {
      //  update
      props.onSkillLevelUpdateSubmit?.(value);
    } else if (props.onSkillLevelCreateSubmit) {
      // create
      props.onSkillLevelCreateSubmit(value);
    }
  };

  return (
    <Modal title={props.translate('SKILL_LEVEL_MODAL.TITLE')} visible={true} onCancel={props.onCancel} onOk={form.submit} okText={props.translate('COMMON.ACTION_SAVE')} cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form<ISkillLevel> layout="vertical" form={form} initialValues={props.value} name="skillLevel" onFinish={handleFinish}>
        <Form.Item
          label={<FormItemLabelIcon label={props.translate('SKILL_LEVEL_FORM.NAME_LABEL')} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
          name={'name'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={props.translate('SKILL_LEVEL_FORM.LEVEL_LABEL')} name={'level'} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
          <InputNumber min={1} max={5} />
        </Form.Item>

        <Form.Item label={props.translate('COMMON.LABEL.STATUS')} name={'active'} rules={[{ required: true, message: props.translate('COMMON.PICK_REQUIRED_FIELD') }]}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={true}>{props.translate('COMMON.LABEL.ACTIVE')} </Radio.Button>
            <Radio.Button value={false}>{props.translate('COMMON.LABEL.INACTIVE')} </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle={true} hidden={true} name={'id'}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withLocalize<ISkillLevelFormOwnProps>(SkillLevelForm as any);

import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import FileTypeUploadModal from '@src/components/common/file/FileTypeUploadModal';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withNumberFormat, { IWithNumberFormatOwnProps } from '@src/components/common/numberformat/withNumberFormat';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import EducationApplicationFileListView from '@src/components/externalEducationApplication/common/EducationApplicationFileListView';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { FileTypeEnum } from '@src/model/file/FileType';
import { IFile } from '@src/model/file/File';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IFileListsByType } from '@src/service/business/files/util';
import { Button } from 'antd';
import React, { SyntheticEvent, useCallback, useState } from 'react';

interface IEducationApplicationFilesViewSectionOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  canUpload?: boolean;
  active?: boolean;
  canFileRemove?: boolean;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}

type IEducationApplicationFilesViewSectionProps = IEducationApplicationFilesViewSectionOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & IWithNumberFormatOwnProps;

const EducationApplicationFilesViewSection = (props: IEducationApplicationFilesViewSectionProps) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const handleFileModalOpen = (event: SyntheticEvent) => {
    event.stopPropagation();
    setModalVisible(true);
  };

  const handleFileModalClose = () => setModalVisible(false);

  const handleFileUpload = (file: IFile[]) => {
    props.onFileUpload?.(file);
    setModalVisible(false);
  };

  const handleFileRemove = (file: IFile[]) => {
    confirmationDialog({
      onConfirm: () => props.onFileRemove?.(file),
      // ** There is only one file in array to remove so we can safely indexing that file from IFile[]  */
      title: props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES_VIEW_SECTION.FILE_REMOVE_CONFIRMATION_TITLE', { fileName: file[0].name }),
    });
  };

  const isApplicationInPhases = useCallback(
    (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
      return EducationApplicationHelperUtils.isInPhases(phases, props.externalEducationApplication.activity);
    },
    [props.externalEducationApplication.activity.id]
  );

  const allowedFileTypes: (keyof typeof FileTypeEnum)[] = props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COORDINATOR])
    ? ['MUTUALLY_SIGNED_FINANCING_AGREEMENT', 'PROOF_OF_PAYMENT', 'ENROLLMENT_REQUIREMENT', 'CURRICULUM_VITAE', 'OFFER', 'REFERRAL']
    : ['ENROLLMENT_REQUIREMENT', 'CURRICULUM_VITAE', 'OFFER'];

  const canUpload =
    props.canUpload &&
    isApplicationInPhases([
      'SUBMITTED',
      'EXTERNAL_EDUCATION_TEMPLATE_SELECTION',
      'AWAITING_APPROVAL',
      'CONTRACT_DATA_PROCESSING',
      'CONTRACT_GENERATION',
      'AWAITING_SIGNED_CONTRACT',
      'AWAITING_PAYMENT',
      'EDUCATION_IN_PROGRESS',
      'CONTRACT_ANNEX_REQUESTED',
      'CONTRACT_ANNEX_SUBMITTED',
      'EXTERNAL_EDUCATION_APPLICATION_CREATION',
      'RETURNED_TO_USER',
    ]);

  return (
    <React.Fragment>
      <ViewSectionItem
        active={props.active}
        bordered={true}
        header={<HeaderTitle title={props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENTS_AND_CERTIFICATES_PANEL_TITLE')} icon={<LemonIcon name="file" />} />}
        collapsible={true}
        actions={
          canUpload ? (
            <Button onClick={handleFileModalOpen} icon={<LemonIcon name="plus" />}>
              {props.translate('COMMON.ACTION_ADD_FILE')}
            </Button>
          ) : null
        }
      >
        {
          <EducationApplicationFileListView
            files={props.applicationFiles}
            displayTypes={['SIGNED_FINANCING_AGREEMENT', 'MUTUALLY_SIGNED_FINANCING_AGREEMENT', 'PROOF_OF_PAYMENT', 'COMPETENCIES_CERTIFICATE', 'ATTENDANCE_CERTIFICATE', 'APPLICATION_EXPORT', 'OFFER', 'ENROLLMENT_REQUIREMENT', 'CURRICULUM_VITAE', 'REFERRAL']}
            canRemove={props.canFileRemove}
            onRemove={handleFileRemove}
          />
        }
      </ViewSectionItem>

      {isModalVisible && <FileTypeUploadModal allowedFileTypes={allowedFileTypes} fileList={props.applicationFiles} onFileRemove={props.onFileRemove} onCancel={handleFileModalClose} onFileUpload={handleFileUpload} />}
    </React.Fragment>
  );
};

export default withRoles(withNumberFormat(withLocalize<IEducationApplicationFilesViewSectionOwnProps>(EducationApplicationFilesViewSection as any)));

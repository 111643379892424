import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import React from 'react';
import FileView from '@src/components/common/file/FileView';
import { IFile } from '@src/model/file/File';
import LemonIcon from '@src/components/common/image/LemonIcon';
import FileUtils from '@src/components/common/file/FileUtils';
import { ITimunFile } from '@src/service/business/files/util';

export interface ICourseCertificateViewOwnProps {
  certificate: IFile;
}

type ICourseCertificateProps = ICourseCertificateViewOwnProps & IWithLocalizeOwnProps;

const CourseCertificateView: React.FC<ICourseCertificateProps> = (props: ICourseCertificateProps) => {
  const certificate: ITimunFile = FileUtils.mapToTimunFile(props.certificate);
  return (
    <React.Fragment>
      <Typography.Title className="timun-wrapper__gutterBox--vertical">{props.translate('CERTIFICATE.COMPLETED_COURSE_LABEL')}</Typography.Title>

      <Row className="timun-wrapper__gutterBox--vertical" justify="space-between">
        <Col>
          <Typography.Title level={3}>{certificate.name}</Typography.Title>
        </Col>
        <Col>
          <Tooltip title={props.translate('COMMON.ACTION_DOWNLOAD')}>
            <Button href={certificate.url}>
              {props.translate('COMMON.ACTION_DOWNLOAD')}
              <LemonIcon name="download" size="xlarge" />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <FileView file={certificate} />
    </React.Fragment>
  );
};

export default withLocalize(CourseCertificateView) as any;

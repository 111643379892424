import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Select } from 'antd';
import React, { useMemo } from 'react';

// -- Const
// ----------

export enum EducationApplicationOwnershipStatusEnum {
  MY_APPLICATIONS = '1',
  OTHER_COORDINATORS_APPLICATIONS = '2',
  WITHOUT_COORDINATOR = '3',
}
// -- Prop types
// ----------
export interface IExternalApplicationOwnershipStatusSelectorOwnProps {
  value?: EducationApplicationOwnershipStatusEnum;
  onChange?: (newValue?: EducationApplicationOwnershipStatusEnum) => void;
}

type IExternalApplicationOwnershipStatusSelectorProps = IExternalApplicationOwnershipStatusSelectorOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const ExternalApplicationOwnershipStatusSelector: React.FC<IExternalApplicationOwnershipStatusSelectorProps> = (props) => {
  const options = useMemo(
    () =>
      Object.keys(EducationApplicationOwnershipStatusEnum).map((item: string) => ({
        value: EducationApplicationOwnershipStatusEnum[item as keyof typeof EducationApplicationOwnershipStatusEnum],
        key: EducationApplicationOwnershipStatusEnum[item as keyof typeof EducationApplicationOwnershipStatusEnum],
        dataTestId: `timun-externalEducationApplicationFilter__ownershipSelect_option_${item}`,
        label: props.translate(`EXTERNAL_EDUCATION_APPLICATION.OWNERSHIP.${item}_LABEL`),
      })),
    [EducationApplicationOwnershipStatusEnum, props.translate]
  );

  return <Select onChange={props.onChange} defaultValue={props.value} allowClear={true} placeholder={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.PICK_STATUS_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationFilter__ownershipSelect" options={options} />;
};

export default withLocalize<IExternalApplicationOwnershipStatusSelectorOwnProps>(ExternalApplicationOwnershipStatusSelector as any);

import React from 'react';
import { connect } from 'react-redux';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseCreatorExamInstanceList from '@src/components/exam/examinstancelist/CourseCreatorExamInstanceList';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import CourseListBusinessStore from '@src/service/business/courses/courseListBusinessStore';
import { IExamInstanceListFilter } from '@src/service/business/examtemplates/examInstanceBusinessStore';

// -- Const
// ----------
const VIEW_NAME = '@@COURSE_EXAM_INSTANCE_LIST';

// -- Prop types
// ----------

export interface IExamInstanceListContainerOwnProps {
  courseId: string;
}

export interface IExamInstanceListContainerStateProps {
  examInstanceList: ICollectionData<IExamInstance>;
}
export interface IExamInstanceListContainerDispatchProps {
  fetchExamInstanceList: (payload: ICollectionFetchPayload<IExamInstanceListFilter>) => void;
}
type IExamInstanceListContainerProps = IExamInstanceListContainerOwnProps & IExamInstanceListContainerStateProps & IExamInstanceListContainerDispatchProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExamInstanceListContainer = (props: IExamInstanceListContainerProps) => {
  const [collectionParams, updateCollectionParams] = useCollectionState<IExamInstanceListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchExamInstanceList,
  }, [props.courseId]);

  return (
    <React.Fragment>
      {props.examInstanceList &&
        <CourseCreatorExamInstanceList
          courseId={props.courseId}
          examInstances={props.examInstanceList}
          examInstanceListFilter={collectionParams.filter}
          onFilterChange={updateCollectionParams.onFilterSet}
          onPageChange={updateCollectionParams.onPageChange} />}
    </React.Fragment >
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExamInstanceListContainerOwnProps): IExamInstanceListContainerStateProps => ({
  examInstanceList: CourseListBusinessStore.selectors.getCourseExamInstanceList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IExamInstanceListContainerOwnProps): IExamInstanceListContainerDispatchProps => ({
  fetchExamInstanceList: (params: ICollectionFetchPayload<IExamInstanceListFilter>) => dispatch(CourseListBusinessStore.actions.fetchCourseExamInstanceList(params, ownProps.courseId)),
});


export default connect<IExamInstanceListContainerStateProps, IExamInstanceListContainerDispatchProps, IExamInstanceListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ExamInstanceListContainer as any));

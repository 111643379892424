import ProgressTrackerBusinessStore from '@src/service/business/progresstracker/ProgressTrackerBuisnessStore';
import { connect } from 'react-redux';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import React, { useCallback, useEffect } from 'react';
import { IProgressTrackResult } from '@src/model/progresstracker/IProgressTrackResult';
import { Modal } from 'antd';
import UserManagementList from '@src/components/user/list/UserManagementList';

export interface IDeactivatedUserListContainerOwnProps {
  progressTrackerId: string;
  onCancel?: () => void;
}

export interface IDeactivatedUserListContainerStateProps {
  deactivatedUser: IProgressTrackResult;
}

export interface IDeactivatedUserListContainerDispatchProps {
  fetchDeactivatedUser: (id: string) => void;
}

type IDeactivatedUserListContainerProps = IDeactivatedUserListContainerOwnProps & IDeactivatedUserListContainerStateProps & IDeactivatedUserListContainerDispatchProps & IWithRolesOwnProps & IWithLocalizeOwnProps;

const IDeactivatedUserListContainer: React.FC<IDeactivatedUserListContainerProps> = (props: IDeactivatedUserListContainerProps) => {
  useEffect(() => {
    fetchDeactivatedUser();
  }, [props.progressTrackerId]);

  const fetchDeactivatedUser = useCallback(() => {
    props.fetchDeactivatedUser(props.progressTrackerId);
  }, [props.fetchDeactivatedUser, props.progressTrackerId]);

  return (
    <React.Fragment>
      {props.deactivatedUser && (
        <Modal className="lemon-modal__modal--lg" title={props.translate('DEACTIVATED_USER_LIST_VIEW.MODAL.TITLE')} maskClosable={false} visible={true} onCancel={props.onCancel} footer={null}>
          <UserManagementList userList={props.deactivatedUser.deactivatedUsers} showAction={true} />
        </Modal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, ownProps: IDeactivatedUserListContainerOwnProps): IDeactivatedUserListContainerStateProps => ({
  deactivatedUser: ProgressTrackerBusinessStore.selectors.getProgressTrackResult(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IDeactivatedUserListContainerOwnProps): IDeactivatedUserListContainerDispatchProps => ({
  fetchDeactivatedUser: (id: string) => dispatch(ProgressTrackerBusinessStore.actions.fetchProgressTrackResult({ id })),
});

export default connect<IDeactivatedUserListContainerStateProps, IDeactivatedUserListContainerDispatchProps, IDeactivatedUserListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRoles(withLocalize(IDeactivatedUserListContainer as any)));

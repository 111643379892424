import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { WebinarStatusEnum } from '@src/model/webinar/Webinar';
import { IWebinarsFilter } from '@src/service/business/webinars/webinarListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Input, Row, Select } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useMemo } from 'react';

const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');

const sortItems = [
  {
    id: 'title,asc',
    translationKey: 'COMMON.ACTION_SORT_ASCEND',
  },
  {
    id: 'title,desc',
    translationKey: 'COMMON.ACTION_SORT_DESCEND',
  },
  {
    id: 'estimatedStartDateTime,asc',
    translationKey: 'WEBINAR_LIST_VIEW.SORT_START_DATE_ASCEND',
  },
  {
    id: 'estimatedStartDateTime,desc',
    translationKey: 'WEBINAR_LIST_VIEW.SORT_START_DATE_DESCEND',
  },
];

// -- Prop types
// ----------

interface IWebinarListViewFilterOwnProps {
  webinarListFilter: IWebinarsFilter;
  sortValue?: string[];
  showStatusFilter?: boolean;
  onFilterChange?: (data: IWebinarsFilter) => void;
  onSortChange?: (sort: string[]) => void;
}

type IWebinarListViewFilterProps = IWebinarListViewFilterOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/**  */
const WebinarListViewFilter: React.FC<IWebinarListViewFilterProps> = (props: IWebinarListViewFilterProps) => {
  const handleStatusChange = useCallback(
    debounce((data: string[]) => {
      props.onFilterChange?.({ ...props.webinarListFilter, statuses: data });
    }, debounceTimeout),
    [props.onFilterChange, props.webinarListFilter, debounceTimeout]
  );

  const handleTitleSearch = useCallback(
    (value: string) => {
      if (value.trim()) {
        props.onFilterChange?.({ ...props.webinarListFilter, title: value.trim() });
      } else {
        const { title, ...filter } = props.webinarListFilter;
        props.onFilterChange?.(filter);
      }
    },
    [props.onFilterChange, props.webinarListFilter]
  );

  const optionsStatuses = useMemo(() => {
    return Object.keys(WebinarStatusEnum).map((item: string) => ({
      key: WebinarStatusEnum[item as keyof typeof WebinarStatusEnum],
      value: WebinarStatusEnum[item as keyof typeof WebinarStatusEnum],
      label: props.translate(`WEBINAR.STATUS_TITLE.${item}`),
    }));
  }, []);

  const optionsSort = useMemo(() => {
    return sortItems.map((item) => ({
      key: item.id,
      value: item.id,
      label: props.translate(item.translationKey),
      dataTestId: 'timun-webinarListFilter__sortSelect',
    }));
  }, []);

  return (
    <Row gutter={16} className="timun-wrapper__gutterBox--vertical" align="middle">
      <Col xs={24} sm={12} md={props.showStatusFilter ? 6 : 12}>
        <GridItem label={props.translate('COMMON.LABEL.NAME')}>
          <Input.Search defaultValue={props.webinarListFilter?.title} className="timun-webinarFilter__searchInput" prefix={<LemonIcon name="search" />} allowClear={true} placeholder={props.translate('COMMON.ACTION_SEARCH')} onSearch={handleTitleSearch} />
        </GridItem>
      </Col>
      {props.showStatusFilter && (
        <Col xs={24} sm={12}>
          <GridItem label={props.translate('COMMON.LABEL.STATUS')}>
            <Select options={optionsStatuses} defaultValue={props.webinarListFilter?.statuses} className="full-width" mode={'multiple'} placeholder={props.translate('WEBINAR_LIST_VIEW.ALL_WEBINARS')} allowClear={true} onChange={handleStatusChange} />
          </GridItem>
        </Col>
      )}
      <Col xs={24} sm={12} md={props.showStatusFilter ? 6 : 12}>
        <GridItem label={props.translate('COMMON.ACTION_SORT_LABEL')}>
          <Select options={optionsSort} onChange={props.onSortChange} value={props.sortValue} data-test-id="timun-webinarListFilter__sortSelect" />
        </GridItem>
      </Col>
    </Row>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IWebinarListViewFilterOwnProps>(WebinarListViewFilter as any);

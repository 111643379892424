import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { ILocation } from '@src/model/location/Location';
import { ICollectionData } from '@src/service/business/common/types';
import LocationBusinessStore, { ILocationListFilter } from '@src/service/business/location/LocationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

export interface ILocationDataPickerOwnProps {
  value?: ILocation;
  disabled?: boolean;
  onChange?: (value?: ILocation) => void;
}

export interface ILocationDataPickerStateProps {
}

export interface ILocationDataPickerDispatchProps {
  fetchLocationList: (filter: ILocationListFilter) => ITrackableAction;
}

type ILocationDataPickerProps = ILocationDataPickerOwnProps & ILocationDataPickerStateProps & ILocationDataPickerDispatchProps;

interface ILocationDataPickerState {
  locationList?: ILocation[];
}

class LocationDataPicker extends React.Component<ILocationDataPickerProps, ILocationDataPickerState> {
  state: ILocationDataPickerState = {
    locationList: undefined,
  };

  componentDidMount() {
    this.updateList();
  }

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.locationList ? this.state.locationList.map(this.mapToItem) : this.props.value ? [this.mapToItem(this.props.value)] : [];

    return <DataPicker disabled={this.props.disabled} onChange={this.handleDataPickerChange} value={pickerValue} items={pickerItems} onSearch={this.handleSearch} />;
  };

  handleSearch = (value: string) => {
    const filter: ILocationListFilter = { title: value };
    if (value.length >= minSearchLength) {
      this.updateList(filter);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.updateList();
    }
  };


  handleDataPickerChange = (value?: IDataPickerItem) => {
    this.props.onChange?.(value?.data);
  };

  private mapToItem = (location: ILocation): IDataPickerItem => {
    return {
      value: location.id,
      name: location.title,
      data: location,
    };
  };

  private updateList = (filter: ILocationListFilter = {}) => {
    this.props
      .fetchLocationList(filter)
      .track()
      .subscribe((data: ICollectionData<ILocation>) => this.storeList(data.content));
  };

  private storeList = (locationList?: ILocation[]) => {
    this.setState({ locationList: locationList ?? [] });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ILocationDataPickerOwnProps): ILocationDataPickerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): ILocationDataPickerDispatchProps => ({
  fetchLocationList: (filter: ILocationListFilter) => createTrackableAction(dispatch(LocationBusinessStore.actions.fetchLocationPickerList(filter))),
});

export default connect<ILocationDataPickerStateProps, ILocationDataPickerDispatchProps, ILocationDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(LocationDataPicker);

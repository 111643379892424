import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import PublicCourseListView from '@src/components/course/list/catalog/PublicCourseListView';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { IUserDetails } from '@src/model/user/UserDetails';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';
import { ICollectionFetchPayload } from '@src/service/business/common/types';
import PublicCourseListBusinessStore, { IPublicCourseListFilter } from '@src/service/business/courses/publicCourseListBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

const defaultSortValue = ['title,asc'];

// -- Prop types
// ----------

export interface IPublicCourseListContainerOwnProps {}
export interface IPublicCourseListContainerStateProps {
  currentUser: IUserDetails;
  courseGroups: ICourseGroup[];
  courseList: ICollectionData<ICourse>;
  courseListFilter: IPublicCourseListFilter;
}
export interface IPublicCourseListContainerDispatchProps {
  fetchList: (params: ICollectionFetchPayload<IPublicCourseListFilter>) => any;
  clearList: () => void;
  storeListFilter: (filter: IPublicCourseListFilter) => void;
  clearListFilter: () => void;
}
type IPublicCourseListContainerProps = IPublicCourseListContainerOwnProps & IPublicCourseListContainerStateProps & IPublicCourseListContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// --
// ----- State types

interface IPublicCourseListContainerState {
  page: number;
  size: number;
  sort: string[];
}

// -- Component
// ----------

/**
 * Container component for public course list.
 *
 * TODO: this component is currently only a POC for public BE endpoints
 */
class PublicCourseListContainer extends React.Component<IPublicCourseListContainerProps, IPublicCourseListContainerState> {
  state = {
    page: 0,
    size: AppConfigService.getValue('api.paging.midPageSize'),
    sort: defaultSortValue,
  };

  componentDidMount() {
    // initial list update
    this.updateList();
  }

  componentDidUpdate(prevProps: IPublicCourseListContainerProps, prevState: IPublicCourseListContainerState) {
    if (this.props.courseListFilter !== prevProps.courseListFilter || this.state !== prevState) {
      this.updateList();
    }
  }

  componentWillUnmount = () => {
    this.props.clearListFilter();
  };

  render = () => {
    return (
      <PublicCourseListView
        courseList={this.props.courseList}
        courseListFilter={this.props.courseListFilter}
        courseGroups={this.props.courseGroups ? this.props.courseGroups : []}
        sortValue={this.state.sort}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
        onFilterChange={this.handleFilterChange}
        getPath={this.getPath}
      />
    );
  };

  handleFilterChange = (data: IPublicCourseListFilter) => {
    this.props.storeListFilter(data);
    this.setState({ page: 0 });
  };

  handleSortChange = (sort: string[]) => {
    this.setState({ page: 0, sort });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  private getPath = (course: ICourse) => {
    const coursePath = CourseHelperUtils.getTraineePath(course);
    return this.props.currentUser ? coursePath : `/public${coursePath}`;
  };

  private updateList(page: number = this.state.page, size: number = this.state.size, sort: string[] = this.state.sort) {
    this.props.fetchList({
      page,
      size,
      sort,
      filter: this.props.courseListFilter,
    });
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IPublicCourseListContainerOwnProps): IPublicCourseListContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'PublicCourseGroup'),
  courseList: PublicCourseListBusinessStore.selectors.getList(state),
  courseListFilter: PublicCourseListBusinessStore.selectors.getListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IPublicCourseListContainerDispatchProps => ({
  fetchList: (filter: ICollectionFetchPayload<IPublicCourseListFilter>) => dispatch(PublicCourseListBusinessStore.actions.fetchList(filter)),
  clearList: () => dispatch(PublicCourseListBusinessStore.actions.clearList()),
  storeListFilter: (filter: IPublicCourseListFilter) => dispatch(PublicCourseListBusinessStore.actions.storeListFilter(filter)),
  clearListFilter: () => dispatch(PublicCourseListBusinessStore.actions.clearListFilter()),
});

export default connect<IPublicCourseListContainerStateProps, IPublicCourseListContainerDispatchProps, IPublicCourseListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(PublicCourseListContainer as any)));

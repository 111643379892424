import SkillLevelCodebookContainer from '@src/components/codebook/SkillLevelCodebookContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { Empty } from 'antd';
import React from 'react';

interface IAdminSkillLevelPageOwnProps { }
type IAdminSkillLevelCodebookPageProps = IWithRolesOwnProps & IWithTenantPropEnabledOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const AdminSkillLevelCodebookPage = (props: IAdminSkillLevelCodebookPageProps) => {
  const DisplayComponent = (props.isSuperAdmin() || props.isTenantPropEnabled('codebook_skill_level')) ? <SkillLevelCodebookContainer /> : <Empty description={props.translate('COMMON.LABEL.NOT_ALLOWED_TO_ACCESS')} />;
  return (
    <React.Fragment>
      {DisplayComponent}
    </React.Fragment>
  );
};

export default withTenantPropEnabled<IAdminSkillLevelPageOwnProps>(withLocalize(withRoles(AdminSkillLevelCodebookPage as any)));

import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { ICity } from '@src/model/location/City';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface ICityListFilter {
  name?: string;
}

// List filter ID
const CITY_LIST_FILTER = '@@CITY_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns list of Citys from store. */
const getCityList = (store: any): ICollectionData<ICity> => store.cityList;

/** Returns City list filter. */
const getCityListFilter = (store: any): ICityListFilter => ListFilterBusinessStore.selectors.getListFilter(store, CITY_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  CITY_LIST_FETCH: 'CITY_LIST_FETCH',
  CITY_LIST_LOAD: 'CITY_LIST_LOAD',
  CITY_LIST_CLEAR: 'CITY_LIST_CLEAR',
};

/** Fetch City list by filter. */
const fetchCityList = (params: ICollectionFetchPayload<ICityListFilter>): IPayloadAction<ICollectionFetchPayload<ICityListFilter>> => {
  return {
    type: Actions.CITY_LIST_FETCH,
    payload: params,
  };
};

/** Load City list to store. */
const loadCityList = (data: ICollectionData<ICity>): IPayloadAction<ICollectionData<ICity>> => {
  return {
    type: Actions.CITY_LIST_LOAD,
    payload: data,
  };
};

/** Clear City list from store. Eg. when leaving list view. */
const clearCityList = (): ILemonAction => {
  return {
    type: Actions.CITY_LIST_CLEAR,
  };
};

/** Store City list filter to store. */
const storeCityListFilter = (listFilter: ICityListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(CITY_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchCityListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ICityListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CITY_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('City')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCityList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching City list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const cityList = (state: ICollectionData<ICity> | null = null, action: IPayloadAction<ICollectionData<ICity>>) => {
  if (action.type === Actions.CITY_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.CITY_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const CityListBusinessStore = {
  actions: {
    fetchCityList,
    loadCityList,
    clearCityList,
    storeCityListFilter,
  },

  selectors: {
    getCityList,
    getCityListFilter,
  },

  effects: {
    fetchCityListEffect,
  },

  reducers: {
    cityList,
  },
};

// --
// export business store
export default CityListBusinessStore;

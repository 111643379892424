import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Card } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

// -- Public component props
export interface ICourseGroupGridItemOwnProps {
  /** Course group object. */
  courseGroup: ICourseGroup;

  /** If this groups should be a link, then this function can be used to inject that URL. */
  linkTo?: string;

  /** Only use when you need the component for editing purposes */
  showEditButtons?: boolean;
  onDeleteCourseGroup?: (courseGroup: ICourseGroup) => void;
  onEditCourseGroupTitle?: (id: string, title: string) => void;
  onEditCourseGroupCover?: (id: string) => void;
}

// -- Complete component props
type ICourseGroupGridItemProps = ICourseGroupGridItemOwnProps;

// -- Component
// ----------

/** Course group grid item. */
export default function CourseGroupGridItem(props: ICourseGroupGridItemProps) {
  const groupDisplayName = props.courseGroup ? props.courseGroup.title : LocalizeService.translate('COURSE_LIST.UNGROUPED_TITLE');
  const groupDisplayImage = props.courseGroup.coverImageUrl ? props.courseGroup.coverImageUrl : 'cover-placeholder.png';

  return (
    <Card
      hoverable={props.linkTo != null}
      className="timun-itemGroup__container"
      cover={<LemonImage imagePath={groupDisplayImage} className="timun-courseCard__coverImage"/>}
      actions={
        props.showEditButtons ? [
          <Button icon={<LemonIcon name="image"/>} key="editPicture" onClick={() => props.onEditCourseGroupCover && props.onEditCourseGroupCover(props.courseGroup.id)} data-test-id="timun-courseGroupItem__editPictureButton"/>,
          <Button icon={<LemonIcon name="edit"/>} key="edit" onClick={() => props.onEditCourseGroupTitle && props.onEditCourseGroupTitle(props.courseGroup.id, props.courseGroup.title)} data-test-id="timun-courseGroupItem__editButton"/>,
          <DeleteElementButton<ICourseGroup> icon={<LemonIcon name="delete"/>} key="delete" item={props.courseGroup} onDelete={props.onDeleteCourseGroup} data-test-id="timun-courseGroupItem__deleteButton"/>,
        ] : []
      }
    >
      <Card.Meta
        title={
          <React.Fragment>
            {props.linkTo != null ? (
              <Link to={props.linkTo} className="timun-itemGroup__groupLink" data-test-id={`timun-courseListFilter__courseGroupItem_link_${props.linkTo}`}>
                {groupDisplayName}
              </Link>
            ) : (
              groupDisplayName
            )}
          </React.Fragment>
        }
      />
    </Card>
  );
}

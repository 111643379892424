import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import ExternalEducationTemplateListBusinessStore, { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// -- Prop types
// ----------

interface IExternalEducationTemplatePickerOwnProps {
  value?: IExternalEducationTemplate;
  onChange?: (data?: IExternalEducationTemplate) => void;
  filter?: IExternalEducationTemplateListFilter;
}

interface IExternalEducationTemplatePickerStateProps {
  externalEducationTemplateList: ICollectionData<IExternalEducationTemplate>;
}

interface IExternalEducationTemplatePickerDispatchProps {
  fetchExternalEducationTemplateList: (params: ICollectionFetchPayload<IExternalEducationTemplateListFilter>) => ITrackableAction;
}
type IExternalEducationTemplatePickerProps = IExternalEducationTemplatePickerOwnProps & IExternalEducationTemplatePickerStateProps & IWithLocalizeOwnProps & IExternalEducationTemplatePickerDispatchProps;

interface IEducationPickerState { }

// -- Component
// ---------- keeping this temporary

class ExternalEducationTemplatePicker extends React.Component<IExternalEducationTemplatePickerProps, IEducationPickerState> {
  componentDidMount() {
    this.updateList(this.props.filter);
  }

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.props.externalEducationTemplateList?.content.map(this.mapToItem);
    return <DataPicker allowClear={true} value={pickerValue} items={pickerItems} disabled={false} onChange={this.handleDataPickerChange} placeholderSuffix={this.props.translate('EXTERNAL_EDUCATION_TEMPLATE.PICKER.SEARCH_PLACEHOLDER')} dataTestIdPrefix="timun-educationPicker" />;
  };

  handleDataPickerChange = (value?: IDataPickerItem) => this.props.onChange?.(value?.data);

  private mapToItem = (education: IExternalEducationTemplate): IDataPickerItem => {
    return {
      value: education.id,
      name: education.title,
      data: education,
      description: `${education.educationProvider.company.name}, ${education.duration}h`,
    };
  };

  private updateList = (filter: IExternalEducationTemplateListFilter = {}) => {
    this.props.fetchExternalEducationTemplateList({
      filter,
      page: 0,
      size: AppConfigService.getValue('api.collectionDefaultLimit'),
      sort: [],
    });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationTemplatePickerOwnProps): IExternalEducationTemplatePickerStateProps => ({
  externalEducationTemplateList: ExternalEducationTemplateListBusinessStore.selectors.getExternalEducationTemplateList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IExternalEducationTemplatePickerDispatchProps => ({
  fetchExternalEducationTemplateList: (params: ICollectionFetchPayload<IExternalEducationTemplateListFilter>) => createTrackableAction(dispatch(ExternalEducationTemplateListBusinessStore.actions.fetchExternalEducationTemplateList(params))),
});

export default connect<IExternalEducationTemplatePickerStateProps, IExternalEducationTemplatePickerDispatchProps, IExternalEducationTemplatePickerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ExternalEducationTemplatePicker as any));

import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import PrivateCourseListView from '@src/components/course/list/catalog/PrivateCourseListView';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

const VIEW_NAME = '@@PRIVATE_COURSE_LIST';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.paging.defaultPageSize');
const DEFAULT_SORT_VALUE = ['title,asc'];

// -- Prop types
// ----------

export interface IPrivateCourseListContainerOwnProps {
  viewName?: string;
  predefinedListFilter?: ICourseListFilter;
}
export interface IPrivateCourseListContainerStateProps {
  courseGroups: ICourseGroup[];
  courseList: ICollectionData<ICourse>;
}
export interface IPrivateCourseListContainerDispatchProps {
  fetchCourseList: (params: ICollectionFetchPayload<ICourseListFilter>) => any;
  clearCourseList: () => void;
}
type IPrivateCourseListContainerProps = IPrivateCourseListContainerOwnProps & IPrivateCourseListContainerStateProps & IPrivateCourseListContainerDispatchProps;

// -- Component
// ----------

/** Describe your component ... */
const PrivateCourseListContainer = (props: IPrivateCourseListContainerProps) => {
  const initialCollectionValues = useMemo(
    () => ({
      sort: DEFAULT_SORT_VALUE,
      size: DEFAULT_PAGE_SIZE_VALUE,
      filter: { ...props.predefinedListFilter },
    }),
    [DEFAULT_SORT_VALUE, DEFAULT_PAGE_SIZE_VALUE, props.predefinedListFilter]
  );

  const [collectionParams, updateCollectionParams] = useCollectionState<ICourseListFilter>({
    viewName: props.viewName ?? VIEW_NAME,
    updateFn: props.fetchCourseList,
    initialValues: initialCollectionValues,
  });

  const handleFilterChange = (filter: ICourseListFilter) => {
    updateCollectionParams.onFilterSet({ ...filter, ...props.predefinedListFilter });
  };

  useEffect(() => {
    return () => {
      props.clearCourseList();
    };
  }, []);

  return (
    <PrivateCourseListView
      courseList={props.courseList}
      courseGroups={props.courseGroups}
      courseListFilter={collectionParams.filter}
      sortValue={collectionParams.sort}
      getPath={CourseHelperUtils.getTraineePath}
      onSortChange={updateCollectionParams.onSortChange}
      onFilterChange={handleFilterChange}
      onPageChange={updateCollectionParams.onPageChange}
    />
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IPrivateCourseListContainerOwnProps): IPrivateCourseListContainerStateProps => ({
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
  courseList: CourseListBusinessStore.selectors.getCourseList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IPrivateCourseListContainerDispatchProps => ({
  fetchCourseList: (params: ICollectionFetchPayload<ICourseListFilter>) => dispatch(CourseListBusinessStore.actions.fetchCourseList(params)),
  clearCourseList: () => dispatch(CourseListBusinessStore.actions.clearCourseList()),
});

export default connect<IPrivateCourseListContainerStateProps, IPrivateCourseListContainerDispatchProps, IPrivateCourseListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(PrivateCourseListContainer as any);

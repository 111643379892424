import AppConfigService from '@src/service/common/AppConfigService';
import { CookieManager } from '@src/service/util/CookieManager';
import { DomainUtils } from '@src/service/util/DomainUtils';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

/** Exposes static methods for authentication token management. */
export default class AuthTokenManager {
  /** Save authentication token. */
  static saveToken(token: string) {
    const webappDomainWithoutSubdomains = DomainUtils.extractDomainWithoutSubs();

    // NOTE: auth cookie has to be removed with same params it was added on login
    CookieManager.setCookie({
      // tslint:disable-next-line: no-duplicate-string
      name: AppConfigService.getValue('authentication.token.name'),
      value: token,
      expires: AppConfigService.getValue('Config.authentication.token.duration') ? new Date(Date.now() + AppConfigService.getValue('authentication.token.duration')) : undefined,
      // we use these cookies ONLY for fetching browser controlled files (eg. images) where we cannot control request and have no way of injecting auth token
      path: UrlBuilderFactory.createApiFileBuilder().build(),
      domain: webappDomainWithoutSubdomains,
    });
  }

  /** Delete authentication token (eg. on logout). */
  static deleteToken() {
    const webappDomainWithoutSubdomains = DomainUtils.extractDomainWithoutSubs();

    // delete ALL auth token cookies (regardless of the path!) - if we leave auth cookie on any path it will be sent to API and user will be logged out
    CookieManager.deleteCookie({
      name: AppConfigService.getValue('authentication.token.name'),
      domain: webappDomainWithoutSubdomains,
    });
  }

  /** Returns authentication token. */
  static getToken(): string {
    return CookieManager.getCookie(AppConfigService.getValue('authentication.token.name'));
  }
}

import React, { useMemo } from 'react';

import ContactDataPropertyInput from '@src/components/codebook/form/common/ContactDataPropertyInput';
import CityDataPicker from '@src/components/common/datapicker/externalEducationTemplate/CityDataPicker';
import { validateOib } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TotalEmploymentDurationInputField from '@src/components/user/form/TotalEmploymentDurationInputField';
import { ResidenceTypeEnum } from '@src/model/user/ResidenceAddress';
import { GenderEnum, IUserInfo } from '@src/model/user/User';
import AppConfigService from '@src/service/common/AppConfigService';
import { Checkbox, Col, DatePicker, Form, FormInstance, Input, Row, Select, Typography } from 'antd';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { UserRoleEnum } from '@src/model/user/UserRole';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';

// -- Const
// ----------
const Option = Select.Option;
const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');
// -- Prop types
// ----------

const LAYOUT = {
  xs: 24,
  md: 12,
};

export interface IUserFormInputFieldsOwnProps {
  formRef: FormInstance<IUserInfo>;
  hidden?: boolean;
}
type IUserFormInputFieldsProps = IUserFormInputFieldsOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const UserFormInputFields: React.FC<IUserFormInputFieldsProps> = (props: IUserFormInputFieldsProps) => {
  const personalInputDisabled = useMemo(() => props.allowedRoles([UserRoleEnum.SUPERADMIN, UserRoleEnum.ORGANIZATION_ADMIN]) || props.isTenantPropEnabled('user_identification_details'), [props.allowedRoles, props.isTenantPropEnabled]);
  return (
    <React.Fragment>
      {/* ---------- About me ---------- */}
      <div className="panel">
        <Typography.Title className="timun-userModalForm__sectionTitle" level={4}>
          {props.translate('USER_FORM.ABOUT_ME_PANEL_TITLE')}
        </Typography.Title>
        <Row gutter={8} justify="start">
          <Col {...LAYOUT}>
            {/* -- First name -- */}
            <Form.Item label={props.translate('USER_FORM.FIRST_NAME_LABEL')} name={'firstName'} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
              <Input disabled={!personalInputDisabled} />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- Last name -- */}
            <Form.Item label={props.translate('USER_FORM.LAST_NAME_LABEL')} name={'lastName'} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
              <Input disabled={!personalInputDisabled} />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- Identification Number - OIB -- */}
            <Form.Item label={props.translate('USER_FORM.OIB_LABEL')} name={'identificationNumber'} rules={[{ validator: validateOib }]}>
              <Input disabled={!personalInputDisabled} />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- Unique Master Citizen Number Number - MBG -- */}
            <Form.Item label={props.translate('USER_FORM.MBG_LABEL')} name={['extendedData', 'uniqueMasterCitizenNumber']}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- Citizenship -- */}
            <Form.Item label={props.translate('USER_FORM.CITIZENSHIP_LABEL')} name={['extendedData', 'citizenship']}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- Date Of Birth -- */}
            <Form.Item label={props.translate('USER_FORM.DATE_OF_BIRTH_LABEL')} name={'dateOfBirth'}>
              <DatePicker placeholder={props.translate('USER_FORM.DATE_OF_BIRTH_PLACEHOLDER')} allowClear={false} format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- Gender -- */}
            <Form.Item label={props.translate('USER_FORM.GENDER_LABEL')} name={'gender'}>
              <Select>
                {Object.keys(GenderEnum).map((item: any) => (
                  <Option value={GenderEnum[item as keyof typeof GenderEnum]} key={item}>
                    {props.translate(`USER_FORM.GENDER_LABEL_${item}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
      {/* ---------- Residence ---------- */}
      <div className="panel">
        <Form.Item name={['residenceAddress', 'address', 'id']} noStyle={true} hidden={true}>
          <Input />
        </Form.Item>
        <Typography.Title className="timun-userModalForm__sectionTitle" level={4}>
          {props.translate('USER_FORM.RESIDENCE_PANEL_TITLE')}
        </Typography.Title>
        <Row gutter={8} justify="start">
          <Col {...LAYOUT}>
            {/* -- Street With Number -- */}
            <Form.Item label={props.translate('USER_FORM.STREET_WITH_NUMBER_LABEL')} name={['residenceAddress', 'address', 'streetWithHomeNumber']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- City -- */}
            <Form.Item label={props.translate('USER_FORM.CITY_LABEL')} name={['residenceAddress', 'address', 'city']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
              <CityDataPicker />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- ResidenceAddress Type -- */}
            <Form.Item label={props.translate('USER_FORM.RESIDENCE_TYPE_LABEL')} name={['residenceAddress', 'type', 'id']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
              <Select>
                {Object.keys(ResidenceTypeEnum).map((item: any) => (
                  <Option value={ResidenceTypeEnum[item as keyof typeof ResidenceTypeEnum]} key={item}>
                    {props.translate(`USER_FORM.RESIDENCE_TYPE_${item}_LABEL`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            <Form.Item label={props.translate('USER_FORM.ADDRESS_ZIP_LABEL')} name={['residenceAddress', 'address', 'zip']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
              <Input min={AppConfigService.getValue('components.userForm.minZipValue')} max={AppConfigService.getValue('components.userForm.maxZipValue')} />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            <Form.Item label={props.translate('USER_FORM.ADDRESS_COUNTRY_LABEL')} name={['residenceAddress', 'address', 'country']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </div>
      {/* ---------- Contacts ---------- */}
      <div className="panel">
        <Typography.Title className="timun-userModalForm__sectionTitle" level={4}>
          {props.translate('USER_FORM.CONTACT_PANEL_TITLE')}
        </Typography.Title>
        <ContactDataPropertyInput formRef={props.formRef} required={true} />
      </div>
      {/* ---------- Qualifications ---------- */}
      <div className="panel">
        <Typography.Title className="timun-userModalForm__sectionTitle" level={4}>
          {props.translate('USER_FORM.ACQUIRED_QUALIFICATION_PANEL_TITLE')}
        </Typography.Title>
        <Row gutter={8} justify="start">
          <Col {...LAYOUT}>
            {/* -- Educational Program -- */}
            <Form.Item label={props.translate('USER_FORM.EDUCATION_PROGRAM_LABEL')} name={['educationData', 'educationalProgram']}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- Educational Institution -- */}
            <Form.Item label={props.translate('USER_FORM.EDUCATION_INSTITUTION_LABEL')} name={['educationData', 'educationalInstitution']}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...LAYOUT}>
            {/* -- International Qualifications Accepted -- */}
            <Form.Item label={props.translate('USER_FORM.INTERNATIONAL_QUALIFICATIONS_ACCEPTED_LABEL')} name={['extendedData', 'internationalQualificationsAccepted']} valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        {/* -- Total Employment Duration -- */}
        <Form.Item label={props.translate('USER_FORM.TOTAL_EMPLOYMENT_DURATION_LABEL')} name={['workData', 'totalEmploymentDuration']}>
          <TotalEmploymentDurationInputField />
        </Form.Item>
      </div>
    </React.Fragment>
  );
};

export default withLocalize<IUserFormInputFieldsOwnProps>(withRoles(withTenantPropEnabled(UserFormInputFields as any)));

import React, { useEffect, useState } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { Layout, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Link } from 'react-router';

// -- Const
// ----------
enum ExamTemplateRouteEnum {
  STATISTICS = 'statistics',
  EXAM_INSTANCE_LIST = 'examinstancelist',
  QUIZ_CREATOR = 'quizcreator',
  QUIZ_DISPLAY = 'quizdisplay',
  SETTINGS = 'settings',
}

enum ExamTemplateSubMenuEnum {
  STATISTICS = 'STATISTICS',
  EXAM_TEMPLATE = 'EXAM_TEMPLATE',
}

const { Content } = Layout;

// -- Prop types
// ----------

export interface IExamTemplateSiderOwnProps {
  courseId: string;
  examTemplateId: string;
  currentPage: string;
  showExamTemplateStatistics?: boolean;
}
type IExamTemplateSiderProps = IExamTemplateSiderOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExamTemplateSider: React.FC<IExamTemplateSiderProps> = (props: IExamTemplateSiderProps) => {
  const getExamTemplateLink = (idPath: string): string => {
    return UrlBuilderFactory.buildAppUrl(`/course/${props.courseId}/examtemplate/${props.examTemplateId}/${idPath}`);
  };
  useEffect(() => {
    setSelectedKeys([props.currentPage]);
  }, [props.currentPage, props.courseId]);

  const [selectedKeys, setSelectedKeys] = useState<string[]>();
  return (
    <Layout>
      <Content>
        <Menu
          selectedKeys={selectedKeys}
          mode="inline"
          defaultOpenKeys={[ExamTemplateSubMenuEnum.EXAM_TEMPLATE, ExamTemplateSubMenuEnum.STATISTICS]}
          className="timun-courseView__siderMenu">
          <SubMenu key={ExamTemplateSubMenuEnum.EXAM_TEMPLATE} title={props.translate('EXAM_TEMPLATE.SIDER.SUBMENU_EXAM_TEMPLATE_EDIT_LABEL')}>
            <Menu.Item key={ExamTemplateRouteEnum.QUIZ_CREATOR}>
              <Link to={getExamTemplateLink(ExamTemplateRouteEnum.QUIZ_CREATOR)}>
                {props.translate('EXAM_TEMPLATE.SIDER.MENU_QUIZ_CREATOR_LABEL')}
              </Link>
            </Menu.Item>
            <Menu.Item key={ExamTemplateRouteEnum.SETTINGS}>
              <Link to={getExamTemplateLink(ExamTemplateRouteEnum.SETTINGS)}>
                {props.translate('EXAM_TEMPLATE.SIDER.MENU_EXAM_TEMPLATE_SETTINGS_LABEL')}
              </Link>
            </Menu.Item>
            <Menu.Item key={ExamTemplateRouteEnum.QUIZ_DISPLAY}>
              <Link to={getExamTemplateLink(ExamTemplateRouteEnum.QUIZ_DISPLAY)}>
                {props.translate('EXAM_TEMPLATE.SIDER.MENU_QUIZ_DISPLAY_LABEL')}
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key={ExamTemplateSubMenuEnum.STATISTICS} title={props.translate('EXAM_TEMPLATE.SIDER.SUBMENU_STATISTICS_LABEL')}>
            <Menu.Item key={ExamTemplateRouteEnum.STATISTICS} disabled={!props.showExamTemplateStatistics}>
              <Link to={getExamTemplateLink(ExamTemplateRouteEnum.STATISTICS)}>
                {props.translate('EXAM_TEMPLATE.SIDER.MENU_EXAM_STATISTIC_LABEL')}
              </Link>
            </Menu.Item>
            <Menu.Item key={ExamTemplateRouteEnum.EXAM_INSTANCE_LIST} disabled={!props.showExamTemplateStatistics}>
              <Link to={getExamTemplateLink(ExamTemplateRouteEnum.EXAM_INSTANCE_LIST)}>
                {props.translate('EXAM_TEMPLATE.SIDER.MENU_TRAINEE_RESULTS_LABEL')}
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu >
      </Content >
    </Layout >
  );
};

export default withLocalize<IExamTemplateSiderOwnProps>(ExamTemplateSider as any);

import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { CourseCompletionStatusEnum, ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import AppConfigService from '@src/service/common/AppConfigService';

import { Col, ColProps, Row, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router';

const COURSE_GROUP_MISSING_ID = '-1';

const COURSE_QUIZ_ROUTE = AppConfigService.getValue('components.courses.courseQuizRouterProp');

interface ICoursesByCourseGroupMap {
  [groupId: string]: ICourse[];
}

// -- Prop types
// ----------

export interface ICourseListOwnProps {
  /** List of course groups. */
  courseGroups: ICourseGroup[];

  /** List of courses. */
  courses: ICourse[];

  /**
   * Width of course column. Number is used as a grid column width.
   * All additional columns are summed together with course column width should be equal to 24 (Ant grid col number).
   */
  courseColWidth?: ColProps;

  /** Render function which returns list of {@link ICourseListAdditionalCol} describing additional groups. */
  // renderAdditionalCols?: (course: ICourse) => ICourseListAdditionalCol[];

  renderAdditionalCols?: () => IListAdditionalCol[];

  /** Show courses without or with missing group. They are shown by default. */
  showUngrouped?: boolean;

  getPath: (id: string, lecture: string) => any;
  returnRoute?: string;
}

type ICourseListProps = ICourseListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Show list of courses grouped by course groups. Additional (custom) columns can be added per each course using render function. */
const CourseList: React.FC<ICourseListProps> = (props: ICourseListProps) => {
  const [coursesMap, setCoursesMap] = useState<ICoursesByCourseGroupMap>({});

  useEffect(() => {
    setCoursesMap(coursesByCourseGroup());
  }, [props.courses]);

  /** Return list of course groups found in reference list. */
  const getCourseGroupsById = useCallback(
    (refList: IIdRef<string>[]): ICourseGroup[] => {
      return props.courseGroups.filter((courseGroup) => refList.find((ref) => ref.id === courseGroup.id) != null);
    },
    [props.courseGroups]
  );

  /** Return course group by ID. */
  const getCourseGroupById = useCallback(
    (id: string): ICourseGroup | undefined => {
      return props.courseGroups.find((courseGroup) => courseGroup.id === id);
    },
    [props.courseGroups]
  );

  const showUngrouped = useCallback((): boolean => {
    return props.showUngrouped == null || props.showUngrouped;
  }, [props.showUngrouped]);

  const coursesByCourseGroup = useCallback((): ICoursesByCourseGroupMap => {
    return props.courses.reduce((accum, course) => {
      // get course's groups
      const courseGroups = getCourseGroupsById(course.courseGroups);

      let refList: IIdRef<string>[];
      // if course group is somehow missing from list or course's group ID is invalid, assign course to "missing list"
      if (courseGroups.length === 0 && showUngrouped()) {
        refList = [{ id: COURSE_GROUP_MISSING_ID }];
      } else {
        refList = courseGroups.map((courseGroup) => ({ id: courseGroup.id }));
      }

      // add course to each of it's groups
      refList.forEach((ref) => {
        if (accum[ref.id] == null) {
          accum[ref.id] = [];
        }

        accum[ref.id].push(course);
      });

      return accum;
    }, {} as ICoursesByCourseGroupMap);
  }, [props.courses, getCourseGroupsById, showUngrouped]);

  const additionalCols = useMemo(() => (props.renderAdditionalCols ? props.renderAdditionalCols() : []), [props.renderAdditionalCols]);
  return (
    <div className="timun-courseList__container">
      {Object.keys(coursesMap).map((courseGroupId: string) => {
        const courseGroup = getCourseGroupById(courseGroupId);
        const courses = coursesMap[courseGroupId];

        const groupDisplayName = courseGroup ? courseGroup.title : props.translate('COURSE_LIST.UNGROUPED_TITLE');

        return (
          <div key={courseGroupId} className="timun-gridTable__table">
            <Typography.Title level={2}>{groupDisplayName}</Typography.Title>
            <Row className="timun-gridTable__headerRow">
              <Col {...props.courseColWidth} className="timun-gridTable__headerCell">
                {props.translate('COURSE_LIST.VIEW_TITLE')}
              </Col>
              {additionalCols.map((col) => {
                return (
                  <Col key={col.key} {...col.responsiveWidth}>
                    {col.headerTitle}
                  </Col>
                );
              })}
            </Row>
            {courses.map((course) => {
              let currentLecture;
              // TODO: combine this check with the one in EducationCardList into a utility method
              if (course.progress && course.progress.completedLessonsNumber !== 0 && course.progress.completedLessonsNumber !== course.progress.lessonsNumber) {
                currentLecture = course.progress.currentLecture.id;
              } else if (course.progress && course.progress.completionStatus.id === CourseCompletionStatusEnum.IN_PROGRESS && course.progress.completedLessonsNumber === course.progress.lessonsNumber) {
                currentLecture = COURSE_QUIZ_ROUTE;
              }

              return (
                <Row key={course.id} className="timun-gridTable__itemRow">
                  <Col {...props.courseColWidth} className="timun-gridTable__itemCell">
                    <Link to={{ pathname: props.getPath(course.id, currentLecture), state: { returnRoute: props.returnRoute } }} data-test-id={`timun-courseList__link_lecture_${currentLecture}`}>
                      {course.title}
                    </Link>
                  </Col>
                  {additionalCols.map((col) => {
                    return (
                      <Col key={`${col.key}-${course.id}`} {...col.responsiveWidth} className="timun-gridTable__itemCell">
                        {col.content(course)}
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default withLocalize<ICourseListOwnProps>(CourseList as any);

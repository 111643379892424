import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import { IUserGroupMember } from '@src/model/usergroup/UserGroupMember';
import { IUserGroupMemberRole, UserGroupMemberRoleEnum } from '@src/model/usergroup/UserGroupMemberRole';

export default class UserGroupHelperUtils {
  static allowedRoles = (requestedRoles: (keyof typeof UserGroupMemberRoleEnum)[], userGroupMemberRole?: IUserGroupMemberRole): boolean => {
    const match = UserGroupHelperUtils.matchingRoles(requestedRoles, userGroupMemberRole);
    return match.length > 0;
  };

  /** Returns list of requested user group role enums. */
  static matchingRoles(requestedRoles: (keyof typeof UserGroupMemberRoleEnum)[], userGroupMemberRole?: IUserGroupMemberRole): (keyof typeof UserGroupMemberRoleEnum)[] {
    if (!userGroupMemberRole) {
      return [];
    }
    return requestedRoles.filter((role) => userGroupMemberRole?.id === UserGroupMemberRoleEnum[role]);
  }

  static getGroupAdmins = (group: IUserGroupHierarchy): IUserGroupMember[] => {
    return group.members.filter((member) => member.role.id === UserGroupMemberRoleEnum.ADMIN);
  };

  static getGroupMembers = (group: IUserGroupHierarchy): IUserGroupMember[] => {
    return group.members.filter((member) => member.role.id === UserGroupMemberRoleEnum.MEMBER);
  };
}

import AppContent from '@src/components/common/container/AppContent';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AllowedUserRoles from '@src/components/common/role/AllowedUserRoles';
import ScormUploadForm from '@src/components/common/upload/ScormUploadForm';
import CourseListView from '@src/components/course/list/CourseListView';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData } from '@src/service/business/common/types';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------

interface IPrivateCourseListViewOwnProps {
  courseList: ICollectionData<ICourse>;
  courseListFilter?: ICourseListFilter;
  courseGroups: ICourseGroup[];
  sortValue?: string[];
  isCourseStatusFilterEnabled?: boolean;
  showCourseListActions?: boolean;

  getPath: (course: ICourse, lecture?: string) => string;
  onPageChange: (page: number, size?: number) => void;
  onSortChange: (sort: string[]) => void;
  onFilterChange: (data: ICourseListFilter) => void;
}

type IPrivateCourseListViewProps = IPrivateCourseListViewOwnProps & IWithLocalizeOwnProps & WithRouterProps & IWithTenantPropEnabledOwnProps;
// -- Component
// ----------

const PrivateCourseListView = (props: IPrivateCourseListViewProps) => {
  const [courseModalsState, toggleCourseModals] = useEntityModalsState<ICourse>();

  const handleCreateCourseButtonClick = () => {
    // redirect to course creation view
    props.router.push('/course/administration/create');
  };

  const handleSCORMSubmit = (course: ICourse) => {
    // TODO: handle modal visible state update after unmount
    toggleCourseModals.onCloseCreate();
    // redirect to new course
    props.router.push(`/scorm/${course.id}`);
  };

  const handleEditCourseGroups = () => {
    // redirect to course group list view
    props.router.push('course/administration/groups');
  };
  return (
    <AppContent
      title={
        <Row justify="space-between">
          {/* ----- Course list actions ----- */}
          {props.showCourseListActions && (
            <Col>
              <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN]} fallback="">
                <Button onClick={handleEditCourseGroups} data-test-id="timun-courseListView__editGroupsButton">
                  {props.translate('COURSE_LIST.ACTION_GROUPS_EDIT')}
                </Button>
                &nbsp;
                <Button type="primary" icon={<LemonIcon name="plus" />} onClick={handleCreateCourseButtonClick} data-test-id="timun-courseListView__createGroupButton">
                  {props.translate('COURSE_LIST.ACTION_CREATE')}
                </Button>
                {props.isTenantPropEnabled('scormCourses') && (
                  <React.Fragment>
                    &nbsp;
                    <Button icon={<LemonIcon name="plus" />} type="primary" onClick={toggleCourseModals.onOpenCreate} data-test-id="timun-courseListView__createSCORMButton">
                      {props.translate('COURSE_LIST.ACTION_CREATE_SCORM')}
                    </Button>
                  </React.Fragment>
                )}
              </AllowedUserRoles>
            </Col>
          )}
        </Row>
      }
    >
      {/* ----- Course List ----- */}
      <CourseListView
        courseList={props.courseList}
        courseListFilter={props.courseListFilter}
        showStatusFilter={props.isCourseStatusFilterEnabled}
        courseGroups={props.courseGroups}
        sortValue={props.sortValue}
        onSortChange={props.onSortChange}
        onPageChange={props.onPageChange}
        onFilterChange={props.onFilterChange}
        getPath={props.getPath}
      />

      {courseModalsState.isCreateModalVisible && <ScormUploadForm submitAfterUpload={true} onSubmit={handleSCORMSubmit} onClose={toggleCourseModals.onCloseCreate} />}
    </AppContent>
  );
}; // -- HOCs and exports
// ----------

export default withLocalize<IPrivateCourseListViewOwnProps>(withTenantPropEnabled(withRouter(PrivateCourseListView) as any));

import SkillLevelClassificationPicker from '@src/components/common/datapicker/SkillLevelClassificationPicker';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DialogPanel from '@src/components/common/panel/DialogPanel';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { ISkillLevelClassification } from '@src/model/skillgroup/SkillLevelClassification';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import React from 'react';

// -- Const
// ----------

// -- Prop types
// ----------
export interface IWorkPositionUpdateFormOwnProps {
  workPosition?: IWorkPosition;
  formRef?: FormInstance<IWorkPosition>;
  onFormFinish?: (values: IWorkPosition) => void;
}

type IWorkPositionUpdateFormProps = IWorkPositionUpdateFormOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------

/** Display contact data input fields */
const WorkPositionUpdateForm: React.FC<IWorkPositionUpdateFormProps> = (props: IWorkPositionUpdateFormProps) => {
  const [form] = Form.useForm<IWorkPosition>(props.formRef);

  const onFinish = (values: IWorkPosition) => {
    props.onFormFinish?.({ ...props.workPosition, ...values });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  return (
    <Form<IWorkPosition> form={form} name="workPosition" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={props.workPosition} layout="vertical">
      <Form.Item noStyle={true} hidden={true} name={['id']} fieldKey={['id']}>
        <Input />
      </Form.Item>
      <Form.Item name={['name']} fieldKey={['name']} label={props.translate('ADMINISTRATION.WORKPOSITION_UPDATE_NAME_LABEL')} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <Input />
      </Form.Item>
      {props.isTenantPropEnabled(['admin_skills', 'skillsgap']) && (
        <DialogPanel title={props.translate('ADMINISTRATION.WORKPOSITION_UPDATE_SKILLS_PANEL_LABEL')}>
          <Form.List name={'skillLevelClassifications'}>
            {(fields, { add, remove, move }, { errors }) => {
              /* TODO: remove element unmounts/remounts all fields after the removed one, needs more debuging */
              return (
                <React.Fragment>
                  {fields.map((field) => {
                    return (
                      <Row gutter={12} justify="space-between" key={field.key}>
                        <Col xs={20} lg={22}>
                          <Form.Item
                            name={[field.name]}
                            key={field.key}
                            fieldKey={[field.key]}
                            rules={[
                              {
                                required: true,
                                validator: async (_, values: ISkillLevelClassification) => {
                                  if (!values || values.skill == null || values.skillLevel == null) {
                                    return Promise.reject(new Error(props.translate('COMMON.FILL_REQUIRED_FIELD')));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <SkillLevelClassificationPicker />
                          </Form.Item>
                        </Col>

                        <Col xs={4} lg={2}>
                          <Button onClick={() => remove(field.name)} icon={<LemonIcon name="times" />} />
                        </Col>
                      </Row>
                    );
                  })}
                  <Row justify="center">
                    <Col>
                      <Button onClick={() => add()}> {props.translate('ADMINISTRATION.WORKPOSITION_UPDATE_ADD_SKILL_BUTTON_LABEL')} </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              );
            }}
          </Form.List>
        </DialogPanel>
      )}
    </Form>
  );
};

export default withLocalize<IWorkPositionUpdateFormOwnProps>(withTenantPropEnabled(WorkPositionUpdateForm as any));

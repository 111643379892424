import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import WebinarGrid from '@src/components/webinar/list/WebinarGrid';
import { IWebinar } from '@src/model/webinar/Webinar';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import WebinarListBusinessStore, { IWebinarsFilter } from '@src/service/business/webinars/webinarListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React from 'react';
import { connect } from 'react-redux';

const SIZE = AppConfigService.getValue('api.paging.midPageSize');
const DEFAULT_SORT_VALUE = 'title,asc';

// -- Prop types
// ----------

export interface IRepositoryWebinarsContainerOwnProps {
  currentTags: string[];
}

export interface IRepositoryWebinarsContainerStateProps {
  webinarList: ICollectionData<IWebinar>;
  webinarListFilter: IWebinarsFilter;
}

export interface IRepositoryWebinarsContainerDispatchProps {
  fetchWebinarList: (params: ICollectionFetchPayload<IWebinarsFilter>) => any;
  clearWebinarList: () => void;
  storeWebinarFilter: (filter: IWebinarsFilter) => void;
  clearWebinarFilter: () => void;
}
type IRepositoryWebinarsContainerProps = IRepositoryWebinarsContainerOwnProps & IRepositoryWebinarsContainerStateProps & IRepositoryWebinarsContainerDispatchProps & IWithLocalizeOwnProps;

interface IRepositoryWebinarsContainerState {
  size: number;
  page: number;
  sort: string;
}
// -- Component
// ----------

/** Repository webinars container component */
class RepositoryWebinarsContainer extends React.Component<IRepositoryWebinarsContainerProps, IRepositoryWebinarsContainerState> {
  state: IRepositoryWebinarsContainerState = {
    size: SIZE,
    page: 0,
    sort: DEFAULT_SORT_VALUE,
  };

  componentDidMount() {
    this.props.storeWebinarFilter({ tags: this.props.currentTags });
  }

  componentDidUpdate(prevProps: IRepositoryWebinarsContainerProps, prevState: IRepositoryWebinarsContainerState) {
    if (this.props.currentTags !== prevProps.currentTags) {
      this.props.storeWebinarFilter({ ...this.props.webinarListFilter, tags: this.props.currentTags });
    }
    if ((this.props.webinarListFilter !== prevProps.webinarListFilter) || this.state.sort !== prevState.sort || this.state.size !== prevState.size || this.state.page !== prevState.page) {
      this.fetchAllWebinarList();
    }
  }

  componentWillUnmount() {
    this.props.clearWebinarList();
    this.props.clearWebinarFilter();
  }

  render = () => {
    return <React.Fragment>
      <div className="timun-repository__pagination">
        {this.props.webinarList && <ListPagination page={this.props.webinarList.page} onChange={this.handlePageChange} />}
      </div>
      <WebinarGrid webinars={this.props.webinarList ? this.props.webinarList.content : []} />
      {this.props.webinarList && <ListPagination page={this.props.webinarList.page} onChange={this.handlePageChange} />}
    </React.Fragment>;
  }

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  private fetchAllWebinarList = () => {
    this.props.fetchWebinarList({
      filter: this.props.webinarListFilter,
      page: this.state.page,
      size: this.state.size,
      sort: [this.state.sort],
    });
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IRepositoryWebinarsContainerStateProps => ({
  webinarList: WebinarListBusinessStore.selectors.getWebinars(state),
  webinarListFilter: WebinarListBusinessStore.selectors.getWebinarsFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IRepositoryWebinarsContainerDispatchProps => ({
  fetchWebinarList: (params: ICollectionFetchPayload<IWebinarsFilter>) => dispatch(WebinarListBusinessStore.actions.fetchWebinars({ ...params })),
  clearWebinarList: () => dispatch(WebinarListBusinessStore.actions.clearWebinars()),
  storeWebinarFilter: (filter: IWebinarsFilter) => dispatch(WebinarListBusinessStore.actions.storeWebinarsFilter(filter)),
  clearWebinarFilter: () => dispatch(WebinarListBusinessStore.actions.clearWebinarsFilter()),
});

export default connect<IRepositoryWebinarsContainerStateProps, IRepositoryWebinarsContainerDispatchProps, IRepositoryWebinarsContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(RepositoryWebinarsContainer as any));

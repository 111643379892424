import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export interface IUserGroupListFilter {
  memberId?: string;
  withoutCurrentUserGroups?: boolean;
  type?: UserGroupTypeEnum;
  name?: string;
}

// List filter ID
const USER_GROUP_LIST_FILTER = '@@USER_GROUP_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns UserGroup list from store. */
const getUserGroupList = (store: any): ICollectionData<IUserGroup> => store.userGroupList;

/** Returns UserGroup list filter. */
const getUserGroupListFilter = (store: any): IUserGroupListFilter => ListFilterBusinessStore.selectors.getListFilter(store, USER_GROUP_LIST_FILTER);

/** Returns UserGroup hierarchy from store. */
const getUserGroupHierarchy = (store: any): IUserGroupHierarchy => store.userGroupHierarchy;

// -
// -------------------- Actions
const Actions = {
  USER_GROUP_LIST_FETCH: 'USER_GROUP_LIST_FETCH',
  USER_GROUP_LIST_PICKER_FETCH: 'USER_GROUP_LIST_PICKER_FETCH',
  USER_GROUP_LIST_LOAD: 'USER_GROUP_LIST_LOAD',
  USER_GROUP_LIST_CLEAR: 'USER_GROUP_LIST_CLEAR',
  USER_GROUP_HIERARCHY_FETCH: 'USER_GROUP_HIERARCHY_FETCH',
  USER_GROUP_HIERARCHY_LOAD: 'USER_GROUP_HIERARCHY_LOAD',
  USER_GROUP_HIERARCHY_CLEAR: 'USER_GROUP_HIERARCHY_CLEAR',
};

/** Fetch UserGroup list by filter */
const fetchUserGroupList = (params: ICollectionFetchPayload<IUserGroupListFilter>): IPayloadAction<ICollectionFetchPayload<IUserGroupListFilter>> => {
  return {
    type: Actions.USER_GROUP_LIST_FETCH,
    payload: params,
  };
};

const fetchUserGroupPickerList = (params: ICollectionFetchPayload<IUserGroupListFilter>): IPayloadAction<ICollectionFetchPayload<IUserGroupListFilter>> => {
  return {
    type: Actions.USER_GROUP_LIST_PICKER_FETCH,
    payload: params,
  };
};

/** Load UserGroup list to the store */
const loadUserGroupList = (data: ICollectionData<IUserGroup>): IPayloadAction<ICollectionData<IUserGroup>> => {
  return {
    type: Actions.USER_GROUP_LIST_LOAD,
    payload: data,
  };
};

/** Clear UserGroup list from store. Eg. when leaving view. */
const clearUserGroupList = (): ILemonAction => {
  return {
    type: Actions.USER_GROUP_LIST_CLEAR,
  };
};

/** Store list filter to store. */
const storeGroupListFilter = (listFilter: IUserGroupListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(USER_GROUP_LIST_FILTER, listFilter);
};

/** Clear list filter from store. */
const clearGroupListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(USER_GROUP_LIST_FILTER);
};

/** Fetch UserGroup hierarchy */
const fetchUserGroupHierarchy = (): ILemonAction => {
  return {
    type: Actions.USER_GROUP_HIERARCHY_FETCH,
  };
};

/** Load UserGroup hierarchy to the store */
const loadUserGroupHierarchy = (data: IUserGroupHierarchy): IPayloadAction<IUserGroupHierarchy> => {
  return {
    type: Actions.USER_GROUP_HIERARCHY_LOAD,
    payload: data,
  };
};

/** Clear UserGroup hierarchy from store. Eg. when leaving view. */
const clearUserGroupHierarchy = (): ILemonAction => {
  return {
    type: Actions.USER_GROUP_HIERARCHY_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchUserGroupListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IUserGroupListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('UserGroup')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserGroupList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching UserGroup list', error);
      return o;
    })
  );
};

const fetchUserGroupPickerListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IUserGroupListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_LIST_PICKER_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      return EntityApiServiceRegistry.getService('UserGroup')
        .fetchEntityList(action.payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching UserGroup picker list', error);
      return o;
    })
  );
};

const fetchUserGroupHierarchyEffect = (action$: Observable<ILemonAction>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_GROUP_HIERARCHY_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      return EntityApiServiceRegistry.getService('UserGroup')
        .fetchMethod('organizationgrouphierarchy')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserGroupHierarchy(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching UserGroup hierarchy', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers
const userGroupList = (state: ICollectionData<IUserGroup> | null = null, action: IPayloadAction<ICollectionData<IUserGroup>>) => {
  if (action.type === Actions.USER_GROUP_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_GROUP_LIST_CLEAR) {
    return null;
  }

  return state;
};

const userGroupHierarchy = (state: IUserGroupHierarchy | null = null, action: IPayloadAction<IUserGroupHierarchy>) => {
  if (action.type === Actions.USER_GROUP_HIERARCHY_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_GROUP_HIERARCHY_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const UserGroupListBusinessStore = {
  actions: {
    fetchUserGroupList,
    fetchUserGroupPickerList,
    loadUserGroupList,
    clearUserGroupList,
    storeGroupListFilter,
    clearGroupListFilter,
    fetchUserGroupHierarchy,
    loadUserGroupHierarchy,
    clearUserGroupHierarchy,
  },

  selectors: {
    getUserGroupList,
    getUserGroupListFilter,
    getUserGroupHierarchy,
  },

  effects: {
    fetchUserGroupListEffect,
    fetchUserGroupHierarchyEffect,
    fetchUserGroupPickerListEffect,
  },

  reducers: {
    userGroupList,
    userGroupHierarchy,
  },
};

// --
// export business store

export default UserGroupListBusinessStore;

import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { INoteListFileCreatePayload } from '@src/service/business/usernotes/userNoteBusinessStore';
import { Button } from 'antd';

// -- Prop types
// ----------
export interface INoteListExportButtonOwnProps {
  onExport: (noteListRefObject: INoteListFileCreatePayload) => void;
  noteListFileId?: string;
  noteListId: string;
  objectTypeId: NoteObjectTypeEnum;
  numberOfNotes: number;
}

// -- State types
// ----------
type NoteListExportButtonProps = INoteListExportButtonOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

export interface INoteListExportButtonState {}

// -- Component
// ----------

/** Component display two state button one for export and one for download */
class NoteListExportButton extends React.Component<NoteListExportButtonProps, INoteListExportButtonState> {
  state: INoteListExportButtonState = {};

  render() {
    const exportButtonDisabled = this.props.numberOfNotes === 0;
    return (
      <React.Fragment>
        {/* Display note list export button */}
        {this.props.noteListFileId ? (
          <Button icon={<LemonIcon name="download" />} href={this.props.resolveApiFilePath(this.props.noteListFileId)}>
            {this.props.translate('COMMON.ACTION_DOWNLOAD')}
          </Button>
        ) : (
          <Button onClick={this.onExport} disabled={exportButtonDisabled}>
            {this.props.translate('NOTE_LIST.EXPORT_BUTTON_LABEL')}
          </Button>
        )}
      </React.Fragment>
    );
  }

  onExport = () => {
    const noteListRefObject: INoteListFileCreatePayload = {
      type: { id: this.props.objectTypeId },
      object: { id: this.props.noteListId },
    };
    this.props.onExport(noteListRefObject);
  };
}
// -- HOCs and exports
// ----------
export default withLocalize<INoteListExportButtonOwnProps>(withPathResolver(NoteListExportButton as any));

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ExamInstanceView from '@src/components/exam/examInstance/ExamInstanceView';
import { ICourse } from '@src/model/course/Course';
import { IExamInstance } from '@src/model/education/ExamInstance';
import courseViewBusinessStore from '@src/service/business/courses/courseViewBusinessStore';
import examInstanceBusinessStore from '@src/service/business/examtemplates/examInstanceBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Modal, Row, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface ICourseExamInstanceContainerOwnProps {
  examInstanceId: string;
  courseId: string;
}

export interface ICourseExamInstanceContainerStateProps {
  examInstance: IExamInstance;
  course: ICourse;
}

export interface ICourseExamInstanceContainerDispatchProps {
  fetchExamInstance: (examInstanceId: string) => ITrackableAction;
  clearExamInstance: () => void;
  submitExamInstance: (examInstance: IExamInstance) => ITrackableAction;

  fetchCourse: (courseId: string) => void;
}

type ICourseExamInstanceContainerProps = ICourseExamInstanceContainerOwnProps & ICourseExamInstanceContainerStateProps & ICourseExamInstanceContainerDispatchProps & IWithRolesOwnProps & IWithLocalizeOwnProps;

interface ICourseExamInstanceContainerState {}

// -- Component
// ----------

/** Describe your component ... */
class CourseExamInstanceContainer extends React.Component<ICourseExamInstanceContainerProps, ICourseExamInstanceContainerState> {
  state: ICourseExamInstanceContainerState = {};

  componentDidMount() {
    // mount
    this.fetchExamInstance(this.props.examInstanceId);
    this.props.fetchCourse(this.props.courseId);
  }

  componentDidUpdate(prevProps: ICourseExamInstanceContainerProps, prevState: ICourseExamInstanceContainerState) {
    if (this.props.examInstanceId !== prevProps.examInstanceId || this.props.courseId !== prevProps.courseId) {
      this.fetchExamInstance(this.props.examInstanceId);
      this.props.fetchCourse(this.props.courseId);
    }
  }

  render() {
    return (
      <AppContent>
        {/* --- Display course exam instance view --- */}
        {this.props.examInstance && <ExamInstanceView examInstance={this.props.examInstance} onSubmit={this.handleExamInstanceSubmit} courseId={this.props.courseId} />}
      </AppContent>
    );
  }

  handleExamInstanceSubmit = (examInstance: IExamInstance) => {
    this.props
      .submitExamInstance(examInstance)
      .track()
      .subscribe(
        // success
        (response: IExamInstance) => {
          if (response.additionalData.examPassed) {
            Modal.success({
              className: 'lemon-modal__modal--md',
              title: LocalizeService.translate('COURSE_SUCCESS_MODAL.SUBMIT_SUCCESSFUL_TITLE'),
              content: (
                <React.Fragment>
                  <Row>
                    <Typography.Text>{LocalizeService.translate('COURSE_SUCCESS_MODAL.SUBMIT_SUCCESSFUL_MESSAGE', { courseName: response.title })}</Typography.Text>{' '}
                  </Row>
                  <Row>
                    <Typography.Text type="secondary">{LocalizeService.translate('COURSE_SUCCESS_MODAL.SUBMIT_SUCCESSFUL_DESCRIPTION')}</Typography.Text>
                  </Row>
                </React.Fragment>
              ),
            });
          }
          this.fetchExamInstance(this.props.examInstanceId);
        }
      );
  };

  fetchExamInstance = (examInstanceId: string) => {
    this.props.fetchExamInstance(examInstanceId);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseExamInstanceContainerOwnProps): ICourseExamInstanceContainerStateProps => ({
  examInstance: examInstanceBusinessStore.selectors.getExamInstance(state),
  course: courseViewBusinessStore.selectors.getCourseContent(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICourseExamInstanceContainerDispatchProps => ({
  fetchExamInstance: (examInstanceId: string) => createTrackableAction(dispatch(examInstanceBusinessStore.actions.fetchExamInstance(examInstanceId))),
  clearExamInstance: () => dispatch(examInstanceBusinessStore.actions.clearExamInstance()),
  submitExamInstance: (examInstance: IExamInstance) => createTrackableAction(dispatch(examInstanceBusinessStore.actions.submitExamInstance(examInstance))),

  fetchCourse: (courseId: string) => dispatch(courseViewBusinessStore.actions.fetchCourseContent({ id: courseId })),
});

export default connect<ICourseExamInstanceContainerStateProps, ICourseExamInstanceContainerDispatchProps, ICourseExamInstanceContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRoles(withLocalize(CourseExamInstanceContainer as any)));

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import { IWithCollectionStateOwnProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SkillBasedRecommendationListView from '@src/components/skillbasedrecommendations/view/SkillBasedRecommendationListView';
import { IChartDataItem } from '@src/components/user/statistics/TimunRadarChart';
import { ISkillBasedRecommendation } from '@src/model/skillbasedrecommendation/SkillBasedRecommendation';
import { IUserSkillEvaluation, UserSkillEvaluationTypeEnum } from '@src/model/user/UserSkillEvaluation';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SkillBasedRecommendationsBusinessStore, { ISkillBasedRecommendationsListFilter } from '@src/service/business/skillbasedrecommendations/SkillBasedRecommendationsBusinessStore';
import UserSkillEvaluationListBusinessStore, { IUserSkillEvaluationListFilter } from '@src/service/business/user/userSkillEvaluationListBusinessStore';
import { isUserInfo, isWorkPosition } from '@src/service/util/recommendations/recommendationUtils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface ISkillBasedRecommendationListContainerOwnProps {

}

export interface ISkillBasedRecommendationListContainerStateProps {
  skillBasedRecommendations: ICollectionData<ISkillBasedRecommendation>;
  userSkillEvaluationList: ICollectionData<IUserSkillEvaluation>;
}

export interface ISkillBasedRecommendationListContainerDispatchProps {
  fetchSkillBasedRecommendations: (params: ICollectionFetchPayload<ISkillBasedRecommendationsListFilter>) => void;
  clearSkillBasedRecommendations: () => void;
  fetchUserSkillEvaluationList: (id: string, params: ICollectionFetchPayload<IUserSkillEvaluationListFilter>) => void;
  clearUserSkillEvaluatiionList: () => void;
}

type ISkillBasedRecommendationListContainerProps = ISkillBasedRecommendationListContainerOwnProps & ISkillBasedRecommendationListContainerStateProps & ISkillBasedRecommendationListContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<ISkillBasedRecommendationsListFilter>;

// -- Component
// ----------

const SkillBasedRecommendationListContainer = (props: ISkillBasedRecommendationListContainerProps) => {
  const [selectedEntitySkills, setSelectedEntitySkills] = useState<IChartDataItem[]>();

  const [collectionParams, updateCollectionParams, onUpdateSkillBasedRecommendationList] = useCollectionState<ISkillBasedRecommendationsListFilter>({});

  const [colParams, , onUpdateUserSkillClassifications] = useCollectionState<IUserSkillEvaluationListFilter>({});

  useEffect(() => {
    if (props.userSkillEvaluationList) {
      mapUserSkillEvaluationList(props.userSkillEvaluationList.content);
    }
  }, [props.userSkillEvaluationList]);

  useEffect(() => {
    return () => {
      props.clearUserSkillEvaluatiionList();
      props.clearSkillBasedRecommendations();
    };
  }, []);

  const mapUserSkillEvaluationList = (list: IUserSkillEvaluation[]) => {
    // filter out self evaluation skills
    const hrList = list.filter((item) => item.userSkillEvaluationType.id === UserSkillEvaluationTypeEnum.HUMAN_MANAGER);
    const skills = hrList.map((evaluation) => {
      return {
        title: evaluation.skillLevelClassification.skill.title,
        level: evaluation.skillLevelClassification.skillLevel.level,
        series: `${evaluation.user.firstName} ${evaluation.user.lastName}`,
      };
    });

    setSelectedEntitySkills(skills ?? []);
  };

  const mapWorkPositionSkillList = (workPosition: IWorkPosition) => {
    const skills = workPosition.skillLevelClassifications.map((classification) => {
      return {
        title: classification.skill.title,
        level: classification.skillLevel.level,
        series: workPosition.name,
      };
    });

    setSelectedEntitySkills(skills ?? []);
  };

  const handleUpdateFilter = (filter: ISkillBasedRecommendationsListFilter) => {
    const selectedEntity = filter.id;
    updateCollectionParams.onFilterSet(filter);

    onUpdateSkillBasedRecommendationList(props.fetchSkillBasedRecommendations, filter);
    if (isUserInfo(selectedEntity)) {
      onUpdateUserSkillClassifications(() => props.fetchUserSkillEvaluationList(selectedEntity.id, colParams));
    } else if (isWorkPosition(selectedEntity)) {
      mapWorkPositionSkillList(selectedEntity);
    } else {
      throw new Error('Unknown entity type');
    }
  };

  return <SkillBasedRecommendationListView
    filter={collectionParams.filter}
    recommendations={props.skillBasedRecommendations}
    onFilterChange={handleUpdateFilter}
    onPageChange={updateCollectionParams.onPageChange}
    selectedEntitySkills={selectedEntitySkills}
  />;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillBasedRecommendationListContainerOwnProps): ISkillBasedRecommendationListContainerStateProps => ({
  skillBasedRecommendations: SkillBasedRecommendationsBusinessStore.selectors.getSkillBasedRecommendationList(state),
  userSkillEvaluationList: UserSkillEvaluationListBusinessStore.selectors.getUserSkillEvaluationList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISkillBasedRecommendationListContainerDispatchProps => ({
  fetchSkillBasedRecommendations: (params: ICollectionFetchPayload<ISkillBasedRecommendationsListFilter>) => dispatch(SkillBasedRecommendationsBusinessStore.actions.fetchSkillBasedRecommendation(params)),
  clearSkillBasedRecommendations: () => dispatch(SkillBasedRecommendationsBusinessStore.actions.clearSkillBasedRecommendation()),
  fetchUserSkillEvaluationList: (id: string, params: ICollectionFetchPayload<IUserSkillEvaluationListFilter>) => dispatch(UserSkillEvaluationListBusinessStore.actions.fetchUserSkillEvaluationList(id, params)),
  clearUserSkillEvaluatiionList: () => dispatch(UserSkillEvaluationListBusinessStore.actions.clearUserSkillEvaluationList()),
});

export default connect<ISkillBasedRecommendationListContainerStateProps, ISkillBasedRecommendationListContainerDispatchProps, ISkillBasedRecommendationListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(SkillBasedRecommendationListContainer as any));

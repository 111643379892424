import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AllowedUserRoles from '@src/components/common/role/AllowedUserRoles';
import ExternalContentCardList from '@src/components/externalcontent/list/ExternalContentCardList';
import ExternalContentListFilter from '@src/components/externalcontent/list/ExternalContentListFilter';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData } from '@src/service/business/common/types';
import { IExternalContentListFilter } from '@src/service/business/externalcontent/ExternalContentListBusinessStore';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

interface IExternalContentListViewOwnProps {
  externalContentList: ICollectionData<IExternalContent>;
  externalContentListFilter: IExternalContentListFilter;
  sortValue: string[];
  onPageChange: (page: number, size?: number) => void;
  onFilterChange: (filter: IExternalContentListFilter) => void;
  onSortChange: (sort: string[]) => void;
  getPath: (id: string) => string;
}

type IExternalContentListViewProps = IExternalContentListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class ExternalContentListView extends React.Component<IExternalContentListViewProps> {
  render = () => {
    const header = <Row justify="space-between">
      <Col>
        {this.props.translate('EXTERNAL_CONTENT.LIST.TITLE')}
      </Col>
      <Col>
        <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN]} fallback="">
          <Link to={'/externalcontent/create'}>
            <Button type="primary" icon={<LemonIcon name="plus"/>}>
              {this.props.translate('EXTERNAL_CONTENT.LIST.ADD')}
            </Button>
          </Link>
        </AllowedUserRoles>
      </Col>
    </Row>;

    return <AppContent title={header}>
      <Row justify="space-between">
        <Col xs={24} md={12}>
          <ExternalContentListFilter
            filter={this.props.externalContentListFilter}
            onFilterChange={this.props.onFilterChange}
            sortValue={this.props.sortValue}
            onSortChange={this.props.onSortChange}
          />
        </Col>
        <Col xs={24} md={12}>
          {this.props.externalContentList && <ListPagination page={this.props.externalContentList.page} onChange={this.props.onPageChange}/>}
        </Col>
      </Row>
      {this.props.externalContentList && <ExternalContentCardList externalContentList={this.props.externalContentList.content} getPath={this.props.getPath}/>}
    </AppContent>;
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IExternalContentListViewOwnProps>(ExternalContentListView as any);

import AppConfigService from '@src/service/common/AppConfigService';
import moment, { Moment, MomentInputObject } from 'moment';
import { MomentInput } from 'moment';

export type IBackendDateFormat = 'backendDate' | 'backendQueryParamDateFormat';
export type IViewDateFormat = 'dateWithTime' | 'dateWithYear' | 'dateWithoutDay';
export type IDateFormat = IBackendDateFormat | IViewDateFormat;

export class DateUtils {
  /** Get date format */
  static getDateFormat = (format: IDateFormat) => AppConfigService.getValue(`dateTimeFormat.${format}`);

  /** Format date to backend format */
  static toBackendDateFormat = (date?: MomentInput, format?: IBackendDateFormat): string | undefined => {
    if (date) {
      return moment(date).format(AppConfigService.getValue(`dateTimeFormat.${format}`));
    }
    return undefined;
  };

  /** Format BE date to view format */
  static toViewDateFormat = (date: MomentInput, format: IViewDateFormat): string | undefined => {
    const getSelecteFormat = () => {
      if (format === 'dateWithTime') {
        return DateUtils.getDateFormat(format);
      } else if (format === 'dateWithYear') {
        return DateUtils.getDateFormat(format);
      } else if (format === 'dateWithoutDay') {
        return DateUtils.getDateFormat(format);
      }
    };
    return date ? moment(date).format(getSelecteFormat()) : undefined;
  };

  /** Format ISO 8601 duration (etc. P(n)Y(n)M(n)DT(n)H(n)M(n)S ) to moment duration object */
  static getDuration = (isoDuration: string): moment.Duration => {
    return moment.duration(isoDuration);
  };

  /** Format to ISO 8601 duration */
  static toISOstring = (period: MomentInputObject): string => {
    return moment.duration(period).toISOString();
  };

  /** Disable past dates */
  static disablePastDate = (current: Moment): boolean => {
    return current != null ? current < moment().startOf('day') : false;
  };

  /** Disable future dates */
  static disableFutureDate = (current: Moment): boolean => {
    return current != null ? current > moment().endOf('day') : false;
  };
}

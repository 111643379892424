import { useCallback, useMemo, useState } from 'react';

export interface ISetModalStateFunctions<T> {
  onCloseUpdate: () => void;
  onCloseCreate: () => void;
  onOpenCreate: () => void;
  onOpenUpdate: (entity: T) => void;
  onOpenEntity: (entity: T) => void;
  onCloseEntity: () => void;
}

interface IUseEntityModalsStateState<T> {
  isCreateModalVisible: boolean;
  isUpdateModalVisible: boolean;
  isEntityModalVisible: boolean;
  selectedEntity?: T;
}

/* custom hook to handle state for showing create and update modal in list components */
const useEntityModalsState = <T>(initialState?: Partial<IUseEntityModalsStateState<T>>): [IUseEntityModalsStateState<T>, ISetModalStateFunctions<T>] => {
  const [modalsState, setModalsState] = useState<IUseEntityModalsStateState<T>>({
    isCreateModalVisible: false,
    isUpdateModalVisible: false,
    isEntityModalVisible: false,
    selectedEntity: undefined,
    ...initialState,
  });

  const toggleUpdateModal = useCallback(
    (entity?: T) => {
      setModalsState({ ...modalsState, selectedEntity: entity, isUpdateModalVisible: !!entity });
    },
    [modalsState]
  );

  const toggleCreateModal = useCallback(
    (isVisible: boolean) => {
      setModalsState({ ...modalsState, isCreateModalVisible: isVisible });
    },
    [modalsState]
  );

  const toggleEntityModal = useCallback(
    (entity?: T) => {
      setModalsState({ ...modalsState, selectedEntity: entity, isEntityModalVisible: !!entity });
    },
    [modalsState]
  );

  const handleCreateModalOpen = useCallback(() => {
    toggleCreateModal(true);
  }, []);

  const handleCreateModalClose = useCallback(() => {
    toggleCreateModal(false);
  }, []);

  const handleUpdateModalClose = useCallback(() => {
    toggleUpdateModal();
  }, []);

  const handleUpdateModalOpen = useCallback((entity: T) => {
    toggleUpdateModal(entity);
  }, []);

  const handleEntityModalOpen = useCallback((entity: T) => {
    toggleEntityModal(entity);
  }, []);

  const handleEntityModalClose = useCallback(() => {
    toggleEntityModal();
  }, []);

  const toggleEntityModals: ISetModalStateFunctions<T> = useMemo(
    () => ({
      onCloseUpdate: handleUpdateModalClose,
      onCloseCreate: handleCreateModalClose,
      onOpenCreate: handleCreateModalOpen,
      onOpenUpdate: handleUpdateModalOpen,
      onOpenEntity: handleEntityModalOpen,
      onCloseEntity: handleEntityModalClose,
    }),
    [handleUpdateModalClose, handleCreateModalClose, handleCreateModalOpen, handleUpdateModalOpen, handleEntityModalOpen, handleEntityModalClose]
  );

  return [modalsState, toggleEntityModals];
};

export default useEntityModalsState;

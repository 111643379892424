import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import EducationApplicationListContainer from '@src/components/externalEducationApplication/list/EducationApplicationListContainer';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import React from 'react';

// -- Prop types
// ----------
interface ICoordinatorEducationApplicationListViewOwnProps {}

type ICoordinatorEducationApplicationListViewProps = ICoordinatorEducationApplicationListViewOwnProps & IWithLocalizeOwnProps;

interface ICoordinatorEducationApplicationListViewState {}
// -- Component
// ----------
/** Coordinator all education application list view */
class CoordinatorEducationApplicationListView extends React.Component<ICoordinatorEducationApplicationListViewProps, ICoordinatorEducationApplicationListViewState> {
  state: ICoordinatorEducationApplicationListViewState = {};

  render = () => {
    return (
      <AppContent>
        <EducationApplicationListContainer scrollable={true} additionalCols={this.educationApplicationReportCols} hideMyProfileColumn={true} showEducationApplicationFilter={true} showEducationApplicationStatusFilter={false} />
      </AppContent>
    );
  };

  private educationApplicationReportCols = (): Array<ITimunDataTableCol<IExternalEducationApplication>> => {
    return [
      {
        key: 'assigneeName',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.ASSIGNEE, record.activity),
      },
      {
        key: 'assigneeResidence',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_RESIDENCE_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.ASSIGNEE, record.activity)?.address?.city.name,
      },
      {
        key: 'evaluatorName',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EVALUATOR_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.EVALUATOR, record.activity),
      },
    ];
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<ICoordinatorEducationApplicationListViewOwnProps>(CoordinatorEducationApplicationListView as any);

import React from 'react';
import { connect } from 'react-redux';

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { IEnrollmentRequirement } from '@src/model/enrollmentrequirement/EnrollmentRequirement';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import EnrollmentRequirementListBusinessStore, { IEnrollmentRequirementListFilter } from '@src/service/business/enrollmentrequirements/enrollmentRequirementBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface IEnrollmentRequirementDataPickerOwnProps {
  value?: IEnrollmentRequirement;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (newValue?: IEnrollmentRequirement) => void;
}

export interface IEnrollmentRequirementDataPickerStateProps {
}

export interface IEnrollmentRequirementDataPickerDispatchProps {
  fetchEnrollmentRequirementList: (params: ICollectionFetchPayload<IEnrollmentRequirementListFilter>) => ITrackableAction;
}
type IEnrollmentRequirementDataPickerProps = IEnrollmentRequirementDataPickerOwnProps & IEnrollmentRequirementDataPickerStateProps & IEnrollmentRequirementDataPickerDispatchProps;

interface IEnrollmentRequirementDataPickerState {
  enrollmentRequirementList?: IEnrollmentRequirement[] | null;
}

// -- Component
// ----------

/** Describe your component ... */
class EnrollmentRequirementDataPicker extends React.Component<IEnrollmentRequirementDataPickerProps, IEnrollmentRequirementDataPickerState> {
  state: IEnrollmentRequirementDataPickerState = {};

  componentDidMount() {
    this.searchList();
  }

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.enrollmentRequirementList != null ? this.state.enrollmentRequirementList.map(this.mapToItem) : [];

    return <DataPicker
      value={pickerValue}
      items={pickerItems}
      disabled={this.props.disabled}
      showSearch={false}
      onChange={this.handleDataPickerChange}
      dataTestIdPrefix="timun-skillPicker"
      placeholderSuffix={this.props.placeholder}
    />;
  };

  private handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  /** Map entity ref list to data picker item list. */
  private mapToItem(skill: IEnrollmentRequirement): IDataPickerItem {
    return {
      value: skill.id,
      name: skill.name,
      data: skill,
    };
  }

  private searchList(search?: string) {
    // search by name only active enrollment req
    this.fetchList({
      filter: { name: search, active: true },
      page: 0,
      size: AppConfigService.getValue('api.paging.defaultPageSize'),
      sort: [],
    });
  }

  private fetchList(params: ICollectionFetchPayload<IEnrollmentRequirementListFilter>) {
    this.props.fetchEnrollmentRequirementList(params).track().subscribe(this.storeList);
  }

  private storeList = (enrollmentRequirementList?: ICollectionData<IEnrollmentRequirement>) => {
    this.setState({ enrollmentRequirementList: enrollmentRequirementList?.content });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEnrollmentRequirementDataPickerOwnProps): IEnrollmentRequirementDataPickerStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEnrollmentRequirementDataPickerDispatchProps => ({
  fetchEnrollmentRequirementList: (params: ICollectionFetchPayload<IEnrollmentRequirementListFilter>) => dispatch(createTrackableAction(EnrollmentRequirementListBusinessStore.actions.fetchEnrollmentRequirementList(params))),
});

export default connect<IEnrollmentRequirementDataPickerStateProps, IEnrollmentRequirementDataPickerDispatchProps, IEnrollmentRequirementDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(EnrollmentRequirementDataPicker as any);

import React from 'react';

import LemonImage from '@src/components/common/image/LemonImage';
import { IWebinar } from '@src/model/webinar/Webinar';

// -- Prop types
export interface IWebinarCoverImagePublicProps {
  webinar?: IWebinar;
}

type IWebinarCoverImageProps = IWebinarCoverImagePublicProps;

// ----- Component
const WebinarCoverImage: React.FC<IWebinarCoverImageProps> = (props) => {
  return (
    <div className="timun-webinarCard__coverView">
      <LemonImage imagePath={props.webinar?.coverImageUrl || 'cover-placeholder.png'} className="timun-webinarCard__coverImage" />
    </div>
  );
};

export default WebinarCoverImage;

import CourseReportListContainer from '@src/components/course/report/CourseReportListContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type ICourseReportPageProps = WithRouterProps;

const CourseReportPage = (props: ICourseReportPageProps) => <CourseReportListContainer courseId={props.params.courseId} />;

export default withRouter(CourseReportPage);

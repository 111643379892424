import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import { ICourse } from '@src/model/course/Course';
import { IShareableContentObject } from '@src/model/course/scorm/ShareableContentObject';
import AppConfigService from '@src/service/common/AppConfigService';
import { Menu, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, withRouter } from 'react-router';

const COURSE_NOTES_ROUTE = AppConfigService.getValue('components.courses.courseNotesRouterProp');

const { SubMenu, Item } = Menu;

// -- Prop types
// ----------

export interface IScormViewSiderOwnProps {
  course: ICourse;
  lectureList: IShareableContentObject[];
  selectedLecture?: IShareableContentObject;
}

type IScormViewSiderProps = IScormViewSiderOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** view component to display the menu with lecture list in the course view */
const ScormViewSider = (props: IScormViewSiderProps) => {
  const [openSubmenu, setOpenSubmenu] = useState<string[]>([]);

  useEffect(() => {
    if (props.selectedLecture && CourseHelperUtils.isLecture(props.selectedLecture.id)) {
      updateOpenSubmenu();
    } else {
      setOpenSubmenu([]);
    }
  }, [props.selectedLecture]);

  const getCourseLink = (id: string): string => {
    return CourseHelperUtils.getTraineePath(props.course, id);
  };

  const updateOpenSubmenu = () => {
    if (props.selectedLecture) {
      const lectureGroup = CourseHelperUtils.findLectureParent(props.lectureList, props.selectedLecture?.id);
      if (lectureGroup) {
        setOpenSubmenu([lectureGroup.id]);
      }
    }
  };

  const renderList = useCallback((lectureList: IShareableContentObject[]) => {
    return lectureList.map((lecture: IShareableContentObject) => {
      if (lecture.children != null && lecture.children.length > 0) {
        return (
          <SubMenu
            data-test-id={`timun-courseViewSider__lectureGroupMenu_${lecture.id}`}
            key={lecture.id}
            title={
              <Tooltip title={lecture.title} placement="topLeft">
                {lecture.filePath != null ? <Link to={getCourseLink(lecture.id)}>{lecture.title}</Link> : <div className="timun-courseView__lectureTitle">{lecture.title}</div>}
              </Tooltip>
            }
          >
            {renderList(lecture.children)}
          </SubMenu>
        );
      } else {
        return (
          <Item key={lecture.id} data-test-id={`timun-courseViewSider__lecture_${lecture.id}`}>
            <Tooltip title={lecture.title} placement="topLeft">
              <Link to={getCourseLink(lecture.id)}>{lecture.title}</Link>
            </Tooltip>
          </Item>
        );
      }
    });
  }, []);

  const lectureMenu = useMemo(() => renderList(props.lectureList), [props.lectureList]);

  return (
    <Menu className="timun-courseView__siderMenu" onOpenChange={setOpenSubmenu} mode="inline" selectedKeys={[props.selectedLecture?.id ?? '']} openKeys={openSubmenu}>
      {...lectureMenu}

      <Item key={COURSE_NOTES_ROUTE}>
        <Link to={getCourseLink(COURSE_NOTES_ROUTE)} data-test-id="timun-courseViewSider__notesMenuItem">
          <div className="timun-courseView__lectureTitle">{props.translate('NOTE_LIST.MY_NOTES_LABEL')}</div>
        </Link>
      </Item>
    </Menu>
  );
};

export default withLocalize<IScormViewSiderOwnProps>(withRouter(withRoles(ScormViewSider) as any));

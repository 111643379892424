import LemonImage from '@src/components/common/image/LemonImage';
import { Tooltip } from 'antd';
import React from 'react';

const style = { width: '100%', height: '100%' }; // content in tooltip must have some width to be displayed properly

export interface ILemonInfoTooltipOwnProps {
  message: string;
}

type ILemonInfoTooltipProps = ILemonInfoTooltipOwnProps;

class LemonInfoTooltip extends React.Component<ILemonInfoTooltipProps> {
  render = () => {
    return (
      <Tooltip overlay={this.props.message} placement={'bottom'}>
        <div style={style}>
          <LemonImage fallbackIconName="infoCircle" className="timun-info-lemonInfoTooltip" />
        </div>
      </Tooltip>
    );
  };
}

export default LemonInfoTooltip;

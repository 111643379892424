import { StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, filter, ignoreElements, mergeMap } from 'rxjs/operators';

import IIdRef from '@src/model/common/IdRef';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface IDisenrollUsersCoursesPayload {
  users?: IIdRef<string>[];
  courses: IIdRef<string>[];
}

export interface IEnrollUsersCoursesPayload extends IDisenrollUsersCoursesPayload {
  deadlineDate?: string;
}

export interface IEnrollAllUsersCoursesPayload extends IDisenrollUsersCoursesPayload {}

export interface IUpdateUsersCoursesPayload {
  dataToAdd: IEnrollUsersCoursesPayload;
  dataToRemove: IDisenrollUsersCoursesPayload;
}

// -
// -------------------- Selectors

// -
// -------------------- Actions

const Actions = {
  USERS_COURSES_UPDATE: 'USERS_COURSES_UPDATE',
  USERS_COURSES_ENROLL: 'USERS_COURSES_ENROLL',
  USERS_COURSES_DISENROLL: 'USERS_COURSES_DISENROLL',
  USERS_ALL_COURSES_ENROLL: 'USERS_ALL_COURSES_ENROLL',
};

/** Assign courses to users, wrapper for both enroll and dissenroll */
const updateUsersCourses = (data: IUpdateUsersCoursesPayload): IPayloadAction<IUpdateUsersCoursesPayload> => {
  return {
    type: Actions.USERS_COURSES_UPDATE,
    payload: data,
  };
};

/** Enroll courses */
const enrollUsersCourses = (data: IEnrollUsersCoursesPayload): IPayloadAction<IEnrollUsersCoursesPayload> => {
  return {
    type: Actions.USERS_COURSES_ENROLL,
    payload: data,
  };
};

/** Disenroll courses */
const disenrollUsersCourses = (data: IDisenrollUsersCoursesPayload): IPayloadAction<IDisenrollUsersCoursesPayload> => {
  return {
    type: Actions.USERS_COURSES_DISENROLL,
    payload: data,
  };
};

/** Enroll all users to courses */
const enrollAllUsersCourses = (data: IEnrollAllUsersCoursesPayload): IPayloadAction<IEnrollAllUsersCoursesPayload> => {
  return {
    type: Actions.USERS_ALL_COURSES_ENROLL,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const updateUsersCoursesEffect = (action$: Observable<IPayloadAction<IUpdateUsersCoursesPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USERS_COURSES_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { dataToAdd, dataToRemove } = action.payload;
      return of(true).pipe(
        mergeMap(() => {
          if (dataToAdd.courses.length) {
            return EntityApiServiceRegistry.getService('Course').updateMethod('enroll', dataToAdd);
          } else {
            return of(true);
          }
        }),
        mergeMap(() => {
          if (dataToRemove.courses.length) {
            return EntityApiServiceRegistry.getService('Course').updateMethod('disenroll', dataToRemove);
          } else {
            return of(true);
          }
        }),
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    // tslint:disable-next-line: no-duplicate-string
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating users courses ', error);
      return o;
    })
  );
};

const enrollUsersCoursesEffect = (action$: Observable<IPayloadAction<IEnrollUsersCoursesPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USERS_COURSES_ENROLL;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .updateMethod('enroll', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error enrolling users courses ', error);
      return o;
    })
  );
};

const disenrollUsersCoursesEffect = (action$: Observable<IPayloadAction<IDisenrollUsersCoursesPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USERS_COURSES_DISENROLL;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .updateMethod('disenroll', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error disenrolling users courses ', error);
      return o;
    })
  );
};

const enrollAllUsersCoursesEffect = (action$: Observable<IPayloadAction<IEnrollAllUsersCoursesPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USERS_ALL_COURSES_ENROLL;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .updateMethod('enrollAll', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE_USER_ENROLL.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error enrolling all users courses ', error);
      return o;
    })
  );
};
// -
// -------------------- Reducers

// --
// -------------------- Business Store

export const UserCourseUpdateBusinessStore = {
  actions: {
    updateUsersCourses,
    enrollUsersCourses,
    disenrollUsersCourses,
    enrollAllUsersCourses,
  },

  selectors: {},

  effects: {
    updateUsersCoursesEffect,
    enrollUsersCoursesEffect,
    disenrollUsersCoursesEffect,
    enrollAllUsersCoursesEffect,
  },

  reducers: {},
};

// --
// export business store
export default UserCourseUpdateBusinessStore;

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdminEducationApplicationReportView from '@src/components/externalEducationApplication/view/AdminEducationApplicationReportView';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import ExternalEducationApplicationBusinessStore, { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
interface IAdminEducationApplicationReportListContainerOwnProps {
}

interface IAdminEducationApplicationReportListContainerStateProps {
  applicationList: ICollectionData<IExternalEducationApplication>;
  applicationListFilter: IExternalEducationApplicationListFilter;
}

interface IAdminEducationApplicationReportListContainerDispatchProps {
  fetchApplicationList: (params: ICollectionFetchPayload<IExternalEducationApplicationListFilter>) => ITrackableAction;
  clearFilter: () => void;
  storeFilter: (listFilter: IExternalEducationApplicationListFilter) => void;
}
type IAdminEducationApplicationReportListContainerProps = IAdminEducationApplicationReportListContainerOwnProps & IAdminEducationApplicationReportListContainerStateProps
  & WithRouterProps & IWithLocalizeOwnProps & IAdminEducationApplicationReportListContainerDispatchProps;

interface IAdminEducationApplicationReportListContainerState {
  page: number;
  size: number;
  sort: string[];
}
// -- Component
// ----------
class AdminEducationApplicationReportListContainer extends React.Component<IAdminEducationApplicationReportListContainerProps, IAdminEducationApplicationReportListContainerState> {
  state: IAdminEducationApplicationReportListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: [],
  };

  componentDidMount = () => {
    this.updateList();
  };

  componentDidUpdate = (prevProps: IAdminEducationApplicationReportListContainerProps, prevState: IAdminEducationApplicationReportListContainerState) => {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || this.props.applicationListFilter !== prevProps.applicationListFilter) {
      this.updateList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearFilter();
  };

  render = () => {
    return (
      this.props.applicationList && <AdminEducationApplicationReportView applicationList={this.props.applicationList} applicationListFilter={this.props.applicationListFilter} onFilterSubmit={this.handleFilterSubmit} onPageChange={this.handlePageChange} />
    );
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleFilterSubmit = (listFilter: IExternalEducationApplicationListFilter) => {
    this.props.storeFilter(listFilter);
    this.setState({ page: 0 });
  }

  private updateList() {
    this.props.fetchApplicationList({
      filter: { ...this.props.applicationListFilter },
      page: this.state.page,
      size: this.state.size,
      sort: this.state.sort,
    });
  }

}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAdminEducationApplicationReportListContainerStateProps => ({
  applicationList: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplications(state),
  applicationListFilter: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplicationListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminEducationApplicationReportListContainerDispatchProps => ({
  fetchApplicationList: (params: ICollectionFetchPayload<IExternalEducationApplicationListFilter>) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.fetchExternalEducationApplicationList({ ...params }))),
  clearFilter: () => dispatch(ExternalEducationApplicationBusinessStore.actions.clearExternalEducationApplicationListFilter()),
  storeFilter: (listFilter: IExternalEducationApplicationListFilter) => dispatch(ExternalEducationApplicationBusinessStore.actions.storeExternalEducationApplicationListFilter(listFilter)),
});

export default connect<IAdminEducationApplicationReportListContainerStateProps, IAdminEducationApplicationReportListContainerDispatchProps, IAdminEducationApplicationReportListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(AdminEducationApplicationReportListContainer as any)));

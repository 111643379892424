import ExternalContentCreateView from '@src/components/externalcontent/create/ExternalContentCreateView';
import { IFile } from '@src/model/file/File';
import ExternalContentViewBusinessStore, { IExternalContentCreatePayload } from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';

// -- Prop types
// ----------

interface IExternalContentCreateContainerOwnProps {
}

interface IExternalContentCreateContainerDispatchProps {
  createExternalContent: (data: IExternalContentCreatePayload) => ITrackableAction;
  uploadCover: (id: string, data: IFile) => ITrackableAction;
}

type IExternalContentCreateContainerProps = IExternalContentCreateContainerOwnProps & IExternalContentCreateContainerDispatchProps & WithRouterProps;

// -- Component
// ----------

class ExternalContentCreateContainer extends React.Component<IExternalContentCreateContainerProps> {
  render() {
    return <ExternalContentCreateView onCreate={this.handleExternalContentCreate} onCancel={this.handleCancel} />;
  }

  handleCancel = () => {
    this.props.router.push('/externalcontents');
  };

  handleExternalContentCreate = (data: IExternalContentCreatePayload, coverImg?: IFile) => {
    this.props.createExternalContent(data).track().subscribe((res) => {
      if (coverImg) {
        this.handleExternalContentCoverSubmit(res.id, coverImg);
      } else {
        this.props.router.replace(`/externalcontent/${res.id}`);
      }
    });
  };

  private handleExternalContentCoverSubmit = (id: string, coverImg: IFile) => {
    this.props.uploadCover(id, coverImg).track().subscribe(() => {
      this.props.router.replace(`externalcontent/${id}`);
    });
  };
}

// -- HOCs and exports
// ----------

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IExternalContentCreateContainerDispatchProps => ({
  createExternalContent: (data: IExternalContentCreatePayload) => createTrackableAction(dispatch(ExternalContentViewBusinessStore.actions.createExternalContent(data))),
  uploadCover: (id: string, data: IFile) => createTrackableAction(dispatch(ExternalContentViewBusinessStore.actions.uploadExternalContentCover(id, data))),
});

export default connect<any, IExternalContentCreateContainerDispatchProps, IExternalContentCreateContainerOwnProps>(null, mapDispatchToProps)(withRouter(ExternalContentCreateContainer as any));

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserListUploadForm from '@src/components/common/upload/UserListUploadForm';
import { IFile } from '@src/model/file/File';
import { Modal } from 'antd';
import React, { useCallback, useState } from 'react';

// -- Prop types
// ----------

export interface IUserListUploadModalOwnProps {
  title: string;
  onSubmit?: (file: IFile) => void;
  onClose?: () => void;
}

type IUserListUploadModalProps = IUserListUploadModalOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const UserListUploadModal: React.FC<IUserListUploadModalProps> = (props: IUserListUploadModalProps) => {
  const [uploadedFile, setUploadedFile] = useState<IFile>();

  const handleUploadedFileChange = useCallback(
    (data: IFile) => {
      setUploadedFile(data);
    },
    [setUploadedFile]
  );
  const handleOk = useCallback(() => (uploadedFile ? props.onSubmit?.(uploadedFile) : undefined), [uploadedFile, props.onSubmit]);

  return (
    <Modal maskClosable={false} title={props.title} className="timun-fileUploader__dialog" visible={true} onOk={handleOk} onCancel={props.onClose} okText={props.translate('COMMON.ACTION_CONTINUE')} cancelText={props.translate('COMMON.ACTION_CANCEL')} okButtonProps={{ disabled: !uploadedFile }}>
      <div className="timun-fileUploader__previewPlaceholder">
        <UserListUploadForm submitAfterUpload={true} onSubmit={props.onSubmit} onClose={props.onClose} onUploadedFileChange={handleUploadedFileChange} />
      </div>
    </Modal>
  );
};

export default withLocalize<IUserListUploadModalOwnProps>(UserListUploadModal as any);

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ReminderCreateContainer from '@src/components/reminder/ReminderCreateContainer';
import WebinarListView from '@src/components/webinar/list/WebinarListView';
import { ReminderTypeEnum } from '@src/model/reminder/Reminder';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IWebinar } from '@src/model/webinar/Webinar';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import WebinarListBusinessStore, { IWebinarsFilter } from '@src/service/business/webinars/webinarListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
// ----- Constants
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.paging.defaultPageSize');
const DEFAULT_SORT_VALUE = ['estimatedStartDateTime,asc'];
export interface IWebinarListContainerOwnProps {
  showStatusFilter?: boolean;
  predefinedListFilter?: IWebinarsFilter;
  viewName?: string;
}
export interface IWebinarListContainerStateProps {
  currentUser: IUserInfo;
  webinarList: ICollectionData<IWebinar>;
}
export interface IWebinarListContainerDispatchProps {
  fetchWebinarList: (params: ICollectionFetchPayload<IWebinarsFilter>) => any;
}
type IWebinarListContainerProps = IWebinarListContainerOwnProps & IWebinarListContainerStateProps & IWebinarListContainerDispatchProps & IWithLocalizeOwnProps & IWithRolesOwnProps & WithRouterProps;
const WebinarListContainer = (props: IWebinarListContainerProps) => {
  const initialCollectionValues = useMemo(
    () => ({
      sort: DEFAULT_SORT_VALUE,
      size: DEFAULT_PAGE_SIZE_VALUE,
      filter: props.predefinedListFilter,
    }),
    [DEFAULT_SORT_VALUE, DEFAULT_PAGE_SIZE_VALUE, props.predefinedListFilter]
  );
  const [webinarModalState, toggleWebinarModals] = useEntityModalsState<IWebinar>();
  const [collectionParams, updateCollectionParams] = useCollectionState<IWebinarsFilter>(
    {
      viewName: props.viewName,
      updateFn: props.fetchWebinarList,
      initialValues: initialCollectionValues,
    },
    []
  );
  const handleCreateWebinarButtonClick = useCallback(() => {
    props.router.push('/webinar/create');
  }, [props.router.push]);

  const showStatusFilter = useMemo(() => props.showStatusFilter && props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]), [props.showStatusFilter, props.isInRoles]);
  return (
    <React.Fragment>
      <WebinarListView
        webinarList={props.webinarList}
        webinarListFilter={collectionParams.filter}
        showStatusFilter={showStatusFilter}
        sortValue={collectionParams.sort}
        onCreateWebinar={handleCreateWebinarButtonClick}
        onPageChange={updateCollectionParams.onPageChange}
        onSetReminder={toggleWebinarModals.onOpenEntity}
        onFilterChange={updateCollectionParams.onFilterSet}
        onSortChange={updateCollectionParams.onSortChange}
      />
      ​{/* Create webinar reminder  */}
      {webinarModalState.selectedEntity && webinarModalState.isEntityModalVisible && (
        <ReminderCreateContainer showModal={webinarModalState.isEntityModalVisible} onCloseModal={toggleWebinarModals.onCloseEntity} refObject={{ id: webinarModalState.selectedEntity.id, name: webinarModalState.selectedEntity.title }} user={props.currentUser} reminderType={ReminderTypeEnum.WEBINAR} />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any, ownProps: IWebinarListContainerOwnProps): IWebinarListContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  webinarList: WebinarListBusinessStore.selectors.getWebinars(state),
});
const mapDispatchToProps = (dispatch: any): IWebinarListContainerDispatchProps => ({
  fetchWebinarList: (params: ICollectionFetchPayload<IWebinarsFilter>) => dispatch(WebinarListBusinessStore.actions.fetchWebinars(params)),
});
export default connect<IWebinarListContainerStateProps, IWebinarListContainerDispatchProps, IWebinarListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRouter(withRoles(withLocalize(WebinarListContainer as any))));

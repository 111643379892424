import { DatePicker, Form, Modal, Transfer } from 'antd';
import { TransferItem } from 'antd/lib/transfer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { ICourse } from '@src/model/course/Course';
import { IUserInfo } from '@src/model/user/User';
import { IUpdateUsersCoursesPayload } from '@src/service/business/courses/UsersCoursesUpdateBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import moment from 'moment';
import { DateUtils } from '@src/service/util/date/DateUtils';

const FormItem = Form.Item;

const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const ISO_DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.backendQueryParamDateFormat');

// -- Prop types
// ----------

export interface ICourseListEditFormOwnProps {
  user: IUserInfo;
  allCourseList: ICourse[];
  userCourseList: ICourse[];
  onCancel: () => void;
  onSubmit: (data: IUpdateUsersCoursesPayload) => void;
}
export interface ICourseListEditForm {
  courseKeys: string[];
  deadlineDate: string;
}

type ICourseListEditFormProps = ICourseListEditFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Show list of courses grouped by course groups. Additional (custom) columns can be added per each course using render function. */
const CourseListEditForm: React.FC<ICourseListEditFormProps> = (props: ICourseListEditFormProps) => {
  const [transferDataSource, setTransferDataSource] = useState<TransferItem[]>([]);
  const [transferTargetKeys, setTransferTargetKeys] = useState<string[]>([]);
  const [isDeadlineDateRequired, setIsDeadlineDateRequired] = useState<boolean>(false);
  const [form] = Form.useForm<ICourseListEditForm>();

  const initialUserCoursesKeys: string[] = useMemo(() => props.userCourseList.map((course: ICourse) => course.id), [props.userCourseList]);

  useEffect(() => {
    const transferDataSource: TransferItem[] = props.allCourseList.map((course: ICourse) => ({ key: course.id, title: course.title }));
    const transferTargetKeys: string[] = props.userCourseList.map((course: ICourse) => course.id);
    setTransferDataSource(transferDataSource);
    setTransferTargetKeys(transferTargetKeys);
  }, []);

  const hasCourseListChanged = useCallback(() => initialUserCoursesKeys.length !== transferTargetKeys.length || !initialUserCoursesKeys.every((key, index) => key === transferTargetKeys[index]), [initialUserCoursesKeys, transferTargetKeys]);

  const hasNewCourses = useCallback(
    (selectedKeys: string[]): boolean => {
      return selectedKeys.filter((item: string) => !initialUserCoursesKeys.includes(item)).length > 0;
    },
    [initialUserCoursesKeys]
  );

  const handleChange = useCallback(
    (nextTargetKeys: string[], direction: string, moveKeys: string[]) => {
      setTransferTargetKeys(nextTargetKeys);
      setIsDeadlineDateRequired(hasNewCourses(nextTargetKeys));
    },
    [setTransferTargetKeys, setIsDeadlineDateRequired, hasNewCourses]
  );

  const handleFinish = useCallback(
    (values: ICourseListEditForm) => {
      const coursesToAdd: IIdRef<string>[] = values.courseKeys.filter((item: string) => !initialUserCoursesKeys.includes(item)).map((item: string) => ({ id: item }));
      const coursesToRemove: IIdRef<string>[] = initialUserCoursesKeys.filter((item: string) => !values.courseKeys.includes(item)).map((item: string) => ({ id: item }));
      props.onSubmit({
        dataToAdd: {
          users: [{ id: props.user.id }],
          courses: coursesToAdd,
          deadlineDate: values.deadlineDate ? moment(values.deadlineDate).format(ISO_DATE_FORMAT) : undefined,
        },
        dataToRemove: {
          users: [{ id: props.user.id }],
          courses: coursesToRemove,
        },
      });
    },
    [initialUserCoursesKeys, props.onSubmit, moment, ISO_DATE_FORMAT]
  );

  return (
    <Modal
      maskClosable={false}
      className="lemon-modal__modal--md timun-courseListEditForm__container"
      open={true}
      title={props.translate(`COURSE_LIST_EDIT_FORM.${props.userCourseList.length ? 'EDIT' : 'ADD'}_TITLE_LABEL`)}
      okText={props.translate(props.userCourseList.length ? 'COMMON.ACTION_SAVE' : 'COMMON.ACTION_ADD')}
      onOk={form.submit}
      onCancel={props.onCancel}
      okButtonProps={{ disabled: !hasCourseListChanged() }}
    >
      <Form<ICourseListEditForm> form={form} onFinish={handleFinish}>
        <FormItem name={'courseKeys'} label={props.translate('COURSE_LIST.VIEW_TITLE')}>
          <Transfer
            titles={[props.translate('COURSE_LIST_EDIT_FORM.UNASSIGNED_COURSES_LABEL'), props.translate('COURSE_LIST_EDIT_FORM.ASSIGNED_COURSES_LABEL')]}
            dataSource={transferDataSource}
            targetKeys={transferTargetKeys}
            render={(item) => item.title || null}
            onChange={handleChange}
            data-test-id="timun-courseListEditForm__courseKeys"
          />
        </FormItem>
        <FormItem name={'deadlineDate'} label={props.translate('COURSE_LIST_EDIT_FORM.DEADLINE_DATE_LABEL')} rules={[{ required: isDeadlineDateRequired, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
          <DatePicker allowClear={false} format={DATE_FORMAT} disabledDate={DateUtils.disablePastDate} disabled={!isDeadlineDateRequired} placeholder={props.translate('COURSE_LIST_EDIT_FORM.DEADLINE_DATE_PLACEHOLDER')} data-test-id="timun-courseListEditForm__deadlineDate" />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default withLocalize<ICourseListEditFormOwnProps>(CourseListEditForm as any);

import ExamTemplateQuizDisplayContainer from '@src/components/exam/examtemplate/ExamTemplateQuizDisplayContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type IExamTemplateQuizDisplayPageProps = WithRouterProps;

const ExamTemplateQuizDisplayPage = (props: IExamTemplateQuizDisplayPageProps) => <ExamTemplateQuizDisplayContainer examTemplateId={props.params.examTemplateId} />;

export default withRouter<IExamTemplateQuizDisplayPageProps>(ExamTemplateQuizDisplayPage);

import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IUserInfo } from '@src/model/user/User';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import CourseViewContainer from '@src/components/course/view/CourseViewContainer';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import RouterNavigationPromptContextProvider from '@src/components/common/route/prompt/RouterNavigationPromptContextProvider';

// -- Prop types
// ----------
export interface ICourseViewPagePublicProps {}
interface ICourseViewPageStateProps {
  currentUser: IUserInfo;
}
type ICourseViewPageProps = ICourseViewPagePublicProps & ICourseViewPageStateProps & WithRouterProps & IWithRolesOwnProps;

// -- Component
// ----------
const CourseViewPage = (props: ICourseViewPageProps) => {
  return (
    <RouterNavigationPromptContextProvider message={LocalizeService.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION')}>
      <CourseViewContainer courseId={props.params.courseId} lectureId={props.params.lectureId} />
    </RouterNavigationPromptContextProvider>
  );
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseViewPagePublicProps): ICourseViewPageStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

export default withRouter(connect<ICourseViewPageStateProps, any, ICourseViewPagePublicProps>(mapStateToProps)(withRoles(CourseViewPage)));

import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserGroupMemberList from '@src/components/usergroup/members/UserGroupMemberList';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { IUserGroupMember } from '@src/model/usergroup/UserGroupMember';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserGroupBusinessStore, { IUserGroupMemberCreatePayload, IUserGroupMemberListFilter } from '@src/service/business/usergroup/UserGroupBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.paging.midPageSize');
const VIEW_NAME = '@@USER_GROUP_MEMBER_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<IUserGroupMemberListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
};

// -- Prop types
// ----------
export interface IUserGroupMemberContainerOwnProps {
  userGroup: IUserGroup;
}

export interface IUserGroupMemberContainerStateProps {
  userGroupMembers: ICollectionData<IUserGroupMember>;
}
export interface IUserGroupMemberContainerDispatchProps {
  fetchUserGroupMembers: (userGroupId: string, params: ICollectionFetchPayload<IUserGroupMemberListFilter>) => void;
  addUserGroupMembers: (profileId: string, data: IUserGroupMemberCreatePayload[]) => ITrackableAction;
  updateUserGroupMembers: (profileId: string, data: IUserGroupMember) => ITrackableAction;
  removeUserGroupMembers: (profileId: string, data: IUserGroupMember[]) => ITrackableAction;
  clearUserGroupMembers: () => void;
}

type IUserGroupMemberContainerProps = IUserGroupMemberContainerOwnProps & IUserGroupMemberContainerStateProps & IUserGroupMemberContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IUserGroupMemberListFilter>;

interface IUserGroupMemberContainerState {}
// -- Component
// ----------

class UserGroupMemberContainer extends React.Component<IUserGroupMemberContainerProps, IUserGroupMemberContainerState> {
  state = {};

  componentDidMount() {
    this.updateList();
  }

  componentDidUpdate(prevProps: IUserGroupMemberContainerProps, prevState: IUserGroupMemberContainerState) {
    if (this.props.collectionParams !== prevProps.collectionParams || this.props.userGroup !== prevProps.userGroup) {
      this.updateList();
    }
  }

  componentWillUnmount = () => {
    this.props.clearUserGroupMembers();
  };

  render() {
    return (
      <React.Fragment>
        {this.props.userGroupMembers && (
          <UserGroupMemberList userGroup={this.props.userGroup} userGroupMemberList={this.props.userGroupMembers} onAddMember={this.handleMemberAdd} onUpdateMember={this.handleMemberUpdate} onDeleteMember={this.handleMemberDelete} onPageChange={this.props.updateCollectionParams.onPageChange} />
        )}
      </React.Fragment>
    );
  }

  handleMemberAdd = (member: IUserGroupMember) => {
    this.props
      .addUserGroupMembers(this.props.userGroup.id, [member])
      .track()
      .subscribe(
        // success
        () => this.updateList()
      );
  };

  handleMemberUpdate = (member: IUserGroupMember) => {
    this.props
      .updateUserGroupMembers(this.props.userGroup.id, member)
      .track()
      .subscribe(
        // success
        () => this.updateList()
      );
  };

  handleMemberDelete = (member: IUserGroupMember) => {
    this.props
      .removeUserGroupMembers(this.props.userGroup.id, [member])
      .track()
      .subscribe(
        // success
        () => this.updateList()
      );
  };

  private fetchUserGroupMembers = (params: ICollectionFetchPayload<IUserGroupMemberListFilter>) => {
    this.props.fetchUserGroupMembers(this.props.userGroup.id, params);
  };

  private updateList = () => {
    this.props.onUpdateList(this.fetchUserGroupMembers);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserGroupMemberContainerStateProps => ({
  userGroupMembers: UserGroupBusinessStore.selectors.getUserGroupMemberList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserGroupMemberContainerDispatchProps => ({
  fetchUserGroupMembers: (userGroupId: string, params: ICollectionFetchPayload<IUserGroupMemberListFilter>) => dispatch(UserGroupBusinessStore.actions.fetchUserGroupMemberList(userGroupId, params)),
  addUserGroupMembers: (organizationId: string, data: IUserGroupMemberCreatePayload[]) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.addUserGroupMembers(organizationId, data))),
  updateUserGroupMembers: (organizationId: string, data: IUserGroupMember) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.updateUserGroupMembers(organizationId, data))),
  removeUserGroupMembers: (organizationId: string, data: IUserGroupMember[]) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.removeUserGroupMembers(organizationId, data))),
  clearUserGroupMembers: () => dispatch(UserGroupBusinessStore.actions.clearUserGroupMemberList()),
});

export default connect<IUserGroupMemberContainerStateProps, IUserGroupMemberContainerDispatchProps, IUserGroupMemberContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(UserGroupMemberContainer as any)));

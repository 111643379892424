import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ExternalContentUpdateContainer from '@src/components/externalcontent/update/ExternalContentUpdateContainer';
import ExternalContentViewContainer from '@src/components/externalcontent/view/ExternalContentViewContainer';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------

export interface IExternalContentViewPagePublicProps {

}

interface IExternalContentViewPageStateProps {
  currentUser: IUserInfo;
}
type IExternalContentViewPageProps = IExternalContentViewPagePublicProps & IExternalContentViewPageStateProps & WithRouterProps & IWithRolesOwnProps;

// -- Component
// ----------

const WebinarViewPage = (props: IExternalContentViewPageProps) => {
  const DisplayComponent = props.allowedRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN]) ? ExternalContentUpdateContainer : ExternalContentViewContainer;

  return (
    <React.Fragment>
      <DisplayComponent externalId={props.params.externalId} />
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalContentViewPagePublicProps): IExternalContentViewPageStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

export default withRouter(connect<IExternalContentViewPageStateProps, any, IExternalContentViewPagePublicProps>(mapStateToProps)(withRoles(WebinarViewPage)) as any);

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NameInputModal from '@src/components/course/update/NameInputModal';
import { Button } from 'antd';
import React, { useCallback, useState } from 'react';

// -- Prop types
// ----------

export interface IAddElementLinkOwnProps {
  title: string;
  onSubmit: (name: string) => void;
  className?: string;
}

type IAddElementLinkProps = IAddElementLinkOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component to display a menu item for creating new course elements, and handle name input */
const AddElementLink: React.FC<IAddElementLinkProps> = (props: IAddElementLinkProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = useCallback(() => setIsModalVisible(!isModalVisible), [isModalVisible]);

  const handleNameSubmit = useCallback(
    (newName: string) => {
      props.onSubmit(newName);
      toggleModal();
    },
    [props.onSubmit, toggleModal]
  );

  return (
    <React.Fragment>
      <Button type="primary" icon={<LemonIcon name="plus" />} onClick={() => toggleModal()}>
        {props.title}
      </Button>
      {isModalVisible && <NameInputModal onSubmit={handleNameSubmit} onCancel={toggleModal} title={props.title} />}
    </React.Fragment>
  );
};

export default withLocalize<IAddElementLinkOwnProps>(AddElementLink as any);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICourse } from '@src/model/course/Course';
import { IComputerManagedInstruction } from '@src/model/course/scorm/ComputerManagedInstruction';
import { IShareableContentObject } from '@src/model/course/scorm/ShareableContentObject';
import { Globals } from '@src/service/util/Globals';
import IframeResizer, { IFrameComponent } from 'iframe-resizer-react';
import { get, set } from 'lodash';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IScormContentViewOwnProps {
  course: ICourse;
  lectureCMI: IComputerManagedInstruction;
  selectedLecture?: IShareableContentObject;
  onLectureCMIUpdate?: (lectureCMI: IComputerManagedInstruction) => void;
}

type IScormContentViewProps = IScormContentViewOwnProps & IWithLocalizeOwnProps & WithRouterProps;


const ScormContentView = (props: IScormContentViewProps) => {

  /**
   * We've successfully initialized resizer.
   *
   * If we've initialized resizer adjust some props for resizable content.
   */
  const handleIframeResizerInit = (iframe: IFrameComponent) => { // TODO: handle in one place with  externalContent function

    // remove "unresizable" content styles
    iframe.classList.remove('timun-externalContentView__content--unresizable');

    // scrolling is ommited by default so we'll disable it only for resizable content
    iframe.scrolling = 'no';
  };

  Globals.window.API = (() => { // SCORM 1.2 needs an API object on global window to work normally and communicate with the LMS
    const data: { cmi: IComputerManagedInstruction; } = { cmi: props.lectureCMI };
    return {
      LMSInitialize() {
        return true;
      },
      LMSCommit() {
        props.onLectureCMIUpdate?.(data.cmi);
        return true;
      },
      LMSFinish() {
        props.onLectureCMIUpdate?.(data.cmi);
        return true;
      },
      LMSGetValue(model: string) {
        return get(data, model) || '';
      },
      LMSSetValue(model: string, value: unknown) {
        set(data, model, value);
        return true;
      },
      LMSGetLastError() {
        return '0'; // TODO: error handling?
      },
      LMSGetErrorString(errorCode: any) {
        return 'No error'; // TODO: error handling?
      },
      LMSGetDiagnostic(errorCode: any) {
        return 'No error'; // TODO: error handling?
      },
    };
  })();

  return (props.selectedLecture?.filePath && props.lectureCMI &&
    <IframeResizer  // TODO: handle in one place with  externalContent render
      className="timun-externalContentView__content timun-externalContentView__content--unresizable"
      src={props.selectedLecture.filePath}
      heightCalculationMethod="bodyScroll"
      checkOrigin={false}
      onInit={handleIframeResizerInit}
      scrolling="omit"
    />
  );
};

export default withLocalize<IScormContentViewOwnProps>(withRouter(ScormContentView as any));

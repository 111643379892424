import EnrollmentRequirementCodebookContainer from '@src/components/codebook/EnrollmentRequirementCodebookContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { Empty } from 'antd';
import React from 'react';

interface IAdminEnrollmentRequirementPageOwnProps { }

type IAdminEnrollmentRequirementCodebookPageProps = IWithRolesOwnProps & IWithTenantPropEnabledOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const AdminEnrollmentRequirementCodebookPage = (props: IAdminEnrollmentRequirementCodebookPageProps) => {
  const DisplayComponent = (props.isSuperAdmin() || props.isTenantPropEnabled('codebook_enroll_requirement')) ? <EnrollmentRequirementCodebookContainer /> : <Empty description={props.translate('COMMON.LABEL.NOT_ALLOWED')} />;
  return (
    <React.Fragment>
      {DisplayComponent}
    </React.Fragment>
  );
};

export default withTenantPropEnabled<IAdminEnrollmentRequirementPageOwnProps>(withLocalize(withRoles(AdminEnrollmentRequirementCodebookPage as any)));

import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import LemonImage from '@src/components/common/image/LemonImage';
import { PublicTenantConfigurationBusinessStore } from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';

// -- Prop types
// ----------
export interface IAppSecondaryLogoContainerPublicProps {}
interface IAppSecondaryLogoContainerStateProps {
  tenantConfiguration: ITenantConfiguration;
}
type IAppSecondaryLogoContainerProps = IAppSecondaryLogoContainerPublicProps & IWithLocalizeOwnProps & IAppSecondaryLogoContainerStateProps & IWithPathResolverOwnProps;

// --
// ----- Component
const AppSecondaryLogoContainer: React.FC<IAppSecondaryLogoContainerProps> = (props) => {
  return (
    <React.Fragment>
      {props.tenantConfiguration.configuration?.secondaryLogoFileId ? props.tenantConfiguration.configuration.secondaryLogoFileId && <LemonImage className="lemon-application__secondaryLogoContainer" imagePath={props.resolveApiFilePath(props.tenantConfiguration.configuration.secondaryLogoFileId, true)} /> : null}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAppSecondaryLogoContainerPublicProps): IAppSecondaryLogoContainerStateProps => ({
  tenantConfiguration: PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state),
});

export default connect<IAppSecondaryLogoContainerStateProps, undefined, IAppSecondaryLogoContainerPublicProps>(mapStateToProps)(withLocalize(withPathResolver(AppSecondaryLogoContainer as any)));

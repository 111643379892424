import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import AdditionalCourseListContainer from '@src/components/course/list/additional/AdditionalCourseListContainer';
import CourseViewLectureContent from '@src/components/course/view/CourseViewLectureContent';
import CourseViewSider from '@src/components/course/view/CourseViewSider';
import TagList from '@src/components/tag/TagList';
import { ICourse } from '@src/model/course/Course';
import { ICourseLectureListElement } from '@src/model/course/LectureGroup';
import PublicCourseViewBusinessStore from '@src/service/business/courses/publicCourseViewBusinessStore';
import { Layout, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

const { Header, Sider, Content } = Layout;

// -- Prop types
// ----------

interface IPublicCourseViewContainerOwnProps {
  courseId: string;
}

interface IPublicCourseViewContainerStateProps {
  course: ICourse;
  courseLectureList: ICourseLectureListElement[];
}

interface IPublicCourseViewContainerDispatchProps {
  fetchCourseContent: (courseId: string) => void;
  clearCourseContent: () => void;
  fetchCourseLectureList: (courseId: string) => void;
  clearCourseLectureList: () => void;
}
type IPublicCourseViewContainerProps = IPublicCourseViewContainerOwnProps & IPublicCourseViewContainerStateProps & IPublicCourseViewContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** container for the display of a course and lectures inside */
class PublicCourseViewContainer extends React.Component<IPublicCourseViewContainerProps> {
  componentDidMount = () => {
    this.updateCourseContent();
    this.updateCourseLectureList();
  };

  componentDidUpdate = (prevProps: IPublicCourseViewContainerProps) => {
    if (this.props.courseId !== prevProps.courseId) {
      this.updateCourseContent();
      this.updateCourseLectureList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearCourseContent();
    this.props.clearCourseLectureList();
  };

  render = () => {
    return (
      <AppContent>
        {this.props.course && (
          <Layout>
            <Header className="timun-courseView__sider-courseTitle">
              <HeaderTitle link={'/public/courses'} title={this.props.translate('COMMON.ACTION_BACK')} icon={<LemonIcon name="left" />} />
              <Typography.Title> {this.props.course.title} </Typography.Title>
              <TagList tags={this.props.course.tags || []} closable={false} />
            </Header>
            <Layout>
              <Sider className="timun-courseView__sider" breakpoint="lg" collapsedWidth={0}>
                <CourseViewSider lectureList={this.props.courseLectureList} selectedLecture={'about'} course={this.props.course} showExamInstanceList={false} previewMode={true} canEdit={false} canSeeExamTemplate={false} />
              </Sider>
              <Content className="timun-courseView__content">
                <CourseViewLectureContent title={this.props.translate('COURSE_VIEW.LECTURE_LIST_MENU.COURSE_ABOUT_ITEM_LABEL')} duration={this.props.course.duration} body={this.props.course.content && this.props.course.content.body} coverImageUrl={this.props.course.coverImageUrl} />
              </Content>
            </Layout>
            <AdditionalCourseListContainer course={this.props.course} getPath={this.getPath} />
          </Layout>
        )}
      </AppContent>
    );
  };

  private getPath = (course: ICourse) => {
    return `/public${CourseHelperUtils.getTraineePath(course)}`;
  };

  private updateCourseContent = () => {
    this.props.fetchCourseContent(this.props.courseId);
  };

  private updateCourseLectureList = () => {
    this.props.fetchCourseLectureList(this.props.courseId);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IPublicCourseViewContainerOwnProps): IPublicCourseViewContainerStateProps => ({
  course: PublicCourseViewBusinessStore.selectors.getPublicCourseContent(state),
  courseLectureList: PublicCourseViewBusinessStore.selectors.getPublicCourseLectureList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IPublicCourseViewContainerDispatchProps => ({
  fetchCourseContent: (id: string) => dispatch(PublicCourseViewBusinessStore.actions.fetchPublicCourseContent(id)),
  clearCourseContent: () => dispatch(PublicCourseViewBusinessStore.actions.clearPublicCourseContent()),
  fetchCourseLectureList: (id: string) => dispatch(PublicCourseViewBusinessStore.actions.fetchPublicCourseLectureList(id)),
  clearCourseLectureList: () => dispatch(PublicCourseViewBusinessStore.actions.clearPublicCourseLectureList()),
});

export default connect<IPublicCourseViewContainerStateProps, IPublicCourseViewContainerDispatchProps, IPublicCourseViewContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(PublicCourseViewContainer as any));

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { Select } from 'antd';
import React, { useMemo } from 'react';

// -- Prop types
// ----------
export interface IEducationApplicationStatusSelectorOwnProps {
  value?: EducationApplicationActivityPhaseEnum[];
  onChange?: (newValue?: EducationApplicationActivityPhaseEnum[]) => void;
}

type IEducationApplicationStatusSelectorProps = IEducationApplicationStatusSelectorOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const EducationApplicationStatusSelector: React.FC<IEducationApplicationStatusSelectorProps> = (props) => {
  const options = useMemo(() => {
    return Object.keys(EducationApplicationActivityPhaseEnum).map((item: string) => ({
      value: EducationApplicationActivityPhaseEnum[item as keyof typeof EducationApplicationActivityPhaseEnum],
      key: EducationApplicationActivityPhaseEnum[item as keyof typeof EducationApplicationActivityPhaseEnum],
      dataTestId: `timun-externalEducationApplicationFilter__statusSelect_option_${item}`,
      label: props.translate(`EXTERNAL_EDUCATION_APPLICATION.PHASE.${item}_LABEL`),
    }));
  }, []);

  return (
    <Select
      showArrow={true}
      mode={'multiple'}
      onChange={props.onChange}
      placeholder={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.PICK_STATUS_PLACEHOLDER')}
      allowClear={true}
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      options={options}
      defaultValue={props.value}
      data-test-id="timun-externalEducationApplicationFilter__statusSelect"
    />
  );
};

export default withLocalize<IEducationApplicationStatusSelectorOwnProps>(EducationApplicationStatusSelector as any);

import { Upload } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { UploadProps } from 'antd/lib/upload';
import React from 'react';

import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import { RcFile, UploadChangeParam, UploadFile, UploadListType } from 'antd/lib/upload/interface';

export type IUploadedFile = RcFile;

// -- Prop types
// ----------

export interface IUploadStatus {
  isUploading: boolean;
}

export interface IFileUploadOwnProps<T> {
  children?: (status: IUploadStatus) => React.ReactNode;
  uploadProps?: UploadProps<T>;
  dragger?: boolean;
  onUploadFile?: (file: T) => void;

  dataTestIdPrefix?: string;
}
type IFileUploadProps<T> = IFileUploadOwnProps<T>;

interface IFileUploadState {
  isUploading: boolean;
}

class FileUpload<T> extends React.Component<IFileUploadProps<T>, IFileUploadState> {
  state: IFileUploadState = {
    isUploading: false,
  };

  handleChange = (info: UploadChangeParam<UploadFile<T>>) => {
    if (this.props.uploadProps?.onChange) {
      this.props.uploadProps?.onChange(info);
    }
    FileUploadHelper.handleAntdUploadChange<T>(info, this.props.onUploadFile);

    if (info.file.status === 'done' || info.file.status === 'error') {
      // stop spinner
      this.setState({ isUploading: false });
    }
  };

  beforeUpload = (file: RcFile, FileList: RcFile[]) => {
    if (this.props.uploadProps?.beforeUpload) {
      const fileValid = this.props.uploadProps?.beforeUpload(file, FileList);
      this.setState({ isUploading: !!fileValid });
      return fileValid;
    }

    this.setState({ isUploading: true });
    return true;
  };

  getUploadProps = () => {
    if (this.props.uploadProps) {
      const { onChange, beforeUpload, ...uploadProps } = this.props.uploadProps;
      return uploadProps;
    }
    return {};
  };

  defaultCustomRequest: UploadProps<T>['customRequest'] = (componentsData) => {
    // TODO: should we check if this really is File or smtng else?
    const file = componentsData.file as File;
    const fileName = file.name;

    // file upload
    FileUploadHelper.uploadFile(file, fileName, componentsData.onProgress).subscribe(componentsData.onSuccess, componentsData.onError);
  };

  render() {
    const UploadType = !this.props.dragger ? Upload : Upload.Dragger;

    const uploadProps: UploadProps<T> = {
      iconRender: (file: UploadFile, listType?: UploadListType) => FileUtils.isFilePreviewable(file.type) && <LemonIcon className="timun-fileUpload__icon" onClick={() => this.props.uploadProps?.onPreview?.(file)} name="eye" />,
      showUploadList: false,
      multiple: true,
      onChange: this.handleChange,
      beforeUpload: this.beforeUpload,

      customRequest: this.props.uploadProps?.customRequest ?? this.defaultCustomRequest,

      ...this.getUploadProps(),
    };

    return (
      <UploadType {...uploadProps} data-={true} test-id={this.props.dataTestIdPrefix ? `${this.props.dataTestIdPrefix}_upload` : undefined}>
        {this.props.children != null ? this.props.children({ isUploading: this.state.isUploading }) : null}
      </UploadType>
    );
  }
}

export default FileUpload;

import { Form } from '@ant-design/compatible';
import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IActivityParticipant } from '@src/model/activity/ActivityParticipant';
import { IUserInfo } from '@src/model/user/User';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Button, Modal, Typography } from 'antd';
import React from 'react';

interface ICoordinatorPickerModalViewOwnProps {
  coordinatorList: IUserInfo[];
  currentCoordinator?: IActivityParticipant;
  onSubmit: (id: string) => void;
  onSearch: (value: string) => void;
}
type ICoordinatorPickerModalViewProps = ICoordinatorPickerModalViewOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface ICoordinatorPickerModalViewState {
  isModalVisible: boolean;
}

class CoordinatorPickerModalView extends React.Component<ICoordinatorPickerModalViewProps, ICoordinatorPickerModalViewState> {
  state: ICoordinatorPickerModalViewState = {
    isModalVisible: false,
  };

  render = () => {
    const { getFieldDecorator } = this.props.form;
    const label = this.props.currentCoordinator ? 'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_CHANGE_LABEL' : 'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_ADD_LABEL';
    const pickerItems = this.props.coordinatorList.map(this.mapToItem) || [];

    return <React.Fragment>
      <Button onClick={this.handleOpenModal} data-test-id="timun-coordinatorPickerView__openModalButton">{this.props.translate(label)}</Button>
      {this.state.isModalVisible && <Modal title={this.props.translate(label)} visible={true} maskClosable={false} onOk={this.handleOk} onCancel={this.handleCloseModal}>
        <Typography.Paragraph> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_LABEL')} </Typography.Paragraph>
        <Form.Item>
          {
            getFieldDecorator('coordinator', {
              initialValue: this.props.currentCoordinator ? this.mapInitialCoordinator(this.props.currentCoordinator) : undefined,
              rules: [{ required: true, message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_VALIDATION_MESSAGE') }],
            })(<DataPicker
              items={pickerItems}
              disabled={false}
              onSearch={this.props.onSearch}
              placeholderSuffix={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_SEARCH_PLACEHOLDER')}
              dataTestIdPrefix="timun-coordinatorPicker"
            />)
          }
        </Form.Item>
      </Modal>}
    </React.Fragment>;
  };

  handleOk = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const id = values.coordinator.data;

        if (id !== this.props.currentCoordinator?.userId) {
          this.props.onSubmit(id);
        }
        this.handleCloseModal();
      }
    });
  };

  handleOpenModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalVisible: false });
  };

  private mapInitialCoordinator = (user: IActivityParticipant): IDataPickerItem => {
    return {
      value: user.userId,
      name: `${user.firstName} ${user.lastName}`,
      data: user.userId,
    };
  };

  private mapToItem = (user: IUserInfo): IDataPickerItem => {
    return {
      value: user.id,
      name: `${user.firstName} ${user.lastName}`,
      data: user.id,
    };
  };
}

const CoordinatorPickerModalViewFormWrapper = Form.create<ICoordinatorPickerModalViewProps>()(CoordinatorPickerModalView);

export default withLocalize<ICoordinatorPickerModalViewOwnProps>(CoordinatorPickerModalViewFormWrapper as any);

import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { ICounty } from '@src/model/location/County';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';

// -- Prop types
// ----------

export interface ICountyDataPickerOwnProps {
  value?: ICounty;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (newValue?: ICounty) => void;
}

export interface ICountyDataPickerStateProps {
  countyList: ICollectionData<ICounty>;
}

export interface ICountyDataPickerDispatchProps {}
type ICountyDataPickerProps = ICountyDataPickerOwnProps & ICountyDataPickerStateProps & ICountyDataPickerDispatchProps;

// -- Component
// ----------

/** Describe your component ... */
const CountyDataPicker: React.FC<ICountyDataPickerProps> = (props: ICountyDataPickerProps) => {
  const handleDataPickerChange = useCallback(
    (value?: IDataPickerItem) => {
      props.onChange?.(value?.data);
    },
    [props.onChange]
  );

  /** Map entity ref list to data picker item list. */
  const mapToItem = useCallback(
    (county: ICounty): IDataPickerItem => ({
      value: county.id,
      name: county.name,
      data: county,
    }),
    []
  );

  const pickerValue = useMemo(() => props.value && mapToItem(props.value), [props.value, mapToItem]);
  const pickerItems = useMemo(() => props.countyList.content?.map(mapToItem) || [], [props.countyList.content, mapToItem]);

  return <DataPicker placeholderSuffix={props.placeholder} value={pickerValue} items={pickerItems} disabled={props.disabled} onChange={handleDataPickerChange} dataTestIdPrefix="timun-countyDataPicker" />;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICountyDataPickerOwnProps): ICountyDataPickerStateProps => ({
  countyList: CollectionBusinessStore.selectors.getCollection(state, 'County'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICountyDataPickerDispatchProps => ({});

export default connect<ICountyDataPickerStateProps, ICountyDataPickerDispatchProps, ICountyDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(CountyDataPicker as any);

import React, { useCallback } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationInfoView from '@src/components/externalEducationApplication/view/ExternalEducationApplicationInfoView';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { IUserInfo } from '@src/model/user/User';
import { IFileListsByType } from '@src/service/business/files/util';
import { Button, Col, Row } from 'antd';

// -- Prop types
// ----------

export interface IExternalEducationApplicationConsultationTraineeViewOwnProps {
  timeline: IExternalEducationApplicationTimelineActivity[];
  externalEducationApplication: IExternalEducationApplication;
  traineeUser?: IUserInfo;
  applicationFiles: IFileListsByType;
  canParticipate?: boolean;
  onPhaseChange?: (phase: EducationApplicationActivityPhaseEnum) => void;
}
type IExternalEducationApplicationConsultationTraineeViewProps = IExternalEducationApplicationConsultationTraineeViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationApplicationConsultationTraineeView: React.FC<IExternalEducationApplicationConsultationTraineeViewProps> = (props: IExternalEducationApplicationConsultationTraineeViewProps) => {
  const handleConsultationCancel = useCallback(() => props.onPhaseChange?.(EducationApplicationActivityPhaseEnum.CONSULTATION_TERMINATED), []);
  const isApplicationInPhases = useCallback(
    (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
      return EducationApplicationHelperUtils.isInPhases(phases, props.externalEducationApplication.activity);
    },
    [props.externalEducationApplication.activity]
  );
  return (
    <React.Fragment>
      <ExternalEducationApplicationInfoView externalEducationApplication={props.externalEducationApplication} timeline={props.timeline} isCurrentUserTrainee={true} traineeUser={props.traineeUser} applicationFiles={props.applicationFiles} panelsActive={true} />

      {isApplicationInPhases(['CONSULTATION_REQUESTED', 'CONSULTATION_STARTED']) && (
        <Row gutter={8} justify="end">
          <Col>
            {props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE_CONSULTATION.CANCEL_CONSULTATION_TEXT')}
            &nbsp;
            <Button onClick={handleConsultationCancel}>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE_CONSULTATION.CANCEL_CONSULTATION_BUTTON_LABEL')} </Button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default withLocalize<IExternalEducationApplicationConsultationTraineeViewOwnProps>(ExternalEducationApplicationConsultationTraineeView as any);

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import CourseAdministrationViewSider from '@src/components/course/view/CourseAdministrationViewSider';
import { ICourse } from '@src/model/course/Course';
import courseViewBusinessStore from '@src/service/business/courses/courseViewBusinessStore';
import { Layout, Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const { Header, Sider, Content } = Layout;

// -- Prop types
// ----------

export interface ICourseAdministrationMenuLayoutContainerOwnProps {
  administrationPathId: string;
  children: React.ReactNode;
  courseId: string;
}
export interface ICourseAdministrationMenuLayoutContainerStateProps {
  course: ICourse;
}
export interface ICourseAdministrationMenuLayoutContainerDispatchProps {
  fetchCourseContent: () => void;
}
type ICourseAdministrationMenuLayoutContainerProps = ICourseAdministrationMenuLayoutContainerOwnProps & IWithLocalizeOwnProps & ICourseAdministrationMenuLayoutContainerStateProps & ICourseAdministrationMenuLayoutContainerDispatchProps;

// -- Component
// ----------

/** Course administration page layout with Menu and Content display */
const CourseAdministrationMenuLayoutContainer = (props: ICourseAdministrationMenuLayoutContainerProps) => {
  useEffect(() => {
    props.fetchCourseContent();
  }, [props.courseId]);

  return (
    <Layout>
      <Header className="timun-courseView__administrationSider-courseTitle timun-wrapper__gutterBox--vertical">
        <HeaderTitle link={`/course/administration/${props.courseId}/about`} title={props.translate('COURSE_ADMINISTRATION.VIEW.RETURN_TO_COURSE_LABEL')} icon={<LemonIcon name="left" />} />
        {props.course && <Typography.Title>{props.translate('COURSE_ADMINISTRATION.VIEW.COURSE_TITLE_LABEL', { courseTitle: props.course.title })} </Typography.Title>}
      </Header>
      <Layout>
        <Sider className="timun-courseView__administrationSider" breakpoint="lg" collapsedWidth={0}>
          {props.course && <CourseAdministrationViewSider administrationPathId={props.administrationPathId} course={props.course} />}
        </Sider>
        <Content className="timun-siderMenu__content">{props.children}</Content>
      </Layout>
    </Layout>
  );
};
// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseAdministrationMenuLayoutContainerOwnProps): ICourseAdministrationMenuLayoutContainerStateProps => ({
  course: courseViewBusinessStore.selectors.getCourseContent(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: ICourseAdministrationMenuLayoutContainerOwnProps): ICourseAdministrationMenuLayoutContainerDispatchProps => ({
  fetchCourseContent: () => dispatch(courseViewBusinessStore.actions.fetchCourseContent({ id: ownProps.courseId })),
});
export default connect<ICourseAdministrationMenuLayoutContainerStateProps, ICourseAdministrationMenuLayoutContainerDispatchProps, ICourseAdministrationMenuLayoutContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(CourseAdministrationMenuLayoutContainer as any));

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ReportListModalView from '@src/components/report/list/ReportListModalView';
import { IReport, IReportTypeEnum } from '@src/model/report/Report';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import courseViewBusinessStore, { ICourseReportListFilter } from '@src/service/business/courses/courseViewBusinessStore';
import ReportsBusinessStore, { IReportCreatePayload, IReportListFilter } from '@src/service/business/reports/reportsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

const VIEW_NAME = '@@COURSE_REPORT_LIST';

export interface ICourseReportListContainerOwnProps {
  courseId: string;
}

export interface ICourseReportListContainerStateProps {
  courseReportList: ICollectionData<IReport>;
}

export interface ICourseReportListContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  createReport: (data: IReportCreatePayload) => ITrackableAction;
  deleteReport: (data: string) => ITrackableAction;
  fetchCourseReportList: (collectionParams: ICollectionFetchPayload<IReportListFilter>) => void;
  clearCourseReportList: () => void;
}

type ICourseReportListContainerProps = ICourseReportListContainerDispatchProps & ICourseReportListContainerStateProps & ICourseReportListContainerOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const CourseReportListContainer: React.FC<ICourseReportListContainerProps> = (props: ICourseReportListContainerProps) => {
  const [, updateCollectionParams, onUpdateList] = useCollectionState<ICourseReportListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchCourseReportList,
    initialValues: { sort: AppConfigService.getValue('api.sorting.byDate') },
  });

  const handleDeleteReport = useCallback(
    (data: IReport) => {
      props
        .deleteReport(data.id)
        .track()
        .subscribe(
          // success
          () => onUpdateList()
        );
    },
    [onUpdateList]
  );

  const handleCreateReport = useCallback(() => {
    props
      .createReport({ reportType: IReportTypeEnum.COURSE, filters: { courseId: props.courseId } })
      .track()
      .subscribe(
        // success
        () => {
          props.reportMessage({ message: props.translate('REPORTS.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
          updateCollectionParams.onPageChange(0);
        }
      );
  }, [updateCollectionParams.onPageChange]);

  return <ReportListModalView courseId={props.courseId} reportList={props.courseReportList} onCreateReport={handleCreateReport} onDeleteReport={handleDeleteReport} onSortChange={updateCollectionParams.onSortChange} onPageChange={updateCollectionParams.onPageChange} onReload={onUpdateList} />;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): ICourseReportListContainerStateProps => ({
  courseReportList: courseViewBusinessStore.selectors.getCourseReportList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: ICourseReportListContainerOwnProps): ICourseReportListContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  createReport: (data: IReportCreatePayload) => dispatch(createTrackableAction(ReportsBusinessStore.actions.createReport(data))),
  deleteReport: (data: string) => dispatch(createTrackableAction(ReportsBusinessStore.actions.deleteReport({ id: data }))),
  fetchCourseReportList: (collectionParams: ICollectionFetchPayload<ICourseReportListFilter>) => dispatch(courseViewBusinessStore.actions.fetchCourseReportList(ownProps.courseId, collectionParams)),
  clearCourseReportList: () => dispatch(courseViewBusinessStore.actions.clearCourseReportList()),
});

export default connect<ICourseReportListContainerStateProps, ICourseReportListContainerDispatchProps, ICourseReportListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(CourseReportListContainer as any));

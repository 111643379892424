import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import UserDetailsPanel from '@src/components/user/view/UserDetailsPanel';
import UserViewTabs from '@src/components/user/view/UserViewTabs';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import RoleUtils from '@src/service/util/role/RoleUtils';

// --
// ----- Prop types
interface IUserViewOwnProps {
  openedTab: string;
  currentUser: IUserInfo;
  user: IUserInfo;
  workPositionList: IWorkPosition[];
  isEditingUser?: boolean;
  onUserUpdate: () => void;
  onAvatarSubmit: (data: IFile) => void;
  onUserSubmit: (data: IUserInfo) => void;
  onUserDelete: (data: IUserInfo) => void;
  onTabChange?: (key: string) => void;
  onNotzUpdate?: () => void;
  setUserEdit?: (visible: boolean) => void;
}

type IUserViewProps = IUserViewOwnProps & WithRouterProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps & IWithRolesOwnProps;
// --
// ----- State types
interface IUserViewState {}

// --
// ----- Component
class UserView extends React.Component<IUserViewProps, IUserViewState> {
  state: IUserViewState = {};

  render = () => {
    const userId = this.props.user.id;
    const isOwnProfile = this.props.currentUser.id === userId;
    const isAdmin = RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN], this.props.currentUser.roles);
    const isCoordinator = RoleUtils.allowedRoles([UserRoleEnum.COORDINATOR], this.props.currentUser.roles);
    return (
      <React.Fragment>
        {this.props.user && (
          <AppContent>
            <UserDetailsPanel
              user={this.props.user}
              workPositionList={this.props.workPositionList}
              onAvatarSubmit={this.props.onAvatarSubmit}
              onUserSubmit={this.props.onUserSubmit}
              onUserUpdate={this.props.onUserUpdate}
              onUserDelete={this.props.onUserDelete}
              isOwnProfile={isOwnProfile}
              isEditingUser={this.props.isEditingUser}
              setUserEdit={this.props.setUserEdit}
            />

            <UserViewTabs
              userId={userId}
              user={this.props.user}
              showActivitiesTab={isOwnProfile || isAdmin}
              showDocumentsTab={isOwnProfile || isAdmin || isCoordinator}
              showEducationApplicationTab={isOwnProfile || isAdmin || isCoordinator}
              showSkillTab={this.props.isTenantPropEnabled(['admin_skills', 'skillsgap'])}
              showOrganizationTab={this.props.allowedRoles([UserRoleEnum.ORGANIZATION_MEMBER]) && isOwnProfile}
              showExternalEducationTemplatesTab={isOwnProfile && isAdmin}
              canAddNote={isOwnProfile || isAdmin}
              canSetPrivateNote={isAdmin}
              openedTab={this.props.openedTab}
              workPositionList={this.props.workPositionList}
              isOwnProfile={isOwnProfile}
              onTabChange={this.props.onTabChange}
              onNotzUpdate={this.props.onNotzUpdate}
            />
          </AppContent>
        )}
      </React.Fragment>
    );
  };
}

export default withLocalize(withRouter<IUserViewOwnProps>(withTenantPropEnabled(withRoles(UserView) as any)));

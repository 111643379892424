import FilePreviewModal from '@src/components/common/file/FilePreviewModal';
import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import { IFile } from '@src/model/file/File';
import { ITimunFile } from '@src/service/business/files/util';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Col, Row, Tooltip } from 'antd';
import React from 'react';

interface IFileItemCardOwnProps<T> {
  file: ITimunFile<T>;
  canRemove?: boolean;
  onRemove?: (file: ITimunFile<T>) => void;
}
type IFileItemCardProps<T> = IFileItemCardOwnProps<T>;

interface IFileItemCardState<T> {
  fileToDisplay?: ITimunFile<T>;
}

class FileItemCard<T extends IFile> extends React.Component<IFileItemCardProps<T>, IFileItemCardState<T>> {
  state: IFileItemCardState<T> = {
    fileToDisplay: undefined,
  };

  render = () => {
    return <React.Fragment>
      <ViewSectionContentItem
        className="timun-userViewSection__file"
        icon={<LemonIcon name="paperClip" size="xlarge" />}
        title={this.props.file.name}
        description={this.props.file.response && 'type' in this.props.file.response ? LocalizeService.translate(`FILES.TYPE_ENUM.LABEL.${this.props.file.response?.type}`) : undefined}
        actions={this.renderFileActions(this.props.file)}
      />
      {this.state.fileToDisplay && <FilePreviewModal closeModal={this.handleClosePreview} record={this.state.fileToDisplay} />}
    </React.Fragment>;
  };

  renderFileActions = (file: ITimunFile<T>) => {

    return <Row gutter={[8, 8]} justify="center">
      <Col>
        <Tooltip title={LocalizeService.translate('COMMON.ACTION_DOWNLOAD')}>
          <Button href={file.url}>
            <LemonIcon name="download" size="small" />
          </Button>
        </Tooltip>
      </Col>

      {FileUtils.isFilePreviewable(file.type) &&
        <Col>
          <Tooltip title={LocalizeService.translate('COMMON.ACTION_PREVIEW')}>
            <Button onClick={() => this.toggleImagePreviewVisible(file)}>
              <LemonIcon name="eye" size="small" />
            </Button>
          </Tooltip>
        </Col>
      }

      {this.props.canRemove &&
        <Col>
          <Tooltip title={LocalizeService.translate('COMMON.ACTION_DELETE')}>
            <Button onClick={() => this.props.onRemove?.(file)}>
              <LemonIcon name="delete" size="small" />
            </Button>
          </Tooltip>
        </Col>
      }
    </Row>;
  };

  toggleImagePreviewVisible = (previewFile: ITimunFile<T>) => {
    this.setState({ fileToDisplay: previewFile });
  };

  handleClosePreview = () => {
    this.setState({
      fileToDisplay: undefined,
    });
  };
}

export default FileItemCard;


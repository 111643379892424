import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';


// -- Const
// ----------

// -- Prop types
// ----------

export interface IHomeViewOwnProps {
  label?: string;
}
export interface IHomeViewStateProps {
}
export interface IHomeViewDispatchProps {
}
type IHomeViewProps = IHomeViewOwnProps & IHomeViewStateProps & IHomeViewDispatchProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const HomeView = (props: IHomeViewProps) => {


  return (
    <React.Fragment >
      {props.label}
    </React.Fragment>
  );

};


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IHomeViewOwnProps): IHomeViewStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IHomeViewDispatchProps => ({
});


export default connect<IHomeViewStateProps, IHomeViewDispatchProps, IHomeViewOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(HomeView as any));

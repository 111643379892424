import React from 'react';
import { connect } from 'react-redux';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import TraineeStartedCourseListView from '@src/components/course/list/catalog/TraineeStartedCourseListView';
import { CourseCompletionStatusEnum, ICourse } from '@src/model/course/Course';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';

// -- Const
// ----------
const VIEW_NAME = '@@TRAINEE_STARTED_COURSE_LIST';

// -- Prop types
// ----------

export interface ITraineeStartedCourseListContainerOwnProps {
}
export interface ITraineeStartedCourseListContainerStateProps {
  courseList: ICollectionData<ICourse>;
}
export interface ITraineeStartedCourseListContainerDispatchProps {
  fetchCourseList: (params: ICollectionFetchPayload<ICourseListFilter>) => void;
}
type ITraineeStartedCourseListContainerProps = ITraineeStartedCourseListContainerOwnProps & ITraineeStartedCourseListContainerStateProps & ITraineeStartedCourseListContainerDispatchProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Diplay trainee in progress course list */
const TraineeStartedCourseListContainer = (props: ITraineeStartedCourseListContainerProps) => {

  const initialCollectionValues: ICollectionFetchPayload<ICourseListFilter> = { filter: { assignee: props.currentUser.id, completionStatus: CourseCompletionStatusEnum.IN_PROGRESS } };

  // tslint:disable-next-line: no-empty-destructuring
  const [] = useCollectionState<ICourseListFilter>({
    viewName: VIEW_NAME,
    initialValues: initialCollectionValues,
    updateFn: props.fetchCourseList,
  }, []);


  return (
    <React.Fragment>
      {props.courseList && <TraineeStartedCourseListView courseList={props.courseList} />}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): ITraineeStartedCourseListContainerStateProps => ({
  courseList: CourseListBusinessStore.selectors.getCourseList(state, VIEW_NAME),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ITraineeStartedCourseListContainerDispatchProps => ({
  fetchCourseList: (params: ICollectionFetchPayload<ICourseListFilter>) => dispatch(CourseListBusinessStore.actions.fetchCourseList(params, VIEW_NAME)),
});

export default connect<ITraineeStartedCourseListContainerStateProps, ITraineeStartedCourseListContainerDispatchProps, ITraineeStartedCourseListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRoles(TraineeStartedCourseListContainer as any));

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExternalEducationTemplateTimelineUIHelper } from '@src/components/externaleducation/common/ExternalEducationTemplateTimelineUIHelper';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { IExternalEducationTemplateTimelineActivity } from '@src/model/externalEducationTemplate/ExternalEducationTemplateTimelineActivity';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { Card, Typography } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------

// -- Prop types
// ----------
export interface IExternalEducationTemplateTimelineViewOwnProps {
  timeline: IExternalEducationTemplateTimelineActivity[];
  id: string;
  canCreateNotes: boolean;
  onCreateNote?: () => void;
}

type IExternalEducationTemplateTimelineViewProps = IExternalEducationTemplateTimelineViewOwnProps & IWithLocalizeOwnProps & WithRouterProps;

/** Display activity history text with date list */
const ExternalEducationTemplateTimelineView: React.FC<IExternalEducationTemplateTimelineViewProps> = (props: IExternalEducationTemplateTimelineViewProps) => {

  return <div className="timun-externalEducationTemplateTimeline__content">
    {props.timeline.map((activity) => {
      return <Card key={activity.content.id} className="timun-externalEducationTemplateTimeline__card">
        <Typography.Title level={4}>{ExternalEducationTemplateTimelineUIHelper.getTimelineItemTitle(activity)}</Typography.Title>
        {ExternalEducationTemplateTimelineUIHelper.isTimelineItemNote(activity) && <div dangerouslySetInnerHTML={{ __html: ExternalEducationTemplateTimelineUIHelper.getTimelineItemNoteText(activity) }} />}
        <span>{ExternalEducationTemplateTimelineUIHelper.getTimelineItemDateTime(activity)}</span>
      </Card>;
    })}
    {props.canCreateNotes && <NoteCreateContainer onCreateNote={props.onCreateNote} objectId={props.id} objectTypeId={NoteObjectTypeEnum.EXTERNAL_EDUCATION_TEMPLATE} />}
  </div>;
};

export default withLocalize(withRouter<IExternalEducationTemplateTimelineViewOwnProps>(ExternalEducationTemplateTimelineView as any));

import { ICollectionResponse } from '@src/service/api/model/apiEvent';
import { ICollectionFetchPayload, IKeyDataPayload, IPayloadAction } from '@src/service/business/common/types';
import { trackAction } from '@src/service/util/observable/operators';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

/** Operator that trackAction and map fetch results as key response object. */
export const mapResponseDataByKeyOperator = <T>(
  action: IPayloadAction<IKeyDataPayload<ICollectionFetchPayload<T>>>
): OperatorFunction<
  ICollectionResponse<any, any>,
  {
    response: ICollectionResponse<any, any>;
    key?: string;
  }
> => {
  const key = action.payload.key;
  return (
    trackAction(action),
    map((response: ICollectionResponse<any, any>) => {
      return { response, key };
    })
  );
};

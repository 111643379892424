import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationApplicationBaseInfo } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import OrganizationEducationApplicationList from '@src/components/organization/list/OrganizationEducationApplicationList';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import OrganizationEducationApplicationListFilter from '@src/components/organization/list/OrganizationEducationApplicationListFilter';
import { ICollectionData } from '@src/service/business/common/types';
import { IOrganizationEducationApplicationRefListFilter } from '@src/service/business/organization/OrganizationBusinessStore';
import OrganizationPayementRequestModal from '@src/components/organization/form/OrganizationPayementRequestModal';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';

// -- Prop types
// ----------

export interface IOrganizationEducationApplicationListViewOwnProps {
  externalEducationApplicationRefList: ICollectionData<IExternalEducationApplicationBaseInfo>;
  filter?: IOrganizationEducationApplicationRefListFilter;
  organizationId?: string;
  onPageChange?: (page: number, pageSize?: number) => void;
  onFilterUpdate?: (partialFilter?: Partial<IOrganizationEducationApplicationRefListFilter>) => void;
  onFilterChange?: (filter?: IOrganizationEducationApplicationRefListFilter) => void;
  additionalCols?: () => ITimunDataTableCol<IExternalEducationApplicationBaseInfo>[];
  onPaymentRequest?: (externalEducationApplication: IExternalEducationApplicationBaseInfo) => void;
}
type IOrganizationEducationApplicationListViewProps = IOrganizationEducationApplicationListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const OrganizationEducationApplicationListView: React.FC<IOrganizationEducationApplicationListViewProps> = (props: IOrganizationEducationApplicationListViewProps) => {
  const [externalEducationBaseInfoState, toggleOrganizationPaymentRequestModals] = useEntityModalsState<IExternalEducationApplicationBaseInfo>();
  const handlePaymentRequest = (externalEducationApplication: IExternalEducationApplicationBaseInfo) => {
    props.onPaymentRequest?.(externalEducationApplication);
    toggleOrganizationPaymentRequestModals.onCloseEntity();
  };
  return (
    <React.Fragment>
      <div className="timun-wrapper__gutterBox--vertical">
        <OrganizationEducationApplicationListFilter filter={props.filter} onSubmit={props.onFilterChange} organizationId={props.organizationId} />
      </div>
      <OrganizationEducationApplicationList externalEducationApplicationRefList={props.externalEducationApplicationRefList} onPageChange={props.onPageChange} onEducationApplicationSelect={toggleOrganizationPaymentRequestModals.onOpenEntity} />

      {externalEducationBaseInfoState.isEntityModalVisible && externalEducationBaseInfoState.selectedEntity && <OrganizationPayementRequestModal value={externalEducationBaseInfoState.selectedEntity} onSubmit={handlePaymentRequest} onCancel={toggleOrganizationPaymentRequestModals.onCloseEntity} />}
    </React.Fragment>
  );
};

export default withLocalize<IOrganizationEducationApplicationListViewOwnProps>(OrganizationEducationApplicationListView as any);

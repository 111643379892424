import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SkillBusinessStore, { ISkillListFilter } from '@src/service/business/skillgroup/SkillBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Select } from 'antd';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

// -- Prop types
// ----------

export interface ISkillListPickerOwnProps {
  onChange?: (newValue?: ISkill[]) => void;
}

export interface ISkillListPickerStateProps {}

export interface ISkillListPickerDispatchProps {
  fetchSkillList: (params: ICollectionFetchPayload<ISkillListFilter>) => ITrackableAction;
}
type ISkillListPickerProps = ISkillListPickerOwnProps & ISkillListPickerStateProps & ISkillListPickerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const SkillListPicker: React.FC<ISkillListPickerProps> = (props: ISkillListPickerProps) => {
  const [skillList, setSkillList] = useState<ISkill[]>();

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = (filter: ISkillListFilter = {}) => {
    props
      .fetchSkillList({
        filter,
        page: 0,
        size: AppConfigService.getValue('api.paging.maxPageSize'),
        sort: [],
      })
      .track()
      .subscribe((data: ICollectionData<ISkill>) => setSkillList(data.content));
  };

  const options = useMemo(() => skillList?.map((item) => ({ value: item.id, key: item.id, data: item, label: item.title })), [skillList]);

  return <Select defaultValue={skillList} options={options} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} placeholder={props.translate('USER_FORM.SKILL_PLACEHOLDER')} mode="multiple" allowClear={true} onChange={props.onChange} />;
};
// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillListPickerOwnProps): ISkillListPickerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISkillListPickerDispatchProps => ({
  fetchSkillList: (params: ICollectionFetchPayload<ISkillListFilter>) => dispatch(createTrackableAction(SkillBusinessStore.actions.fetchSkillPickerList(params))),
});

export default withLocalize(connect<ISkillListPickerStateProps, ISkillListPickerDispatchProps, ISkillListPickerOwnProps>(mapStateToProps, mapDispatchToProps)(SkillListPicker as any));

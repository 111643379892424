import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import OrganizationListView from '@src/components/organization/list/OrganizationListView';
import { IOrganization } from '@src/model/organization/Organization';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import OrganizationListBusinessStore, { IOrganizationListFilter } from '@src/service/business/organization/OrganizationListBusinessStore';
import React from 'react';
import { connect } from 'react-redux';

const VIEW_NAME = '@@USER_ORGANIZATION_MEMBERSHIP_LIST';

export interface IUserOrganizationMembershipContainerOwnProps {
  userId: string;
}

export interface IUserOrganizationMembershipContainerStateProps {
  organizationList: ICollectionData<IOrganization>;
}

export interface IUserOrganizationMembershipContainerDispatchProps {
  fetchOrganizationList: (payload: ICollectionFetchPayload<IOrganizationListFilter>) => void;
  clearList: () => void;
}

type IUserOrganizationMembershipContainerProps = IUserOrganizationMembershipContainerOwnProps & IUserOrganizationMembershipContainerStateProps & IUserOrganizationMembershipContainerDispatchProps;

const UserOrganizationMembershipContainer = (props: IUserOrganizationMembershipContainerProps) => {

  const handleUpdate = () => {
    onUpdateList(props.fetchOrganizationList, { ...collectionParams.filter, member: props.userId });
  };

  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IOrganizationListFilter>({
    viewName: VIEW_NAME,
    updateFn: handleUpdate,
  }, [props.userId]);

  return <React.Fragment>
    {props.organizationList &&
      <OrganizationListView
        organizationList={props.organizationList}
        filter={collectionParams.filter}
        onFilterChange={updateCollectionParams.onFilterSet}
        onPageChange={updateCollectionParams.onPageChange}
        canEdit={false}
        canDelete={false}
      />
    }
  </React.Fragment>;
};

const mapStateToProps = (state: any): IUserOrganizationMembershipContainerStateProps => ({
  organizationList: OrganizationListBusinessStore.selectors.getOrganizationList(state),
});

const mapDispatchToProps = (dispatch: any): IUserOrganizationMembershipContainerDispatchProps => ({
  fetchOrganizationList: (payload: ICollectionFetchPayload<IOrganizationListFilter>) => dispatch(OrganizationListBusinessStore.actions.fetchOrganizationList(payload)),
  clearList: () => dispatch(OrganizationListBusinessStore.actions.clearOrganizationList()),
});

export default connect<IUserOrganizationMembershipContainerStateProps, IUserOrganizationMembershipContainerDispatchProps, IUserOrganizationMembershipContainerOwnProps>(mapStateToProps, mapDispatchToProps)(UserOrganizationMembershipContainer as any);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationStatusSelector from '@src/components/externalEducationApplication/filter/common/EducationApplicationStatusSelector';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { Button, Col, Form, Row } from 'antd';
import React, { useCallback } from 'react';

// -- Prop types
// ----------
export interface IEducationApplicationStatusFilterOwnProps {
  listFilter?: IExternalEducationApplicationListFilter;
  onFilterChange?: (filter: IExternalEducationApplicationListFilter) => void;
}
type IEducationApplicationStatusFilterProps = IEducationApplicationStatusFilterOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
const EducationApplicationStatusFilter: React.FC<IEducationApplicationStatusFilterProps> = (props: IEducationApplicationStatusFilterProps) => {
  const [form] = Form.useForm<IExternalEducationApplicationListFilter>();

  const handleSubmit = useCallback((values: IExternalEducationApplicationListFilter) => props.onFilterChange?.({ phases: values.phases }), [props.onFilterChange]);

  return (
    <Form<IExternalEducationApplicationListFilter> className="timun-educationApplicationFilter__content" name="externalEducationStatusListFilter" form={form} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={8}>
        <Col xs={24} sm={12} md={6}>
          <Form.Item name="phase" label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.EDUCATION_APPLICATION_STATUS_LABEL')}>
            <EducationApplicationStatusSelector />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col>
          <Button onClick={form.submit}>{props.translate('COMMON.ACTION_FILTER')}</Button>
        </Col>
      </Row>
    </Form>
  );
};
export default withLocalize<IEducationApplicationStatusFilterOwnProps>(EducationApplicationStatusFilter as any);

import IIdTitleRef from '@src/model/common/IdTitleRef';
import { ICourseContent } from '@src/model/course/Content';
import { ICourseContentType } from '@src/model/course/ContentType';
import { ICourseExam } from '@src/model/course/CourseExam';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ILecture } from '@src/model/course/Lecture';
import { IFile } from '@src/model/file/File';
import { ITag } from '@src/model/tag/Tag';
import { IUserDetails } from '@src/model/user/UserDetails';

export interface ICourse {
  id: string;
  title: string;
  certificate: IFile;
  courseGroups: ICourseGroup[];
  content?: ICourseContent;
  coverImageUrl: string;
  status: ICourseStatus;
  createdBy: IUserDetails;
  createdAt: string;
  exam?: ICourseExam;
  progress?: ICourseProgress;
  additionalData: IAdditionalData;
  tags?: ITag[];
  visibility: ICourseVisibility;
  courseContentType: ICourseContentType;
  duration?: number;
  settings?: ICourseSettings;
}

export interface ICourseVisibility {
  id: CourseVisibilityEnum;
  name: string;
  description: string;
}

export enum CourseVisibilityEnum {
  PUBLIC = '1',
  LIMITED = '2',
  PRIVATE = '3',
}

export interface ICourseStatus {
  id: CourseStatusEnum;
  description: string;
  name: string;
}

export enum CourseStatusEnum {
  IN_CREATION = '1',
  CREATION_FINISHED = '2',
  PUBLISHED = '3',
  ARCHIVED = '4',
}

export interface ILectureProgress {
  id: string;
  startDateTime: string;
  finishDateTime?: string;
  lecture: ILecture;
}

export interface ICourseProgress {
  completionStatus: ICourseCompletionStatus;
  currentLecture: ILecture;
  completedLessonsNumber: number;
  lessonsNumber: number;
  assignedDateTime: string;
  finishDateTime: string;
  endDate: string;
  deadlineDate: string;
  scormProgress?: IScormProgress;
  lectureProgresses?: ILectureProgress[];
}

export interface IScormProgress {
  currentShareableContentObject: IIdTitleRef;
  shareableContentObjectProgresses: IScormSCOProgress[];
}

export interface IScormSCOProgress {
  id: string;
  startDateTime: string;
  finishDateTime: string;
}
export interface ICourseCompletionStatus {
  id: CourseCompletionStatusEnum;
  name: string;
  description: string;
  shareableContentObject: IIdTitleRef;
}

export enum CourseCompletionStatusEnum {
  TO_DO = '1',
  IN_PROGRESS = '2',
  COMPLETED = '3',
  PUBLISHED = '2',
  FAILED = '4',
}

export interface IAdditionalData {
  traineesStats?: ITraineesStats;
}

export interface ITraineesStats {
  toDoCount: number;
  inProgressCount: number;
  completedCount: number;
}

export interface ICourseSettings {
  id?: string;
  nextLessonsLocked?: boolean;
}

import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import CodedGradeForm from '@src/components/codebook/form/CodedGradeForm';
import CodebookListView from '@src/components/codebook/view/CodebookList';
import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICodedGrade } from '@src/model/education/CodedGrade';
import codedGradeBusinessStore, { ICodedGradeCreatePayload, ICodedGradeListFilter } from '@src/service/business/codedgrade/codedGradeBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const DEFAULT_SORT_VALUE = ['ordering,asc'];
const VIEW_NAME = '@@CODED_GRADE_LIST';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');

// -- Prop types
// ----------
export interface ICodedGradeContainerOwnProps { }
export interface ICodedGradeContainerStateProps {
  codedGradeList: ICollectionData<ICodedGrade>;
}
export interface ICodedGradeContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchCodedGradeList: (params: ICollectionFetchPayload<ICodedGradeListFilter>) => ITrackableAction;
  createCodedGrade: (data: ICodedGradeCreatePayload) => ITrackableAction;
  updateCodedGrade: (data: ICodedGrade) => ITrackableAction;
}
type ICodedGradeContainerProps = ICodedGradeContainerOwnProps & ICodedGradeContainerStateProps & ICodedGradeContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Coded grade container */
const CodedGradeContainer = (props: ICodedGradeContainerProps) => {
  const initialCollectionValues = { sort: DEFAULT_SORT_VALUE, size: DEFAULT_PAGE_SIZE_VALUE };
  const [codedGradeModalsState, toggleCodedGradeModals] = useEntityModalsState<ICodedGrade>();
  const [, updateCollectionParams, onUpdateList] = useCollectionState<ICodedGradeListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchCodedGradeList,
    initialValues: initialCollectionValues,
  });
  const handleCodedGradeCreateSubmit = useCallback((data: ICodedGradeCreatePayload) => {
    props.createCodedGrade(data).track().subscribe(
      // success
      () => {
        props.reportMessage({ message: props.translate('CODEBOOK_GRADE.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        onUpdateList();
        toggleCodedGradeModals.onCloseCreate();
      }
    );
  }, [props.createCodedGrade, toggleCodedGradeModals, props.reportMessage, onUpdateList]);

  const handleCodedGradeUpdateSubmit = useCallback((data: ICodedGrade) => {
    props.updateCodedGrade(data).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleCodedGradeModals.onCloseUpdate();
      }
    );
  }, [props.updateCodedGrade, toggleCodedGradeModals, onUpdateList]);

  return (
    <React.Fragment>
      {props.codedGradeList && <CodebookListView<ICodedGrade>
        showOrderingColumn={true}
        showBackColumn={true}
        title={props.translate(`CODEBOOK.TITLE_LABEL.${CodebookEnum.GRADE}`)}
        descriptionPrefix={'CODEBOOK_GRADE.CODED_GRADE_DESCRIPTION_LABEL'}
        buttonLabel={props.translate('CODEBOOK_GRADE.CREATE_CODED_GRADE_BUTTON_LABEL')}
        codebookItemList={props.codedGradeList}
        canEdit={true}
        onEditCodebookItemClick={toggleCodedGradeModals.onOpenUpdate}
        onCreateCodebookItemClick={toggleCodedGradeModals.onOpenCreate}
        onPageChange={updateCollectionParams.onPageChange}
      />}

      {(codedGradeModalsState.isCreateModalVisible || codedGradeModalsState.isUpdateModalVisible) && <CodedGradeForm
        codedGrade={codedGradeModalsState.selectedEntity}
        onCancel={toggleCodedGradeModals.onCloseCreate}
        onCodedGradeUpdateSubmit={handleCodedGradeUpdateSubmit}
        onCodedGradeCreateSubmit={handleCodedGradeCreateSubmit} />}
    </React.Fragment>
  );

};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICodedGradeContainerOwnProps): ICodedGradeContainerStateProps => ({
  codedGradeList: codedGradeBusinessStore.selectors.getCodedGradeList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICodedGradeContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchCodedGradeList: (params: ICollectionFetchPayload<ICodedGradeListFilter>) => dispatch(createTrackableAction(codedGradeBusinessStore.actions.fetchCodedGradeList(params))),
  createCodedGrade: (data: ICodedGradeCreatePayload) => dispatch(createTrackableAction(codedGradeBusinessStore.actions.createCodedGrade(data))),
  updateCodedGrade: (data: ICodedGrade) => dispatch(createTrackableAction(codedGradeBusinessStore.actions.updateCodedGrade(data))),
});

export default connect<ICodedGradeContainerStateProps, ICodedGradeContainerDispatchProps, ICodedGradeContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(CodedGradeContainer as any));

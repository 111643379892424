import LemonAvatar from '@src/components/common/image/LemonAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { UserNameView } from '@src/components/common/view/UserNameView';

import { IUserGroupMember } from '@src/model/usergroup/UserGroupMember';
import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

export interface IUserGroupHierarchyMemberOwnProps {
  member: IUserGroupMember;
  isAdmin: boolean;
}

type IUserGroupHierarchyMemberProps = IUserGroupHierarchyMemberOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const UserGroupHierarchyMember: React.FC<IUserGroupHierarchyMemberProps> = (props) => {
  return (
    <Col className="timun-userGroupHierarchy__member" span={24} key={props.member.id}>
      <Row justify="start" gutter={8}>
        <Col>
          <LemonAvatar size="xsmall" imagePath={props.member.user.profileImageUrl} fallbackIconName="user" />
        </Col>
        <Col flex={'1 0 0'}>
          <Row>
            <Link to={`/user/${props.member.user.id}`}>
              <UserNameView user={props.member.user} />
            </Link>
            {props.isAdmin && (
              <Tooltip overlay={props.translate('USER_GROUP.LIST_VIEW.HIERARCHY_NODE_ADMIN_LABEL')}>
                &nbsp;
                <LemonIcon name="shield" />
              </Tooltip>
            )}
          </Row>
          {props.member.user.workData?.workPosition != null && <Row className="timun-userGroupHierarchy__workPosition">{props.member.user.workData?.workPosition?.name}</Row>}
        </Col>
      </Row>
    </Col>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IUserGroupHierarchyMemberOwnProps>(UserGroupHierarchyMember as any);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import UserDocumentsListView from '@src/components/user/view/UserDocumentsListView';
import { IFile } from '@src/model/file/File';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

interface IUserDocumentsListContainerOwnProps {
  userId: string;
}

interface IUserDocumentsListContainerStateProps {
  userFileList: ICollectionData<IFile>;
}

interface IUserDocumentsListContainerDispatchProps {
  fetchUserFileList: (id: string, page: number, size: number, sort: string[]) => void;
  clearUserFileList: () => void;
  addUserFile: (id: string, data: IFile[]) => ITrackableAction;
  removeUserFile: (id: string, data: IFile[]) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type IUserDocumentsListContainerProps = IUserDocumentsListContainerOwnProps & IUserDocumentsListContainerStateProps & IUserDocumentsListContainerDispatchProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IUserDocumentsListContainerState {
  page: number;
  size: number;
  sort: string[];
}

// -- Component
// ----------

class UserDocumentsListContainer extends React.Component<IUserDocumentsListContainerProps, IUserDocumentsListContainerState> {
  state: IUserDocumentsListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: [],
  };

  componentDidMount = () => {
    this.updateList();
  };

  componentDidUpdate = (prevProps: IUserDocumentsListContainerProps, prevState: IUserDocumentsListContainerState) => {
    if (this.state !== prevState) {
      this.updateList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearUserFileList();
  };

  render = () => {
    const isOwnProfile = this.props.currentUser.id === this.props.userId;
    return <UserDocumentsListView
      canEditDocuments={isOwnProfile}
      userFileList={this.props.userFileList}
      onUploadFile={this.handleUploadFile}
      onRemoveFile={this.handleRemoveFile}
      onPageChange={this.onPageChange}
    />;
  };

  onPageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleUploadFile = (data: IFile[]) => {
    this.props.addUserFile(this.props.userId, data).track().subscribe(
      () => this.updateList()
    );
  };

  handleRemoveFile = (data: IFile) => {
    this.props.removeUserFile(this.props.userId, [data]).track().subscribe(
      () => {
        this.updateList();
        this.props.reportMessage({ message: this.props.translate('FILE_UPLOAD.FILE_REMOVED_MESSAGE', { filename: data.name }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      }
    );
  };

  private updateList = () => {
    this.props.fetchUserFileList(this.props.userId, this.state.page, this.state.size, this.state.sort);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserDocumentsListContainerOwnProps): IUserDocumentsListContainerStateProps => ({
  userFileList: UserBusinessStore.selectors.getUserFileList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IUserDocumentsListContainerOwnProps): IUserDocumentsListContainerDispatchProps => ({
  fetchUserFileList: (id: string, page: number, size: number, sort: string[]) => dispatch(UserBusinessStore.actions.fetchUserFileList(id, page, size, sort)),
  clearUserFileList: () => dispatch(UserBusinessStore.actions.clearUserFileList()),
  addUserFile: (id: string, data: IFile[]) => createTrackableAction(dispatch(UserBusinessStore.actions.addUserFiles(id, data))),
  removeUserFile: (id: string, data: IFile[]) => createTrackableAction(dispatch(UserBusinessStore.actions.removeUserFiles(id, data))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<IUserDocumentsListContainerStateProps, IUserDocumentsListContainerDispatchProps, IUserDocumentsListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRoles(withLocalize(UserDocumentsListContainer as any)));

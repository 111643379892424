import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Row, Select, Typography } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useMemo } from 'react';

/** Data picker item data structure. */
export interface IDataPickerItem<T = any> {
  name: string;
  value: string | number;
  description?: string;
  icon?: string;
  /** Item data can be used to store additional object related to selected value which can only be string. */
  data?: T;
}

// -- Prop types
// ----------
export interface IDataPickerOwnProps {
  items?: IDataPickerItem[];
  value?: IDataPickerItem;
  disabled?: boolean;
  placeholderSuffix?: string;
  dataTestIdPrefix?: string;
  emptyMessage?: string;
  showSearch?: boolean;
  allowClear?: boolean;
  onChange?: (newValue?: IDataPickerItem) => void;
  onSearch?: (value: string) => void;
  onClear?: () => void;
}

type IDataPickerProps = IDataPickerOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
/** Describe your component ... */
const DataPicker: React.FC<IDataPickerProps> = (props: IDataPickerProps) => {
  const handleSearch = useCallback(
    debounce((value: string) => {
      props.onSearch?.(value);
    }, AppConfigService.getValue(`components.common.debounceTimeout`)),
    [debounce, props.onSearch, AppConfigService.getValue]
  );

  const handleChange = useCallback(
    (value: string) => {
      const selectedItem = props.items?.find((item: IDataPickerItem) => {
        return item.value === value;
      });

      if (selectedItem) {
        props.onChange?.(selectedItem);
      } else {
        props.onChange?.();
      }
    },
    [props.items, props.onChange]
  );

  const options = useMemo(
    () =>
      props.items?.map((item) =>
        props.items?.length !== 0
          ? {
              value: item.value,
              key: item.value,
              dataTestId: props.dataTestIdPrefix ? `${props.dataTestIdPrefix}_value_${item.value}` : undefined,
              name: item.name,
              label: (
                <React.Fragment>
                  <Row>
                    <Typography.Text>{item.name}</Typography.Text>
                  </Row>
                  {item.description && (
                    <Row>
                      <Typography.Text type="secondary">{item.description}</Typography.Text>
                    </Row>
                  )}
                </React.Fragment>
              ),
            }
          : /** No data option */
            {
              value: '',
              key: 'empty',
              disabled: true,
              name: '',
              label: (
                <Row>
                  <Col className="text-center">{props.emptyMessage || props.translate('COMMON.MESSAGE_NO_DATA')}</Col>
                </Row>
              ),
            }
      ),
    [props.items, props.dataTestIdPrefix, props.emptyMessage, props.translate]
  );

  return (
    <Select
      value={props.value?.name}
      defaultActiveFirstOption={true}
      options={options}
      notFoundContent={null}
      disabled={props.disabled}
      allowClear={props.allowClear ?? true}
      onClear={props.onClear}
      showSearch={props.showSearch ?? true}
      onSearch={handleSearch}
      filterOption={props.onSearch ? false : (input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
      onChange={handleChange}
      className="timun-dataPicker__select"
      optionLabelProp="label"
      data-test-id={props.dataTestIdPrefix ? `${props.dataTestIdPrefix}_select` : undefined}
      placeholder={props.placeholderSuffix || props.translate('COMMON.ACTION_SEARCH')}
    />
  );
};

export default withLocalize<IDataPickerOwnProps>(DataPicker as any);

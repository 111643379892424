import CommentList from '@src/components/comment/CommentList';
import CommentListSortPicker from '@src/components/comment/CommentListSortPicker';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles from '@src/components/common/role/withRoles';
import { IComment, ICommentCreatePayload } from '@src/model/comment/Comment';
import { CommentObjectTypeEnum } from '@src/model/comment/CommentObjectType';
import { VoteTypeEnum } from '@src/model/comment/VoteObjectType';
import { IUserInfo } from '@src/model/user/User';
import CommentsBusinessStore, { ICommentsFilter } from '@src/service/business/comment/commentsBusinessStore';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dispatch } from 'redux';

// -- Const
// ----------
const DEFAULT_SORT_VALUE = 'addedDateTime,asc';

// -- PropTypes
// ----------

export interface ICommentContainerOwnProps {
  objectId: string;
  objectTypeId: CommentObjectTypeEnum;
}

export interface ICommentContainerStateProps {
  comments: ICollectionData<IComment>;
  commentsFilter: ICommentsFilter;
  currentUser: IUserInfo;
}

export interface ICommentContainerDispatchProps {
  fetchComments: (commentFilter: ICommentsFilter, size: number, page: number, sort: string[], callback: IActionThunkMap) => void;
  createComment: (data: ICommentCreatePayload, callback: IActionThunkMap) => any;
  updateComment: (data: IComment, callback: IActionThunkMap) => any;
  deleteComment: (id: string, callback: IActionThunkMap) => any;
  voteOnComment: (commentId: string, voteType: VoteTypeEnum, callback: IActionThunkMap) => any;
  deleteVote: (commentId: string, callback: IActionThunkMap) => any;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type ICommentContainerProps = ICommentContainerDispatchProps & ICommentContainerStateProps & ICommentContainerOwnProps & IWithLocalizeOwnProps;

// --
// ----- State types
interface ICommentContainerState {
  page: number;
  size: number;
  sort: string;
}

// -- Component
// ----------

/** container for displaying, editing & deleting comments and comment votes */
class CommentContainer extends React.Component<ICommentContainerProps, ICommentContainerState> {
  state: ICommentContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: DEFAULT_SORT_VALUE,
  };

  componentDidMount = () => {
    // initial list update
    this.fetchComments();
  };

  componentDidUpdate = (prevProps: ICommentContainerProps, prevState: ICommentContainerState) => {
    if (this.state !== prevState || (this.props !== prevProps && this.props.objectId !== prevProps.objectId)) {
      this.fetchComments();
    }
  };

  render() {
    return (
      <React.Fragment>
        <CommentListSortPicker changeSort={this.handleChangeSort} />

        {/* Pagination */}
        {this.props.comments && <ListPagination page={this.props.comments.page} onChange={this.handlePageChange} />}

        {/* List */}
        {this.props.comments && this.props.comments.content && (
          <CommentList
            comments={this.props.comments.content}
            onCommentUpdate={this.updateComment}
            onCommentDelete={this.deleteComment}
            objectId={this.props.objectId}
            objectTypeId={this.props.objectTypeId}
            onCommentCreateSubmit={this.createComment}
            onVoteAdd={this.addVote}
            currentUser={this.props.currentUser}
            onDeleteVote={this.deleteVote}
          />
        )}

        {/* Pagination */}
        {this.props.comments && <ListPagination page={this.props.comments.page} onChange={this.handlePageChange} />}
      </React.Fragment>
    );
  }

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleChangeSort = (sort: string) => {
    this.setState({ page: 0, sort });
  };

  private deleteVote = (commentId: string, voteType: VoteTypeEnum): void => {
    this.props.deleteVote(commentId, {
      success: () => {
        this.fetchComments();
      },
    });
  };

  private addVote = (commentId: string, voteTypeId: VoteTypeEnum): void => {
    this.props.voteOnComment(commentId, voteTypeId, {
      success: () => {
        this.fetchComments();
      },
    });
  };

  private deleteComment = (id: string): void => {
    this.props.deleteComment(id, {
      success: () => {
        this.fetchComments();
        this.props.reportMessage({ message: this.props.translate('COMMENTS_VIEW.COMMENT_DELETED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      },
    });
  };

  private createComment = (data: ICommentCreatePayload): void => {
    this.props.createComment(data, {
      success: () => {
        this.fetchComments();
        this.props.reportMessage({ message: this.props.translate('COMMENTS_VIEW.COMMENT_ADDED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
      },
    });
  };

  private updateComment = (data: IComment): void => {
    this.props.updateComment(data, {
      success: () => {
        this.fetchComments();
      },
    });
  };

  private fetchComments = (pageNumber = this.state.page, pageSize = this.state.size, sort = this.state.sort): void => {
    const filter = {
      ...this.props.commentsFilter,
      objectId: this.props.objectId,
      objectType: this.props.objectTypeId,
    };

    this.props.fetchComments(filter, pageSize, pageNumber, [sort], {});
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICommentContainerOwnProps): ICommentContainerStateProps => ({
  comments: CommentsBusinessStore.selectors.getComments(state),
  commentsFilter: CommentsBusinessStore.selectors.getCommentsFilter(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): ICommentContainerDispatchProps => ({
  fetchComments: (commentFilter: ICommentsFilter, size: number, page: number, sort: string[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(CommentsBusinessStore.actions.fetchComments(commentFilter, size, page, sort), thunkMap)),
  createComment: (data: ICommentCreatePayload, thunkMap: IActionThunkMap) => dispatch(createActionThunk(CommentsBusinessStore.actions.createComment(data), thunkMap)),
  updateComment: (data: IComment, thunkMap: IActionThunkMap) => dispatch(createActionThunk(CommentsBusinessStore.actions.updateComment(data), thunkMap)),
  deleteComment: (id: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(CommentsBusinessStore.actions.deleteComment({ id }), thunkMap)),
  voteOnComment: (commentId: string, voteType: VoteTypeEnum, thunkMap: IActionThunkMap) => dispatch(createActionThunk(CommentsBusinessStore.actions.voteOnComment(commentId, { id: voteType }), thunkMap)),
  deleteVote: (commentId: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(CommentsBusinessStore.actions.deleteVote(commentId), thunkMap)),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<ICommentContainerStateProps, ICommentContainerDispatchProps, ICommentContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRouter(withRoles(withLocalize(CommentContainer as any))));

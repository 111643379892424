import { FormComponentProps } from '@ant-design/compatible/lib/form';
import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import { Editor } from '@src/components/common/editor/Editor';
import { TimunEditorConfig } from '@src/components/common/editor/TimunEditorConfigs';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import MathContent from '@src/components/common/math/MathContent';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseContentControl from '@src/components/course/CourseContentControl';
import { CourseStatusEnum, CourseVisibilityEnum, ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ICourseCreatePayload } from '@src/service/business/courses/courseUpdateBusinessStore';
import { Button, Col, Input, InputNumber, Radio, Row, Select, Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

const FormItem = Form.Item;
const Option = Select.Option;

export interface ICourseDataFormOwnProps {
  course?: ICourse;
  courseGroups: ICourseGroup[];
  canDeleteCourse?: boolean;
  isInCreation?: boolean;
  onCreate?: (data: ICourseCreatePayload) => void;
  onUpdate?: (data: ICourse) => void;
  onCancel: () => void;
  onDelete?: (data: ICourse) => void;
}

type ICourseDataFormProps = ICourseDataFormOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Create and update form for course data */
const CourseDataForm: React.FC<ICourseDataFormProps> = (props: ICourseDataFormProps) => {
  const [form] = Form.useForm<ICourse>();

  const handleCancel = useCallback(() => {
    if (form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: () => {
          form.resetFields();
          props.onCancel();
        },
        title: props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      form.resetFields();
      props.onCancel();
    }
  }, [props.onCancel, form.isFieldsTouched, form.resetFields]);

  const handleFinish = useCallback(
    (values: ICourse) => {
      if (props.course?.id) {
        const data: ICourse = {
          ...props.course,
          title: values.title,
          courseGroups: values.courseGroups,
          content: values.content,
          duration: values.duration,
          visibility: values.visibility,
          settings: values.settings,
        };
        props.onUpdate?.(data);
      } else {
        const data: ICourseCreatePayload = {
          ...props.course,
          title: values.title,
          courseGroups: values.courseGroups,
          content: values.content,
          duration: values.duration,
        };
        props.onCreate?.(data);
      }
    },
    [props.course, props.onUpdate, props.onCreate]
  );

  const ButtonControls = () => (
    <FormItem shouldUpdate={true}>
      {() => (
        <Row justify="end" gutter={12}>
          {props.onDelete && props.canDeleteCourse && (
            <Col>
              <DeleteElementButton<ICourse> item={props.course} buttonText={props.translate('COMMON.ACTION_DELETE')} onDelete={props.onDelete} />
            </Col>
          )}
          <Col>
            <Button onClick={handleCancel} data-test-id="timun-courseView__update-aboutCourse_cancelButton">
              {props.translate('COMMON.ACTION_CANCEL')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" data-test-id="timun-courseView__update-aboutCourse_submitButton" htmlType="submit">
              {props.translate('COMMON.ACTION_SAVE')}
            </Button>
          </Col>
        </Row>
      )}
    </FormItem>
  );

  // course which was already published (statuses published and archived) can not be set back to creation
  const wasPublished = useMemo(() => props.course && CourseHelperUtils.isCourseInStatus(props.course, [CourseStatusEnum.PUBLISHED, CourseStatusEnum.ARCHIVED]), [props.course, CourseHelperUtils.isCourseInStatus]);

  return (
    <div className="timun-courseView__lecture-content">
      <Form<ICourse> form={form} layout="vertical" onFinish={handleFinish} initialValues={props.course}>
        <ButtonControls />

        {/* ----- course title ----- */}
        <FormItem
          name="title"
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            // tslint:disable-next-line: no-duplicate-string
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
          ]}
          label={props.translate('COURSE_VIEW.UPDATE.COURSE_TITLE_FORM_LABEL')}
        >
          <Input data-test-id="timun-courseView__update-aboutCourse_courseTitle" />
        </FormItem>

        {/* ----- course groups ----- */}
        <FormItem name={['courseGroups', 0, 'id']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]} label={props.translate('COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_FORM_LABEL')}>
          <Select placeholder={props.translate('COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_PLACEHOLDER')} showArrow={true} data-test-id="timun-courseDataForm__courseGroupSelect">
            {props.courseGroups.map((courseGroup: ICourseGroup) => (
              <Option key={courseGroup.id} value={courseGroup.id} data-test-id={`timun-courseDataForm__courseGroupSelect_option_${courseGroup.id}`}>
                {courseGroup.title}
              </Option>
            ))}
          </Select>
        </FormItem>

        {/* ----- lessons locked ----- */}

        {props.course && (
          <FormItem name={['settings', 'nextLessonsLocked']} rules={[{ required: true, message: props.translate('COURSE_VIEW.UPDATE.LESSONS_LOCKED_REQUIRED_LABEL') }]} label={props.translate('COURSE_VIEW.UPDATE.LESSONS_LOCKED_LABEL')}>
            <Radio.Group buttonStyle="solid" disabled={wasPublished} data-test-id="timun-courseDataForm__nextLessonsLockedRadio">
              <Radio.Button value={true} data-test-id={`timun-courseDataForm__nextLessonsLockedRadio_option_locked`}>
                {props.translate('COURSE_VIEW.UPDATE.LESSONS_LOCKED_TITLE_LOCKED')}
              </Radio.Button>
              <Radio.Button value={false} data-test-id={`timun-courseDataForm__nextLessonsLockedRadio_option_unlocked`}>
                {props.translate('COURSE_VIEW.UPDATE.LESSONS_LOCKED_TITLE_UNLOCKED')}
              </Radio.Button>
            </Radio.Group>
          </FormItem>
        )}

        {/* ----- visibility ----- */}
        {props.course && (
          <FormItem name={['visibility', 'id']} rules={[{ required: true, message: props.translate('COURSE_VIEW.UPDATE.VISIBILITY_REQUIRED_LABEL') }]} label={props.translate('COURSE_VIEW.UPDATE.VISIBILITY_LABEL')}>
            <Radio.Group buttonStyle="solid" data-test-id="timun-courseDataForm__courseVisibilityRadio">
              <Radio.Button value={CourseVisibilityEnum.PUBLIC} data-test-id={`timun-courseDataForm__courseVisibilityRadio_option_${CourseVisibilityEnum.PUBLIC}`}>
                {props.translate('COURSE_VIEW.UPDATE.VISIBILITY_TITLE_PUBLIC')}
              </Radio.Button>
              <Radio.Button value={CourseVisibilityEnum.PRIVATE} data-test-id={`timun-courseDataForm__courseVisibilityRadio_option_${CourseVisibilityEnum.PRIVATE}`}>
                {props.translate('COURSE_VIEW.UPDATE.VISIBILITY_TITLE_PRIVATE')}
              </Radio.Button>
              <Radio.Button value={CourseVisibilityEnum.LIMITED} data-test-id={`timun-courseDataForm__courseVisibilityRadio_option_${CourseVisibilityEnum.LIMITED}`}>
                {props.translate('COURSE_VIEW.UPDATE.VISIBILITY_TITLE_LIMITED')}
              </Radio.Button>
            </Radio.Group>
          </FormItem>
        )}
        {/* ----- content ----- */}
        <FormItem name="content" label={props.translate('COURSE_VIEW.UPDATE.COURSE_TEXT_FORM_LABEL')}>
          <CourseContentControl
            renderEdit={(content, handleEditorChange) => {
              return {
                body: (
                  <Editor
                    value={content.body}
                    onChange={(contentBody: string) => {
                      handleEditorChange(contentBody);
                    }}
                    data-test-id="timun-courseView__update-aboutCourse-content"
                    init={{
                      ...TimunEditorConfig.defaultConfig(),
                      height: 500,
                      menubar: true,
                    }}
                  />
                ),
              };
            }}
            renderPreview={(content) => {
              return {
                body: (
                  <React.Fragment>
                    {content.body && (
                      <MathContent key={content.body}>
                        <div dangerouslySetInnerHTML={{ __html: content.body }} />
                      </MathContent>
                    )}
                  </React.Fragment>
                ),
              };
            }}
          />
        </FormItem>

        {/* ----- course duration ----- */}
        <FormItem name="duration" label={props.translate('COURSE_VIEW.UPDATE.COURSE_DURATION_FORM_LABEL')}>
          <InputNumber min={1} addonAfter={props.translate('COURSE_VIEW.UPDATE.COURSE_DURATION_FORM_HOURS_LABEL')} data-test-id="timun-courseView__update-aboutCourse_courseDuration" />
        </FormItem>

        <ButtonControls />

        {/* ----- quiz ----- */}
        {/* TODO: add InpuntNumber component in Form for numberOfQuestion in course exam */}
      </Form>
    </div>
  );
};

export default withLocalize<ICourseDataFormOwnProps>(withRoles(CourseDataForm as any));

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { ITimunFile } from '@src/service/business/files/util';
import { Modal } from 'antd';
import FileView from '@src/components/common/file/FileView';

// -- Prop types
// ----------

export interface IFilePreviewModalOwnProps {
  record: ITimunFile;
  closeModal: () => void;
}
type IFilePreviewModalProps = IFilePreviewModalOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

interface IFilePreviewModalState { }

// -- Component
// ----------

/** Modal for file preview ... */
class FilePreviewModal extends React.Component<IFilePreviewModalProps, IFilePreviewModalState> {
  getItemLink = (record: ITimunFile) => {
    return (
      <a href={this.props.resolveApiFilePath(record.uid)} download={record.name}>
        {record.name}
      </a>
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <Modal title={this.getItemLink(this.props.record)} visible={true} mask={true} centered={true} footer={null} onCancel={this.props.closeModal} transitionName="fade" className="timun-courseViewFileList__previewImageModal timun-fileList__previewModal">
          <FileView file={this.props.record} />
        </Modal>
      </React.Fragment>
    );
  };
}

export default withLocalize<IFilePreviewModalOwnProps>(withPathResolver(FilePreviewModal as any));

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import UserGroupHierarchyMember from '@src/components/usergroup/hierarchy/UserGroupHierarchyMember';
import UserGroupHelperUtils from '@src/components/usergroup/utils/UserGroupHelperUtils';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';

import { Button, Col, Divider, Row, Tooltip, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

export interface IUserGroupHierarchyMemberGroupOwnProps {
  basePath: string;
  returnRoute?: string;
  userGroupHierarchy: IUserGroupHierarchy;
  canEdit?: boolean;
  canAdd?: boolean;
  onUserGroupEdit?: (userGroup: IUserGroup) => void;
  onUserGroupHierarchyAdd?: (parentUserGroup: IUserGroup) => void;
}

type IUserGroupHierarchyMemberGroupProps = IUserGroupHierarchyMemberGroupOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

const UserGroupHierarchyMemberGroup: React.FC<IUserGroupHierarchyMemberGroupProps> = (props) => {
  const groupAdmins = useMemo(() => UserGroupHelperUtils.getGroupAdmins(props.userGroupHierarchy), [props.userGroupHierarchy]);
  const groupMembers = useMemo(() => UserGroupHelperUtils.getGroupMembers(props.userGroupHierarchy), [props.userGroupHierarchy]);

  return (
    <Row className="timun-userGroupHierarchy__groupCard">
      <Col span={24} key="title">
        <Row justify="center">
          <Col>
            <Tooltip overlay={props.userGroupHierarchy.userGroup.name}>
              <Typography.Title level={4}>
                <Link
                  to={{
                    pathname: `${props.basePath}/${props.userGroupHierarchy.userGroup.id}`,
                    state: { returnRoute: props.returnRoute },
                  }}
                >
                  {props.userGroupHierarchy.userGroup.name}
                </Link>
              </Typography.Title>
            </Tooltip>
          </Col>
        </Row>
      </Col>
      {groupAdmins.map((admin) => {
        return <UserGroupHierarchyMember member={admin} isAdmin={true} key={admin.id} />;
      })}
      {groupMembers.map((member) => {
        return <UserGroupHierarchyMember member={member} isAdmin={false} key={member.id} />;
      })}
      {(props.canEdit || props.canAdd) && (
        <React.Fragment>
          <Divider className="timun_userGroupHierarchy__actionsDivider" />
          <Col span={24} key="actions">
            <Row justify="space-around" align="middle">
              {props.canEdit && (
                <Col>
                  <Button icon={<LemonIcon key="edit" name="edit" />} onClick={() => props.onUserGroupEdit?.(props.userGroupHierarchy.userGroup)} />
                </Col>
              )}
              {props.canEdit && props.canAdd && <Divider type="vertical" />}
              {props.canAdd && (
                <Col>
                  <Button icon={<LemonIcon key="add" name="plusCircle" />} onClick={() => props.onUserGroupHierarchyAdd?.(props.userGroupHierarchy.userGroup)} />
                </Col>
              )}
            </Row>
          </Col>
        </React.Fragment>
      )}
    </Row>
  );
};

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IUserGroupHierarchyMemberGroupOwnProps>(UserGroupHierarchyMemberGroup as any));

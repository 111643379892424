import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ProgressBar from '@src/components/common/progressbar/ProgressBar';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import AdditionalCourseListContainer from '@src/components/course/list/additional/AdditionalCourseListContainer';
import ScormContentView from '@src/components/course/scorm/ScormContentView';
import ScormViewSider from '@src/components/course/scorm/ScormViewSider';
import TagContainer from '@src/components/tag/TagContainer';
import NoteListViewContainer from '@src/components/user/view/NoteListViewContainer';
import { UserNoteUtils } from '@src/components/usernote/utils/UserNoteUtils';
import { ICourse } from '@src/model/course/Course';
import { IComputerManagedInstruction } from '@src/model/course/scorm/ComputerManagedInstruction';
import { IShareableContentObject } from '@src/model/course/scorm/ShareableContentObject';
import { TagItemTypeEnum } from '@src/model/tag/TagItemType';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { Layout, Typography } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IScormCourseViewOwnProps {
  course: ICourse;
  lectureList: IShareableContentObject[];
  lectureCMI: IComputerManagedInstruction;
  selectedLecture?: IShareableContentObject;
  hideSider?: boolean;
  showNotes?: boolean;
  onEntityTagUpdate?: () => void;
  onLectureCMIUpdate?: (lectureCMI: IComputerManagedInstruction) => void;
}

type IScormCourseViewProps = IScormCourseViewOwnProps & IWithLocalizeOwnProps & WithRouterProps;

const ScormCourseView = (props: IScormCourseViewProps) => {
  return (
    <Layout>
      <Header className="timun-courseView__sider-courseTitle">
        <HeaderTitle link={'/dashboard'} title={props.translate('APP_NAVIGATION.MY_PROFILE')} icon={<LemonIcon name="left" />} />
        <Typography.Title> {props.course.title} </Typography.Title>
        <TagContainer entityId={props.course.id} entityType={TagItemTypeEnum.COURSE} tags={props.course.tags} onEntityTagUpdate={props.onEntityTagUpdate} />
      </Header>
      <Layout>
        {!props.hideSider && (
          <Layout.Sider className="timun-courseView__sider" breakpoint="lg" collapsedWidth={0}>
            <ScormViewSider lectureList={props.lectureList} selectedLecture={props.selectedLecture} course={props.course} />
          </Layout.Sider>
        )}
        <Layout.Content className="timun-courseView__content">
          <React.Fragment>
            <ProgressBar progressProps={{ className: 'timun-courseView__content-progressBar', showInfo: false }} progress={props.course.progress} />
            {/* About course */}

            {props.selectedLecture?.filePath && props.lectureCMI && <ScormContentView course={props.course} lectureCMI={props.lectureCMI} selectedLecture={props.selectedLecture} onLectureCMIUpdate={props.onLectureCMIUpdate} />}

            {/* ----- Course notes ----- */}
            {props.showNotes && <NoteListViewContainer canEditnote={UserNoteUtils.isAuthorCurrentUser} objectId={props.course.id} type={NoteObjectTypeEnum.COURSE} />}
          </React.Fragment>
        </Layout.Content>
      </Layout>
      <AdditionalCourseListContainer course={props.course} getPath={CourseHelperUtils.getTraineePath} />
    </Layout>
  );
};

export default withLocalize<IScormCourseViewOwnProps>(withRouter(ScormCourseView as any));

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IWebinar } from '@src/model/webinar/Webinar';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';

// -- Const
const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const TIME_FORMAT: string = AppConfigService.getValue('dateTimeFormat.time');

interface IAdditionalCardElementsOwnProps {
  webinar?: IWebinar;
  cardDescription?: React.ReactNode;
}

type IAdditionalCardElementsProps = IAdditionalCardElementsOwnProps & IWithLocalizeOwnProps;

const AdditionalCardElements: React.FC<IAdditionalCardElementsProps> = (props: IAdditionalCardElementsProps) => {
  const startDate = useMemo(() => moment(props.webinar?.estimatedStartDateTime).format(DATE_FORMAT), [props.webinar?.estimatedStartDateTime, DATE_FORMAT]);
  const startTime = useMemo(() => moment(props.webinar?.estimatedStartDateTime).format(TIME_FORMAT), [props.webinar?.estimatedStartDateTime, TIME_FORMAT]);
  const isWebinarUpcoming = useMemo(() => moment(props.webinar?.estimatedStartDateTime) > moment(), [props.webinar?.estimatedStartDateTime, moment]);

  return (
    <React.Fragment>
      <Row gutter={16} align="middle" justify="start">
        <Col>
          <Row gutter={4} align={'middle'}>
            <Col>
              <LemonIcon name="calendar" className="timun-additionalCardElements__icon" />
            </Col>
            <Col> {startDate}</Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={4} align={'middle'}>
            <Col>
              <LemonIcon name="clockCircle" className="timun-additionalCardElements__icon" />
            </Col>
            <Col> {startTime}</Col>
          </Row>
        </Col>

        <Col>
          {isWebinarUpcoming && (
            <Row gutter={4} align={'middle'}>
              <Col>
                <LemonIcon name="podcast" className="timun-additionalCardElements__icon" />
              </Col>
              <Col>{props.translate('COMMON.LABEL.ONLINE')}</Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row>{props.cardDescription}</Row>
    </React.Fragment>
  );
};

export default withLocalize<IAdditionalCardElementsOwnProps>(AdditionalCardElements as any);

import React, { useState } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserRegistrationForm from '@src/components/user/registration/UserRegistrationForm';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import { IUserRole, UserRoleEnum } from '@src/model/user/UserRole';
import { IFileListsByType } from '@src/service/business/files/util';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { Button, Col, Form, Modal, Row } from 'antd';

// -- Prop types
// ----------
export interface IUserRegistrationFormModalOwnProps {
  user: IUserInfo;
  userFiles?: IFileListsByType;
  onCancel?: () => void;
  onSubmit?: (data: IUserInfo) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}

type IUserRegistrationFormModalProps = IUserRegistrationFormModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display user registration fields in modal */
const UserRegistrationFormModal: React.FC<IUserRegistrationFormModalProps> = (props: IUserRegistrationFormModalProps) => {
  const [form] = Form.useForm<IUserInfo>();
  const [step, setStep] = useState<number>(0);
  const [user, setUser] = useState<IUserInfo>(props.user);
  const handleSubmit = () => {
    props.onSubmit?.(user);
  };
  const handleStepChange = () => {
    form.validateFields().then(
      // success
      (values: Partial<IUserInfo>) => {
        setStep(step + 1);
        setUser((prevUser) => {
          const roles = [{ id: UserRoleEnum.TRAINEE }] as unknown as IUserRole[]; // TODO: cast
          const dateOfBirth = DateUtils.toBackendDateFormat(values.dateOfBirth, 'backendQueryParamDateFormat');
          return {
            ...prevUser,
            ...values,
            dateOfBirth,
            roles,
          };
        });
      }
    ).catch(
      // error msg
      (errorMsg) => console.log('Registration form error', errorMsg)
    );
  };
  return (
    <Modal
      className="lemon-modal__modal--md"
      title={props.translate('USER_REGISTRATION.MODAL.TITLE')}
      visible={true}
      maskClosable={false}
      onCancel={props.onCancel}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}
      footer={
        <React.Fragment>
          {/* --------- Step 1 --------- */}
          {step === 0 && <Button type="primary" onClick={handleStepChange}>{props.translate('COMMON.ACTION_CONTINUE')}</Button>}
          {/* --------- Step 2 --------- */}
          {step === 1 &&
            <Row justify="end" gutter={8}>
              <Col>
                <Button onClick={() => setStep(0)}>{props.translate('COMMON.ACTION_BACK')}</Button>
              </Col>
              <Col>
                <Button type="primary" onClick={handleStepChange}>{props.translate('COMMON.ACTION_CONTINUE')}</Button>
              </Col>
            </Row>}
          {/* --------- Step 3 --------- */}
          {step === 2 &&
            <Row justify="end" gutter={8}>
              <Col>
                <Button onClick={() => setStep(1)}>{props.translate('COMMON.ACTION_BACK')}</Button>
              </Col>
              <Col>
                <Button type="primary" onClick={form.submit}>{props.translate('COMMON.ACTION_SAVE')}</Button>
              </Col>
            </Row>}
        </React.Fragment>
      }>

      <UserRegistrationForm
        formRef={form}
        value={user}
        currentStep={step}
        onSubmit={handleSubmit}
        userFiles={props.userFiles}
        onFileUpload={props.onFileUpload}
        onFileRemove={props.onFileRemove} />
    </Modal >
  );
};

export default withLocalize<IUserRegistrationFormModalOwnProps>(UserRegistrationFormModal as any);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IFile } from '@src/model/file/File';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import UserListBusinessStore from '@src/service/business/user/userListBusinessStore';
import { Button, Col, Row } from 'antd';
import { IUserInfo } from '@src/model/user/User';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import { InvalidUsers } from '@src/model/user/InvalidUsers';
import { TimunUser } from '@src/model/user/TimunUsers';
import TimunUserImportListView from '@src/components/user/list/TimunUserImportListView ';
import LemonIcon from '@src/components/common/image/LemonIcon';
import UserListUploadModal from '@src/components/user/list/UserListUploadModal';

export interface IAdminTimunUserImportListContainerOwnProps {}

export interface IAdminTimunUserImportListContainerStateProps {}

export interface IAdminTimunUserImportListContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  importTimunUserList: (data: IFile) => ITrackableAction;
  createTimunUser: (data: IUserInfo[]) => ITrackableAction;
}

type IAdminTimunUserImportListContainerProps = IAdminTimunUserImportListContainerOwnProps & IAdminTimunUserImportListContainerStateProps & IAdminTimunUserImportListContainerDispatchProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

const AdminTimunUserImportListContainer: React.FC<IAdminTimunUserImportListContainerProps> = (props: IAdminTimunUserImportListContainerProps) => {
  const [validUsers, setValidUsers] = useState<IUserInfo[]>([]);
  const [invalidUsers, setInvalidUsers] = useState<InvalidUsers[]>([]);
  const [canImportTimunUser, setCanImportTimunUser] = useState<boolean>(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState<boolean>(false);

  const handleImportTimunUser = useCallback(
    (selected: IUserInfo[]) => {
      props
        .createTimunUser(selected)
        .track()
        .subscribe(() => {
          props.reportMessage({ message: props.translate('TIMUN_IMPORT_USER.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        });

      setValidUsers([]);
      setInvalidUsers([]);
      setCanImportTimunUser(false);
    },
    [props.createTimunUser, setValidUsers, setInvalidUsers, setCanImportTimunUser]
  );

  const handleTimunUserListUpload = useCallback(
    (data: IFile) => {
      props
        .importTimunUserList(data)
        .track()
        .subscribe((response: TimunUser) => {
          setValidUsers(response.validUsers);
          setInvalidUsers(response.invalidUsers);
          setCanImportTimunUser(true);
          setIsImportModalVisible(false);
          props.reportMessage({ message: props.translate('USER_VIEW_CONTAINER.IMPORT_USERS_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        });
    },
    [props.importTimunUserList, props.reportMessage, setValidUsers, setInvalidUsers, setCanImportTimunUser, setIsImportModalVisible]
  );

  return (
    <React.Fragment>
      <Row className="timun-wrapper__gutterBox--vertical" gutter={[4, 4]} justify="end">
        <Col>
          <Button icon={<LemonIcon name="plus" />} type="primary" onClick={() => setIsImportModalVisible(true)} data-test-id="timun-courseListView__createSCORMButton">
            {props.translate('TIMUN_IMPORT_USER.ADD_TIMUN_USERS_TITLE')}
          </Button>
        </Col>
      </Row>
      {isImportModalVisible && <UserListUploadModal onClose={() => setIsImportModalVisible(false)} onSubmit={handleTimunUserListUpload} title={props.translate('FILE_UPLOAD.TIMUN_USER_IMPORT_MODAL_TITLE')} />}

      <TimunUserImportListView validusers={validUsers} invalidUsers={invalidUsers} canImportTimunUser={canImportTimunUser} onTimunUserImport={handleImportTimunUser} />
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

const mapStateToProps = (state: any, ownProps: IAdminTimunUserImportListContainerOwnProps): IAdminTimunUserImportListContainerStateProps => ({});

const mapDispatchToProps = (dispatch: any, ownProps: IAdminTimunUserImportListContainerOwnProps): IAdminTimunUserImportListContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  importTimunUserList: (data: IFile) => dispatch(createTrackableAction(UserListBusinessStore.actions.importTimunUserList(data))),
  createTimunUser: (data: IUserInfo[]) => dispatch(createTrackableAction(UserBusinessStore.actions.createTimunUser(data))),
});

export default connect<IAdminTimunUserImportListContainerStateProps, IAdminTimunUserImportListContainerDispatchProps, IAdminTimunUserImportListContainerOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(withRouter<IAdminTimunUserImportListContainerOwnProps>(withTenantPropEnabled(AdminTimunUserImportListContainer as any))));

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IAddress } from '@src/model/user/Address';
import AppConfigService from '@src/service/common/AppConfigService';
import { Form, Input, InputNumber } from 'antd';
import CityDataPicker from '@src/components/common/datapicker/externalEducationTemplate/CityDataPicker';

const ZIP_MIN_LENGTH = AppConfigService.getValue('components.userForm.minZipValue');
const ZIP_MAX_LENGTH = AppConfigService.getValue('components.userForm.maxZipValue');

// -- Prop types
// ----------
export interface IAddressPropertyInputOwnProps {
  value?: IAddress;
  onChange?: () => void;
}

type IAddressPropertyInputProps = IAddressPropertyInputOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Address input fileds */
const AddressPropertyInput: React.FC<IAddressPropertyInputProps> = (props: IAddressPropertyInputProps) => {
  return (
    <React.Fragment>
      <Form.Item
        label={props.translate('ADDRESS_PROPERTY_INPUT.STREET_WITH_HOME_NUMBER_LABEL')}
        name={['address', 'streetWithHomeNumber']}
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label={props.translate('ADDRESS_PROPERTY_INPUT.ZIP_LABEL')}
        name={['address', 'zip']}
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]} >
        <InputNumber min={ZIP_MIN_LENGTH} max={ZIP_MAX_LENGTH} />
      </Form.Item>
      <Form.Item
        label={props.translate('ADDRESS_PROPERTY_INPUT.CITY_LABEL')}
        name={['address', 'city']}
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <CityDataPicker />
      </Form.Item>

      <Form.Item
        label={props.translate('ADDRESS_PROPERTY_INPUT.COUNTRY_LABEL')}
        name={['address', 'country']}
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <Input />
      </Form.Item>

      <Form.Item
        noStyle={true}
        hidden={true}
        name={'id'}
      >
        <Input />
      </Form.Item>
    </React.Fragment>
  );
};

export default withLocalize<IAddressPropertyInputOwnProps>(AddressPropertyInput as any);

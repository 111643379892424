import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { INotification, NotificationTypeEnum } from '@src/model/notification/Notification';
import { INotificationCreatePayload } from '@src/service/business/notification/notificationViewBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Form, Input, Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';

// -- Const
// ----------
const TextArea = Input.TextArea;
const FormItem = Form.Item;
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const TITLE_MAX_LENGTH = AppConfigService.getValue('components.common.smallCharacterLengthInput');

// -- Prop types
// ----------
interface INotificationFormOwnProps {
  onCreateSubmit: (data: INotificationCreatePayload) => void;
  onUpdateSubmit: (data: INotification) => void;
  onClose: () => void;
  notification?: INotification;
}

type INotificationFormProps = INotificationFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display modal with notification form */
const NotificationForm: React.FC<INotificationFormProps> = (props: INotificationFormProps) => {
  const handleFinish = useCallback(
    (values: INotification) => {
      if (props.notification) {
        props.onUpdateSubmit?.({
          ...values,
          id: props.notification.id,
        });
      } else {
        props.onCreateSubmit?.({
          ...values,
        });
      }
    },
    [props.notification, props.onUpdateSubmit, props.onCreateSubmit]
  );

  const handleCancel = useCallback(() => {
    props.onClose();
  }, [props.onClose]);

  const initialValues = useMemo(
    () => ({
      title: props.notification?.title,
      message: props.notification?.message,
      type: props.notification?.type ?? { id: NotificationTypeEnum.GENERIC },
    }),
    [props.notification, NotificationTypeEnum.GENERIC]
  );

  const [form] = Form.useForm<INotification>();
  return (
    <Modal open={true} title={props.translate('NOTIFICATION.NEW_NOTIFICATION')} onCancel={handleCancel} onOk={form.submit} okText={props.translate('COMMON.ACTION_SAVE')} cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form<INotification> form={form} initialValues={initialValues} onFinish={handleFinish}>
        <FormItem
          name={'title'}
          label={props.translate('NOTIFICATION_FORM.TITLE_LABEL')}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: TITLE_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}
        >
          <Input placeholder={props.translate('NOTIFICATION.TITLE')} />
        </FormItem>
        <FormItem
          name={'message'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}
          label={<FormItemLabelIcon label={props.translate('NOTIFICATION_FORM.MESSAGE_LABEL')} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
        >
          <TextArea placeholder={props.translate('NOTIFICATION.TEXT')} />
        </FormItem>

        <FormItem name={['type', 'id']} hidden={true} noStyle={true}>
          <Input type="hidden" />
        </FormItem>
      </Form>
    </Modal>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<INotificationFormOwnProps>(NotificationForm as any);

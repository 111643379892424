import React, { useEffect } from 'react';

import CountyDataPicker from '@src/components/common/datapicker/CountyDataPicker';
import OrganizationDataPicker from '@src/components/common/datapicker/OrganizationDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import EducationSectorDataPicker from '@src/components/common/datapicker/EducationSectorDataPicker';
import EducationCategoryPicker from '@src/components/common/datapicker/EducationCategoryPicker';
import { ExternalEducationTemplateStatusEnum } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { UserRoleEnum } from '@src/model/user/UserRole';

// -- Const
// ----------
const { Option } = Select;
// -- Prop types
// ----------

export interface IExternalEducationTemplateListFilterOwnProps {
  filter?: IExternalEducationTemplateListFilter;
  onFilterChange?: (filter: IExternalEducationTemplateListFilter) => void;
}
type IExternalEducationTemplateListFilterProps = IExternalEducationTemplateListFilterOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationTemplateListFilter: React.FC<IExternalEducationTemplateListFilterProps> = (props: IExternalEducationTemplateListFilterProps) => {
  const [form] = Form.useForm<IExternalEducationTemplateListFilter>();

  useEffect(() => {
    form.resetFields();
  }, [props.filter]);

  const handleSubmit = (values: IExternalEducationTemplateListFilter) => props.onFilterChange?.({
    evaluator: values.evaluator,
    educationProvider: values.educationProvider,
    approvalPhase: values.approvalPhase,
    title: values.title,
    county: values.county,
    educationSector: values.educationSector,
    educationCategories: values.educationCategories,
    status: values.status,
  });
  const handleFilterReset = () => props.onFilterChange?.({});
  const activityPhaseOptions = Object.keys(ExternalEducationTemplateActivityPhaseEnum).map((item: string) => (
    <Option
      value={ExternalEducationTemplateActivityPhaseEnum[item as keyof typeof ExternalEducationTemplateActivityPhaseEnum]}
      key={ExternalEducationTemplateActivityPhaseEnum[item as keyof typeof ExternalEducationTemplateActivityPhaseEnum]}
    >
      {props.translate(`EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY.${item}`)}
    </Option>
  ));
  const statusOptions = Object.keys(ExternalEducationTemplateStatusEnum).map((item: string) => (
    <Option
      value={ExternalEducationTemplateStatusEnum[item as keyof typeof ExternalEducationTemplateStatusEnum]}
      key={ExternalEducationTemplateStatusEnum[item as keyof typeof ExternalEducationTemplateStatusEnum]}
    >
      {props.translate(`EXTERNAL_EDUCATION_TEMPLATE.STATUS.${item}`)}
    </Option>
  ));

  return (
    <Form<IExternalEducationTemplateListFilter>
      className="timun_externalEducationTemplateListFilter__content"
      name="externalEducationTemplateListFilter"
      initialValues={props.filter}
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Row gutter={8}>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="educationProvider"
            label={props.translate('EXTERNAL_EDUCATION_TEMPLATE_LIST.FILTER.EDUCATION_PROVIDER_LABEL')}>
            <OrganizationDataPicker />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="approvalPhase"
            label={props.translate('EXTERNAL_EDUCATION_TEMPLATE_LIST.FILTER.APPROVAL_PHASE_LABEL')} >
            <Select allowClear={true} defaultValue={props.filter?.approvalPhase} placeholder={props.translate('COMMON.ACTION_SELECT')}>
              {activityPhaseOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="title"
            label={props.translate('EXTERNAL_EDUCATION_TEMPLATE_LIST.FILTER.TEMPLATE_TITLE_LABEL')} >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            name="county"
            label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_COUNTY_LABEL')} >
            <CountyDataPicker placeholder={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_COUNTY_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <Form.Item name={'educationCategories'} label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.EDUCATION_CATEGORY_LABEL')}>
            <EducationCategoryPicker placeholder={props.translate('COMMON.LABEL.SELECT_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <Form.Item name={'educationSector'} label={props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.EDUCATION_SECTOR_LABEL')}>
            <EducationSectorDataPicker placeholder={props.translate('COMMON.LABEL.SELECT_PLACEHOLDER')} />
          </Form.Item>
        </Col>

        {props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && <Col xs={24} sm={12} md={6}>
          <Form.Item
            name={'status'}
            label={props.translate('EXTERNAL_EDUCATION_TEMPLATE_LIST.FILTER.STATUS_LABEL')} >
            <Select allowClear={true} defaultValue={props.filter?.status} placeholder={props.translate('COMMON.ACTION_SELECT')}>
              {statusOptions}
            </Select>
          </Form.Item>
        </Col>}
      </Row>
      <Row gutter={8}>
        <Col>
          <Button onClick={form.submit}>{props.translate('COMMON.ACTION_FILTER')}</Button>
        </Col>
        <Col>
          <Button onClick={handleFilterReset}>{props.translate('COMMON.ACTION_RESET_FILTER')}</Button>
        </Col>
      </Row>
    </Form >
  );
};

export default withLocalize<IExternalEducationTemplateListFilterOwnProps>(withRoles(ExternalEducationTemplateListFilter as any));

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import PrivateCourseListContainer from '@src/components/course/list/catalog/PrivateCourseListContainer';
import TraineeMyCourseListView from '@src/components/course/list/catalog/TraineeMyCourseListView';
import { CourseStatusEnum } from '@src/model/course/Course';
import { Tabs } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

enum CourseTabsViewMenuKeys {
  Catalog = 'catalog',
  My = 'my',
}

export interface ICourseTabsViewOwnProps {
  openedTab: string;
}

type ICourseTabsViewProps = ICourseTabsViewOwnProps & WithRouterProps & IWithLocalizeOwnProps;

const CourseTabsView: React.FC<ICourseTabsViewProps> = (props) => {
  const handleChange = (key: string) => {
    props.router.push(`/courses/${key}`);
  };

  return (
    <AppContent>
      <Tabs activeKey={props.openedTab} onChange={handleChange} destroyInactiveTabPane={true}>
        <Tabs.TabPane tab={props.translate('COURSE_LIST.VIEW_TAB_MY')} key={CourseTabsViewMenuKeys.My}>
          <TraineeMyCourseListView />
        </Tabs.TabPane>

        <Tabs.TabPane key={CourseTabsViewMenuKeys.Catalog} tab={props.translate('COURSE_LIST.VIEW_TAB_CATALOG')}>
          {/* Catalog view fetch all published courses */}
          <PrivateCourseListContainer predefinedListFilter={{ status: CourseStatusEnum.PUBLISHED }} />
        </Tabs.TabPane>
      </Tabs>
    </AppContent>
  );
};

export default withLocalize<ICourseTabsViewOwnProps>(withRouter(CourseTabsView) as any);

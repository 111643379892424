import CompanyDataPicker from '@src/components/common/datapicker/CompanyDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CompanyDataForm from '@src/components/company/CompanyDataForm';
import { ICompany } from '@src/model/company/Company';
import { Form } from 'antd';
import React from 'react';

interface ICompanyPickerFormViewOwnProps {
  company?: ICompany;
  onChange?: (data?: ICompany) => void;
  onClear?: () => void;
}

type ICompanyPickerFormViewProps = ICompanyPickerFormViewOwnProps & IWithLocalizeOwnProps;

interface ICompanyPickerFormViewState {
  company?: ICompany;
}

class CompanyPickerFormView extends React.Component<ICompanyPickerFormViewProps, ICompanyPickerFormViewState> {
  state: ICompanyPickerFormViewState = {
    company: undefined,
  };

  componentDidMount = () => {
    if (this.props.company) {
      this.handleChange(this.props.company);
    }
  };

  componentDidUpdate = (prevProps: ICompanyPickerFormViewProps) => {
    if (JSON.stringify(this.props.company) !== JSON.stringify(prevProps.company)) {
      // after form change, company is always a new object
      this.handleChange(this.props.company);
    }
  };

  render = () => {
    return (
      <React.Fragment>
        <Form.Item>
          <CompanyDataPicker value={this.state.company} onChange={this.handleChange} onClear={this.props.onClear} />
        </Form.Item>

        <CompanyDataForm onChange={this.handleFormChange} company={this.state.company} />
      </React.Fragment>
    );
  };

  handleFormChange = (values: Partial<ICompany>) => {
    const company = {
      ...this.state.company,
      ...values,
    } as ICompany;
    this.handleChange(company);
  };

  handleChange = (company?: ICompany) => {
    this.setState({ company });

    if (this.props.onChange) {
      this.props.onChange(company);
    }
  };
}

export default withLocalize<ICompanyPickerFormViewOwnProps>(CompanyPickerFormView as any);

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICourseUserGroup } from '@src/model/course/CourseUserGroup';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { ICourseUserGroupPayload } from '@src/service/business/courses/courseListBusinessStore';
import { Button, Col, Empty, Row, Switch, Tree, TreeSelect } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import GridItem from '@src/components/common/grid/GridItem';
import { IWorkPositionListFilter } from '@src/service/business/workpositions/workPositionBusinessStore';
import IIdRef from '@src/model/common/IdRef';
import { RouterNavigationPromptContext } from '@src/components/common/route/prompt/RouterNavigationPromptContext';

// -- Prop types
// ----------
interface IWorkPositionsByUserGroupMap {
  [userGroupId: string]: IIdRef<string>[];
}

export interface IUserGroupWorkpositionTreeOwnProps {
  userGroupHierarchy?: IUserGroupHierarchy;
  userGroupList: IUserGroup[];
  workPositionList: IWorkPosition[];
  assignedUserGroupWorkPositions: ICourseUserGroup[];
  disabled?: boolean;
  onSubmit?: (data: ICourseUserGroupPayload[]) => void;
  onFilterSubmit?: (filter?: IWorkPositionListFilter) => void;
}
type IUserGroupWorkpositionTreeProps = IUserGroupWorkpositionTreeOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const UserGroupWorkpositionTree: React.FC<IUserGroupWorkpositionTreeProps> = (props: IUserGroupWorkpositionTreeProps) => {
  const [assignedUserGroupWorkPositions, setUserGroupAssignedWorkPositions] = useState<IWorkPositionsByUserGroupMap>({});
  const [userGroupId, setUserGroupId] = useState<string>();
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

  const { setIsRouterPromptActive } = useContext(RouterNavigationPromptContext);
  const handleUserGroupWorkpositionSelected = useCallback(() => {
    setIsRouterPromptActive?.(true);
    setIsFilterApplied(false);
  }, [setIsRouterPromptActive, setIsFilterApplied]);

  useEffect(() => {
    setUserGroupAssignedWorkPositions(userGroupWorkPositionsByCourseUserGroup(props.assignedUserGroupWorkPositions));
  }, []);
  useEffect(() => {
    if (isFilterApplied) {
      props.onFilterSubmit?.({ userGroup: userGroupId });
    } else props.onFilterSubmit?.({});
  }, [isFilterApplied]);

  /** Maps workpositions to their course user groups in an object with keys that corresponds to user group id */
  const userGroupWorkPositionsByCourseUserGroup = useCallback((userGroupList: ICourseUserGroupPayload[]): IWorkPositionsByUserGroupMap => {
    return userGroupList.reduce((accum, item) => {
      if (accum[item.userGroup.id] == null) {
        accum[item.userGroup.id] = [];
      }
      if (item.workPositions) {
        accum[item.userGroup.id].push(...item.workPositions);
      }
      return accum;
    }, {} as IWorkPositionsByUserGroupMap);
  }, []);

  /** Map user group hierarchy to data picker item list. */
  const mapToItems = useCallback((userGroupHierarchyList?: IUserGroupHierarchy[]): DataNode[] => {
    return (
      userGroupHierarchyList?.map((userGroupHierarchy) => {
        return {
          key: userGroupHierarchy.userGroup.id,
          value: userGroupHierarchy.userGroup.id,
          title: userGroupHierarchy.userGroup.name,
          selectable: true,
          isLeaf: userGroupHierarchy.children.length === 0,
          children: userGroupHierarchy.children.length === 0 ? undefined : mapToItems(userGroupHierarchy.children),
          data: userGroupHierarchy.userGroup,
          ['data-test-id']: `timun-userGroupWorkposition__userGroupHierarchy_${userGroupHierarchy.userGroup.id}`,
        };
      }) || []
    );
  }, []);

  const userGroupHierarchyTreeData = useMemo(
    () => [
      {
        key: props.userGroupHierarchy?.userGroup.id,
        value: props.userGroupHierarchy?.userGroup.id,
        title: props.userGroupHierarchy?.userGroup.name,
        children: mapToItems(props.userGroupHierarchy?.children),
        selectable: true,
        isLeaf: false,
        ['data-test-id']: `timun-userGroupWorkposition__userGroupHierarchy_${props.userGroupHierarchy?.userGroup.id}`,
      },
    ],
    [props.userGroupHierarchy]
  );
  /** Create new user group workposition corelation and overwrite existing state */
  const setCheckedKeys = useCallback(
    (userGroupId: string, checked: string[]) => {
      const data = {
        [userGroupId]: checked.map((check) => {
          return { id: check };
        }),
      };
      setUserGroupAssignedWorkPositions({ ...assignedUserGroupWorkPositions, ...data });
    },
    [assignedUserGroupWorkPositions, setUserGroupAssignedWorkPositions]
  );

  const handleSelectAllWorkPositions = useCallback(() => {
    const allWorkpositionIds = props.workPositionList.map((workposition) => workposition.id);
    if (userGroupId) {
      setCheckedKeys(userGroupId, allWorkpositionIds);
    }
  }, [props.workPositionList, userGroupId, setCheckedKeys]);

  const handleDeselectAllWorkPositions = useCallback(() => {
    if (userGroupId) {
      setCheckedKeys(userGroupId, []);
    }
  }, [userGroupId, setCheckedKeys]);

  const getWorkPositionTreeData = useCallback(() => {
    const workPositionTree: DataNode[] = props.workPositionList?.map((workposition) => {
      return {
        title: workposition.name,
        checkable: true,
        key: workposition.id,
        value: workposition.id,
      };
    });

    /** Insert bulk action tree nodes at the start of the list */
    workPositionTree.unshift(
      {
        title: <span onClick={handleSelectAllWorkPositions}> {props.translate('COMMON.ACTION_SELECT_ALL')} </span>,
        key: 'selectAll',
        checkable: false,
      },
      {
        title: <span onClick={handleDeselectAllWorkPositions}>{props.translate('COMMON.ACTION_DESELECT_ALL')} </span>,
        key: 'deselectAll',
        checkable: false,
      }
    );
    return workPositionTree;
  }, [props.workPositionList, handleSelectAllWorkPositions, handleDeselectAllWorkPositions]);

  const getCheckedKeys = useCallback((userGroupId: string) => assignedUserGroupWorkPositions?.[userGroupId]?.map((workposition) => workposition.id), [assignedUserGroupWorkPositions]);

  const handleSubmit = useCallback(() => {
    const userGroupWorkpositionPayloadData: ICourseUserGroupPayload[] = Object.keys(assignedUserGroupWorkPositions).map((userGroupId) => {
      return {
        userGroup: { id: userGroupId },
        workPositions: assignedUserGroupWorkPositions[userGroupId].map((workposition) => {
          return { id: workposition.id };
        }),
      };
    });
    props.onSubmit?.(userGroupWorkpositionPayloadData);
    setIsRouterPromptActive?.(false);
  }, [assignedUserGroupWorkPositions, props.onSubmit, setIsRouterPromptActive]);
  return (
    <React.Fragment>
      <Row justify="space-between">
        <Col>
          <GridItem label={props.translate('USER_GROUP_WORKPOSITION.TREE.APPLIED_FILTER_LABEL')}>
            <Switch checked={isFilterApplied} onChange={setIsFilterApplied} disabled={!userGroupId} />
          </GridItem>
        </Col>
        <Col>
          <GridItem label={' '}>
            <Button type="primary" key="submit" onClick={handleSubmit}>
              {props.translate('COMMON.ACTION_SAVE')}
            </Button>
          </GridItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <GridItem label={props.translate('USER_GROUP_WORKPOSITION.TREE.USER_GROUP_HIERARCHY_LABEL')}>
            <TreeSelect
              onSelect={setUserGroupId}
              placeholder={props.translate('USER_GROUP_WORKPOSITION.TREE.USER_GROUP_HIERARCHY_PLACEHOLDER')}
              showSearch={true}
              allowClear={true}
              onClear={() => setUserGroupId(undefined)}
              onChange={handleUserGroupWorkpositionSelected}
              treeNodeFilterProp="name"
              treeData={userGroupHierarchyTreeData}
              treeDefaultExpandAll={true}
              disabled={props.disabled}
            />
          </GridItem>
        </Col>
        <Col span={12}>
          {userGroupId ? (
            <GridItem label={props.translate('USER_GROUP_WORKPOSITION.TREE.WORKPOSITION_LABEL')}>
              <Tree treeData={getWorkPositionTreeData()} checkedKeys={getCheckedKeys(userGroupId)} checkable={true} onCheck={(check) => setCheckedKeys(userGroupId, check as string[])} />
            </GridItem>
          ) : (
            <Empty description={props.translate('USER_GROUP_WORKPOSITION.TREE.UNSELECTED_GROUP_EMPTY_LABEL')} />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withLocalize<IUserGroupWorkpositionTreeOwnProps>(UserGroupWorkpositionTree as any);

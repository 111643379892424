import React from 'react';
import { connect } from 'react-redux';

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { IEducationCategory } from '@src/model/educationcategory/EducationCategory';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';

// -- Prop types
// ----------

export interface IEducationCategoryDataPickerOwnProps {
  value?: IEducationCategory;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (newValue?: IEducationCategory) => void;
}

export interface IEducationCategoryDataPickerStateProps {
  educationCategoryList: IEducationCategory[];
}

export interface IEducationCategoryDataPickerDispatchProps {
}
type IEducationCategoryDataPickerProps = IEducationCategoryDataPickerOwnProps & IEducationCategoryDataPickerStateProps & IEducationCategoryDataPickerDispatchProps;

interface IEducationCategoryDataPickerState {
}

// -- Component
// ----------

/** Describe your component ... */
class EducationCategoryDataPicker extends React.Component<IEducationCategoryDataPickerProps, IEducationCategoryDataPickerState> {
  state: IEducationCategoryDataPickerState = {};

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.props.educationCategoryList.map(this.mapToItem);

    return <DataPicker
      value={pickerValue}
      items={pickerItems}
      disabled={this.props.disabled}
      onChange={this.handleDataPickerChange}
      dataTestIdPrefix="timun-skillPicker"
      placeholderSuffix={this.props.placeholder}
    />;
  };

  private handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  /** Map entity ref list to data picker item list. */
  private mapToItem(educationCategory: IEducationCategory): IDataPickerItem {
    return {
      value: educationCategory.id,
      name: educationCategory.name,
      data: educationCategory,
    };
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationCategoryDataPickerOwnProps): IEducationCategoryDataPickerStateProps => ({
  educationCategoryList: CollectionBusinessStore.selectors.getCollectionContent(state, 'EducationCategory'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationCategoryDataPickerDispatchProps => ({
});

export default connect<IEducationCategoryDataPickerStateProps, IEducationCategoryDataPickerDispatchProps, IEducationCategoryDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(EducationCategoryDataPicker as any);

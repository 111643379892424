import { QuizDisplay } from '@lamarodigital/quizzler-lib-frontend';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import useUserSettingsStore from '@src/components/common/usersettings/useUserSettings';
import { QuizHelperUtils } from '@src/components/exam/quiz/QuizHelperUtils';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';
import { Button, Modal, Typography } from 'antd';
import React, { useMemo } from 'react';

// -- Prop types
// ----------
export interface ISurveyInstanceFeedbackModalOwnProps {
  surveyInstance?: ISurveyInstance;
  surveyInstanceSubmissionList: IQuiz[];
  showEvaluationFeedback: boolean;
  onCancel: () => void;
}

type ISurveyInstanceFeedbackModalProps = ISurveyInstanceFeedbackModalOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
const SurveyInstanceFeedbackModal = (props: ISurveyInstanceFeedbackModalProps) => {
  const [userSettings] = useUserSettingsStore();

  const surveyFeedbackTitle = useMemo(
    () => (
      <React.Fragment>
        <Typography.Paragraph> {props.translate(`SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.${props.surveyInstance?.type.name}`)} </Typography.Paragraph>
        <Typography.Paragraph> {props.surveyInstance?.generatedIntro} </Typography.Paragraph>
      </React.Fragment>
    ),
    [props.translate, props.surveyInstance, props.surveyInstance]
  );
  return (
    <Modal
      maskClosable={true}
      title={surveyFeedbackTitle}
      visible={true}
      className="lemon-modal__modal--lg"
      onCancel={props.onCancel}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}
      footer={
        <Button key="back" onClick={props.onCancel}>
          {props.translate('COMMON.ACTION_CLOSE')}
        </Button>
      }
    >
      <div className="timun-surveyInstanceView__content">
        <QuizDisplay locale={userSettings?.locale} resolveFileUrl={QuizHelperUtils.resolveFileUrl} showHelpIntroActionButton={false} showRedoIntroActionButton={false} showEvaluationFeedback={props.showEvaluationFeedback} quiz={props.surveyInstanceSubmissionList[0]} />
      </div>
    </Modal>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<ISurveyInstanceFeedbackModalOwnProps>(SurveyInstanceFeedbackModal as any);

import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ReminderCalendarView from '@src/components/reminder/calendar/ReminderCalendarView';
import { IReminder, ReminderTypeEnum } from '@src/model/reminder/Reminder';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import ReminderBusinessStore from '@src/service/business/reminder/ReminderBusinessStore';
import ReminderListBusinessStore, { IReminderListFilter } from '@src/service/business/reminder/reminderListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.paging.smallPageSize');
const VIEW_NAME = '@@REMINDER_CALENDAR_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<IReminderListFilter> = {
  viewName: VIEW_NAME,
  initialValues: {
    size: DEFAULT_PAGE_SIZE_VALUE,
    filter: {
      startDateTime: moment().startOf('month').format(backendDate),
      endDateTime: moment().endOf('month').format(backendDate),
    },
  },
};

// -- Prop types
// ----------

export interface IReminderCalendarContainerOwnProps {
  currentUser: IUserInfo;
}

export interface IReminderCalendarContainerStateProps {
  reminderList: ICollectionData<IReminder>;
  allowedTypesList?: ReminderTypeEnum[];
}

export interface IReminderCalendarContainerDispatchProps {
  fetchSelectedReminderList: (payload: ICollectionFetchPayload<IReminderListFilter>) => ITrackableAction;
  fetchReminderList: (payload: ICollectionFetchPayload<IReminderListFilter>) => void;
  deleteReminder: (id: string) => ITrackableAction;
  updateReminder: (data: IReminder) => ITrackableAction;
}

type IReminderCalendarContainerProps = IReminderCalendarContainerOwnProps & IReminderCalendarContainerStateProps & IReminderCalendarContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IReminderListFilter>;

interface IReminderCalendarContainerState {
  selectedReminderList?: ICollectionData<IReminder>;
}

// -- Component
// ----------

/** Reminder calendar component container ... */
class ReminderCalendarContainer extends React.Component<IReminderCalendarContainerProps, IReminderCalendarContainerState> {
  state: IReminderCalendarContainerState = {
    selectedReminderList: undefined,
  };

  componentDidMount() {
    this.updateList();
  }

  componentDidUpdate(prevProps: IReminderCalendarContainerProps, prevState: IReminderCalendarContainerState) {
    if (this.props.collectionParams !== prevProps.collectionParams) {
      this.updateList();
    }
  }

  render = () => {
    return <ReminderCalendarView
      reminderList={this.props.reminderList}
      reminderFilter={this.props.collectionParams.filter}
      selectedReminderList={this.state.selectedReminderList}
      currentUser={this.props.currentUser}
      onLoadMore={this.handleLoadMore}
      onUpdateReminder={this.updateReminder}
      onDeleteReminder={this.handleReminderDelete}
      onCreateReminder={this.updateList}
      onUpdateFilter={this.handleUpdateFilter}
      onSelectedChange={this.fetchSelectedDateList}
    />;
  };

  handleUpdateFilter = (filter: IReminderListFilter) => {
    this.props.updateCollectionParams.onFilterUpdate({ ...filter, types: this.props.allowedTypesList });
  };

  fetchSelectedDateList = (filter: IReminderListFilter) => {
    this.props.fetchSelectedReminderList({
      filter: {
        ...filter,
        types: this.props.allowedTypesList,
      },
      page: 0,
      size: 20,
      sort: [],
    }).track().subscribe(
      (data: ICollectionData<IReminder>) => this.setState({ selectedReminderList: data })
    );
  };

  handleLoadMore = () => {
    this.props.updateCollectionParams.onLoadMore(DEFAULT_PAGE_SIZE_VALUE);
  };

  handleReminderDelete = (reminder: IReminder) => {
    this.props
      .deleteReminder(reminder.id)
      .track()
      .subscribe(() => {
        this.updateList();
      });
  };

  updateList = () => {
    if (this.props.collectionParams.filter) {
      this.props.onUpdateList(this.props.fetchReminderList);
    }
  };

  private updateReminder = (data: IReminder): ITrackableAction => {
    const action = this.props.updateReminder(data);
    action.track().subscribe(() => {
      this.updateList();
    });

    return action;
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IReminderCalendarContainerOwnProps): IReminderCalendarContainerStateProps => ({
  reminderList: ReminderListBusinessStore.selectors.getReminderList(state),
  allowedTypesList: ReminderListBusinessStore.selectors.getAllowedTypesList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IReminderCalendarContainerDispatchProps => ({
  fetchSelectedReminderList: (payload: ICollectionFetchPayload<IReminderListFilter>) => dispatch(createTrackableAction(ReminderListBusinessStore.actions.fetchSelectedReminderList(payload))),
  fetchReminderList: (payload: ICollectionFetchPayload<IReminderListFilter>) => dispatch(ReminderListBusinessStore.actions.fetchReminderList(payload)),
  deleteReminder: (id: string) => createTrackableAction(dispatch(ReminderBusinessStore.actions.deleteReminder({ id }))),
  updateReminder: (data: IReminder) => createTrackableAction(dispatch(ReminderBusinessStore.actions.updateReminder(data))),
});

export default connect<IReminderCalendarContainerStateProps, IReminderCalendarContainerDispatchProps, IReminderCalendarContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(ReminderCalendarContainer as any)));

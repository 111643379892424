
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExpenseForm from '@src/components/externalEducationApplication/form/ExpenseForm';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { Button, Col, Form, Row } from 'antd';
import React from 'react';


interface IExternalEducationExpensesFormOwnProps {
  value?: IExternalEducationApplication;
  disabled?: boolean;
  onSubmit?: (data: IExternalEducationExpense[]) => void;
}

type IExternalEducationExpensesFormProps = IExternalEducationExpensesFormOwnProps & IWithLocalizeOwnProps;


const ExternalEducationExpensesForm = (props: IExternalEducationExpensesFormProps) => {
  const [form] = Form.useForm<IExternalEducationExpense>();
  const handleSubmit = (newExpenseData: IExternalEducationExpense) => {
    const updatedExpenses = props.value?.expenses ?? [];
    if (updatedExpenses) {
      updatedExpenses.push(newExpenseData);
    }
    props.onSubmit?.(updatedExpenses);
    form.resetFields();
  };

  return (
    <Form<IExternalEducationExpense>
      name="externalEducationApplication"
      form={form}
      layout="vertical"
      colon={false}>
      <ExpenseForm formRef={form} onSubmit={handleSubmit} expenseTypeId={ExpeneseTypeEnum.TRAVEL_EXPENSE} />

      <Row>
        <Col>
          <Button type="primary" onClick={form.submit} disabled={props.disabled} data-test-id="timun-externalEducationApplicationExpensesForm__submitButton">
            {props.translate('COMMON.ACTION_SAVE')}
          </Button>
        </Col>
      </Row>
    </Form >
  );

};


export default withLocalize<IExternalEducationExpensesFormOwnProps>(ExternalEducationExpensesForm as any);

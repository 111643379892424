import AppLogoContainer from '@src/components/app/layout/AppLogoContainer';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import WhenUserAuthenticated from '@src/components/common/role/WhenUserAuthenticated';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { UserRoleEnum } from '@src/model/user/UserRole';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import { Button, Col, Drawer, Menu, Row } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, WithRouterProps } from 'react-router';

export enum AppNavigationMenuKeys {
  Dashboard = '/dashboard',
  Repository = '/repository',
  CoursesAdministraton = '/courses/administration',
  Courses = '/courses/catalog',
  TraineeCourses = '/courses/my',
  Webinars = '/webinars',
  ExternalContents = '/externalcontents',
  Reports = '/reports',
  Administration = '/administration',
  UserGroups = '/usergroups',
  EducationApplications = '/educationapplications',
  ExternalEducationTemplates = '/externaleducationtemplates',
  UserGroupHierarchy = '/usergrouphierarchy',
  // TODO: hzz demo
  MyProfile = '/myprofile',
  SkillsAdministration = '/skillsadministration',
}

// -- Prop types
// ----------

export interface IAppNavigationStateProps {
  isUserLoggedIn: boolean;
}

export interface IAppNavigationOwnProps {}

type IAppNavigationProps = IAppNavigationOwnProps & IAppNavigationStateProps & IWithLocalizeOwnProps & WithRouterProps & IWithRolesOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------

/** Main app navigation component. */
const AppNavigation: React.FC<IAppNavigationProps> = (props) => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  return (
    <div data-test-id="timun-appNavigation">
      <div className="lemon-appNavigation__menu--horizontal">
        <Menu
          mode="horizontal"
          selectedKeys={getSelectedItems(props)}
          className="lemon-appLayout__headerMenu"
          overflowedIndicator={
            <Row gutter={8}>
              <Col>
                <LemonIcon name="ellipsis" />
              </Col>
            </Row>
          }
        >
          {renderAppNavigationItems(props)}
        </Menu>
      </div>

      <div className="lemon-appNavigation__menu--vertical">
        <Button icon={<LemonIcon name="menu" />} type="link" onClick={() => setMenuOpened(!menuOpened)} />

        <Drawer
          placement="left"
          closeIcon={
            <Row gutter={8} align="bottom">
              <Col>
                <LemonIcon name="close" size="xxlarge" />
              </Col>
              <Col>{props.translate('COMMON.ACTION_CLOSE')}</Col>
            </Row>
          }
          onClose={() => setMenuOpened(false)}
          visible={menuOpened}
          className="lemon-appNavigation__verticalDrawer"
        >
          <Menu mode="inline" selectedKeys={getSelectedItems(props)} className="lemon-appNavigation__menu--vertical">
            <Menu.Item key="tenant" className="lemon-appNavigation__tenantMenuItem" data-test-id="timun-appNavigation__tenantMenuItem">
              {<AppLogoContainer showTenantName={true} />}
            </Menu.Item>

            {renderAppNavigationItems(props)}
          </Menu>
        </Drawer>
      </div>
    </div>
  );
};

const renderAppNavigationItems = (props: IAppNavigationProps) => {
  return [
    props.isTenantPropEnabled('dashboard') && (
      <Menu.Item key={AppNavigationMenuKeys.Dashboard} data-test-id="timun-appNavigation__DashboardMenuItem">
        {/* link to dashboard for auth users or to root for others */}
        <WhenUserAuthenticated fallback={<React.Fragment />}>
          <Link to={AppNavigationMenuKeys.Dashboard}>{props.translate('APP_NAVIGATION.DASHBOARD')}</Link>
        </WhenUserAuthenticated>
      </Menu.Item>
    ),

    /* NOTE: disabled until tenant config is implemented */
    /* {props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && (
              <Menu.Item>
                <Link to="/education/groups">{props.translate('APP_NAVIGATION.EDUCATION_GROUPS')}</Link>
              </Menu.Item>
            )} */

    props.isUserLoggedIn && (
      <Menu.Item key={AppNavigationMenuKeys.MyProfile} data-test-id="timun-appNavigation__MyProfileMenuItem">
        <Link to={AppNavigationMenuKeys.MyProfile}>{props.translate('APP_NAVIGATION.MY_PROFILE')}</Link>
      </Menu.Item>
    ),

    props.isTenantPropEnabled('externaleducationapplications') && props.allowedRoles([UserRoleEnum.COORDINATOR, UserRoleEnum.ORGANIZATION_ADMIN]) && (
      <Menu.Item key={AppNavigationMenuKeys.EducationApplications} data-test-id="timun-appNavigation__EducationApplicationsMenuItem">
        <Link to={AppNavigationMenuKeys.EducationApplications}>{props.translate('APP_NAVIGATION.EDUCATION_APPLICATION')}</Link>
      </Menu.Item>
    ),

    props.isTenantPropEnabled('externaleducations') && props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COORDINATOR]) && (
      <Menu.Item key={AppNavigationMenuKeys.ExternalEducationTemplates} data-test-id="timun-appNavigation__ExternalEducationsMenuItem">
        <Link to={AppNavigationMenuKeys.ExternalEducationTemplates}>{props.translate('APP_NAVIGATION.EXTERNAL_EDUCATION_TEMPLATES')}</Link>
      </Menu.Item>
    ),

    props.isTenantPropEnabled('repository') && props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && (
      <Menu.Item key={AppNavigationMenuKeys.Repository} data-test-id="timun-appNavigation__RepositoryMenuItem">
        <Link to={AppNavigationMenuKeys.Repository}>{props.translate('APP_NAVIGATION.REPOSITORY')}</Link>
      </Menu.Item>
    ),

    props.isTenantPropEnabled('courses') && props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR, UserRoleEnum.TRAINEE]) && (
      <Menu.Item key={AppNavigationMenuKeys.Courses} data-test-id="timun-appNavigation__CoursesMenuItem">
        <Link to={props.isInRoles([UserRoleEnum.TRAINEE]) ? AppNavigationMenuKeys.TraineeCourses : AppNavigationMenuKeys.Courses}>{props.translate('APP_NAVIGATION.COURSES')}</Link>
      </Menu.Item>
    ),

    props.isTenantPropEnabled('courses') && props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR]) && (
      <Menu.Item key={AppNavigationMenuKeys.CoursesAdministraton} data-test-id="timun-appNavigation__CoursesAdministrationMenuItem">
        <Link to={AppNavigationMenuKeys.CoursesAdministraton}>{props.translate('APP_NAVIGATION.COURSES_ADMINISTRATION')}</Link>
      </Menu.Item>
    ),

    props.isTenantPropEnabled('usergroups') && props.allowedRoles([UserRoleEnum.TRAINEE, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR]) && (
      <Menu.Item key={AppNavigationMenuKeys.UserGroups} data-test-id="timun-appNavigation__UserGroupsMenuItem">
        <Link to={AppNavigationMenuKeys.UserGroups}>{props.translate('APP_NAVIGATION.MY_USER_GROUPS')}</Link>
      </Menu.Item>
    ),
    props.isTenantPropEnabled('organizationgraph') && props.isUserLoggedIn && (
      <Menu.Item key={AppNavigationMenuKeys.UserGroupHierarchy} data-test-id="timun-appNavigation__UserGroupsMenuItem">
        <Link to={AppNavigationMenuKeys.UserGroupHierarchy}>{props.translate('APP_NAVIGATION.ORGANIZATION_GRAPH')}</Link>
      </Menu.Item>
    ),

    /* NOTE: disabled until tenant config is implemented */
    props.isTenantPropEnabled('webinars') && props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR, UserRoleEnum.TRAINEE]) && (
      <Menu.Item key={AppNavigationMenuKeys.Webinars} data-test-id="timun-appNavigation__WebinarsMenuItem">
        <Link to={AppNavigationMenuKeys.Webinars}>{props.translate('APP_NAVIGATION.WEBINARS')}</Link>
      </Menu.Item>
    ),

    props.isTenantPropEnabled('externalcontents') && props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR, UserRoleEnum.TRAINEE]) && (
      <Menu.Item key={AppNavigationMenuKeys.ExternalContents} data-test-id="timun-appNavigation__ExternalContentsMenuItem">
        <Link to={AppNavigationMenuKeys.ExternalContents}>{props.translate('APP_NAVIGATION.EXTERNAL_CONTENTS')}</Link>
      </Menu.Item>
    ),

    props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && (
      <Menu.Item key={AppNavigationMenuKeys.Reports} data-test-id="timun-appNavigation__ReportsMenuItem">
        <Link to={AppNavigationMenuKeys.Reports}>{props.translate('APP_NAVIGATION.REPORTS')}</Link>
      </Menu.Item>
    ),

    props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && (
      <Menu.Item key={AppNavigationMenuKeys.Administration} data-test-id="timun-appNavigation__AdministrationMenuItem">
        <Link to={AppNavigationMenuKeys.Administration}>{props.translate('APP_NAVIGATION:ADMINISTRATION')}</Link>
      </Menu.Item>
    ),

    props.isTenantPropEnabled('admin_skills') && props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]) && (
      <Menu.Item key={AppNavigationMenuKeys.SkillsAdministration} data-test-id="timun-appNavigation__Skillsadministration MenuItem">
        <Link to={AppNavigationMenuKeys.SkillsAdministration}>{props.translate('APP_NAVIGATION.ADMINISTRATION_SKILLS')}</Link>
      </Menu.Item>
    ),
  ];
};

const getSelectedItems = (props: IAppNavigationProps): string[] => {
  const selectedItemKey = Object.values(AppNavigationMenuKeys)
    .filter((key) => props.location.pathname.startsWith(key))
    .slice(0, 1);

  return selectedItemKey;
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAppNavigationStateProps => ({
  isUserLoggedIn: LoginBusinessStore.selectors.isUserLoggedIn(state),
});

export default connect<IAppNavigationStateProps, undefined, IAppNavigationOwnProps>(mapStateToProps)(withLocalize<IAppNavigationOwnProps>(withRoles(withTenantPropEnabled(withRouter(AppNavigation as any)))));

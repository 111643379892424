import {
  ExternalEducationAplicationTimelineActivityItemTypeEnum,
  ExternalEducationApplicationTimelineActivityTypeEnum,
  IConsultationUserActivityContent,
  IEducationApplicationDateContent,
  IExternalEducationApplicationTimelineActivity,
  ITimelineCoordinatorAddContent,
  ITimelineCoordinatorRemoveContent,
  ITimelineFileContent,
  ITimelineSurveySubmittedContent,
} from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { SurveyTypeEnum } from '@src/model/survey/SurveyTemplate';
import { INote } from '@src/model/user/Note';
import AppConfigService from '@src/service/common/AppConfigService';
import { DateUtils } from '@src/service/util/date/DateUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import moment from 'moment';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');

/** Utility helper for timeline which is composed of very mixed contents */
export class EducationApplicationTimelineUiHelper {
  static getTimelineItemTitle = (timelineItem: IExternalEducationApplicationTimelineActivity) => {
    const content = timelineItem.content;
    const phaseName = Object.keys(ExternalEducationAplicationTimelineActivityItemTypeEnum).find((phase) => ExternalEducationAplicationTimelineActivityItemTypeEnum[phase as keyof typeof ExternalEducationAplicationTimelineActivityItemTypeEnum] === content.type?.id);
    const creator = `${content.createdBy?.firstName} ${content.createdBy?.lastName}`;
    let text = '';

    // ----- Timeline action
    if (EducationApplicationTimelineUiHelper.isTimelineItemAction(timelineItem)) {
      // ---- Multiple use cases depending on content type
      if (content.type?.id === ExternalEducationAplicationTimelineActivityItemTypeEnum.FILE_ATTACHED) {
        const fileContent: any = content.content;
        let filename = LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.UNKNOWN_FILE_TITLE');

        if (EducationApplicationTimelineUiHelper.isTimelineItemContentFile(fileContent)) {
          filename = fileContent.fileResource.name;
        }

        text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${phaseName}`, { name: creator, filename });
      } else if (content.type?.id === ExternalEducationAplicationTimelineActivityItemTypeEnum.COORDINATOR_ADDED || content.type?.id === ExternalEducationAplicationTimelineActivityItemTypeEnum.COORDINATOR_REMOVED) {
        const coordinatorName: string | undefined = (content.content as ITimelineCoordinatorAddContent).addedParticipant?.name || (content.content as ITimelineCoordinatorRemoveContent).removedParticipant?.name;
        if (coordinatorName) {
          text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${phaseName}`, { name: coordinatorName });
        }
      } else if (content.type?.id === ExternalEducationAplicationTimelineActivityItemTypeEnum.SURVEY_SUBMITTED) {
        const surveyType: SurveyTypeEnum | null = (content.content as ITimelineSurveySubmittedContent)?.surveyType?.id;
        if (surveyType === SurveyTypeEnum.EDUCATION_PROVIDER) {
          text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${phaseName}_EDUCATION_PROVIDER`);
        } else if (surveyType === SurveyTypeEnum.EMPLOYER) {
          text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${phaseName}_EMPLOYER`);
        } else {
          text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${phaseName}`, { name: creator });
        }
      } else if (content.type?.id === ExternalEducationAplicationTimelineActivityItemTypeEnum.EDUCATION_PROVIDER_REQUESTED_PAYMENT) {
        const endDateTime: string | undefined = DateUtils.toViewDateFormat((content.content as IEducationApplicationDateContent).endDateTime, 'dateWithYear');
        const paymentRequestedDateTime: string | undefined = DateUtils.toViewDateFormat((content.content as IEducationApplicationDateContent).paymentRequestedDateTime, 'dateWithYear');
        text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${phaseName}`, { endDateTime, paymentRequestedDateTime });
      }
      // ----- everything else
      else {
        text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${phaseName}`, { name: creator });
      }
    }

    // ----- Timeline note
    else if (EducationApplicationTimelineUiHelper.isTimelineItemNote(timelineItem)) {
      text = LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.NOTE.TITLE', { name: creator });
    } else {
      throw new Error(`Cannot resolve name for unknown timeline activity type: ${timelineItem.type}`);
    }

    return text;
  };

  static getTimelineItemContentDescription = (timelineItem: IExternalEducationApplicationTimelineActivity): string | undefined => {
    return (timelineItem.content.content as IConsultationUserActivityContent)?.description;
  };

  static getTimelineItemDateTime = (timelineItem: IExternalEducationApplicationTimelineActivity) => {
    return moment(timelineItem.createdAt).format(dateFormat);
  };

  static getTimelineItemNoteText = (timelineItem: IExternalEducationApplicationTimelineActivity) => {
    const content = timelineItem.content;
    if (EducationApplicationTimelineUiHelper.isTimelineItemContentNote(content)) {
      return content.text;
    } else {
      return '';
    }
  };

  static isTimelineItemNote = (timelineItem: IExternalEducationApplicationTimelineActivity) => {
    return timelineItem.type === ExternalEducationApplicationTimelineActivityTypeEnum.NOTE;
  };

  static isTimelineItemAction = (timelineItem: IExternalEducationApplicationTimelineActivity) => {
    return timelineItem.type === ExternalEducationApplicationTimelineActivityTypeEnum.ACTION;
  };

  // ----- Various type checks to ensure proper content is shown for timeline activities

  /** Check is timeline content of type INote for timeline type of NOTE */
  static isTimelineItemContentNote = (arg: any): arg is INote => {
    return 'text' in arg;
  };

  static isTimelineItemContentFile = (arg: any): arg is ITimelineFileContent => {
    return 'fileResource' in arg;
  };
}

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import EducationApplicationListView from '@src/components/externalEducationApplication/list/EducationApplicationListView';
import ExternalEducationApplicationStatisticsContainer from '@src/components/externalEducationApplication/statistics/ExternalEducationApplicationStatisticsContainer';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ExpeneseTypeEnum } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { ICollectionData } from '@src/service/business/common/types';
import React from 'react';

// -- Prop types
// ----------
interface IAdminEducationApplicationReportListViewOwnProps {
  applicationList: ICollectionData<IExternalEducationApplication>;
  onPageChange: (page: number, pageSize?: number) => void;
}

type IAdminEducationApplicationReportListViewProps = IAdminEducationApplicationReportListViewOwnProps & IWithLocalizeOwnProps;

interface IAdminEducationApplicationReportListViewState {}

// -- Component
// ----------
/** Admin education application list view for report tab with additional columns */
class AdminEducationApplicationReportListView extends React.Component<IAdminEducationApplicationReportListViewProps, IAdminEducationApplicationReportListViewState> {
  state: IAdminEducationApplicationReportListViewState = {};

  render = () => {
    return (
      <React.Fragment>
        {/* Statistics */}
        <ExternalEducationApplicationStatisticsContainer />
        {/* Application list */}
        <EducationApplicationListView hideMyProfileColumn={true} additionalCols={this.educationApplicationReportCols} additionalSearchParams={false} scrollable={true} applicationList={this.props.applicationList} onPageChange={this.props.onPageChange} />
      </React.Fragment>
    );
  };

  private educationApplicationReportCols = (): Array<ITimunDataTableCol<IExternalEducationApplication>> => {
    return [
      {
        key: 'assigneeName',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.ASSIGNEE, record.activity),
      },
      {
        key: 'assigneeResidence',
        contentType: 'string',
        // colWidth: '20%',
        columnOptional: true,
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_RESIDENCE_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.ASSIGNEE, record.activity)?.address?.city.name,
      },
      {
        key: 'evaluatorName',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EVALUATOR_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.EVALUATOR, record.activity),
      },
      {
        key: 'employmentStatus',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_EMPLOYMENT_STATUS_BEFORE_EDUCATION_HEADER_TITLE'),
        columnOptional: true,
        content: (record: IExternalEducationApplication) => record.employmentStatus?.name && this.props.translate(`EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYMENT_STATUS.${record.employmentStatus.name}`),
      },
      {
        key: 'coordinatorHelpWanted',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.HZZ_COORDINATOR_HELP_WANTED_HEADER_TITLE'),
        columnOptional: true,
        content: (record: IExternalEducationApplication) => (record.consultation ? this.props.translate('COMMON.LABEL.YES') : this.props.translate('COMMON.LABEL.NO')),
      },
      {
        key: 'educationRelated',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_RELEATED_HEADER_TITLE'),
        columnOptional: true,
        content: (record: IExternalEducationApplication) => (record.educationRelated ? this.props.translate('COMMON.LABEL.YES') : this.props.translate('COMMON.LABEL.NO')),
      },
      {
        key: 'jobRelated',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.JOB_RELATED_HEADER_TITLE'),
        columnOptional: true,
        content: (record: IExternalEducationApplication) => (record.jobRelated ? this.props.translate('COMMON.LABEL.YES') : this.props.translate('COMMON.LABEL.NO')),
      },
      {
        key: 'educationDuration',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_DURATION_HEADER_TITLE'),
        columnOptional: true,
        content: (record: IExternalEducationApplication) => record.externalEducationTemplate?.duration,
      },
      {
        key: 'educationExpense',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_EXPENSE_HEADER_TITLE'),
        columnOptional: true,
        content: (record: IExternalEducationApplication) => record.expenses && (EducationApplicationHelperUtils.getEducationApplicationExpenseValueByExpenseType(ExpeneseTypeEnum.EDUCATION_OFFER_PRICE, record.expenses) ?? ''),
      },
      {
        key: 'travelExpense',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.TRAVEL_EXPENSE_HEADER_TITLE'),
        columnOptional: true,
        content: (record: IExternalEducationApplication) => record.expenses && (EducationApplicationHelperUtils.getEducationApplicationExpenseValueByExpenseType(ExpeneseTypeEnum.TRAVEL_EXPENSE, record.expenses) ?? ''),
      },
    ];
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IAdminEducationApplicationReportListViewOwnProps>(AdminEducationApplicationReportListView as any);

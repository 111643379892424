import AppContent from '@src/components/common/container/AppContent';
import FileList from '@src/components/common/file/FileList';
import FileTypeUploadModal from '@src/components/common/file/FileTypeUploadModal';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IFile } from '@src/model/file/File';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';

const timeFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');

// -- Prop types
// ----------

interface IUserDocumentsListViewOwnProps {
  userFileList: ICollectionData<IFile>;
  canEditDocuments?: boolean;
  onPageChange: (page: number, size?: number) => void;
  onUploadFile?: (data: IFile[]) => void;
  onRemoveFile?: (data: IFile) => void;
}

type IUserDocumentsListViewProps = IUserDocumentsListViewOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationFilesViewSectionState {
  isFileListUploadModalVisible: boolean;
}

// -- Component
// ----------

class UserDocumentsListView extends React.Component<IUserDocumentsListViewProps> {
  state: IEducationApplicationFilesViewSectionState = {
    isFileListUploadModalVisible: false,
  };

  handleModalOpen = () => this.setState({ isFileListUploadModalVisible: true });
  handleModalClose = () => this.setState({ isFileListUploadModalVisible: false });

  handleFileUpload = (file: IFile[]) => {
    this.props.onUploadFile?.(file);
    this.handleModalClose();
  };


  render = () => {
    return (
      <AppContent
        title={
          <Row justify="end">
            <Col>
              {this.props.canEditDocuments && <Button type="primary" onClick={this.handleModalOpen} icon={<LemonIcon name="plus" />}>{this.props.translate('COMMON.ACTION_ADD_FILE')}</Button>}
            </Col>
          </Row>
        }
      >
        {this.props.userFileList && <ListPagination page={this.props.userFileList.page} onChange={this.props.onPageChange} />}
        <FileList<IFile>
          fileList={this.props.userFileList ? this.props.userFileList.content : []}
          fileColWidth={10}
          renderAdditionalCols={this.additionalCols}
          showActions={this.props.canEditDocuments}
          onRemoveFile={this.props.onRemoveFile}
        />
        {this.props.userFileList && <ListPagination page={this.props.userFileList.page} onChange={this.props.onPageChange} />}

        {this.state.isFileListUploadModalVisible &&
          <FileTypeUploadModal defaultFileType="GENERAL" allowedFileTypes={['GENERAL', 'ID_CARD', 'COMPLETED_EDUCATION_CERTIFICATE']} onCancel={this.handleModalClose} onFileUpload={this.handleFileUpload} />
        }
      </AppContent>
    );
  };

  private additionalCols = (): IListAdditionalCol[] => {
    return [
      {
        key: 'author',
        responsiveWidth: { lg: 6, md: 7, xs: 0 },
        headerTitle: this.props.translate('USER_VIEW.DOCUMENTS_TABLE_AUTHOR_LABEL'),
        content: (file: IFile) => <UserNameView user={file.creator} fallback={this.props.translate('USER_VIEW.USER_DELETED_LABEL')} />,
      },
      {
        key: 'createdAt',
        responsiveWidth: { lg: 6, md: 5, xs: 10 },
        headerTitle: this.props.translate('USER_VIEW.DOCUMENTS_TABLE_TIME_LABEL'),
        content: (file: IFile) => moment(file.addedDateTime).format(timeFormat),
        className: 'timun-userView__fileListCol',
      },
    ];
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IUserDocumentsListViewOwnProps>(UserDocumentsListView as any);

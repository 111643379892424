import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

export interface IAppFooterPublicProps {}

interface IAppFooterStateProps {
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}

type IAppFooterProps = IAppFooterPublicProps & IWithLocalizeOwnProps & IAppFooterStateProps & IWithTenantPropEnabledOwnProps;

class AppFooter extends React.Component<IAppFooterProps> {
  renderDefaultFooter = () => {
    return (
      <React.Fragment>
        {this.props.translate('APPLICATION.APP_DESCRIPTION_MADE_WITH')}
        &nbsp;
        <LemonImage className="lemon-application__footerIcon" imagePath="heart.svg" title="love" />
        &nbsp;&amp;&nbsp;
        <LemonImage className="lemon-application__footerIcon" imagePath="lemon.svg" title="lemons" />
        &nbsp;
        {this.props.translate('APPLICATION.APP_DESCRIPTION_MADE_BY')}
        &nbsp; &copy; {moment().format('YYYY')}
        {/*
        &nbsp;&nbsp;
        <Link to="/home">Test</Link>
        */}
      </React.Fragment>
    );
  };

  render() {
    const footer = this.props.tenantOrganizationConfiguration.configuration?.footerHtml;
    return (
      <React.Fragment>
        {/* Apply tenant footer, fallback to default */}
        {footer ? <div dangerouslySetInnerHTML={{ __html: footer }} /> : this.renderDefaultFooter()}
      </React.Fragment>
    );
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAppFooterPublicProps): IAppFooterStateProps => ({
  tenantOrganizationConfiguration: PublicTenantOrganizationConfigurationBusinessStore.selectors.getTenantOrganizationConfiguration(state),
});

export default connect<IAppFooterStateProps, undefined, IAppFooterPublicProps>(mapStateToProps)(withLocalize(withTenantPropEnabled(AppFooter) as any));

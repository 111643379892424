import AppContent from '@src/components/common/container/AppContent';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import UserGroupListView from '@src/components/usergroup/list/UserGroupListView';
import UserGroupCreateContainer from '@src/components/usergroup/update/UserGroupCreateContainer';
import UserGroupUpdateContainer from '@src/components/usergroup/update/UserGroupUpdateContainer';
import { IFile } from '@src/model/file/File';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import { ICollectionData } from '@src/service/business/common/types';
import { IUserGroupListFilter } from '@src/service/business/usergroup/UserGroupListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Row, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';

// -- Prop types
// ----------

interface IAdminUserGroupListViewOwnProps {
  groupType: UserGroupTypeEnum;
  userGroupList: ICollectionData<IUserGroup>;
  userGroupHierarchy: IUserGroupHierarchy;
  userGroupListFilter?: IUserGroupListFilter;
  returnRoute?: string;
  onDataUpdate: () => void;
  onPageChange: (page: number, size?: number) => void;
  onUserGroupDelete: (userGroupId: string) => void;
  onUserGroupCoverSubmit: (data: IFile, selectedUserGroup: IUserGroup) => void;
}
type IAdminUserGroupListViewProps = IAdminUserGroupListViewOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------
const ADMIN_USER_GROUP_ROUTE = AppConfigService.getValue('components.userGroups.adminUserGroupRouterProp');
const ADMIN_ORGANIZATION_GROUP_ROUTE = AppConfigService.getValue('components.userGroups.adminOrganizationGroupRouterProp');

const AdminUserGroupListView: React.FC<IAdminUserGroupListViewProps> = (props: IAdminUserGroupListViewProps) => {
  const [userGroupModalsState, toggleUserGroupModals] = useEntityModalsState<IUserGroup>();
  const basePath = useMemo(() => (props.groupType === UserGroupTypeEnum.ORGANIZATION ? ADMIN_ORGANIZATION_GROUP_ROUTE : ADMIN_USER_GROUP_ROUTE), [props.groupType]);
  const title = useMemo(() => (props.groupType === UserGroupTypeEnum.ORGANIZATION ? props.translate('ORGANIZATION_GROUP.NEW_GROUP_TITLE') : props.translate('USER_GROUP.NEW_GROUP_TITLE')), [props.groupType, props.translate]);

  const handleUserGroupCreateSubmit = useCallback(() => {
    toggleUserGroupModals.onCloseCreate();
    props.onDataUpdate();
  }, [toggleUserGroupModals.onCloseCreate, props.onDataUpdate]);

  const handleUserGroupUpdateSubmit = useCallback(() => {
    toggleUserGroupModals.onCloseUpdate();
    props.onDataUpdate();
  }, [toggleUserGroupModals.onCloseUpdate, props.onDataUpdate]);

  const handleUserGroupCoverSubmit = useCallback(
    (data: IFile) => {
      toggleUserGroupModals.onCloseEntity();
      if (userGroupModalsState.selectedEntity) {
        props.onUserGroupCoverSubmit(data, userGroupModalsState.selectedEntity);
      }
    },
    [toggleUserGroupModals.onCloseEntity, userGroupModalsState.selectedEntity, props.onUserGroupCoverSubmit]
  );

  return (
    <AppContent
      title={
        <Row justify="end">
          <Space size={'small'}>
            <Col>
              <Button type="primary" icon={<LemonIcon name="plus" />} onClick={toggleUserGroupModals.onOpenCreate}>
                {title}
              </Button>
            </Col>
          </Space>
        </Row>
      }
    >
      {/* ----- User group list ----- */}
      {props.userGroupList && (
        <UserGroupListView
          emptyMessage={props.groupType === UserGroupTypeEnum.BASIC ? props.translate('USER_GROUP.NO_DATA') : props.translate('ORGANIZATION_GROUP.NO_DATA')}
          basePath={basePath}
          returnRoute={props.returnRoute}
          showButtons={true}
          userGroupList={props.userGroupList}
          userGroupListFilter={props.userGroupListFilter}
          onPageChange={props.onPageChange}
          onUserGroupEdit={toggleUserGroupModals.onOpenUpdate}
          onUserGroupCoverEdit={toggleUserGroupModals.onOpenEntity}
          onUserGroupDelete={props.onUserGroupDelete}
          userGroupHierarchy={props.groupType === UserGroupTypeEnum.ORGANIZATION ? props.userGroupHierarchy : undefined}
          onUserGroupHierachyUpdate={props.groupType === UserGroupTypeEnum.ORGANIZATION ? props.onDataUpdate : undefined}
        />
      )}

      {/* ----- User group update modals ----- */}
      {userGroupModalsState.isCreateModalVisible && <UserGroupCreateContainer onSubmit={handleUserGroupCreateSubmit} onCancel={toggleUserGroupModals.onCloseCreate} groupType={props.groupType} />}

      {userGroupModalsState.isUpdateModalVisible && userGroupModalsState.selectedEntity && (
        <UserGroupUpdateContainer userGroup={userGroupModalsState.selectedEntity} onSubmit={handleUserGroupUpdateSubmit} onCancel={toggleUserGroupModals.onCloseUpdate} groupType={props.groupType} parentGroup={userGroupModalsState.selectedEntity.parent} />
      )}

      {userGroupModalsState.isEntityModalVisible && <ImageUploadForm onSubmit={handleUserGroupCoverSubmit} onClose={toggleUserGroupModals.onCloseEntity} />}
    </AppContent>
  );
};

// -- HOCs and exports
// ----------

export default withTenantPropEnabled<IAdminUserGroupListViewOwnProps>(withLocalize(AdminUserGroupListView as any));

import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICollectionData } from '@src/service/business/common/types';

import ActivityShortListView from '@src/components/activity/ActivityShortListView';
import { IUserActivity } from '@src/model/activity/UserActivity';
import userActivityListBusinessStore, { IUserActivityListFilter } from '@src/service/business/user/userActivityListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

// -- Prop types
// ----------
interface IActivityShortListContainerOwnProps { }

interface IActivityShortListContainerStateProps {
  userActivityList: ICollectionData<IUserActivity>;
}
interface IActivityShortListContainerDispatchProps {
  fetchList: (listFilter: IUserActivityListFilter, page: number, size: number, sort: string[]) => any;
  clearList: () => void;
}
type IActivityShortListContainerProps = IActivityShortListContainerOwnProps & IActivityShortListContainerStateProps & IActivityShortListContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class ActivityShortListContainer extends React.Component<IActivityShortListContainerProps> {
  componentDidMount = () => {
    this.fetchActivityList();
  };

  componentWillUnmount = () => {
    this.props.clearList();
  };

  render = () => {
    return <ActivityShortListView userActivityList={this.props.userActivityList ? this.props.userActivityList.content : []} />;
  };

  private fetchActivityList = () => {
    this.props.fetchList({}, 0, AppConfigService.getValue('api.paging.smallPageSize'), []);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IActivityShortListContainerStateProps => ({
  userActivityList: userActivityListBusinessStore.selectors.getList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IActivityShortListContainerDispatchProps => ({
  fetchList: (listFilter: IUserActivityListFilter, page: number, size: number, sort: string[]) => dispatch(userActivityListBusinessStore.actions.fetchList(listFilter, page, size, sort)),
  clearList: () => dispatch(userActivityListBusinessStore.actions.clearList()),
});

// TODO: improve component public props API when using third party HOCs
// type assertion to 'any' to because 'withRouter' doesn't do type reduction so injected props types are appearing in public props API
export default connect<IActivityShortListContainerStateProps, IActivityShortListContainerDispatchProps, IActivityShortListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(ActivityShortListContainer)) as any);

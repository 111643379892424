import React from 'react';

import AppContent from '@src/components/common/container/AppContent';
import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import TotalEmploymentDurationInputField from '@src/components/user/form/TotalEmploymentDurationInputField';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { DateUtils } from '@src/service/util/date/DateUtils';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Button, Col, Row, Typography } from 'antd';


// -- Prop types
// ----------

export interface IUserAdditionalInfoViewOwnProps {
  user: IUserInfo;
  onNotzUpdate?: () => void;
}
type IUserAdditionalInfoViewProps = IUserAdditionalInfoViewOwnProps & IWithRolesOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const UserAdditionalInfoView: React.FC<IUserAdditionalInfoViewProps> = (props: IUserAdditionalInfoViewProps) => {

  return (
    <AppContent>
      {/* ---------- About me ---------- */}
      <div className="panel">
        <Typography.Title className="timun-userModalForm__sectionTitle" level={4}> {props.translate('USER_FORM.ABOUT_ME_PANEL_TITLE')} </Typography.Title>
        <Row gutter={8} justify="start">
          <Col span={12}>
            {/* -- Identification Number - OIB -- */}
            <GridItem
              label={props.translate('USER_FORM.OIB_LABEL')}>
              {props.user.identificationNumber}
            </GridItem>
          </Col>
          <Col span={12}>
            {/* -- Unique Master Citizen Number Number - MBG -- */}
            <GridItem
              label={props.translate('USER_FORM.MBG_LABEL')}>
              {props.user.extendedData?.uniqueMasterCitizenNumber}
            </GridItem>
          </Col>
          <Col span={12}>
            {/* -- Citizenship -- */}
            <GridItem
              label={props.translate('USER_FORM.CITIZENSHIP_LABEL')}>
              {props.user.extendedData?.citizenship}
            </GridItem>
          </Col>
          <Col span={12}>
            {/* -- Date Of Birth -- */}
            <GridItem
              label={props.translate('USER_FORM.DATE_OF_BIRTH_LABEL')}>
              {DateUtils.toViewDateFormat(props.user.dateOfBirth, 'dateWithYear') ?? props.translate('COMMON.LABEL.MISSING_DATA')}
            </GridItem>
          </Col>
          <Col span={12}>
            {/* -- Gender -- */}
            <GridItem
              label={props.translate('USER_FORM.GENDER_LABEL')} >
              {props.user.gender && props.translate(`USER_FORM.GENDER_LABEL_${props.user.gender}`)}
            </GridItem>
          </Col>
        </Row>
      </div>
      {/* ---------- Residence ---------- */}
      <div className="panel">
        <Typography.Title className="timun-userModalForm__sectionTitle" level={4}> {props.translate('USER_FORM.RESIDENCE_PANEL_TITLE')} </Typography.Title>
        <Row gutter={8} justify="start">
          <Col span={12}>
            {/* -- ResidenceAddress Type -- */}
            <GridItem
              label={props.translate('USER_FORM.RESIDENCE_TYPE_LABEL')}>
              {props.user.residenceAddress?.type?.name && props.translate(`USER_FORM.RESIDENCE_TYPE_${props.user.residenceAddress.type.name}_LABEL`)}
            </GridItem>
          </Col>
          <Col span={12}>
            <GridItem
              label={props.translate('USER_FORM.ADDRESS_ZIP_LABEL')}>
              {props.user.residenceAddress?.address?.zip}
            </GridItem>
          </Col>
          <Col span={12}>
            <GridItem
              label={props.translate('USER_FORM.ADDRESS_COUNTRY_LABEL')}>
              {props.user.residenceAddress?.address?.country}
            </GridItem>
          </Col>
        </Row>
      </div>

      {/* ---------- Qualifications ---------- */}
      <div className="panel">
        <Typography.Title className="timun-userModalForm__sectionTitle" level={4}>{props.translate('USER_FORM.ACQUIRED_QUALIFICATION_PANEL_TITLE')} </Typography.Title>
        <Row gutter={8} justify="start">
          <Col span={12}>
            {/* -- Educational Program -- */}
            <GridItem
              label={props.translate('USER_FORM.EDUCATION_PROGRAM_LABEL')}>
              {props.user.educationData?.educationalProgram}
            </GridItem>
          </Col>
          <Col span={12}>
            {/* -- Educational Institution -- */}
            <GridItem
              label={props.translate('USER_FORM.EDUCATION_INSTITUTION_LABEL')} >
              {props.user.educationData?.educationalInstitution}
            </GridItem>
          </Col>
          <Col span={12}>
            {/* -- International Qualifications Accepted -- */}
            <GridItem
              label={props.translate('USER_FORM.INTERNATIONAL_QUALIFICATIONS_ACCEPTED_LABEL')}>
              {props.user.extendedData?.internationalQualificationsAccepted ? props.translate('COMMON.ACTION_YES') : props.translate('COMMON.ACTION_NO')}
            </GridItem>
          </Col>
        </Row>
        {/* -- Total Employment Duration -- */}
        <GridItem label={props.translate('USER_FORM.TOTAL_EMPLOYMENT_DURATION_LABEL')}>
          <TotalEmploymentDurationInputField value={props.user.workData?.totalEmploymentDuration} disabled={true} />
        </GridItem>
      </div>

      {/* ---------- Notz ---------- */}
      {props.isTenantPropEnabled('notz') && RoleUtils.allowedRoles([UserRoleEnum.COORDINATOR, UserRoleEnum.ORGANIZATION_ADMIN], props.currentUser.roles) && <div className="panel">
        <Row justify="space-between">
          <Col>
            <Typography.Title className="timun-userModalForm__sectionTitle" level={4}>{props.translate('USER_FORM.NOTZ_PANEL_TITLE')} </Typography.Title>
          </Col>
          <Col>
            <Button onClick={props.onNotzUpdate}> {props.translate('USER_FORM.NOTZ.NOTZ_SYNC_BUTON_LABEL')} </Button>
          </Col>
        </Row>
        <Row gutter={8} justify="start">
          <Col span={12}>
            {/* -- notz sync date time -- */}
            <GridItem
              label={props.translate('USER_FORM.NOTZ.NOTZ_SYNC_DATE_TIME_LABEL')} >
              {DateUtils.toViewDateFormat(props.user.extendedData?.notzSyncDateTime, 'dateWithTime')}
            </GridItem>
          </Col>
          <Col span={12}>
            {/* -- notz Last Check In Date -- */}
            <GridItem
              label={props.translate('USER_FORM.NOTZ.NOTZ_LAST_CHECK_IN_DATE_LABEL')} >
              {DateUtils.toViewDateFormat(props.user.extendedData?.notzLastCheckInDate, 'dateWithTime') ?? props.translate('COMMON.LABEL.MISSING_DATA')}
            </GridItem>
          </Col>
          <Col span={12}>
            {/* -- notz Last Check Out Date -- */}
            <GridItem
              label={props.translate('USER_FORM.NOTZ.NOTZ_LAST_CHECK_OUT_DATE_LABEL')}>
              {DateUtils.toViewDateFormat(props.user.extendedData?.notzLastCheckOutDate, 'dateWithTime') ?? props.translate('COMMON.LABEL.MISSING_DATA')}
            </GridItem>
          </Col>
          <Col span={12}>
            {/* -- notz Status -- */}
            <GridItem
              label={props.translate('USER_FORM.NOTZ.NOTZ_STATUS_LABEL')}>
              {props.user.extendedData?.notzStatus ?? props.translate('COMMON.LABEL.MISSING_DATA')}
            </GridItem>
          </Col>
        </Row>
      </div>}
    </AppContent>
  );
};

export default withLocalize<IUserAdditionalInfoViewOwnProps>(withRoles(withTenantPropEnabled(UserAdditionalInfoView as any)));

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import UserManagementList from '@src/components/user/list/UserManagementList';
import UserModal from '@src/components/user/modal/UserModal';
import { IUserInfo } from '@src/model/user/User';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ICollectionData } from '@src/service/business/common/types';
import { Button, Col, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------
export interface IUserManagementListViewOwnProps {
  userList: ICollectionData<IUserInfo>;
  workPositionList?: IWorkPosition[];
  canCreateUser?: boolean;
  canEnrollUserCourse?: boolean;
  selectableTable?: boolean;
  isEditing?: boolean;
  setUserEdit?: (visible: boolean) => void;
  onSelectedUserSubmit?: (data: IUserInfo[]) => void;
  onPageChange?: (page: number, pageSize?: number) => void;
  onUserSubmit?: (data: IUserInfo) => void;
  additionalCols?: () => ITimunDataTableCol<IUserInfo>[];
}

export interface IUserManagementListViewStateProps {}

export interface IUserManagementListViewDispatchProps {}

type IUserManagementListViewProps = IUserManagementListViewOwnProps & IUserManagementListViewStateProps & IUserManagementListViewDispatchProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

interface IUserManagementListViewState {}

// -- Component
// ----------
/** Admin user management list view */
class UserManagementListView extends React.Component<IUserManagementListViewProps, IUserManagementListViewState> {
  state: IUserManagementListViewState = {};

  render = () => {
    return (
      <React.Fragment>
        {/* Action */}
        <Row className="timun-wrapper__gutterBox--vertical" gutter={[4, 4]} justify="end">
          {this.props.canCreateUser && (
            <Col>
              <Button type="primary" icon={<LemonIcon name="plus" />} onClick={() => this.props.setUserEdit?.(true)}>
                {this.props.translate('USER_VIEW_CONTAINER.CREATE_USER')}
              </Button>
            </Col>
          )}
        </Row>
        {/* List */}
        {this.props.userList && (
          <UserManagementList
            showSelectedElementsPanel={true}
            selectableTable={this.props.selectableTable}
            userList={this.props.userList.content}
            paginationData={this.props.userList.page}
            onPageChange={this.props.onPageChange}
            canEnrollUserCourse={this.props.canEnrollUserCourse}
            onSelectedUserSubmit={this.props.onSelectedUserSubmit}
            additionalCols={this.props.additionalCols}
            showAction={true}
          />
        )}
        {/* Modal */}
        {this.props.isEditing && this.props.workPositionList && <UserModal workPositionList={this.props.workPositionList} allowPasswordCreate={true} allowUserStatusChange={true} allowRoleChange={true} setUserEdit={this.props.setUserEdit} onUserSubmit={this.props.onUserSubmit} />}
      </React.Fragment>
    );
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IUserManagementListViewOwnProps>(withTenantPropEnabled(UserManagementListView as any));

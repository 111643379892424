import LemonIcon from '@src/components/common/image/LemonIcon';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { RouterNavigationPromptContext } from '@src/components/common/route/prompt/RouterNavigationPromptContext';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExternalContentDataForm from '@src/components/externalcontent/create/ExternalContentDataForm';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { IFile } from '@src/model/file/File';
import { IExternalContentCreatePayload } from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import { Button, Layout, Row } from 'antd';
import IframeResizer from 'iframe-resizer-react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

const { Header, Content } = Layout;

// -- Prop types
// ----------

interface IExternalContentViewOwnProps {
  externalContent?: IExternalContent;
  canEdit?: boolean;
  isEditing?: boolean;
  onUpdate?: (data: IExternalContentCreatePayload) => void;
  onDelete?: () => void;
  onCoverUpload?: (data: IFile) => void;
  setEditing?: (isEditing: boolean) => void;
}

type IExternalContentViewProps = IExternalContentViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
/**
 * Content displays eternal web content in an IFRAME element.
 *
 * Unresizable content is content not under our control thus we cannot setup resizer.
 * We should introduce content types in order to make appropriate assumptions about content sizing.
 */
const ExternalContentView = (props: IExternalContentViewProps) => {
  const [isDeleteAction, setIsDeleteAction] = useState<boolean>(false);
  const { setIsRouterPromptActive } = useContext(RouterNavigationPromptContext);
  useEffect(() => {
    if (props.canEdit && props.isEditing && !isDeleteAction) {
      setIsRouterPromptActive?.(true);
    }
    if (!props.isEditing) {
      setIsRouterPromptActive?.(false);
    }
  }, [setIsRouterPromptActive, props.canEdit, props.isEditing, isDeleteAction]);

  const header = useMemo(() => {
    if (props.externalContent) {
      return (
        <Row justify="space-between">
          <HeaderTitle link={'/externalcontents'} icon={<LemonIcon name="left" />} title={props.externalContent.title} />
          {props.canEdit && !props.isEditing && <Button onClick={() => props.setEditing && props.setEditing(true)}>{props.translate('COMMON.ACTION_EDIT')}</Button>}
        </Row>
      );
    }
    return;
  }, [props.setEditing, props.externalContent]);

  /**
   * We've successfully initialized resizer.
   *
   * If we've initialized resizer adjust some props for resizable content.
   */
  const handleIframeResizerInit = (iframe: HTMLIFrameElement) => {
    // remove "unresizable" content styles
    iframe.classList.remove('timun-externalContentView__content--unresizable');

    // scrolling is ommited by default so we'll disable it only for resizable content
    iframe.scrolling = 'no';
  };

  const handleDelete = useCallback(() => {
    setIsRouterPromptActive?.(false);
    setIsDeleteAction(true);
    props.onDelete?.();
  }, [setIsDeleteAction, props.onDelete, setIsRouterPromptActive]);

  const handleSubmit = useCallback(
    (data: IExternalContentCreatePayload) => {
      setIsRouterPromptActive?.(false);
      props.onUpdate?.(data);
    },
    [props.onUpdate, setIsRouterPromptActive]
  );

  return (
    <Layout>
      <Header className="timun-externalContentView__header">{header}</Header>
      <Content className="timun-externalContentView__container panel">
        <Layout>
          {props.isEditing && props.onCoverUpload ? (
            <ExternalContentDataForm externalContent={props.externalContent} onCoverSubmit={props.onCoverUpload} onSubmit={handleSubmit} onDelete={handleDelete} onCancel={props.setEditing} />
          ) : (
            props.externalContent && (
              <React.Fragment>
                {props.externalContent.description && <div className="timun-externalContentView__description">{props.externalContent.description}</div>}

                <div className="timun-externalContentView__contentContainer">
                  <IframeResizer className="timun-externalContentView__content timun-externalContentView__content--unresizable" src={props.externalContent.url} checkOrigin={false} heightCalculationMethod="lowestElement" onInit={handleIframeResizerInit} scrolling="omit" />
                </div>
              </React.Fragment>
            )
          )}
        </Layout>
      </Content>
    </Layout>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IExternalContentViewOwnProps>(ExternalContentView as any);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IProgressTracker, ProgressTrackerTypeEnum } from '@src/model/progresstracker/ProgressTracker';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import ProgressTrackerListBuisnessStore, { IProgressTrackerListFilter } from '@src/service/business/progresstracker/ProgressTrackerListBuisnessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import UserGroupBusinessStore from '@src/service/business/usergroup/UserGroupBusinessStore';
import LdapView from '@src/components/usergroup/ldapsync/LdapView';

const PROGRESS_TRACKER_LIST_VIEW_NAME = '@@PROGRESS_TRACKER_LIST_CONTAINER';
const DEFAULT_PAGE_SIZE: number = AppConfigService.getValue('api.paging.defaultPageSize');

export interface ILdapContainerOwnProps {
  type: ProgressTrackerTypeEnum;
}

export interface ILdapContainerStateProps {
  progressTrackerList: ICollectionData<IProgressTracker>;
}

export interface ILdapContainerDispatchProps {
  fetchProgressTrackerList: (params: ICollectionFetchPayload<IProgressTrackerListFilter>) => void;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  syncldap: () => ITrackableAction;
}

type ILdapContainerProps = ILdapContainerOwnProps & ILdapContainerStateProps & ILdapContainerDispatchProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

const LdapContainer: React.FC<ILdapContainerProps> = (props: ILdapContainerProps) => {
  const handleProgressTrackerListUpdate = () => {
    onUpdateList(props.fetchProgressTrackerList, { ...collectionParams.filter, type: props.type });
  };

  const initialCollectionValues = useMemo(() => ({ page: 0, size: DEFAULT_PAGE_SIZE, sort: [] }), []);
  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IProgressTrackerListFilter>(
    {
      viewName: PROGRESS_TRACKER_LIST_VIEW_NAME,
      updateFn: handleProgressTrackerListUpdate,
      initialValues: initialCollectionValues,
    },
    [props.type]
  );

  const handleLdapSync = useCallback(() => {
    props
      .syncldap()
      .track()
      .subscribe(() => {
        props.reportMessage({ message: props.translate('USER_VIEW_CONTAINER.LDAP_SYNC_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
      });
    onUpdateList();
  }, [props.syncldap, props.reportMessage]);

  return (
    <React.Fragment>
      <LdapView onLdapSync={handleLdapSync} progressTrackerList={props.progressTrackerList} type={props.type} onPageChange={updateCollectionParams.onPageChange} onReload={onUpdateList} />
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ILdapContainerOwnProps): ILdapContainerStateProps => ({
  progressTrackerList: ProgressTrackerListBuisnessStore.selectors.getProgressTrackerList(state),
});
// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: ILdapContainerOwnProps): ILdapContainerDispatchProps => ({
  fetchProgressTrackerList: (params: ICollectionFetchPayload<IProgressTrackerListFilter>) => dispatch(ProgressTrackerListBuisnessStore.actions.fetchProgressTrackerList(params)),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  syncldap: () => dispatch(createTrackableAction(UserGroupBusinessStore.actions.userGroupLdapSync())),
});

export default connect<ILdapContainerStateProps, ILdapContainerDispatchProps, ILdapContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter<ILdapContainerOwnProps>(LdapContainer as any)));

import { Observable } from 'rxjs';

import { ICourse } from '@src/model/course/Course';
import EntityApiService from '@src/service/api/registry/entity/EntityApiService';
import { IHttpRestClientOptions } from '@src/service/api/rest/IHttpRestClient';
import IAbstractEntityApi from '@src/service/util/api/IAbstractEntityApi';

export default class CourseApiService extends EntityApiService<ICourse> {
  static METHODS = {
    SCROM_IMPORT: 'scormimport',
  };

  /** Service API factory method. */
  static create(entityName: string, api: IAbstractEntityApi<any, any>): CourseApiService {
    return new CourseApiService(entityName, api);
  }

  /** Upload and import SCORM archive. */
  scormImport(body?: object, options?: IHttpRestClientOptions): Observable<ICourse> {
    // TODO: this should call createMethod, but backend API isn't finished so we're targeting static json file which can accept only fetch
    return this.createMethod(CourseApiService.METHODS.SCROM_IMPORT, body, options);
  }
}

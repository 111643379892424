import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Form, Modal, Select } from 'antd';
import { IReportTypeEnum } from '@src/model/report/Report';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import UserGroupPicker from '@src/components/common/datapicker/UserGroupPicker';
import CoursePicker from '@src/components/common/datapicker/CoursePicker';
import { IReportCreatePayload } from '@src/service/business/reports/reportsBusinessStore';
import EducationApplicationReportListFilterForm from '@src/components/externalEducationApplication/filter/EducationApplicationReportListFilterForm';
import { UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';

// -- Const
// ----------
const Option = Select.Option;

// -- Prop types
// ----------
export interface ICourseReportModalOwnProps {
  onSubmit: (data: IReportCreatePayload) => void;
  onCancel: () => void;
}
type ICourseReportModalProps = ICourseReportModalOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const CourseReportModal: React.FC<ICourseReportModalProps> = (props: ICourseReportModalProps) => {
  const [form] = Form.useForm();
  const selectedReportType = Form.useWatch('reportType', form);
  const handleSubmit = (values: IReportCreatePayload) => props.onSubmit?.(values);

  /** Check if selected report type is in one of requestedTypes */
  const isReportTypeInList = (reportType: IReportTypeEnum, requestedTypes: (keyof typeof IReportTypeEnum)[]): boolean => {
    return requestedTypes.some((type) => reportType === type);
  };

  return (
    <Modal className="lemon-modal__modal--md" title={props.translate('COURSE_REPORT_MODAL.TITLE')} visible={true} okText={props.translate('COURSE_REPORT_MODAL.OK_TEXT')} onOk={form.submit} cancelText={props.translate('COMMON.ACTION_CANCEL')} onCancel={props.onCancel}>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item name={'reportType'} label={props.translate('COURSE_REPORT_MODAL.FORM.REPORT_TYPE_LABEL')} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
          <Select>
            {props.isTenantPropEnabled('courses') && (
              <Option value={IReportTypeEnum.COURSE} key={IReportTypeEnum.COURSE}>
                {props.translate(`REPORTS_LIST.TYPE_${IReportTypeEnum.COURSE}`)}
              </Option>
            )}
            {props.isTenantPropEnabled('courses') && (
              <Option value={IReportTypeEnum.COURSE_TRAINEE} key={IReportTypeEnum.COURSE_TRAINEE}>
                {props.translate(`REPORTS_LIST.TYPE_${IReportTypeEnum.COURSE_TRAINEE}`)}
              </Option>
            )}
            {props.isTenantPropEnabled('courses') && (
              <Option value={IReportTypeEnum.GENERAL_COURSE} key={IReportTypeEnum.GENERAL_COURSE}>
                {props.translate(`REPORTS_LIST.TYPE_${IReportTypeEnum.GENERAL_COURSE}`)}
              </Option>
            )}
            {props.isTenantPropEnabled('externaleducationapplications') && (
              <Option value={IReportTypeEnum.EXTERNAL_EDUCATION_APPLICATION} key={IReportTypeEnum.EXTERNAL_EDUCATION_APPLICATION}>
                {props.translate(`REPORTS_LIST.TYPE_${IReportTypeEnum.EXTERNAL_EDUCATION_APPLICATION}`)}
              </Option>
            )}
          </Select>
        </Form.Item>
        {props.isTenantPropEnabled('organization_group_courses') && (
          <React.Fragment>
            {isReportTypeInList(selectedReportType, ['COURSE', 'COURSE_TRAINEE', 'GENERAL_COURSE']) ? (
              <Form.Item name={['filters', 'userGroupIds']} label={props.translate('COURSE_REPORT_MODAL.FORM.USER_GROUPS_LABEL')}>
                <UserGroupPicker filter={{ type: UserGroupTypeEnum.ORGANIZATION }} />
              </Form.Item>
            ) : null}
          </React.Fragment>
        )}

        <React.Fragment>
          {isReportTypeInList(selectedReportType, ['COURSE']) ? (
            <Form.Item name={['filters', 'courseId']} label={props.translate('COURSE_REPORT_MODAL.FORM.COURSES_LABEL')} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
              <CoursePicker />
            </Form.Item>
          ) : null}
        </React.Fragment>
        <React.Fragment>
          {isReportTypeInList(selectedReportType, ['EXTERNAL_EDUCATION_APPLICATION']) ? (
            <Form.Item name={['filters']} label={props.translate('COURSE_REPORT_MODAL.FORM.EXTERNAL_EDUCATION_APPLICATION_LABEL')}>
              <EducationApplicationReportListFilterForm isFormComponent={false} />
            </Form.Item>
          ) : null}
        </React.Fragment>
      </Form>
    </Modal>
  );
};

export default withLocalize<ICourseReportModalOwnProps>(withTenantPropEnabled(CourseReportModal as any));

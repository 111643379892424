import { FormComponentProps } from '@ant-design/compatible/lib/form';
import UserGroupDataPicker from '@src/components/common/datapicker/UserGroupDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Form, Row } from 'antd';
import moment, { Moment } from 'moment';
import React, { useCallback } from 'react';

// --
// ----- Prop types
const FormItem = Form.Item;

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const apiDateFormat = AppConfigService.getValue('dateTimeFormat.backendDate');

interface ICoursesOverviewListFilterOwnProps {
  value?: ICourseListFilter;
  onChange?: (listFilter: ICourseListFilter) => void;
}

type ICoursesOverviewListFilterProps = ICoursesOverviewListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// --
// ----- Courses report list filter
const CoursesOverviewListFilter: React.FC<ICoursesOverviewListFilterProps> = (props: ICoursesOverviewListFilterProps) => {
  const [form] = Form.useForm<ICourseListFilter>();
  const disabledStartDate = useCallback(
    (startValue?: Moment) => {
      const endValue = form.getFieldValue('traineeStatsTo');
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    [form.getFieldValue]
  );

  const disabledEndDate = useCallback(
    (endValue?: Moment) => {
      const startValue = form.getFieldValue('traineeStatsFrom');
      if (!endValue || !startValue) {
        return false;
      }
      return endValue.valueOf() <= startValue.valueOf();
    },
    [form.getFieldValue]
  );

  const handleFinish = useCallback(
    (values: ICourseListFilter) => {
      const listFilter: ICourseListFilter = {
        traineeStatsFrom: values.traineeStatsFrom
          ? moment(values.traineeStatsFrom)
              .startOf('day')
              .format(apiDateFormat)
              .toString()
          : undefined,
        traineeStatsTo: values.traineeStatsTo
          ? moment(values.traineeStatsTo)
              .endOf('day')
              .format(apiDateFormat)
              .toString()
          : undefined,
        userGroups: values.userGroups,
      };

      props.onChange?.(listFilter);
    },
    [moment, props.onChange]
  );

  return (
    <Form<ICourseListFilter>
      layout="vertical"
      form={form}
      onFinish={handleFinish}
      initialValues={{ traineeStatsFrom: props.value?.traineeStatsFrom != null ? moment(props.value?.traineeStatsFrom) : undefined, traineeStatsTo: props.value?.traineeStatsTo != null ? moment(props.value?.traineeStatsTo) : undefined, userGroups: props.value?.userGroups }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={12} md={6}>
          <FormItem name={'traineeStatsFrom'} label={props.translate('COURSE_LIST_FILTER.TRAINEE_STATS_FROM_LABEL')}>
            <DatePicker allowClear={true} disabledDate={disabledStartDate} format={dateFormat} className="full-width" data-test-id="timun-courseReportListFilter__traineeStatsFrom" />
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <FormItem name={'traineeStatsTo'} label={props.translate('COURSE_LIST_FILTER.TRAINEE_STATS_TO_LABEL')}>
            <DatePicker allowClear={true} disabledDate={disabledEndDate} format={dateFormat} className="full-width" data-test-id="timun-courseReportListFilter__traineeStatsTo" />
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <FormItem name={'userGroups'} label={props.translate('COURSE_LIST_FILTER.ORGANIZATION_GROUPS_LABEL')}>
            <UserGroupDataPicker />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col className="timun-wrapper__gutterBox--vertical">
          <Button type="primary" htmlType="submit" data-test-id="timun-courseReportListFilter__submitButton">
            {props.translate('COMMON.ACTION_SEARCH')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize<ICoursesOverviewListFilterOwnProps>(CoursesOverviewListFilter as any);

import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AllowedUserRoles from '@src/components/common/role/AllowedUserRoles';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import CourseGroupGrid from '@src/components/course/group/CourseGroupGrid';
import AddElementLink from '@src/components/course/update/AddElementLink';
import NameInputModal from '@src/components/course/update/NameInputModal';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { IFile } from '@src/model/file/File';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import CourseGroupBusinessStore, { ICourseGroupFilter } from '@src/service/business/coursegroups/courseGroupBusinessStore';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const path = '/courses/administration';
const VIEW_NAME = '@@COURSE_GROUP_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<ICourseGroupFilter> = {
  viewName: VIEW_NAME,
  initialValues: {},
};

// -- Prop types
// ----------

export interface ICourseGroupListContainerOwnProps {}

export interface ICourseGroupListContainerStateProps {
  courseGroups: ICollectionData<ICourseGroup>;
}

export interface ICourseGroupListContainerDispatchProps {
  fetchCourseGroups: (collectionParams: ICollectionFetchPayload<ICourseGroupFilter>) => void;
  createCourseGroup: (title: string, thunkMap: IActionThunkMap) => void;
  deleteCourseGroup: (id: string, thunkMap: IActionThunkMap) => void;
  updateCourseGroup: (iid: string, nesh: string, thunkMap: IActionThunkMap) => void;
  uploadCourseGroupCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => void;
}

type ICourseGroupListContainerProps = ICourseGroupListContainerOwnProps & ICourseGroupListContainerStateProps & ICourseGroupListContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<ICourseGroupFilter>;

// -- State types
// ----------

export interface ICourseGroupListContainerState {
  isTitleModalVisible: boolean;
  isCoverModalVisible: boolean;
  courseGroupName: string;
  courseGroupId: string;
}

// -- Component
// ----------

/** Container component for list of all courses. */
class CourseGroupListContainer extends React.Component<ICourseGroupListContainerProps, ICourseGroupListContainerState> {
  state = {
    isTitleModalVisible: false,
    isCoverModalVisible: false,
    courseGroupName: '',
    courseGroupId: '',
  };

  componentDidMount = () => {
    this.fetchCourseGroups();
  };

  componentDidUpdate = (prevProps: ICourseGroupListContainerProps, prevState: ICourseGroupListContainerState) => {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.fetchCourseGroups();
    }
  };

  render = () => {
    return (
      <React.Fragment>
        {this.props.courseGroups && (
          <AppContent
            level={1}
            title={
              <Row justify="space-between">
                <Col xs={24} lg={12}>
                  <HeaderTitle title={this.props.translate('COURSE_GROUP_LIST.VIEW_TITLE')} link={path} icon={<LemonIcon name="left" />} />
                </Col>
                <Col>
                  <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN]} fallback="">
                    <AddElementLink title={this.props.translate('COURSE_GROUP_LIST.CREATE_GROUP_TITLE')} onSubmit={(data: string) => this.handleCreateCourseGroup(data)} />
                  </AllowedUserRoles>
                </Col>
              </Row>
            }
          >
            {/* Pagination */}
            {this.props.courseGroups && <ListPagination page={this.props.courseGroups.page} onChange={this.props.updateCollectionParams.onPageChange} />}

            {/* List */}
            <CourseGroupGrid courseGroups={this.props.courseGroups.content} onDeleteCourseGroup={this.handleDeleteCourseGroup} onEditCourseGroupTitle={this.handleUpdateCourseGroupTitle} onEditCourseGroupCover={this.handleUpdateCourseGroupCover} />

            {/* Pagination */}
            {this.props.courseGroups && <ListPagination page={this.props.courseGroups.page} onChange={this.props.updateCollectionParams.onPageChange} />}
          </AppContent>
        )}
        {/* Modal */}
        {this.state.isCoverModalVisible && <ImageUploadForm onSubmit={this.handleCourseCoverGroupSubmit} onClose={this.toggleCoverModal} />}
        {this.state.isTitleModalVisible && <NameInputModal title={this.props.translate('COURSE_GROUP_LIST.EDIT_GROUP_TITLE')} defaultName={this.state.courseGroupName} onCancel={this.toggleTitleModal} onSubmit={this.updateCourseGroupTitle} />}
      </React.Fragment>
    );
  };

  toggleTitleModal = () => {
    this.setState({ isTitleModalVisible: !this.state.isTitleModalVisible });
  };

  toggleCoverModal = () => {
    this.setState({ isCoverModalVisible: !this.state.isCoverModalVisible });
  };
  handleUpdateCourseGroupTitle = (id: string, name: string) => {
    this.setState({ courseGroupId: id, courseGroupName: name });
    this.toggleTitleModal();
  };

  handleUpdateCourseGroupCover = (id: string) => {
    this.setState({ courseGroupId: id });
    this.toggleCoverModal();
  };

  handleCreateCourseGroup = (title: string) => {
    this.props.createCourseGroup(title, { success: () => this.fetchCourseGroups() });
  };

  handleDeleteCourseGroup = (data: ICourseGroup) => {
    this.props.deleteCourseGroup(data.id, { success: () => this.fetchCourseGroups() });
  };

  updateCourseGroupTitle = (title: string) => {
    this.props.updateCourseGroup(this.state.courseGroupId, title, { success: () => this.fetchCourseGroups() });
    this.toggleTitleModal();
  };

  handleCourseCoverGroupSubmit = (data: IFile) => {
    this.props.uploadCourseGroupCover(this.state.courseGroupId, data, {
      success: () => {
        this.fetchCourseGroups();
      },
    });
  };

  private fetchCourseGroups = () => {
    this.props.onUpdateList(this.props.fetchCourseGroups, {});
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseGroupListContainerOwnProps): ICourseGroupListContainerStateProps => ({
  courseGroups: CourseGroupBusinessStore.selectors.getCourseGroups(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICourseGroupListContainerDispatchProps => ({
  fetchCourseGroups: (collectionParams: ICollectionFetchPayload<ICourseGroupFilter>) => dispatch(CourseGroupBusinessStore.actions.fetchCourseGroups(collectionParams)),
  createCourseGroup: (title: string, thunkMap: IActionThunkMap) => createActionThunk(dispatch(CourseGroupBusinessStore.actions.createCourseGroup({ title })), thunkMap),
  deleteCourseGroup: (id: string, thunkMap: IActionThunkMap) => createActionThunk(dispatch(CourseGroupBusinessStore.actions.deleteCourseGroup({ id })), thunkMap),
  updateCourseGroup: (id: string, title: string, thunkMap: IActionThunkMap) => createActionThunk(dispatch(CourseGroupBusinessStore.actions.updateCourseGroup({ id, data: { title } })), thunkMap),
  uploadCourseGroupCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => createActionThunk(dispatch(CourseGroupBusinessStore.actions.uploadCourseGroupCover(id, data)), thunkMap),
});

export default connect<ICourseGroupListContainerStateProps, ICourseGroupListContainerDispatchProps, ICourseGroupListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(CourseGroupListContainer as any)));

import React, { useState } from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import GdprModal from '@src/components/user/registration/GdprModal';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';


// -- Prop types
// ----------

export interface IGdprContainerOwnProps {
}
export interface IGdprContainerStateProps {
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}
export interface IGdprContainerDispatchProps {
}
type IGdprContainerProps = IGdprContainerOwnProps & IGdprContainerStateProps & IGdprContainerDispatchProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const GdprContainer = (props: IGdprContainerProps) => {
  const [isGdprModalVisible, setIsGdprModalVisible] = useState<boolean>(false);
  const handleShowGdprModal = () => setIsGdprModalVisible(true);
  const handleOk = () => setIsGdprModalVisible(false);
  const handleCancel = () => setIsGdprModalVisible(false);

  return (
    <React.Fragment>
      <a onClick={handleShowGdprModal}>{props.translate('USER_REGISTRATION.FORM.GDPR_BUTTON_LABEL')}</a>

      {isGdprModalVisible &&
        <GdprModal terms={props.tenantOrganizationConfiguration.configuration.displayTerms}
          onOk={handleOk} onCancel={handleCancel} />}
    </React.Fragment>
  );

};


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IGdprContainerOwnProps): IGdprContainerStateProps => ({
  tenantOrganizationConfiguration: PublicTenantOrganizationConfigurationBusinessStore.selectors.getTenantOrganizationConfiguration(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IGdprContainerDispatchProps => ({
});


export default connect<IGdprContainerStateProps, IGdprContainerDispatchProps, IGdprContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(GdprContainer as any));

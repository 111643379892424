import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationTimelineItem from '@src/components/externalEducationApplication/common/EducationApplicationTimelineItem';
import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { Button, Modal } from 'antd';

// -- Prop types
// ----------

export interface IExternalEducationApplicationActivitiesModalOwnProps {
  timeline: IExternalEducationApplicationTimelineActivity[];
  onCancel?: () => void;
}
type IExternalEducationApplicationActivitiesModalProps = IExternalEducationApplicationActivitiesModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationApplicationActivitiesModal: React.FC<IExternalEducationApplicationActivitiesModalProps> = (props: IExternalEducationApplicationActivitiesModalProps) => {

  return (
    <Modal
      title={props.translate('EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.TITLE')}
      visible={true}
      onCancel={props.onCancel}
      cancelText={props.translate('COMMON.ACTION_CLOSE')}
      footer={[<Button onClick={props.onCancel}>{props.translate('COMMON.ACTION_CLOSE')} </Button>]}>
      {props.timeline && props.timeline.map((item: IExternalEducationApplicationTimelineActivity) => {
        return <EducationApplicationTimelineItem item={item} />;
      })}
    </Modal>
  );
};

export default withLocalize<IExternalEducationApplicationActivitiesModalOwnProps>(ExternalEducationApplicationActivitiesModal as any);

import { IActivity } from '@src/model/activity/Activity';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { IPaymentInfo } from '@src/model/common/PaymentInfo';
import { ICompany } from '@src/model/company/Company';
import { IExternalEducationApplicationConsultation } from '@src/model/externalEducationApplication/ExternalEducationApplicationConsultation';
import { FinancingAgreemntTypeEnum } from '@src/model/externalEducationApplication/ExternalEducationApplicationFilePreviewData';
import { IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICity } from '@src/model/location/City';
import { ILocation } from '@src/model/location/Location';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';
import { IUserCategory } from '@src/model/user/UserCategory';

export interface IExternalEducationApplication extends IExternalEducationApplicationBaseInfo {
  skill?: ISkill;
  consultation?: IExternalEducationApplicationConsultation;
  jobRelated?: boolean;
  interestRelated?: boolean;
  educationRelated?: boolean;
  description?: string;
  employmentStatus?: IEmploymentStatus;
  employerInformation?: ICompany;
  employerWillingToCoFinance?: boolean;
  userWillingToCoFinance?: boolean;
  userAcceptedTermsOfUse?: boolean;
  userAllowedEmployerSurvey?: boolean;
  externalEducationInstance?: IExternalEducationInstance;
  submittedAt?: string;
  surveyInstances?: ISurveyInstance[];
  expenses?: IExternalEducationExpense[];
  districtOffice?: ILocation;
  paymentInfo?: IPaymentInfo;
  userCategories: IUserCategory[];
  startDateTime?: string;
  financingAgreementType: FinancingAgreemntTypeEnum;
  statementAccepted?: boolean;
}

export interface IExternalEducationApplicationBaseInfo {
  id: string;
  activity: IActivity;
  externalEducationTemplate?: IExternalEducationTemplate;
  offerCode?: string;
  endDateTime?: string;
  paymentRequestedDateTime?: string;
  isOnline: boolean;
  educationLocation?: ICity;
}

export interface IEmploymentStatus extends ICodeBookEntry<EmploymentStatusEnum> {}

export enum EmploymentStatusEnum {
  EMPLOYED = '1',
  UNEMPLOYED = '2',
}

import React, { useMemo } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ActivityPhaseEnum, ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { Tag } from 'antd';


// -- Prop types
// ----------

export interface IExternalEducationTemplateActivityStatusOwnProps {
  activityStatus?: ICodeBookEntry<ActivityPhaseEnum>;
}
type IExternalEducationTemplateActivityStatusProps = IExternalEducationTemplateActivityStatusOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display colored activity status tag */
const ExternalEducationTemplateActivityStatus: React.FC<IExternalEducationTemplateActivityStatusProps> = (props: IExternalEducationTemplateActivityStatusProps) => {
  const renderStatusColor = useMemo(() => {
    let tagColor;
    if (props.activityStatus?.id === ExternalEducationTemplateActivityPhaseEnum.APPROVAL_REQUESTED) {
      tagColor = 'yellow';
    } else if (props.activityStatus?.id === ExternalEducationTemplateActivityPhaseEnum.APPROVAL_IN_PROCESS) {
      tagColor = 'purple';
    } else if (props.activityStatus?.id === ExternalEducationTemplateActivityPhaseEnum.CHANGE_REQUESTED) {
      tagColor = 'orange';
    } else if (props.activityStatus?.id === ExternalEducationTemplateActivityPhaseEnum.CHANGE_SUBMITTED) {
      tagColor = 'brown';
    } else if (props.activityStatus?.id === ExternalEducationTemplateActivityPhaseEnum.APPROVED) {
      tagColor = 'green';
    } else if (props.activityStatus?.id === ExternalEducationTemplateActivityPhaseEnum.EXTERNAL_EDUCATION_TEMPLATE_DENIED) {
      tagColor = 'red';
    } else if (props.activityStatus?.id === ExternalEducationTemplateActivityPhaseEnum.CANCELED) {
      tagColor = 'red';
    }
    return tagColor;
  }, [props.activityStatus?.id]);

  const phaseName = Object.keys(ActivityPhaseEnum).find((phase) => ActivityPhaseEnum[phase as keyof typeof ActivityPhaseEnum] === props.activityStatus?.id);


  return (
    <React.Fragment>
      <Tag color={renderStatusColor}>  {props.translate(`EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY.${phaseName}`)}  </Tag>
    </React.Fragment >
  );
};

export default withLocalize<IExternalEducationTemplateActivityStatusOwnProps>(ExternalEducationTemplateActivityStatus as any);

import { FormComponentProps } from '@ant-design/compatible/es/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import { Editor } from '@src/components/common/editor/Editor';
import { TimunEditorConfig } from '@src/components/common/editor/TimunEditorConfigs';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { INote, NoteObjectTypeEnum } from '@src/model/user/Note';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input, Modal, Radio, Form } from 'antd';
import React from 'react';

const FormItem = Form.Item;

const MAX_NUMBER_OF_CHARACTER: number = AppConfigService.getValue('components.userNoteForm.maxChar');

interface INoteFormOwnProps {
  onCancel?: () => void;
  onSubmit?: (noteData: INote) => void;
  title: string;
  okButtonLabel?: string;
  cancelButtonLabel?: string;
  note?: INote;
  objectId?: string;
  objectTypeId?: NoteObjectTypeEnum;
  objectPublic?: boolean;
  canSetPrivateNote?: boolean;
}

type INoteFormProps = INoteFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

const NoteForm: React.FC<INoteFormProps> = (props: INoteFormProps) => {
  const [form] = Form.useForm<INote>();
  const handleCancel = () => {
    if (props.onCancel) {
      if (form.isFieldsTouched()) {
        confirmationDialog({
          onConfirm: props.onCancel,
          title: props.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION'),
        });
      } else {
        props.onCancel();
      }
    }
  };

  const handleFinish = (values: INote) => {
    const noteData = props.note
      ? {
          ...props.note,
          ...values,
        }
      : ({
          ...values,
        } as INote);

    props.onSubmit?.(noteData);
  };

  return (
    <Modal className="lemon-modal__modal--md" title={props.title} visible={true} onCancel={handleCancel} onOk={form.submit} okText={props.okButtonLabel || props.translate('COMMON.ACTION_SAVE')} cancelText={props.cancelButtonLabel || props.translate('COMMON.ACTION_CANCEL')}>
      <Form form={form} onFinish={handleFinish} initialValues={{ text: props.note?.text, object: { id: props.objectId }, objectType: { id: props.objectTypeId }, public: props.objectPublic || props.note?.public || false }} layout="vertical">
        <FormItem
          label={<FormItemLabelIcon label={''} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: MAX_NUMBER_OF_CHARACTER })} />}
          name={'text'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            // tslint:disable-next-line:no-duplicate-string
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: MAX_NUMBER_OF_CHARACTER, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}
        >
          <Editor
            data-test-id="timun-notesForm__text"
            init={{
              ...TimunEditorConfig.defaultConfig(),
              height: 300,
              menubar: true,
            }}
          />
        </FormItem>
        {/* Enable two hidden input field when creating new note */}
        <React.Fragment>
          <FormItem name={['object', 'id']} hidden={true} noStyle={true}>
            <Input type="hidden" />
          </FormItem>
          <FormItem name={['objectType', 'id']} hidden={true} noStyle={true}>
            <Input type="hidden" />
          </FormItem>
        </React.Fragment>

        <FormItem hidden={!props.canSetPrivateNote} noStyle={!props.canSetPrivateNote} label={props.translate('USER_NOTES.VISIBILITY_REQUIRED_LABEL')} className="timun-noteCreateContainer__visibilityLabel" name="public">
          <Radio.Group buttonStyle="solid">
            <Radio value={true}>{props.objectTypeId === NoteObjectTypeEnum.EDUCATION_APPLICATION ? props.translate('USER_NOTES.VISIBILITY_TITLE_VISIBLE_TO_USER') : props.translate('USER_NOTES.VISIBILITY_TITLE_PUBLIC')}</Radio>
            <Radio value={false}>{props.translate('USER_NOTES.VISIBILITY_TITLE_PRIVATE')}</Radio>
          </Radio.Group>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default withLocalize<INoteFormOwnProps>(NoteForm as any);

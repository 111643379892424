import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LockOutlined } from '@ant-design/icons';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Button, Card, Input } from 'antd';
import React, { SyntheticEvent } from 'react';

const FormItem = Form.Item;

interface IChangePasswordFormOwnProps {
  onSubmit: (oldPassword: string, newPassword: string) => void;
}

type IChangePasswordFormProps = IChangePasswordFormOwnProps & FormComponentProps & IWithLocalizeOwnProps;

class NormalChangePasswordForm extends React.Component<IChangePasswordFormProps> {
  render = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card className="timun-changePassword__changePasswordForm">
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem label={this.props.translate('CHANGE_PASSWORD.OLD_PASSWORD_LABEL')}>
            {getFieldDecorator('oldPassword', {
              // tslint:disable-next-line: no-duplicate-string
              rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }, { validator: this.validateOldPassword }],
            })(<Input type="password" prefix={<LockOutlined/>} placeholder={this.props.translate('CHANGE_PASSWORD.OLD_PASSWORD_PLACEHOLDER')} data-test-id="timun-changePassword__inputOldPassword"/>)}
          </FormItem>
          <FormItem label={this.props.translate('CHANGE_PASSWORD.NEW_PASSWORD_LABEL')}>
            {getFieldDecorator('newPassword', {
              rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }, { min: 8, message: this.props.translate('CHANGE_PASSWORD.MINIMUM_LENGTH_VALIDATION_MESSAGE') }, { validator: this.validateNewPassword }],
              validateFirst: true,
            })(<Input type="password" prefix={<LockOutlined/>} placeholder={this.props.translate('CHANGE_PASSWORD.NEW_PASSWORD_PLACEHOLDER')} data-test-id="timun-changePassword__inputNewPassword"/>)}
          </FormItem>
          <FormItem label={this.props.translate('CHANGE_PASSWORD.CONFIRM_PASSWORD_LABEL')}>
            {getFieldDecorator('confirmPassword', {
              rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }, { min: 8, message: this.props.translate('CHANGE_PASSWORD.MINIMUM_LENGTH_VALIDATION_MESSAGE') }, { validator: this.validateConfirmPassword }],
              validateFirst: true,
            })(<Input type="password" prefix={<LockOutlined/>} placeholder={this.props.translate('CHANGE_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER')} data-test-id="timun-changePassword__inputConfirmPassword"/>)}
          </FormItem>
          <Button type="primary" htmlType="submit" block={true} data-test-id="timun-changePassword__buttonSubmit">
            {this.props.translate('CHANGE_PASSWORD.CHANGE_PASSWORD_BUTTON_LABEL')}
          </Button>
        </Form>
      </Card>
    );
  };

  private validateOldPassword = (rule: any, value: any, callback: any) => {
    const newPassword = this.props.form.getFieldValue('newPassword');
    if (value && newPassword) {
      this.props.form.validateFields(['newPassword'], { force: true });
    }
    callback();
  };

  /*private validateNewPassword = (rule: any, value: any, callback: any) => {
    const oldPassword = this.props.form.getFieldValue('oldPassword');
    const confirmPassword = this.props.form.getFieldValue('confirmPassword');
    if (value && oldPassword && value === oldPassword) {
      if (confirmPassword) {
        this.props.form.validateFields(['confirmPassword'], { force: true });
      }
      callback('Nova lozinka se mora razlikovati od stare');
    } else if (value && confirmPassword) {
      this.props.form.validateFields(['confirmPassword'], { force: true });
      callback();
    } else {
      callback();
    }
  }*/

  private validateNewPassword = (rule: any, value: any, callback: any) => {
    const oldPassword = this.props.form.getFieldValue('oldPassword');
    const confirmPassword = this.props.form.getFieldValue('confirmPassword');
    if (value) {
      if (confirmPassword) {
        this.props.form.validateFields(['confirmPassword'], { force: true });
      }
      if (oldPassword && value === oldPassword) {
        callback(this.props.translate('CHANGE_PASSWORD.PASSWORD_NOT_NEW_ERROR'));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  private validateConfirmPassword = (rule: any, value: any, callback: any) => {
    const newPassword = this.props.form.getFieldValue('newPassword');
    if (value && newPassword && value !== newPassword) {
      callback(this.props.translate('CHANGE_PASSWORD.PASSWORDS_DIFFERENT_ERROR'));
    } else {
      callback();
    }
  };

  private handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.onSubmit(values.oldPassword, values.newPassword);
      }
    });
  };
}

const ChangePasswordForm = Form.create()(NormalChangePasswordForm);

export default withLocalize<IChangePasswordFormOwnProps>(ChangePasswordForm as any);

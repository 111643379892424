import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalContentListFilter } from '@src/service/business/externalcontent/ExternalContentListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Col, Input, Row } from 'antd';
import { debounce } from 'lodash';
import React, { ChangeEvent, useCallback } from 'react';
import GridItem from '@src/components/common/grid/GridItem';
import SortSelector from '@src/components/common/select/SortSelector';

const sortItems = [
  {
    id: 'title,asc',
    translationKey: 'COMMON.ACTION_SORT_ASCEND',
  },
  {
    id: 'title,desc',
    translationKey: 'COMMON.ACTION_SORT_DESCEND',
  },
];

// -- Prop types
// ----------

export interface IExternalContentListFilterOwnProps {
  filter: IExternalContentListFilter;
  sortValue: string[];
  onFilterChange: (filter: IExternalContentListFilter) => void;
  onSortChange: (sort: string[]) => void;
}
type IExternalContentListFilterProps = IExternalContentListFilterOwnProps & FormComponentProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const ExternalContentListFilter: React.FC<IExternalContentListFilterProps> = (props: IExternalContentListFilterProps) => {
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      const filter = { title: value };
      props.onFilterChange(filter);
    }, AppConfigService.getValue(`components.common.debounceTimeout`)),
    [debounce, props.onFilterChange, AppConfigService.getValue]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  return (
    <Row gutter={12}>
      <Col xs={24} lg={12}>
        <GridItem layout="horizontal" label={props.translate('COMMON.ACTION_FILTER')}>
          <Input defaultValue={props.filter?.title || ''} allowClear={true} placeholder={props.translate('EXTERNAL_CONTENT.LIST.FILTER_PLACEHOLDER')} onChange={handleChange} />
        </GridItem>
      </Col>
      <Col xs={24} lg={12}>
        <GridItem layout="horizontal" label={props.translate('COMMON.ACTION_SORT_LABEL')}>
          <SortSelector sortItems={sortItems} onChange={props.onSortChange} value={props.sortValue || sortItems[0].id} />
        </GridItem>
      </Col>
    </Row>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IExternalContentListFilterOwnProps>(ExternalContentListFilter as any);

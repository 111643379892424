import EducationCard from '@src/components/common/card/EducationCard';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdditionalCardElements from '@src/components/webinar/common/AdditionalCardElements';
import WebinarCoverImage from '@src/components/webinar/common/WebinarCoverImage';
import { IWebinar } from '@src/model/webinar/Webinar';
import { Col, Empty, Row, Tooltip } from 'antd';
import React, { SyntheticEvent, useCallback } from 'react';

interface IWebinarGridOwnProps {
  webinars?: IWebinar[];
  inProgress?: boolean;
  title?: string;
  onSetReminder?: (webinar: IWebinar) => void;
}

type IWebinarGridProps = IWebinarGridOwnProps & IWithLocalizeOwnProps;

const WebinarGrid: React.FC<IWebinarGridProps> = (props: IWebinarGridProps) => {
  const getPath = useCallback((webinar: IWebinar) => {
    return `/webinar/${webinar.id}`;
  }, []);

  return props.webinars?.length ? (
    <Row className="timun-courseCardList__container" gutter={[16, 16]}>
      {props.webinars.map((webinar: IWebinar) => {
        return (
          <Col key={webinar.id} xs={24} sm={12} md={8} lg={6} className="timun-gridTable__itemCell">
            <EducationCard
              type="WEBINAR"
              showStatusBadge={true}
              key={webinar.id}
              education={webinar}
              getPath={getPath}
              cardCover={<WebinarCoverImage webinar={webinar} />}
              cardDescription={<AdditionalCardElements webinar={webinar} cardDescription={webinar.guest} />}
              cardTitleSuffix={
                <Col>
                  <Tooltip title={props.translate('REMINDER.SET_REMINDER_TOOLTIP')}>
                    <LemonIcon
                      name="bell"
                      className="timun-educationCard__bellIcon"
                      onClick={(e: SyntheticEvent) => {
                        e.preventDefault();
                        props.onSetReminder?.(webinar);
                      }}
                    />
                  </Tooltip>
                </Col>
              }
            />
          </Col>
        );
      })}
    </Row>
  ) : (
    <Empty description={props.translate('WEBINAR_LIST_VIEW.NO_DATA')} />
  );
};

export default withLocalize<IWebinarGridOwnProps>(WebinarGrid as any);

import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import FileUpload, { IUploadedFile, IUploadStatus } from '@src/components/common/upload/FileUpload';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import { ICourse } from '@src/model/course/Course';
import AppConfigService from '@src/service/common/AppConfigService';
import { Modal, Spin } from 'antd';
import React, { useCallback, useState } from 'react';

const ALLOWED_FILE_TYPES: string[] = AppConfigService.getValue('api.upload.allowedScormTypes');
const ALLOWED_MAX_SIZE: number = AppConfigService.getValue('api.upload.maxFileSize');

const checkFile = (file: IUploadedFile) => FileUtils.checkFileValidity(file, ALLOWED_FILE_TYPES, ALLOWED_MAX_SIZE);

// -- Prop types
// ----------

export interface IScormUploadFormOwnProps {
  submitAfterUpload?: boolean;

  onSubmit?: (file: ICourse) => void;
  onClose?: () => void;
}

type IScormUploadFormProps = IScormUploadFormOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

/** Component with a modal form for uploading new scorm zip files */
const ScormUploadForm = (props: IScormUploadFormProps) => {
  const [uploadedFile, setUploadedFile] = useState<ICourse>();

  const handleUploadFile = (data: ICourse) => {
    if (props.submitAfterUpload) {
      props.onSubmit?.(data);
    } else {
      setUploadedFile(data);
    }
  };

  const handleOk = useCallback(() => (uploadedFile ? props.onSubmit?.(uploadedFile) : undefined), [uploadedFile]);

  const customRequest = useCallback((componentsData) => {
    // TODO: should we check if this really is File or smtng else?
    const file = componentsData.file as File;
    const fileName = file.name;

    // file upload
    FileUploadHelper.importScorm(file, fileName, componentsData.onProgress).subscribe({
      next: componentsData.onSuccess,
      error: componentsData.onError,
    });
  }, []);

  return (
    <React.Fragment>
      <Modal
        maskClosable={false}
        title={props.translate('FILE_UPLOAD.SCORM_MODAL_TITLE')}
        className="timun-fileUploader__dialog"
        visible={true}
        onOk={handleOk}
        onCancel={props.onClose}
        okText={props.translate('COMMON.ACTION_CONTINUE')}
        cancelText={props.translate('COMMON.ACTION_CANCEL')}
        okButtonProps={{ disabled: !uploadedFile }}
      >
        <div className="timun-fileUploader__previewPlaceholder">
          <FileUpload
            onUploadFile={handleUploadFile}
            dragger={true}
            uploadProps={{ customRequest, multiple: false, beforeUpload: checkFile, showUploadList: true }}
          >
            {(status: IUploadStatus) => (
              <React.Fragment>
                {status.isUploading && <Spin />}&nbsp;
                <LemonIcon name="plus" />
                <br />
                {props.translate('FILE_UPLOAD.DROPZONE_TEXT')}
              </React.Fragment>
            )}
          </FileUpload>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withLocalize<IScormUploadFormOwnProps>(withPathResolver(ScormUploadForm) as any);

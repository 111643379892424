import IIdRef from '@src/model/common/IdRef';
import { INotification, INotificationType } from '@src/model/notification/Notification';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, IIdDataPayload, IIdPayload, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export interface INotificationCreatePayload {
  message: string;
  title: string;
  type: INotificationType;
}

// -
// -------------------- Selectors

/** Returns notification from store. */
const getNotification = (store: any): INotification => store.notification;

// -
// -------------------- Actions

const Actions = {
  NOTIFICATION_FETCH: 'NOTIFICATION_FETCH',
  NOTIFICATION_CREATE: 'NOTIFICATION_CREATE',
  NOTIFICATION_UPDATE: 'NOTIFICATION_UPDATE',
  NOTIFICATION_DELETE: 'NOTIFICATION_DELETE',
  NOTIFICATION_LOAD: 'NOTIFICATION_LOAD',
  NOTIFICATION_CLEAR: 'NOTIFICATION_CLEAR',
};

/** Fetch notification by ID. */
const fetchNotification = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.NOTIFICATION_FETCH,
    payload: params,
  };
};

const createNotification = (data: INotificationCreatePayload): IPayloadAction<INotificationCreatePayload> => {
  return {
    type: Actions.NOTIFICATION_CREATE,
    payload: data,
  };
};

const deleteNotification = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.NOTIFICATION_DELETE,
    payload: data,
  };
};

const updateNotification = (data: INotification): IPayloadAction<IIdDataPayload<INotification>> => {
  return {
    type: Actions.NOTIFICATION_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

const loadNotification = (data: ICollectionData<INotification>): IPayloadAction<ICollectionData<INotification>> => {
  return {
    type: Actions.NOTIFICATION_LOAD,
    payload: data,
  };
};

// -
// ------------------- Effects

const fetchNotificationEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.NOTIFICATION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Notification')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadNotification(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    // tslint:disable-next-line: no-duplicate-string
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'NOTIFICATION.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching webinar', error);
      return o;
    })
  );
};

const deleteNotificationEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.NOTIFICATION_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Notification')
        .deleteEntity(payload.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      return { message: LocalizeService.translate('NOTIFICATION.DELETED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO };
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'NOTIFICATION.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      return o;
    })
  );
};

const updateNotificationEffect = (action$: Observable<IPayloadAction<IIdDataPayload<INotification>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.NOTIFICATION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Notification')
        .updateEntity(payload.id, payload.data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      return { message: LocalizeService.translate('COMMON.EDITED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS };
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'NOTIFICATION.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      return o;
    })
  );
};

const createNotificationEffect = (action$: Observable<IPayloadAction<INotificationCreatePayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.NOTIFICATION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Notification')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      return { message: LocalizeService.translate('NOTIFICATION.CREATED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS };
    }),

    map((data) => {
      return loadNotification(data);
    }),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'NOTIFICATION.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating notification', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const notification = (state: INotification | null = null, action: IPayloadAction<INotification>) => {
  if (action.type === Actions.NOTIFICATION_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.NOTIFICATION_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

const notificationViewBusinessStore = {
  actions: {
    fetchNotification,
    createNotification,
    updateNotification,
    deleteNotification,
    loadNotification,
  },

  selectors: {
    getNotification,
  },

  effects: {
    fetchNotificationEffect,
    createNotificationEffect,
    updateNotificationEffect,
    deleteNotificationEffect,
  },

  reducers: {
    notification,
  },
};

export default notificationViewBusinessStore;

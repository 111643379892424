import React, { useMemo } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import { IActivity } from '@src/model/activity/Activity';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ActivityUtils } from '@src/service/util/activity/ActivityUtils';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Button, Col, Row } from 'antd';

// -- Prop types
// ----------

export interface IExternalEducationApplicationInfoPanelOwnProps {
  canParticipate: boolean;
  paymentRequestedDateTime?: string;
  activity: IActivity;
  onActivityModalOpen: () => void;
}
type IExternalEducationApplicationInfoPanelProps = IExternalEducationApplicationInfoPanelOwnProps & IWithRolesOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationApplicationInfoPanel: React.FC<IExternalEducationApplicationInfoPanelProps> = (props: IExternalEducationApplicationInfoPanelProps) => {
  const phaseName = useMemo(() => ActivityUtils.getPhaseName(props.activity), [props.activity.id, props.activity.currentPhase.id]);
  const isApplicationInPhases = useMemo(
    () => (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
      return EducationApplicationHelperUtils.isInPhases(phases, props.activity);
    },
    [props.activity.id, props.activity.currentPhase.id]
  );

  return (
    <React.Fragment>
      {/* Trainee */}
      {RoleUtils.allowedRoles([UserRoleEnum.TRAINEE]) && (
        <Row justify="space-between" gutter={4} className="timun-externalEducationApplicationInfoPanel__row panel">
          <Col>{props.translate(`EXTERNAL_EDUCATION_APPLICATION.TRAINEE.${phaseName}_INFO_TEXT`)}</Col>
          {isApplicationInPhases(['RETURNED_TO_USER']) && (
            <Col>
              <Button onClick={props.onActivityModalOpen}> {props.translate(`EXTERNAL_EDUCATION_APPLICATION.TRAINEE.RETURN_TOUSER_BUTTON_LABEL`)}</Button>
            </Col>
          )}
        </Row>
      )}
      {/* Coordinator */}
      {RoleUtils.allowedRoles([UserRoleEnum.COORDINATOR]) && (
        <Row gutter={4} className="timun-externalEducationApplicationInfoPanel__row panel">
          <Col>{props.translate(`EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.${phaseName}_INFO_TEXT`)}</Col>
          {!props.canParticipate && <Col>{props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CAN_PARTICIPATE_INFO_TEXT')}</Col>}
          {props.paymentRequestedDateTime && <Col>{props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.PAYMENT_REQUESTED_DATE_TIME_INFO_TEXT')}</Col>}
        </Row>
      )}
    </React.Fragment>
  );
};

export default withRoles(withLocalize<IExternalEducationApplicationInfoPanelOwnProps>(ExternalEducationApplicationInfoPanel as any));

import { ITag } from '@src/model/tag/Tag';
import { IAdditionalData } from '@src/model/user/AdditionalData';
import { IContactData } from '@src/model/user/ContactData';
import { IResidenceAddress } from '@src/model/user/ResidenceAddress';
import { IUserDetails } from '@src/model/user/UserDetails';
import { IUserEducationData } from '@src/model/user/UserEducationData';
import { IUserExtendedData } from '@src/model/user/UserExtendedData';
import { IUserRole } from '@src/model/user/UserRole';
import { IUserStatus } from '@src/model/user/UserStatus';
import { IUserType } from '@src/model/user/UserType';

export interface IUserInfo extends IUserDetails {
  userStatus?: IUserStatus;
  gender?: GenderEnum;
  dateOfBirth?: string;
  roles: IUserRole[];
  additionalData?: IAdditionalData;
  residenceAddress?: IResidenceAddress;
  contactData: IContactData[];
  userType?: IUserType;
  gdprAccepted?: boolean;
  tags?: ITag[];
  identificationNumber?: string;
  extendedData?: IUserExtendedData;
  educationData?: IUserEducationData;
}

export enum GenderEnum {
  M = 'M',
  F = 'F',
}

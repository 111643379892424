import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ILemonAction, IPayloadAction, ITokenPayload } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';

export interface ISurveyInstanceSubmitPayload {
  token: string;
  surveyInstance: ISurveyInstance;
}

// -
// -------------------- Selectors

/** Returns public SurveyInstace from store. */
const getPublicSurveyInstance = (store: any): ISurveyInstance => store.publicSurveyInstance;

// -
// -------------------- Actions

const Actions = {
  PUBLIC_SURVEY_INSTANCE_FETCH: 'PUBLIC_SURVEY_INSTANCE_FETCH',
  PUBLIC_SURVEY_INSTANCE_LOAD: 'PUBLIC_SURVEY_INSTANCE_LOAD',
  PUBLIC_SURVEY_INSTANCE_CLEAR: 'PUBLIC_SURVEY_INSTANCE_CLEAR',
  PUBLIC_SURVEY_INSTANCE_SUBMIT: 'PUBLIC_SURVEY_INSTANCE_SUBMIT',
};

/** Fetch public SurveyInstance by token. */
const fetchPublicSurveyInstance = (token: string): IPayloadAction<ITokenPayload> => {
  return {
    type: Actions.PUBLIC_SURVEY_INSTANCE_FETCH,
    payload: {
      token,
    },
  };
};

/** Load public SurveyInstance to store. */
const loadPublicSurveyInstance = (data: ISurveyInstance): IPayloadAction<ISurveyInstance> => {
  return {
    type: Actions.PUBLIC_SURVEY_INSTANCE_LOAD,
    payload: data,
  };
};

/** Clear public SurveyInstance from store. Eg. when leaving view. */
const clearPublicSurveyInstance = (): ILemonAction => {
  return {
    type: Actions.PUBLIC_SURVEY_INSTANCE_CLEAR,
  };
};

/** Submit public SurveyInstance  */
const submitPublicSurveyInstance = (surveyInstanceData: ISurveyInstanceSubmitPayload): IPayloadAction<ISurveyInstanceSubmitPayload> => {
  return {
    type: Actions.PUBLIC_SURVEY_INSTANCE_SUBMIT,
    payload: surveyInstanceData,
  };
};

// -
// -------------------- Side-effects

const fetchPublicSurveyInstanceEffect = (action$: Observable<IPayloadAction<ITokenPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PUBLIC_SURVEY_INSTANCE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const token = action.payload;

      return EntityApiServiceRegistry.getService('PublicSurveyInstance')
        .fetchNoMethod(token)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadPublicSurveyInstance(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SURVEY_INSTANCE.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching public surveyInstance', error);
      return o;
    })
  );
};

const submitPublicSurveyInstanceEffect = (action$: Observable<IPayloadAction<ISurveyInstanceSubmitPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PUBLIC_SURVEY_INSTANCE_SUBMIT;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('PublicSurveyInstance')
        .createMethod('', payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SURVEY_INSTANCE.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error submiting public surveyInstance', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const publicSurveyInstance = (state: ISurveyInstance | null = null, action: IPayloadAction<ISurveyInstance>) => {
  if (action.type === Actions.PUBLIC_SURVEY_INSTANCE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.PUBLIC_SURVEY_INSTANCE_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const PublicSurveyInstancePublicBusinessStore = {
  actions: {
    fetchPublicSurveyInstance,
    loadPublicSurveyInstance,
    clearPublicSurveyInstance,
    submitPublicSurveyInstance,
  },

  selectors: {
    getPublicSurveyInstance,
  },

  effects: {
    fetchPublicSurveyInstanceEffect,
    submitPublicSurveyInstanceEffect,
  },

  reducers: {
    publicSurveyInstance,
  },
};

// --
// export business store
export default PublicSurveyInstancePublicBusinessStore;

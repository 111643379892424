import IIdRef from '@src/model/common/IdRef';
import { ICourse } from '@src/model/course/Course';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import CollectionHelperService from '@src/service/business/common/CollectionHelperService';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

export interface IWorkPositionListFilter {
  userGroup?: string;
}

export type IWorkPositionCreatePayload = Pick<IWorkPosition, Exclude<keyof IWorkPosition, 'id' | 'description' | 'skillLevelClassifications'>>;

// List filter ID
const WORKPOSITION_LIST_FILTER = '@@WORKPOSITION_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns list of workpositions from store. */
const getWorkPositionList = (store: any): ICollectionData<IWorkPosition> => store.workPositionList;

/** Returns workposition list filter. */
const getWorkPositionListFilter = (store: any): IWorkPositionListFilter => ListFilterBusinessStore.selectors.getListFilter(store, WORKPOSITION_LIST_FILTER);

/** Returns courses for workposition from store. */
const getWorkPositionCourseList = (store: any): ICollectionData<ICourse> => store.workPositionCourseList;

/** Return loaded workposition */
const getWorkPosition = (store: any): IWorkPosition => store.workPosition;

// -
// -------------------- Actions

const Actions = {
  WORKPOSITION_LIST_FETCH: 'WORKPOSITION_LIST_FETCH',
  WORKPOSITION_LIST_LOAD: 'WORKPOSITION_LIST_LOAD',
  WORKPOSITION_LIST_CLEAR: 'WORKPOSITION_LIST_CLEAR',
  WORKPOSITION_FETCH: 'WORKPOSITION_FETCH',
  WORKPOSITION_LOAD: 'WORKPOSITION_LOAD',
  WORKPOSITION_CLEAR: 'WORKPOSITION_CLEAR',
  WORKPOSITION_CREATE: 'WORKPOSITION_CREATE',
  WORKPOSITION_UPDATE: 'WORKPOSITION_UPDATE',
  WORKPOSITION_COURSE_LIST_FETCH: 'WORKPOSITION_COURSE_LIST_FETCH',
  WORKPOSITION_COURSE_LIST_UPDATE: 'WORKPOSITION_COURSE_LIST_UPDATE',
  WORKPOSITION_COURSE_LIST_LOAD: 'WORKPOSITION_COURSE_LIST_LOAD',
  WORKPOSITION_COURSE_LIST_CLEAR: 'WORKPOSITION_COURSE_LIST_CLEAR',
};

/** Create new workposition. */
const createWorkPosition = (data: IWorkPositionCreatePayload): IPayloadAction<IWorkPositionCreatePayload> => {
  return {
    type: Actions.WORKPOSITION_CREATE,
    payload: data,
  };
};

/** Update workposition by ID. */
const updateWorkPosition = (data: IWorkPosition): IPayloadAction<IWorkPosition> => {
  return {
    type: Actions.WORKPOSITION_UPDATE,
    payload: data,
  };
};

/** Fetch workposition by ID. */
const fetchWorkPosition = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.WORKPOSITION_FETCH,
    payload: { id },
  };
};

/** Load workposition to store */
const loadWorkPosition = (workPos: IWorkPosition): IPayloadAction<IWorkPosition> => {
  return {
    type: Actions.WORKPOSITION_LOAD,
    payload: workPos,
  };
};

const clearWorkPosition = (): ILemonAction => {
  return {
    type: Actions.WORKPOSITION_CLEAR,
  };
};

/** Fetch workposition list by filter. Forces default WORKPOSITION_LIST_PAYLOAD_BASE. */
const fetchWorkPositionList = (params: ICollectionFetchPayload<IWorkPositionListFilter>): IPayloadAction<ICollectionFetchPayload<IWorkPositionListFilter>> => {
  return {
    type: Actions.WORKPOSITION_LIST_FETCH,
    payload: params,
  };
};

/** Load workposition list to store. */
const loadWorkPositionList = (data: ICollectionData<IWorkPosition>): IPayloadAction<ICollectionData<IWorkPosition>> => {
  return {
    type: Actions.WORKPOSITION_LIST_LOAD,
    payload: data,
  };
};

const clearWorkPositionList = (): ILemonAction => {
  return {
    type: Actions.WORKPOSITION_LIST_CLEAR,
  };
};

/** Fetch workposition course list. */
const fetchWorkPositionCourseList = (data: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.WORKPOSITION_COURSE_LIST_FETCH,
    payload: data,
  };
};

/** Updates courses for workposition. */
const updateWorkPositionCourseList = (data: IIdDataPayload<IIdRef<string>[]>): IPayloadAction<IIdDataPayload<IIdRef<string>[]>> => {
  return {
    type: Actions.WORKPOSITION_COURSE_LIST_UPDATE,
    payload: data,
  };
};

/** Load workposition courses to store. */
const loadWorkPositionCourseList = (data: ICollectionData<ICourse>): IPayloadAction<ICollectionData<ICourse>> => {
  return {
    type: Actions.WORKPOSITION_COURSE_LIST_LOAD,
    payload: data,
  };
};

const clearWorkPositionCourseList = (): ILemonAction => {
  return {
    type: Actions.WORKPOSITION_COURSE_LIST_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchWorkpositionEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WORKPOSITION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('WorkPosition')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => loadWorkPosition(data)),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'WORKPOSITION.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching workposition', error);
      return o;
    })
  );
};

const createWorkPositionEffect = (action$: Observable<IPayloadAction<IWorkPositionCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WORKPOSITION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('WorkPosition')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    tap(() => {
      // Workposition list is a collection loaded on start of the app, should also be updated when something changes with elements it lists
      CollectionHelperService.fetchCollection<IWorkPosition>('WorkPosition');
    }),

    stopGlobalProgress(),

    reportMessage((value) => ({ message: LocalizeService.translate('ADMINISTRATION.WORKPOSITION_CRREATED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS })),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'WORKPOSITION.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating workposition', error);
      return o;
    })
  );
};

const updateWorkPositionEffect = (action$: Observable<IPayloadAction<IWorkPosition>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WORKPOSITION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('WorkPosition')
        .updateEntity(data.id, data)
        .pipe(actionThunk(action));
    }),

    tap(() => {
      // Workposition list is a collection loaded on start of the app, should also be updated when something changes with elements it lists
      CollectionHelperService.fetchCollection<IWorkPosition>('WorkPosition');
    }),

    stopGlobalProgress(),

    reportMessage((value) => ({ message: LocalizeService.translate('COMMON.EDITED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS })),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'WORKPOSITION.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating workposition', error);
      return o;
    })
  );
};

const fetchWorkPositionListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IWorkPositionListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WORKPOSITION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('WorkPosition')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadWorkPositionList(data);
    }),

    // tslint:disable-next-line: no-duplicate-string
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'WORKPOSITION.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching workposition list', error);
      return o;
    })
  );
};

const fetchWorkPositionCourseListEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WORKPOSITION_COURSE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('WorkPosition')
        .fetchSubentityList(id, 'course')
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadWorkPositionCourseList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'WORKPOSITION.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching workposition course list', error);
      return o;
    })
  );
};

const updateWorkPositionCourseListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IIdRef<string>[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WORKPOSITION_COURSE_LIST_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('WorkPosition')
        .updateSubobject(id, 'courses', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => ({ message: LocalizeService.translate('COMMON.EDITED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS })),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'WORKPOSITION.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating workposition course list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const workPositionList = (state: ICollectionData<IWorkPosition> | null = null, action: IPayloadAction<ICollectionData<IWorkPosition>>) => {
  if (action.type === Actions.WORKPOSITION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.WORKPOSITION_LIST_CLEAR) {
    return null;
  }
  return state;
};

const workPositionCourseList = (state: ICollectionData<ICourse> | null = null, action: IPayloadAction<ICollectionData<ICourse>>) => {
  if (action.type === Actions.WORKPOSITION_COURSE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.WORKPOSITION_COURSE_LIST_CLEAR) {
    return null;
  }
  return state;
};

const workPosition = (state: IWorkPosition | null = null, action: IPayloadAction<IWorkPosition>) => {
  if (action.type === Actions.WORKPOSITION_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.WORKPOSITION_CLEAR) {
    return null;
  }
  return state;
};

// --
// -------------------- Business Store

export const WorkPositionListBusinessStore = {
  actions: {
    fetchWorkPositionList,
    loadWorkPositionList,
    clearWorkPositionList,
    createWorkPosition,
    updateWorkPosition,
    fetchWorkPositionCourseList,
    updateWorkPositionCourseList,
    loadWorkPositionCourseList,
    clearWorkPositionCourseList,
    fetchWorkPosition,
    clearWorkPosition,
  },

  selectors: {
    getWorkPositionList,
    getWorkPositionListFilter,
    getWorkPositionCourseList,
    getWorkPosition,
  },

  effects: {
    fetchWorkPositionListEffect,
    createWorkPositionEffect,
    fetchWorkPositionCourseListEffect,
    updateWorkPositionEffect,
    updateWorkPositionCourseListEffect,
    fetchWorkpositionEffect,
  },

  reducers: {
    workPositionList,
    workPositionCourseList,
    workPosition,
  },
};

// --
// export business store
export default WorkPositionListBusinessStore;

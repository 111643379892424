import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { Col, Form, InputNumber, Row } from 'antd';
import { MomentInputObject } from 'moment';

// -- Prop types
// ----------

export interface ITotalEmploymentDurationInputFieldOwnProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}
type ITotalEmploymentDurationInputFieldProps = ITotalEmploymentDurationInputFieldOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const TotalEmploymentDurationInputField: React.FC<ITotalEmploymentDurationInputFieldProps> = (props: ITotalEmploymentDurationInputFieldProps) => {
  const [form] = Form.useForm<MomentInputObject>();
  const transformInputValue = (): MomentInputObject => {
    let period: MomentInputObject = {
      year: 0,
      month: 0,
      day: 0,
    };
    if (props.value) {
      const duration = DateUtils.getDuration(props.value);
      period = {
        year: duration.years(),
        month: duration.months(),
        day: duration.days(),
      };
    }
    return period;
  };

  const handleChange = (changedValues: any, allFields: MomentInputObject) => {
    const period = {
      year: allFields.year ?? 0,
      month: allFields.month ?? 0,
      day: allFields.day ?? 0,
    };
    return props.onChange?.(DateUtils.toISOstring(period));
  };

  return (
    <Form<MomentInputObject>
      initialValues={transformInputValue()}
      component={false}
      name="period"
      form={form}
      onValuesChange={handleChange}
      layout={'vertical'}>
      <Row gutter={8}>
        <Col span={8}>
          <Form.Item
            label={props.translate('COMMON.LABEL.PERIOD.YEAR')}
            name={'year'}>
            <InputNumber disabled={props.disabled} min={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={props.translate('COMMON.LABEL.PERIOD.MONTH')}
            name={'month'}>
            <InputNumber disabled={props.disabled} min={0} max={11} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={props.translate('COMMON.LABEL.PERIOD.DAY')}
            name={'day'}>
            <InputNumber disabled={props.disabled} min={0} max={30} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize<ITotalEmploymentDurationInputFieldOwnProps>(TotalEmploymentDurationInputField as any);

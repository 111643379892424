import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DialogPanel from '@src/components/common/panel/DialogPanel';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationForm from '@src/components/externalEducationApplication/form/ExternalEducationApplicationForm';
import CoordinatorPickerModalContainer from '@src/components/externalEducationApplication/participant/CoordinatorPickerModalContainer';
import ExternalEducationApplicationInfoView from '@src/components/externalEducationApplication/view/ExternalEducationApplicationInfoView';
import ExternalEducationApplicationCoordinatorApprovalView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationCoordinatorApprovalView';
import ExternalEducationApplicationCoordinatorCompletedView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationCoordinatorCompletedView';
import ExternalEducationApplicationCoordinatorDurationView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationCoordinatorDurationView';
import ExternalEducationApplicationTraineeApprovalView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationTraineeApprovalView';
import ExternalEducationApplicationTraineeCompletedView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationTraineeCompletedView';
import ExternalEducationApplicationTraineeDurationView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationTraineeDurationView';
import { IActivityParticipant } from '@src/model/activity/ActivityParticipant';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IFileListsByType } from '@src/service/business/files/util';

import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';

import UserInterfaceUtils from '@src/components/common/util/UserInterfaceUtils';
import ExternalEducationApplicationInfoPanel from '@src/components/externalEducationApplication/view/ExternalEducationApplicationInfoPanel';
import ExternalEducationApplicationConsultationCoordinatorView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationConsultationCoordinatorView';
import ExternalEducationApplicationConsultationTraineeView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationConsultationTraineeView';
import ExternalEducationApplicationActivitiesModal from '@src/components/externalEducationApplication/view/section/ExternalEducationApplicationActivitiesModal';
import IIdRef from '@src/model/common/IdRef';
import { IEducationApplicationAnnex } from '@src/model/externalEducationApplication/EducationApplicationAnnex';
import { IExternalEducationApplicationFilePreviewData } from '@src/model/externalEducationApplication/ExternalEducationApplicationFilePreviewData';
import { IExternalEducationApplicationTimelineActivity } from '@src/model/externalEducationApplication/ExternalEducationApplicationTimelineActivity';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import { IUserCategory } from '@src/model/user/UserCategory';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData } from '@src/service/business/common/types';
import { ITrackableAction } from '@src/service/util/action/trackAction';
import { ActivityUtils } from '@src/service/util/activity/ActivityUtils';
import { Row, Steps } from 'antd';
import React from 'react';
import { IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';

const Step = Steps.Step;

// -- Prop types
// ----------
interface IExternalEducationApplicationViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  currentUser: IUserInfo;
  traineeUser?: IUserInfo;
  currentCoordinator?: IActivityParticipant;
  timeline: ICollectionData<IExternalEducationApplicationTimelineActivity>;
  termsOfAgreement?: string;
  onExternalEducationTemplateUpdate: (data: IExternalEducationTemplate) => void;
  onUpdateExternalEducationApplication?: (data: IExternalEducationApplication) => void;
  onSubmit?: (data: IExternalEducationApplication) => void;
  onUpdateAndChangePhase?: (newPhase: EducationApplicationActivityPhaseEnum, data: IExternalEducationApplication) => void;
  onChangePhase?: (newPhase: EducationApplicationActivityPhaseEnum) => void;
  onCancel?: () => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onCoordinatorAdd?: (userId: string) => void;
  onChangeCoordinator?: (userId: string) => void;
  onCreateNote?: () => void;
  onFinishConsultation?: () => void;
  onAnnexCreate?: (data: IEducationApplicationAnnex) => void;
  onFilePreview: (externalEducationApplicationId: string, filePreviewData: IExternalEducationApplicationFilePreviewData) => ITrackableAction;
  onExpenseSubmit?: (data: IExternalEducationExpense[]) => void;
}
type IExternalEducationApplicationViewProps = IExternalEducationApplicationViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationViewState {
  externalEducationApplication?: IExternalEducationApplication;
  currentStep?: number;
  isActivitiesModalVisible: boolean;
}

// -- Component
// ----------

class ExternalEducationApplicationView extends React.Component<IExternalEducationApplicationViewProps, IExternalEducationApplicationViewState> {
  state: IExternalEducationApplicationViewState = {
    isActivitiesModalVisible: false,
  };

  componentDidMount = () => {
    if (this.props.externalEducationApplication) {
      this.handleChangeApplicationData();
    }
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationViewProps, prevState: IExternalEducationApplicationViewState) => {
    if (this.props.externalEducationApplication !== prevProps.externalEducationApplication) {
      this.handleChangeApplicationData();
    }
  };

  isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  handleChangePhase = (phase: EducationApplicationActivityPhaseEnum, saveExternalEducationApplication: boolean = false) => {
    if (saveExternalEducationApplication && this.props.onUpdateAndChangePhase && this.state.externalEducationApplication) {
      UserInterfaceUtils.scrollToTop();
      this.props.onUpdateAndChangePhase(phase, this.state.externalEducationApplication);
    } else if (this.props.onChangePhase) {
      this.props.onChangePhase(phase);
      UserInterfaceUtils.scrollToTop();
    }
  };

  handleCoordinatorAdd = () => {
    if (this.props.onCoordinatorAdd) {
      this.props.onCoordinatorAdd(this.props.currentUser.id);
    }
  };

  render() {
    const isCurrentUserTrainee = this.props.isInRoles([UserRoleEnum.TRAINEE]);
    const isCurrentUserCoordinator = this.props.isInRoles([UserRoleEnum.COORDINATOR]);
    const isCurrentUserAdmin = this.props.isInRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]);

    const canParticipate = ActivityUtils.isParticipant(this.props.currentUser.id, this.props.externalEducationApplication.activity);

    return this.state.externalEducationApplication && this.props.applicationFiles ? (
      <React.Fragment>
        <Row className="timun-externalEducationApplicationView__coordinatorRow" justify="space-between">
          {this.props.currentCoordinator && (isCurrentUserAdmin || isCurrentUserCoordinator) && <HeaderTitle icon={null} title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.EVALUATOR_TITLE', { name: `${this.props.currentCoordinator.firstName} ${this.props.currentCoordinator.lastName}` })} />}
          {isCurrentUserAdmin && this.props.onChangeCoordinator && <CoordinatorPickerModalContainer currentCoordinator={this.props.currentCoordinator} onChangeCoordinator={this.props.onChangeCoordinator} />}
        </Row>

        {/* Transition info text panel */}
        <ExternalEducationApplicationInfoPanel canParticipate={canParticipate} paymentRequestedDateTime={this.props.externalEducationApplication.paymentRequestedDateTime} activity={this.props.externalEducationApplication.activity} onActivityModalOpen={this.handleActivitesModalOpen} />

        {/* ---------- Step 0 consultation ---------- */}
        {this.state.currentStep === 0 && (
          <React.Fragment>
            {isCurrentUserTrainee && (
              <ExternalEducationApplicationConsultationTraineeView
                externalEducationApplication={this.props.externalEducationApplication}
                timeline={this.props.timeline?.content}
                applicationFiles={this.props.applicationFiles}
                traineeUser={this.props.traineeUser}
                onPhaseChange={this.handleChangePhase}
                canParticipate={canParticipate}
              />
            )}

            {isCurrentUserCoordinator && (
              <ExternalEducationApplicationConsultationCoordinatorView
                currentUser={this.props.currentUser}
                externalEducationApplication={this.props.externalEducationApplication}
                timeline={this.props.timeline?.content}
                applicationFiles={this.props.applicationFiles}
                traineeUser={this.props.traineeUser}
                onPhaseChange={this.handleChangePhase}
                onCoordinatorAdd={this.handleCoordinatorAdd}
                onCreateNote={this.props.onCreateNote}
                canParticipate={canParticipate}
              />
            )}
          </React.Fragment>
        )}

        {/* ---------- Step 1 ---------- */}
        {this.state.currentStep === 1 && (
          <React.Fragment>
            <ExternalEducationApplicationForm
              externalEducationApplication={this.props.externalEducationApplication}
              applicationFiles={this.props.applicationFiles}
              traineeUser={this.props.traineeUser}
              isCurrentUserTrainee={isCurrentUserTrainee}
              isCurrentUserCoordinator={isCurrentUserCoordinator}
              onCreateNote={this.props.onCreateNote}
              timeline={this.props.timeline?.content}
              onUpdate={this.props.onUpdateExternalEducationApplication}
              onSubmit={this.props.onSubmit}
              onCancel={this.props.onCancel}
              onChangePhase={this.handleChangePhase}
              onFileUpload={this.props.onFileUpload}
              onFileRemove={this.props.onFileRemove}
              termsOfAgreement={this.props.termsOfAgreement}
              canParticipate={canParticipate}
            />
          </React.Fragment>
        )}

        {this.state.currentStep != null && this.state.currentStep !== 0 && this.state.currentStep !== 1 && (
          <DialogPanel className="timun-externalEducationApplication__stepsPanel">
            {/* current step reduced for 1 becuase consultation step is not part of <Steps /> component */}
            <Steps current={this.state.currentStep - 1} responsive={true}>
              <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_ONE_TITLE')} data-test-id="timun-externalEducationApplication__stepsView_1" />
              <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_TWO_TITLE')} data-test-id="timun-externalEducationApplication__stepsView_2" />
              <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_THREE_TITLE')} data-test-id="timun-externalEducationApplication__stepsView_3" />
              <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_FOUR_TITLE')} data-test-id="timun-externalEducationApplication__stepsView_3" />
            </Steps>

            <br />

            {/* ---------- Step 2 ---------- */}
            {this.state.currentStep === 2 && (
              <React.Fragment>
                {isCurrentUserTrainee && (
                  <React.Fragment>
                    <ExternalEducationApplicationTraineeApprovalView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.props.onUpdateExternalEducationApplication}
                      onExternalEducationTemplateUpdate={this.props.onExternalEducationTemplateUpdate}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                      canParticipate={canParticipate}
                      onCancelApplication={this.props.onCancel}
                    />
                  </React.Fragment>
                )}

                {(isCurrentUserCoordinator || isCurrentUserAdmin) && (
                  <React.Fragment>
                    <ExternalEducationApplicationCoordinatorApprovalView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdateExternalEducationApplication={this.props.onUpdateExternalEducationApplication}
                      onUserCategorySubmit={this.props.onUpdateExternalEducationApplication}
                      onExternalEducationApplicationAgreementSubmit={this.props.onUpdateExternalEducationApplication}
                      onChangePhase={this.handleChangePhase}
                      onCoordinatorAdd={this.handleCoordinatorAdd}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                      canParticipate={canParticipate}
                      isCurrentUserAdmin={isCurrentUserAdmin}
                      onFilePreview={this.props.onFilePreview}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {/* ---------- Step 3 ---------- */}
            {this.state.currentStep === 3 && (
              <React.Fragment>
                {isCurrentUserTrainee && (
                  <React.Fragment>
                    <ExternalEducationApplicationTraineeDurationView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.setApplication}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                      canParticipate={canParticipate}
                    />
                  </React.Fragment>
                )}

                {(isCurrentUserCoordinator || isCurrentUserAdmin) && (
                  <React.Fragment>
                    <ExternalEducationApplicationCoordinatorDurationView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.setApplication}
                      onAnnexCreate={this.props.onAnnexCreate}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                      canParticipate={canParticipate}
                      isCurrentUserAdmin={isCurrentUserAdmin}
                      onFilePreview={this.props.onFilePreview}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {/* ---------- Step 4 ---------- */}
            {this.state.currentStep === 4 && (
              <React.Fragment>
                {isCurrentUserTrainee && (
                  <React.Fragment>
                    <ExternalEducationApplicationTraineeCompletedView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.setApplication}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                      canParticipate={canParticipate}
                    />
                  </React.Fragment>
                )}

                {(isCurrentUserCoordinator || isCurrentUserAdmin) && (
                  <React.Fragment>
                    <ExternalEducationApplicationCoordinatorCompletedView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.setApplication}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                      canParticipate={canParticipate}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </DialogPanel>
        )}
        {/* ---------- Common application data ---------- */}
        {this.state.currentStep != null && this.state.currentStep !== 0 && this.state.currentStep !== 1 && (
          <ExternalEducationApplicationInfoView
            externalEducationApplication={this.state.externalEducationApplication}
            applicationFiles={this.props.applicationFiles}
            onExpenseSubmit={this.props.onExpenseSubmit}
            traineeUser={this.props.traineeUser}
            timeline={this.props.timeline?.content}
            onFileUpload={this.props.onFileUpload}
            isCurrentUserAdmin={isCurrentUserAdmin}
            isCurrentUserCoordinator={isCurrentUserCoordinator}
            isCurrentUserTrainee={isCurrentUserTrainee}
            canFileRemove={isCurrentUserAdmin}
            onFileRemove={this.props.onFileRemove}
            onCreateNote={this.props.onCreateNote}
            panelsActive={true}
            canParticipate={canParticipate}
            onUserCategorySubmit={this.handleUserCategorySubmit}
          />
        )}
        {/* ---------- Activites modal ---------- */}
        {this.state.isActivitiesModalVisible && <ExternalEducationApplicationActivitiesModal timeline={this.props.timeline?.content} onCancel={this.handleActivitesModalClose} />}
      </React.Fragment>
    ) : null;
  }

  toggleActivitiesModal = (isVisible: boolean) => this.setState({ isActivitiesModalVisible: isVisible });
  handleActivitesModalOpen = () => this.toggleActivitiesModal(true);
  handleActivitesModalClose = () => this.toggleActivitiesModal(false);
  handleUserCategorySubmit = (userCategoryIdList: IIdRef<string>[]) => {
    if (this.props.externalEducationApplication) {
      this.props.onUpdateExternalEducationApplication?.({
        ...this.props.externalEducationApplication,
        userCategories: userCategoryIdList as IUserCategory[],
      });
    }
  };

  private setApplication = (externalEducationApplication?: IExternalEducationApplication) => {
    this.setState({ externalEducationApplication });
  };

  private setStep = () => {
    let step: number | undefined = this.state.currentStep;
    if (this.isApplicationInPhases(['CONSULTATION_REQUESTED', 'CONSULTATION_STARTED', 'CONSULTATION_TERMINATED'])) {
      step = 0;
    } else if (this.isApplicationInPhases(['EXTERNAL_EDUCATION_APPLICATION_CREATION', 'RETURNED_TO_USER'])) {
      step = 1;
    } else if (this.isApplicationInPhases(['SUBMITTED', 'EXTERNAL_EDUCATION_APPLICATION_DENIED', 'USER_CANCELED', 'AWAITING_APPROVAL', 'EXTERNAL_EDUCATION_TEMPLATE_SELECTION', 'CONTRACT_DATA_PROCESSING', 'CONTRACT_GENERATION', 'AWAITING_SIGNED_CONTRACT', 'AWAITING_PAYMENT'])) {
      step = 2;
    } else if (this.isApplicationInPhases(['EDUCATION_IN_PROGRESS', 'CONTRACT_ANNEX_REQUESTED', 'CONTRACT_ANNEX_SUBMITTED'])) {
      step = 3;
    } else if (this.isApplicationInPhases(['CONTRACT_TERMINATED', 'EDUCATION_COMPLETED'])) {
      step = 4;
    }

    this.setState({ currentStep: step });
  };

  private handleChangeApplicationData = () => {
    this.setStep();
    this.setApplication(this.props.externalEducationApplication);
  };
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationViewOwnProps>(ExternalEducationApplicationView as any));

import { IActivity } from '@src/model/activity/Activity';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { IEducationCategory } from '@src/model/educationcategory/EducationCategory';
import { IEducationSector } from '@src/model/educationsector/EducationSector';
import { IEnrollmentRequirement } from '@src/model/enrollmentrequirement/EnrollmentRequirement';
import { IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { IExternalEducationOutcome } from '@src/model/externalEducationTemplate/ExternalEducationOutcome';
import { ICity } from '@src/model/location/City';
import { IOrganizationBaseInfo } from '@src/model/organization/Organization';
import { ISkill } from '@src/model/skillgroup/Skill';

export interface IExternalEducationTemplate {
  activity?: IActivity;
  title: string;
  description?: string;
  duration: number;
  enrollmentRequirements: IEnrollmentRequirement[];
  skills: ISkill[];
  educationSector?: IEducationSector;
  averagePrice: number;
  maxVoucherPrice?: number;
  certificateName: string;
  contractorQualification: string;
  educationCategories?: IEducationCategory[];
  educationOutcomes: IExternalEducationOutcome[];
  educationProvider: IOrganizationBaseInfo;
  externalEducationInstances: IExternalEducationInstance[];
  status: ICodeBookEntry<ExternalEducationTemplateStatusEnum>;
  instanceLocation?: IInstanceLocation;
  id: string;
  submittedAt: string;
}

export enum ExternalEducationTemplateStatusEnum {
  IN_CREATION = '1',
  CREATION_FINISHED = '2',
  PUBLISHED = '3',
  ARCHIVED = '4',
}

export interface IInstanceLocation {
  cities: ICity[];
  executableOnline: boolean;
}

import React from 'react';

import FileTypeUploadField from '@src/components/common/file/FileTypeUploadField';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { UserFileTypeEnum } from '@src/model/file/FileType';
import { IFile } from '@src/model/file/File';
import { IFileListsByType } from '@src/service/business/files/util';


// -- Prop types
// ----------

export interface IUserFileListUploadOwnProps {
  userFiles?: IFileListsByType;
  fileType: keyof typeof UserFileTypeEnum;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}
type IUserFileListUploadProps = IUserFileListUploadOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

const UserFileListUpload: React.FC<IUserFileListUploadProps> = (props: IUserFileListUploadProps) => {

  return <FileTypeUploadField
    fileList={props.userFiles}
    defaultFileType={props.fileType}
    onFileUpload={props.onFileUpload}
    onFileRemove={props.onFileRemove}
  />;
};

export default withLocalize<IUserFileListUploadOwnProps>(UserFileListUpload as any);

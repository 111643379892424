import { ExternalEducationTemplateTimelineActivityItemTypeEnum, ExternalEducationTemplateTimelineActivityTypeEnum, IExternalEducationTemplateTimelineActivity } from '@src/model/externalEducationTemplate/ExternalEducationTemplateTimelineActivity';
import { INote } from '@src/model/user/Note';
import { DateUtils } from '@src/service/util/date/DateUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';

/** Utility helper for timeline which is composed of very mixed contents */
export class ExternalEducationTemplateTimelineUIHelper {
  static getTimelineItemTitle = (timelineItem: IExternalEducationTemplateTimelineActivity) => {
    const content = timelineItem.content;
    const creator = `${content.createdBy?.firstName} ${content.createdBy?.lastName}`;

    let text = '';

    // ----- Timeline action
    if (ExternalEducationTemplateTimelineUIHelper.isTimelineItemAction(timelineItem)) {
      const phaseName = Object.keys(ExternalEducationTemplateTimelineActivityItemTypeEnum).find((phase) => ExternalEducationTemplateTimelineActivityItemTypeEnum[phase as keyof typeof ExternalEducationTemplateTimelineActivityItemTypeEnum] === content.type?.id);

      text = LocalizeService.translate(`EXTERNAL_EDUCATION_TEMPLATE.TIMELINE_ACTIVITY.${phaseName}`, { name: creator });
    }

    // ----- Timeline note
    else if (ExternalEducationTemplateTimelineUIHelper.isTimelineItemNote(timelineItem)) {
      text = LocalizeService.translate('EXTERNAL_EDUCATION_TEMPLATE.TIMELINE_ACTIVITY.NOTE.TITLE', { name: creator });
    } else {
      throw new Error(`Cannot resolve name for unknown timeline activity type: ${timelineItem.type}`);
    }

    return text;
  };

  static getTimelineItemDateTime = (timelineItem: IExternalEducationTemplateTimelineActivity) => {
    return DateUtils.toViewDateFormat(timelineItem.createdAt, 'dateWithTime');
  };

  static getTimelineItemNoteText = (timelineItem: IExternalEducationTemplateTimelineActivity) => {
    const content = timelineItem.content;
    if (ExternalEducationTemplateTimelineUIHelper.isTimelineItemContentNote(content)) {
      return content.text;
    } else {
      return '';
    }
  };

  static isTimelineItemNote = (timelineItem: IExternalEducationTemplateTimelineActivity) => {
    return timelineItem.type === ExternalEducationTemplateTimelineActivityTypeEnum.NOTE;
  };

  static isTimelineItemAction = (timelineItem: IExternalEducationTemplateTimelineActivity) => {
    return timelineItem.type === ExternalEducationTemplateTimelineActivityTypeEnum.ACTION;
  };

  // ----- Various type checks to ensure proper content is shown for timeline activities

  /** Check is timeline content of type INote for timeline type of NOTE */
  static isTimelineItemContentNote = (arg: any): arg is INote => {
    return 'text' in arg;
  };
}

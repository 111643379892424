import React from 'react';

export interface IScrollableContainerOwnProps {
  width?: number;
}

type IScrollableContainerProps = IScrollableContainerOwnProps;

/** Scrollable container used for displaying content too big for smaller devices (ie. charts)
 *  Positions itself realtive to parent container.
 *  Scrolls only on X axis (NOTE: can implement height for future requirements)
 *  Auto switches to scroll display after hitting medium device size breakpoint and toggles fixed container width
 *  Container width defaults to 1000px if not set by props.
 * */
// NOTE: rethink name of component if "real" scrollable container implemented (not just for responsive purposes)
const ScrollableContainer: React.FC<IScrollableContainerProps> = (props) => {
  return <div className="timun-scrollableContainer__container">
    <div style={props.width ? { width: `${props.width}px` } : undefined} className="timun-scrollableContainer__content">
      {props.children}
    </div>
  </div>;
};

export default ScrollableContainer;

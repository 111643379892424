import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import UsersCoursesUpdateBusinessStore, { IEnrollAllUsersCoursesPayload, IEnrollUsersCoursesPayload } from '@src/service/business/courses/UsersCoursesUpdateBusinessStore';
import { ITrackableAction, createTrackableAction } from '@src/service/util/action/trackAction';
import IIdRef from '@src/model/common/IdRef';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import CourseUserListView from '@src/components/user/list/CourseUserListView';
import { UserStatusEnum } from '@src/model/user/UserStatus';


// -- Const
// ----------
const VIEW_NAME = '@@COURSE_USER_LIST_CONTAINER';

// -- Prop types
// ----------

export interface ICourseUserListContainerOwnProps {
  courseId: string;
}
export interface ICourseUserListContainerStateProps {
  userListFilter: IUserListFilter;
  userList: ICollectionData<IUserInfo>;
  workPositionList: IWorkPosition[];
}

export interface ICourseUserListContainerDispatchProps {
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => void;
  enrollUser: (data: IEnrollUsersCoursesPayload) => ITrackableAction;
  enrollAllUsers: (data: IEnrollAllUsersCoursesPayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}
type ICourseUserListContainerProps = ICourseUserListContainerOwnProps & ICourseUserListContainerStateProps & ICourseUserListContainerDispatchProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const CourseUserListContainer = (props: ICourseUserListContainerProps) => {
  const predefinedFilter = { notEnrolledInCourse: props.courseId, status: UserStatusEnum.ACTIVE };
  const initialCollectionValues: ICollectionFetchPayload<IUserListFilter> = { filter: predefinedFilter };
  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IUserListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchUserList,
    initialValues: initialCollectionValues,
  }, [props.courseId]);

  const handleSelectedUserSubmit = (userIds: IIdRef<string>[]) => {
    const enrollData: IEnrollUsersCoursesPayload = {
      users: userIds,
      courses: [{ id: props.courseId }],
    };
    props.enrollUser(enrollData)
      .track()
      .subscribe(
        () => {
          onUpdateList();
          props.reportMessage({ message: props.translate('COURSE_USER_LIST.ENROLL_USERS_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        }
      );
  };

  const handleAllUsersEnroll = () => {
    const enrollAllData: IEnrollAllUsersCoursesPayload = {
      courses: [{ id: props.courseId }],
    };
    props.enrollAllUsers(enrollAllData)
      .track()
      .subscribe(
        () => {
          onUpdateList();
          props.reportMessage({ message: props.translate('COURSE_USER_LIST.ENROLL_ALL_USERS_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        }
      );
  };

  const handleFilterSubmit = (filter: IUserListFilter) => updateCollectionParams.onFilterSet({ ...filter, ...predefinedFilter });

  return (
    <React.Fragment>
      {props.userList && <CourseUserListView
        onFilterSubmit={handleFilterSubmit}
        filter={collectionParams.filter}
        workPositionList={props.workPositionList}
        onSelecteUserSubmit={handleSelectedUserSubmit}
        onAllUsersEnroll={handleAllUsersEnroll}
        onPageChange={updateCollectionParams.onPageChange}
        userList={props.userList} />}

    </React.Fragment>
  );

};


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseUserListContainerOwnProps): ICourseUserListContainerStateProps => ({
  userListFilter: UserListBusinessStore.selectors.getUserListFilter(state),
  userList: UserListBusinessStore.selectors.getUserList(state),
  workPositionList: CollectionBusinessStore.selectors.getCollectionContent(state, 'WorkPosition'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICourseUserListContainerDispatchProps => ({
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => dispatch(UserListBusinessStore.actions.fetchUserList({ ...params })),
  enrollUser: (data: IEnrollUsersCoursesPayload) => createTrackableAction(dispatch(UsersCoursesUpdateBusinessStore.actions.enrollUsersCourses(data))),
  enrollAllUsers: (data: IEnrollAllUsersCoursesPayload) => createTrackableAction(dispatch(UsersCoursesUpdateBusinessStore.actions.enrollAllUsersCourses(data))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});


export default connect<ICourseUserListContainerStateProps, ICourseUserListContainerDispatchProps, ICourseUserListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(CourseUserListContainer as any));

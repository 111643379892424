import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import React from 'react';
import ZoomWrapper from '@src/components/common/zoom/ZoomWrapper';
import UserGroupHierarchyGraph from '@src/components/usergroup/hierarchy/UserGroupHierarchyGraph';

// -- Prop types
// ----------

export interface IUserGroupHierarchyViewOwnProps {
  basePath: string;
  returnRoute?: string;
  userGroupHierarchy: IUserGroupHierarchy;
  showButtons: boolean;
  onUserGroupHierachyUpdate?: () => void;
}

type IUserGroupHierarchyViewProps = IUserGroupHierarchyViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------
const UserGroupHierarchyView: React.FC<IUserGroupHierarchyViewProps> = (props: IUserGroupHierarchyViewProps) => {
  return (
    <ZoomWrapper
      className={'timun-userGroupHierarchyView__ZoomWrapper'}
      content={<UserGroupHierarchyGraph basePath={props.basePath} returnRoute={props.returnRoute} userGroupHierarchy={props.userGroupHierarchy} onUserGroupHierachyUpdate={props.onUserGroupHierachyUpdate} canAdd={props.showButtons} canEdit={props.showButtons} />}
    >
      {({ controls, content }) => {
        return (
          <div className="panel full-width">
            <div className="timun-userGroupHierarchyView__controls">{controls}</div>
            <div className="timun-userGroupHierarchyView__content">{content}</div>
          </div>
        );
      }}
    </ZoomWrapper>
  );
};

// -- HOCs and exports
// ----------
export default withRoles(withLocalize<IUserGroupHierarchyViewOwnProps>(UserGroupHierarchyView as any));

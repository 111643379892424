import { Column } from '@ant-design/charts';
import { ColumnConfig } from '@ant-design/charts/es/column';
import React from 'react';

// -- Const
// ----------
const DEFAULT_COLOR = '#1e81b0';

export interface IColumnChartData {
  label: string;
  value: number;
  color?: string;
}

// -- Props
// ----------
export interface ITimunColumnChartOwnProps {
  data: IColumnChartData[];
}

type ITimunColumnChart = ITimunColumnChartOwnProps;

/** Timun colum chart component */
export const TimunColumnChart = (props: ITimunColumnChart) => {
  const config: ColumnConfig = {
    data: props.data,
    color: ({ label }) => {
      let color: string | undefined;
      color = props.data.find((columnData: IColumnChartData) => columnData.label === label)?.color;
      if (color) {
        return color;
      }
      return DEFAULT_COLOR;
    },
    xField: 'label',
    yField: 'value',
    yAxis: {
      label: {
        style: {
          fontSize: 14,
          fontWeight: 'bold',
        },
      },
    },
    xAxis: {
      label: {
        autoEllipsis: true,
        autoHide: false,
        autoRotate: true,
        style: {
          fontSize: 14,
          fontWeight: 'bold',
        },
      },
    },
    style: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    label: {
      position: 'top',
      style: {
        fontSize: 14,
        fill: '#35393d',
        opacity: 0.6,
        fontWeight: 'bold',
      },
      offset: 10,
    },
  };
  return <Column {...config} />;
};

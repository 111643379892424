import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { IEducationSector } from '@src/model/educationsector/EducationSector';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export type IEducationSectorCreatePayload = Pick<IEducationSector, Exclude<keyof IEducationSector, 'id' | 'description'>>;

export interface IEducationSectorListFilter {
  name?: string;
  active?: boolean;
}

// List filter ID
const EDUCATION_SECTOR_LIST_FILTER = '@@EDUCATION_SECTOR_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns EducationSector from store. */
const getEducationSector = (store: any): IEducationSector => store.educationSector;

/** Returns list of EducationSectors from store. */
const getEducationSectorList = (store: any): ICollectionData<IEducationSector> => store.educationSectorList;

/** Returns EducationSector list filter. */
const getEducationSectorListFilter = (store: any): IEducationSectorListFilter => ListFilterBusinessStore.selectors.getListFilter(store, EDUCATION_SECTOR_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  EDUCATION_SECTOR_FETCH: 'EDUCATION_SECTOR_FETCH',
  EDUCATION_SECTOR_LOAD: 'EDUCATION_SECTOR_LOAD',
  EDUCATION_SECTOR_CLEAR: 'EDUCATION_SECTOR_CLEAR',
  EDUCATION_SECTOR_CREATE: 'EDUCATION_SECTOR_CREATE',
  EDUCATION_SECTOR_UPDATE: 'EDUCATION_SECTOR_UPDATE',
  EDUCATION_SECTOR_DELETE: 'EDUCATION_SECTOR_DELETE',
  EDUCATION_SECTOR_LIST_FETCH: 'EDUCATION_SECTOR_LIST_FETCH',
  EDUCATION_SECTOR_LIST_LOAD: 'EDUCATION_SECTOR_LIST_LOAD',
  EDUCATION_SECTOR_LIST_CLEAR: 'EDUCATION_SECTOR_LIST_CLEAR',
};

/** Fetch EducationSector by ID. */
const fetchEducationSector = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EDUCATION_SECTOR_FETCH,
    payload: params,
  };
};

/** Load EducationSector to store. */
const loadEducationSector = (data: IEducationSector): IPayloadAction<IEducationSector> => {
  return {
    type: Actions.EDUCATION_SECTOR_LOAD,
    payload: data,
  };
};

/** Clear EducationSector from store. Eg. when leaving view. */
const clearEducationSectorData = (): ILemonAction => {
  return {
    type: Actions.EDUCATION_SECTOR_CLEAR,
  };
};

/** Create new EducationSector. */
const createEducationSector = (data: IEducationSectorCreatePayload): IPayloadAction<IEducationSectorCreatePayload> => {
  return {
    type: Actions.EDUCATION_SECTOR_CREATE,
    payload: data,
  };
};

/** Update EducationSector by ID. */
const updateEducationSector = (data: IEducationSector): IPayloadAction<IEducationSector> => {
  return {
    type: Actions.EDUCATION_SECTOR_UPDATE,
    payload: data,
  };
};

/** Delete EducationSector by ID. */
const deleteEducationSector = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EDUCATION_SECTOR_DELETE,
    payload: {
      id,
    },
  };
};

/** Fetch EducationSector list by filter. */
const fetchEducationSectorList = (params: ICollectionFetchPayload<IEducationSectorListFilter>): IPayloadAction<ICollectionFetchPayload<IEducationSectorListFilter>> => {
  return {
    type: Actions.EDUCATION_SECTOR_LIST_FETCH,
    payload: params,
  };
};

/** Load EducationSector list to store. */
const loadEducationSectorList = (data: ICollectionData<IEducationSector>): IPayloadAction<ICollectionData<IEducationSector>> => {
  return {
    type: Actions.EDUCATION_SECTOR_LIST_LOAD,
    payload: data,
  };
};

/** Clear EducationSector list from store. Eg. when leaving list view. */
const clearEducationSectorList = (): ILemonAction => {
  return {
    type: Actions.EDUCATION_SECTOR_LIST_CLEAR,
  };
};

/** Store EducationSector list filter to store. */
const storeEducationSectorListFilter = (listFilter: IEducationSectorListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(EDUCATION_SECTOR_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchEducationSectorEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_SECTOR_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('EducationSector')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationSector(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EducationSector_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_SECTOR.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching EducationSector', error);
      return o;
    })
  );
};

const createEducationSectorEffect = (action$: Observable<IPayloadAction<IEducationSectorCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_SECTOR_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationSector')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationSector(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EducationSector_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_SECTOR.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating EducationSector', error);
      return o;
    })
  );
};

const updateEducationSectorEffect = (action$: Observable<IPayloadAction<IEducationSector>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_SECTOR_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('EducationSector')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationSector(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_SECTOR.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating EducationSector', error);
      return o;
    })
  );
};

const fetchEducationSectorListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IEducationSectorListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_SECTOR_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationSector')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationSectorList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_SECTOR.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching EducationSector list', error);
      return o;
    })
  );
};

const deleteEducationSectorEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_SECTOR_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;
      return EntityApiServiceRegistry.getService('EducationSector')
        .deleteEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_SECTOR.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting EducationSector', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const educationSector = (state: IEducationSector | null = null, action: IPayloadAction<IEducationSector>) => {
  if (action.type === Actions.EDUCATION_SECTOR_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EDUCATION_SECTOR_CLEAR) {
    return null;
  }

  return state;
};

const educationSectorList = (state: ICollectionData<IEducationSector> | null = null, action: IPayloadAction<ICollectionData<IEducationSector>>) => {
  if (action.type === Actions.EDUCATION_SECTOR_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EDUCATION_SECTOR_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const EducationSectorBusinessStore = {
  actions: {
    fetchEducationSector,
    loadEducationSector,
    clearEducationSectorData,
    createEducationSector,
    updateEducationSector,
    deleteEducationSector,
    fetchEducationSectorList,
    loadEducationSectorList,
    clearEducationSectorList,
    storeEducationSectorListFilter,
  },

  selectors: {
    getEducationSector,
    getEducationSectorList,
    getEducationSectorListFilter,
  },

  effects: {
    fetchEducationSectorEffect,
    createEducationSectorEffect,
    updateEducationSectorEffect,
    deleteEducationSectorEffect,
    fetchEducationSectorListEffect,
  },

  reducers: {
    educationSector,
    educationSectorList,
  },
};

// --
// export business store
export default EducationSectorBusinessStore;

import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import StatusBadge from '@src/components/common/status/StatusBadge';
import { IOrganization } from '@src/model/organization/Organization';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { AddressUtils } from '@src/service/util/location/AddressUtils';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Button, Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router';

export interface IOrganizationDetailsPanelOwnProps {
  organization: IOrganization;
  toggleEdit: (isEditing: boolean) => void;
  onEdit?: (org: IOrganization) => void;
}

type IOrganizationDetailsPanelProps = IOrganizationDetailsPanelOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & IWithPathResolverOwnProps;

interface IOrganizationDetailsPanelState {
}

class OrganizationDetailsPanel extends React.Component<IOrganizationDetailsPanelProps, IOrganizationDetailsPanelState> {
  state: IOrganizationDetailsPanelState = {};

  render = () => {
    const organization = this.props.organization;
    const isPaymentInfoVisible = RoleUtils.allowedRoles([UserRoleEnum.COORDINATOR, UserRoleEnum.ORGANIZATION_ADMIN]) || (this.props.organization.currentUserMember != null);
    return (
      <Card className="lemon-card--fullWidthBody">
        <Col className="timun-organizationView__orgCol">
          <Row gutter={6}>
            <Col lg={4} md={6} xs={24} className="text-center">
              <AppUserAvatar imagePath={this.props.organization.logo ? this.props.resolveApiFilePath(this.props.organization.logo.id) : undefined} size="large" />
            </Col>
            <Col lg={20} md={18} xs={24}>
              <Row align="middle" gutter={8}>
                <Col>
                  <Typography.Title level={1}>{organization.company.name}</Typography.Title>
                </Col>
                <Col>
                  <StatusBadge status={this.props.organization.active} />
                </Col>
              </Row>
              <Col>
                <Row>
                  <Col xs={24} md={12} lg={12}>
                    <ViewSectionContentItem icon={<LemonIcon name="user" size="xxlarge" />} title={organization.company.identificationNumber} description={this.props.translate('ORGANIZATION_VIEW.IDENTIFICATION_NUMBER_LABEL')} />
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    {organization.company.contactData?.map((item: IContactData) => (
                      <ViewSectionContentItem key={item.id} icon={<LemonIcon name={item.type.id === ContactDataTypeEnum.EMAIL ? 'mail' : 'phone'} size="xxlarge" />} title={item.value} description={this.props.translate('ORGANIZATION_VIEW.CONTACT_LABEL')} />
                    ))}
                  </Col>
                  {organization.webUrl &&
                    <Col xs={24} md={12} lg={12}>
                      <ViewSectionContentItem icon={<LemonIcon name="link" size="xxlarge" />} title={<Link target={'_blank'} to={organization.webUrl}>{organization.webUrl}</Link>} description={this.props.translate('ORGANIZATION_VIEW.URL_LABEL')} />
                    </Col>
                  }
                  <Col xs={24} md={12} lg={12}>
                    <ViewSectionContentItem icon={<LemonIcon name="address" size="xxlarge" />} title={AddressUtils.getFullAddress(organization.company.address)} description={this.props.translate('ORGANIZATION_VIEW.ADDRESS_LABEL')} />
                  </Col>
                  {isPaymentInfoVisible &&
                    <React.Fragment>
                      <Col xs={24} md={12} lg={12}>
                        <ViewSectionContentItem icon={<LemonIcon name="bank" size="xxlarge" />} title={organization.paymentInfo?.bankName} description={this.props.translate('ORGANIZATION_VIEW.BANK_LABEL')} />
                      </Col>
                      <Col xs={24} md={12} lg={12}>
                        <ViewSectionContentItem icon={<LemonIcon name="iban" size="xxlarge" />} title={organization.paymentInfo?.iban} description={this.props.translate('ORGANIZATION_VIEW.IBAN_LABEL')} />
                      </Col>
                    </React.Fragment>
                  }
                </Row>
              </Col>
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              {organization.description &&
                <Row className="timun-organizationView__detailsRow">
                  <span dangerouslySetInnerHTML={{ __html: organization.description }} />
                </Row>
              }

              {/* spacer row */}
              <Row>
                <Col>&nbsp;</Col>
              </Row>

              <Row justify="center">
                <Col>
                  {this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && (
                    this.props.organization.active ? <Button danger={true} onClick={this.handleDeactivation}> {this.props.translate('COMMON.ACTION_DEACTIVATE')}</Button> :
                      <Button type="primary" onClick={this.handleActivation}> {this.props.translate('COMMON.ACTION_ACTIVATE')}</Button>
                  )}
                  &nbsp;&nbsp;
                  {(this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && (
                    <Button icon={<LemonIcon name="edit" />} onClick={() => this.props.toggleEdit(true)}>
                      {this.props.translate('ORGANIZATION_VIEW.EDIT_BUTTON')}
                    </Button>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Card>
    );
  };

  handleDeactivation = () => this.handleActiveStatusChange(false);
  handleActivation = () => this.handleActiveStatusChange(true);

  handleActiveStatusChange = (isActive: boolean) => {
    const updatedStatus = { ...this.props.organization, active: isActive };
    this.props.onEdit?.(updatedStatus);
  };

  handleSubmit = (org: IOrganization) => {
    this.props.onEdit?.(org);
  };
}

export default withLocalize<IOrganizationDetailsPanelOwnProps>(withRoles(withPathResolver(OrganizationDetailsPanel as any)));

import React from 'react';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseCardList from '@src/components/course/list/CourseCardList';
import { ICourse } from '@src/model/course/Course';
import { ICollectionData } from '@src/service/business/common/types';

// -- Prop types
// ----------

export interface ITraineeStartedCourseListViewOwnProps {
  courseList: ICollectionData<ICourse>;
}
type ITraineeStartedCourseListViewProps = ITraineeStartedCourseListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display trainee started courses list */
const TraineeStartedCourseListView: React.FC<ITraineeStartedCourseListViewProps> = (props) => {
  return (
    <AppContent title={props.translate('COURSE_LIST_STARTED.VIEW_TITLE')}>
      <CourseCardList showProgressView={true} courses={props.courseList.content} getPath={CourseHelperUtils.getTraineePath} />
    </AppContent>
  );
};

export default withLocalize<ITraineeStartedCourseListViewOwnProps>(TraineeStartedCourseListView as any);

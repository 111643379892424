import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import UserGroupAccessFormWrapper from '@src/components/usergroup/form/UserGroupAccessForm';
import UserGroupListView from '@src/components/usergroup/list/UserGroupListView';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserGroupBusinessStore from '@src/service/business/usergroup/UserGroupBusinessStore';
import UserGroupListBusinessStore, { IUserGroupListFilter } from '@src/service/business/usergroup/UserGroupListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Button, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const USER_GROUP_ROUTE = AppConfigService.getValue('components.userGroups.userGroupRouterProp');

// -- Prop types
// ----------
export interface IUserGroupListContainerOwnProps {
  memberId?: string;
  userGroupType?: UserGroupTypeEnum;
  showAvailableGroups?: boolean;
}

export interface IUserGroupListContainerStateProps {
  userGroupList: ICollectionData<IUserGroup>;
  userGroupListFilter: IUserGroupListFilter;
}

export interface IUserGroupListContainerDispatchProps {
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => ITrackableAction;
  clearUserGroupList: () => void;
  storeFilter: (filter: IUserGroupListFilter) => void;
  clearFilter: () => void;
  joinUserGroup: (userGroupAccessCode: string) => ITrackableAction;
}

type IUserGroupListContainerProps = IUserGroupListContainerOwnProps & IUserGroupListContainerStateProps & IWithRolesOwnProps & IUserGroupListContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

interface IUserGroupListContainerState {
  page: number;
  size: number;
  sort: string[];
  isAccessModalVisible: boolean;
}
// -- Component
// ----------

class UserGroupListContainer extends React.Component<IUserGroupListContainerProps, IUserGroupListContainerState> {
  state: IUserGroupListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: [],
    isAccessModalVisible: false,
  };

  componentDidMount() {
    this.props.storeFilter({ memberId: this.props.memberId, withoutCurrentUserGroups: this.props.showAvailableGroups });
  }

  componentDidUpdate(prevProps: IUserGroupListContainerProps, prevState: IUserGroupListContainerState) {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || prevProps.userGroupListFilter !== this.props.userGroupListFilter) {
      this.updateList();
    }
  }

  componentWillUnmount() {
    this.props.clearUserGroupList();
  }

  render() {
    return (
      <React.Fragment>
        <Row className="timun-wrapper__gutterBox--vertical" justify="end">
          <Button type="primary" onClick={this.handleUserGroupAccess}>
            {this.props.translate('USER_GROUP.JOIN_BUTTON_LABEL')}
          </Button>
        </Row>
        {/* ----- User group list MEMBER ----- */}
        {this.props.userGroupList && (
          <UserGroupListView
            emptyMessage={this.props.showAvailableGroups ? this.props.translate('USER_GROUP.AVAILABLE.NO_DATA') : this.props.translate('USER_GROUP.NO_DATA')}
            userGroupList={this.props.userGroupList}
            userGroupListFilter={this.props.userGroupListFilter}
            showButtons={false}
            basePath={USER_GROUP_ROUTE}
            returnRoute={this.props.location?.pathname}
            onPageChange={this.handlePageChange}
          />
        )}

        {this.state.isAccessModalVisible && <UserGroupAccessFormWrapper title={this.props.translate('ACCESS_CODE_FORM.TITLE')} onSubmit={this.handleSubmit} onCancel={this.handleCancel} />}
      </React.Fragment>
    );
  }

  handleUserGroupAccess = () => {
    this.toggleAccessModal(true);
  };

  toggleAccessModal = (isVisible: boolean) => {
    this.setState({ isAccessModalVisible: isVisible });
  };

  handleCancel = () => {
    this.toggleAccessModal(false);
  };

  handleSubmit = (accessCode: string) => {
    this.props
      .joinUserGroup(accessCode)
      .track()
      .subscribe(
        // Success
        () => {
          this.handleDataUpdate();
          this.toggleAccessModal(false);
        }
      );
  };

  handleDataUpdate = () => {
    this.updateList();
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  updateList = () => {
    this.props.fetchUserGroupList({
      filter: { ...this.props.userGroupListFilter, type: this.props.userGroupType },
      page: this.state.page,
      size: this.state.size,
      sort: this.state.sort,
    });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserGroupListContainerStateProps => ({
  userGroupList: UserGroupListBusinessStore.selectors.getUserGroupList(state),
  userGroupListFilter: UserGroupListBusinessStore.selectors.getUserGroupListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserGroupListContainerDispatchProps => ({
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => createTrackableAction(dispatch(UserGroupListBusinessStore.actions.fetchUserGroupList(params))),
  clearUserGroupList: () => dispatch(UserGroupListBusinessStore.actions.clearUserGroupList()),
  storeFilter: (filter: IUserGroupListFilter) => dispatch(UserGroupListBusinessStore.actions.storeGroupListFilter(filter)),
  clearFilter: () => dispatch(UserGroupListBusinessStore.actions.clearGroupListFilter()),
  joinUserGroup: (userGroupAccessCode: string) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.joinUserGroup(userGroupAccessCode))),
});

export default connect<IUserGroupListContainerStateProps, IUserGroupListContainerDispatchProps, IUserGroupListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRoles(withLocalize(withRouter(UserGroupListContainer as any))));

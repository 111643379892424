import { FormComponentProps } from '@ant-design/compatible/lib/form';
import SkillListPicker from '@src/components/common/datapicker/SkillListPicker';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IfTenantPropEnabled from '@src/components/tenant/IfTenantPropEnabled';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { HzzUserRoleEnum, UserRoleEnum } from '@src/model/user/UserRole';
import { UserStatusEnum } from '@src/model/user/UserStatus';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import { Button, Col, Input, Row, Select, Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

// --
// ----- Const

export enum UserListActivityEnum {
  ALL_USERS = 0,
  ACTIVE_ONLY = 1,
}

// --
// ----- Prop types
const FormItem = Form.Item;
const Option = Select.Option;

interface IAdminUserManagementListFilterOwnProps {
  userListFilter?: IUserListFilter;
  workPositionList?: IWorkPosition[];
  showUserOibFilter?: boolean;
  showUserActivityStatusFilter?: boolean;
  showUserRoleFilter?: boolean;
  onFilterSubmit?: (listFilter: IUserListFilter) => void;
}

type IAdminUserManagementListFilterProps = IAdminUserManagementListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------
const AdminUserManagementListFilter: React.FC<IAdminUserManagementListFilterProps> = (props: IAdminUserManagementListFilterProps) => {
  const [form] = Form.useForm<IUserListFilter>();
  const userRoleEnum = useMemo(() => (props.isTenantPropEnabled('courses') ? UserRoleEnum : HzzUserRoleEnum), [props.isTenantPropEnabled]);

  const handleFilterClear = useCallback(() => {
    form.resetFields();
    props.onFilterSubmit?.({});
  }, [props.onFilterSubmit, form.resetFields]);

  const handleFinish = useCallback(
    (values: IUserListFilter) => {
      props.onFilterSubmit?.(values);
    },
    [props.onFilterSubmit]
  );

  return (
    <Form form={form} initialValues={props.userListFilter} onFinish={handleFinish} className="timun-userManagementListFilter__form" layout="vertical">
      <Row gutter={12}>
        <Col xs={24} sm={12} md={7}>
          <FormItem name="name" label={props.translate('ADMIN_USER_MANAGEMENT_LIST_FILTER.NAME_LABEL')}>
            <Input prefix={<LemonIcon name="search" />} placeholder={props.translate('COMMON.ACTION_SEARCH')} allowClear={true} />
          </FormItem>
        </Col>
        {props.showUserOibFilter && (
          <Col xs={24} sm={12} md={6}>
            <FormItem name="identificationNumber" label={props.translate('ADMIN_USER_MANAGEMENT_LIST_FILTER.OIB_LABEL')}>
              <Input prefix={<LemonIcon name="search" />} allowClear={true} />
            </FormItem>
          </Col>
        )}
        {props.showUserRoleFilter && (
          <Col xs={24} sm={12} md={6}>
            <FormItem name="role" label={props.translate('ADMIN_USER_MANAGEMENT_LIST_FILTER.ROLE_LABEL')}>
              <Select placeholder={props.translate('USER_FORM.ROLES_PLACEHOLDER')} allowClear={true}>
                {Object.keys(userRoleEnum).map((item: any) => (
                  <Option value={userRoleEnum[item as keyof typeof userRoleEnum]} key={userRoleEnum[item as keyof typeof userRoleEnum]}>
                    {props.translate(`USER_FORM.ROLE_${item}_LABEL`)}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        )}
        {props.showUserActivityStatusFilter && (
          <Col xs={24} sm={12} md={4}>
            <FormItem name="status" label={props.translate('USER_FORM.ACTIVITY_PLACEHOLDER')}>
              <Select placeholder={props.translate('USER_FORM.STATUS_PLACEHOLDER')} allowClear={true}>
                {Object.keys(UserStatusEnum).map((item) => (
                  <Option value={UserStatusEnum[item as keyof typeof UserStatusEnum]} key={UserStatusEnum[item as keyof typeof UserStatusEnum]}>
                    {props.translate(`USER_FORM.STATUS_${item}_LABEL`)}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        )}
        {props.isTenantPropEnabled('admin_workpositions') && (
          <Col xs={24} sm={12} md={7}>
            <FormItem name={'workPosition'} label={props.translate('ADMIN_USER:MANAGEMENT_LIST_FILTER.WORK_POSITION_LABEL')}>
              <Select placeholder={props.translate('USER_FORM.WORK_POSITION_NAME_PLACEHOLDER')} allowClear={true}>
                {props.workPositionList?.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        )}

        <IfTenantPropEnabled property={['skillsgap', 'admin_skills']} fallback="">
          <Col xs={24} sm={12} md={7}>
            <Form.Item name={'skills'} label={props.translate('ADMIN_USER_MANAGEMENT_LIST_FILTER.SKILL_LABEL')}>
              <SkillListPicker />
            </Form.Item>
          </Col>
        </IfTenantPropEnabled>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xs={24} md={8} lg={3}>
          <Button type="primary" onClick={form.submit}>
            {props.translate('COMMON.ACTION_FILTER')}{' '}
          </Button>
        </Col>
        <Col xs={24} md={8} lg={3}>
          <Button onClick={handleFilterClear}> {props.translate('COMMON.ACTION_RESET_FILTER')} </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize(withTenantPropEnabled<IAdminUserManagementListFilterOwnProps>(AdminUserManagementListFilter as any));

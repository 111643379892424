import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { emailTemplateTypeEnum } from '@src/model/emailtemplate/EmailTemplate';
import { IEmailTemplateListFilter } from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Col, Form, Row, Select } from 'antd';
import React, { useCallback } from 'react';

// -- Const
// ----------
const emailTemplateTypes = Object.keys(emailTemplateTypeEnum).map((key) => {
  return {
    name: key,
    id: emailTemplateTypeEnum[key],
  };
});

const ALL_EMAIL_TEMPLATE_TYPES = '';

// -- Prop types
// ----------
interface IEmailTemplateFilterOwnProps {
  onFilterChange: (filter: IEmailTemplateListFilter) => void;
  filter?: IEmailTemplateListFilter;
}

type IEmailTemplateFilterProps = IEmailTemplateFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------
const EmailTemplateFilter: React.FC<IEmailTemplateFilterProps> = (props: IEmailTemplateFilterProps) => {
  const [form] = Form.useForm<IEmailTemplateListFilter>();

  const handleChange = useCallback(
    (changedValues: any, emailTemplateType: IEmailTemplateListFilter) => {
      props.onFilterChange?.(emailTemplateType);
    },
    [props.onFilterChange]
  );

  return (
    <Form<IEmailTemplateListFilter> form={form} name={'emailTemplateListFilter'} onValuesChange={handleChange}>
      <Row gutter={12}>
        <Col xs={24} md={12}>
          <Form.Item label={props.translate('COMMON.ACTION_FILTER')} name={'type'} trigger={'onSelect'}>
            <Select placeholder={props.translate('EMAIL_TEMPLATE_FILTER.ALL_TYPES_LABEL')}>
              <Select.Option value={ALL_EMAIL_TEMPLATE_TYPES}>{props.translate('EMAIL_TEMPLATE_FILTER.ALL_TYPES_LABEL')}</Select.Option>
              {emailTemplateTypes.map((type) => {
                return (
                  <Select.Option key={type.id} value={type.id}>
                    {props.translate(`EMAIL_TEMPLATE_FORM.${type.name}_TYPE`)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IEmailTemplateFilterOwnProps>(EmailTemplateFilter as any);

import React from 'react';

import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Typography } from 'antd';

export interface ILemonDefaultErrorPublicProps { }
type ILemonDefaultErrorProps = ILemonDefaultErrorPublicProps & IWithLocalizeOwnProps;

class LemonDefaultError extends React.Component<ILemonDefaultErrorProps> {
  render() {
    return (
      <div className="lemon-error__errorPlaceholderContainer">
        <LemonImage imagePath="error-image.png" className="lemon-error__errorImage" />
        <br />
        <div className="lemon-error__errorDesriptionContainer">
          <Typography.Title level={2}>{this.props.translate('ERROR_PAGE.DEFAULT_ERROR_TITLE')}</Typography.Title>
          <Typography.Title level={4}>{this.props.translate('ERROR_PAGE.DEFAULT_ERROR_DESCRIPTION')}</Typography.Title>
        </div>
      </div>
    );
  }
}

export default withLocalize<ILemonDefaultErrorPublicProps>(LemonDefaultError as any);

import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import { IRoleTypes } from '@src/components/common/member/MemberList';
import TimunShortInfoTable from '@src/components/common/table/TimunShortInfoTable';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IMemberUser } from '@src/model/common/MemberUser';
import { IReference } from '@src/model/common/Reference';
import { ICollectionData } from '@src/service/business/common/types';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

export interface IMemberTableListOwnProps<T> {
  memberList: ICollectionData<IMemberUser<T>>;
  canOpenProfile?: boolean;
  roleTypes: IRoleTypes[];
  canEditMember?: (member: IMemberUser<T>) => boolean;
  onPageChange?: (page?: number | undefined, size?: number | undefined) => void;
  onUpdateMember?: (user: IMemberUser<T>) => void;
  onDeleteMember?: (user: IMemberUser<T>) => void;

}
type IMemberTableListProps<T> = IMemberTableListOwnProps<T>;

// -- Component
// ----------

class MemberTableList<T extends IReference> extends React.Component<IMemberTableListProps<T>> {

  getRoleLabel = (role: T) => {
    const filtered = this.props.roleTypes.find((type) => type.value === role.id);
    return filtered?.label;
  };

  render = () => {
    return (
      <React.Fragment>
        <TimunShortInfoTable<IMemberUser<T>>
          items={this.props.memberList.content}
          bordered={false}
          paginationData={this.props.memberList.page}
          renderFrontAdditionalColumns={this.renderAvatar}
          customNameRender={(member) => <UserNameView user={member.user} fallback={LocalizeService.translate('USER_VIEW.USER_DELETED_LABEL')} link={this.props.canOpenProfile} />}
          rowDescription={this.renderRole}
          paginationConfig={{ onChange: this.props.onPageChange }}
          actionButtons={this.props.canEditMember != null ? {
            edit: { showButton: this.props.canEditMember, onClick: this.props.onUpdateMember },
            delete: { showButton: this.props.canEditMember, onClick: this.props.onDeleteMember },
          } : undefined}
        />
      </React.Fragment>
    );
  };

  renderAvatar = () => (
    <Column
      key="avatar"
      width="5%"
      align="center"
      render={(record: IMemberUser<T>) => <Link to={`/user/${record.user.id}`}><AppUserAvatar size="small" imagePath={record.user.profileImageUrl} /></Link>}
    />
  );

  renderRole = (member: IMemberUser<T>) => (
    <Typography.Paragraph key={member.user.id} type="secondary">
      {LocalizeService.translate('MEMBER_LIST.ROLE_LABEL')}:&nbsp;{this.getRoleLabel(member.role)}
    </Typography.Paragraph>
  );
}

// -- HOCs and exports
// ----------

export default MemberTableList;

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { ExecutionMethodEnum, IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { ICollectionData } from '@src/service/business/common/types';
import { AddressUtils } from '@src/service/util/location/AddressUtils';
import { Typography } from 'antd';

// -- Prop types
// ----------

export interface IExternalEducationInstanceListOwnProps {
  externalEducationInstanceList: ICollectionData<IExternalEducationInstance>;
  additionalCols?: () => Array<ITimunDataTableCol<IExternalEducationInstance>>;
  onPageChange?: (page: number, pageSize?: number) => void;
}
type IExternalEducationInstanceListProps = IExternalEducationInstanceListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** External education instance table */
const ExternalEducationInstanceList: React.FC<IExternalEducationInstanceListProps> = (props: IExternalEducationInstanceListProps) => {
  const renderColumns = () => {
    const columns: Array<ITimunDataTableCol<IExternalEducationInstance>> = [
      {
        key: 'address',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.ADDRESS_LABEL'),
        content: (record: IExternalEducationInstance) => {
          if (record.addresses.length === 0 && record.executionMethod.id === ExecutionMethodEnum.ONLINE) {
            return props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.ADDRESS_ONLINE_LABEL');
          } else {
            return record.addresses.map((address) => <span key={address.id}>  {AddressUtils.getFullAddress(address)} </span>);
          }
        },
      },
      {
        key: 'executionMethod',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.EXECUTION_METHOD_LABEL'),
        content: (record: IExternalEducationInstance) => props.translate(`EXECUTION_METHOD.SELECT_OPTION.${record.executionMethod.name}_LABEL`),
      },
    ];
    if (props.additionalCols) {
      columns.push(...props.additionalCols());
    }
    return columns;
  };

  return (
    <DataTable<IExternalEducationInstance>
      items={props.externalEducationInstanceList.content}
      paginationData={props.externalEducationInstanceList.page}
      paginationConfig={{ onChange: props.onPageChange }}
      columns={renderColumns()}
      scrollable={true}
      expandable={{
        rowExpandable: (record) => record.description != null,
        expandedRowRender: (record) => <React.Fragment>
          <Typography.Paragraph strong={true} className="text-uppercase">{props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.EXPANDED_ROW_TITLE')}</Typography.Paragraph>
          <div dangerouslySetInnerHTML={{ __html: record.description }} />
        </React.Fragment>,
      }}
    />
  );
};

export default withLocalize<IExternalEducationInstanceListOwnProps>(ExternalEducationInstanceList as any);

import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import IIdRef from '@src/model/common/IdRef';
import { IEducationGroup } from '@src/model/education/EducationGroup';
import { IEducationGroupItem } from '@src/model/education/EducationGroupItem';
import { IFile } from '@src/model/file/File';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface IEducationGroupFilter {}
interface IEducationGroupCreatePayload {
  title: string;
}

// -
// -------------------- Selectors

/** Returns EducationGroup from store. */
const getEducationGroup = (store: any): IEducationGroup => store.educationGroup;

const getEducationGroups = (store: any): ICollectionData<IEducationGroup> => store.educationGroupList;

// -
// -------------------- Actions

const Actions = {
  EDUCATION_GROUP_LIST_FETCH: 'EDUCATION_GROUP_LIST_FETCH',
  EDUCATION_GROUP_LIST_LOAD: 'EDUCATION_GROUP_LIST_LOAD',
  EDUCATION_GROUP_LIST_CLEAR: 'EDUCATION_GROUP_LIST_CLEAR',
  EDUCATION_GROUP_FETCH: 'EDUCATION_GROUP_FETCH',
  EDUCATION_GROUP_LOAD: 'EDUCATION_GROUP_LOAD',
  EDUCATION_GROUP_CLEAR: 'EDUCATION_GROUP_CLEAR',
  EDUCATION_GROUP_CREATE: 'EDUCATION_GROUP_CREATE',
  EDUCATION_GROUP_UPDATE: 'EDUCATION_GROUP_UPDATE',
  EDUCATION_GROUP_DELETE: 'EDUCATION_GROUP_DELETE',
  EDUCATION_GROUP_COVER_IMAGE_UPLOAD: 'EDUCATION_GROUP_COVER_IMAGE_UPLOAD',
  EDUCATION_GROUP_COVER_IMAGE_DELETE: 'EDUCATION_GROUP_COVER_IMAGE_DELETE',
  EDUCATION_GROUP_ITEMS_ADD: 'EDUCATION_GROUP_ITEMS_ADD',
  EDUCATION_GROUP_ITEM_DELETE: 'EDUCATION_GROUP_ITEM_DELETE',
};

/** Fetch coursegroup list by filter. */
const fetchEducationGroupList = (listFilter: IEducationGroupFilter, size: number, page: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<IEducationGroupFilter>> => {
  return {
    type: Actions.EDUCATION_GROUP_LIST_FETCH,
    payload: {
      filter: { listFilter },
      size,
      page,
      sort,
    },
  };
};

/** Load educationGroup to store. */
const loadEducationGroupList = (data: ICollectionData<IEducationGroup>): IPayloadAction<ICollectionData<IEducationGroup>> => {
  return {
    type: Actions.EDUCATION_GROUP_LIST_LOAD,
    payload: data,
  };
};

const clearEducationGroupList = (): ILemonAction => {
  return {
    type: Actions.EDUCATION_GROUP_LIST_CLEAR,
  };
};

/** Fetch EducationGroup by ID. */
const fetchEducationGroup = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EDUCATION_GROUP_FETCH,
    payload: {
      id,
    },
  };
};

/** Load EducationGroup to store. */
const loadEducationGroup = (data: IEducationGroup): IPayloadAction<IEducationGroup> => {
  return {
    type: Actions.EDUCATION_GROUP_LOAD,
    payload: data,
  };
};

/** Clear EducationGroup from store. Eg. when leaving view. */
const clearEducationGroupData = (): ILemonAction => {
  return {
    type: Actions.EDUCATION_GROUP_CLEAR,
  };
};

/** Create new EducationGroup. */
const createEducationGroup = (data: IEducationGroupCreatePayload): IPayloadAction<IEducationGroupCreatePayload> => {
  return {
    type: Actions.EDUCATION_GROUP_CREATE,
    payload: data,
  };
};

/** Update EducationGroup by ID. */
const updateEducationGroup = (data: IEducationGroup): IPayloadAction<IEducationGroup> => {
  return {
    type: Actions.EDUCATION_GROUP_UPDATE,
    payload: data,
  };
};

/** Delete coursegroup by ID */
const deleteEducationGroup = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.EDUCATION_GROUP_DELETE,
    payload: data,
  };
};

/** Add EducationGroup cover image. */
const uploadEducationGroupCoverImage = (id: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.EDUCATION_GROUP_COVER_IMAGE_UPLOAD,
    payload: {
      id,
      data,
    },
  };
};

/** Delete EducationGroup cover image. */
const deleteEducationGroupCoverImage = (data: IEducationGroupCreatePayload): IPayloadAction<IEducationGroupCreatePayload> => {
  return {
    type: Actions.EDUCATION_GROUP_COVER_IMAGE_DELETE,
    payload: data,
  };
};

/** Add EducationGroup items . */
const addEducationGroupItems = (data: IEducationGroupItem[], id: string): IPayloadAction<IIdDataPayload<IEducationGroupItem[]>> => {
  return {
    type: Actions.EDUCATION_GROUP_ITEMS_ADD,
    payload: {
      data,
      id,
    },
  };
};

/** Delete EducationGroup items . */
const deleteEducationGroupItem = (data: IEducationGroupItem): IPayloadAction<IEducationGroupItem> => {
  return {
    type: Actions.EDUCATION_GROUP_ITEM_DELETE,
    payload: data,
  };
};

// -
// -------------------- Side-effects
const fetchEducationGroupListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IEducationGroupFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_GROUP_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationGroup')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationGroupList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching education group list', error);
      return o;
    })
  );
};

const fetchEducationGroupEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_GROUP_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('EducationGroup')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationGroup(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_GROUP_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching EducationGroup', error);
      return o;
    })
  );
};

const createEducationGroupEffect = (action$: Observable<IPayloadAction<IEducationGroupCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_GROUP_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('EducationGroup')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationGroup(data);
    }),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating EducationGroup', error);
      return o;
    })
  );
};

const updateEducationGroupEffect = (action$: Observable<IPayloadAction<IEducationGroup>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_GROUP_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('EducationGroup')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadEducationGroup(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating EducationGroup', error);
      return o;
    })
  );
};

const deleteEducationGroupEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_GROUP_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('EducationGroup')
        .deleteEntity(data.id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting educationgroup', error);
      return o;
    })
  );
};

const addEducationGroupItemsEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IEducationGroupItem[]>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_GROUP_ITEMS_ADD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;
      return EntityApiServiceRegistry.getService('EducationGroup')
        .createSubobject(id, 'items', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error adding educationGroupItem', error);
      return o;
    })
  );
};

const uploadEducationGroupCoverImageEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EDUCATION_GROUP_COVER_IMAGE_UPLOAD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('EducationGroup')
        .createSubobject(id, 'cover', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EDUCATION_GROUP.ERROR', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error uploading cover image', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const educationGroupList = (state: ICollectionData<IEducationGroup> | null = null, action: IPayloadAction<ICollectionData<IEducationGroup>>) => {
  if (action.type === Actions.EDUCATION_GROUP_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EDUCATION_GROUP_LIST_CLEAR) {
    return null;
  }
  return state;
};

const educationGroup = (state: IEducationGroup | null = null, action: IPayloadAction<IEducationGroup>) => {
  if (action.type === Actions.EDUCATION_GROUP_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EDUCATION_GROUP_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const educationGroupBusinessStore = {
  actions: {
    fetchEducationGroupList,
    loadEducationGroupList,
    clearEducationGroupList,
    fetchEducationGroup,
    loadEducationGroup,
    clearEducationGroupData,
    createEducationGroup,
    updateEducationGroup,
    deleteEducationGroup,
    uploadEducationGroupCoverImage,
    deleteEducationGroupCoverImage,
    addEducationGroupItems,
    deleteEducationGroupItem,
  },

  selectors: {
    getEducationGroup,
    getEducationGroups,
  },

  effects: {
    fetchEducationGroupEffect,
    createEducationGroupEffect,
    updateEducationGroupEffect,
    deleteEducationGroupEffect,
    fetchEducationGroupListEffect,
    addEducationGroupItemsEffect,
    uploadEducationGroupCoverImageEffect,
  },

  reducers: {
    educationGroup,
    educationGroupList,
  },
};

// --
// export business store
export default educationGroupBusinessStore;

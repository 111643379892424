import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ICollectionResponse } from '@src/service/api/model/apiEvent';
import { InvalidEntityApiReturnValueException } from '@src/service/api/registry/entity/InvalidEntityApiReturnValueException';
import { IHttpRestClientOptions } from '@src/service/api/rest/IHttpRestClient';
import { ICollectionFetchPayload } from '@src/service/business/common/types';
import IAbstractEntityApi from '@src/service/util/api/IAbstractEntityApi';
import IApiService from '@src/service/util/api/IApiService';
import { LangUtils } from '@src/service/util/LangUtils';

export default class EntityApiService<E, C = any> implements IApiService {
  constructor(protected entityName: string, protected entityApi: IAbstractEntityApi<any, any>) {}

  // ----- Entity API

  fetchEntity<ME = E>(id: string, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.fetchEntity(this.entityName, id, options).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          // tslint:disable-next-line: no-duplicate-string
          throw new InvalidEntityApiReturnValueException('fetchEntity', 'missing payload');
        }

        if (LangUtils.isEmpty(data.payload.id)) {
          // tslint:disable-next-line: no-duplicate-string
          throw new InvalidEntityApiReturnValueException('fetchEntity', 'missing ID');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // check errors
      catchError(this.handleApiErrors)
    );
  }

  createEntity<ME = E>(body: any, options?: IHttpRestClientOptions): Observable<ME> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.createEntity(this.entityName, convertedBody, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createEntity', 'missing payload');
        }

        if (LangUtils.isEmpty(data.payload.id)) {
          throw new InvalidEntityApiReturnValueException('createEntity', 'missing ID');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // check errors
      catchError(this.handleApiErrors)
    );
  }

  updateEntity<ME = E>(id: string, body: any, options?: IHttpRestClientOptions): Observable<ME> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.updateEntity(this.entityName, id, convertedBody, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('updateEntity', 'missing payload');
        }

        if (LangUtils.isEmpty(data.payload.id)) {
          throw new InvalidEntityApiReturnValueException('updateEntity', 'missing ID');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  deleteEntity<ME = E>(id: string, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.deleteEntity(this.entityName, id, options).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('deleteEntity', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  updateEntityMethod<ME = E>(id: string, method: string, body: any, options?: IHttpRestClientOptions): Observable<ME> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.updateEntityMethod(this.entityName, id, method, convertedBody, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('updateEntityMethod', 'missing payload');
        }

        if (LangUtils.isEmpty(data.payload.id)) {
          throw new InvalidEntityApiReturnValueException('updateEntityMethod', 'missing ID');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ----- Entity list API

  fetchEntityList<ME = E>(params?: any, options?: IHttpRestClientOptions): Observable<ICollectionResponse<ME, C>> {
    const apiParams = this.convertEntiyListParamsFromModel(params);

    return this.entityApi.fetchEntityList(this.entityName, apiParams, options).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchEntityList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload.content)) {
          // tslint:disable-next-line: no-duplicate-string
          throw new InvalidEntityApiReturnValueException('fetchEntityList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityListToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  createEntityList(body: any[], options?: IHttpRestClientOptions): Observable<object[]> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityListFromModel(this.entityName, body);

    return this.entityApi.createEntityList(this.entityName, convertedBody, options).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createEntityList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createEntityList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertReturnListToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  deleteEntityList<ME = E>(body: object[], options?: IHttpRestClientOptions): Observable<ICollectionResponse<ME, C>> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.deleteEntityList(this.entityName, convertedBody, options).pipe(
      // map result back to model
      map((data) => {
        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ----- Subentity list API

  fetchSubentityList<ME = E>(id: string, subentityName: string, params?: any, options?: IHttpRestClientOptions): Observable<ICollectionResponse<ME, C>> {
    const apiParams = this.convertEntiyListParamsFromModel(params);

    return this.entityApi.fetchSubentityList(this.entityName, id, subentityName, apiParams, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchSubentityList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload.content)) {
          throw new InvalidEntityApiReturnValueException('fetchSubentityList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityListToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  createSubentityList(id: string, subentityName: string, body: any, options?: IHttpRestClientOptions): Observable<object[]> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityListFromModel(this.entityName, body);

    return this.entityApi.createSubentityList(this.entityName, id, subentityName, convertedBody, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createSubentityList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createSubentityList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertReturnListToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  updateSubentityList(id: string, subentityName: string, body: any, options?: IHttpRestClientOptions): Observable<void> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.updateSubentityList(this.entityName, id, subentityName, convertedBody, options).pipe(
      // map result back to model
      map((data) => {
        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  deleteSubentityList(id: string, subentityName: string, body: any, options?: IHttpRestClientOptions): Observable<void> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.deleteSubentityList(this.entityName, id, subentityName, convertedBody, options).pipe(
      // map result back to model
      map((data) => {
        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ---------- Object API

  fetchObjectList<ME = E>(method: string, params?: any, options?: IHttpRestClientOptions): Observable<ICollectionResponse<ME, C>> {
    const apiParams = this.convertEntiyListParamsFromModel(params);

    return this.entityApi.fetchMethod(this.entityName, method, apiParams, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchObjectList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload.content)) {
          throw new InvalidEntityApiReturnValueException('fetchObjectList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityListToModel(this.entityName, payload);
      }),

      // check errors
      catchError(this.handleApiErrors)
    );
  }

  // ---------- Subobject API

  fetchSubobject<ME = E>(id: string, subobject: string, params?: any, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.fetchSubobject(this.entityName, id, subobject, params, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchSubobject', 'missing payload');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  createSubobject<ME = E>(id: string, subobject: string, body: any, options?: IHttpRestClientOptions): Observable<ME> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.createSubobject(this.entityName, id, subobject, convertedBody, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createSubobject', 'missing payload');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  updateSubobject<ME = E>(id: string, subobject: string, body: any, options?: IHttpRestClientOptions): Observable<ME> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.updateSubobject(this.entityName, id, subobject, convertedBody, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('updateSubobject', 'missing payload');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  deleteSubobject(id: string, subobject: string, body: any, options?: IHttpRestClientOptions): Observable<void> {
    return this.entityApi.deleteSubobject(this.entityName, id, subobject, body, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('deleteSubobject', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ---------- Custom entity API

  fetchMethod<ME = E>(method: string, queryParams?: object, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.fetchMethod(this.entityName, method, queryParams, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchMethod', 'missing payload');
        }

        return data.payload;
      }),

      // check errors
      catchError(this.handleApiErrors)
    );
  }

  fetchNoMethod<ME = E>(queryParams?: object, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.fetchNoMethod(this.entityName, queryParams, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchNoMethod', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  createMethod<ME = E>(method: string, body?: object, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.createMethod(this.entityName, method, body, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createMethod', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  updateMethod<ME = E>(method: string, body: object, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.updateMethod(this.entityName, method, body, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('updateMethod', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  deleteMethod<ME = E>(method: string, body: object, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.deleteMethod(this.entityName, method, body, options).pipe(
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('deleteMethod', 'missing payload');
        }
        return data.payload;
      }),

      catchError(this.handleApiErrors)
    );
  }

  deleteNoMethod<ME = E>(body?: object, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.deleteNoMethod(this.entityName, body, options).pipe(
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('deleteNoMethod', 'missing payload');
        }
        return data.payload;
      }),

      catchError(this.handleApiErrors)
    );
  }

  createSubmethod<ME = E>(id: string, subobject: string, body?: object, options?: IHttpRestClientOptions): Observable<ME> {
    return this.entityApi.createSubmethod(this.entityName, id, subobject, body, options).pipe(
      // check return value
      // tslint:disable-next-line: no-identical-functions
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createSubobject', 'missing payload');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ---------- protected

  // TODO: implement entity converters and use conversion methods

  protected convertEntityFromModel(entityName: string, entity: any): any {
    // * return EntityConverterUtils.convertFromModel(entity, entityName, this.getEntityConverters(entityName));
    return entity;
  }

  protected convertEntityToModel(entityName: string, obj: any): any {
    // * return EntityConverterUtils.convertToModel(obj, entityName, this.getEntityConverters(entityName));
    return obj;
  }

  protected convertEntityListFromModel(entityName: string, entityArr: any[]): any[] {
    /* * return this.ensureArray(entityArr)
        map((entity) => EntityConverterUtils.convertFromModel(entity, entityName, this.getEntityConverters(entityName)));*/
    return entityArr;
  }

  protected convertEntityListToModel(entityName: string, collection: ICollectionResponse<any, any>): ICollectionResponse<any, any> {
    /* * return this.ensureArray(objArr)
      map((obj) => EntityConverterUtils.convertToModel(obj, entityName, this.getEntityConverters(entityName)));*/

    /*
     * Split BE's response into content and page (rest of reponse).
     * Backend's response mixes paging and sorting data on the same level as content, so we will divide it for easier separation.
     */
    // TODO: this should be implemente pluggable, like converters (also a TODO :-))
    const { content, ...page } = collection;

    return { content, page };
  }

  protected convertReturnListToModel(entityName: string, collection: object[]): object[] {
    /* * return this.ensureArray(objArr)
      map((obj) => EntityConverterUtils.convertToModel(obj, entityName, this.getEntityConverters(entityName)));*/

    /*
     * Split BE's response into content and page (rest of reponse).
     * Backend's response mixes paging and sorting data on the same level as content, so we will divide it for easier separation.
     */
    // TODO: this should be implemente pluggable, like converters (also a TODO :-))

    return collection;
  }

  protected getEntityConverters(entityName: string): /*AbstractEntityModelConverter<any, any, any>*/ any[] {
    // * return this.entityConverterRegistry.getEntityConverters(entityName);
    return [];
  }

  protected ensureArray(arr: any[]): any[] {
    return arr == null ? [] : arr;
  }

  /** Backend API has flat parameter list so we have to flatten filter object together with other params. */
  // TODO: this should be implemented pluggable, like converters (also a TODO :-))
  protected convertEntiyListParamsFromModel(params?: ICollectionFetchPayload<any>): Record<string, any> {
    // avoid null references in case params are missing
    if (params == null) {
      return {};
    }

    const { filter, ...rest } = params;

    return {
      ...(filter || {}),
      ...(rest || {}),
    };
  }

  /**
   * Check API repsonse errors and react if required. This is hooked into stream via "catchError" operator
   * and simply rethrows error
   * Currently handles:
   *  - SESSION_EXPIRED - any API call can receive this reponse and should redirect user to login page
   *
   * This is meant to be a centralized place for handlign global API response errors. But this should
   * be configurable from outside, as a list of error handlers.
   */
  private handleApiErrors(error: any, o: Observable<any>): Observable<any> {
    // tslint:disable-next-line: no-ignored-return
    API_ERROR_HANDLERS.reduce((result, handler) => {
      return result ? handler(error) : false;
    }, true);

    return throwError(error);
  }
}

// ---------- API service error handling

/**
 * Describes API service error handler function.
 *
 * @param error {any} - error object
 * @returns {boolean} true if error processing should continue
 */
export type ApiServiceErrorHandler = (error: any) => boolean;

/** List that stores registered API service error handler functions */
const API_ERROR_HANDLERS: ApiServiceErrorHandler[] = [];

/**
 * Registration function for API service error handlers.
 *
 * @param {ApiServiceErrorHandler} handler function
 * @returns {Function} unregistration function - function that removes handler from list of used handlers
 */
export function registerAPiServiceErrorHandler(handler: ApiServiceErrorHandler) {
  API_ERROR_HANDLERS.push(handler);
  const currentIndex = API_ERROR_HANDLERS.length - 1;

  return () => {
    API_ERROR_HANDLERS.splice(currentIndex, 1);
  };
}

import IIdRef from '@lamarodigital/quizzler-lib-frontend/model/common/IdRef';
import { CourseCompletionStatusEnum } from '@src/model/course/Course';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { ICourseUserGroup } from '@src/model/course/CourseUserGroup';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { IExamTemplateStatistics } from '@src/model/education/ExamTemplateStatistics';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { BussinessStoreHelperUtils } from '@src/service/business/common/BussinessStoreHelperUtils';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, IKeyDataPayload, IKeyPayload, ILemonAction, IPayloadAction, IStoreListData, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { transformCourseListFilter } from '@src/service/business/courses/util';
import { IExamInstanceListFilter } from '@src/service/business/examtemplates/examInstanceBusinessStore';
import { transformExamInstanceListFilter } from '@src/service/business/examtemplates/examInstanceBussinesStoreUtil';
import AppConfigService from '@src/service/common/AppConfigService';
import { RequiredBy } from '@src/service/util/lang/type';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { actionThunk, startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { mapResponseDataByKeyOperator } from '@src/service/util/observable/operators/mapResponseDataByKeyOperator';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
// -
// -------------------- Types&Consts

export interface ICourseListFilter {
  status?: CourseStatusEnum;
  completionStatus?: CourseCompletionStatusEnum;
  assignee?: string;
  withTraineesStats?: boolean;
  tags?: string[];
  createdBy?: string;
  courseGroups?: string[];
  traineeStatsFrom?: string;
  traineeStatsTo?: string;
  title?: string;
  userGroups?: RequiredBy<Partial<IUserGroup>, 'id'>;
}

export interface ICourseUserGroupPayload {
  userGroup: IIdRef<string>;
  workPositions?: IIdRef<string>[];
}

// List filter ID
const COURSE_LIST_FILTER = '@@COURSE_LIST_FILTER';

/**
 * Force collection size course list.
 *
 * Courses are always displayed in groups thus paging will not work.
 * Additionally, there will probably not be that many courses to require paging.
 */
const COURSE_LIST_DEFAULT_PAGE_SIZE = AppConfigService.getValue('api.collectionDefaultLimit');

/** trainee default course list filter base */
const COURSE_LIST_PAYLOAD_BASE: Pick<ICollectionFetchPayload<ICourseListFilter>, Exclude<keyof ICollectionFetchPayload<ICourseListFilter>, 'filter'>> = {
  sort: [],
  // force first page since courses are fetched with collection size - see explanation on COURSE_LIST_DEFAULT_PAGE_SIZE
  page: 0,
  // force default size
  size: COURSE_LIST_DEFAULT_PAGE_SIZE,
};

// -
// -------------------- Selectors

/** Returns list of courses from store. */
const getCourseList = (store: any, listKey?: string): ICollectionData<ICourse> => BussinessStoreHelperUtils.getStoreValue(store.courseList, listKey);

/** Returns last viewed course from store. */
const getLastCourse = (store: any): ICourse => store.latestCourse;

/** Returns course list filter. */
const getCourseListFilter = (store: any): ICourseListFilter => ListFilterBusinessStore.selectors.getListFilter(store, COURSE_LIST_FILTER);

/**
 * Returns list of all courses from store.
 * same as fetchAdminCourseLists, but separated for cases when both admin and trainee courses needed at the same time
 */
const getAllCourseList = (store: any): ICollectionData<ICourse> => store.allCourseList;

/** Returns user group workpositions for course from store. */
const getCourseUserGroupWorkPositionList = (store: any): ICollectionData<ICourseUserGroup> => store.courseUserGroupWorkPositionList;

/** Returns course exam instance list from store. */
const getCourseExamInstanceList = (store: any): ICollectionData<IExamInstance> => store.courseExamInstanceList;

/** Returns course exam instance list from store. */
const getCourseExamTemplateStatistics = (store: any): IExamTemplateStatistics => store.courseExamTemplateStatistics;

// -
// -------------------- Actions

const Actions = {
  COURSE_LIST_FETCH: 'COURSE_LIST_FETCH',
  COURSE_LIST_LOAD: 'COURSE_LIST_LOAD',
  COURSE_LIST_CLEAR: 'COURSE_LIST_CLEAR',
  COURSE_LAST_FETCH: 'COURSE_LAST_FETCH',
  COURSE_LAST_CLEAR: 'COURSE_LAST_CLEAR',
  COURSE_LAST_LOAD: 'COURSE_LAST_LOAD',
  COURSE_LIST_ALL_FETCH: 'COURSE_LIST_ALL_FETCH',
  COURSE_LIST_ALL_LOAD: 'COURSE_LIST_ALL_LOAD',
  COURSE_LIST_ALL_CLEAR: 'COURSE_LIST_ALL_CLEAR',
  COURSE_USER_GROUP_WORKPOSITION_LIST_FETCH: 'COURSE_USER_GROUP_WORKPOSITION_LIST_FETCH',
  COURSE_USER_GROUP_WORKPOSITION_LIST_LOAD: 'COURSE_USER_GROUP_WORKPOSITION_LIST_LOAD',
  COURSE_USER_GROUP_WORKPOSITION_LIST_UPDATE: 'COURSE_USER_GROUP_WORKPOSITION_LIST_UPDATE',
  COURSE_USER_GROUP_WORKPOSITION_LIST_CLEAR: 'COURSE_USER_GROUP_WORKPOSITION_LIST_CLEAR',
  COURSE_EXAM_INSTANCE_LIST_FETCH: 'COURSE_EXAM_INSTANCE_LIST_FETCH',
  COURSE_EXAM_INSTANCE_LIST_LOAD: 'COURSE_EXAM_INSTANCE_LIST_LOAD',
  COURSE_EXAM_INSTANCE_LIST_CLEAR: 'COURSE_EXAM_INSTANCE_LIST_CLEAR',
  COURSE_EXAM_TEMPLATE_STATISTICS_FETCH: 'COURSE_EXAM_TEMPLATE_STATISTICS_FETCH',
  COURSE_EXAM_TEMPLATE_STATISTICS_LOAD: 'COURSE_EXAM_TEMPLATE_STATISTICS_LOAD',
  COURSE_EXAM_TEMPLATE_STATISTICS_CLEAR: 'COURSE_EXAM_TEMPLATE_STATISTICS_CLEAR',
};

/** Fetch admin course list. Forces default COURSE_LIST_PAYLOAD_BASE except sort */
const fetchAdminCourseList = (params: ICollectionFetchPayload<ICourseListFilter>, storeKey?: string): IPayloadAction<IKeyDataPayload<ICollectionFetchPayload<ICourseListFilter>>> => {
  return fetchCourseList(
    {
      filter: params.filter,
      sort: params.sort ?? COURSE_LIST_PAYLOAD_BASE.sort,
      page: params.page ?? COURSE_LIST_PAYLOAD_BASE.page,
      size: params.size ?? COURSE_LIST_PAYLOAD_BASE.size,
    },
    storeKey
  );
};

/** Fetch trainee course list. */
const fetchTraineeCourseList = (userId: string, listFilter: ICourseListFilter, page: number, size: number, sort: string[], key?: string): IPayloadAction<IKeyDataPayload<ICollectionFetchPayload<ICourseListFilter>>> => {
  return fetchCourseList(
    {
      filter: {
        ...listFilter,
        assignee: userId,
      },
      page,
      size,
      sort,
    },
    key
  );
};

/** Fetch trainee latest viewed course */
const fetchLastCourse = (): ILemonAction => {
  return {
    type: Actions.COURSE_LAST_FETCH,
  };
};

/** Clear trainee last viewed course */
const clearLastCourse = (): ILemonAction => {
  return {
    type: Actions.COURSE_LAST_CLEAR,
  };
};

/** Load trainee last viewed course */
const loadLastCourse = (course: ICourse): IPayloadAction<ICourse> => {
  return {
    type: Actions.COURSE_LAST_LOAD,
    payload: course,
  };
};

/** Fetch course list by filter. */
const fetchCourseList = (params: ICollectionFetchPayload<ICourseListFilter>, storeKey?: string): IPayloadAction<IKeyDataPayload<ICollectionFetchPayload<ICourseListFilter>>> => {
  return {
    type: Actions.COURSE_LIST_FETCH,
    payload: {
      key: storeKey,
      data: params,
    },
  };
};

/** Load course list to store. */
const loadCourseList = (data: ICollectionData<ICourse>, storeKey?: string): IPayloadAction<IKeyDataPayload<ICollectionData<ICourse>>> => {
  return {
    type: Actions.COURSE_LIST_LOAD,
    payload: {
      data,
      key: storeKey,
    },
  };
};

/** Clear course list from store. Eg. when leaving list view. */
const clearCourseList = (storeKey?: string): IPayloadAction<IKeyPayload> => {
  return {
    type: Actions.COURSE_LIST_CLEAR,
    payload: {
      key: storeKey,
    },
  };
};

/** Store course list filter to store. */
const storeCourseListFilter = (listFilter: ICourseListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(COURSE_LIST_FILTER, listFilter);
};

const clearCourseListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(COURSE_LIST_FILTER);
};

/** Fetch list of all courses by filter. */
const fetchAllCourseList = (allListFilter: ICourseListFilter): IPayloadAction<ICollectionFetchPayload<ICourseListFilter>> => {
  return {
    type: Actions.COURSE_LIST_ALL_FETCH,
    payload: {
      filter: {
        ...allListFilter,
      },
      ...COURSE_LIST_PAYLOAD_BASE,
    },
  };
};

/** Load list of all courses to store. */
const loadAllCourseList = (data: ICollectionData<ICourse>): IPayloadAction<ICollectionData<ICourse>> => {
  return {
    type: Actions.COURSE_LIST_ALL_LOAD,
    payload: data,
  };
};

/** Clear list of all courses from store. Eg. when leaving list view. */
const clearAllCourseList = (): ILemonAction => {
  return {
    type: Actions.COURSE_LIST_ALL_CLEAR,
  };
};

const fetchCourseUserGroupWorkPositionList = (data: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.COURSE_USER_GROUP_WORKPOSITION_LIST_FETCH,
    payload: data,
  };
};

const loadCourseUserGroupWorkPositionList = (data: ICollectionData<ICourseUserGroup>): IPayloadAction<ICollectionData<ICourseUserGroup>> => {
  return {
    type: Actions.COURSE_USER_GROUP_WORKPOSITION_LIST_LOAD,
    payload: data,
  };
};

const updateCourseUserGroupWorkPositionList = (data: IIdDataPayload<ICourseUserGroupPayload[]>): IPayloadAction<IIdDataPayload<ICourseUserGroupPayload[]>> => {
  return {
    type: Actions.COURSE_USER_GROUP_WORKPOSITION_LIST_UPDATE,
    payload: data,
  };
};

const clearCourseUserGroupWorkPositionList = (): ILemonAction => {
  return {
    type: Actions.COURSE_USER_GROUP_WORKPOSITION_LIST_CLEAR,
  };
};

const fetchCourseExamInstanceList = (params: ICollectionFetchPayload<IExamInstanceListFilter>, id: string): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IExamInstanceListFilter>>> => {
  return {
    type: Actions.COURSE_EXAM_INSTANCE_LIST_FETCH,
    payload: {
      data: params,
      id,
    },
  };
};
const loadCourseExamInstanceList = (data: ICollectionData<IExamInstance>): IPayloadAction<ICollectionData<IExamInstance>> => {
  return {
    type: Actions.COURSE_EXAM_INSTANCE_LIST_LOAD,
    payload: data,
  };
};
const clearCourseExamInstanceList = (): ILemonAction => {
  return {
    type: Actions.COURSE_EXAM_INSTANCE_LIST_CLEAR,
  };
};

const fetchCourseExamTemplateStatistics = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.COURSE_EXAM_TEMPLATE_STATISTICS_FETCH,
    payload: {
      id,
    },
  };
};

const loadCourseExamTemplateStatistics = (data: IExamTemplateStatistics): IPayloadAction<IExamTemplateStatistics> => {
  return {
    type: Actions.COURSE_EXAM_TEMPLATE_STATISTICS_LOAD,
    payload: data,
  };
};

const clearCourseExamTemplateStatistics = (): ILemonAction => {
  return {
    type: Actions.COURSE_EXAM_TEMPLATE_STATISTICS_CLEAR,
  };
};
// -
// -------------------- Side-effects
const fetchLastCourseEffect = (action$: Observable<ILemonAction>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_LAST_FETCH;
    }),

    // fetch user latest course
    mergeMap((action) => {
      return EntityApiServiceRegistry.getService('Course')
        .fetchMethod('latest')
        .pipe(actionThunk(action));
    }),

    // load user latest course
    map((course) => {
      return loadLastCourse(course);
    }),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching last viewed course', error);

      return o;
    })
  );
};

const fetchCourseListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<ICourseListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const params = {
        ...action.payload.data,
        filter: action.payload.data.filter && transformCourseListFilter(action.payload.data.filter),
      };

      return EntityApiServiceRegistry.getService('Course')
        .fetchEntityList(params)
        .pipe(mapResponseDataByKeyOperator(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseList(data.response, data.key);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course list', error);
      return o;
    })
  );
};

const fetchAllCourseListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ICourseListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_LIST_ALL_FETCH;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadAllCourseList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching list of all courses', error);
      return o;
    })
  );
};

const fetchCourseUserGroupWorkPositionListEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_USER_GROUP_WORKPOSITION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('Course')
        .fetchSubentityList(id, 'userGroupWorkPositionRelation')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseUserGroupWorkPositionList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course user group workposition list', error);
      return o;
    })
  );
};

const updateCourseUserGroupWorkPositionListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICourseUserGroupPayload[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_USER_GROUP_WORKPOSITION_LIST_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .updateSubentityList(id, 'userGroupWorkPositionRelation', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => ({ message: LocalizeService.translate('COMMON.EDITED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS })),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating course user group  workposition list', error);
      return o;
    })
  );
};

const fetchCourseExamInstanceListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IExamInstanceListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_EXAM_INSTANCE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      const payload = {
        // first copy entire payload
        ...data,
        // then override filter with transformed value
        filter: data.filter ? transformExamInstanceListFilter(data.filter) : undefined,
      };

      return EntityApiServiceRegistry.getService('Course')
        .fetchSubentityList(id, 'examInstance', payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseExamInstanceList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course exam instance list', error);
      return o;
    })
  );
};

const fetchCourseExamTemplateStatisticsEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_EXAM_TEMPLATE_STATISTICS_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .fetchSubobject(id, 'examstatistics')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseExamTemplateStatistics(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course exam statistics', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const courseList = (state: IStoreListData<ICollectionData<ICourse>> = {}, action: IPayloadAction<IKeyDataPayload<ICollectionData<ICourse>>>) => {
  const courseListKey = BussinessStoreHelperUtils.getStoreKey(action.payload?.key);
  if (action.type === Actions.COURSE_LIST_LOAD) {
    const { data } = action.payload;
    return {
      ...state,
      [courseListKey]: data,
    };
  } else if (action.type === Actions.COURSE_LIST_CLEAR) {
    const { [courseListKey]: removedCourses, ...newState } = state;
    return newState;
  }

  return state;
};

const allCourseList = (state: ICollectionData<ICourse> | null = null, action: IPayloadAction<ICollectionData<ICourse>>) => {
  if (action.type === Actions.COURSE_LIST_ALL_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.COURSE_LIST_ALL_CLEAR) {
    return null;
  }

  return state;
};

/** Dummy login check. Untila proper user is resolved. */
const latestCourse = (state: any = null, action: IPayloadAction<ICourse>) => {
  if (action.type === Actions.COURSE_LAST_LOAD) {
    return action.payload;
  } else if (action.type === Actions.COURSE_LAST_CLEAR) {
    return null;
  }

  return state;
};

const courseUserGroupWorkPositionList = (state: ICollectionData<ICourseUserGroup> | null = null, action: IPayloadAction<ICollectionData<ICourseUserGroup>>) => {
  if (action.type === Actions.COURSE_USER_GROUP_WORKPOSITION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.COURSE_USER_GROUP_WORKPOSITION_LIST_CLEAR) {
    return null;
  }
  return state;
};

const courseExamInstanceList = (state: ICollectionData<IExamInstance> | null = null, action: IPayloadAction<ICollectionData<IExamInstance>>) => {
  if (action.type === Actions.COURSE_EXAM_INSTANCE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.COURSE_EXAM_INSTANCE_LIST_CLEAR) {
    return null;
  }
  return state;
};

const courseExamTemplateStatistics = (state: ICollectionData<IExamTemplateStatistics> | null = null, action: IPayloadAction<ICollectionData<IExamTemplateStatistics>>) => {
  if (action.type === Actions.COURSE_EXAM_TEMPLATE_STATISTICS_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.COURSE_EXAM_TEMPLATE_STATISTICS_CLEAR) {
    return null;
  }
  return state;
};
// --
// -------------------- Business Store

export const CourseListBusinessStore = {
  actions: {
    fetchLastCourse,
    clearLastCourse,
    fetchCourseList,
    fetchCourseExamInstanceList,
    loadCourseExamInstanceList,
    clearCourseExamInstanceList,
    fetchCourseExamTemplateStatistics,
    loadCourseExamTemplateStatistics,
    clearCourseExamTemplateStatistics,
    fetchAdminCourseList,
    fetchTraineeCourseList,
    loadCourseList,
    clearCourseList,
    storeCourseListFilter,
    clearCourseListFilter,
    fetchAllCourseList,
    loadAllCourseList,
    clearAllCourseList,
    fetchCourseUserGroupWorkPositionList,
    loadCourseUserGroupWorkPositionList,
    updateCourseUserGroupWorkPositionList,
    clearCourseUserGroupWorkPositionList,
  },

  selectors: {
    getLastCourse,
    getCourseList,
    getCourseListFilter,
    getAllCourseList,
    getCourseUserGroupWorkPositionList,
    getCourseExamInstanceList,
    getCourseExamTemplateStatistics,
  },

  effects: {
    fetchLastCourseEffect,
    fetchCourseListEffect,
    fetchAllCourseListEffect,
    fetchCourseUserGroupWorkPositionListEffect,
    updateCourseUserGroupWorkPositionListEffect,
    fetchCourseExamInstanceListEffect,
    fetchCourseExamTemplateStatisticsEffect,
  },

  reducers: {
    courseList,
    allCourseList,
    latestCourse,
    courseUserGroupWorkPositionList,
    courseExamInstanceList,
    courseExamTemplateStatistics,
  },
};

// --
// export business store
export default CourseListBusinessStore;

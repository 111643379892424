import { Observable } from 'rxjs';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { IProgressTracker, ProgressTrackerTypeEnum } from '@src/model/progresstracker/ProgressTracker';

// -
// -------------------- Types&Consts
export interface IProgressTrackerListFilter {
  type?: ProgressTrackerTypeEnum;
}

// -
// -------------------- Selectors

/** Returns ProgressTracker list from store. */
const getProgressTrackerList = (store: any): ICollectionData<IProgressTracker> => store.progressTrackerList;

// -
// -------------------- Actions
const Actions = {
  PROGRESS_TRACKER_LIST_FETCH: 'PROGRESS_TRACKER_LIST_FETCH',
  PROGRESS_TRACKER_LIST_LOAD: 'PROGRESS_TRACKER_LIST_LOAD',
  PROGRESS_TRACKER_LIST_CLEAR: 'PROGRESS_TRACKER_LIST_CLEAR',
};

/** Featch ProgressTracker by type */
const fetchProgressTrackerList = (params: ICollectionFetchPayload<IProgressTrackerListFilter>): IPayloadAction<ICollectionFetchPayload<IProgressTrackerListFilter>> => {
  return {
    type: Actions.PROGRESS_TRACKER_LIST_FETCH,
    payload: params,
  };
};

/** Load ProgressTracker to the store */
const loadProgressTrackerList = (data: ICollectionData<IProgressTracker>): IPayloadAction<ICollectionData<IProgressTracker>> => {
  return {
    type: Actions.PROGRESS_TRACKER_LIST_LOAD,
    payload: data,
  };
};
/** Clear ProgressTracker list from store. Eg. when leaving view. */
const clearProgressTrackerList = (): ILemonAction => {
  return {
    type: Actions.PROGRESS_TRACKER_LIST_CLEAR,
  };
};

// -
// -------------------- Side-effects
const fetchProgressTrackerListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IProgressTrackerListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PROGRESS_TRACKER_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;
      return EntityApiServiceRegistry.getService('ProgressTracker')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadProgressTrackerList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'PROGRESS_TRACKER.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching progress tracker list', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers
const progressTrackerList = (state: ICollectionData<IProgressTracker> | null = null, action: IPayloadAction<ICollectionData<IProgressTracker>>) => {
  if (action.type === Actions.PROGRESS_TRACKER_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.PROGRESS_TRACKER_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store
export const ProgressTrackerListBuisnessStore = {
  actions: {
    fetchProgressTrackerList,
    loadProgressTrackerList,
    clearProgressTrackerList,
  },

  selectors: {
    getProgressTrackerList,
  },

  effects: {
    fetchProgressTrackerListEffect,
  },

  reducer: {
    progressTrackerList,
  },
};

// --
// ----- Exports
export default ProgressTrackerListBuisnessStore;

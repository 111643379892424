import React from 'react';

import DataPickerHelperUtils from '@src/components/common/datapicker/utils/DataPickerHelperUtils';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { classNames } from '@src/components/common/util/classNames';
import IIdRef from '@src/model/common/IdRef';
import { ICollectionDataCount } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Empty, Table, TablePaginationConfig, Tag } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

// -- Const
// ----------
const TABLE_SCROLL_SIZE = AppConfigService.getValue('layout.table.scrollSize');
// -- Prop types
// ----------

export interface ITableDataPickerOwnProps<T> {
  items: T[];
  value?: T;
  className?: string;
  paginationData: ICollectionDataCount;
  paginationConfig?: Exclude<TablePaginationConfig, 'current' | 'total' | 'pageSize'>;
  columns: Array<ITimunDataTableCol<T>>;
  scrollable?: boolean;
  emptyText?: string;
  disabled?: boolean;
  onChange?: (value?: T) => void;
}
type ITableDataPickerProps<T> = ITableDataPickerOwnProps<T>;

// -- Component
// ----------

/** Describe your component ... */
const TableDataPicker = <T extends IIdRef<string>>(props: ITableDataPickerProps<T>) => {
  const currentPageNumber = props.paginationData.pageable.pageNumber + 1;
  const isItemSelected = props.items.some((item) => item.id === props.value?.id);

  const getClassName = () => {
    return classNames({
      [props.className || '']: true,
    });
  };

  const getDataSoruce = () => {
    if (props.value) {
      // aka selectedItem
      return [props.value];
    } else {
      return props.items;
    }
  };

  const getColumns = () => {
    const columns = DataPickerHelperUtils.setColumns(props.columns);
    if (!props.disabled) {
      columns.push({
        key: 'selectAction',
        title: <Button disabled={!isItemSelected} onClick={() => props.onChange?.()}> {LocalizeService.translate('COMMON.ACTION_DISCARD')}  </Button>,
        width: '5%',
        render: (record: T) => isItemSelected ? <Tag color="green"> {LocalizeService.translate('COMMON.SELECTED_LABEL')} </Tag> : <Button onClick={() => props.onChange?.(record)}>{LocalizeService.translate('COMMON.ACTION_SELECT')}</Button>,
      });
    }
    return columns;
  };

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<T> | Array<SorterResult<T>>) => {
    if (pagination.current && pagination.pageSize && props.paginationConfig?.onChange) {
      props.paginationConfig?.onChange(pagination.current, pagination.pageSize);
    }
    // TODO: set table sortimg string | string[]
    /* if (sorter?.columnKey && typeof sorter.columnKey === 'string' && sorter?.order && this.props.onSortChange) {
      this.props.onSortChange(sorter.columnKey, sorter.order);
    } */
  };

  return (
    <Table
      className={getClassName()}
      columns={getColumns()}
      dataSource={getDataSoruce()}
      rowKey={(record) => record.id}
      onChange={handleTableChange}
      scroll={props.scrollable ? { x: TABLE_SCROLL_SIZE } : undefined}
      locale={{
        emptyText: <Empty description={props.emptyText ?? LocalizeService.translate('COMMON.MESSAGE_NO_DATA')} />,
      }}
      pagination={{
        position: ['topRight', 'bottomRight'],
        hideOnSinglePage: true,
        showSizeChanger: false,
        total: props.paginationData.totalElements,
        pageSize: props.paginationData.size,
        current: currentPageNumber,
        responsive: true,
        ...props.paginationConfig,
      }} />
  );

};


// -- HOCs and exports
// ----------

export default TableDataPicker;

import { CookieManager } from '@src/service/util/CookieManager';
import { Globals } from '@src/service/util/Globals';

/** Class contains utility methods for handling domain name. */
export class DomainUtils {
  /**
   * Prepend new subdomain to given absolute URI.
   * If target subdomain is give, then, if it is found in uri, it will be ommited from final URI.
   * Effectively, target subdomain will be replaced with new subdomain.
   *
   * Examples
   *      target.example.com -> new.example.com
   *      foo.example.com -> new.foo.example.com
   */
  static prependSubdomain(uri: string, newSubdomain: string, targetSubdomain?: string | string[]): string {
    const matches = DomainUtils.URI_PATTERN.exec(uri);
    if (matches) {
      const protocolWithColon = matches[1] || '';
      const subdomain = matches[2] || '';
      const remainingUri = matches[3] || '';

      targetSubdomain = targetSubdomain != null ? targetSubdomain : [];
      // TODO: ported from original source, but not used? remove if unnecessary
      // const targetSubdomainList = LangUtils.isString(targetSubdomain) ? [targetSubdomain] : targetSubdomain;

      // append new subdomain and remove only target subdomain
      return `${protocolWithColon}//${newSubdomain}${targetSubdomain == null || targetSubdomain.indexOf(subdomain) === -1 ? '.' + subdomain : ''}${remainingUri}`;
    }

    // return original uri
    return uri;
  }

  /** Returns domain of current browser document. */
  static getCurrentDomain(): string {
    return Globals.window.location.hostname;
  }

  /** Returns domain of current browser document with protocol and port. */
  static getFullCurrentDomain(): string {
    return Globals.window.location.origin;
  }

  /**
   * Extract pure domain without subdomains.
   *
   * It breaks full domain in parts and concats parts of domain starting from the end of the array.
   * Eg. a.b.example.co.uk
   *      -> uk
   *      -> co.uk
   *      -> example.co.uk [this is domain name!]
   *
   * Then it tries to create cookie with each subpart and sees if it will stick in browser.
   * The first one that does is minimal domain name ie. without subdomains.
   *
   * Obviously, access to browser cookies is required.
   *
   * Idea came from: http://rossscrivener.co.uk/blog/javascript-get-domain-exclude-subdomain
   */
  static extractDomainWithoutSubs(): string {
    let domain = DomainUtils.getCurrentDomain();
    const domainParts = domain.split('.');
    const tempValue = '_gd' + new Date().getTime();

    let i = 0;
    while (i < domainParts.length - 1 && !CookieManager.cookieAvailable(tempValue)) {
      // take & concat domain parts from the back of the array
      domain = domainParts.slice(-1 - ++i).join('.');

      // create cookie with new domain subpart and see if it will stick in browser
      CookieManager.setCookie({
        name: tempValue,
        value: tempValue,
        domain,
      });
    }
    // remove test cookie
    CookieManager.deleteCookie({
      name: tempValue,
      domain,
    });

    return domain;
  }

  /**
   * Parse URI to get extract protocol, subdomain and the rest of URI.
   * URI domain must contain at least three parts (eg. tenant.example.com).
   */
  private static URI_PATTERN = new RegExp('^([^:]+:)?//([^./]+)((?:\\.[^.]+){2,}.+)$');
}

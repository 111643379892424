import '@ant-design/compatible/assets/index.css';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import { Editor } from '@src/components/common/editor/Editor';
import { TimunEditorConfig } from '@src/components/common/editor/TimunEditorConfigs';
import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import IntegrationVideoForm from '@src/components/common/integrationvideo/IntegrationVideoForm';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import MathContent from '@src/components/common/math/MathContent';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import FileUpload, { IUploadStatus } from '@src/components/common/upload/FileUpload';
import CourseContentControl from '@src/components/course/CourseContentControl';
import RepositoryFileListPicker from '@src/components/repository/file/RepositoryFileListPicker';
import IfTenantPropEnabled from '@src/components/tenant/IfTenantPropEnabled';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { ICourse } from '@src/model/course/Course';
import { ILectureWithContent } from '@src/model/course/Lecture';
import { ICourseLectureListElement } from '@src/model/course/LectureGroup';
import { IFile } from '@src/model/file/File';
import { ITimunFile } from '@src/service/business/files/util';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Input, InputNumber, Row, Select, Spin, Typography, Form } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useCallback, useMemo } from 'react';

const FormItem = Form.Item;
const Option = Select.Option;

const MIN_LECTURE_ORDERING_VALUE = AppConfigService.getValue('components.courses.minOrderingValue');

// -- Prop types
// ----------

export interface ICourseLectureContentUpdateFormOwnProps {
  lecture: ILectureWithContent;
  lectureList: ICourseLectureListElement[];
  fileList: IFile[];
  course: ICourse;
  canEditStructure: boolean;
  onLectureUpdate: (data: ILectureWithContent) => void;
  onLectureDelete: (data: ILectureWithContent) => void;
  onEditingChange: (editing: boolean) => void;
  onEditorFileAdd: (fileBlob: any, fileName: string, successFn: (url: string) => void, failureFn: (err: string) => void) => void;
  onFileAdd: (data: IFile[]) => void;
  onFileDelete: (data: IFile[]) => void;
}

type ICourseLectureContentUpdateFormProps = ICourseLectureContentUpdateFormOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithPathResolverOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------

/** Update form for lecture content ... */
const CourseLectureContentUpdateForm: React.FC<ICourseLectureContentUpdateFormProps> = (props: ICourseLectureContentUpdateFormProps) => {
  const [form] = Form.useForm<ILectureWithContent>();

  const selectedLectureGroups = Form.useWatch(['lectureGroup'], form);

  const handleEditorFileAdd = useCallback(
    (blobInfo: any, success: (msg: string) => void, failure: (msg: string) => void) => {
      props.onEditorFileAdd(blobInfo.blob(), blobInfo.filename(), success, failure);
    },
    [props.onEditorFileAdd]
  );

  const handleFileAdd = useCallback(
    (data: IFile) => {
      props.onFileAdd([data]);
    },
    [props.onFileAdd]
  );

  const handleFileDelete = useCallback(
    (data: UploadFile) => {
      const deleted: IFile | undefined = props.fileList.find((file: IFile) => file.id === data.uid);
      if (deleted) {
        props.onFileDelete([deleted]);
      }
    },
    [props.onFileDelete, props.fileList]
  );

  const closeForm = useCallback(() => {
    form.resetFields();
    props.onEditingChange(false);
  }, [props.onEditingChange, form.resetFields]);

  const handleCancel = useCallback(() => {
    if (form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: () => {
          closeForm();
        },
        title: props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      closeForm();
    }
  }, [form.isFieldsTouched, closeForm]);

  const handleFinish = useCallback(
    (values: ILectureWithContent) => {
      const data: ILectureWithContent = {
        ...props.lecture,
        title: values.title,
        ordering: values.ordering,
        lectureGroup: values.lectureGroup,
        content: values.content,
        integrationVideo: values.integrationVideo,
      };
      props.onLectureUpdate(data);
      closeForm();
    },
    [props.onLectureUpdate, props.lecture, closeForm]
  );

  // tslint:disable-next-line: no-big-function
  // if user has changed some values and there are no errors, save button can be enabled
  const isReadyToSubmit = useCallback(() => {
    const fieldsError: any = form.getFieldsError();
    const hasFormErrors = Object.keys(fieldsError).some((field) => fieldsError[field].errors.length > 0);
    return form.isFieldsTouched() && !hasFormErrors;
  }, [form.getFieldsError, form.isFieldsTouched]);

  // selected lecture group, it's length and data if lecture group has been changed are needed to correctly limit value of ordering
  const currentLectureGroup = useMemo(
    () =>
      props.lectureList.find((lectureGroup) => {
        const selected = selectedLectureGroups ? selectedLectureGroups.id : props.lecture.lectureGroup.id;
        return lectureGroup.id === selected;
      }),
    [props.lectureList, props.lecture, selectedLectureGroups]
  );
  // safety, should be impossible to get undefined for currentLectureGroup or list length
  const currentLectureGroupListLength = useMemo(() => (currentLectureGroup ? currentLectureGroup.lectureList.length : 0), [currentLectureGroup]);
  const maxLectureOrderingValue = useMemo(() => (currentLectureGroup && currentLectureGroup.id === props.lecture.lectureGroup.id ? currentLectureGroupListLength : currentLectureGroupListLength + 1), [props.lecture.lectureGroup.id, currentLectureGroup, currentLectureGroupListLength]);

  const fileList: ITimunFile[] = useMemo(() => FileUtils.mapToTimunFileList(props.fileList), [props.fileList]);

  const ButtonControls = () => (
    <FormItem shouldUpdate={true}>
      {() => (
        <Row justify="end" gutter={12}>
          {props.canEditStructure && (
            <Col>
              <DeleteElementButton<ILectureWithContent> buttonText={props.translate('COMMON.ACTION_DELETE')} icon={<LemonIcon name="delete" />} titlePropPath={'title'} item={props.lecture} onDelete={props.onLectureDelete} />
            </Col>
          )}
          <Col>
            <Button onClick={handleCancel} data-test-id="timun-courseView__update-lecture_cancelButton">
              {props.translate(`COMMON.ACTION_${!isReadyToSubmit ? 'CANCEL' : 'CLOSE'}`)}
            </Button>
          </Col>
          <Col>
            <Button type="primary" data-test-id="timun-courseView__update-lecture_submitButton" disabled={!isReadyToSubmit} htmlType="submit">
              {props.translate('COMMON.ACTION_SAVE')}
            </Button>
          </Col>
        </Row>
      )}
    </FormItem>
  );

  return (
    <React.Fragment>
      <Form<ILectureWithContent> form={form} layout="vertical" hideRequiredMark={true} onFinish={handleFinish} className="timun-courseView__lecture-content" initialValues={props.lecture}>
        {/* ----- buttons ----- */}
        <ButtonControls />

        {/* ----- title ----- */}
        <FormItem
          label={props.translate('COURSE_VIEW.UPDATE.LECTURE_TITLE_FORM_LABEL')}
          name="title"
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
          ]}
        >
          <Input data-test-id="timun-courseView__update-lecture_lectureTitle" />
        </FormItem>

        {/* ----- groups ----- */}
        <FormItem label={props.translate('COURSE_VIEW.UPDATE.LECTURE_GROUP_TITLE')} name={['lectureGroup', 'id']} rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
          <Select placeholder={props.translate('COURSE_VIEW.UPDATE.LECTURE_GROUP_SELECT_PLACEHOLDER')} disabled={!props.canEditStructure}>
            {props.lectureList.map((lectureGroup: ICourseLectureListElement) => (
              <Option key={lectureGroup.id} value={lectureGroup.id} data-test-id={`lemon-fileRepository__tlectureGroupSelect_${lectureGroup.id}`}>
                {lectureGroup.title}
              </Option>
            ))}
          </Select>
        </FormItem>

        {/* ----- ordering ----- */}
        <FormItem label={props.translate('COURSE_VIEW.UPDATE.LECTURE_ORDERING_FORM_LABEL')} name="ordering">
          <InputNumber data-test-id="timun-courseView__update-lecture_lectureOrdering" min={MIN_LECTURE_ORDERING_VALUE} max={maxLectureOrderingValue} disabled={!props.canEditStructure} />
        </FormItem>

        {/* ----- video URL ----- */}
        <IfTenantPropEnabled property="integration_video" fallback="">
          <FormItem name="integrationVideo" label={props.translate('COURSE_VIEW.UPDATE.LECTURE_VIDEO_FORM_LABEL')}>
            {/*// TODO: BE returns integrationVideo as null when empty, it should be undefined*/}
            <IntegrationVideoForm />
          </FormItem>
        </IfTenantPropEnabled>

        {/* ----- content ----- */}
        <Typography.Title level={4}>{props.translate('COURSE_VIEW.UPDATE.LECTURE_CONTENT_FORM_LABEL')}</Typography.Title>
        <FormItem name="content">
          {/* change to Editor */}
          <CourseContentControl
            dataTestId="timun-courseView__update-lecture-content"
            renderEdit={(content, handleEditorChange) => {
              return {
                body: (
                  <Editor
                    value={content.body}
                    onChange={(contentBody: string) => {
                      handleEditorChange(contentBody);
                    }}
                    data-test-id="timun-courseView__update-aboutCourse-content"
                    init={{
                      ...TimunEditorConfig.defaultConfig(),
                      height: 500,
                      menubar: true,
                      // image upload config (NOTE: we should probably create new componente (eg. TinyEditor) that will configure this for all editors
                      relative_urls: false, // tinymce tries to convert all URLs to relative ones and breaks links to uploaded API file (removes leading /) - this disables that behaviour
                      images_upload_handler: handleEditorFileAdd, // image upload handler
                    }}
                  />
                ),
              };
            }}
            renderPreview={(content) => {
              return {
                body: (
                  <React.Fragment>
                    {/*TODO: replace with component that will render lecture body*/}
                    {content.body && (
                      <Typography.Paragraph>
                        <MathContent key={content.body}>
                          <div dangerouslySetInnerHTML={{ __html: content.body }} />
                        </MathContent>
                      </Typography.Paragraph>
                    )}
                  </React.Fragment>
                ),
              };
            }}
          />
        </FormItem>

        {/* ----- buttons ----- */}
        <ButtonControls />

        {/* ----- files ----- */}
        {props.canEditStructure && (
          <FormItem>
            <Typography.Title level={4}>{props.translate('COURSE_VIEW.UPDATE.LECTURE_FILES_LABEL')}</Typography.Title>
            <Row align="middle" gutter={8}>
              <Col>
                {/* Antd Upload component has issues with upload and display of changes when there is a controlled list.
              Bypassed if one upload component handles upload only, and additional one displays the list and handles remove of files*/}
                <FileUpload onUploadFile={handleFileAdd}>
                  {(status: IUploadStatus) => (
                    <React.Fragment>
                      {status.isUploading && <Spin />}
                      <Button icon={<LemonIcon name="paperClip" />}>{props.translate('COMMON.ACTION_ADD_FROM_PC')}</Button>
                    </React.Fragment>
                  )}
                </FileUpload>
              </Col>
              <Col>{props.isTenantPropEnabled('repository') && <RepositoryFileListPicker onFileSelect={handleFileAdd} />}</Col>
            </Row>
            <FileUpload uploadProps={{ showUploadList: { showRemoveIcon: true, removeIcon: <LemonIcon className="timun-courseView__fileDeleteButton" name="delete" /> }, fileList, onRemove: handleFileDelete }} />
          </FormItem>
        )}
      </Form>
    </React.Fragment>
  );
};

export default withPathResolver(withTenantPropEnabled<ICourseLectureContentUpdateFormOwnProps>(withLocalize(CourseLectureContentUpdateForm as any)));

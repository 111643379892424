import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { getMinDateTime } from '@src/components/common/util/DateTimeUtils';
import { IReminder } from '@src/model/reminder/Reminder';
import AppConfigService from '@src/service/common/AppConfigService';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { DatePicker, Input, Modal, TimePicker, Form } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';

// -- Const
// ----------
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const timeFormat = AppConfigService.getValue('dateTimeFormat.time');
const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');
const REMINDER_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const TextArea = Input.TextArea;

// -- Prop types
// ----------
export interface IReminderEditFormOwnProps {
  reminder?: IReminder;
  onCancel: () => void;
  onSubmit: (data: IReminder) => void;
}

type IReminderEditFormProps = IReminderEditFormOwnProps & FormComponentProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component for creating and editing reminders */
const ReminderEdit: React.FC<IReminderEditFormProps> = (props: IReminderEditFormProps) => {
  const [form] = Form.useForm<IReminder>();

  const disabledHours = useCallback((): number[] => {
    const startTime = form.getFieldValue('startTime');
    const minDateTime = getMinDateTime();
    const disabledHours = [];
    if (startTime.isSame(minDateTime, 'day')) {
      // is Today
      const minHour = minDateTime.hour();
      for (let i = 0; i < minHour; i++) {
        disabledHours.push(i);
      }
    }
    return disabledHours;
  }, [form.getFieldValue, getMinDateTime]);

  const disabledMinutes = useCallback(
    (selectedHour: number): number[] => {
      const startTime = form.getFieldValue('startTime');
      const minDateTime = getMinDateTime();
      const disabledMinutes: number[] = [];
      if (startTime.isSame(minDateTime, 'day')) {
        // isToday
        if (selectedHour === minDateTime.hour()) {
          // is Today's min Hour
          const minMinute = minDateTime.minute();
          for (let i = 0; i < minMinute; i++) {
            disabledMinutes.push(i);
          }
        }
      }
      return disabledMinutes;
    },
    [form.getFieldValue, getMinDateTime]
  );

  const getDateTime = useCallback(() => {
    const date = form.getFieldValue('startDate');
    const time = form.getFieldValue('startTime');
    let dateTime = moment(time).set({ year: date.year(), month: date.month(), date: date.date() });

    const minDateTime = getMinDateTime();
    if (dateTime.isBefore(minDateTime)) {
      dateTime = minDateTime;
    }
    return dateTime.format(backendDate);
  }, [form.getFieldValue, moment, getMinDateTime]);

  const handleCancel = useCallback(() => {
    if (form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: props.onCancel,
        title: props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      props.onCancel();
    }
  }, [form.isFieldsTouched, confirmationDialog, props.onCancel]);

  const handleFinish = useCallback(
    (values: IReminder) => {
      const newDateTime = getDateTime();
      const data = props.reminder
        ? {
            ...props.reminder,
            description: values.description,
            startDateTime: newDateTime,
          }
        : ({
            description: values.description,
            startDateTime: newDateTime,
          } as IReminder);

      props.onSubmit(data);
    },
    [getDateTime, props.reminder, props.onSubmit]
  );

  return (
    <Modal title={props.translate('REMINDER_FORM.LABEL')} visible={true} maskClosable={false} onOk={form.submit} onCancel={handleCancel} okText={props.translate('COMMON.ACTION_SAVE')} cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form<IReminder> form={form} onFinish={handleFinish} initialValues={{ startDate: props.reminder ? moment(props.reminder.startDateTime) : moment(), startTime: props.reminder ? moment(props.reminder.startDateTime) : moment(), description: props.reminder ? props.reminder.description : null }} layout="vertical">
        <Form.Item name={'startDate'} label={props.translate('REMINDER_FORM.DATE')} rules={[{ required: true, message: props.translate('REMINDER_FORM.DATE_VALIDATION_MESSAGE') }]}>
          <DatePicker format={dateFormat} disabledDate={DateUtils.disablePastDate} allowClear={false} />
        </Form.Item>
        <Form.Item name={'startTime'} label={props.translate('REMINDER_FORM.TIME')} rules={[{ required: true, message: props.translate('REMINDER_FORM.TIME_VALIDATION_MESSAGE') }]}>
          <TimePicker
            showNow={false}
            format={timeFormat}
            disabledTime={() => {
              return { disabledHours, disabledMinutes };
            }}
            hideDisabledOptions={true}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item
          name={'description'}
          label={<FormItemLabelIcon label={props.translate('REMINDER_FORM.DESCRIPTION')} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: REMINDER_MAX_LENGTH })} />}
          rules={[
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: REMINDER_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
// -- HOCs and exports
// ----------

export default withLocalize<IReminderEditFormOwnProps>(ReminderEdit as any);

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import UserListFilter from '@src/components/user/filter/UserListFilter';
import UserManagementList from '@src/components/user/list/UserManagementList';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const VIEW_NAME = '@@USER_LIST';

// --
// ----- Prop types
interface IUserListOwnProps {
  additionalSearchParams: boolean;
  predefinedListFilter?: IUserListFilter;
  additionalCols?: () => ITimunDataTableCol<IUserInfo>[];
}

interface IUserListStateProps {
  userList: ICollectionData<IUserInfo>;
}

interface IUserListDispatchProps {
  clearUserList: () => any;
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => any;
}

type IUserListProps = IUserListOwnProps & IUserListStateProps & IUserListDispatchProps & IWithLocalizeOwnProps;

// --
// ----- Component
const UserListContainer: React.FC<IUserListProps> = (props: IUserListProps) => {
  const updateCourseList = () => {
    onUpdateList(props.fetchUserList, { ...collectionParams.filter, ...(props.predefinedListFilter ?? {}) });
  };
  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IUserListFilter>(
    {
      viewName: VIEW_NAME,
      updateFn: updateCourseList,
      initialValues: { size: AppConfigService.getValue('api.paging.defaultPageSize'), filter: props.predefinedListFilter ?? {} },
    },
    [props.predefinedListFilter]
  );

  useEffect(() => {
    return () => {
      props.clearUserList();
    };
  }, []);

  return (
    <React.Fragment>
      <UserListFilter showUserGroupParams={true} filter={collectionParams.filter} onChange={updateCollectionParams.onFilterSet} additionalSearchParams={props.additionalSearchParams} />
      {props.userList && <UserManagementList showAction={true} userList={props.userList.content} additionalCols={props.additionalCols} paginationData={props.userList.page} onPageChange={updateCollectionParams.onPageChange} />}
    </React.Fragment>
  );
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any /*, ownProps: ITutorListOwnProps*/): IUserListStateProps => ({
  userList: UserListBusinessStore.selectors.getUserList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserListDispatchProps => ({
  clearUserList: () => dispatch(UserListBusinessStore.actions.clearUserList()),
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => dispatch(UserListBusinessStore.actions.fetchUserList({ ...params })),
});

export default connect<IUserListStateProps, IUserListDispatchProps, IUserListOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(UserListContainer as any));

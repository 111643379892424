
import withScroll from '@src/components/app/withScroll';
import ExternalEducationApplicationContainer from '@src/components/externalEducationApplication/view/ExternalEducationApplicationContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type IExternalEducationApplicationViewPageProps = WithRouterProps;

const ExternalEducationApplicationViewPage = (props: IExternalEducationApplicationViewPageProps) => <ExternalEducationApplicationContainer id={props.params.id} />;

export default withScroll(withRouter<IExternalEducationApplicationViewPageProps>(ExternalEducationApplicationViewPage) as any);

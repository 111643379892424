import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { UserGroupVisibilityEnum } from '@src/model/usergroup/UserGroupVisibility';
import { IUserGroupCreatePayload } from '@src/service/business/usergroup/UserGroupBusinessStore';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import UserGroupHierarchyDataPicker from '@src/components/common/datapicker/UserGroupHierarchyDataPicker';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import { validateRequired } from '@src/components/common/form/validation';
import { IReference } from '@src/model/common/Reference';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input, Modal, Radio, Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

// -- Const
// ----------
const FormItem = Form.Item;
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');

// -- Prop types
// ----------
interface IUserGroupFormOwnProps {
  onCancel?: () => void;
  onUpdate?: (userGroup: IUserGroup) => void;
  onCreate?: (userGroupData: IUserGroupCreatePayload) => void;
  groupType: UserGroupTypeEnum;
  parentGroup?: IReference<string>;
  userGroup?: IUserGroup;
}

type IUserGroupFormProps = IUserGroupFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------
const UserGroupForm: React.FC<IUserGroupFormProps> = (props: IUserGroupFormProps) => {
  const [form] = Form.useForm<IUserGroup>();
  const titleMessageCreate = useMemo(() => (props.groupType === UserGroupTypeEnum.ORGANIZATION ? props.translate('ORGANIZATION_GROUP.NEW_GROUP_TITLE') : props.translate('USER_GROUP.NEW_GROUP_TITLE')), [props.groupType, props.translate]);
  const titleMessageUpdate = useMemo(() => (props.groupType === UserGroupTypeEnum.ORGANIZATION ? props.translate('ORGANIZATION_GROUP.EDIT_GROUP_TITLE') : props.translate('USER_GROUP.EDIT_GROUP_TITLE')), [props.groupType, props.translate]);

  const handleFinish = useCallback(
    (values: IUserGroup) => {
      let userGroupData;
      if (props.userGroup) {
        userGroupData = {
          ...props.userGroup,
          name: values.name,
          description: values.description,
          visibility: values.visibility,
        };
        props.onUpdate?.(userGroupData);
      } else {
        userGroupData = {
          name: values.name,
          description: values.description,
          groupType: { id: props.groupType ?? UserGroupTypeEnum.BASIC },
          parent: values.parent,
        };
        props.onCreate?.(userGroupData);
      }
    },
    [props.userGroup, props.onUpdate, props.onCreate, props.groupType]
  );

  return (
    <Modal title={props.userGroup ? titleMessageUpdate : titleMessageCreate} open={true} onCancel={props.onCancel} onOk={form.submit} okText={props.translate('COMMON.ACTION_SAVE')} cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form
        form={form}
        onFinish={handleFinish}
        initialValues={{
          name: props.userGroup?.name,
          description: props.userGroup?.description,
          parent: props.parentGroup,
          visibility: { id: props.userGroup?.visibility?.id ?? UserGroupVisibilityEnum.CLOSED },
        }}
      >
        <FormItem
          name={'name'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, validator: validateRequired },
          ]}
          label={props.translate('USER_GROUP_FORM.NAME_LABEL')}
        >
          <Input />
        </FormItem>

        <FormItem
          name={'description'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, validator: validateRequired },
            { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
          ]}
          label={<FormItemLabelIcon label={props.translate('USER_GROUP_FORM.DESCRIPTION_LABEL')} tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
        >
          <Input.TextArea rows={4} />
        </FormItem>

        {props.groupType === UserGroupTypeEnum.ORGANIZATION && (
          <Form.Item name={'parent'} label={props.translate('USER_GROUP_FORM.PARENT_PICKER_LABEL')}>
            <UserGroupHierarchyDataPicker value={props.parentGroup} disabled={props.userGroup != null} />
          </Form.Item>
        )}

        <Form.Item name={['visibility', 'id']} label={props.translate('USER_GROUP_FORM.VISIBILITY_LABEL')} hidden={!props.userGroup || props.groupType === UserGroupTypeEnum.ORGANIZATION} noStyle={!props.userGroup || props.groupType === UserGroupTypeEnum.ORGANIZATION}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={UserGroupVisibilityEnum.PUBLIC}>{props.translate('USER_GROUP_FORM.VISIBILITY_TITLE_PUBLIC')}</Radio.Button>
            <Radio.Button value={UserGroupVisibilityEnum.LIMITED}>{props.translate('USER_GROUP_FORM.VISIBILITY_TITLE_LIMITED')}</Radio.Button>
            <Radio.Button value={UserGroupVisibilityEnum.CLOSED}>{props.translate('USER_GROUP_FORM.VISIBILITY_TITLE_CLOSED')}</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IUserGroupFormOwnProps>(UserGroupForm as any);

import React from 'react';
import { connect } from 'react-redux';

import { IEducationCategory } from '@src/model/educationcategory/EducationCategory';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { Select } from 'antd';


// -- Const
// ----------
const Option = Select.Option;

// -- Prop types
// ----------

export interface IEducationCategoryPickerOwnProps {
  value?: string[];
  disabled?: boolean;
  placeholder?: string;
  onChange?: (value?: string[]) => void;
}

export interface IEducationCategoryPickerStateProps {
  educationCategoryList: IEducationCategory[];
}

export interface IEducationCategoryPickerDispatchProps {
}
type IEducationCategoryPickerProps = IEducationCategoryPickerOwnProps & IEducationCategoryPickerStateProps & IEducationCategoryPickerDispatchProps;

// -- Component
// ----------

/** Describe your component ... */
const EducationCategoryPicker = (props: IEducationCategoryPickerProps) => {
  return <Select
    disabled={props.disabled}
    onChange={props.onChange}
    placeholder={props.placeholder}
    mode={'multiple'}
    value={props.value}>
    {props.educationCategoryList.map((educationCategory) => {
      return (
        <Option value={educationCategory.id} key={educationCategory.id}>{educationCategory.name}</Option>
      );
    })}
  </Select>;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationCategoryPickerOwnProps): IEducationCategoryPickerStateProps => ({
  educationCategoryList: CollectionBusinessStore.selectors.getCollectionContent(state, 'EducationCategory'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationCategoryPickerDispatchProps => ({
});

export default connect<IEducationCategoryPickerStateProps, IEducationCategoryPickerDispatchProps, IEducationCategoryPickerOwnProps>(mapStateToProps, mapDispatchToProps)(EducationCategoryPicker as any);

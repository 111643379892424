import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { IPath } from '@src/model/common/Path';
import { IReference } from '@src/model/common/Reference';
import { IUserGroupMember } from '@src/model/usergroup/UserGroupMember';
import { IUserGroupVisibility } from '@src/model/usergroup/UserGroupVisibility';

export interface IUserGroup {
  name: string;
  description: string;
  id: string;
  coverImageUrl: string;
  visibility: IUserGroupVisibility;
  latestCode: IUserGroupAccessCode;
  currentUserMember?: IUserGroupMember;
  fullPath?: IPath;
  groupType: ICodeBookEntry<UserGroupTypeEnum>;
  parent?: IReference;
}

export interface IUserGroupAccessCode {
  id: string;
  value: string;
}

export enum UserGroupTypeEnum {
  BASIC = '1',
  ORGANIZATION = '2',
}

import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationFileListView from '@src/components/externalEducationApplication/common/EducationApplicationFileListView';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/utils/EducationApplicationHelperUtils';
import ExternalEducationApplicationFileListUpload from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFileListUpload';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IFileListsByType } from '@src/service/business/files/util';

import { EmploymentStatusEnum, IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IFile } from '@src/model/file/File';
import { Button, Col, Row, Typography } from 'antd';

import React from 'react';

const LAYOUT_BUTTONS_1 = {
  sm: 24,
  xs: 24,
  md: 12,
};

// -- Prop types
// ----------
interface IExternalEducationApplicationTraineeDurationViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  canParticipate?: boolean;
  onUpdate?: (data: IExternalEducationApplication) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: EducationApplicationActivityPhaseEnum) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}

type IExternalEducationApplicationTraineeDurationViewProps = IExternalEducationApplicationTraineeDurationViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

const ExternalEducationApplicationTraineeDurationView = (props: IExternalEducationApplicationTraineeDurationViewProps) => {
  const isApplicationInPhases = (phases: (keyof typeof EducationApplicationActivityPhaseEnum)[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, props.externalEducationApplication.activity);
  };
  const handleChangePhase = (phase: EducationApplicationActivityPhaseEnum) => props.onChangePhase?.(phase);

  const canSubmitSignedAnnex = EducationApplicationHelperUtils.hasFilesInAllTypes(props.applicationFiles, ['SIGNED_CONTRACT_ANNEX']);
  const canSubmitEducationCompletion = EducationApplicationHelperUtils.hasFilesInAllTypes(props.applicationFiles, ['COMPETENCIES_CERTIFICATE', 'ATTENDANCE_CERTIFICATE']) && (isApplicationInPhases(['EDUCATION_IN_PROGRESS']) || isApplicationInPhases(['CONTRACT_ANNEX_SUBMITTED']));
  return (
    <React.Fragment>
      {isApplicationInPhases(['EDUCATION_IN_PROGRESS', 'CONTRACT_ANNEX_SUBMITTED']) && (
        <React.Fragment>
          <Row>
            {isApplicationInPhases(['EDUCATION_IN_PROGRESS']) && <Col>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_IN_PROGRESS_TEXT')}</Col>}
            {isApplicationInPhases(['CONTRACT_ANNEX_SUBMITTED']) && <Col>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CONTRACT_ANNEX_SUBMITTED_TEXT')}</Col>}
          </Row>

          {props.externalEducationApplication.employmentStatus?.id === EmploymentStatusEnum.UNEMPLOYED && (
            <Row>
              <Col>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.UNEMPLOYED_ATTENDANCE_CERTIFICATE_TEXT')}</Col>
            </Row>
          )}

          <GridItem label={props.translate('FILES.TYPE_ENUM.LABEL.ATTENDANCE_CERTIFICATE')} infoText={props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.ATTENDANCE_CERTIFICATE_INFO')}>
            <ExternalEducationApplicationFileListUpload applicationFiles={props.applicationFiles} fileType="ATTENDANCE_CERTIFICATE" onFileUpload={props.onFileUpload} onFileRemove={props.onFileRemove} />
          </GridItem>

          <Row>
            <Col>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.UPLOAD_COMPETENCIES_CERTIFICATE_TEXT')}</Col>
          </Row>

          <GridItem label={props.translate('FILES.TYPE_ENUM.LABEL.COMPETENCIES_CERTIFICATE')} infoText={props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.COMPETENCIES_CERTIFICATE_INFO')} requiredMark={true}>
            <ExternalEducationApplicationFileListUpload applicationFiles={props.applicationFiles} fileType="COMPETENCIES_CERTIFICATE" onFileUpload={props.onFileUpload} onFileRemove={props.onFileRemove} />
          </GridItem>

          {isApplicationInPhases(['EDUCATION_IN_PROGRESS']) && (
            <React.Fragment>
              <Typography.Paragraph>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.DESCRIPTION_EDUCATION_COMPLETED_BUTTON_TEXT')}</Typography.Paragraph>
              <Row>
                <Col {...LAYOUT_BUTTONS_1}>
                  <Button type="primary" block={false} onClick={() => handleChangePhase(EducationApplicationActivityPhaseEnum.EDUCATION_COMPLETED)} disabled={!canSubmitEducationCompletion} data-test-id="timun-externalEducationApplicationView__educationCompletedButton">
                    {props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_COMPLETED_BUTTON_TEXT')}
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {isApplicationInPhases(['CONTRACT_ANNEX_REQUESTED']) && (
        <React.Fragment>
          <Typography.Paragraph>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CONTRACT_ANNEX_REQUESTED_TEXT')}</Typography.Paragraph>

          <GridItem label={props.translate('FILES.TYPE_ENUM.LABEL.CONTRACT_ANNEX')}>
            <EducationApplicationFileListView files={props.applicationFiles} displayTypes={['CONTRACT_ANNEX']} />
            {props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SIGNED_CONTRACT_ANNEX_REQUESTED_TEXT')}
          </GridItem>

          <GridItem label={props.translate('FILES.TYPE_ENUM.LABEL.SIGNED_CONTRACT_ANNEX')}>
            <ExternalEducationApplicationFileListUpload applicationFiles={props.applicationFiles} fileType="SIGNED_CONTRACT_ANNEX" onFileUpload={props.onFileUpload} onFileRemove={props.onFileRemove} />
          </GridItem>

          <Typography.Paragraph>{props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.MUTUALLY_SIGNED_CONTRACT_ANNEX_REQUESTED_TEXT')}</Typography.Paragraph>
          <Row>
            <Col>
              <Button type="primary" disabled={!canSubmitSignedAnnex} block={false} onClick={() => handleChangePhase(EducationApplicationActivityPhaseEnum.CONTRACT_ANNEX_SUBMITTED)} data-test-id="timun-externalEducationApplicationView__cancelApplicationButton">
                {props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.MUTUALLY_SIGNED_CONTRACT_ANNEX_REQUESTED_BUTTON_LABEL')}
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationTraineeDurationViewOwnProps>(ExternalEducationApplicationTraineeDurationView as any));

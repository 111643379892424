import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICourse } from '@src/model/course/Course';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Select } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// -- Const
// ----------
const MAX_PAGE_SIZE = AppConfigService.getValue('api.collectionDefaultLimit');
const Option = Select.Option;

// -- Prop types
// ----------

interface ICoursePickerOwnProps {
  value?: string;
  disabled?: boolean;
  onChange?: (data?: string) => void;
}

interface ICoursePickerStateProps {
  courseList: ICollectionData<ICourse>;
}

interface ICoursePickerDispatchProps {
  fetchCourseList: (params: ICollectionFetchPayload<ICourseListFilter>) => void;
}
type ICoursePickerProps = ICoursePickerOwnProps & ICoursePickerStateProps & IWithLocalizeOwnProps & ICoursePickerDispatchProps;

// -- Component
// ----------

const CoursePicker = (props: ICoursePickerProps) => {
  useEffect(() => {
    updateList();
  }, []);

  const updateList = (filter: ICourseListFilter = {}) => {
    props.fetchCourseList({
      filter,
      page: 0,
      sort: [],
      size: MAX_PAGE_SIZE,
    });
  };

  return (
    <Select value={props.value} allowClear={true} onChange={props.onChange}>
      {props.courseList?.content.map((course) => {
        return (
          <Option value={course.id} key={course.id}>
            {course.title}
          </Option>
        );
      })}
    </Select>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICoursePickerOwnProps): ICoursePickerStateProps => ({
  courseList: CourseListBusinessStore.selectors.getCourseList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): ICoursePickerDispatchProps => ({
  fetchCourseList: (params: ICollectionFetchPayload<ICourseListFilter>) => dispatch(CourseListBusinessStore.actions.fetchCourseList(params)),
});

export default connect<ICoursePickerStateProps, ICoursePickerDispatchProps, ICoursePickerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(CoursePicker as any));

import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { IImportUserErrorData, InvalidUsers } from '@src/model/user/InvalidUsers';
import React, { useCallback, useMemo } from 'react';

export interface IInvalidUserListOwnProps {
  invalidUsers: InvalidUsers[];
}

type IInvalidUserListProps = IInvalidUserListOwnProps & IWithLocalizeOwnProps;

const InvalidUserList: React.FC<IInvalidUserListProps> = (props: IInvalidUserListProps) => {
  const columns: ITimunDataTableCol<InvalidUsers>[] = useMemo(
    () => [
      {
        key: 'avatar',
        contentType: 'string',
        headerTitle: props.translate('USER_MANAGEMENT_LIST_VIEW.USER_AVATAR_TITLE'),
        content: (record: InvalidUsers) => <AppUserAvatar imagePath={record.user.profileImageUrl} />,
      },
      {
        key: 'userFirstName',
        contentType: 'string',
        headerTitle: props.translate('USER_MANAGEMENT_LIST_VIEW.USER_FIRST_NAME_TITLE'),
        content: (record: InvalidUsers) => record.user.firstName,
      },
      {
        key: 'userLastName',
        contentType: 'string',
        headerTitle: props.translate('USER_MANAGEMENT_LIST_VIEW.USER_LAST_NAME_TITLE'),
        content: (record: InvalidUsers) => record.user.lastName,
      },
      {
        key: 'userEmail',
        contentType: 'string',
        headerTitle: props.translate('USER_MANAGEMENT_LIST_VIEW.USER_EMAIL_TITLE'),
        content: (record: InvalidUsers) => getEmailValue(record.user.contactData),
      },
      {
        key: 'importUserErrorData',
        contentType: 'string',
        headerTitle: props.translate('TIMUN_IMPORT_USER.USER_ERROR'),
        content: (record: InvalidUsers) =>
          record.importUserErrorData.map((error: IImportUserErrorData) => (
            <p>
              {props.translate(`TIMUN_IMPORT_USER.USER_DATA_ERROR_ENUM.${error.timunImportUserError.id}_ERROR.${error.data.id}_LABEL`)}
              <br />
            </p>
          )),
      },
    ],
    []
  );
  const getEmailValue = useCallback((contactData: IContactData[]): string => {
    if (contactData) {
      const email = contactData?.find((contact) => contact.type.id === ContactDataTypeEnum.EMAIL);
      if (email) {
        return email.value;
      }
    }
    return '';
  }, []);
  return (
    <React.Fragment>
      <DataTable<InvalidUsers> items={props.invalidUsers} columns={columns} />
    </React.Fragment>
  );
};

export default withLocalize<IInvalidUserListOwnProps>(InvalidUserList as any);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { FileTypeEnum } from '@src/model/file/FileType';
import { IFile } from '@src/model/file/File';
import { IFileListsByType, ITimunFile } from '@src/service/business/files/util';
import React from 'react';
import FileListCardView from '@src/components/common/file/FileListCardView';

interface IFileTypesListViewOwnProps {
  displayTypes: (keyof typeof FileTypeEnum)[];
  files?: IFileListsByType;
  canRemove?: boolean;
  onRemove?: (file: IFile[]) => void;
}
type IFileTypesListViewProps = IFileTypesListViewOwnProps & IWithLocalizeOwnProps;

interface IFileTypesListViewState {
}

class FileTypesListView extends React.Component<IFileTypesListViewProps, IFileTypesListViewState> {
  state: IFileTypesListViewState = {
  };

  render = () => {
    const fileList = this.props.displayTypes.reduce((accum: ITimunFile[], item: keyof typeof FileTypeEnum) => {
      const addList = this.props.files?.[FileTypeEnum[item]];
      if (addList) {
        return accum.concat(addList);
      }
      return accum;
    }, []);
    return <React.Fragment>
      <FileListCardView
        fileList={fileList}
        canRemove={this.props.canRemove}
        onRemove={this.props.onRemove}
      />

    </React.Fragment>;
  };

}

export default withLocalize<IFileTypesListViewOwnProps>(FileTypesListView as any);


import { IFile } from '@src/model/file/File';
import { FileTypeEnum } from '@src/model/file/FileType';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

import { UploadFile } from 'antd/lib/upload/interface';
import _ from 'lodash';

export type ITimunFile<T = IFile> = UploadFile<T>;

export type IFileListsByType = {
  [key in FileTypeEnum]?: ITimunFile[];
};

/** get files sorted by type from standard file list */
export const mapFileListsByType = (files?: IFile[]): IFileListsByType => {
  const fileLists: IFileListsByType = {};
  files?.forEach((item: IFile) => {
    const typeId = item?.type;
    const fileObject = {
      uid: item.id,
      name: item.name,
      type: item.mimeType,
      size: item.size,
      response: item,
      url: UrlBuilderFactory.buildApiFileUrl(item.id),
    };

    if (typeId) {
      const fileList = fileLists[typeId];

      fileLists[typeId] = fileList ? [...fileList, fileObject] : [fileObject];
    }
  });
  return fileLists;
};

/** Check if there are files for all listed types. */
export const hasFilesInAllTypes = (files: IFileListsByType, types: (keyof typeof FileTypeEnum)[]) => {
  return types.every((type) => {
    const fileList = files?.[FileTypeEnum[type]];
    return fileList != null && fileList.length > 0;
  });
};

/** Check if there are files for any listed types. */
export const hasFilesInTypes = (files: IFileListsByType, types: (keyof typeof FileTypeEnum)[]) => {
  return types.some((type) => {
    const fileList = files[FileTypeEnum[type]];
    return fileList != null && fileList.length > 0;
  });
};

/** Take the first file in the list and ignore other entries */
export const getFirstFileByType = (files: IFileListsByType, type: keyof typeof FileTypeEnum): IFile | undefined => {
  const fileList = files[FileTypeEnum[type]];
  if (fileList != null && fileList.length > 0) {
    return _.head(files[FileTypeEnum[type]])?.response;
  }
  return;
};

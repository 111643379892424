import React from 'react';

import UserDataPicker from '@src/components/common/datapicker/UserDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IExamInstanceListFilter } from '@src/service/business/examtemplates/examInstanceBusinessStore';
import { Button, Col, Form, Row } from 'antd';


// -- Prop types
// ----------

export interface IExamInstanceListFilterOwnProps {
  filter?: IExamInstanceListFilter;
  onFilterChange?: (filter: IExamInstanceListFilter) => void;
}
type IExamInstanceListFilterProps = IExamInstanceListFilterOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExamInstanceListFilter: React.FC<IExamInstanceListFilterProps> = (props: IExamInstanceListFilterProps) => {
  const [form] = Form.useForm<IExamInstanceListFilter>();
  const handleFinish = (values: IExamInstanceListFilter) => props.onFilterChange?.(values);
  return (
    <div className="timun-wrapper__gutterBox--vertical">
      <Form<IExamInstanceListFilter>
        name="examInstanceListFilter"
        form={form}
        initialValues={props.filter}
        onFinish={handleFinish}>
        <Row justify="start">
          <Col span={6}>
            <Form.Item
              label={props.translate('EXAM_INSTANCE_LIST.FILTER.FORM_ASSIGNEE_LABEL')}
              name={'assignee'}>
              <UserDataPicker role={UserRoleEnum.TRAINEE} />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" onClick={form.submit}>{props.translate('COMMON.ACTION_FILTER')}</Button>
      </Form>
    </div>
  );
};

export default withLocalize<IExamInstanceListFilterOwnProps>(ExamInstanceListFilter as any);

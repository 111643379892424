import EducationQualificationCodebookContainer from '@src/components/codebook/EducationQualificationCodebookContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { Empty } from 'antd';
import React from 'react';

interface IAdmiEducationQualificationPageOwnProps { }

type IAdminEducationQualificationCodebookPageProps = IWithRolesOwnProps & IWithTenantPropEnabledOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const AdminEducationQualificationCodebookPage = (props: IAdminEducationQualificationCodebookPageProps) => {
  const DisplayComponent = (props.isSuperAdmin() || props.isTenantPropEnabled('codebook_education_qualification')) ? <EducationQualificationCodebookContainer /> : <Empty description={props.translate('COMMON.LABEL.NOT_ALLOWED_TO_ACCESS_TO_ACCESS')} />;
  return (
    <React.Fragment>
      {DisplayComponent}
    </React.Fragment>
  );
};

export default withTenantPropEnabled<IAdmiEducationQualificationPageOwnProps>(withLocalize(withRoles(AdminEducationQualificationCodebookPage as any)));



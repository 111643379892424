import EnrollButtonView from '@src/components/common/enroll/EnrollButtonView';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICourse } from '@src/model/course/Course';
import { IUserInfo } from '@src/model/user/User';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import UsersCoursesUpdateBusinessStore, { IEnrollUsersCoursesPayload } from '@src/service/business/courses/UsersCoursesUpdateBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

interface ICourseEnrollButtonContainerOwnProps {
  course: ICourse;
  onEnroll: () => void;
}

interface ICourseEnrollButtonContainerStateProps {
  currentUser: IUserInfo;
}

interface ICourseEnrollButtonContainerDispatchProps {
  enrollUser: (data: IEnrollUsersCoursesPayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}
type ICourseEnrollButtonContainerProps = ICourseEnrollButtonContainerOwnProps & ICourseEnrollButtonContainerStateProps & ICourseEnrollButtonContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component for rendering enrolled button or status */
const CourseEnrollButtonContainer = (props: ICourseEnrollButtonContainerProps) => {
  const [isEnrolled, setIsEnrolled] = useState<boolean>(false);

  useEffect(() => {
    if (props.course.progress?.assignedDateTime) {
      setIsEnrolled(true);
    }
  }, [props.course.id, props.course.progress?.assignedDateTime]);

  const handleEnroll = () => {
    props
      .enrollUser({
        courses: [{ id: props.course.id }],
      })
      .track()
      .subscribe(() => {
        props.reportMessage({ message: props.translate('COURSE_VIEW.ENROLL_MESSAGE', { courseName: props.course.title }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        props.onEnroll();
      });
  };

  return !isEnrolled ? <EnrollButtonView onEnroll={handleEnroll} /> : null;
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseEnrollButtonContainerOwnProps): ICourseEnrollButtonContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICourseEnrollButtonContainerDispatchProps => ({
  enrollUser: (data: IEnrollUsersCoursesPayload) => createTrackableAction(dispatch(UsersCoursesUpdateBusinessStore.actions.enrollUsersCourses(data))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect<ICourseEnrollButtonContainerStateProps, ICourseEnrollButtonContainerDispatchProps, ICourseEnrollButtonContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(CourseEnrollButtonContainer as any));

import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { EmploymentStatusEnum } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import NumberFormatService from '@src/service/util/numberformat/NumberFormatService';
import moment from 'moment';

// -- Const
// ----------
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
// TODO: reminder -> duration symbol to appConfig
const durationSymbol = 'h';

export interface IExternalEducationApplicationListFilterInfo {
  numberOfSelectedParameters: number;
}
export default class EducationApplicationFilterHelper {
  /** first check selected parameter value type and return matching value format  */
  static getSelectedParameterValue = (filter: IExternalEducationApplicationListFilter, currentFilterParameter: keyof IExternalEducationApplicationListFilter): string | undefined => {
    let value;

    if (currentFilterParameter === 'participant' || currentFilterParameter === 'evaluator' || currentFilterParameter === 'assignee') {
      const userInfo = filter[currentFilterParameter];
      value = `${userInfo?.firstName} ${userInfo?.lastName}`;
    } else if (currentFilterParameter === 'educationProvider') {
      const company = filter[currentFilterParameter];
      value = company?.name;
    } else if (currentFilterParameter === 'skill') {
      const skill = filter[currentFilterParameter];
      value = skill?.title;
    } else if (currentFilterParameter === 'education') {
      const education = filter[currentFilterParameter];
      value = education?.title;
    } else if (currentFilterParameter === 'phases') {
      const selectedPhaseIds = filter[currentFilterParameter];
      const phaseNames = selectedPhaseIds?.map((phaseId) => Object.keys(EducationApplicationActivityPhaseEnum).find((phase) => EducationApplicationActivityPhaseEnum[phase as keyof typeof EducationApplicationActivityPhaseEnum] === phaseId));
      value = phaseNames?.map((phaseName) => LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.PHASE.${phaseName}_LABEL`)).join(', ');
    } else if (currentFilterParameter === 'submittedFrom' || currentFilterParameter === 'submittedTo') {
      const date = filter[currentFilterParameter];
      value = moment(date).format(dateFormat);
    } else if (
      currentFilterParameter === 'educationMinExpenses' ||
      currentFilterParameter === 'educationMaxExpenses' ||
      currentFilterParameter === 'travelMinExpenses' ||
      currentFilterParameter === 'travelMaxExpenses' ||
      currentFilterParameter === 'coFinancedEducationPriceMin' ||
      currentFilterParameter === 'coFinancedEducationPriceMax'
    ) {
      const paramterNumber = filter[currentFilterParameter];
      if (paramterNumber) {
        value = NumberFormatService.formatCurrency(paramterNumber);
      }
    } else if (currentFilterParameter === 'educationMinDuration' || currentFilterParameter === 'educationMaxDuration') {
      value = `${filter[currentFilterParameter]} ${durationSymbol}`;
    } else if (currentFilterParameter === 'pendingConsultation' || currentFilterParameter === 'educationRelated' || currentFilterParameter === 'jobRelated' || currentFilterParameter === 'interestRelated') {
      value = filter[currentFilterParameter] ? LocalizeService.translate('COMMON.LABEL.YES') : LocalizeService.translate('COMMON.LABEL.NO');
    } else if (currentFilterParameter === 'employmentStatus') {
      value = filter[currentFilterParameter] === EmploymentStatusEnum.EMPLOYED ? LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYMENT_STATUS.EMPLOYED') : LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYMENT_STATUS.UNEMPLOYED');
    } else if (currentFilterParameter === 'city') {
      value = filter[currentFilterParameter];
    } else if (currentFilterParameter === 'educationCategory') {
      value = filter[currentFilterParameter]?.name;
    } else if (currentFilterParameter === 'userCategory') {
      value = filter[currentFilterParameter]?.name;
    }
    return value;
  };

  /** get selected paramters as array of keys */
  static getSelectedParamterKeys(filter: IExternalEducationApplicationListFilter): (keyof IExternalEducationApplicationListFilter)[] {
    return (Object.keys(filter) as (keyof IExternalEducationApplicationListFilter)[]).filter((param) => filter[param] != null);
  }

  /** get number of selected paramteres */
  static getNumberOfSelectedParameters(filter: IExternalEducationApplicationListFilter): number {
    return (Object.keys(filter) as (keyof IExternalEducationApplicationListFilter)[]).filter((key) => filter[key] != null).length;
  }

  /** get information about number of selected filter parameters */
  static getFilterInfo(filter: IExternalEducationApplicationListFilter): IExternalEducationApplicationListFilterInfo {
    return { numberOfSelectedParameters: EducationApplicationFilterHelper.getNumberOfSelectedParameters(filter) };
  }
}

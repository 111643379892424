import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import CodebookShortInfoForm from '@src/components/codebook/form/CodebookShortInfoForm';
import CodebookListView from '@src/components/codebook/view/CodebookList';
import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEnrollmentRequirement } from '@src/model/enrollmentrequirement/EnrollmentRequirement';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import EnrollmentRequirementBusinessStore, { IEnrollmentRequirementCreatePayload, IEnrollmentRequirementListFilter } from '@src/service/business/enrollmentrequirements/enrollmentRequirementBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const VIEW_NAME = '@@ENROLLMENT_REQUIREMENT_CODEBOOK';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');

// -- Prop types
// ----------
export interface IEnrollmentRequirementCodebookContainerOwnProps { }
export interface IEnrollmentRequirementCodebookContainerStateProps {
  enrollmentRequirementList: ICollectionData<IEnrollmentRequirement>;
}
export interface IEnrollmentRequirementCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchEnrollmentRequirementList: (params: ICollectionFetchPayload<IEnrollmentRequirementListFilter>) => ITrackableAction;
  createEnrollmentRequirement: (data: IEnrollmentRequirementCreatePayload) => ITrackableAction;
  updateEnrollmentRequirement: (data: IEnrollmentRequirement) => ITrackableAction;
}
type IEnrollmentRequirementCodebookContainerProps = IEnrollmentRequirementCodebookContainerOwnProps & IEnrollmentRequirementCodebookContainerStateProps & IEnrollmentRequirementCodebookContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Coded grade container */
const EnrollmentRequirementCodebookContainer = (props: IEnrollmentRequirementCodebookContainerProps) => {
  const [enrollmentRequirementModalsState, toggleEnrollmentRequirementModals] = useEntityModalsState<IEnrollmentRequirement>();
  const [, updateCollectionParams, onUpdateList] = useCollectionState<IEnrollmentRequirementListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchEnrollmentRequirementList,
    initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
  });
  const handleEnrollmentRequirementCreateSubmit = useCallback((data: IEnrollmentRequirementCreatePayload) => {
    props.createEnrollmentRequirement(data).track().subscribe(
      // success
      () => {
        props.reportMessage({ message: props.translate('ENROLLMENT_REQUIREMENT.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        onUpdateList();
        toggleEnrollmentRequirementModals.onCloseCreate();
      }
    );
  }, [props.createEnrollmentRequirement, props.reportMessage, onUpdateList, toggleEnrollmentRequirementModals]);

  const handleEnrollmentRequirementUpdateSubmit = useCallback((data: IEnrollmentRequirement) => {
    props.updateEnrollmentRequirement(data).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleEnrollmentRequirementModals.onCloseUpdate();
      }
    );
  }, [props.updateEnrollmentRequirement, onUpdateList, toggleEnrollmentRequirementModals]);

  return (
    <React.Fragment>
      {props.enrollmentRequirementList && <CodebookListView<IEnrollmentRequirement>
        showBackColumn={true}
        title={props.translate(`CODEBOOK.TITLE_LABEL.${CodebookEnum.ENROLLMENT_REQUIREMENT}`)}
        descriptionPrefix={'ENROLLMENT_REQUIREMENT.DESCRIPTION_LABEL'}
        buttonLabel={props.translate('ENROLLMENT_REQUIREMENT.CREATE_BUTTON_LABEL')}
        codebookItemList={props.enrollmentRequirementList}
        canEdit={true}
        onEditCodebookItemClick={toggleEnrollmentRequirementModals.onOpenUpdate}
        onCreateCodebookItemClick={toggleEnrollmentRequirementModals.onOpenCreate}
        onPageChange={updateCollectionParams.onPageChange}
      />}

      {(enrollmentRequirementModalsState.isCreateModalVisible || enrollmentRequirementModalsState.isUpdateModalVisible) && <CodebookShortInfoForm<IEnrollmentRequirement, IEnrollmentRequirementCreatePayload>
        value={enrollmentRequirementModalsState.selectedEntity}
        onCancel={toggleEnrollmentRequirementModals.onCloseCreate}
        onUpdate={handleEnrollmentRequirementUpdateSubmit}
        onCreate={handleEnrollmentRequirementCreateSubmit} />}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEnrollmentRequirementCodebookContainerOwnProps): IEnrollmentRequirementCodebookContainerStateProps => ({
  enrollmentRequirementList: EnrollmentRequirementBusinessStore.selectors.getEnrollmentRequirementList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEnrollmentRequirementCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchEnrollmentRequirementList: (params: ICollectionFetchPayload<IEnrollmentRequirementListFilter>) => dispatch(createTrackableAction(EnrollmentRequirementBusinessStore.actions.fetchEnrollmentRequirementList(params))),
  createEnrollmentRequirement: (data: IEnrollmentRequirementCreatePayload) => dispatch(createTrackableAction(EnrollmentRequirementBusinessStore.actions.createEnrollmentRequirement(data))),
  updateEnrollmentRequirement: (data: IEnrollmentRequirement) => dispatch(createTrackableAction(EnrollmentRequirementBusinessStore.actions.updateEnrollmentRequirement(data))),
});

export default connect<IEnrollmentRequirementCodebookContainerStateProps, IEnrollmentRequirementCodebookContainerDispatchProps, IEnrollmentRequirementCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(EnrollmentRequirementCodebookContainer as any));

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationUserCategoryForm, { IUserCategoryForm } from '@src/components/externalEducationApplication/form/ExternalEducationUserCategoryForm';
import IIdRef from '@src/model/common/IdRef';
import { IUserCategory } from '@src/model/user/UserCategory';
import { Form, Modal } from 'antd';


// -- Prop types
// ----------

export interface IExternalEducationUserCategoryModalOwnProps {
  userCategories?: IUserCategory[];
  onSubmit?: (value: Array<IIdRef<string>>) => void;
  onCancel?: () => void;
}
type IExternalEducationUserCategoryModalProps = IExternalEducationUserCategoryModalOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationUserCategoryModal: React.FC<IExternalEducationUserCategoryModalProps> = (props: IExternalEducationUserCategoryModalProps) => {
  const [form] = Form.useForm<IUserCategoryForm>();
  const handleSubmit = (value: Array<IIdRef<string>>) => {
    props.onSubmit?.(value);
    props.onCancel?.();
  };

  return (
    <Modal
      title={props.translate('USER_CATEGORY.MODAL.TITLE')}
      maskClosable={false}
      visible={true}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <ExternalEducationUserCategoryForm value={props.userCategories} onSubmit={handleSubmit} formRef={form} />
    </Modal>
  );
};

export default withLocalize<IExternalEducationUserCategoryModalOwnProps>(ExternalEducationUserCategoryModal as any);

import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import IIdRef from '@src/model/common/IdRef';
import { INote, NoteObjectTypeEnum } from '@src/model/user/Note';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
// -
// -------------------- Types&Consts
export interface INoteListFilter {
  objectId?: number; // for successful filter by parametar objectId parametar type must be included
  type?: string;
  text?: string;
}

export interface INoteCreatePayload {
  text: string;
  object?: IIdRef<string>;
  objectType?: IIdRef<NoteObjectTypeEnum>;
}

export interface INoteListFileCreatePayload {
  object: IIdRef<string>;
  type: IIdRef<NoteObjectTypeEnum>;
}

// List filter ID
const NOTE_LIST_FILTER = '@@NOTE_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns UserNote from store. */
const getUserNote = (store: any): INote => store.UserNoteView;

/** Returns UserNote from store. */
const getUserNotes = (store: any): ICollectionData<INote> => store.notes;

/** Returns notes filter. */
const getNoteListFilter = (store: any): INoteListFilter => ListFilterBusinessStore.selectors.getListFilter(store, NOTE_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  USER_NOTE_LIST_FETCH: 'USER_NOTE_LIST_FETCH',
  USER_NOTE_LIST_LOAD: 'USER_NOTE_LIST_LOAD',
  USER_NOTE_LIST_CLEAR: 'USER_NOTE_LIST_CLEAR',
  USER_NOTE_LIST_FILE_CREATE: 'USER_NOTE_LIST_FILE_CREATE',
  USER_NOTE_FETCH: 'USER_NOTE_FETCH',
  USER_NOTE_LOAD: 'USER_NOTE_LOAD',
  USER_NOTE_CLEAR: 'USER_NOTE_CLEAR',
  USER_NOTE_CREATE: 'USER_NOTE_CREATE',
  USER_NOTE_UPDATE: 'USER_NOTE_UPDATE',
  USER_NOTE_DELETE: 'USER_NOTE_DELETE',
};

/** Fetch note list by filter */
const fetchUserNoteList = (noteListFilter: INoteListFilter, size: number, page: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<INoteListFilter>> => {
  return {
    type: Actions.USER_NOTE_LIST_FETCH,
    payload: {
      filter: noteListFilter,
      size,
      page,
      sort,
    },
  };
};

/** Load note list to the store */
const loadUserNoteList = (data: ICollectionData<INote>): IPayloadAction<ICollectionData<INote>> => {
  return {
    type: Actions.USER_NOTE_LIST_LOAD,
    payload: data,
  };
};

/** Clear note list from store. Eg. when leaving view. */
const clearUserNoteList = (): ILemonAction => {
  return {
    type: Actions.USER_NOTE_LIST_CLEAR,
  };
};

/** create note list file */
const createNoteListFile = (data: INoteListFileCreatePayload): IPayloadAction<INoteListFileCreatePayload> => {
  return {
    type: Actions.USER_NOTE_LIST_FILE_CREATE,
    payload: data,
  };
};

const storeNoteListFilter = (listFilter: INoteListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(NOTE_LIST_FILTER, listFilter);
};

const clearNoteListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(NOTE_LIST_FILTER);
};

/** Fetch UserNote by ID. */
const fetchUserNote = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_NOTE_FETCH,
    payload: params,
  };
};

/** Load UserNote to store. */
const loadUserNote = (data: INote): IPayloadAction<INote> => {
  return {
    type: Actions.USER_NOTE_LOAD,
    payload: data,
  };
};

/** Create new UserNote. */
const createUserNote = (data: INoteCreatePayload): IPayloadAction<INoteCreatePayload> => {
  return {
    type: Actions.USER_NOTE_CREATE,
    payload: data,
  };
};

/** Delete note by id */

const deleteUserNote = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_NOTE_DELETE,
    payload: {
      id,
    },
  };
};

/** Update note */

const updateUserNote = (note: INote): IPayloadAction<IIdDataPayload<INote>> => {
  return {
    type: Actions.USER_NOTE_UPDATE,
    payload: {
      id: note.id,
      data: note,
    },
  };
};

// -
// -------------------- Side-effects

const fetchUserNoteListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<INoteListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_NOTE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Note')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserNoteList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_NOTES.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching note list', error);
      return o;
    })
  );
};

const createNoteListFileEffect = (action$: Observable<IPayloadAction<INoteListFileCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_NOTE_LIST_FILE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Note')
        .createMethod('export', payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_NOTES.ERROR', 'NOTE_LIST.CREATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating note list file', error);
      return o;
    })
  );
};

const fetchUserNoteEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_NOTE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Note')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserNote(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_NOTE_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_NOTES.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching UserNote', error);
      return o;
    })
  );
};

const createUserNoteEffect = (action$: Observable<IPayloadAction<INoteCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_NOTE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Note')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserNote(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_NOTES.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating UserNote', error);
      return o;
    })
  );
};

const deleteUserNoteEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_NOTE_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('Note')
        .deleteEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_NOTES.ERROR', 'GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting user note', error);
      return o;
    })
  );
};

const updateUserNoteEffect = (action$: Observable<IPayloadAction<IIdDataPayload<INote>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_NOTE_UPDATE;
    }),

    startGlobalProgress(),
    mergeMap((action) => {
      const { id, data } = action.payload;
      return EntityApiServiceRegistry.getService('Note')
        .updateEntity(id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USER_NOTES.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating user note', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers
const notes = (state: ICollectionData<INote> | null = null, action: IPayloadAction<ICollectionData<INote>>) => {
  if (action.type === Actions.USER_NOTE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_NOTE_LIST_CLEAR) {
    return null;
  }

  return state;
};

const UserNoteView = (state: INote | null = null, action: IPayloadAction<INote>) => {
  if (action.type === Actions.USER_NOTE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_NOTE_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const UserNoteBusinessStore = {
  actions: {
    fetchUserNoteList,
    loadUserNoteList,
    clearUserNoteList,
    storeNoteListFilter,
    clearNoteListFilter,
    createNoteListFile,
    fetchUserNote,
    createUserNote,
    updateUserNote,
    deleteUserNote,
  },

  selectors: {
    getUserNote,
    getUserNotes,
    getNoteListFilter,
  },

  effects: {
    fetchUserNoteListEffect,
    createNoteListFileEffect,
    fetchUserNoteEffect,
    createUserNoteEffect,
    updateUserNoteEffect,
    deleteUserNoteEffect,
  },

  reducers: {
    UserNoteView,
    notes,
  },
};

// --
// export business store

export default UserNoteBusinessStore;

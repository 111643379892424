import { IUpdateListFunction } from '@src/components/common/collectionParams/useCollectionState';
import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ReportListView from '@src/components/report/list/ReportListView';
import CourseReportModal from '@src/components/report/modal/CourseReportModal';
import { IReport, IReportTypeEnum } from '@src/model/report/Report';
import { ICollectionData } from '@src/service/business/common/types';
import { ICourseReportListFilter } from '@src/service/business/courses/courseViewBusinessStore';
import { IReportCreatePayload } from '@src/service/business/reports/reportsBusinessStore';
import React, { useCallback, useState } from 'react';

// -- Prop types
// ----------

export interface IReportListModalViewOwnProps {
  courseId?: string;
  reportList: ICollectionData<IReport>;
  onCreateReport: (data: IReportCreatePayload) => void;
  onDeleteReport: (data: IReport) => void;
  onSortChange: (sort?: string[] | undefined) => void;
  onPageChange: (page?: number | undefined, size?: number | undefined) => void;
  onReload: IUpdateListFunction<ICourseReportListFilter>;
}

type IReportListModalViewProps = IReportListModalViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const ReportListModalView: React.FC<IReportListModalViewProps> = (props: IReportListModalViewProps) => {
  const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);
  const handleReportSubmit = useCallback(
    (data: IReportCreatePayload) => {
      setIsReportModalVisible(false);
      props.onCreateReport(data);
    },
    [props.onCreateReport]
  );

  const handleButtonClick = useCallback(() => {
    props.courseId ? handleReportSubmit({ reportType: IReportTypeEnum.COURSE, filters: { courseId: props.courseId } }) : setIsReportModalVisible(true);
  }, [props.courseId, handleReportSubmit]);

  return (
    <AppContent>
      {isReportModalVisible && <CourseReportModal onCancel={() => setIsReportModalVisible(false)} onSubmit={handleReportSubmit} />}
      <ReportListView courseId={props.courseId} reportList={props.reportList} onButtonClick={handleButtonClick} onDeleteReport={props.onDeleteReport} onSortChange={props.onSortChange} onPageChange={props.onPageChange} onReload={props.onReload} />
    </AppContent>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IReportListModalViewOwnProps>(ReportListModalView as any);

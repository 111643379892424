import SkillLevelForm from '@src/components/codebook/form/SkillLevelForm';
import CodebookListView from '@src/components/codebook/view/CodebookList';
import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';
import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkillLevel } from '@src/model/skillgroup/SkillLevelClassification';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SkillLevelBusinessStore, { ISkillLevelCreatePayload, ISkillLevelListFilter } from '@src/service/business/skilllevel/SkillLevelBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const VIEW_NAME = '@@SKILL_LEVEL_CODEBOOK_LIST';
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');

// -- Prop types
// ----------
export interface ISkillLevelCodebookContainerOwnProps {}

export interface ISkillLevelCodebookContainerStateProps {
  skillLevelList: ICollectionData<ISkillLevel>;
}

export interface ISkillLevelCodebookContainerDispatchProps {
  fetchSkillLevelList: (params: ICollectionFetchPayload<ISkillLevelListFilter>) => ITrackableAction;
  updateSkillLevel: (data: ISkillLevel) => ITrackableAction;
  createSkillLevel: (data: ISkillLevelCreatePayload) => ITrackableAction;
}

type ISkillLevelCodebookContainerProps = ISkillLevelCodebookContainerOwnProps & ISkillLevelCodebookContainerStateProps & ISkillLevelCodebookContainerDispatchProps & IWithLocalizeOwnProps;
// -- Component
// ----------

/** Skill levels codebook container */
const SkillLevelCodebookContainer = (props: ISkillLevelCodebookContainerProps) => {
  const [skillLevelModalsState, toggleSkillLevelModals] = useEntityModalsState<ISkillLevel>();
  const [, updateCollectionParams, onUpdateList] = useCollectionState<ISkillLevelListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchSkillLevelList,
    initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
  });

  const handleSkillLevelUpdateSubmit = useCallback(
    (data: ISkillLevel) => {
      props
        .updateSkillLevel(data)
        .track()
        .subscribe(
          // success
          () => {
            onUpdateList();
            toggleSkillLevelModals.onCloseUpdate();
          }
        );
    },
    [props.updateSkillLevel, onUpdateList, toggleSkillLevelModals]
  );

  const handleSkillLevelCreateSubmit = useCallback(
    (data: ISkillLevelCreatePayload) => {
      props
        .createSkillLevel(data)
        .track()
        .subscribe(
          // success
          () => {
            onUpdateList();
            toggleSkillLevelModals.onCloseCreate();
          }
        );
    },
    [props.updateSkillLevel, onUpdateList, toggleSkillLevelModals]
  );

  /** Skill level list is completed when 5 levels are created (bussiness logic) */
  const isSkillLevelListCompleted = props.skillLevelList?.content.length === 5;
  return (
    <React.Fragment>
      {props.skillLevelList && (
        <CodebookListView<ISkillLevel>
          title={props.translate(`CODEBOOK.TITLE_LABEL.${CodebookEnum.SKILL_LEVEL}`)}
          buttonLabel={props.translate('SKILL_LEVEL.CREATE_BUTTON_LABEL')}
          showBackColumn={true}
          canEdit={true}
          createDisabled={isSkillLevelListCompleted}
          codebookItemList={props.skillLevelList}
          onCreateCodebookItemClick={toggleSkillLevelModals.onOpenCreate}
          onEditCodebookItemClick={toggleSkillLevelModals.onOpenUpdate}
          onPageChange={updateCollectionParams.onPageChange}
        />
      )}
      {(skillLevelModalsState.isUpdateModalVisible || skillLevelModalsState.isCreateModalVisible) && (
        <SkillLevelForm value={skillLevelModalsState.selectedEntity} onSkillLevelUpdateSubmit={handleSkillLevelUpdateSubmit} onSkillLevelCreateSubmit={handleSkillLevelCreateSubmit} onCancel={toggleSkillLevelModals.onCloseUpdate} />
      )}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillLevelCodebookContainerOwnProps): ISkillLevelCodebookContainerStateProps => ({
  skillLevelList: SkillLevelBusinessStore.selectors.getSkillLevelList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISkillLevelCodebookContainerDispatchProps => ({
  fetchSkillLevelList: (params: ICollectionFetchPayload<ISkillLevelListFilter>) => createTrackableAction(dispatch(SkillLevelBusinessStore.actions.fetchSkillLevelList(params))),
  updateSkillLevel: (data: ISkillLevel) => createTrackableAction(dispatch(SkillLevelBusinessStore.actions.updateSkillLevel(data))),
  createSkillLevel: (data: ISkillLevelCreatePayload) => createTrackableAction(dispatch(SkillLevelBusinessStore.actions.createSkillLevel(data))),
});

export default connect<ISkillLevelCodebookContainerStateProps, ISkillLevelCodebookContainerDispatchProps, ISkillLevelCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(SkillLevelCodebookContainer as any));

import CourseExamInstanceContainer from '@src/components/course/exam/CourseExamInstanceContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type ICourseExamInstancePageProps = WithRouterProps;

const CourseExamInstancePage = (props: ICourseExamInstancePageProps) => <CourseExamInstanceContainer courseId={props.params.courseId} examInstanceId={props.params.examId} />;

export default withRouter<ICourseExamInstancePageProps>(CourseExamInstancePage);

import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseProgressBar from '@src/components/course/common/CourseProgressBar';
import CourseProgressInfo from '@src/components/course/common/CourseProgressInfo';
import { ICourse } from '@src/model/course/Course';

// -- Prop types
// ----------

export interface ICourseProgressViewOwnProps {
  course: ICourse;
}
type ICourseProgressViewProps = ICourseProgressViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display course progress bar and info */
const CourseProgressView: React.FC<ICourseProgressViewProps> = (props: ICourseProgressViewProps) => {
  return (
    <React.Fragment>
      <CourseProgressInfo progress={props.course.progress} exam={props.course?.exam} />
      <CourseProgressBar course={props.course} />
    </React.Fragment>
  );
};

export default withLocalize<ICourseProgressViewOwnProps>(CourseProgressView as any);

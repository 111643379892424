import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { CourseStatusEnum } from '@src/model/course/Course';
import { UserRoleEnum } from '@src/model/user/UserRole';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Button, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';

export interface CourseStatusActionsViewOwnProps {
  status: CourseStatusEnum;

  onUpdateCourseStatus: (data: CourseStatusEnum) => void;
}

type ICourseStatusActionsViewProps = CourseStatusActionsViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

const CourseStatusActionsView: React.FC<ICourseStatusActionsViewProps> = (props: ICourseStatusActionsViewProps) => {
  const handleUpdateCourseStatus = useCallback(
    (statusId: CourseStatusEnum) => {
      confirmationDialog({
        title: props.translate('COMMON.CONFIRMATION_TITLE'),
        onConfirm: () => props.onUpdateCourseStatus(statusId),
      });
    },
    [props.onUpdateCourseStatus]
  );
  const isCurrentUserAdmin = useMemo(() => RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]), [RoleUtils.allowedRoles]);
  const canArchiveCourse = useMemo(() => RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.PUBLISHER]), [RoleUtils.allowedRoles]);
  const renderCourseStatusButton = useCallback(
    (courseStatus: CourseStatusEnum) => {
      return <Button onClick={() => handleUpdateCourseStatus(courseStatus)}>{props.translate(`COURSE_UPDATE_STATUS.${courseStatus}_BUTTON`)}</Button>;
    },
    [handleUpdateCourseStatus]
  );
  return (
    <React.Fragment>
      {props.status === CourseStatusEnum.IN_CREATION && (
        <Space size={'small'}>
          {renderCourseStatusButton(CourseStatusEnum.CREATION_FINISHED)}
          {canArchiveCourse && renderCourseStatusButton(CourseStatusEnum.ARCHIVED)}
        </Space>
      )}
      {props.status === CourseStatusEnum.CREATION_FINISHED && (
        <Space size={'small'}>
          {renderCourseStatusButton(CourseStatusEnum.IN_CREATION)}
          {renderCourseStatusButton(CourseStatusEnum.PUBLISHED)}
          {canArchiveCourse && renderCourseStatusButton(CourseStatusEnum.ARCHIVED)}
        </Space>
      )}
      {props.status === CourseStatusEnum.PUBLISHED && canArchiveCourse && renderCourseStatusButton(CourseStatusEnum.ARCHIVED)}
      {props.status === CourseStatusEnum.ARCHIVED && isCurrentUserAdmin && renderCourseStatusButton(CourseStatusEnum.PUBLISHED)}
    </React.Fragment>
  );
};

export default withLocalize<CourseStatusActionsViewOwnProps>(withRoles(CourseStatusActionsView as any));

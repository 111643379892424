import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { CodebookEnum } from '@src/components/codebook/view/SuperAdminCodebookListView';
import EditableWrapper from '@src/components/common/container/EditableWrapper';

import { validateRequired } from '@src/components/common/form/validation';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { availableStyleThemes } from '@src/components/common/theme/styleThemePathResolver';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import { IFile } from '@src/model/file/File';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import { ITenantConfigurationUpdatePayload } from '@src/service/business/tenant/tenantConfigurationBusinessService';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Row, Select, Switch, Form, Input, Divider, Typography } from 'antd';
import Col, { ColProps } from 'antd/lib/col';
import React, { useCallback, useMemo, useState } from 'react';

// -- Const
// ----------
const GRID_LAYOUT_LABEL_LAYOUT: ColProps = { xs: 24, sm: 10, md: 8 };
const GRID_LAYOUT_WRAPPER_LAYOUT: ColProps = { xs: 24, sm: 12, md: 12 };
const Option = Select.Option;
const surveyQuestionList: string[] = AppConfigService.getValue('quizzler.questionTypes.surveyQuestionList');
const examQuestionList: string[] = AppConfigService.getValue('quizzler.questionTypes.examQuestionList');

// -- Prop types
// ----------

export interface ITenantConfigurationEditOwnProps {
  configuration: ITenantConfiguration;

  onCancel?: () => void;
  onSubmit?: (data: ITenantConfigurationUpdatePayload) => void;
}

type ITenantConfigurationEditProps = ITenantConfigurationEditOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithPathResolverOwnProps;

const TenantConfigurationEditForm: React.FC<ITenantConfigurationEditProps> = (props: ITenantConfigurationEditProps) => {
  const [form] = Form.useForm<ITenantConfiguration>();
  const themeList = useMemo(() => availableStyleThemes().sort(), []);
  const [openedEditor, setOpenedEditor] = useState<boolean>(false);

  const handleFinish = useCallback(
    (values: ITenantConfiguration) => {
      const tenantConfiguration: ITenantConfigurationUpdatePayload = {
        ...values,
      };
      props.onSubmit?.(tenantConfiguration);
    },
    [props.onSubmit]
  );

  /** Set image form value and mark field as touched. */
  const setImageFormValue = (value: string | undefined) => {
    const namePath = ['configuration', 'secondaryLogoFileId'];
    form.setFields([{ name: namePath, touched: true, value }]);
  };

  /** Returns resolved image file path if there is image value, undefined otherwise. */
  const resolveFormImagePath = (fieldName: string): string | undefined => {
    const namePath = ['configuration', 'secondaryLogoFileId'];
    if (form.getFieldValue(namePath)) {
      return props.resolveApiFilePath(form.getFieldValue(namePath));
    }
    return;
  };

  // --- Image handlers

  const handleImageEditClick = useCallback(() => setOpenedEditor(true), [setOpenedEditor]);

  const handleImageRemoveClick = useCallback(() => setImageFormValue(undefined), [setImageFormValue]);

  const handleImageClose = useCallback(() => setOpenedEditor(false), [setOpenedEditor]);

  const handleImageSubmit = useCallback(
    (file: IFile) => {
      setImageFormValue(file.id);
      setOpenedEditor(false);
    },
    [setImageFormValue, setOpenedEditor]
  );

  const handleCancel = useCallback(() => {
    props.onCancel?.();
  }, [props.onCancel]);
  return (
    <Form form={form} onFinish={handleFinish} initialValues={props.configuration}>
      {/* --- form buttons --- */}
      <Form.Item shouldUpdate={true}>
        {() => {
          return (
            <Row justify="end">
              <Col className="text-center">
                <Button onClick={handleCancel}>{props.translate('COMMON.ACTION_CANCEL')}</Button>
                &nbsp;
                <Button type="primary" htmlType="submit" disabled={!form.isFieldsTouched()}>
                  {props.translate('COMMON.ACTION_SAVE')}
                </Button>
              </Col>
            </Row>
          );
        }}
      </Form.Item>

      {/** HZZ
       * --- Enable external education applications ---
       * --- Bussines profile tab ---
       * --- Enable notz ---
       * --- Enable external educations ---
       */}
      <Typography.Title level={2}> {props.translate('TENANT_CONFIGURATION_VIEW.HZZ_TITLE')} </Typography.Title>
      <Form.Item name={['configuration', 'enableExternalEducationApplications']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATION_APPLICATIONS_LABEL')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableOrganizations']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_BUSINESS_PROFILE')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableNotz']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_NOTZ')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableExternalEducations']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATIONS_TEMPLATE_LABEL')}>
        <Switch />
      </Form.Item>
      <Divider />

      {/** Import user
       * --- Hrnet user import ---
       * --- Timun user import ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.USER_IMPORT_TITLE')} </Typography.Title>
      <Form.Item name={['configuration', 'enableHrnetUserImport']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_HRNET_USER_IMPORT')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableTimunUserImport']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_TIMUN_USER_IMPORT')}>
        <Switch />
      </Form.Item>
      <Divider />

      {/** Courses
       * --- Enable courses ---
       * --- Enable SCORM courses ---
       * --- Enable organization user groups ---
       * --- Comments tab ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.COURSES_TITLE')} </Typography.Title>
      <Form.Item name={['configuration', 'enableCourses']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_COURSES_LABEL')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableScormCourses']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_SCORM_COURSES_LABEL')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableOrganizationGroupCourses']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ORGANIZATION_GROUP_COURSES_LABEL')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableComments']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_COMMENTS')}>
        <Switch />
      </Form.Item>
      <Divider />

      {/**  Skills
       * --- Enable skills  ---
       * --- Enable skills gap ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.SKILLS_TITLE')} </Typography.Title>
      <Form.Item name={['configuration', 'enableSkills']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILLS')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableSkillsGap']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_RECOMMEDITION')}>
        <Switch />
      </Form.Item>
      <Divider />

      {/**  Codebooks
       * --- Codebooks tab ---
       * --- Admin codebook list ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.CODEBOOKS_TITLE')} </Typography.Title>
      <Form.Item name={['configuration', 'adminCodebook', 'enableCodebooks']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_CODEBOOKS')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'adminCodebook', 'adminCodebookList']} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ADMIN_CODEBOOK_LIST')}>
        <Select mode="multiple" allowClear={true} showArrow={true}>
          {Object.values(CodebookEnum).map((item) => (
            <Option value={item} key={item}>
              {props.translate(`CODEBOOK.TITLE_LABEL.${item}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Divider />
      {/** Groups
       * --- Enable user groups ---
       * --- Enable organization groups ---
       *  ---Enable organization graph ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.GROUPS_TITLE')} </Typography.Title>
      <Form.Item name={['configuration', 'enableUserGroups']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUPS_LABEL')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableOrganizationGroups']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ORGANIZATION_GROUP_LABEL')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'enableOrganizationGraph']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ORGANIZATION_GRAPH_LABEL')}>
        <Switch />
      </Form.Item>
      <Divider />
      {/** Quizzler
       * --- Enable surveys ---
       * --- Exam question list ---
       * --- Suvey question list ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.QUIZZLER_TITLE')} </Typography.Title>
      <Form.Item name={['configuration', 'enableSurveys']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SURVEYS')}>
        <Switch />
      </Form.Item>
      <Form.Item name={['configuration', 'examQuestionList']} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.EXAM_QUESTION_LIST')}>
        <Select mode="multiple" allowClear={true} showArrow={true}>
          {examQuestionList.map((questionType) => (
            <Option key={questionType} value={questionType}>
              {props.translate(`QUESTION_TYPE.${questionType}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name={['configuration', 'surveyQuestionList']} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.SURVEY_QUESTION_LIST')}>
        <Select mode="multiple" allowClear={true} showArrow={true}>
          {surveyQuestionList.map((questionType) => (
            <Option key={questionType} value={questionType}>
              {props.translate(`QUESTION_TYPE.${questionType}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Divider />
      {/** System
       * --- Style Theme ---
       * --- Enable multi locales ---
       * --- Enable dashboard ---
       * --- Enable user profile edit ---
       * --- Enable user first last name and oib edit ---
       * --- Enable external contents ---
       * --- Enable repository ---
       * --- Enable webinars ---
       * --- Video integration ---
       * --- Workpositions tab ---
       * --- Activities tab ---
       * --- Notifications tab ---
       * --- Email templates tab ---
       * --- LDAP sync ---
       */}
      <Typography.Title level={2}>{props.translate('TENANT_CONFIGURATION_VIEW.BASIC_SYSTEM_SETTINGS_TITLE')} </Typography.Title>
      <Form.Item name={['configuration', 'styleTheme']} rules={[{ required: true, validator: validateRequired }]} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.STYLE_THEME_LABEL')}>
        <Select>
          {themeList.map((themeName) => (
            <Select.Option key={themeName} value={themeName}>
              {themeName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.LOGO_IMAGE_LABEL')} shouldUpdate={true}>
        {() => {
          return (
            <React.Fragment>
              <Form.Item noStyle={true} name={['configuration', 'secondaryLogoFileId']}>
                <Input type="hidden" />
              </Form.Item>

              {/* avatar preview*/}
              <EditableWrapper onEdit={handleImageEditClick} onRemove={handleImageRemoveClick} showRemove={form.getFieldValue(['configuration', 'secondaryLogoFileId']) != null}>
                <LemonImage imagePath={resolveFormImagePath('secondaryLogoFileId')} fallbackImagePath={props.resolveImagePath('cover-placeholder.png')} className="timun-courseView__coverImage" />
              </EditableWrapper>

              {/* image uploader */}
              {openedEditor && <ImageUploadForm defaultImageLink={resolveFormImagePath('secondaryLogoFileId')} onSubmit={handleImageSubmit} onClose={handleImageClose} />}
            </React.Fragment>
          );
        }}
      </Form.Item>

      <Form.Item name={['configuration', 'enableLanguages']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_LOCALES_LABEL')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableDashboard']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_DASHBOARD_LABEL')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableUserProfileEdit']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_PROFILE_EDIT_LABEL')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableUserIdentificationDetailsEdit']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_IDENTIFICATION_DETAILS_EDIT_LABEL')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableExternalContents']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_CONTENTS_LABEL')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableRepository']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_REPOSITORY_LABEL')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableWebinars']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_WEBINARS_LABEL')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableIntegrationVideo']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_INTEGRATION_VIDEO')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableWorkpositions']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_WORKPOSITIONS')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableActivities']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_ACTIVITIES')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableNotifications']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_NOTIFICATIONS')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableEmails']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_EMAILS')}>
        <Switch />
      </Form.Item>

      <Form.Item name={['configuration', 'enableLDAP']} valuePropName={'checked'} labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_LDAP_SYNC')}>
        <Switch />
      </Form.Item>
      <Divider />

      {/* --- Configuration id --- */}
      <Form.Item noStyle={true} hidden={true} name={['id']}>
        <Input />
      </Form.Item>

      {/* --- form buttons --- */}
      <Form.Item shouldUpdate={true}>
        {() => {
          return (
            <Row justify="end">
              <Col className="text-center">
                <Button onClick={handleCancel}>{props.translate('COMMON.ACTION_CANCEL')}</Button>
                &nbsp;
                <Button type="primary" htmlType="submit" disabled={!form.isFieldsTouched()}>
                  {props.translate('COMMON.ACTION_SAVE')}
                </Button>
              </Col>
            </Row>
          );
        }}
      </Form.Item>
    </Form>
  );
};
export default withLocalize<ITenantConfigurationEditOwnProps>(withPathResolver(TenantConfigurationEditForm as any));

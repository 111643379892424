import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExternalEducationTemplateHelperUtils } from '@src/components/externaleducation/common/ExternalEducationTemplateHelperUtils';
import ExternalEducationTemplateView from '@src/components/externaleducation/ExternalEducationTemplateView';
import ExternalEducationTemplateFormModal from '@src/components/externaleducation/form/ExternalEducationTemplateFormModal';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import activityBusinessStore, { IActivityParticipantCreatePayload, IActivityPhaseCreatePayload } from '@src/service/business/examtemplates/activityBusinessStore';
import ExternalEducationTemplateBusinessStore from '@src/service/business/externaleducations/ExternalEducationTemplateBusinessStore';
import { IFileListFilter } from '@src/service/business/files/FileListBusinessStore';
import { IFileListsByType } from '@src/service/business/files/util';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface IExternalEducationTemplateContainerOwnProps {
  externalEducationTemplateId: string;
  openedTab: string;
}
export interface IExternalEducationTemplateContainerStateProps {
  externalEducationTemplate: IExternalEducationTemplate;
  currentUser: IUserInfo;
  externalEducationTemplateFileList: IFileListsByType;
}
export interface IExternalEducationTemplateContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchExternalEducationTemplate: () => ITrackableAction;
  updateExternalEducationTemplate: (data: IExternalEducationTemplate) => ITrackableAction;

  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => ITrackableAction;
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => ITrackableAction;

  fetchExternalEducationTemplateFileList: (listFilter?: IFileListFilter) => ITrackableAction;
  addExternalEducationTemplateFile: (data: IFile[]) => ITrackableAction;
  removeExternalEducationTemplateFile: (data: IFile[]) => ITrackableAction;
}
type IExternalEducationTemplateContainerProps = IExternalEducationTemplateContainerOwnProps & IExternalEducationTemplateContainerStateProps & IExternalEducationTemplateContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education template information view page */
const ExternalEducationTemplateContainer = (props: IExternalEducationTemplateContainerProps) => {

  const [templateModalsState, toggleTemplateModals] = useEntityModalsState<IExternalEducationTemplate>();

  useEffect(() => {
    props.fetchExternalEducationTemplate();
    props.fetchExternalEducationTemplateFileList();
  }, [props.externalEducationTemplateId]);

  const handleActivityPhaseChange = useCallback((nextPhaseId: ExternalEducationTemplateActivityPhaseEnum) => {
    if (props.externalEducationTemplate.activity) {
      props.updateActivityNextPhase(props.externalEducationTemplate.activity.id, { phaseId: nextPhaseId })
        .track()
        .subscribe(
          // success
          () => props.fetchExternalEducationTemplate()
        );
    }
  }, [props.externalEducationTemplate]);

  const handleAddCoordinator = useCallback(() => {
    const participant: IActivityParticipantCreatePayload = {
      userId: props.currentUser.id,
      participantRole: ParticipantRoleEnum.EVALUATOR,
    };
    if (props.externalEducationTemplate.activity) {
      props.addActivityParticipant(props.externalEducationTemplate.activity.id, participant)
        .track()
        .subscribe((res) => {
          props.fetchExternalEducationTemplate();
        });
    }
  }, [props.externalEducationTemplate, props.currentUser.id]);

  const handleUpdate = (template: IExternalEducationTemplate) => {
    props.updateExternalEducationTemplate(template).track().subscribe(
      // success
      () => {
        props.reportMessage({ message: props.translate('EXTERNAL_EDUCATION_TEMPLATE.UPDATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        toggleTemplateModals.onCloseUpdate();
      }
    );
  };

  const handleEducationTemplateEdit = () => {
    toggleTemplateModals.onOpenUpdate(props.externalEducationTemplate);
  };

  const handleFileUpload = (data: IFile[]) => {
    props.addExternalEducationTemplateFile(data).track().subscribe((res) => {
      props.fetchExternalEducationTemplateFileList();
    });
  };
  const handleFileRemove = (data: IFile[]) => {
    props.removeExternalEducationTemplateFile(data).track().subscribe((res) => {
      props.fetchExternalEducationTemplateFileList();
    });
  };

  return (
    <React.Fragment>
      {props.externalEducationTemplate &&
        <ExternalEducationTemplateView
          currentUser={props.currentUser}
          openedTab={props.openedTab}
          externalEducationTemplate={props.externalEducationTemplate}
          onActivityPhaseChange={handleActivityPhaseChange}
          onCoordinatorAdd={handleAddCoordinator}
          onVerificationSubmit={props.updateExternalEducationTemplate}
          canEdit={ExternalEducationTemplateHelperUtils.isEducationTemplateEditable(props.externalEducationTemplate)}
          onUpdate={toggleTemplateModals.onOpenUpdate}
          files={props.externalEducationTemplateFileList}
          onFileUpload={handleFileUpload}
          onFileRemove={handleFileRemove}
          onExternalEducationTemplateEdit={handleEducationTemplateEdit}
        />
      }

      {templateModalsState.isUpdateModalVisible && props.externalEducationTemplate &&
        <ExternalEducationTemplateFormModal
          externalEducationTemplate={props.externalEducationTemplate}
          educationProviderId={props.externalEducationTemplate.educationProvider.id}
          onCancel={toggleTemplateModals.onCloseUpdate}
          onUpdate={handleUpdate} />
      }
    </React.Fragment>
  );
};


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationTemplateContainerOwnProps): IExternalEducationTemplateContainerStateProps => ({
  externalEducationTemplate: ExternalEducationTemplateBusinessStore.selectors.getExternalEducationTemplate(state),
  externalEducationTemplateFileList: ExternalEducationTemplateBusinessStore.selectors.getExternalEducationTemplateFileList(state, ownProps.externalEducationTemplateId),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IExternalEducationTemplateContainerOwnProps): IExternalEducationTemplateContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchExternalEducationTemplate: () => createTrackableAction(dispatch(ExternalEducationTemplateBusinessStore.actions.fetchExternalEducationTemplate(ownProps.externalEducationTemplateId))),
  updateExternalEducationTemplate: (data: IExternalEducationTemplate) => createTrackableAction(dispatch(ExternalEducationTemplateBusinessStore.actions.updateExternalEducationTemplate(data))),
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityPhase(id, data))),
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityParticipant(id, data))),
  fetchExternalEducationTemplateFileList: (listFilter?: IFileListFilter) => createTrackableAction(dispatch(ExternalEducationTemplateBusinessStore.actions.fetchExternalEducationTemplateFileList(ownProps.externalEducationTemplateId, listFilter))),
  addExternalEducationTemplateFile: (data: IFile[]) => createTrackableAction(dispatch(ExternalEducationTemplateBusinessStore.actions.addExternalEducationTemplateFile(ownProps.externalEducationTemplateId, data))),
  removeExternalEducationTemplateFile: (data: IFile[]) => createTrackableAction(dispatch(ExternalEducationTemplateBusinessStore.actions.removeExternalEducationTemplateFile(ownProps.externalEducationTemplateId, data))),
});

export default connect<IExternalEducationTemplateContainerStateProps, IExternalEducationTemplateContainerDispatchProps, IExternalEducationTemplateContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ExternalEducationTemplateContainer as any));

import { ISkillLevel } from '@src/model/skillgroup/SkillLevelClassification';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export type ISkillLevelCreatePayload = Pick<ISkillLevel, 'name' | 'active'>;

export interface ISkillLevelListFilter {
  level: 1 | 2 | 3 | 4 | 5;
  name?: string;
  active?: boolean;
}

// List filter ID
const SKILL_LEVEL_LIST_FILTER = '@@SKILL_LEVEL_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns skill level list from store. */
const getSkillLevelList = (store: any): ICollectionData<ISkillLevel> => store.skillLevelList;

/** Returns skill level list filter. */
const getSkillLevelListFilter = (store: any): ISkillLevelListFilter => ListFilterBusinessStore.selectors.getListFilter(store, SKILL_LEVEL_LIST_FILTER);

// -
// -------------------- Actions
const Actions = {
  SKILL_LEVEL_LIST_FETCH: 'SKILL_LEVEL_LIST_FETCH',
  SKILL_LEVEL_LIST_LOAD: 'SKILL_LEVEL_LIST_LOAD',
  SKILL_LEVEL_LIST_CLEAR: 'SKILL_LEVEL_LIST_CLEAR',
  SKILL_LEVEL_UPDATE: 'SKILL_LEVEL_UPDATE',
  SKILL_LEVEL_CREATE: 'SKILL_LEVEL_CREATE',
};

/** Fetch skill level list by filter */
const fetchSkillLevelList = (params: ICollectionFetchPayload<ISkillLevelListFilter>): IPayloadAction<ICollectionFetchPayload<ISkillLevelListFilter>> => {
  return {
    type: Actions.SKILL_LEVEL_LIST_FETCH,
    payload: params,
  };
};

/** Load skill level list to the store */
const loadSkillLevelList = (data: ICollectionData<ISkillLevel>): IPayloadAction<ICollectionData<ISkillLevel>> => {
  return {
    type: Actions.SKILL_LEVEL_LIST_LOAD,
    payload: data,
  };
};

/** Clear skill level list from store. Eg. when leaving view. */
const clearSkillLevelList = (): ILemonAction => {
  return {
    type: Actions.SKILL_LEVEL_LIST_CLEAR,
  };
};

/** Update skill level by id */
const updateSkillLevel = (data: ISkillLevel): IPayloadAction<IIdDataPayload<ISkillLevel>> => {
  return {
    type: Actions.SKILL_LEVEL_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

/** Create skill level by id */
const createSkillLevel = (data: ISkillLevelCreatePayload): IPayloadAction<ISkillLevelCreatePayload> => {
  return {
    type: Actions.SKILL_LEVEL_CREATE,
    payload: data,
  };
};

const storeSkillLevelListFilter = (listFilter: ISkillLevelListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(SKILL_LEVEL_LIST_FILTER, listFilter);
};

const clearSkillLevelListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(SKILL_LEVEL_LIST_FILTER);
};

// -
// -------------------- Side-effects
const fetchSkillLevelListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISkillLevelListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_LEVEL_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('SkillLevel')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSkillLevelList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SKILL_LEVEL.ERROR', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching skill level list', error);
      return o;
    })
  );
};

const updateSkillLevelEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ISkillLevel>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_LEVEL_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('SkillLevel')
        .updateEntity(id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SKILL_LEVEL.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating skill level', error);
      return o;
    })
  );
};

const createSkillLevelEffect = (action$: Observable<IPayloadAction<ISkillLevelCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_LEVEL_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { payload } = action;

      return EntityApiServiceRegistry.getService('SkillLevel')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SKILL_LEVEL.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error creating skill level', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers
const skillLevelList = (state: ICollectionData<ISkillLevel> | null = null, action: IPayloadAction<ICollectionData<ISkillLevel>>) => {
  if (action.type === Actions.SKILL_LEVEL_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SKILL_LEVEL_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store
export const SkillLevelBusinessStore = {
  actions: {
    fetchSkillLevelList,
    clearSkillLevelList,
    updateSkillLevel,
    storeSkillLevelListFilter,
    clearSkillLevelListFilter,
    createSkillLevel,
  },

  selectors: {
    getSkillLevelList,
    getSkillLevelListFilter,
  },

  effects: {
    fetchSkillLevelListEffect,
    updateSkillLevelEffect,
    createSkillLevelEffect,
  },

  reducers: {
    skillLevelList,
  },
};

// --
// export business store
export default SkillLevelBusinessStore;

import React from 'react';

import EducationCard from '@src/components/common/card/EducationCard';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseCoverImage from '@src/components/course/common/CourseCoverImage';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseProgressBar from '@src/components/course/common/CourseProgressBar';
import { ICourse } from '@src/model/course/Course';
import { Empty } from 'antd';

// -- Prop types
// ----------

export interface ITraineeLastCourseOwnProps {
  lastCourse?: ICourse;
}

type ITraineeLastCourseProps = ITraineeLastCourseOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component display card with the last watched course ... */
class TraineeLastCourse extends React.Component<ITraineeLastCourseProps> {
  render = () => {
    return this.props.lastCourse ? (
      <EducationCard
        type="COURSE"
        education={this.props.lastCourse}
        currentLecture={CourseHelperUtils.getCurrentLecture(this.props.lastCourse)}
        getPath={CourseHelperUtils.getTraineePath}
        cardCover={<CourseCoverImage coverImageUrl={this.props.lastCourse.coverImageUrl} />}
        cardDescription={<CourseProgressBar course={this.props.lastCourse} />}
      />
    ) : (
      <Empty description={this.props.translate('COURSE_LIST_STARTED.NO_DATA')} />
    );
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<ITraineeLastCourseOwnProps>(TraineeLastCourse as any);

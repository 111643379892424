import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TimunRadarChart, { IChartDataItem } from '@src/components/user/statistics/TimunRadarChart';
import { IUserSkillEvaluation } from '@src/model/user/UserSkillEvaluation';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { Typography } from 'antd';
import React from 'react';

export interface IUserSkillLevelChartOwnProps {
  workPosition?: IWorkPosition;
  userSkillEvaluationList?: IUserSkillEvaluation[];
}

type IUserSKillLevelChartProps = IUserSkillLevelChartOwnProps & IWithLocalizeOwnProps;

class UserSkillLevelChart extends React.Component<IUserSKillLevelChartProps> {
  render = () => {
    return <div className="timun-userView__skillEvaluationsContainer">
      {(this.props.userSkillEvaluationList && this.props.userSkillEvaluationList.length > 0) && <React.Fragment>
        &nbsp;
        <Typography.Title level={3}>{this.props.translate('USER_SKILL_EVALUATION.RADAR_CHART_TITLE')}</Typography.Title>
        <TimunRadarChart chartData={this.getChartData()}/>
      </React.Fragment>}
    </div>;
  };

  getUserSkillEvaluations = (): IChartDataItem[] => {
    const skills = this.props.userSkillEvaluationList?.map(({ skillLevelClassification, user }) => {
      return {
        title: skillLevelClassification.skill.title,
        level: skillLevelClassification.skillLevel.level,
        series: `${user.firstName} ${user.lastName}`,
      };
    });

    return skills ?? [];
  };

  getWorkPositionSkills = (): IChartDataItem[] => {
    const skills = this.props.workPosition?.skillLevelClassifications?.map(({ skill, skillLevel }) => {
      return {
        title: skill.title,
        level: skillLevel.level,
        series: this.props.workPosition?.name ?? '',
      };
    });
    return skills ?? [];
  };

  getChartData = (): IChartDataItem[][] => {
    return [this.getWorkPositionSkills(), this.getUserSkillEvaluations()];
  };
}

export default withLocalize<IUserSkillLevelChartOwnProps>(UserSkillLevelChart as any);

import { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import ListFilterHelper, { ListFilterQuery } from '@src/service/util/filter/ListFilterHelper';

/** Transform ExternalEducationApplication list filter to API filter */
export function transformExternalEducationTemplateListFilter(filter: IExternalEducationTemplateListFilter): ListFilterQuery {
  return ListFilterHelper.simpleFilterToQueryMapper(filter, (filterQuery, name, filterValue) => {
    // process "city", "educationProvider", "evaluator", "county"

    if (name === 'city' || name === 'educationProvider' || name === 'evaluator' || name === 'county' || name === 'educationSector') {
      const value = filterValue[name]; // take filter value for current property

      if (value != null /* empty values should not be added to query */) {
        filterQuery[name] = value.id;
      }
      return true; // signal mapper that this prop has been manually processed
    }

    return false; // signal mapper that this prop has NOT been processed and mapper should apply default mapping
  });
}

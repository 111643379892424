import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import AppContent from '@src/components/common/container/AppContent';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseList from '@src/components/course/list/CourseList';
import CoursesOverviewListFilter from '@src/components/course/report/CoursesOverviewListFilter';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction } from '@src/service/util/action/trackAction';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const VIEW_NAME = '@@COURSES_REPORT';
const MAX_PAGE_SIZE = AppConfigService.getValue('api.collectionDefaultLimit');
// -- Prop types
// ----------

export interface ICoursesOverviewContainerOwnProps {}

export interface ICoursesOverviewContainerStateProps {
  courseGroups: ICourseGroup[];
  courseList: ICollectionData<ICourse>;
}

export interface ICoursesOverviewContainerDispatchProps {
  fetchCourseList: (params: ICollectionFetchPayload<ICourseListFilter>) => void;
  clearCourseList: () => void;
}

type ICoursesOverviewContainerProps = ICoursesOverviewContainerOwnProps & ICoursesOverviewContainerStateProps & ICoursesOverviewContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------

/** Container component for list of all courses. */
const CoursesOverviewContainer: React.FC<ICoursesOverviewContainerProps> = (props: ICoursesOverviewContainerProps) => {
  const updateCourseList = () => {
    onUpdateList(props.fetchCourseList, { ...collectionParams.filter, withTraineesStats: true });
  };
  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<ICourseListFilter>({
    viewName: VIEW_NAME,
    updateFn: updateCourseList,
    initialValues: { filter: { withTraineesStats: true }, size: MAX_PAGE_SIZE },
  });

  useEffect(() => {
    return () => {
      props.clearCourseList();
    };
  }, []);

  const additionalCols = useCallback((): IListAdditionalCol[] => {
    return [
      {
        key: 'toDo',
        responsiveWidth: { lg: 4, xs: 5 },
        headerTitle: props.translate('COURSES_REPORT.TODO_LABEL'),
        content: (record: ICourse) => record.additionalData && record.additionalData.traineesStats && record.additionalData.traineesStats.toDoCount,
      },
      {
        key: 'inProgress',
        responsiveWidth: { lg: 4, xs: 5 },
        headerTitle: props.translate('COURSES_REPORT.INPROGRESS_LABEL'),
        content: (record: ICourse) => record.additionalData && record.additionalData.traineesStats && record.additionalData.traineesStats.inProgressCount,
      },
      {
        key: 'completedCount',
        responsiveWidth: { lg: 4, xs: 5 },
        headerTitle: props.translate('COURSES_REPORT.COMLETED_LABEL'),
        content: (record: ICourse) => record.additionalData && record.additionalData.traineesStats && record.additionalData.traineesStats.completedCount,
      },
    ];
  }, [props.translate]);

  const getPath = useCallback((id: string, lecture: string) => {
    return `courses/administration/overview/course/${id}`;
  }, []);

  return (
    <AppContent>
      <CoursesOverviewListFilter value={collectionParams.filter} onChange={updateCollectionParams.onFilterUpdate} />
      {/* ----- Course List ----- */}
      <CourseList courseGroups={props.courseGroups} courses={props.courseList?.content ?? []} courseColWidth={{ lg: 12, xs: 9 }} renderAdditionalCols={additionalCols} getPath={getPath} returnRoute={props.location?.pathname} />
    </AppContent>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICoursesOverviewContainerOwnProps): ICoursesOverviewContainerStateProps => ({
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
  courseList: CourseListBusinessStore.selectors.getCourseList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICoursesOverviewContainerDispatchProps => ({
  fetchCourseList: (params: ICollectionFetchPayload<ICourseListFilter>) => createTrackableAction(dispatch(CourseListBusinessStore.actions.fetchAdminCourseList(params))),
  clearCourseList: () => dispatch(CourseListBusinessStore.actions.clearCourseList()),
});

export default connect<ICoursesOverviewContainerStateProps, ICoursesOverviewContainerDispatchProps, ICoursesOverviewContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(CoursesOverviewContainer as any)));

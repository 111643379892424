import Keycloak, { KeycloakInstance } from 'keycloak-js';
import { from, Observable, throwError } from 'rxjs';

import AppConfigService from '@src/service/common/AppConfigService';
import LemonError from '@src/service/common/LemonError';
import AuthTokenManager from '@src/service/util/AuthTokenManager';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { catchError } from 'rxjs/operators';

const CLIENT_NOT_INITIALIZED_ERROR = 'Keycloak client not initialized. Run init() method first.';
const URL_PROTOCOL_REGEX = /^[a-z](?:[-a-z0-9\+\.])*:(?:\/\/).+/;
const DEFAULT_TOKEN_UPDATE_MIN_TIMEOUT = AppConfigService.getValue('authentication.token.updateMinTimeout');

// keycloak client
let keycloakClient: KeycloakInstance;

export default class AuthenticationManager {
  /** Initialize authentication client. This method is async and returns observable. */
  static init(): Observable<boolean> {
    keycloakClient = Keycloak(AppConfigService.getValue('authentication.keycloak.config'));

    function copyToken() {
      const token = AuthenticationManager.getToken();
      if (token) {
        AuthTokenManager.saveToken(`${keycloakClient.token}`);
      }
    }

    function deleteToken() {
      AuthTokenManager.deleteToken();
    }

    keycloakClient.onReady = () => {
      // copy JWT token
      console.info('AUTH - onReady');
    };
    keycloakClient.onAuthSuccess = () => {
      // copy JWT token
      console.info('AUTH - onAuthSuccess');
      copyToken();
    };
    keycloakClient.onAuthError = () => {
      // clear JWT token
      console.info('AUTH - onAuthError');
      deleteToken();
    };
    keycloakClient.onAuthRefreshSuccess = () => {
      // copy JWT token
      console.info('AUTH - onAuthRefreshSuccess');
      copyToken();
    };
    keycloakClient.onAuthRefreshError = () => {
      console.warn('AUTH - onAuthRefreshError');
      AuthenticationManager.logout();
    };
    keycloakClient.onAuthLogout = () => {
      // clear JWT token
      console.info('AUTH - onAuthLogout');
      deleteToken();
    };
    keycloakClient.onTokenExpired = () => {
      // refresh JWT token
      console.info('AUTH - onTokenExpired');
      AuthenticationManager.updateToken();
    };

    return from(keycloakClient.init(AppConfigService.getValue('authentication.keycloak.init')));
  }

  /** Start login procedure. This method is async and returns observable. */
  static login(options?: { redirectUri?: string }): Observable<void> {
    if (keycloakClient == null) {
      throw new LemonError(CLIENT_NOT_INITIALIZED_ERROR);
    }

    // create full app URL to satisfy keycloak's protection
    let redirectUri;
    if (options?.redirectUri != null) {
      redirectUri = AuthenticationManager.createFullAppUrl(options?.redirectUri != null ? options.redirectUri : AppConfigService.getValue('routing.publicDefaultRoute'));
    }

    return from(
      keycloakClient.login({
        redirectUri,
      })
    );
  }

  /** Start logout procedure. This method is async and returns observable. */
  static logout(options?: { redirectUri?: string }): Observable<void> {
    if (keycloakClient == null) {
      throw new LemonError(CLIENT_NOT_INITIALIZED_ERROR);
    }

    // create full app URL to satisfy keycloak's protection
    let redirectUri;
    if (options?.redirectUri != null) {
      redirectUri = AuthenticationManager.createFullAppUrl(options?.redirectUri != null ? options.redirectUri : AppConfigService.getValue('routing.publicDefaultRoute'));
    }

    keycloakClient.clearToken(); // this also triggers onAuthLogout event, so call this before logout and redirect
    // ... BUT, for some reason, onAuthLogout is NOT triggered when token expires on idle session - so we trigger it manually
    // on normal logouts this will trigger the same event twice but, it seems there is no harm
    keycloakClient.onAuthLogout?.();

    return from(
      keycloakClient.logout({
        redirectUri,
      })
    );
  }

  /**
   * Synchronously returns authentication token.
   */
  static getToken(): string | undefined {
    if (keycloakClient == null) {
      throw new LemonError(CLIENT_NOT_INITIALIZED_ERROR);
    }

    return keycloakClient.token;
  }

  /**
   * Update token or at leat try. Keycloak will refresh only expired token.
   *
   * See KeycloakInstance.updateToken() for more info.
   */
  static updateToken(): Observable<boolean> {
    return from(keycloakClient.updateToken(DEFAULT_TOKEN_UPDATE_MIN_TIMEOUT)).pipe(
      // KC client throws an "undefined" error if missing token so this should set more meaningfull error
      catchError((err) => throwError(err ?? 'Error updating auth token'))
    );
  }

  private static createFullAppUrl(urlPart: string): string {
    // URL part already contains protocol, so leave it as it is
    if (urlPart.match(URL_PROTOCOL_REGEX)) {
      return urlPart;
    }
    // no protocol, create full appURL
    else {
      return UrlBuilderFactory.createFullApplicationBuilder()
        .urlPart(urlPart)
        .build();
    }
  }
}

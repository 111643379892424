import React from 'react';
import { Helmet } from 'react-helmet';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import AppConfigService from '@src/service/common/AppConfigService';

const APP_NAME = AppConfigService.getValue('app.name');

// -- Prop types
// ----------

export interface IAppInfoOwnProps {
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}
type IAppInfoProps = IAppInfoOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display app info like tenant name, ... */
const AppInfo: React.FC<IAppInfoProps> = (props) => {
  const js = props.tenantOrganizationConfiguration?.configuration?.jsScripts;
  return (
    <Helmet>
      <title>
        {props.tenantOrganizationConfiguration.configuration?.displayName != null ? `${props.tenantOrganizationConfiguration.configuration?.displayName} | ` : ''}
        {APP_NAME ?? ''}
      </title>
      {js && <script>{js}</script>}
    </Helmet>
  );
};

export default withLocalize<IAppInfoOwnProps>(AppInfo as any);

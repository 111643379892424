import ExternalContentListView from '@src/components/externalcontent/list/ExternalContentListView';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { ICollectionData } from '@src/service/business/common/types';
import ExternalContentListBusinessStore, { IExternalContentListFilter } from '@src/service/business/externalcontent/ExternalContentListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const defaultSortValue = ['title,asc'];

// -- Prop types
// ----------

interface IExternalContentListContainerOwnProps {
}

interface IExternalContentListContainerStateProps {
  externalContentList: ICollectionData<IExternalContent>;
  externalContentListFilter: IExternalContentListFilter;
}

interface IExternalContentListContainerDispatchProps {
  fetchExternalContentList: (filter: IExternalContentListFilter, page: number, size: number, sort: string[]) => void;
  storeExternalContentListFilter: (filter: IExternalContentListFilter) => void;
  clearExternalContentList: () => void;
  clearExternalContentListFilter: () => void;
}
type IExternalContentListContainerProps = IExternalContentListContainerOwnProps & IExternalContentListContainerStateProps & IExternalContentListContainerDispatchProps;

interface IExternalContentListState {
  page: number;
  size: number;
  sort: string[];
}

// -- Component
// ----------

class ExternalContentListContainer extends React.Component<IExternalContentListContainerProps, IExternalContentListState> {
  state: IExternalContentListState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: defaultSortValue,
  };

  componentDidMount = () => {
    this.updateList();
  }

  componentDidUpdate = (prevProps: IExternalContentListContainerProps, prevState: any) => {
    if (this.props.externalContentListFilter !== prevProps.externalContentListFilter || this.state !== prevState) {
      this.updateList();
    }
  }

  componentWillUnmount = () => {
    this.props.clearExternalContentListFilter();
    this.props.clearExternalContentList();
  }

  render = () => {
    return <ExternalContentListView
      externalContentList={this.props.externalContentList}
      externalContentListFilter={this.props.externalContentListFilter}
      sortValue={this.state.sort}
      onPageChange={this.handlePageChange}
      onFilterChange={this.handleFilterChange}
      onSortChange={this.handleSortChange}
      getPath={this.getPath}
    />;
  }

  handleSortChange = (sort: string[]) => {
    this.setState({ sort, page: 0 });
  }

  handleFilterChange = (filter: IExternalContentListFilter) => {
    this.props.storeExternalContentListFilter(filter);
    this.setState({ page: 0 });
  }

  handlePageChange = (page: number, size?: number) => {
    this.setState({ page, size: size ?? this.state.size });
  }

  private getPath = (id: string): string => {
    return `/externalcontent/${id}`;
  }

  private updateList = (filter: IExternalContentListFilter = this.props.externalContentListFilter, page: number = this.state.page, size: number = this.state.size, sort: string[] = this.state.sort) => {
    this.props.fetchExternalContentList(filter, page, size, sort);
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalContentListContainerOwnProps): IExternalContentListContainerStateProps => ({
  externalContentList: ExternalContentListBusinessStore.selectors.getExternalContentList(state),
  externalContentListFilter: ExternalContentListBusinessStore.selectors.getExternalContentListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IExternalContentListContainerDispatchProps => ({
  fetchExternalContentList: (filter: IExternalContentListFilter, page: number, size: number, sort: string[]) => dispatch(ExternalContentListBusinessStore.actions.fetchExternalContentList(filter, page, size, sort)),
  storeExternalContentListFilter: (filter: IExternalContentListFilter) => dispatch(ExternalContentListBusinessStore.actions.storeExternalContentListFilter(filter)),
  clearExternalContentList: () => dispatch(ExternalContentListBusinessStore.actions.clearExternalContentList()),
  clearExternalContentListFilter: () => dispatch(ExternalContentListBusinessStore.actions.clearExternalContentListFilter()),
});

export default connect<IExternalContentListContainerStateProps, IExternalContentListContainerDispatchProps, IExternalContentListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(ExternalContentListContainer);

import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Button, Col, Row, Select } from 'antd';
import React from 'react';

const sortItems = [
  {
    id: 'requestedDateTime,desc',
    translationKey: 'COMMON.ACTION_SORT_NEWEST',
  },
  {
    id: 'requestedDateTime,asc',
    translationKey: 'COMMON.ACTION_SORT_OLDEST',
  },
];

// -- Prop types
// ----------

export interface IAdminReportHeaderOwnProps {
  onButtonClick: () => void;
  onSortChange: (sort: string[]) => void;
}

type IAdminReportHeaderProps = IAdminReportHeaderOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const AdminReportHeader = (props: IAdminReportHeaderProps) => {
  return (
    <div className="timun-adminReportsHeader">
      <Row align="middle" justify="space-between" gutter={[16, 16]}>
        <Col span={6}>
          <GridItem layout="horizontal" label={props.translate('COMMON.ACTION_SORT_LABEL')}>
            <Select defaultValue={[sortItems[0].id]} onChange={props.onSortChange}>
              {sortItems.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {props.translate(item.translationKey)}
                </Select.Option>
              ))}
            </Select>
          </GridItem>
        </Col>

        <Col>
          <Button onClick={props.onButtonClick} type="primary">
            {props.translate('REPORTS_LIST.CREATE_REPORT_BUTTON_LABEL')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<IAdminReportHeaderOwnProps>(AdminReportHeader as any);

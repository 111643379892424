import FileTypesListView from '@src/components/common/file/FileTypesListView';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExternalEducationApplicationFileTypeEnum } from '@src/model/file/FileType';
import { IFile } from '@src/model/file/File';
import { IFileListsByType } from '@src/service/business/files/util';
import React from 'react';

interface IEducationApplicationFileListViewOwnProps {
  displayTypes: Array<keyof typeof ExternalEducationApplicationFileTypeEnum>;
  files?: IFileListsByType;
  canRemove?: boolean;
  onRemove?: (file: IFile[]) => void;
}
type IEducationApplicationFileListViewProps = IEducationApplicationFileListViewOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationFileListViewState {
}

class EducationApplicationFileListView extends React.Component<IEducationApplicationFileListViewProps, IEducationApplicationFileListViewState> {
  state: IEducationApplicationFileListViewState = {
  };

  render = () => {
    return <FileTypesListView
      displayTypes={this.props.displayTypes}
      files={this.props.files}
      canRemove={this.props.canRemove}
      onRemove={this.props.onRemove}
    />;
  };
}

export default withLocalize<IEducationApplicationFileListViewOwnProps>(EducationApplicationFileListView as any);


import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SkillLevelClassificationModal from '@src/components/skilllevelclassification/modal/SkillLevelClassificationModal';
import { ISkillLevel, ISkillLevelClassificationShortInfo } from '@src/model/skillgroup/SkillLevelClassification';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import { ISkillLevelListFilter, SkillLevelBusinessStore } from '@src/service/business/skilllevel/SkillLevelBusinessStore';
import SkillLevelClassificationListBusinessStore, { ISkillLevelClassificationShortInfoListFilter } from '@src/service/business/skilllevelclassification/SkillLevelClassificationBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface ISkillLevelClassificationModalContainerOwnProps {
  skillId: string;
  onClose?: () => void;
  onSkillLevelClassificationUpdate?: () => void;
}
export interface ISkillLevelClassificationModalContainerStateProps {
  skillLevelList: ICollectionData<ISkillLevel>;
  skillLevelClassificationList: ICollectionData<ISkillLevelClassificationShortInfo>;
}
export interface ISkillLevelClassificationModalContainerDispatchProps {
  fetchSkillLevelList: (params: ICollectionFetchPayload<ISkillLevelListFilter>) => void;
  fetchSkillLevelClassificationList: (params: ICollectionFetchPayload<ISkillLevelClassificationShortInfoListFilter>) => void;
  updateSkillLevelClassificationList: (data: ISkillLevelClassificationShortInfo[]) => ITrackableAction;
  clearSkillLevelClassificationList: () => void;
}

type ISkillLevelClassificationModalContainerProps = ISkillLevelClassificationModalContainerOwnProps & ISkillLevelClassificationModalContainerStateProps
  & ISkillLevelClassificationModalContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const SkillLevelClassificationModalContainer = (props: ISkillLevelClassificationModalContainerProps) => {
  useEffect(() => { onUpdateClassificationList(); }, [props.skillId]);

  const [, , onUpdateClassificationList] = useCollectionState<ISkillLevelClassificationShortInfoListFilter>({
    updateFn: props.fetchSkillLevelClassificationList,
  }, [props.skillId]);

  useCollectionState<ISkillLevelListFilter>({ updateFn: props.fetchSkillLevelList });

  const handleUpdateSkillLevelClassification = (skillLevelClassificationList: ISkillLevelClassificationShortInfo[]) => {
    props.updateSkillLevelClassificationList(skillLevelClassificationList).track().subscribe(
      // success
      () => {
        onUpdateClassificationList();
        props.onClose?.();
      }
    );
  };

  return (
    <React.Fragment>
      {props.skillLevelClassificationList && props.skillLevelList &&
        <SkillLevelClassificationModal
          skillLevelClassificationList={props.skillLevelClassificationList.content}
          skillLevelList={props.skillLevelList.content}
          onSubmit={handleUpdateSkillLevelClassification}
          onCancel={props.onClose}
        />
      }
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillLevelClassificationModalContainerOwnProps): ISkillLevelClassificationModalContainerStateProps => ({
  skillLevelList: SkillLevelBusinessStore.selectors.getSkillLevelList(state),
  skillLevelClassificationList: SkillLevelClassificationListBusinessStore.selectors.getSkillLevelClassificationShortInfoListData(state, ownProps.skillId),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: ISkillLevelClassificationModalContainerOwnProps): ISkillLevelClassificationModalContainerDispatchProps => ({
  fetchSkillLevelList: (params: ICollectionFetchPayload<ISkillLevelListFilter>) => dispatch(SkillLevelBusinessStore.actions.fetchSkillLevelList(params)),

  fetchSkillLevelClassificationList: (params: ICollectionFetchPayload<ISkillLevelClassificationShortInfoListFilter>) => dispatch(SkillLevelClassificationListBusinessStore.actions.fetchSkillLevelClassificationShortInfoList(ownProps.skillId, params)),
  updateSkillLevelClassificationList: (data: ISkillLevelClassificationShortInfo[]) => createTrackableAction(dispatch(SkillLevelClassificationListBusinessStore.actions.updateSkillLevelClassificationShortInfoList(ownProps.skillId, data))),
  clearSkillLevelClassificationList: () => dispatch(SkillLevelClassificationListBusinessStore.actions.clearSkillLevelClassificationShortInfoList(ownProps.skillId)),
});

export default connect<ISkillLevelClassificationModalContainerStateProps, ISkillLevelClassificationModalContainerDispatchProps, ISkillLevelClassificationModalContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(SkillLevelClassificationModalContainer as any));

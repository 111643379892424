import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IUserInfo } from '@src/model/user/User';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import CourseUpdateContainer from '@src/components/course/update/CourseUpdateContainer';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import RouterNavigationPromptContextProvider from '@src/components/common/route/prompt/RouterNavigationPromptContextProvider';

// -- Prop types
// ----------
export interface ICourseAdminViewPagePublicProps {}
interface ICourseAdminViewPageStateProps {
  currentUser: IUserInfo;
}
type ICourseAdminViewPageProps = ICourseAdminViewPagePublicProps & ICourseAdminViewPageStateProps & WithRouterProps & IWithRolesOwnProps;

// -- Component
// ----------
const CourseAdminViewPage = (props: ICourseAdminViewPageProps) => {
  return (
    <RouterNavigationPromptContextProvider message={LocalizeService.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION')}>
      <CourseUpdateContainer courseId={props.params.courseId} lectureId={props.params.lectureId} />
    </RouterNavigationPromptContextProvider>
  );
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseAdminViewPagePublicProps): ICourseAdminViewPageStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

export default withRouter(connect<ICourseAdminViewPageStateProps, any, ICourseAdminViewPagePublicProps>(mapStateToProps)(withRoles(CourseAdminViewPage)));

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TagList from '@src/components/tag/TagList';
import { ITag } from '@src/model/tag/Tag';
import { IUserRole, UserRoleEnum } from '@src/model/user/UserRole';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Select, Tag } from 'antd';
import React from 'react';

// -- Prop types
// ----------

interface ITagViewOwnProps {
  tagList: ITag[];
  userRoles: IUserRole[];
  tags?: ITag[];
  onTagDelete: (event: React.MouseEvent, id: string) => void;
  onAddTagToEntity: (tag: ITag) => void;
  onCreateTag: (newName: string) => void;
  onTagSearch: (value: string) => void;
}

type ITagViewProps = ITagViewOwnProps & IWithLocalizeOwnProps;

interface ITagViewState {
  showTagInput: boolean;
  newName: string;
}

// -- Component
// ----------

class TagView extends React.Component<ITagViewProps, ITagViewState> {
  state: ITagViewState = {
    showTagInput: false,
    newName: '',
  };

  render = () => {
    const tagsSelect = (
      <Select
        className="timun-tagContainer__select"
        mode="tags"
        showSearch={true}
        dropdownMatchSelectWidth={false}
        autoFocus={true}
        size="small"
        allowClear={true}
        onBlur={this.closeTagInput}
        onChange={this.handleChange}
        onSearch={this.handleTagSearch}
        defaultActiveFirstOption={false}
        filterOption={false}
        open={this.state.showTagInput}
        onInputKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.keyCode === 13) {
            // Enter key
            event.stopPropagation();
            this.handleInputConfirm();
          }
        }}
      >
        {this.props.tagList.map((tag: ITag) => (
          <Select.Option value={tag.id} key={tag.id}>
            {tag.title}
          </Select.Option>
        ))}
      </Select>
    );

    return (
      <div className="timun-tagView__tagList">
        {this.props.tags &&
        <TagList
          tags={this.props.tags}
          closable={RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR], this.props.userRoles)}
          onClose={this.props.onTagDelete}
        />
        }
        {this.state.showTagInput ? tagsSelect :
          RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR], this.props.userRoles) &&
          <Tag icon={<LemonIcon name="plus"/>} onClick={this.openTagInput}>{this.props.translate('TAG_CONTAINER.ADD_TAG')}</Tag>
        }
      </div>
    );
  };

  handleTagSearch = (value: string) => {
    this.setState({
      newName: value,
    });

    if (value !== this.state.newName) {
      this.props.onTagSearch(value);
    }
  };

  handleChange = (value: string[]) => {
    const newTag = this.props.tagList.find((tag: ITag) => tag.id === value[0]);

    if (newTag) {
      this.props.onAddTagToEntity(newTag);
    }

    this.closeTagInput();
  };

  handleInputConfirm = (newTag?: ITag) => {
    const existingTag = newTag ? newTag : this.props.tagList.find((tag: ITag) => tag.title === this.state.newName);
    if (existingTag) {
      this.props.onAddTagToEntity(existingTag);
    } else if (this.state.newName) {
      this.props.onCreateTag(this.state.newName);
    }
    this.setState({
      newName: '',
    });
    this.closeTagInput();
  };

  openTagInput = () => {
    this.setState({
      showTagInput: true,
    });
  };

  closeTagInput = () => {
    this.setState({
      showTagInput: false,
    });
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<ITagViewOwnProps>(TagView as any);

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import React from 'react';
import { Button, Col, Row } from 'antd';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { ICollectionData } from '@src/service/business/common/types';
import { IProgressTracker, ProgressTrackerTypeEnum } from '@src/model/progresstracker/ProgressTracker';
import ProgressTrackerList from '@src/components/user/list/ProgressTrackerList';

export interface ILdapViewOwnProps {
  onLdapSync: () => void;
  progressTrackerList: ICollectionData<IProgressTracker>;
  onPageChange?: (page: number, size?: number) => void;
  type: ProgressTrackerTypeEnum;
  onReload: () => void;
}

type ILdapViewProps = ILdapViewOwnProps & IWithLocalizeOwnProps;

const LdapView: React.FC<ILdapViewProps> = (props: ILdapViewProps) => {
  return (
    <React.Fragment>
      <Row className="timun-wrapper__gutterBox--vertical" gutter={[4, 4]} justify="end">
        <Col>
          <Button icon={<LemonIcon name="download" />} type="primary" onClick={props.onLdapSync}>
            {props.translate('USER_GROUP.LDAP_SYNC_LABEL')}
          </Button>
        </Col>
      </Row>
      {props.progressTrackerList && <ProgressTrackerList progressTrackerList={props.progressTrackerList} onPageChange={props.onPageChange} onReload={props.onReload} />}
    </React.Fragment>
  );
};

export default withLocalize<ILdapViewOwnProps>(LdapView as any);
